import { noop } from 'lodash-es';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { showError, showInfo, showSuccess } from '../../utils/messageUtils';

const FlashMessageContext = createContext({
  errorMessage: noop,
  successMessage: noop,
  infoMessage: noop,
});
export function useFlashMessageContext() {
  return useContext(FlashMessageContext);
}

export default function FlashMessageProvider({ children }) {
  const intl = useIntl();
  const errorMessage = useCallback(
    content => showError(content, { intl }),
    [intl]
  );
  const successMessage = useCallback(
    content => showSuccess(content, { intl }),
    [intl]
  );
  const infoMessage = useCallback(
    content => showInfo(content, { intl }),
    [intl]
  );

  const value = useMemo(
    () => ({ errorMessage, successMessage, infoMessage }),
    [errorMessage, successMessage, infoMessage]
  );

  return (
    <FlashMessageContext.Provider value={value}>
      {children}
    </FlashMessageContext.Provider>
  );
}
