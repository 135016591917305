import classNames from 'classnames';
import { isEqual, isFunction, merge } from 'lodash-es';
import { createContext, useCallback, useReducer, useRef } from 'react';
import RCScrollbars from 'react-custom-scrollbars';

import { usePeriodicCheck } from '../../utils/reactUtils';
import { useResponsiveQueries } from '../responsive/responsiveQueries';

// See https://github.com/malte-wessel/react-custom-scrollbars/blob/master/docs/API.md
const SCROLLBARS_CONTEXT_DEFAULT = {
  ref: { current: null },
  top: 0,
  left: 0,
  clientWidth: 0,
  clientHeight: 0,
  scrollWidth: 0,
  scrollHeight: 0,
  scrollLeft: 0,
  scrollTop: 0,
};
export const ScrollbarsContext = createContext(SCROLLBARS_CONTEXT_DEFAULT);

function makeRenderer(classSuffix) {
  return ({ style }) => (
    <div className={`Scrollbar-${classSuffix}`} style={style} />
  );
}

const renderThumb = makeRenderer('Thumb');
const renderTrackVertical = makeRenderer('TrackVertical');
const renderTrackHorizontal = makeRenderer('TrackHorizontal');
const renderView = makeRenderer('View');

export const HorizontalTrackTypes = {
  NONE: 'NONE',
  TOP: 'TOP',
  BOTTOM: 'BOTTOM',
};
const HORIZONTAL_TRACK_MAPPINGS = {
  [HorizontalTrackTypes.NONE]: 'Scrollbar-NoHorizontal',
  [HorizontalTrackTypes.TOP]: 'Scrollbar-HorizontalTop',
  [HorizontalTrackTypes.BOTTOM]: 'Scrollbar-HorizontalBottom',
};
export const VerticalTrackTypes = {
  INSIDE: 'INSIDE',
  ABOVE_CONTENT: 'ABOVE_CONTENT',
  OUTSIDE: 'OUTSIDE',
};
const VERTICAL_TRACK_MAPPINGS = {
  [VerticalTrackTypes.INSIDE]: 'Scrollbar-ShrinkContent',
  [VerticalTrackTypes.ABOVE_CONTENT]: '',
  [VerticalTrackTypes.OUTSIDE]: 'Scrollbar-VerticalOutside',
};

function scrollValuesReducer(state, action) {
  switch (action.type) {
    case 'SET':
      return !isEqual(action.value, state) ? action.value : state;
    default:
      return state;
  }
}

export function Scrollbars({
  horizontal = HorizontalTrackTypes.NONE,
  vertical = VerticalTrackTypes.ABOVE_CONTENT,
  spaceOutside,
  style: styleOuter,
  className,
  isInFlexContainer,
  computeHeight,
  scrollbarsRef,
  ...rest
}) {
  const scrollbar = useRef();
  const [scrollValues, dispatchScrollValues] = useReducer(
    scrollValuesReducer,
    SCROLLBARS_CONTEXT_DEFAULT
  );
  usePeriodicCheck(() => {
    if (scrollbar.current) {
      scrollbar.current.update();
      dispatchScrollValues({
        type: 'SET',
        value: scrollbar.current.getValues(),
      });
    }
  });
  const setRef = useRef(newRef => {
    scrollbar.current = newRef;
    if (newRef) {
      dispatchScrollValues({
        type: 'SET',
        value: newRef.getValues(),
      });
    }
    if (scrollbarsRef) {
      // integration with react-window (https://github.com/bvaughn/react-window/issues/110#issuecomment-469061213)
      scrollbarsRef(newRef?.view);
    }
  });
  const onScrollFrame = useCallback(vals => {
    dispatchScrollValues({ type: 'SET', value: vals });
  }, []);

  const media = useResponsiveQueries();
  const isSm = media.xs || media.sm;

  const style = { width: 'initial' };
  if (isInFlexContainer) {
    style.flex = 1;
    style.height = 'auto';
  } else if (isFunction(computeHeight)) {
    style.height = computeHeight({ ...scrollValues, ref: scrollbar });
  } else {
    style.height = '100%';
  }
  merge(style, styleOuter);

  return (
    <ScrollbarsContext.Provider value={{ ...scrollValues, ref: scrollbar }}>
      <RCScrollbars
        renderTrackVertical={renderTrackVertical}
        renderThumbVertical={renderThumb}
        renderTrackHorizontal={renderTrackHorizontal}
        renderThumbHorizontal={renderThumb}
        renderView={renderView}
        onScrollFrame={onScrollFrame}
        ref={setRef.current}
        className={classNames(
          'Scrollbar',
          {
            'Scrollbar-NoHorizontal': !horizontal,
            'Scrollbar-SpaceOutside':
              spaceOutside || vertical === VerticalTrackTypes.OUTSIDE,
          },
          VERTICAL_TRACK_MAPPINGS[vertical],
          HORIZONTAL_TRACK_MAPPINGS[horizontal],
          className
        )}
        hideTracksWhenNotNeeded
        autoHide={isSm}
        style={style}
        {...rest}
      />
    </ScrollbarsContext.Provider>
  );
}
