import classNames from 'classnames';
import { createContext, useContext } from 'react';
import { createPortal } from 'react-dom';

const ModalOverlayContext = createContext({ level: 0 });

const MODAL_OVERLAY_BASE_ZINDEX = 100;

export default function ModalOverlay({
  component: C = 'div',
  maskOpacity = 95,
  // When multiple modal overlays are at the same level, React portal doesn't
  // guarantee the order and it should be forced to be consistent
  levelBoost = 0,
  style,
  className,
  children,
  ...rest
}) {
  const { level: parentLevel } = useContext(ModalOverlayContext);
  const level = parentLevel + levelBoost + 1;

  return createPortal(
    <C
      className={classNames(
        'fixed-full-screen',
        `Mask-${maskOpacity}`,
        className
      )}
      style={{
        ...style,
        zIndex: MODAL_OVERLAY_BASE_ZINDEX + level,
      }}
      {...rest}
    >
      <ModalOverlayContext.Provider value={{ level }}>
        {children}
      </ModalOverlayContext.Provider>
    </C>,
    document.body
  );
}
