import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import { FAIcon } from '../../components/adapters/fontAwesomeAdapters';
import InlineDialog from '../../components/dialogs/InlineDialog';

export default function MonitorNoData() {
  return (
    <InlineDialog
      className="MonitorNoData"
      icon={<FAIcon icon="exclamation-triangle" />}
    >
      <Typography.Title level={2}>
        <FormattedMessage id="titles.attention" />
      </Typography.Title>
      <Typography.Paragraph className="nl-as-newline">
        <FormattedMessage id="filterResult.empty" />
      </Typography.Paragraph>
    </InlineDialog>
  );
}
