import { useMutation } from '@apollo/client';
import { merge, pick } from 'lodash-es';
import { useCallback, useState } from 'react';

import { ErrorCode } from '../app/common/ErrorCode';
import { LOGIN_MUTATION } from '../app/graphql/authQueries';
import routes from '../app/routes';
import { HeaderLogo, SmallLogo } from '../components/Logo';
import {
  FormItemFieldRegistration,
  FormItemInputPassword,
  FormItemInputText,
} from '../components/forms/basicFormElements';
import { FormItemInlineCheckbox } from '../components/forms/checkable';
import { NoLabelForm, SubmitButton } from '../components/forms/forms';
import { InlineLink } from '../components/typography';
import { makeError } from '../utils/errorUtils';
import {
  AuthEnvelope,
  AuthEnvelopeInfoText,
  AuthEnvelopeLinks,
  AuthEnvelopeSubtitle,
  AuthEnvelopeTitle,
  useAuthErrorMessage,
} from '../widgets/AuthEnvelope';
import PinField from './auth/PinField';

function CredentialsForm() {
  return (
    <>
      <AuthEnvelopeSubtitle textId="login.envelope.title" />
      <div className="LoginForm__FormItems">
        <FormItemInputText
          name="email"
          labelId="form.email.label"
          rules={[{ required: true }]}
        />
        <FormItemInputPassword
          name="password"
          labelId="form.password.label"
          rules={[{ required: true }]}
        />
        <FormItemInlineCheckbox
          name="rememberMe"
          labelId="form.rememberMe.label"
        />
        <SubmitButton
          type="primary"
          textId="login.action"
          data-subject="auth"
          data-action="login"
        />
      </div>
      <AuthEnvelopeLinks>
        <InlineLink textId="login.forgotPassword" to={routes.passwordReset} />
      </AuthEnvelopeLinks>
      <FormItemFieldRegistration name="pin" />
    </>
  );
}

function PinForm({ goBack }) {
  return (
    <>
      <AuthEnvelopeTitle textId="login.pin.title" />
      <AuthEnvelopeInfoText textId="login.pin.infoText" />
      <div className="LoginForm__FormItems">
        <PinField />
        <SubmitButton
          type="primary"
          textId="login.action"
          data-subject="auth"
          data-action="login"
        />
      </div>
      <AuthEnvelopeLinks>
        <InlineLink textId="buttons.back" onClick={goBack} />
      </AuthEnvelopeLinks>
      <FormItemFieldRegistration name="email" />
      <FormItemFieldRegistration name="password" />
      <FormItemFieldRegistration name="rememberMe" />
    </>
  );
}

function mapInputValues({ email, password, rememberMe, pin }, { pinRequired }) {
  return {
    email,
    password,
    rememberMe,
    pin: pinRequired ? (pin || []).join('') : null,
  };
}

function computeDialogProps({ errorCode, pinRequired, goBack, errorMessage }) {
  const dialogProps = {};

  // If PIN is required and there was an error, we want to exit
  if (pinRequired) {
    merge(dialogProps, {
      onClose: () => {
        goBack();
        errorMessage();
      },
      cancelTextId: 'buttons.exit',
    });
  }
  // Only in case of invalid PIN we want to provide an option to try again
  if (errorCode === ErrorCode.INVALID_PIN) {
    merge(dialogProps, {
      onOk: () => {
        errorMessage();
      },
      okTextId: 'login.dialog.tryAgain',
    });
  }

  return dialogProps;
}

const PASSWORD_STEP_INDEX = 0;
const PIN_STEP_INDEX = 1;

function useLoginSubmit({ onLoginSuccess, onTermsOfService }) {
  const [stepIndex, setStepIndex] = useState(PASSWORD_STEP_INDEX);
  const [pinRequired, setPinRequired] = useState(false);
  const goBack = useCallback(() => {
    setStepIndex(PASSWORD_STEP_INDEX);
    setPinRequired(false);
  }, []);

  const errorMessage = useAuthErrorMessage();
  const [loginMutation] = useMutation(LOGIN_MUTATION);
  const executeLogin = useCallback(
    async values => {
      const {
        data: { login: result },
      } = await loginMutation({
        variables: { input: mapInputValues(values, { pinRequired }) },
      });
      if (!result.success) {
        if (result.code === ErrorCode.PIN_REQUIRED) {
          setPinRequired(true);
          setStepIndex(PIN_STEP_INDEX);
          return;
        }
        errorMessage(
          makeError({
            message: result.errorText,
            code: result.code,
            dialogProps: computeDialogProps({
              errorCode: result.code,
              pinRequired,
              goBack,
              errorMessage,
            }),
          })
        );
        return;
      }
      // These will cause unmounting, so we want to wait until all operations are completed
      window.requestAnimationFrame(() => {
        const loginResult = { ...result, ...pick(values, ['email']) };
        if (result.termsOfService) {
          onTermsOfService(loginResult);
        } else {
          onLoginSuccess(loginResult);
        }
      });
    },
    [
      errorMessage,
      goBack,
      loginMutation,
      onLoginSuccess,
      onTermsOfService,
      pinRequired,
    ]
  );

  const onSubmit = useCallback(
    values => {
      if (stepIndex === PASSWORD_STEP_INDEX) {
        return executeLogin(values);
      }
      return executeLogin(values);
    },
    [executeLogin, stepIndex]
  );

  return {
    onSubmit,
    stepIndex: pinRequired ? stepIndex : PASSWORD_STEP_INDEX,
    goBack,
  };
}

function LoginFormContent({ onLoginSuccess, onTermsOfService }) {
  const { onSubmit, stepIndex, goBack } = useLoginSubmit({
    onLoginSuccess,
    onTermsOfService,
  });
  return (
    <NoLabelForm onSubmit={onSubmit} hideRequiredMark>
      {stepIndex === PASSWORD_STEP_INDEX && <CredentialsForm />}
      {stepIndex === PIN_STEP_INDEX && <PinForm goBack={goBack} />}
    </NoLabelForm>
  );
}

export default function LoginForm({ onLoginSuccess, onTermsOfService }) {
  return (
    <AuthEnvelope className="LoginForm" data-subject="auth" data-role="form">
      <div>
        <SmallLogo size="lg" className="hide-sm-and-smaller" />
        <HeaderLogo
          size="sm"
          className="hide-md-and-bigger always-full-image"
        />
      </div>
      <LoginFormContent
        onLoginSuccess={onLoginSuccess}
        onTermsOfService={onTermsOfService}
      />
    </AuthEnvelope>
  );
}
