const { gql } = require('@apollo/client');

const notificationGroupAllFields = `{
  myself
  shipper
  consignee
  other
}`;

const notificationSettingsAllFields = `
  orderPlaced ${notificationGroupAllFields}
  csConfirmMyself
  pickedUp ${notificationGroupAllFields}
  flightChange ${notificationGroupAllFields}
  flightTenderTime ${notificationGroupAllFields}
  recovered ${notificationGroupAllFields}
  clearedCustoms ${notificationGroupAllFields}
  outForDelivery ${notificationGroupAllFields}
  revisedEta ${notificationGroupAllFields}
  proofOfDelivery ${notificationGroupAllFields}
  jobCancelled ${notificationGroupAllFields}
`;

export const USER_NOTIFICATION_SETTINGS_QUERY = gql`
  query UserNotificationSettings {
    userNotificationSettings {
      ${notificationSettingsAllFields}
    }
  }
`;

export const UPDATE_USER_NOTIFICATION_SETTINGS_MUTATION = gql`
  mutation UpdateUserNotificationSettings($input: NotificationSettingsInput!) {
    updateUserNotificationSettings(input: $input)
  }
`;
