import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { FAIcon } from '../adapters/fontAwesomeAdapters';
import Collapsible from './Collapsible';
import { Scrollbars, VerticalTrackTypes } from './Scrollbars';

/**
 * @prop {array<{name: string, title: ReactNode, titleId: string, content: ReactNode}>} data
 */
export default function Accordion({
  data,
  className,
  isInFlexContainer,
  fullHeight,
  value,
  setValue,
  collapsibleProps,
}) {
  return (
    <div
      className={classNames(
        'Accordion',
        {
          'Accordion--isInFlexContainer': isInFlexContainer,
          'Accordion--fullHeight': fullHeight,
        },
        className
      )}
    >
      {data.map(({ name, title, titleId, content }) => {
        const isActive = value === name;
        return (
          <div
            key={name}
            className={classNames('Accordion__Item', {
              'Accordion__Item--active': isActive,
            })}
            data-subject="accordion"
            data-role="tab"
            data-active={isActive ? 'true' : 'false'}
          >
            <div
              className={classNames('Accordion__Header', {
                'Accordion__Header--active': isActive,
              })}
              onClick={() => setValue(isActive ? null : name)}
            >
              <FAIcon
                className="icon-16"
                icon={isActive ? 'caret-down' : 'caret-right'}
              />
              {titleId ? <FormattedMessage id={titleId} /> : title}
            </div>
            <Collapsible
              className={classNames('Accordion__Body', {
                'Accordion__Body--active': isActive,
              })}
              expanded={isActive}
              {...collapsibleProps}
            >
              {fullHeight ? (
                <Scrollbars vertical={VerticalTrackTypes.OUTSIDE}>
                  {content}
                </Scrollbars>
              ) : (
                content
              )}
            </Collapsible>
          </div>
        );
      })}
    </div>
  );
}
