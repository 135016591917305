import { useQuery } from '@apollo/client';

import { SERVICE_TYPES_QUERY } from '../../../../app/graphql/adminQueries';
import AdminAccountTransferPage from './AdminAccountTransferPage';

const AdminAccountServiceTypes = ({ account, viewMode, visible }) => {
  const { data, loading } = useQuery(SERVICE_TYPES_QUERY, {
    skip: !visible,
  });

  return (
    <AdminAccountTransferPage
      account={account}
      viewMode={viewMode}
      visible={visible}
      titleIcon="shipping-fast"
      titleId="admin.accountManagement.accountDetail.serviceTypes"
      itemName="serviceTypesSettings"
      titlesPrefix="admin.accountManagement.accountDetail.service"
      data={data?.serviceTypes}
      loading={loading}
    />
  );
};

export default AdminAccountServiceTypes;
