import { isNumber } from 'lodash-es';

export function numberOr(value, defaultValue) {
  if (isNumber(value) && isFinite(value)) {
    return value;
  }
  return defaultValue;
}

export function forceIntoRange(value, { min, max }) {
  return Math.max(min, Math.min(value, max));
}
