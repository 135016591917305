import { Col, Row } from 'antd';
import { get, isNumber } from 'lodash-es';
import { useContext } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { UnitSystems, convertUnit } from '../../app/enums/measuringUnits';
import { numberOr } from '../../common/utils/numberUtils';
import { cssVariables } from '../../styles/cssVariables';
import { pxToNumber } from '../../utils/cssUtils';
import { makeRulesAddingDecorator } from '../forms/FormItem';
import { FormItemInputNumber } from '../forms/basicFormElements';
import { greaterThanRule } from '../forms/formValidationRules';
import { SettingsContext } from './settingsElements';

function useUnit({ unit, unitType }) {
  const { unitSystem } = useContext(SettingsContext);
  if (unit) {
    return unit;
  }
  if (unitType) {
    return get(UnitSystems[unitSystem], unitType);
  }
  return undefined;
}

function FormattedUnit({ value, unitType, unit, inputUnit }) {
  const targetUnit = useUnit({ unit, unitType });

  const valueInTargetUnit = inputUnit
    ? convertUnit(value, inputUnit, targetUnit)
    : value;
  return isNumber(value) && isFinite(value) ? (
    <>
      <FormattedNumber value={valueInTargetUnit} maximumFractionDigits={2} />{' '}
      {targetUnit.suffix}
    </>
  ) : (
    <FormattedMessage id="labels.na" />
  );
}

function FormItemInputNumberWithUnit({
  unit: outerUnit,
  unitType,
  min: outerMin,
  rules: outerRules,
  ...rest
}) {
  const unit = useUnit({ unit: outerUnit, unitType });
  const min = numberOr(outerMin, 0);
  const rules = makeRulesAddingDecorator({
    outerRules,
    newRules: [greaterThanRule(0)],
  });
  const suffix = unit?.suffix;

  return (
    <Row align="top" gutter={pxToNumber(cssVariables.spaceSm)}>
      <Col className="Flex1">
        <FormItemInputNumber min={min} rules={rules} {...rest} />
      </Col>
      <Col
        className="flex-center"
        style={{ height: cssVariables['input-height-md'] }}
      >
        {suffix}
      </Col>
    </Row>
  );
}

export const FormItemInputWeight = props => (
  <FormItemInputNumberWithUnit unitType="weight" {...props} />
);
export const WeightFormat = props => (
  <FormattedUnit unitType="weight" {...props} />
);

export const FormItemInputLength = props => (
  <FormItemInputNumberWithUnit unitType="length" {...props} />
);
export const LengthFormat = props => (
  <FormattedUnit unitType="length" {...props} />
);

export const FormItemInputNumericDuration = props => (
  <FormItemInputNumberWithUnit unitType="time" {...props} />
);
export const NumericDurationFormat = props => (
  <FormattedUnit unitType="time" {...props} />
);
