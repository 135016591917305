import { Typography } from 'antd';
import {
  difference,
  intersection,
  map,
  max,
  size,
  some,
  union,
} from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import { Spinner } from '../../../../components/data/Spinner';
import TransferFormItem from '../../../../components/forms/Transfer';
import {
  FormTheme,
  FormThemeOverrideContextProvider,
} from '../../../../components/forms/formTheme';
import AdminConsoleSection, {
  AdminConsoleSectionContent,
  AdminConsoleSectionTitleHeader,
} from '../../components/AdminConsoleSection';
import AdminConsoleContent from '../../layout/AdminConsoleContent';
import { formatAccountLabel } from '../../utils/adminFormatting';
import { AccountBusinessTypeField } from './AdminAccountDetailCommon';

const getTransferSections = (accountProfile, titlesPrefix, height) => [
  {
    key: 'top',
    leftHeader: (
      <FormattedMessage
        id={`${titlesPrefix}.types.bussiness-type`}
        values={{ bussinessType: accountProfile }}
      />
    ),
    rightHeader: (
      <FormattedMessage
        id={`${titlesPrefix}.types.bussiness-type`}
        values={{ bussinessType: accountProfile }}
      />
    ),
    className: `transfer-section-fixed transfer-section-${height}`,
  },
  {
    key: 'bottom',
    leftHeader: <FormattedMessage id={`${titlesPrefix}.types.library.full`} />,
    rightHeader: (
      <FormattedMessage id={`${titlesPrefix}.types.library.allowed`} />
    ),
    className: 'transfer-section-auto-height',
    scrollbarsConfig: {
      autoHeight: true,
      autoHeightMin: 0,
      autoHeightMax: '100%',
    },
  },
];

const AdminAccountTransferPage = ({
  account,
  viewMode,
  visible,
  itemName,
  titleIcon,
  titleId,
  titlesPrefix,
  data,
  loading,
}) => {
  const editingArray = account[itemName];
  const dataSource = map(data, d => ({
    key: d.code,
    title: d.text,
    fromBussinessType: some(editingArray?.default, s => s === d.code),
  }));

  const updateSelection = (value, direction, movedKeys) => {
    const movedDefault = intersection(value.default, movedKeys);
    const movedAll = difference(movedKeys, value.default);

    const newValues = { ...value };
    if (direction === 'left') {
      newValues.remove = union(value.remove, movedDefault);
      newValues.add = difference(value.add, movedAll);
    } else {
      newValues.add = union(movedAll, value.add);
      newValues.remove = difference(value.remove, movedDefault);
    }
    return newValues;
  };

  const getTargetKeys = value =>
    union(difference(value?.default, value?.remove), value?.add);

  const getItemSection = item => (item.fromBussinessType ? 'top' : 'bottom');

  const topSectionHeight = max([
    size(editingArray?.default) - size(editingArray?.remove),
    size(editingArray?.remove),
  ]);

  const sections = getTransferSections(
    account?.profile,
    titlesPrefix,
    topSectionHeight
  );

  return (
    <AdminConsoleContent
      titleIcon={titleIcon}
      titleId={titleId}
      entityLabel={formatAccountLabel(account)}
      isEditing={!viewMode}
      visible={visible}
      headerRight={<AccountBusinessTypeField />}
    >
      <AdminConsoleSection className="page-with-transferrable-items">
        <AdminConsoleSectionTitleHeader>
          <Typography.Title level={3}>
            {formatAccountLabel(account, { long: true })}
          </Typography.Title>
        </AdminConsoleSectionTitleHeader>

        <FormThemeOverrideContextProvider themeClassName={FormTheme.DarkOrange}>
          {loading ? (
            <Spinner />
          ) : (
            <AdminConsoleSectionContent className="AdminAccountTransferPage">
              <TransferFormItem
                name={itemName}
                dataSource={dataSource}
                titles={[
                  `${titlesPrefix}.types.source`,
                  `${titlesPrefix}.types.allowed`,
                ]}
                updateSelection={updateSelection}
                getTargetKeys={getTargetKeys}
                transferSections={sections}
                getSection={getItemSection}
                floatingLabel={false}
              />
            </AdminConsoleSectionContent>
          )}
        </FormThemeOverrideContextProvider>
      </AdminConsoleSection>
    </AdminConsoleContent>
  );
};
export default AdminAccountTransferPage;
