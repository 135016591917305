import { Button, Col, DatePicker, Form, Row, TimePicker } from 'antd';
import classNames from 'classnames';
import {
  get,
  groupBy,
  isArray,
  isBoolean,
  isEmpty,
  isNil,
  last,
  set,
} from 'lodash-es';
import moment from 'moment';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { isDateRangeEqual } from '../../common/utils/dateRangeUtils';
import { joinDateAndTime, now, toDate } from '../../common/utils/dateUtils';
import { useDateRangePresets } from '../../config/dateRangeConstants';
import { cssVariables } from '../../styles/cssVariables';
import { pxToNumber } from '../../utils/cssUtils';
import {
  DateFormatNumeric,
  timeFormatShort,
  useDateFormats,
} from '../../utils/dateFormatting';
import { makeComposedFormAccessors } from '../../utils/reactUtils';
import { FAIcon, FATypes } from '../adapters/fontAwesomeAdapters';
import { Popup, PopupContext } from '../nav/navElements';
import { FormItem } from './FormItem';
import { FormItemInputHidden } from './basicFormElements';
import { getIsRequired } from './formHelpers';
import { useFormContext } from './forms';

const CalendarIcon = ({ className, ...rest }) => (
  <FAIcon
    {...rest}
    className={classNames('DatePreviewIcon', className)}
    icon="calendar"
    type={FATypes.REGULAR}
  />
);
const ClockIcon = ({ className, ...rest }) => (
  <FAIcon
    {...rest}
    className={classNames('DatePreviewIcon', className)}
    icon="clock"
    type={FATypes.REGULAR}
  />
);

function makePlaceholder({
  placeholder,
  isRequired,
  isFloatingLabel,
  defaultPlaceholder,
}) {
  const pl = placeholder || defaultPlaceholder;
  return isRequired && !isFloatingLabel ? `${pl} *` : pl;
}

function DatePickerAdapter({
  className,
  placeholder,
  isRequired,
  isFloatingLabel,
  showIcon = true,
  ...rest
}) {
  const { numeric: dateFormatNumeric } = useDateFormats();
  return (
    <DatePicker
      className={classNames('suffix-as-prefix', className)}
      format={dateFormatNumeric}
      placeholder={makePlaceholder({
        placeholder,
        isRequired,
        isFloatingLabel,
        defaultPlaceholder: dateFormatNumeric,
      })}
      suffixIcon={showIcon ? <CalendarIcon /> : undefined}
      showTime={false}
      {...rest}
    />
  );
}

function TimePickerAdapter({
  className,
  placeholder,
  isRequired,
  isFloatingLabel,
  showIcon = true,
  ...rest
}) {
  return (
    <TimePicker
      className={classNames('suffix-as-prefix', className)}
      format={timeFormatShort}
      placeholder={makePlaceholder({
        placeholder,
        isRequired,
        isFloatingLabel,
        defaultPlaceholder: timeFormatShort,
      })}
      suffixIcon={showIcon ? <ClockIcon /> : undefined}
      {...rest}
    />
  );
}

export function FormItemDatePicker({
  showIcon,
  formItemComponentProps,
  rules,
  floatingLabel = true,
  ...rest
}) {
  const parseValue = val => (isNil(val) ? val : toDate(val));

  return (
    <FormItem
      {...rest}
      component={DatePickerAdapter}
      formItemComponentProps={{
        parseValue,
        showIcon,
        isRequired: getIsRequired(rules),
        isFloatingLabel: floatingLabel,
        ...formItemComponentProps,
      }}
      rules={rules}
      floatingLabel={floatingLabel}
    />
  );
}

export function FormItemTimePicker({
  showIcon,
  formItemComponentProps,
  rules,
  floatingLabel = true,
  ...rest
}) {
  return (
    <FormItem
      {...rest}
      component={TimePickerAdapter}
      formItemComponentProps={{
        showIcon,
        isRequired: getIsRequired(rules),
        isFloatingLabel: floatingLabel,
        ...formItemComponentProps,
      }}
      rules={rules}
      floatingLabel={floatingLabel}
    />
  );
}

function ErrorSetter({ form, setHasError, name }) {
  // This needs to be in effect, therefore a simple function cannot be used
  useEffect(() => {
    setHasError(!isEmpty(form.getFieldError(name)));
  });
  return null;
}

const addSuffixToName = (name, suffix) =>
  isArray(name)
    ? [...name.slice(0, -1), `${last(name)}${suffix}`]
    : `${name}${suffix}`;

function syncValue({ form, sourceValue, targetValue, targetName, isEqual }) {
  const shouldSync =
    (sourceValue && !targetValue) ||
    (!sourceValue && targetValue) ||
    (sourceValue && targetValue && !isEqual(sourceValue, targetValue));

  if (shouldSync) {
    const newValues = {};
    set(newValues, targetName, sourceValue);
    form.setFieldsValue(newValues);
  }
}

export function FormItemDateTimePicker({
  dateProps,
  timeProps,
  rules,
  name,
  disabled,
  expandSize,
  normalize,
  floatingLabel = false,
  ...rest
}) {
  const { formInstance: form, values, forceUpdate } = useFormContext();
  const { separateRules = [], groupRules = [] } = groupBy(rules || [], rule =>
    isBoolean(rule.required) ? 'separateRules' : 'groupRules'
  );

  const [hasError, setHasError] = useState(false);
  const errorProps = hasError ? { validateStatus: 'error' } : {};

  const [pickerOpen, setPickerOpen] = useState();

  const updateComposedField = useCallback(() => {
    const date = form.getFieldValue(addSuffixToName(name, '-date'));
    const time = form.getFieldValue(addSuffixToName(name, '-time'));
    const dateTime = date && time ? joinDateAndTime(date, time) : undefined;

    if (dateTime) {
      // Virtual date-time field will be touched the first time it has value
      form.setFields([{ name, touched: true }]);
    }
    const newValues = {};
    set(newValues, name, normalize ? normalize(dateTime) : dateTime);
    form.setFieldsValue(newValues);
    forceUpdate();
    if (form.isFieldTouched(name)) {
      form.validateFields([name]);
    }
  }, [forceUpdate, form, name, normalize]);

  // Synchronize the main value and partial values (for example if set from outside)
  const value = get(values, name);
  useEffect(() => {
    const dateValue = form.getFieldValue(addSuffixToName(name, '-date'));
    const timeValue = form.getFieldValue(addSuffixToName(name, '-time'));

    // If entering is in progress (one of the two subfields is entered), we don't want to sync
    const ignoreSync = (dateValue && !timeValue) || (!dateValue && timeValue);
    if (ignoreSync) {
      return;
    }
    syncValue({
      form,
      sourceValue: value,
      targetValue: dateValue,
      targetName: addSuffixToName(name, '-date'),
      isEqual: (m1, m2) => m1.isSame(m2, 'day'),
    });
    syncValue({
      form,
      sourceValue: value,
      targetValue: timeValue,
      targetName: addSuffixToName(name, '-time'),
      isEqual: (m1, m2) => {
        const time1 = joinDateAndTime(moment(), m1);
        const time2 = joinDateAndTime(moment(), m2);
        return time1.isSame(time2, 'minute');
      },
    });
  }, [form, name, value]);

  const setNow = useCallback(() => {
    const dateTime = now();
    const newValues = {};
    set(newValues, name, normalize ? normalize(dateTime) : dateTime);
    set(newValues, addSuffixToName(name, '-date'), dateTime);
    set(newValues, addSuffixToName(name, '-time'), dateTime);
    form.setFieldsValue(newValues);
    forceUpdate();
    setPickerOpen(false);
  }, [forceUpdate, form, name, normalize]);

  const dateFormItemComponentProps = {
    onChange: updateComposedField,
    showToday: false,
    ...dateProps?.formItemComponentProps,
    renderExtraFooter: () => (
      <>
        {dateProps?.showNow !== false && (
          <div className="DatePicker-FooterButton" onClick={setNow}>
            <FormattedMessage id="date.now" />
          </div>
        )}
        {dateProps?.formItemComponentProps?.renderExtraFooter &&
          dateProps.formItemComponentProps.renderExtraFooter({
            pickerOpen,
            setPickerOpen,
          })}
      </>
    ),
    open: pickerOpen,
    onOpenChange: setPickerOpen,
  };

  return (
    <div
      className={classNames('DateTimePicker', { 'expand-size': expandSize })}
    >
      <div className="Col-DatePicker">
        <FormItemDatePicker
          name={addSuffixToName(name, '-date')}
          floatingLabel={floatingLabel}
          rules={separateRules}
          disabled={disabled}
          {...dateProps}
          formItemComponentProps={dateFormItemComponentProps}
          {...errorProps}
        />
        <FormItemInputHidden
          name={name}
          rules={groupRules}
          className="DateTimePicker-ValidationGroup"
          {...rest}
        />
        <Form.Item noStyle shouldUpdate>
          {fm => (
            <ErrorSetter form={fm} name={name} setHasError={setHasError} />
          )}
        </Form.Item>
      </div>
      <div className="Col-TimePicker">
        <FormItemTimePicker
          name={addSuffixToName(name, '-time')}
          floatingLabel={floatingLabel}
          rules={separateRules}
          disabled={disabled}
          {...timeProps}
          formItemComponentProps={{
            onChange: updateComposedField,
            ...timeProps?.formItemComponentProps,
          }}
          {...errorProps}
        />
      </div>
    </div>
  );
}

function DateRangePreview({ value, placeholder }) {
  const { from, to } = value || {};
  const isValueEmpty = !from && !to;

  return (
    <Row
      align="middle"
      style={{ display: 'inline-flex' }}
      className="flex-nowrap DateRange-Preview"
    >
      <Col>
        <CalendarIcon />
      </Col>
      {isValueEmpty ? (
        <Col>{placeholder}</Col>
      ) : (
        <>
          <Col>
            <DateFormatNumeric value={from} />
          </Col>
          <Col style={{ fontWeight: 'normal' }}>
            <FormattedMessage id="filters.labels.rangeJoin" />
          </Col>
          <Col>
            <DateFormatNumeric value={to} />
          </Col>
        </>
      )}
    </Row>
  );
}

const DEFAULT_POPUP_PROPS = {};

const ColBottomRow = ({ children, ...rest }) => (
  <Col {...rest}>
    <Form.Item>{children}</Form.Item>
  </Col>
);

function DateRangePresetButton({ name, selected, value, labelId, setValue }) {
  const { setOpen } = useContext(PopupContext);

  const onClick = () => {
    setValue(name, value);
    setOpen(false);
  };

  return (
    <Button
      className="DateRange-PresetButton"
      type={selected ? 'primary' : 'default'}
      onClick={onClick}
    >
      <FormattedMessage id={labelId} />
    </Button>
  );
}
function PopupAutocloser({ value }) {
  const { setOpen } = useContext(PopupContext);
  const from = value?.from;
  const to = value?.to;

  const prevTo = useRef(to);
  useEffect(() => {
    // Close if "to" changed and full range is entered
    if (to && (!prevTo.current || !to.isSame(prevTo.current, 'day')) && from) {
      setOpen(false);
    }
    prevTo.current = to;
  }, [from, setOpen, to]);

  return null;
}

function DateRangePopup({
  popupComponent: PopupC = Popup,
  popupProps,
  value,
  onChange,
  placeholder,
  fromProps: fromPropsOuter,
  toProps: toPropsOuter,
}) {
  const {
    from: fromProps,
    to: toProps,
    namedRange,
  } = makeComposedFormAccessors({
    value,
    onChange,
    fields: ['from', 'to', 'namedRange'],
  });
  const { presetsOrdered, presetsByKey } = useDateRangePresets();

  // Clear `namedRange` when the value is changed to anything that doesn't match the range
  useEffect(() => {
    const preset = presetsByKey[namedRange?.value];
    if (preset && !isDateRangeEqual(preset, value)) {
      namedRange.onChange(null);
    }
  }, [namedRange, presetsByKey, value]);

  const setPresetValue = (name, val) => {
    onChange(
      val && {
        namedRange: name,
        ...val,
      }
    );
  };

  return (
    <PopupC
      label={<DateRangePreview value={value} placeholder={placeholder} />}
      {...DEFAULT_POPUP_PROPS}
      {...popupProps}
    >
      <PopupAutocloser value={value} />
      <Row
        align="bottom"
        className="DateRange-Container no-margin-form-items"
        gutter={pxToNumber(cssVariables.spaceSm)}
      >
        <ColBottomRow>
          <CalendarIcon />
        </ColBottomRow>
        <Col className="Flex1">
          <FormItemDatePicker
            labelId="filters.labels.from"
            formItemComponentProps={{ ...fromProps, ...fromPropsOuter }}
            showIcon={false}
            floatingLabel={false}
            labelCol={{ offset: 0, span: 24 }}
            wrapperCol={{ offset: 0, span: 24 }}
          />
        </Col>
        <ColBottomRow style={{ textAlign: 'center' }}>
          <span className="DateRange-Dash">-</span>
        </ColBottomRow>
        <ColBottomRow>
          <CalendarIcon />
        </ColBottomRow>
        <Col className="Flex1">
          <FormItemDatePicker
            labelId="filters.labels.to"
            formItemComponentProps={{ ...toProps, ...toPropsOuter }}
            showIcon={false}
            floatingLabel={false}
            labelCol={{ offset: 0, span: 24 }}
            wrapperCol={{ offset: 0, span: 24 }}
          />
        </Col>
      </Row>
      {presetsOrdered.map(({ name, labelId, value: val }) => (
        <DateRangePresetButton
          key={name}
          name={name}
          labelId={labelId}
          value={val}
          setValue={setPresetValue}
          selected={namedRange.value === name}
        />
      ))}
    </PopupC>
  );
}

export function FormItemDateRangePopup({
  popupComponent,
  popupProps,
  formItemComponentProps,
  fromProps,
  toProps,
  ...rest
}) {
  return (
    <FormItem
      {...rest}
      floatingLabel={false}
      component={DateRangePopup}
      formItemComponentProps={{
        ...formItemComponentProps,
        popupComponent,
        popupProps,
        fromProps,
        toProps,
      }}
    />
  );
}
