import { values as objValues, pickBy } from 'lodash-es';

import {
  fromServerDateOptional,
  toServerDateOptional,
} from '../../common/utils/dateUtils';
import { isFormValuePresent } from '../../components/forms/formHelpers';
import { makeSort } from '../common/sort';

export function monitorFiltersFormToGraphQL(formValues) {
  return {
    dateFrom: toServerDateOptional(formValues?.dateRange?.from),
    dateTo: toServerDateOptional(formValues?.dateRange?.to),
    accountNumber: formValues?.accountNumber,
    shipmentStatus: formValues?.shipmentStatus,
    ...formValues.shipmentDetails,
  };
}

export function monitorSortFormToGraphQL(sort) {
  if (!sort) {
    return null;
  }
  if (sort.name === 'dateRange') {
    return makeSort('date', sort.order);
  }
  return makeSort(sort.name, sort.order);
}

export function filterTemplateFormToGraphQL(formValues) {
  return {
    name: formValues.name,
    date: formValues?.dateRange && {
      dateFrom: toServerDateOptional(formValues.dateRange.from),
      dateTo: toServerDateOptional(formValues.dateRange.to),
      namedRange: formValues.dateRange.namedRange,
    },
    accountNumber: formValues?.accountNumber,
    shipmentStatus: formValues?.shipmentStatus,
    ...formValues.shipmentDetails,
  };
}

export function filterTemplateGraphQLToForm(values, presets) {
  const shipmentDetails = pickBy(
    {
      destinationContactName: values?.destinationContactName,
      destinationCity: values?.destinationCity,
      destinationCountry: values?.destinationCountry,
    },
    isFormValuePresent
  );
  let dateRange;
  if (values?.date) {
    if (values.date.namedRange) {
      dateRange = {
        namedRange: values.date.namedRange,
        ...presets[values.date.namedRange],
      };
    } else {
      dateRange = {
        from: fromServerDateOptional(values.date.dateFrom),
        to: fromServerDateOptional(values.date.dateTo),
        namedRange: null,
      };
    }
  }
  return {
    id: values.id,
    name: values.name,
    dateRange,
    accountNumber: values?.accountNumber,
    shipmentStatus: values?.shipmentStatus,
    shipmentDetails:
      objValues(shipmentDetails).length > 0 ? shipmentDetails : null,
  };
}
