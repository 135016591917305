import classNames from 'classnames';
import { noop, sumBy } from 'lodash-es';
import { useLayoutEffect, useRef, useState } from 'react';

function computeTotalChildrenHeight(parentRef) {
  return parentRef.current
    ? sumBy([...parentRef.current.children], 'scrollHeight')
    : 0;
}

function useChildrenScrollHeight({ parentRef, dynamicContent }) {
  const [result, setResult] = useState(computeTotalChildrenHeight(parentRef));

  useLayoutEffect(() => {
    if (!dynamicContent) {
      setResult(computeTotalChildrenHeight(parentRef));
      return noop;
    }

    const interval = setInterval(() => {
      setResult(computeTotalChildrenHeight(parentRef));
    }, 100);
    return () => clearInterval(interval);
  }, [dynamicContent, parentRef]);

  return result;
}

export default function Collapsible({
  children,
  expanded,
  style,
  dynamicContent,
  className,
  ...rest
}) {
  const ref = useRef();
  const totalHeight = useChildrenScrollHeight({
    parentRef: ref,
    dynamicContent,
  });

  return (
    <div
      className={classNames('AnimatedCollapsible', className)}
      style={{
        ...style,
        height: expanded ? totalHeight : 0,
      }}
      ref={ref}
      {...rest}
    >
      {children}
    </div>
  );
}
