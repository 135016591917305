import { Col, Row } from 'antd';
import { FormattedMessage } from 'react-intl';

import { sanitizeHtml } from '../common/utils/htmlUtils';
import { HeaderLogo } from '../components/Logo';
import { CustomLoaderButton } from '../components/buttons/buttons';
import { FormItemInlineCheckbox } from '../components/forms/checkable';
import { assertTrueRule } from '../components/forms/formValidationRules';
import { NoLabelForm, SubmitButton } from '../components/forms/forms';
import {
  Scrollbars,
  VerticalTrackTypes,
} from '../components/layout/Scrollbars';
import {
  Flex1,
  FlexCol,
  ThreeLines,
} from '../components/layout/layoutElements';

export default function TermsOfServicePage({ onAccept, onCancel, content }) {
  const cleanHtml = sanitizeHtml(content);

  return (
    <div id="TermsOfService__Page">
      <FlexCol className="TermsOfService__Container">
        <HeaderLogo size="sm" />
        <Flex1>
          <Scrollbars vertical={VerticalTrackTypes.INSIDE}>
            <div className="TermsOfService__Content">
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: cleanHtml }}
              />
              <p>
                <FormattedMessage id="termsOfService.suffix" />
              </p>
            </div>
          </Scrollbars>
        </Flex1>
        <ThreeLines />
        <NoLabelForm onSubmit={onAccept}>
          <Row justify="space-between" align="middle">
            <Col className="TermsOfService__Checkbox no-margin-form-items">
              <FormItemInlineCheckbox
                labelId="termsOfService.accept"
                name="accept"
                rules={[assertTrueRule]}
              />
            </Col>
            <Col className="TermsOfService__Buttons">
              <CustomLoaderButton size="middle" onClick={onCancel}>
                <FormattedMessage id="buttons.cancel" />
              </CustomLoaderButton>
              <SubmitButton size="middle" type="primary">
                <FormattedMessage id="buttons.continue" />
              </SubmitButton>
            </Col>
          </Row>
        </NoLabelForm>
      </FlexCol>
    </div>
  );
}
