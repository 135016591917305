/* eslint-disable no-underscore-dangle */
import { identity } from 'lodash-es';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { adminReducer } from './redux/admin';
import { authReducer } from './redux/auth';
import { dialogsReducer } from './redux/dialogs';
import { settingsReducer } from './redux/settings';
import { shipmentDetailReducer } from './redux/shipmentDetail';

export function makeStore(apolloClient) {
  const store = createStore(
    combineReducers({
      auth: authReducer,
      settings: settingsReducer,
      dialogs: dialogsReducer,
      shipmentDetail: shipmentDetailReducer,
      admin: adminReducer,
    }),
    {},
    compose(
      applyMiddleware(thunk.withExtraArgument({ apolloClient })),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : identity
    )
  );
  return store;
}
