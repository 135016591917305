import { compact, mapValues } from 'lodash-es';

import { now } from '../common/utils/dateUtils';

export function useDateRangePresets({ extended } = {}) {
  const presets = {
    TODAY: {
      name: 'TODAY',
      labelId: 'dateRange.today',
      value: { from: now().startOf('day'), to: now().endOf('day') },
    },
    YESTERDAY: {
      name: 'YESTERDAY',
      labelId: 'dateRange.yesterday',
      value: {
        from: now().subtract(1, 'day').startOf('day'),
        to: now().subtract(1, 'day').endOf('day'),
      },
    },
    THIS_MONTH: {
      name: 'THIS_MONTH',
      labelId: 'dateRange.thisMonth',
      value: {
        from: now().startOf('month'),
        to: now().endOf('month'),
      },
    },
    LAST_MONTH: {
      name: 'LAST_MONTH',
      labelId: 'dateRange.lastMonth',
      value: {
        from: now().subtract(1, 'month').startOf('month'),
        to: now().subtract(1, 'month').endOf('month'),
      },
    },
    YEAR_TO_DATE: {
      name: 'YEAR_TO_DATE',
      labelId: 'dateRange.yearToDate',
      value: {
        from: now().startOf('year'),
        to: now().endOf('day'),
      },
    },
    LAST_YEAR: {
      name: 'LAST_YEAR',
      labelId: 'dateRange.lastYear',
      value: {
        from: now().subtract(1, 'year').startOf('year'),
        to: now().subtract(1, 'year').endOf('year'),
      },
    },
  };

  const presetsOrdered = compact([
    presets.TODAY,
    presets.YESTERDAY,
    presets.THIS_MONTH,
    presets.LAST_MONTH,
    extended && presets.YEAR_TO_DATE,
    extended && presets.LAST_YEAR,
  ]);

  const presetsByKey = mapValues(presets, info => info.value);

  const labelIdsByKey = mapValues(presets, info => info.labelId);

  return { presetsOrdered, presetsByKey, labelIdsByKey };
}
