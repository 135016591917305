import { useQuery } from '@apollo/client';
import { get } from 'lodash-es';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { normalizeAccount } from '../../app/data/accountConversions';
import { AccountType } from '../../app/enums/AccountType';
import { ShipmentMode } from '../../app/enums/ShipmentMode';
import { USER_QUERY } from '../../app/graphql/authQueries';
import { getLoggedIn, logoutUser } from '../../app/redux/auth';
import {
  extractGraphqlEntity,
  isGraphqlEntityEmpty,
} from '../../common/utils/graphqlUtils';
import { useCachedProp } from '../../common/utils/hookUtils';
import { useFlashMessageContext } from '../../components/dialogs/FlashMessageProvider';
import { useLoginPageRedirectRoute } from '../../utils/redirectUtils';

function isCustomerAccount(account) {
  return account.type === AccountType.CUSTOMER;
}

function isShipmentSupported(account) {
  return account.shipmentMode !== ShipmentMode.UNKNOWN;
}

export function useAccounts() {
  const { data, loading, error } = useQuery(USER_QUERY);
  const user = data && extractGraphqlEntity(data);
  const rawAccounts = [
    ...(user?.accounts ?? []),
    ...(user?.accountGroups ?? []).flatMap(gr => gr.accounts ?? []),
    ...(user?.partnerGroups ?? []).flatMap(gr => gr.accounts ?? []),
  ];
  const customerAccounts = rawAccounts
    .filter(isCustomerAccount)
    .filter(isShipmentSupported)
    .map(normalizeAccount);
  return { customerAccounts, loading, error };
}

export function useAccount(accountNumber) {
  const { customerAccounts } = useAccounts();
  const account = useCachedProp(
    customerAccounts &&
      customerAccounts.find(acc => acc.number === accountNumber)
  );

  return account;
}

export function useSettings() {
  const { data, loading } = useQuery(USER_QUERY);
  const settings = data && extractGraphqlEntity(data)?.settings;
  return { settings, loading };
}

/**
 * @returns {boolean} Current local state of login
 */
export function useRedirectWhenLoggedOutLocally() {
  const loggedIn = useSelector(getLoggedIn);
  const { push, location } = useHistory();
  const redirectUrl = useLoginPageRedirectRoute();

  // We want to make a check after each path change in case a different
  // redirect in the same rendering-round takes precedence
  const path = location.pathname;

  useEffect(() => {
    // Redirect to login page if not logged in
    if (!loggedIn) {
      push(redirectUrl);
    }
  }, [loggedIn, push, redirectUrl, path]);

  return loggedIn;
}

/**
 * WARNING: should only be called when logged in locally, no internal checks are made
 */
export function useAutoLogoutWhenLoggedOutRemotely() {
  const { data, loading, error } = useQuery(USER_QUERY);
  const dispatch = useDispatch();
  const { errorMessage } = useFlashMessageContext();

  const loggedIn = loading || !isGraphqlEntityEmpty(data);

  useEffect(() => {
    // Logout locally if login is invalid for some reason
    if (!loggedIn) {
      if (error) {
        errorMessage({ contentId: 'error.currentUser' });
      }
      dispatch(logoutUser());
    }
  }, [data, dispatch, error, errorMessage, loading, loggedIn]);

  return loggedIn;
}

export function useSilentLogout() {
  const dispatch = useDispatch();
  const logout = useCallback(() => {
    dispatch(logoutUser());
  }, [dispatch]);
  return logout;
}

export function useLogoutHandler() {
  const dispatch = useDispatch();
  const { errorMessage } = useFlashMessageContext();
  // We want to logout regardless on the server call status, therefore the action
  // is also dispatched in an error handler
  const onLogoutSuccess = useCallback(
    result => {
      const { success, code } = get(result, 'data.logout');
      if (!success) {
        throw new Error(code);
      }
      dispatch(logoutUser());
    },
    [dispatch]
  );
  const onLogoutError = useCallback(
    e => {
      dispatch(logoutUser());
      errorMessage(e);
    },
    [dispatch, errorMessage]
  );

  return { onLogoutSuccess, onLogoutError };
}
