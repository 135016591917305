import { Col, Row, Tooltip } from 'antd';
import classNames from 'classnames';
import {
  every,
  find,
  flatten,
  isObject,
  keys,
  noop,
  omit,
  property,
  some,
  values,
} from 'lodash-es';
import { Fragment, useCallback, useContext, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generatePath, useHistory } from 'react-router';

import { shipmentConversions } from '../../app/data/shipmentConversions';
import { ShipmentStage } from '../../app/enums/ShipmentStage';
import routes from '../../app/routes';
import {
  formatAccount,
  formatLocationShort,
} from '../../common/utils/formatUtils';
import { useMountAndUpdateEffect } from '../../common/utils/hookUtils';
import { useNodeInnerText } from '../../common/utils/stringUtils';
import Whitespace from '../../components/Whitespace';
import { FAIcon } from '../../components/adapters/fontAwesomeAdapters';
import {
  AnimatedList,
  InfiniteScrollList,
} from '../../components/data/dataRenderers';
import CustomGPSIcon from '../../components/graphics/CustomGPSIcon';
import Accordion from '../../components/layout/Accordion';
import {
  Scrollbars,
  VerticalTrackTypes,
} from '../../components/layout/Scrollbars';
import { Flex1 } from '../../components/layout/layoutElements';
import { useResponsiveQueries } from '../../components/responsive/responsiveQueries';
import { BlockLink } from '../../components/typography';
import {
  CopyToClipboardButton,
  CopyToClipboardHoverArea,
} from '../../components/widgets/CopyToClipboard';
import { useAccounts } from '../../hooks/data/auth';
import { cssVariables } from '../../styles/cssVariables';
import { pxToNumber } from '../../utils/cssUtils';
import { DateTimeFormatMedium } from '../../utils/dateFormatting';
import { cssTransitionClassNames } from '../../utils/reactTransitionUtils';
import { useScrollToThis } from '../../utils/scrollUtils';
import { formatDateTimeSchedule } from '../shipmentDetail/shipmentFormats';
import MonitorNoData from './MonitorNoData';
import { DeleteShipmentButton } from './monitorButtons';
import { MonitorContext, getLocationModeInfo } from './monitorContexts';

const MONITOR_ITEM_SCALE_TIMEOUT = 800;
const MONITOR_ITEM_SHRINK_TIMEOUT = 600;
const MONITOR_ITEM_SCROLL_TIMEOUT = 300;

function Item({ className, jobNumber, item, children }) {
  const { push } = useHistory();
  const { activeJob, setActiveJob, locationMode } = useContext(MonitorContext);
  const active = activeJob === jobNumber;
  const ref = useScrollToThis({ active, skipIfInScrollView: true });
  const { stage } = item;
  const media = useResponsiveQueries();

  const onClick = useCallback(() => {
    setActiveJob(jobNumber);
    if (stage === ShipmentStage.SAVED) {
      push(generatePath(routes.releaseShipment, { jobNumber }));
    } else {
      push(generatePath(routes.shipmentDetail, { jobNumber }));
    }
  }, [jobNumber, push, setActiveJob, stage]);

  const location = getLocationModeInfo(locationMode);

  const { customerAccounts } = useAccounts();
  const account = find(
    customerAccounts,
    acc => acc.number === item?.metadata?.qolAccount?.number
  );
  const isPartner = account?.partner;

  return (
    <BlockLink
      ref={ref}
      component="div"
      className={classNames(
        'MonitorJobList__Item',
        { active, 'MonitorJobList__Item--HasTopBar': isPartner },
        className
      )}
      onClick={onClick}
      data-subject="job-list"
      data-role="item"
    >
      <div className="MonitorJobList__ItemContent">
        {children({ location, media })}
      </div>
      <div>
        {isPartner && (
          <div className="MonitorJobList__TopBar">
            <FormattedMessage id="job.partnerAccess" />:<Whitespace />
            {account.name}
          </div>
        )}
        {item.stage === ShipmentStage.SAVED && (
          <DeleteShipmentButton jobNumber={jobNumber} />
        )}
      </div>
    </BlockLink>
  );
}

function Column({
  className,
  labelId,
  children,
  noTitle,
  customTitle,
  allowCopy,
  copyTooltipId,
}) {
  const { ref, text } = useNodeInnerText();
  let title = text;
  if (customTitle) {
    title = customTitle;
  }
  if (noTitle) {
    title = undefined;
  }

  return (
    <CopyToClipboardHoverArea
      className={classNames('MonitorJobList__Column', className)}
    >
      <div className="MonitorJobList__Label">
        <FormattedMessage id={labelId} />
        {allowCopy && (
          <CopyToClipboardButton
            tooltipId={copyTooltipId}
            className="icon-11"
            getContent={() => text}
          />
        )}
      </div>
      <div ref={ref} title={title} className="MonitorJobList__Content">
        {children}
      </div>
    </CopyToClipboardHoverArea>
  );
}

const DateTimeFormat = props => (
  <DateTimeFormatMedium {...props} separator=" | " />
);

function ActivityIndicatorTooltip({ tooltipId, active, children }) {
  const intl = useIntl();
  const tooltip =
    tooltipId &&
    intl.formatMessage(
      { id: tooltipId },
      {
        suffix: intl.formatMessage({
          id: active
            ? 'job.activityIndicator.suffix.active'
            : 'job.activityIndicator.suffix.inactive',
        }),
      }
    );

  return tooltip ? <Tooltip title={tooltip}>{children}</Tooltip> : children;
}

function ActivityIndicator({
  className,
  indicator,
  icon,
  tooltipId,
  children,
}) {
  if (!indicator) {
    return null;
  }

  return (
    <ActivityIndicatorTooltip tooltipId={tooltipId} active={indicator.active}>
      <div
        className={classNames(
          'ActivityIndicators-indicator',
          {
            highlight: indicator.active,
          },
          className
        )}
      >
        {icon && <FAIcon icon={icon} className="ActivityIndicators-icon" />}
        {children}
      </div>
    </ActivityIndicatorTooltip>
  );
}

function ActivityIndicators({ indicators }) {
  const isAnyAvailable = some(values(indicators), isObject);
  if (!isAnyAvailable) {
    return (
      <div>
        <FormattedMessage id="labels.na" />
      </div>
    );
  }
  return (
    <div className="ActivityIndicators">
      <ActivityIndicator
        icon="truck"
        indicator={indicators.driver}
        tooltipId="job.activityIndicator.onWay"
      />
      <ActivityIndicator
        icon="plane"
        indicator={indicators.flight}
        tooltipId="job.activityIndicator.flightTracker"
      />
      <ActivityIndicator
        indicator={indicators.device}
        tooltipId="job.activityIndicator.gpsDevice"
      >
        <CustomGPSIcon size={23} />
      </ActivityIndicator>
      <ActivityIndicator
        className="orange"
        icon="exclamation-triangle"
        indicator={indicators.serviceUpdate}
        tooltipId="job.activityIndicator.revisedEta"
      />
    </div>
  );
}

function renderRowItem(item) {
  const data = shipmentConversions.graphqlToDetail(item);
  return (
    <Item
      jobNumber={data.jobNumber}
      className="MonitorJobList__Item--RowLayout"
      item={item}
    >
      {({ location: { labelId: locationLabelId, getJobLocation } }) => (
        <>
          <Column
            labelId="job.jobNumber"
            allowCopy
            copyTooltipId="job.jobNumber.copy"
          >
            {data.jobNumber}
          </Column>
          <Column labelId={locationLabelId} className="wide-column">
            {formatLocationShort(getJobLocation(item))}
          </Column>
          <Column labelId="job.status" className="hide-md-and-smaller">
            {data.status}
          </Column>
          <Column labelId="job.readyDate" className="hide-sm-and-smaller">
            {formatDateTimeSchedule(data.pickupDateTime, DateTimeFormat)}
          </Column>
          <Column labelId="job.deadlineDate" className="hide-sm-and-smaller">
            {formatDateTimeSchedule(data.deliveryDateTime, DateTimeFormat)}
          </Column>
          <Column labelId="job.accountNumber" className="hide-md-and-smaller">
            {formatAccount(data?.metadata?.account)}
          </Column>
          <Column
            labelId="job.activityIndicators"
            className="hide-md-and-smaller"
            noTitle
          >
            <ActivityIndicators indicators={data.indicators} />
          </Column>
        </>
      )}
    </Item>
  );
}

function renderCardItem(item) {
  const data = shipmentConversions.graphqlToDetail(item);
  const references = data.shipperReference.split(/\n/g);

  return (
    <Item
      jobNumber={data.jobNumber}
      className="MonitorJobList__Item--CardLayout"
      item={item}
    >
      {({ location: { labelId: locationLabelId, getJobLocation }, media }) => (
        <>
          {(media.xs || media.sm || media.md) && (
            <Row gutter={pxToNumber(cssVariables.spaceNorm)}>
              <Col>
                <Column labelId={locationLabelId}>
                  {formatLocationShort(getJobLocation(item))}
                </Column>
              </Col>
              <Col>
                <Column
                  labelId="job.jobNumber"
                  allowCopy
                  copyTooltipId="job.jobNumber.copy"
                >
                  {data.jobNumber}
                </Column>
                <Column
                  labelId="shipmentDetail.referenceNumber.full"
                  customTitle={data.shipperReference}
                >
                  {references[0]}
                  {references.length > 1 && '…'}
                </Column>
              </Col>
              <Col className="hide-sm-and-smaller">
                <Column labelId="job.readyDate">
                  {formatDateTimeSchedule(data.pickupDateTime, DateTimeFormat)}
                </Column>
                <Column labelId="job.deadlineDate">
                  {formatDateTimeSchedule(
                    data.deliveryDateTime,
                    DateTimeFormat
                  )}
                </Column>
              </Col>
            </Row>
          )}
          {(media.lg || media.xl || media.xxl) && (
            <>
              <Column labelId={locationLabelId}>
                {formatLocationShort(getJobLocation(item))}
              </Column>
              <Column labelId="job.activityIndicators" noTitle>
                <ActivityIndicators indicators={data.indicators} />
              </Column>
              <Column labelId="job.status">{data.status}</Column>
              <Column
                labelId="job.jobNumber"
                allowCopy
                copyTooltipId="job.jobNumber.copy"
              >
                {data.jobNumber}
              </Column>
              <Column labelId="job.accountNumber">
                {formatAccount(data?.metadata?.account)}
              </Column>
              <Column
                labelId="shipmentDetail.referenceNumber.full"
                customTitle={data.shipperReference}
              >
                {references[0]}
                {references.length > 1 && '…'}
              </Column>
              <Column labelId="job.readyDate">
                {formatDateTimeSchedule(data.pickupDateTime, DateTimeFormat)}
              </Column>
              <Column labelId="job.deadlineDate">
                {formatDateTimeSchedule(data.deliveryDateTime, DateTimeFormat)}
              </Column>
            </>
          )}
        </>
      )}
    </Item>
  );
}

export const MonitorJobListRenderers = {
  ROW: renderRowItem,
  CARD: renderCardItem,
};

function useActiveJobDetails() {
  const { data, activeJob } = useContext(MonitorContext);

  const activeItem = find(
    flatten(values(data).map(property('rows'))),
    shipment => shipment.jobNumber === activeJob
  );
  const lastActiveItem = useRef();
  const stageChanged =
    activeItem?.jobNumber === lastActiveItem.current?.jobNumber &&
    activeItem?.stage !== lastActiveItem.current?.stage &&
    !!lastActiveItem.current?.stage;
  // If job changes stage, it may disappear for a moment if different lists load at different speeds
  useEffect(() => {
    if (activeItem) {
      lastActiveItem.current = activeItem;
    }
  });

  return { activeItem, stageChanged };
}

function useActiveJobSectionAutoOpen({ setActiveSection, stageChanged }) {
  const { data, activeJob } = useContext(MonitorContext);

  const computedActiveSection = find(keys(data), key =>
    some(data[key]?.rows || [], shipment => shipment.jobNumber === activeJob)
  );

  useMountAndUpdateEffect(
    {
      onMount: () => {
        if (computedActiveSection) {
          setActiveSection(computedActiveSection);
        }
      },
      onUpdate: ([, prevActiveJob]) => {
        // Never autoclose
        if (!computedActiveSection) {
          return noop;
        }
        // The same job is active but its section was changed - we will autoopen the section in an animated way
        if (prevActiveJob === activeJob && stageChanged) {
          const timeout = setTimeout(() => {
            setActiveSection(computedActiveSection);
          }, MONITOR_ITEM_SCALE_TIMEOUT + MONITOR_ITEM_SHRINK_TIMEOUT);
          return () => clearTimeout(timeout);
        }
        // Active job has changed - we need to autoopen the section
        if (prevActiveJob !== activeJob) {
          setActiveSection(computedActiveSection);
          return noop;
        }
        return noop;
      },
    },
    [computedActiveSection, activeJob]
  );
}

const GROUPS = ['saved', 'live', 'delivered'];
const RENDER_EMPTY = () => <MonitorNoData />;

function GroupList({
  groupData,
  className,
  rowKey,
  isOpen,
  getTransitionProps,
  ...rest
}) {
  return (
    <InfiniteScrollList
      {...omit(groupData, ['totalRows'])}
      render={({ rows }) => (
        <AnimatedList
          dataSource={rows}
          rowKey={rowKey}
          className={classNames('MonitorJobList', className)}
          renderEmpty={RENDER_EMPTY}
          disabled={!isOpen}
          transitionProps={row => {
            const transitionProps = getTransitionProps?.(row);
            if (transitionProps) {
              return transitionProps;
            }
            return row.stage === ShipmentStage.SAVED
              ? {
                  classNames: cssTransitionClassNames('monitor-item-delete', {
                    appear: false,
                    enter: false,
                    exit: true,
                  }),
                  timeout:
                    MONITOR_ITEM_SHRINK_TIMEOUT + MONITOR_ITEM_SCALE_TIMEOUT,
                }
              : { timeout: 0 };
          }}
          {...rest}
        />
      )}
    />
  );
}

function FixedHeightLayoutWrapper({ children }) {
  return (
    <Flex1>
      <Scrollbars vertical={VerticalTrackTypes.OUTSIDE}>{children}</Scrollbars>
    </Flex1>
  );
}

function SingleGroupMonitorJobList({
  data,
  className,
  rowKey,
  fixedHeightLayout,
  ...rest
}) {
  const Wrapper = fixedHeightLayout ? FixedHeightLayoutWrapper : Fragment;
  return (
    <Wrapper>
      <GroupList
        groupData={data.all}
        className={className}
        rowKey={rowKey}
        isOpen
        {...rest}
      />
    </Wrapper>
  );
}

function GroupedMonitorJobList({
  data,
  className,
  rowKey,
  fixedHeightLayout,
  activeSection,
  setActiveSection,
  ...rest
}) {
  const { activeItem, stageChanged } = useActiveJobDetails();
  useActiveJobSectionAutoOpen({ setActiveSection, stageChanged });

  return (
    <Accordion
      className="MonitorJobsAccordion"
      value={activeSection}
      setValue={setActiveSection}
      data={GROUPS.map(key => ({
        name: key,
        title: (
          <>
            <FormattedMessage id={`monitor.stage.${key}`} /> (
            {data[key].totalRows})
          </>
        ),
        content: (
          <GroupList
            groupData={data[key]}
            className={className}
            rowKey={rowKey}
            isOpen={activeSection === key}
            getTransitionProps={row =>
              row.jobNumber === activeItem?.jobNumber &&
              stageChanged && {
                classNames: cssTransitionClassNames(
                  'monitor-item-change-stage',
                  {
                    appear: false,
                    enter: true,
                    exit: false,
                  }
                ),
                timeout:
                  MONITOR_ITEM_SCALE_TIMEOUT * 2 +
                  MONITOR_ITEM_SHRINK_TIMEOUT +
                  MONITOR_ITEM_SCROLL_TIMEOUT,
              }
            }
            {...rest}
          />
        ),
      }))}
      isInFlexContainer={fixedHeightLayout}
      fullHeight={fixedHeightLayout}
      collapsibleProps={{ dynamicContent: true }}
    />
  );
}

function MonitorJobList({
  className,
  rowKey = 'jobNumber',
  fixedHeightLayout,
  ...rest
}) {
  const { data, activeSection, setActiveSection } = useContext(MonitorContext);

  return every(GROUPS, gr => !!data[gr]) ? (
    <GroupedMonitorJobList
      data={data}
      className={className}
      rowKey={rowKey}
      fixedHeightLayout={fixedHeightLayout}
      activeSection={activeSection}
      setActiveSection={setActiveSection}
      {...rest}
    />
  ) : (
    <SingleGroupMonitorJobList
      data={data}
      className={className}
      rowKey={rowKey}
      fixedHeightLayout={fixedHeightLayout}
      {...rest}
    />
  );
}

export default MonitorJobList;
