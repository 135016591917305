import classNames from 'classnames';
import { noop } from 'lodash-es';
import { FormattedMessage, useIntl } from 'react-intl';

import { FAIcon } from '../adapters/fontAwesomeAdapters';

export default function IconButton({
  className,
  disabled,
  active,
  icon,
  type,
  label,
  labelId,
  title,
  titleId,
  children,
  onClick,
  style,
  size,
  ...rest
}) {
  const intl = useIntl();

  return (
    <div
      className={classNames(
        'IconButton',
        {
          active,
          disabled,
          'IconButton-HasLabel': label || labelId || children,
        },
        size && `size-${size}`,
        className
      )}
      onClick={disabled ? noop : onClick}
      style={style}
      title={titleId ? intl.formatMessage({ id: titleId }) : title}
      {...rest}
    >
      <div className="IconButton-Icon">
        <FAIcon icon={icon} type={type} />
      </div>
      <div className="IconButton-Label">
        {labelId ? <FormattedMessage id={labelId} /> : label}
        {children}
      </div>
    </div>
  );
}
