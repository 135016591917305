import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import routes from '../../app/routes';
import { timeoutPromise } from '../../common/utils/promiseUtils';
import { HeaderLogo } from '../../components/Logo';
import { ThreeDotsSpinner } from '../../components/data/Spinner';
import { FormItemFieldRegistration } from '../../components/forms/basicFormElements';
import { NoLabelForm, SubmitButton } from '../../components/forms/forms';
import { InlineLink } from '../../components/typography';
import { REDIRECT_TIMEOUT } from '../../config/constants';
import {
  AuthEnvelope,
  AuthEnvelopeInfoText,
  AuthEnvelopeLinks,
  AuthEnvelopeTitle,
  useAuthErrorMessage,
} from '../../widgets/AuthEnvelope';
import NewPasswordField from './NewPasswordField';
import PinField from './PinField';

function PasswordForm({ validatePassword, buttonTextId }) {
  return (
    <>
      <AuthEnvelopeTitle textId="newPassword.password.title" />
      <NewPasswordField validatePassword={validatePassword} />
      <SubmitButton type="primary" textId={buttonTextId} />
    </>
  );
}

function PinForm({ goBack, buttonTextId, submitted }) {
  return (
    <>
      <AuthEnvelopeTitle textId="newPassword.pin.title" />
      <AuthEnvelopeInfoText textId="newPassword.pin.infoText" />
      <PinField />
      <SubmitButton type="primary" textId={buttonTextId} disabled={submitted} />
      {!submitted && (
        <AuthEnvelopeLinks>
          <InlineLink textId="buttons.back" onClick={goBack} />
        </AuthEnvelopeLinks>
      )}
    </>
  );
}

const PASSWORD_STEP_INDEX = 0;
const PIN_STEP_INDEX = 1;

function NewPasswordFormContent({
  onSuccess,
  execute,
  validatePassword,
  passwordButtonTextId,
  pinButtonTextId,
}) {
  const { push } = useHistory();
  const errorMessage = useAuthErrorMessage();

  const [submitted, setSubmitted] = useState(false);
  const [stepIndex, setStepIndex] = useState(PASSWORD_STEP_INDEX);
  const goBack = useCallback(() => setStepIndex(PASSWORD_STEP_INDEX), []);

  const onSubmit = useCallback(
    async values => {
      if (stepIndex === PASSWORD_STEP_INDEX) {
        setStepIndex(PIN_STEP_INDEX);
        return undefined;
      }
      try {
        await execute(values);
        setSubmitted(true);
        onSuccess();
        // Timeout should not block submit, therefore no waiting
        timeoutPromise(REDIRECT_TIMEOUT).then(() => push(routes.login));
      } catch (e) {
        errorMessage(e);
      }
      return null;
    },
    [errorMessage, execute, onSuccess, push, stepIndex]
  );

  return (
    <NoLabelForm onSubmit={onSubmit} hideRequiredMark>
      <FormItemFieldRegistration name="password" />
      <FormItemFieldRegistration name="pin" />

      {stepIndex === PASSWORD_STEP_INDEX && (
        <PasswordForm
          validatePassword={validatePassword}
          buttonTextId={passwordButtonTextId}
        />
      )}
      {stepIndex === PIN_STEP_INDEX && (
        <PinForm
          goBack={goBack}
          buttonTextId={pinButtonTextId}
          submitted={submitted}
        />
      )}
    </NoLabelForm>
  );
}

function TokenErrorMessage({ error }) {
  const errorMessage = useAuthErrorMessage();
  useEffect(() => {
    errorMessage({
      ...error,
      dialogProps: {
        cancelTextId: null,
        centered: true,
        wrapClassName: 'no-pointer-events',
      },
    });
  }, [error, errorMessage]);
  return null;
}

export default function NewPasswordForm({
  className,
  changePassword,
  onChangePasswordSuccess,
  validatePassword,
  tokenError,
  tokenValidLoading,
  passwordButtonTextId,
  pinButtonTextId,
}) {
  if (!tokenValidLoading && tokenError) {
    return (
      <AuthEnvelope style={{ display: 'none' }}>
        <TokenErrorMessage error={tokenError} />;
      </AuthEnvelope>
    );
  }
  return (
    <AuthEnvelope className={className}>
      <HeaderLogo />
      {tokenValidLoading && (
        <AuthEnvelopeInfoText
          text={
            <>
              <FormattedMessage id="newPassword.password.verifyingToken" />
              <ThreeDotsSpinner />
            </>
          }
        />
      )}
      {!tokenValidLoading && !tokenError && (
        <NewPasswordFormContent
          execute={changePassword}
          onSuccess={onChangePasswordSuccess}
          validatePassword={validatePassword}
          passwordButtonTextId={passwordButtonTextId}
          pinButtonTextId={pinButtonTextId}
        />
      )}
    </AuthEnvelope>
  );
}
