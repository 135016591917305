import { noop } from 'lodash-es';
import { createContext, useState } from 'react';

export const DialogsParentContext = createContext({
  isDialogOpen: false,
  setDialogOpen: noop,
});

export function DialogsParentProvider({ children }) {
  const [isDialogOpen, setDialogOpen] = useState();
  return (
    <DialogsParentContext.Provider value={{ isDialogOpen, setDialogOpen }}>
      {children}
    </DialogsParentContext.Provider>
  );
}
