import { useMutation } from '@apollo/client';
import { merge } from 'lodash-es';
import { generatePath } from 'react-router';

import {
  userFormToGraphql,
  userGraphqlToForm,
} from '../../../app/data/userConversions';
import {
  UPDATE_USER_MUTATION,
  USER_DETAIL_QUERY,
} from '../../../app/graphql/adminQueries';
import routes from '../../../app/routes';
import AdminConsoleDetail from '../common/AdminConsoleDetail';
import AdminUserDetailDocumentGroup from './detail/AdminUserDetailDocumentGroup';
import AdminUserDetailExpandedTracking from './detail/AdminUserDetailExpandedTracking';
import AdminUserDetailProfile from './detail/AdminUserDetailProfile';

const STEPS = [
  {
    path: routes.admin.userDetailSteps.root,
    routeProps: { exact: true },
    component: AdminUserDetailProfile,
  },
  {
    path: routes.admin.userDetailSteps.documentGroup,
    component: AdminUserDetailDocumentGroup,
  },
  {
    path: routes.admin.userDetailSteps.expandedTracking,
    component: AdminUserDetailExpandedTracking,
  },
];

const MOCK_VALUES = {
  settings: { addressBookPermissions: ['SINGLE', 'MULTIPLE'] },
};

export default function AdminUserDetail({
  baseRoute = routes.admin.userDetail,
  userId,
  headerRightRef,
}) {
  const [mutate] = useMutation(UPDATE_USER_MUTATION);
  const save = (user, values) =>
    mutate({
      variables: {
        id: user.id,
        input: values,
      },
    });

  return (
    <AdminConsoleDetail
      query={USER_DETAIL_QUERY}
      variables={{ id: userId }}
      headerRightRef={headerRightRef}
      graphqlToForm={val => merge(userGraphqlToForm(val), MOCK_VALUES)}
      formToGraphql={userFormToGraphql}
      save={save}
      saveSuccessMessageId="admin.userManagement.userDetail.save.success"
      getAfterSaveRedirectRoute={user =>
        generatePath(baseRoute, { userId: user.id })
      }
      sectionHomeRoute={routes.admin.userManagement}
      steps={STEPS.map(s => ({ ...s, path: `${baseRoute}${s.path}` }))}
      getStepsProps={user => ({ user })}
    />
  );
}
