import { Alert } from 'antd';
import { Component } from 'react';
import { useIntl } from 'react-intl';

import { useMountEffect } from '../common/utils/hookUtils';
import { formatErrorWithMessage } from '../utils/errorUtils';
import { log } from '../utils/logger';
import { useRemoteLogger } from './debug/useRemoteLogger';

function RenderError({ messageId, error, apolloClient }) {
  const logRemote = useRemoteLogger({ client: apolloClient });

  const intl = useIntl();
  const errorMsg = formatErrorWithMessage({
    error,
    intl,
    messageId,
  });
  useMountEffect(() => {
    logRemote(error);
  });
  return <Alert type="error" message={errorMsg} />;
}

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: undefined };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    log.error(error, errorInfo);
  }

  render() {
    const { hasError, error } = this.state;
    const { children, messageId, apolloClient } = this.props;

    return hasError ? (
      <RenderError
        messageId={messageId}
        error={error}
        apolloClient={apolloClient}
      />
    ) : (
      children
    );
  }
}
ErrorBoundary.defaultProps = {
  messageId: 'error.render',
};
