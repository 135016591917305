import { useMutation, useQuery } from '@apollo/client';
import { Button, Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  CONFIRM_RELEASE_NOTES_MUTATION,
  RELEASE_NOTES_QUERY,
} from '../app/graphql/authQueries';
import { closeReleaseNotes, getReleaseNotes } from '../app/redux/auth';
import { extractGraphqlEntity } from '../common/utils/graphqlUtils';
import { sanitizeHtml } from '../common/utils/htmlUtils';
import { SmallLogo } from '../components/Logo';
import { FAIcon } from '../components/adapters/fontAwesomeAdapters';
import { CustomLoaderButton } from '../components/buttons/buttons';
import { useFlashMessageContext } from '../components/dialogs/FlashMessageProvider';
import {
  Scrollbars,
  VerticalTrackTypes,
} from '../components/layout/Scrollbars';
import { TwoLines } from '../components/layout/layoutElements';

function ChangelogDialogContent({ isOpen, close, onAck, content, submitting }) {
  const cleanHtml = sanitizeHtml(content);

  return (
    <Modal
      className="ChangelogDialog"
      open={isOpen}
      footer={null}
      closable={false}
      centered
      title={
        <>
          <SmallLogo size="sm" />
          <FormattedMessage id="changelog.title" />
        </>
      }
    >
      <TwoLines className="size-10" />
      <div className="ChangelogDialog-ControlButtons">
        {onAck ? (
          <>
            <Button
              className="width-90"
              onClick={close}
              data-subject="changelog"
              data-action="readLater"
            >
              <FormattedMessage id="buttons.readLater" />
            </Button>
            <CustomLoaderButton
              type="primary"
              className="width-100"
              onClick={async () => {
                await onAck();
                close();
              }}
              loading={submitting}
              data-subject="changelog"
              data-action="close"
            >
              <FAIcon icon="times-circle" />
              <span>
                <FormattedMessage id="buttons.close" />
              </span>
            </CustomLoaderButton>
          </>
        ) : (
          <Button type="primary" className="width-100" onClick={close}>
            <FAIcon icon="times-circle" />
            <span>
              <FormattedMessage id="buttons.close" />
            </span>
          </Button>
        )}
      </div>
      <Scrollbars
        spaceOutside
        isInFlexContainer
        vertical={VerticalTrackTypes.ABOVE_CONTENT}
      >
        <div
          className="ChangelogDialog-Content"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: cleanHtml }}
        />
      </Scrollbars>
    </Modal>
  );
}

export function LoginControlledChangelogDialog() {
  const content = useSelector(getReleaseNotes);
  const dispatch = useDispatch();
  const [confirm, { loading }] = useMutation(CONFIRM_RELEASE_NOTES_MUTATION);
  const { errorMessage } = useFlashMessageContext();

  const isOpen = !!content;
  const close = () => dispatch(closeReleaseNotes());

  const onAck = async () => {
    try {
      await confirm();
    } catch (e) {
      errorMessage(e);
    }
  };

  return (
    <ChangelogDialogContent
      isOpen={isOpen}
      close={close}
      onAck={onAck}
      content={content}
      submitting={loading}
    />
  );
}

export function UserControlledChangelogDialog({ isOpen, close }) {
  const { data } = useQuery(RELEASE_NOTES_QUERY);
  const content = data ? extractGraphqlEntity(data) : '';

  return (
    <ChangelogDialogContent isOpen={isOpen} close={close} content={content} />
  );
}
