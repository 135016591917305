import { pick } from 'lodash-es';

import { omitByDeep } from '../../common/utils/funcUtils';
import { formatUserLabel } from '../../containers/admin/utils/adminFormatting';

export function userGraphqlToForm(user) {
  return {
    name: formatUserLabel(user),
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phone: user.phone,
    reportsEnabled: user.reportsEnabled,
    settings: {
      dateFormat: user.settings?.dateFormat,
      unitSystem: user.settings?.unitSystem,
    },
    accounts: (user.accounts || []).map(acc => pick(acc, ['number', 'name'])),
    accountGroups: (user.accountGroups || []).map(ag => ({
      code: ag.code,
      text: ag.name,
    })),
    partnerGroups: (user.partnerGroups || []).map(pg => ({
      code: pg.code,
      text: pg.name,
    })),
    documentGroup: user.documentGroup,
    expandedTracking: user.expandedTracking,
  };
}

function mapExpandedTrackingSection(section) {
  return {
    city: (section?.city || []).filter(item => item.operation && item.text),
    name: (section?.name || []).filter(item => item.operation && item.text),
    address: (section?.address || []).filter(
      item => item.operation && item.text
    ),
  };
}

export function userFormToGraphql(values) {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    title: values.title,
    phone: values.phone,
    reportsEnabled: values.reportsEnabled,
    settings: {
      dateFormat: values.settings?.dateFormat,
      unitSystem: values.settings?.unitSystem,
    },
    accounts: values.accounts?.map(acc => pick(acc, ['number'])),
    accountGroups: values.accountGroups?.map(ag => pick(ag, ['code'])),
    partnerGroups: values.partnerGroups?.map(pg => pick(pg, ['code'])),
    documentGroup: values.documentGroup && pick(values.documentGroup, ['code']),
    expandedTracking: omitByDeep(
      {
        shipper: mapExpandedTrackingSection(values.expandedTracking?.shipper),
        consignee: mapExpandedTrackingSection(
          values.expandedTracking?.consignee
        ),
      },
      (val, key) => key === '__typename'
    ),
  };
}
