import { compact, groupBy, property, upperCase } from 'lodash-es';

import { richSelectValueToCodeValueInput } from '../../components/forms/selectUtils';
import { makeError } from '../../utils/errorUtils';

export const DeviceAddonType = {
  GPS: 'GPS',
  TEMPERATURE: 'TEMPERATURE',
};

export function parseDeviceAddonText(text) {
  const chunks = text.split(/\s*\|\s*/g);
  if (chunks.length !== 2) {
    throw makeError({
      code: 'error.addonDevice.invalidFormat',
      values: { value: text },
    });
  }
  return { brandName: chunks[0], modelName: chunks[1] };
}

export function getDeviceAddonLabel({ brandName, modelName }) {
  return compact([brandName && upperCase(brandName), modelName]).join(' | ');
}

function mapDeviceAddonInput(deviceAddon) {
  const { brandName, modelName } = parseDeviceAddonText(
    deviceAddon.device.text
  );
  return {
    deviceCode: deviceAddon.device.code,
    brandName,
    modelName,
    serialNumber: deviceAddon.serialNumber,
    source: richSelectValueToCodeValueInput(deviceAddon.source),
  };
}

export function mapDeviceAddonsInput(deviceAddons) {
  const { [DeviceAddonType.GPS]: gps, [DeviceAddonType.TEMPERATURE]: temp } =
    groupBy(
      deviceAddons?.filter(v => !!v),
      property('type')
    );

  return {
    gpsDevices: gps?.map(mapDeviceAddonInput),
    temperatureDevices: temp?.map(mapDeviceAddonInput),
  };
}
