import { createContext, useContext } from 'react';

import { useCachedProp } from '../../../common/utils/hookUtils';

const PackageListFieldVisibilityContext = createContext({
  packoutDateTime: true,
});

export const usePackageListFieldVisibilityContext = () =>
  useContext(PackageListFieldVisibilityContext);

export function PackageListFieldVisibilityProvider({
  children,
  packoutDateTime,
}) {
  const value = useCachedProp({ packoutDateTime });

  return (
    <PackageListFieldVisibilityContext.Provider value={value}>
      {children}
    </PackageListFieldVisibilityContext.Provider>
  );
}
