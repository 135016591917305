import { Marker, Polyline } from '@react-google-maps/api';
import { useContext, useState } from 'react';

import { cssVariables } from '../../../styles/cssVariables';
import { mapSize, point } from '../../../utils/mapUtils';
import { svgCirclePath } from '../../../utils/svgUtils';
import { GoogleMapClustererContext } from './googleMapClustering';
import { useGoogleMapTooltip } from './googleMapTooltips';

// ICONS, SYMBOLS
// https://developers.google.com/maps/documentation/javascript/reference/marker#Icon
// https://developers.google.com/maps/documentation/javascript/reference/marker#Symbol

function circleIcon(color) {
  return {
    scale: 1,
    path: svgCirclePath(9),
    strokeWeight: 1,
    strokeColor: cssVariables.colorWhite,
    fillOpacity: 1,
    fillColor: color,
    labelOrigin: point(9, 9),
    anchor: point(10, 10),
    size: mapSize(20, 20),
  };
}

function dashedLineSymbol(color) {
  return {
    path: 'M 0,-0.5 0,0.5',
    strokeOpacity: 0.5,
    strokeWeight: 3,
    scale: 3,
    strokeColor: color || cssVariables.colorRed,
  };
}

export function imgIcon(url, size, anchor) {
  return {
    url,
    size,
    anchor: anchor || point(size?.width / 2 || 0, size?.height / 2 || 0),
  };
}

// LABELS
// https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerLabel

function shortLabel(label) {
  return {
    text: label,
    color: cssVariables.colorWhite,
    fontFamily: cssVariables.fontStd,
    fontSize: cssVariables['font-size-sm'],
    fontWeight: 'bold',
  };
}

export function iconLabel(char, color, fontSize) {
  return {
    color: color || cssVariables.colorRed,
    fontFamily: "'Font Awesome 5 Free'",
    fontWeight: '700',
    fontSize: fontSize || cssVariables['heading-4-size'],
    text: char,
  };
}

// ICON / LABEL COMBINATIONS
// https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerOptions
// https://developers.google.com/maps/documentation/javascript/reference/polygon#PolylineOptions

const NO_ICON = { url: ' ', size: mapSize(0, 0) };
const NO_LABEL = { text: ' ' };

export function circleShortLabel(label, color) {
  return {
    icon: circleIcon(color || cssVariables.colorRed),
    label: label && shortLabel(label),
  };
}

export function iconOnlyLabel(char, color, fontSize) {
  return {
    icon: NO_ICON,
    label: iconLabel(char, color, fontSize),
  };
}

export function imgOnlyLabel(url, size) {
  return {
    icon: imgIcon(url, size),
    label: NO_LABEL,
  };
}

export function planeRoute(color) {
  return {
    options: {
      strokeWeight: 0,
      icons: [{ icon: dashedLineSymbol(color), offset: 0, repeat: '10px' }],
      geodesic: true,
    },
  };
}

// RENDERERS

function ExtendedMarker({ marker }) {
  const [element, setElement] = useState();
  const clusterer = useContext(GoogleMapClustererContext);

  const listenerProps = useGoogleMapTooltip({
    getContent: () => marker.tooltipContent,
    onMouseOver: marker.onMouseOver,
    onMouseOut: marker.onMouseOut,
    getElement: () => element,
  });

  return (
    <Marker
      clusterer={clusterer}
      position={marker.coords}
      icon={marker.icon}
      shape={marker.shape}
      label={marker.label}
      title={marker.title}
      options={{ data: marker.data }}
      onLoad={setElement}
      {...listenerProps}
    />
  );
}
export function renderMarker(marker, index) {
  return <ExtendedMarker key={`${marker.key}${index}`} marker={marker} />;
}

export function renderPolyline(polyline) {
  return (
    <Polyline
      key={polyline.key}
      path={polyline.path}
      options={polyline.options}
    />
  );
}
