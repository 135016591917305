import { useApolloClient } from '@apollo/client';
import { compact, isEmpty, property } from 'lodash-es';
import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { extractGraphqlEntity } from '../../../common/utils/graphqlUtils';
import { useFlashMessageContext } from '../../dialogs/FlashMessageProvider';
import { useDynamicFieldDependencies } from './dynamicFormDependencies';
import { extractStringValueFromRichFormValue } from './dynamicFormHelpers';
import { DynamicFormSchemaContext } from './dynamicFormSchema';

function useDynamicAsyncValidation({ schemaName, asyncValidation, required }) {
  const intl = useIntl();
  const { fieldValidationQuery } = useContext(DynamicFormSchemaContext);
  const { errorMessage } = useFlashMessageContext();
  const { dependencies } = useDynamicFieldDependencies(
    asyncValidation?.dependsOn || []
  );
  const client = useApolloClient();

  // NOTE: Validation will not be rerun when a dependency changes - soundness of the dependency
  // model must be ensured at the backend side. This was a request to reduce number of
  // validation requests and potential user discomfort was decided to be a worthy tradeoff

  if (!asyncValidation) {
    return null;
  }
  if (!schemaName) {
    return null;
  }

  return {
    validator: async (rule, value) => {
      if (!value) {
        return;
      }
      let data;
      try {
        const response = await client.query({
          query: fieldValidationQuery,
          variables: {
            input: {
              fieldName: schemaName,
              value: extractStringValueFromRichFormValue(value),
              params: dependencies,
            },
          },
        });
        data = response.data;
      } catch (e) {
        errorMessage(e);
        throw new Error(
          intl.formatMessage({
            id: 'error.validationFailed',
          })
        );
      }
      // This must be out of `try` so that the validation error wasn't caught in the `catch` block
      // and could be differentiated from server error
      if (data) {
        const { errors } = extractGraphqlEntity(data);
        if (!isEmpty(errors)) {
          throw new Error(errors.map(property('message')).join('\n'));
        }
      }
    },
  };
}

export function useDynamicFormValidationRules({ schemaName, validation }) {
  const { required, email } = validation?.rules || {};
  const asyncValidation = validation?.async;

  const asyncValidator = useDynamicAsyncValidation({
    schemaName,
    asyncValidation,
    required,
  });

  const antObjRule = {};
  if (required) {
    antObjRule.required = true;
  }
  if (email) {
    antObjRule.type = 'email';
  }

  return compact([!isEmpty(antObjRule) && antObjRule, asyncValidator]);
}
