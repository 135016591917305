import { Record } from 'immutable';

import { simpleAction } from '../../common/utils/reduxUtils';

const InitialState = Record({
  summaryView: undefined,
});
const INITIAL_STATE = InitialState();

const SET_SHIPMENT_DETAIL_SUMMARY_VIEW = 'SET_SHIPMENT_DETAIL_SUMMARY_VIEW';
const CLEAR_SHIPMENT_DETAIL = 'CLEAR_SHIPMENT_DETAIL';

export function shipmentDetailReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_SHIPMENT_DETAIL_SUMMARY_VIEW:
      return state.set('summaryView', action.value);
    case CLEAR_SHIPMENT_DETAIL:
      return state.clear();
    default:
      return state;
  }
}

export const getShipmentDetailSummaryView = state =>
  state.shipmentDetail.summaryView;

export const setShipmentDetailSummaryView = simpleAction(
  SET_SHIPMENT_DETAIL_SUMMARY_VIEW,
  'value'
);
export const clearShipmentDetail = simpleAction(CLEAR_SHIPMENT_DETAIL);
