/* eslint-disable react/style-prop-object */
import { Col, Row } from 'antd';
import { isNil } from 'lodash-es';
import { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { shipmentConversions } from '../app/data/shipmentConversions';
import { setShipmentDetailSummaryView } from '../app/redux/shipmentDetail';
import Whitespace from '../components/Whitespace';
import ProcessingModal from '../components/dialogs/ProcessingModal';
import { CurrencyFormat } from '../components/domainSpecific/currencyElements';
import { useDynamicFormSchemaDynamicFieldsDefinition } from '../components/forms/dynamic/dynamicFormSchema';
import { SimpleCard, SimpleCardTheme } from '../components/layout/cardElements';
import { useResponsiveQueries } from '../components/responsive/responsiveQueries';
import { SavedShipmentContext } from '../forms/shipments/newShipment/SavedShipmentProvider';
import useNewShipmentAsyncConfig from '../forms/shipments/newShipment/useNewShipmentAsyncConfig';
import { cssVariables } from '../styles/cssVariables';
import ModalPage from '../templates/ModalPage';
import { pxToNumber } from '../utils/cssUtils';
import { DateTimeFormatMedium } from '../utils/dateFormatting';
import ShipmentDetailMap from './shipmentDetail/ShipmentDetailMap';
import ShipmentMilestonesSteps from './shipmentDetail/ShipmentMilestonesSteps';
import { formatDateTimeSchedule } from './shipmentDetail/shipmentFormats';
import { ShipmentDetailResponsiveContent } from './shipmentDetail/shipmentSummaries/ShipmentDetailResponsiveContent';

const DateTimeFormat = props => (
  <DateTimeFormatMedium
    {...props}
    separator={
      <>
        <Whitespace />
        <FormattedMessage id="time.prefixBy" />
        <Whitespace />
      </>
    }
  />
);

const Card = props => (
  <SimpleCard {...props} theme={SimpleCardTheme.ROUND_BORDERED} />
);

const VIEWS = ['order-summary', 'package-list'];

function LargeView({ data, shipment, children, isQuoteEnabled }) {
  const { accountDetailResult } = useNewShipmentAsyncConfig({
    account: data.account,
  });
  const { active: savedShipmentActive } = useContext(SavedShipmentContext);
  const quoteEnabled =
    !isNil(data.quote) ||
    // If quote process fails, it's null but still it can be enabled
    (!accountDetailResult.loading && isQuoteEnabled && savedShipmentActive);

  return (
    <>
      <Row gutter={pxToNumber(cssVariables.spaceNorm2)} align="stretch">
        <Col className="ShipmentDetail-LeftColumn">
          <Card
            bodyProps={{ className: 'ConfirmOrderPage-Quote' }}
            titleId={
              quoteEnabled
                ? 'book.confirmOrder.pickupAndQuote'
                : 'book.confirmOrder.pickup'
            }
          >
            {quoteEnabled ? (
              <>
                <div className="ConfirmOrderPage-QuoteLabel size-sm">
                  <FormattedMessage id="book.confirmOrder.quoteEstimate" />
                </div>
                <div className="ConfirmOrderPage-QuoteValue size-lg">
                  {isNil(data.quote) ? (
                    <span className="text-uppercase">
                      <FormattedMessage id="labels.na" />
                    </span>
                  ) : (
                    <CurrencyFormat value={data.quote} />
                  )}
                </div>
                <div className="ConfirmOrderPage-QuoteLabel size-sm">
                  <FormattedMessage id="book.confirmOrder.pickUpReadyTime" />
                </div>
                <div
                  className="ConfirmOrderPage-QuoteValue size-sm"
                  data-info-id="pickupDateTime"
                  data-format="long"
                >
                  {formatDateTimeSchedule(
                    shipment.pickupDateTime,
                    DateTimeFormat
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="ConfirmOrderPage-QuoteLabel size-lg">
                  <FormattedMessage id="book.confirmOrder.pickUpReadyTime" />
                </div>
                <div
                  className="ConfirmOrderPage-QuoteValue size-md"
                  data-info-id="pickupDateTime"
                  data-format="long"
                >
                  {formatDateTimeSchedule(
                    shipment.pickupDateTime,
                    DateTimeFormat
                  )}
                </div>
              </>
            )}
          </Card>
        </Col>
        <Col className="FlexCol Flex1">
          <Card
            className="ShipmentMilestonesCard"
            titleId="shipmentDetail.shipmentMilestones"
          >
            <ShipmentMilestonesSteps shipment={shipment} data={data} />
          </Card>
        </Col>
      </Row>
      <Row
        className="Flex1 flex-nowrap ConfirmOrderPage-Main"
        gutter={pxToNumber(cssVariables.spaceNorm2)}
      >
        {children}
        <Col className="FlexCol spaces-vert-norm2" flex="1">
          <ShipmentDetailMap shipment={shipment} />
        </Col>
      </Row>
    </>
  );
}

export default function ConfirmOrderPage({
  data,
  onConfirm,
  onCancel,
  isQuoteEnabled,
  headerComponent: Header,
  headerProps,
  action,
}) {
  const shipperSpecificInfoFields = useDynamicFormSchemaDynamicFieldsDefinition(
    { schemaName: 'serviceInformation.shipperSpecificInfo' }
  );

  const shipmentForm = data;
  const shipment =
    shipmentForm &&
    shipmentConversions.formToDetail(data, { shipperSpecificInfoFields });

  const shipmentWithLocations = {
    ...shipment,
    origin: {
      ...shipment.origin,
      location: data.originLocation,
    },
    destination: {
      ...shipment.destination,
      location: data.destinationLocation,
    },
  };

  const actionWithArgs = {
    ...action,
    execute: (...args) => action.execute({ shipment, shipmentForm }, ...args),
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setShipmentDetailSummaryView(VIEWS[0]));
  }, [dispatch]);

  const media = useResponsiveQueries();
  const isSm = media.sm || media.xs;

  return (
    <ModalPage
      id="ConfirmOrderPage"
      header={
        <Header
          shipment={shipmentWithLocations}
          shipmentForm={shipmentForm}
          action={actionWithArgs}
          onConfirm={onConfirm}
          onCancel={onCancel}
          {...headerProps}
        />
      }
      wrapInScrollbar={isSm}
    >
      <ShipmentDetailResponsiveContent
        data={data}
        shipment={shipmentWithLocations}
        summariesViews={VIEWS}
        largeViewComponent={LargeView}
        isQuoteEnabled={isQuoteEnabled}
      />
      {shipment.containsTbd && action.loading && (
        <ProcessingModal textId="book.confirmOrder.savingShipment" />
      )}
    </ModalPage>
  );
}
