import { message } from 'antd';
import { isPlainObject } from 'lodash-es';

import { FAIcon } from '../components/adapters/fontAwesomeAdapters';
import { formatError } from './errorUtils';

function contentToConfig(content) {
  return isPlainObject(content) ? content : { content };
}

function showMessage(content, { intl, iconName, method }) {
  const config = contentToConfig(content);
  // Override icon
  if (iconName) {
    config.icon = <FAIcon icon={iconName} />;
  }
  // Translate content
  if (config.contentId) {
    const msg = intl.formatMessage(
      {
        id: config.contentId,
        defaultMessage: config.contentId,
      },
      config.contentValues
    );
    if (config.content) {
      // show both msg and content if available
      config.content = `${msg} (${config.content})`;
    } else {
      config.content = msg;
    }
  }

  method(config);
}

export const showError = (content, { intl }) => {
  const config = contentToConfig(content);
  // Format error
  if (config.content instanceof Error) {
    config.content = formatError(config.content, { intl });
  }
  showMessage(config, {
    method: message.error,
    iconName: 'times-circle',
    intl,
  });
};

export const showSuccess = (content, { intl }) =>
  showMessage(content, {
    method: message.success,
    iconName: 'check-square',
    intl,
  });

export const showInfo = (content, { intl }) =>
  showMessage(content, {
    method: message.info,
    iconName: 'exclamation-triangle',
    intl,
  });
