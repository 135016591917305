import { gql } from '@apollo/client';

import { codeValueFragment, makeLocationFragment } from './commonFragments';

export const JOB_TEMPLATES_QUERY = gql`
  query CustomerShipmentTemplatesQuery($accountNumber: String!, $sort: Sort) {
    customerShipmentTemplates(accountNumber: $accountNumber, sort: $sort) {
      total
      data {
        id
        name
      }
      enableEditButton
    }
  }
`;

const deviceFragment = `{
  deviceCode
  brandName
  modelName
  serialNumber
  source ${codeValueFragment}
}`;

const locationFragment = makeLocationFragment({ fullState: true });

export const JOB_TEMPLATE_QUERY = gql`
  query CustomerShipmentTemplateQuery($id: String!) {
    customerShipmentTemplate(id: $id) {
      id
      name
      origin ${locationFragment}
      destination ${locationFragment}
      serviceInformation {
        commodity
        serviceType ${codeValueFragment}
        dangerousGoods
        unNumber ${codeValueFragment}
        exceptedQuantity
        additionalInsurance
        declaredValue
        costCenter
        currency ${codeValueFragment}
      }
      packages {
        packageSource ${codeValueFragment}
        packagingType ${codeValueFragment}
        commodityTemperature ${codeValueFragment}
        packageQualificationTime
        actualWeight
        length
        width
        height
        gpsDevices ${deviceFragment}
        temperatureDevices ${deviceFragment}
        pieces {
          description
          partNumber
          quantity
        }
        coolantAddon {
          type ${codeValueFragment}
          source ${codeValueFragment}
          amount
          replenishTime
        }
      }
      unitSystem
    }
  }
`;

export const CREATE_SHIPMENT_TEMPLATE_MUTATION = gql`
  mutation CreateShipmentTemplateMutation($input: ShipmentTemplateInput!) {
    createShipmentTemplate(input: $input) {
      id
    }
  }
`;

export const EDIT_SHIPMENT_TEMPLATE_MUTATION = gql`
  mutation EditShipmentTemplateMutation(
    $id: String!
    $input: ShipmentTemplateInput!
  ) {
    editShipmentTemplate(id: $id, input: $input) {
      id
    }
  }
`;

export const DELETE_SHIPMENT_TEMPLATE_MUTATION = gql`
  mutation DeleteShipmentTemplateMutation($id: String!) {
    deleteShipmentTemplate(id: $id)
  }
`;
