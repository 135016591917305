import { useApolloClient, useQuery } from '@apollo/client';
import PromisePool from '@supercharge/promise-pool';
import { Button, Form, Tooltip, Typography } from 'antd';
import classNames from 'classnames';
import { saveAs } from 'file-saver';
import { every, groupBy, isEmpty, sortBy, stubFalse, toPairs } from 'lodash-es';
import moment from 'moment';
import { Fragment, useCallback, useMemo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { OperationStatus } from '../../../app/enums/OperationStatus';
import {
  DOCUMENT_TYPE_OPTIONS_QUERY,
  DOCUMENT_UPLOAD_SUBSCRIPTION,
} from '../../../app/graphql/jobDocumentQueries';
import { JOB_DOCUMENTS_QUERY } from '../../../app/graphql/jobQueries';
import { getLoggedIn } from '../../../app/redux/auth';
import { useReduxDialogControls } from '../../../common/utils/dialogUtils';
import { extractGraphqlEntity } from '../../../common/utils/graphqlUtils';
import { useMountAndUpdateEffect } from '../../../common/utils/hookUtils';
import { takeOneFromObservable } from '../../../common/utils/observableUtils';
import { timeoutPromise } from '../../../common/utils/promiseUtils';
import UploadDropArea, {
  UploadStatus,
  useFileUpload,
} from '../../../components/UploadDropArea';
import Whitespace from '../../../components/Whitespace';
import { FAIcon } from '../../../components/adapters/fontAwesomeAdapters';
import IconButton from '../../../components/buttons/IconButton';
import { CustomLoaderButton } from '../../../components/buttons/buttons';
import { ThreeDotsSpinner } from '../../../components/data/Spinner';
import { useAsynchronousAction } from '../../../components/data/asyncActionElements';
import { DataError } from '../../../components/data/dataStateHandlers';
import { useFlashMessageContext } from '../../../components/dialogs/FlashMessageProvider';
import SimpleDialog from '../../../components/dialogs/SimpleDialog';
import { NoLabelForm } from '../../../components/forms/forms';
import { codeValueToSelectOption } from '../../../components/forms/selectUtils';
import { FormItemSelect } from '../../../components/forms/selects';
import {
  Scrollbars,
  VerticalTrackTypes,
} from '../../../components/layout/Scrollbars';
import { useResponsiveQueries } from '../../../components/responsive/responsiveQueries';
import {
  SimpleTable,
  SimpleTableBody,
  SimpleTableCell,
  SimpleTableHead,
  SimpleTableRow,
} from '../../../components/tables/tables';
import { InlineLink } from '../../../components/typography';
import {
  DEFAULT_UPLOAD_TIMEOUT,
  MAX_CONCURRENT_FILE_UPLOADS,
} from '../../../config/constants';
import { useRichFetch } from '../../../config/http';
import { URLS } from '../../../config/urls';
import {
  DateFormatLong,
  DateFormatNumeric,
  TimeFormatShort,
} from '../../../utils/dateFormatting';
import { formatError } from '../../../utils/errorUtils';
import { useStateIfMounted } from '../../../utils/reactUtils';
import { ShipmentSummaryCard } from './shipmentSummariesCommon';

function DocumentLink({ jobNumber, document }) {
  const intl = useIntl();
  const media = useResponsiveQueries();
  const isSm = media.xs || media.sm || media.md;
  const { errorMessage } = useFlashMessageContext();

  const richFetch = useRichFetch();
  const { execute: download, loading: downloading } = useAsynchronousAction(
    async () => {
      try {
        const res = await richFetch(
          URLS.downloadShipmentDocument(jobNumber, document.id),
          {
            method: 'GET',
          }
        );
        const data = await res.blob();
        saveAs(data, res.headers.get('X-Filename'));
      } catch (e) {
        if (!e.handled) {
          errorMessage(e);
        }
      }
    }
  );

  const naLabel = intl.messages['labels.na'];

  return (
    <Tooltip
      title={
        <FormattedMessage
          id="shipmentDetail.docs.uploadedBy"
          values={{ name: document.uploadedBy || naLabel }}
        />
      }
      overlayClassName={isSm ? '' : 'ant-tooltip--arrow-at-left'}
      placement="top"
    >
      <span>
        <InlineLink
          onClick={download}
          loading={downloading}
          data-subject="documents"
          data-role="item"
        >
          {document.description}
        </InlineLink>
      </span>
    </Tooltip>
  );
}

function DocumentItemIcon({ status, remove }) {
  if (status === UploadStatus.DONE || status === UploadStatus.SUCCESS) {
    return (
      <div className="FileUploadItemPreview__Icon FileUploadItemPreview__Icon--Success">
        <FAIcon icon="check-circle" className="icon-14" />
      </div>
    );
  }
  if (status === UploadStatus.UPLOADING) {
    return (
      <div className="FileUploadItemPreview__Icon">
        <ThreeDotsSpinner className="size-xs" />
      </div>
    );
  }
  return (
    <div className="FileUploadItemPreview__Icon FileUploadItemPreview__Icon--Error">
      <IconButton icon="trash" className="icon-14" onClick={remove} />
    </div>
  );
}

function DocumentItemPreview({ file, remove, options, loading }) {
  const intl = useIntl();

  if (file.status === UploadStatus.REMOVED) {
    return null;
  }
  return (
    <>
      <div
        className={classNames(
          'FileUploadItemPreview FileUploadItemPreview--WithSelect',
          {
            'FileUploadItemPreview--HasError':
              file.status === UploadStatus.ERROR,
          }
        )}
      >
        <DocumentItemIcon status={file.status} remove={remove} />
        <div className="FileUploadItemPreview__DocumentName" title={file.name}>
          {file.name}
        </div>
        <div className="Flex1 no-margin-form-items">
          <FormItemSelect
            placeholder={
              <>
                <FormattedMessage id="documentsUpload.type.label" />
                <Whitespace />*
              </>
            }
            name={['types', file.uid]}
            className="size-sm"
            options={options}
            rules={[{ required: true }]}
            floatingLabel={false}
            loading={loading}
          />
        </div>
      </div>
      {file.status === UploadStatus.ERROR && (
        <div className="FileUploadItemPreview__Error">
          {formatError(file.error, { intl })}
        </div>
      )}
    </>
  );
}

function useCustomDocumentItemPreview({ options, loading, error }) {
  const renderDocumentItemPreview = useCallback(
    (_1, file, _2, { remove }) => (
      <DocumentItemPreview
        file={file}
        remove={remove}
        options={options}
        loading={loading}
      />
    ),
    [loading, options]
  );

  return { renderDocumentItemPreview, error };
}

function AutoHeightScrollbars({ bottomReserve = 150, children }) {
  const ref = useRef();
  return (
    <Scrollbars
      vertical={VerticalTrackTypes.OUTSIDE}
      computeHeight={vals => {
        const contentHeight = ref.current?.scrollHeight || 0;
        const top =
          vals.ref.current?.container?.getBoundingClientRect()?.top || 0;
        const maxHeight = document.body.clientHeight - top - bottomReserve;
        return Math.min(contentHeight, maxHeight);
      }}
    >
      <div ref={ref}>{children}</div>
    </Scrollbars>
  );
}

function DocumentsUploadModal({ isOpen, close, jobNumber, documentTypes }) {
  const { successMessage } = useFlashMessageContext();
  const client = useApolloClient();

  const [form] = Form.useForm();

  const {
    fileList,
    onChange,
    upload,
    cancel,
    reset: resetUploadState,
  } = useFileUpload({
    uploadUrl: URLS.uploadShipmentDocument(jobNumber),
    getData: file => ({
      type: form.getFieldValue(['types', file.uid]),
    }),
    afterUpload: async ({ actionId }) => {
      const getResult = async () => {
        const dataPromise = takeOneFromObservable(
          client.subscribe({
            query: DOCUMENT_UPLOAD_SUBSCRIPTION,
            variables: { actionId },
          })
        ).then(({ data }) => extractGraphqlEntity(data));

        return Promise.race([
          dataPromise,
          timeoutPromise(DEFAULT_UPLOAD_TIMEOUT).then(() => {
            throw new Error('Timeout');
          }),
        ]);
      };

      const result = await getResult();

      if (result.status === OperationStatus.SUCCESS) {
        return { id: result.id };
      }

      const e = new Error(result.error?.message);
      e.code = result?.error?.code;
      throw e;
    },
  });

  const { renderDocumentItemPreview, error } =
    useCustomDocumentItemPreview(documentTypes);

  useMountAndUpdateEffect(
    {
      onUpdate([prevIsOpen]) {
        if (!prevIsOpen && isOpen) {
          // Clear state on open
          form.resetFields();
          setUploading(false);
          resetUploadState();
        }
      },
    },
    [isOpen]
  );

  const [uploading, setUploading] = useStateIfMounted();
  const uploadingKey = useRef(0);

  const triggerRefresh = () =>
    client.query({
      query: JOB_DOCUMENTS_QUERY,
      variables: { jobNumber },
      fetchPolicy: 'network-only',
    });

  const validateAndUpload = async () => {
    const key = ++uploadingKey.current;
    setUploading(true);
    try {
      await form.validateFields();
      const { results } = await PromisePool.for(
        fileList.filter(
          file => !file.status || file.status === UploadStatus.ERROR
        )
      )
        .withConcurrency(MAX_CONCURRENT_FILE_UPLOADS)
        .process(upload);

      if (every(results, v => !!v)) {
        await triggerRefresh();
        close();
        successMessage({ contentId: 'documentsUpload.message.success' });
      }
    } catch (e) {
      // eslint-disable-next-line no-empty
    } finally {
      // Skip if old upload was finished when new upload has already been initiated
      if (uploadingKey.current === key) {
        setUploading(false);
      }
    }
  };

  const onCancel = () => {
    cancel();
    close();
    triggerRefresh();
  };

  return error ? (
    <DataError error={error} />
  ) : (
    <SimpleDialog
      open={isOpen}
      className="DocumentsUploadDialog"
      icon={<FAIcon icon="cloud-upload-alt" className="size-md" />}
      mask
      wrapProps={{
        'data-subject': 'documents',
        'data-role': 'upload-dialog',
      }}
    >
      <Typography.Title level={1} className="sm">
        <FormattedMessage id="documentsUpload.dialog.title" />
      </Typography.Title>
      <NoLabelForm form={form}>
        <AutoHeightScrollbars>
          <UploadDropArea
            multiple
            beforeUpload={stubFalse}
            fileList={fileList}
            onChange={onChange}
            itemRender={renderDocumentItemPreview}
            disabled={uploading}
          />
        </AutoHeightScrollbars>
      </NoLabelForm>
      <div className="DocumentsUploadDialog__Buttons">
        <Button onClick={onCancel}>
          <FormattedMessage id="buttons.cancel" />
        </Button>
        <CustomLoaderButton
          type="primary"
          onClick={validateAndUpload}
          disabled={isEmpty(fileList) || uploading}
          loading={uploading}
          data-subject="documents"
          data-action="upload"
        >
          <FormattedMessage
            id={
              every(fileList, file => file.status === UploadStatus.SUCCESS) &&
              !uploading
                ? 'buttons.close'
                : 'buttons.upload'
            }
          />
        </CustomLoaderButton>
      </div>
    </SimpleDialog>
  );
}

function DocumentUploadButton({ open, documentTypes }) {
  const disabled =
    documentTypes.error ||
    isEmpty(documentTypes.options) ||
    documentTypes.loading;

  // eslint-disable-next-line no-nested-ternary
  const titleId = documentTypes.error
    ? 'documentsUpload.button.documentTypes.error'
    : isEmpty(documentTypes.options)
      ? 'documentsUpload.button.documentTypes.empty'
      : null;

  return (
    <Tooltip title={titleId && <FormattedMessage id={titleId} />}>
      <div className="DocumentsUploadButtonWrapper">
        <Button
          size="small"
          className="DocumentsUploadButton"
          onClick={open}
          disabled={disabled}
          data-subject="documents"
          data-action="open"
        >
          <FAIcon icon="cloud-upload-alt icon-18" />
          <span>
            <FormattedMessage id="buttons.upload" />
          </span>
        </Button>
      </div>
    </Tooltip>
  );
}

function DocumentsCard({ jobNumber, documents, documentTypes }) {
  const { open, isOpen, close } = useReduxDialogControls({
    id: 'shipmentDetail.documentUpload',
  });

  return (
    <ShipmentSummaryCard
      titleId="shipmentDetail.documents"
      headerExtraContent={
        documentTypes && (
          <DocumentUploadButton open={open} documentTypes={documentTypes} />
        )
      }
    >
      <SimpleTable className="DocumentsTable">
        <SimpleTableBody>
          {(documents || []).map(doc => (
            <SimpleTableRow key={doc.id}>
              <SimpleTableCell>
                <DateFormatNumeric value={doc.uploadedAt} />
              </SimpleTableCell>
              <SimpleTableCell>
                <DocumentLink jobNumber={jobNumber} document={doc} />
              </SimpleTableCell>
            </SimpleTableRow>
          ))}
        </SimpleTableBody>
      </SimpleTable>
      {documentTypes && (
        <DocumentsUploadModal
          isOpen={isOpen}
          close={close}
          jobNumber={jobNumber}
          documentTypes={documentTypes}
        />
      )}
    </ShipmentSummaryCard>
  );
}

function ActivityCard({ activity, blinded }) {
  const rowsGroupedByDay = groupBy(
    sortBy(activity || [], row => -row.dateTime.valueOf()),
    row => row.dateTime.format('YYYY-MM-DD')
  );

  return (
    <ShipmentSummaryCard titleId="shipmentDetail.activityLog">
      {blinded ? (
        <div className="ShipmentDetail__BlindedActivity">
          <FormattedMessage id="gdpr.blinded" />
        </div>
      ) : (
        <SimpleTable className="TrackingHistoryTable">
          {toPairs(rowsGroupedByDay).map(([day, rows]) => (
            <Fragment key={day}>
              <SimpleTableHead>
                <SimpleTableCell>
                  <DateFormatLong value={moment(day)} />
                </SimpleTableCell>
              </SimpleTableHead>
              <SimpleTableBody>
                {rows.map(row => (
                  <SimpleTableRow key={`${row.dateTime.valueOf()}${row.type}`}>
                    <SimpleTableCell>
                      <TimeFormatShort value={row.dateTime} />
                    </SimpleTableCell>
                    <SimpleTableCell>{row.type}</SimpleTableCell>
                  </SimpleTableRow>
                ))}
              </SimpleTableBody>
            </Fragment>
          ))}
        </SimpleTable>
      )}
    </ShipmentSummaryCard>
  );
}

function useDocumentTypes({ accountNumber, skip }) {
  const { errorMessage } = useFlashMessageContext();
  const { data, loading, error } = useQuery(DOCUMENT_TYPE_OPTIONS_QUERY, {
    variables: { accountNumber },
    skip,
    onError: errorMessage,
  });
  const options = useMemo(() => {
    if (skip || !data) {
      return [];
    }
    return extractGraphqlEntity(data).map(codeValueToSelectOption);
  }, [data, skip]);
  return { options, loading, error };
}

export function ShipmentHistoryAndDocs({ shipment }) {
  const loggedIn = useSelector(getLoggedIn);
  const documentTypes = useDocumentTypes({
    accountNumber: shipment?.metadata?.account?.number,
    skip: !loggedIn,
  });
  return (
    <div className="margins-bottom-norm2">
      <DocumentsCard
        jobNumber={shipment.jobNumber}
        documents={shipment?.documents?.documentList}
        documentTypes={loggedIn && documentTypes}
      />
      <ActivityCard
        activity={shipment?.activity}
        blinded={shipment?.metadata?.blindingInformation?.activity}
      />
    </div>
  );
}
