import { pick } from 'lodash-es';

import { AdminUserLinkedAccountsFormSections } from '../forms/AdminUserLinkedAccountsFormSections';
import AdminAddUserStepTemplate from './AdminAddUserStepTemplate';

export default function AdminAddUserAccounts({
  onSave,
  onBack,
  onCancel,
  values,
}) {
  return (
    <AdminAddUserStepTemplate
      titleId="admin.userManagement.addUser.linkAccounts"
      titleIcon="file-invoice"
      onSave={onSave}
      onBack={onBack}
      onCancel={onCancel}
      values={values}
      extractRelevantValues={vals =>
        pick(vals, ['accounts', 'accountGroups', 'partnerGroups'])
      }
    >
      <AdminUserLinkedAccountsFormSections viewMode={false} user={{}} />
    </AdminAddUserStepTemplate>
  );
}
