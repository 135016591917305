import { get } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import { DynamicFormFieldType } from '../../app/enums/dynamicFormEnums';
import {
  fromServerDateOptional,
  fromServerDateTimeOptional,
  fromServerTimeOptional,
} from '../../common/utils/dateUtils';
import { WeightFormat } from '../../components/domainSpecific/measuringUnitsElements';
import { YesNoFormat } from '../../components/forms/checkable';
import {
  DateFormatMedium,
  DateTimeFormatMedium,
  TimeFormatShort,
} from '../../utils/dateFormatting';

export function formatDateTimeSchedule(value, DateFormat) {
  if (value?.isAsap) {
    return <FormattedMessage id="date.asap" />;
  }
  if (value?.isNow) {
    return <FormattedMessage id="date.now" />;
  }
  return <DateFormat value={value?.scheduled} />;
}

export function formatShipmentActualWeight(pkg) {
  const value = get(pkg, 'actualWeight');
  const unknown = get(pkg, 'unknownWeight');

  return unknown ? (
    <FormattedMessage id="book.newShipment.label.unknownWeight" />
  ) : (
    <WeightFormat value={value} />
  );
}

export function formatShipperSpecificInfoField(info) {
  if (info.type === DynamicFormFieldType.DATE) {
    const value = fromServerDateOptional(info.value);
    return <DateFormatMedium value={value} />;
  }
  if (info.type === DynamicFormFieldType.TIME) {
    const value = fromServerTimeOptional(info.value);
    return <TimeFormatShort value={value} />;
  }
  if (info.type === DynamicFormFieldType.DATETIME) {
    const value = fromServerDateTimeOptional(info.value);
    return <DateTimeFormatMedium value={value} separator=" | " />;
  }
  if (info.type === DynamicFormFieldType.BOOLEAN) {
    const value =
      // eslint-disable-next-line no-nested-ternary
      info.value === 'true' ? true : info.value === 'false' ? false : undefined;
    return (
      <span className="text-uppercase">
        <YesNoFormat value={value} />
      </span>
    );
  }

  return info.value;
}
