import { Col, Row } from 'antd';
import { get, merge, pick } from 'lodash-es';
import { useCallback } from 'react';

import {
  DeviceAddonType,
  parseDeviceAddonText,
} from '../../../app/data/deviceAddonConversions';
import { FATypes } from '../../../components/adapters/fontAwesomeAdapters';
import IconButton from '../../../components/buttons/IconButton';
import { useFlashMessageContext } from '../../../components/dialogs/FlashMessageProvider';
import { FormItemCustomGroupAutocomplete } from '../../../components/forms/CustomGroupAutocomplete';
import FormList from '../../../components/forms/FormList';
import {
  FormItemFieldRegistration,
  FormItemInputText,
} from '../../../components/forms/basicFormElements';
import { useDynamicSelectOptionsLoader } from '../../../components/forms/dynamic/dynamicSelects';
import { useFormListCallbacks } from '../../../components/forms/formDecorators';
import {
  EnsureInitialFormListItem,
  mergeFormNames,
} from '../../../components/forms/formHelpers';
import { useFormContext } from '../../../components/forms/forms';
import { useLabelFilter } from '../../../components/forms/selectDecorators';
import {
  FormItemSchemaDefinedSelect,
  FormItemSelect,
} from '../../../components/forms/selects';
import { FlexCol } from '../../../components/layout/layoutElements';
import { cssVariables } from '../../../styles/cssVariables';
import { pxToNumber } from '../../../utils/cssUtils';

const MAX_DEVICES = 4;

const SCHEMA_NAME_PREFIXES = {
  [DeviceAddonType.GPS]: 'packages.gpsDevices',
  [DeviceAddonType.TEMPERATURE]: 'packages.temperatureDevices',
};

function mapOptions(options, type) {
  return (
    options &&
    options.map(opt => ({
      value: opt.code,
      label: opt.text,
      entry: { type, ...parseDeviceAddonText(opt.text), ...opt },
    }))
  );
}

function filterOptions(options, filterOption, searchText) {
  return options && options.filter(opt => filterOption(searchText, opt));
}

function useOptions({ searchText }) {
  const { filterOption } = useLabelFilter();

  const {
    options: gpsOptions,
    loading: gpsLoading,
    loadOptions: loadGps,
  } = useDynamicSelectOptionsLoader({
    schemaName: `${SCHEMA_NAME_PREFIXES[DeviceAddonType.GPS]}.device`,
    preloadOptions: false,
  });

  const {
    options: tempOptions,
    loading: tempLoading,
    loadOptions: loadTemp,
  } = useDynamicSelectOptionsLoader({
    schemaName: `${SCHEMA_NAME_PREFIXES[DeviceAddonType.TEMPERATURE]}.device`,
    preloadOptions: false,
  });

  const loadOptions = useCallback(() => {
    loadGps('');
    loadTemp('');
  }, [loadGps, loadTemp]);

  const options = [
    {
      groupId: 'gps',
      headerId: 'book.newShipment.label.device.group.gps',
      loading: gpsLoading,
      options: filterOptions(
        mapOptions(gpsOptions, DeviceAddonType.GPS),
        filterOption,
        searchText
      ),
    },
    {
      groupId: 'temperature',
      headerId: 'book.newShipment.label.device.group.temperature',
      loading: tempLoading,
      options: filterOptions(
        mapOptions(tempOptions, DeviceAddonType.TEMPERATURE),
        filterOption,
        searchText
      ),
    },
  ];

  return { options, loadOptions };
}

function DeviceAddon({
  fields,
  index,
  name,
  canAdd,
  add: addOuter,
  remove,
  move,
}) {
  const { infoMessage } = useFlashMessageContext();

  const add = useCallback(
    (...args) => {
      if (fields.length >= MAX_DEVICES - 1) {
        infoMessage({ contentId: 'book.newShipment.warning.device.maxNumber' });
      }
      addOuter(...args);
    },
    [addOuter, fields.length, infoMessage]
  );

  const { addAfter, removeThis } = useFormListCallbacks({
    fields,
    index,
    add,
    remove,
    move,
  });
  const { values, formInstance, forceUpdate } = useFormContext();
  const type = get(values, `deviceAddons[${index}].type`);
  const searchText = get(values, `deviceAddons[${index}].brandName`);
  const { options, loadOptions } = useOptions({ searchText });

  const fillDeviceAddon = useCallback(
    vals => {
      const deviceAddons = [...formInstance.getFieldValue('deviceAddons')];
      const deviceAddon = merge({}, deviceAddons[index], vals);
      deviceAddons[index] = deviceAddon;
      formInstance.setFieldsValue({ deviceAddons });
      forceUpdate();
    },
    [forceUpdate, formInstance, index]
  );

  const onSelect = useCallback(
    ({ entry }) => {
      fillDeviceAddon({
        type: entry.type,
        device: pick(entry, ['code', 'text']),
        brandName: entry.brandName,
        modelName: entry.modelName,
      });
    },
    [fillDeviceAddon]
  );
  const onSearch = useCallback(
    text => {
      fillDeviceAddon({
        type: null,
        device: null,
        brandName: text,
        modelName: '',
      });
    },
    [fillDeviceAddon]
  );

  return (
    <div
      className="NewShipmentPackage-DeviceAddon"
      data-subject="device-addons"
      data-role="item"
    >
      <Row align="middle" gutter={pxToNumber(cssVariables.spaceSm)}>
        <Col>
          <FlexCol
            style={{ height: cssVariables['input-height-md'] }}
            justify={canAdd ? 'space-between' : 'center'}
          >
            <IconButton
              icon="times-circle"
              type={FATypes.SOLID}
              className="MonitorDeviceIcon"
              onClick={removeThis}
              data-subject="device-addons"
              data-action="delete"
            />
            {canAdd && (
              <IconButton
                icon="plus-square"
                type={FATypes.SOLID}
                className="MonitorDeviceIcon"
                onClick={addAfter}
                data-subject="device-addons"
                data-action="add-after"
              />
            )}
          </FlexCol>
        </Col>
        <Col className="Flex1">
          <div className="spaces-vert-norm">
            <Row align="top" gutter={pxToNumber(cssVariables.spaceNorm)}>
              <Col span={12}>
                <FormItemCustomGroupAutocomplete
                  className="CustomGroupAutocomplete__WithArrow"
                  name={mergeFormNames(name, 'brandName')}
                  labelId="book.newShipment.label.device.brandName"
                  options={options}
                  formItemComponentProps={{
                    onFocus: loadOptions,
                    onSearch,
                    onSelect,
                    setLabelOnSelect: false,
                  }}
                  labelCol={{ span: 0 }}
                  wrapperCol={{ span: 24 }}
                />
                <FormItemFieldRegistration
                  name={mergeFormNames(name, 'device')}
                />
                <FormItemFieldRegistration
                  name={mergeFormNames(name, 'type')}
                />
              </Col>
              <Col span={12}>
                <FormItemInputText
                  name={mergeFormNames(name, 'modelName')}
                  labelId="book.newShipment.label.device.modelName"
                  disabled
                />
              </Col>
            </Row>
            <Row align="top" gutter={pxToNumber(cssVariables.spaceNorm)}>
              <Col span={12}>
                {type ? (
                  <FormItemSchemaDefinedSelect
                    name={mergeFormNames(name, 'source')}
                    schemaName={`${SCHEMA_NAME_PREFIXES[type]}.source`}
                    labelId="book.newShipment.label.device.source"
                  />
                ) : (
                  <>
                    {/* Only for styling - it has a different format of value, which might lead to errors */}
                    <FormItemSelect
                      labelId="book.newShipment.label.device.source"
                      options={[]}
                    />
                    <FormItemFieldRegistration
                      name={mergeFormNames(name, 'source')}
                    />
                  </>
                )}
              </Col>
              <Col span={12}>
                <FormItemInputText
                  name={mergeFormNames(name, 'serialNumber')}
                  schemaName={
                    type
                      ? `${SCHEMA_NAME_PREFIXES[type]}.serialNumber`
                      : undefined
                  }
                  labelId="book.newShipment.label.device.serialNumber"
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

function renderDeviceAddons(fields, { add, remove, move }) {
  return (
    <div className="spaces-vert-norm">
      <EnsureInitialFormListItem fields={fields} add={add} />
      {(fields || []).map(({ name, key }, index) => (
        <DeviceAddon
          key={key}
          name={name}
          fields={fields}
          index={index}
          canAdd={fields.length < MAX_DEVICES}
          add={add}
          remove={remove}
          move={move}
        />
      ))}
    </div>
  );
}

export function PackageListDeviceAddonsField() {
  return <FormList name="deviceAddons">{renderDeviceAddons}</FormList>;
}
