import { useQuery } from '@apollo/client';

import { ACCOUNT_SHIPMENT_INFO_QUERY } from '../../../app/graphql/accountQueries';

export default function useNewShipmentAsyncConfig({ account }) {
  const accountDetailResult = useQuery(ACCOUNT_SHIPMENT_INFO_QUERY, {
    variables: { accountNumber: account?.number },
    skip: !account,
  });

  const isQuoteEnabled =
    accountDetailResult.data?.accountShipmentInfo?.isQuoteEnabled;

  return { accountDetailResult, isQuoteEnabled };
}
