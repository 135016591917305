export function nextEventPromise(element, event) {
  return new Promise(resolve => {
    const listener = () => {
      element.removeEventListener(event, listener);
      resolve();
    };
    element.addEventListener(event, listener);
  });
}

export function triggerEvent(element, type) {
  element.dispatchEvent(new CustomEvent(type));
}
