import { Menu } from 'antd';
import classNames from 'classnames';
import { first, get, property } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { usePageGuard } from '../../../components/guards/PageGuard';
import {
  isMenuItemActive,
  renderMenuDefinition,
  useActiveRoute,
} from '../../../utils/routeUtils';

export default function AdminConsoleSideMenu({ items }) {
  const activeRoute = get(useActiveRoute(items), 'to');
  const props = { activeRoute };

  return (
    <div className="AdminConsoleSideMenu">
      {renderMenuDefinition(items, {
        renderMenuItem,
        renderSubmenu,
        props,
      })}
    </div>
  );
}

function MenuItem({
  component: C,
  className,
  label,
  labelId,
  to,
  children,
  disabled: disabledOuter,
  active: activeOuter,
  activeRoute,
  guard,
  level,
  ...rest
}) {
  const allowed = usePageGuard(guard);
  const disabled = disabledOuter || !allowed;
  const active =
    activeOuter || (to && isMenuItemActive(activeRoute, { to, children }));

  return (
    <div
      key={labelId || label}
      className={classNames(
        'AdminConsoleSideMenu__Item',
        `AdminConsoleSideMenu__Item--Level${level}`,
        { active, disabled },
        className
      )}
      {...rest}
    >
      <Link className="AdminConsoleSideMenu__ItemInner" to={to}>
        {labelId ? <FormattedMessage id={labelId} /> : label}
      </Link>
    </div>
  );
}

function renderMenuItem(
  { label, labelId, to, guard, className, disabled, children },
  { activeRoute } = {},
  { level } = {}
) {
  return (
    <MenuItem
      component={Menu.Item}
      key={labelId || label}
      to={to}
      label={label}
      labelId={labelId}
      disabled={!to || disabled}
      activeRoute={activeRoute}
      guard={guard}
      level={level}
      className={className}
      // eslint-disable-next-line react/no-children-prop
      children={children}
    />
  );
}

function renderSubmenu({ children, ...menuItem }, recurse, data, props) {
  return (
    <div key={menuItem.labelId || menuItem.label}>
      {renderMenuItem(
        {
          to: first(children?.map(property('to'))),
          className: 'AdminConsoleSideMenu__Item--HasSubmenu',
          children,
          ...menuItem,
        },
        props,
        data
      )}
      <div
        className={classNames(
          'AdminConsoleSideMenu__Submenu',
          `AdminConsoleSideMenu__Submenu--Level${data.level}`
        )}
      >
        {recurse(children)}
      </div>
    </div>
  );
}
