import AddressBookControls from '../../../dialogs/shipments/AddressBookControls';
import {
  DeliveryDateTimeField,
  PickupDateTimeField,
} from '../shipmentFormsCommon/shipmentDateFields';
import { JobLocationCards, NewShipmentForm } from './newShipmentElements';

export { default as NewShipmentPackageListForm } from './NewShipmentPackageListForm';
export { default as NewShipmentServiceInformationForm } from './NewShipmentServiceInformationForm';

export function NewShipmentFromAddressForm(props) {
  return (
    <NewShipmentForm {...props} name="origin">
      <JobLocationCards
        schemaNamePrefix="origin"
        firstTitleId="book.newShipment.fromAddress.dateSection.title"
        dateSection={<PickupDateTimeField />}
        secondTitleId="book.newShipment.fromAddress"
        renderSecondHeaderExtra={({ type }) => (
          <AddressBookControls name="origin" type={type} />
        )}
        enableAddressLookup
      />
    </NewShipmentForm>
  );
}

export function NewShipmentToAddressForm(props) {
  return (
    <NewShipmentForm {...props} name="destination">
      <JobLocationCards
        schemaNamePrefix="destination"
        firstTitleId="book.newShipment.toAddress.dateSection.title"
        dateSection={<DeliveryDateTimeField />}
        secondTitleId="book.newShipment.toAddress"
        renderSecondHeaderExtra={({ type }) => (
          <AddressBookControls name="destination" type={type} />
        )}
        enableAddressLookup
      />
    </NewShipmentForm>
  );
}
