import { useApolloClient } from '@apollo/client';
import { useContext, useEffect, useRef } from 'react';

import { extractGraphqlEntity } from '../../../common/utils/graphqlUtils';
import { useDebouncedAsyncCallback } from '../../../common/utils/promiseUtils';
import { useStateIfMounted } from '../../../utils/reactUtils';
import { useFlashMessageContext } from '../../dialogs/FlashMessageProvider';
import { useDynamicFieldDependencies } from './dynamicFormDependencies';
import {
  DynamicFormSchemaContext,
  useDynamicFormSchemaFieldDefinition,
} from './dynamicFormSchema';

const DEBOUNCE_DELAY = 500;

export function useDynamicSelectOptionsLoader({ schemaName, preloadOptions }) {
  const fieldDef = useDynamicFormSchemaFieldDefinition({ schemaName });

  const { optionsQuery, schema } = useContext(DynamicFormSchemaContext);
  const { errorMessage } = useFlashMessageContext();

  const client = useApolloClient();

  const prevSearchText = useRef();
  const [options, setOptions] = useStateIfMounted([]);
  const [loading, setLoading] = useStateIfMounted(preloadOptions);

  const { dependencies } = useDynamicFieldDependencies(
    fieldDef?.option?.dependsOn || []
  );

  const loadOptions = useDebouncedAsyncCallback(
    async searchText => {
      const schemaIsNotLoaded = !schema;
      if (schemaIsNotLoaded) {
        return;
      }

      // No change - no need to load
      if (searchText === prevSearchText.current) {
        return;
      }

      setLoading(true);
      try {
        const { data } = await client.query({
          query: optionsQuery,
          variables: {
            input: {
              fieldName: schemaName,
              value: searchText,
              params: dependencies,
            },
          },
        });
        prevSearchText.current = searchText;
        setOptions(extractGraphqlEntity(data)?.values || []);
      } catch (e) {
        errorMessage(e);
        setOptions([]);
        prevSearchText.current = undefined;
      } finally {
        setLoading(false);
      }
    },
    [client, dependencies, errorMessage, optionsQuery, schema, schemaName],
    { delay: DEBOUNCE_DELAY }
  );

  // Clear state on dependency change
  useEffect(() => {
    if (preloadOptions) {
      loadOptions('');
    } else {
      setOptions([]);
      prevSearchText.current = undefined;
    }
  }, [dependencies, loadOptions, preloadOptions, setOptions]);

  return { options, loading, loadOptions };
}
