import { pick } from 'lodash-es';

import { config } from '../../config/config';

export const timestamp = () => new Date().toISOString();

export function debugEnvInfo() {
  return `CLIENT DEBUG REPORT INFO
  Browser: ${navigator.userAgent}
  URL: ${window.location.href}
  Date: ${timestamp()}
  Config: ${JSON.stringify(
    pick(config, ['version', 'commitHash', 'commitTimestamp', 'logLevel'])
  )}`;
}
