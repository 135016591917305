import { camelCase } from 'lodash-es';

import {
  NewShipmentFromAddressForm,
  NewShipmentPackageListForm,
  NewShipmentServiceInformationForm,
  NewShipmentToAddressForm,
} from './newShipmentForms';

export const NEW_SHIPMENT_STEPS = [
  { name: 'from-address', component: NewShipmentFromAddressForm },
  { name: 'to-address', component: NewShipmentToAddressForm },
  {
    name: 'shipment-information',
    component: NewShipmentServiceInformationForm,
  },
  { name: 'package-list', component: NewShipmentPackageListForm },
].map(({ name, ...rest }) => ({
  ...rest,
  titleId: `book.newShipment.step.${camelCase(name)}`,
  shortTitleId: `book.newShipment.step.${camelCase(name)}.short`,
  name,
}));
