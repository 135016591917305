import { gql } from '@apollo/client';

import { accountInfoFragment } from './accountQueries';

const UserFullFragment = `
  fragment UserFull on User {
    id
    email
    firstName
    lastName
    phone
    active
    isAdmin
    isLocked
    accounts {${accountInfoFragment}}
    reportsEnabled
    settings {
      unitSystem
      dateFormat
    }
    accountGroups {
      code
      name
      accounts {
        number
        name
      }
    }
    partnerGroups {
      code
      name
      accounts {
        number
        name
      }
    }
    documentTypes {
      code
      text
    }
    documentGroup {
      code
      text
    }
    expandedTracking {
      shipper {
        city {
          operation
          text
        }
        name {
          operation
          text
        }
        address {
          operation
          text
        }
      }
      consignee {
        city {
          operation
          text
        }
        name {
          operation
          text
        }
        address {
          operation
          text
        }
      }
    }
  }
`;

export const USERS_LIST_QUERY = gql`
  query Users(
    $pageIndex: Int!
    $pageSize: Int!
    $filter: UserFilter
    $sort: Sort
  ) {
    users(
      pageIndex: $pageIndex
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      data {
        id
        firstName
        lastName
        email
        active
        accountsCount
        accountGroupsCount
        partnerGroupsCount
      }
      pageIndex
      pageSize
      total
    }
  }
`;

export const USER_DETAIL_QUERY = gql`
  ${UserFullFragment}
  query User($id: String!) {
    user(id: $id) {
      ...UserFull
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  ${UserFullFragment}
  mutation UpdateUserMutation($id: String!, $input: UserInput!) {
    updateUser(id: $id, input: $input) {
      ...UserFull
    }
  }
`;

export const CREATE_USER_MUTATION = gql`
  ${UserFullFragment}
  mutation CreateUserMutation($input: UserInput!) {
    createUser(input: $input) {
      ...UserFull
    }
  }
`;

export const UNLOCK_USER_MUTATION = gql`
  mutation UnlockUserMutation($id: String!) {
    unlockUser(id: $id)
  }
`;

export const ACCOUNTS_SIMPLE_QUERY = gql`
  query AccountsSimpleQuery {
    accountsSimple {
      number
      name
    }
  }
`;

export const ACCOUNT_GROUPS_QUERY = gql`
  query AccountGroupsQuery {
    accountGroups {
      code
      text
    }
  }
`;

export const PARTNER_GROUPS_QUERY = gql`
  query PartnerGroupsQuery {
    partnerGroups {
      code
      text
    }
  }
`;

export const DOCUMENT_TYPES_QUERY = gql`
  query DocumentTypesQuery {
    documentTypes {
      code
      text
    }
  }
`;

export const DOCUMENT_GROUPS_QUERY = gql`
  query DocumentGroupsQuery {
    documentGroups {
      code
      text
    }
  }
`;

export const SERVICE_TYPES_QUERY = gql`
  query ServiceTypesQuery {
    serviceTypes {
      code
      text
    }
  }
`;

export const COMMODITIES_QUERY = gql`
  query CommoditiesQuery {
    commodities {
      code
      text
    }
  }
`;

export const GPS_DEVICES_QUERY = gql`
  query GPSDevicesQuery {
    gpsDevices {
      code
      text
    }
  }
`;

export const TEMPERATURE_DEVICES_QUERY = gql`
  query TemperatureDevicesQuery {
    temperatureDevices {
      code
      text
    }
  }
`;

export const PACKAGE_TYPES_QUERY = gql`
  query PackageTypesQuery {
    packageTypes {
      code
      text
    }
  }
`;

export const PACKAGE_TEMPERATURES_QUERY = gql`
  query PackageTemperaturesQuery {
    packageTemperatures {
      code
      text
    }
  }
`;
