import { Menu } from 'antd';
import classNames from 'classnames';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { LOGOUT_MUTATION } from '../app/graphql/authQueries';
import routes from '../app/routes';
import { formatUser } from '../common/utils/formatUtils';
import IconButton from '../components/buttons/IconButton';
import { GraphQLMutatingLink } from '../components/data/asyncActionElements';
import { DropdownMenu } from '../components/nav/navElements';
import { useResponsiveQueries } from '../components/responsive/responsiveQueries';
import { InlineLink } from '../components/typography';
import { useLogoutHandler } from '../hooks/data/auth';
import { ReportsDialog } from './ReportsDialog';

const ALIGN_TEXT = {
  points: ['tr', 'br'],
  offset: [22, 2],
  overflow: {
    adjustX: 0,
    adjustY: 1,
  },
};

const ALIGN_ICON = {
  points: ['tr', 'br'],
  offset: [9, 6],
  overflow: {
    adjustX: 0,
    adjustY: 1,
  },
};

function UserLabel({ user }) {
  const media = useResponsiveQueries();
  const isSm = media.sm || media.xs;

  if (isSm) {
    return <IconButton icon="user-circle icon-21" />;
  }

  return (
    <div className="Account-UserLabel">
      <IconButton icon="user-circle icon-14" />
      <span>{formatUser(user)}</span>
    </div>
  );
}

export function AccountDropdown({ user }) {
  const { onLogoutSuccess, onLogoutError } = useLogoutHandler();
  const [reportsOpen, setReportsOpen] = useState(false);
  const media = useResponsiveQueries();
  const isSm = media.sm || media.xs;
  const isMd = media.md;

  return (
    <>
      <DropdownMenu
        overlayClassName={classNames({ IconTrigger: isSm })}
        label={<UserLabel user={user} />}
        noArrow={isSm}
        trigger={isSm || isMd ? 'click' : 'hover'}
        align={isSm ? ALIGN_ICON : ALIGN_TEXT}
      >
        {user.reportsEnabled && (
          <Menu.Item key="reports">
            <InlineLink onClick={() => setReportsOpen(true)}>
              <FormattedMessage id="account.reports" />
            </InlineLink>
          </Menu.Item>
        )}
        {user.isAdmin && (
          <Menu.Item key="admin">
            <Link to={routes.admin.userManagement}>
              <FormattedMessage id="account.admin" />
            </Link>
          </Menu.Item>
        )}
        <Menu.Item key="logout">
          <GraphQLMutatingLink
            mutation={LOGOUT_MUTATION}
            onDone={onLogoutSuccess}
            onError={onLogoutError}
            textId="account.logout"
          />
        </Menu.Item>
      </DropdownMenu>
      <ReportsDialog
        visible={reportsOpen}
        close={() => setReportsOpen(false)}
      />
    </>
  );
}
