/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */

if (process.env.NODE_ENV !== 'production') {
  const { PolyfillChecker, createReactAppPreset } = require('polyfill-checker');

  const checker = new PolyfillChecker({
    minBrowsers: {
      chrome: '60',
      chrome_android: '60',
      edge: '12',
      edge_mobile: false,
      firefox: '54',
      firefox_android: '54',
      ie: false,
      opera: false,
      opera_android: false,
      safari: '10',
      safari_ios: '10',
      webview_android: false,
    },
    exclude: [
      createReactAppPreset,
      // used in: react-dom.development.js
      'WeakMap',
      name => name.indexOf('Set') !== 0,
      name => name.indexOf('Map') !== 0,
      // used in: React DevTools extension
      'String.prototype.codePointAt',
      'Number.isInteger',
    ],
  });
  checker.downgradeMode();
}

export async function loadPolyfills() {
  if (!Array.prototype.includes) {
    require('core-js/features/array/includes');
  }
  if (!String.prototype.endsWith) {
    require('core-js/features/string/ends-with');
  }
  if (!String.prototype.startsWith) {
    require('core-js/features/string/starts-with');
  }
  let IntlPolyfill;
  if (!window.Intl) {
    IntlPolyfill = require('intl');
    window.Intl = IntlPolyfill;
  }
  if (!window.Intl.NumberFormat || !window.Intl.DateTimeFormat) {
    IntlPolyfill = IntlPolyfill || require('intl');
    window.Intl.NumberFormat = IntlPolyfill.NumberFormat;
    window.Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat;
  }
  if (IntlPolyfill) {
    // https://github.com/andyearnshaw/Intl.js/issues/231#issuecomment-291049802
    IntlPolyfill.__disableRegExpRestore();
    require('intl/locale-data/jsonp/en');
  }
  if (
    !('ResizeObserver' in global) ||
    !('ResizeObserverEntry' in global) ||
    !('contentRect' in ResizeObserverEntry.prototype)
  ) {
    require('resize-observer-polyfill');
  }
}
