import { useQuery } from '@apollo/client';

import { TEMPERATURE_DEVICES_QUERY } from '../../../../app/graphql/adminQueries';
import AdminAccountTransferPage from './AdminAccountTransferPage';

const AdminAccountTemperatureDevices = ({ account, viewMode, visible }) => {
  const { data, loading } = useQuery(TEMPERATURE_DEVICES_QUERY, {
    skip: !visible,
  });

  return (
    <AdminAccountTransferPage
      account={account}
      viewMode={viewMode}
      visible={visible}
      itemName="tempDevicesSettings"
      titleIcon="temperature-high"
      titleId="admin.accountManagement.accountDetail.temperatureDevices"
      titlesPrefix="admin.accountManagement.accountDetail.temperature"
      data={data?.temperatureDevices}
      loading={loading}
    />
  );
};

export default AdminAccountTemperatureDevices;
