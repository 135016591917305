import { FormattedMessage } from 'react-intl';

import {
  CopyToClipboardButton,
  CopyToClipboardHoverArea,
} from '../../components/widgets/CopyToClipboard';
import { TwoLineLabelWithValue } from './shipmentSummaries/shipmentSummariesCommon';

export default function CopiableJobNumber({ jobNumber }) {
  return (
    <CopyToClipboardHoverArea alwaysVisible>
      <TwoLineLabelWithValue
        size="md"
        label={
          <>
            <FormattedMessage id="shipmentDetail.jobNumber" />
            {jobNumber && (
              <CopyToClipboardButton
                tooltipId="job.jobNumber.copy"
                className="icon-11"
                getContent={() => jobNumber}
              />
            )}
          </>
        }
        text={jobNumber}
        valueProps={{ 'data-info-id': 'jobNumber' }}
      />
    </CopyToClipboardHoverArea>
  );
}
