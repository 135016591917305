import { Col, Row } from 'antd';
import classNames from 'classnames';
import { ary } from 'lodash-es';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { ExpandedTrackingOperationValues } from '../../../../app/enums/ExpandedTrackingOperation';
import { FATypes } from '../../../../components/adapters/fontAwesomeAdapters';
import IconButton from '../../../../components/buttons/IconButton';
import {
  FormItemOverrideContext,
  FormItemsOverrideContextProvider,
} from '../../../../components/forms/FormItem';
import FormList from '../../../../components/forms/FormList';
import { FormItemInputText } from '../../../../components/forms/basicFormElements';
import { EnsureInitialFormListItem } from '../../../../components/forms/formHelpers';
import {
  FormTheme,
  FormThemeOverrideContextProvider,
} from '../../../../components/forms/formTheme';
import { FormItemEnumSelect } from '../../../../components/forms/selects';
import { cssVariables } from '../../../../styles/cssVariables';
import { pxToNumber } from '../../../../utils/cssUtils';
import AdminConsoleSection, {
  AdminConsoleSectionContent,
  AdminConsoleSectionSubheader,
  AdminConsoleSectionTitleHeader,
} from '../../components/AdminConsoleSection';

const EMPTY_ITEM = { operations: undefined, text: '' };
const MAX_ITEMS = 3;

function Item({ add, remove, name }) {
  return (
    <Row align="top" gutter={pxToNumber(cssVariables.spaceNorm2)}>
      <FormThemeOverrideContextProvider
        component={Col}
        themeClassName={FormTheme.DarkSimple}
      >
        <FormItemEnumSelect
          name={[name, 'operation']}
          className="width-140"
          floatingLabel={false}
          intlPrefix="admin.userManagement.forms.expandedTrackingItem.operationType"
          intlValues={ExpandedTrackingOperationValues}
          placeholderId="admin.userManagement.forms.expandedTrackingItem.operationType.placeholder"
        />
      </FormThemeOverrideContextProvider>
      <FormThemeOverrideContextProvider
        component={Col}
        themeClassName={FormTheme.DarkOrangeSimple}
        className="Flex1"
      >
        <FormItemInputText name={[name, 'text']} floatingLabel={false} />
      </FormThemeOverrideContextProvider>
      {(remove || add) && (
        <Col className="AdminUserForms__ExpandedTracking__ListControls">
          {remove && (
            <IconButton
              icon="trash-alt"
              type={FATypes.REGULAR}
              className="icon-20"
              onClick={remove}
            />
          )}
          {add && (
            <IconButton icon="plus-square" className="icon-18" onClick={add} />
          )}
        </Col>
      )}
    </Row>
  );
}

function renderItems(fields, { add, remove }) {
  return (
    <>
      <EnsureInitialFormListItem
        fields={fields}
        add={add}
        value={EMPTY_ITEM}
        initialCount={1}
      />
      <div className="spaces-vert-norm1_5 no-margin-form-items">
        {fields.map((field, i) => {
          const canAdd = i === fields.length - 1 && fields.length < MAX_ITEMS;
          const canRemove = i > 0;

          return (
            <Item
              {...field}
              add={canAdd && ary(add, 0)}
              remove={canRemove && (() => remove(i))}
            />
          );
        })}
      </div>
      <div className="AdminUserForms__ExpandedTracking__MaxItemsLabel">
        <FormattedMessage
          id="admin.userManagement.forms.expandedTrackingItem.maxItems"
          values={{ count: MAX_ITEMS }}
        />
      </div>
    </>
  );
}

function Items({ name, titleId }) {
  return (
    <>
      <AdminConsoleSectionSubheader size="lg">
        <FormattedMessage id={titleId} />
      </AdminConsoleSectionSubheader>
      <AdminConsoleSectionContent>
        <FormList name={name}>{renderItems}</FormList>
      </AdminConsoleSectionContent>
    </>
  );
}

function Section({ name, titleId }) {
  const fullName = ['expandedTracking', name];
  return (
    <AdminConsoleSection>
      <AdminConsoleSectionTitleHeader>
        <strong>
          <FormattedMessage id={titleId} />
        </strong>
      </AdminConsoleSectionTitleHeader>
      <Items
        name={[...fullName, 'city']}
        titleId="admin.userManagement.forms.expandedTrackingItem.city"
      />
      <Items
        name={[...fullName, 'name']}
        titleId="admin.userManagement.forms.expandedTrackingItem.name"
      />
      <Items
        name={[...fullName, 'address']}
        titleId="admin.userManagement.forms.expandedTrackingItem.address"
      />
    </AdminConsoleSection>
  );
}

export default function AdminUserExpandedTrackingFormSections() {
  const { viewMode } = useContext(FormItemOverrideContext);
  return (
    <FormItemsOverrideContextProvider viewMode={false}>
      <div
        className={classNames('spaces-vert-norm3', {
          'DisabledBlock--35': viewMode,
        })}
      >
        <Section name="shipper" titleId="admin.userManagement.forms.shipper" />
        <Section
          name="consignee"
          titleId="admin.userManagement.forms.consignee"
        />
      </div>
    </FormItemsOverrideContextProvider>
  );
}
