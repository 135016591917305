export const PAGE_ONE = 1;
export const DEFAULT_PAGE_SIZE = 10;

export const LOCAL_STORAGE_AUTH_KEY = 'Auth/LoginState';
export const LOCAL_STORAGE_DIALOG_DONT_SHOW_AGAIN_KEY = dialogId =>
  `Dialogs/${dialogId}/DontShowAgain`;

export const DEFAULT_POLL_REFRESH_INTERVAL = 120000; // 2 minutes
export const REDIRECT_TIMEOUT = 3000; // 3 seconds
export const MAX_CONCURRENT_FILE_UPLOADS = 4;
export const DEFAULT_UPLOAD_TIMEOUT = 120000;
// Keep in sync with ML, translation (to prevent the need for filesize formatter) and python microservice
export const MAX_UPLOAD_FILE_SIZE = 10 * 1024 * 1024; // 10MB

export const HttpStatusCodes = {
  UNAUTHENTICATED: 401,
  INTERNAL_SERVER_ERROR: 500,
};
