import PackageListPackages from '../packageList/PackageListPackages';
import PackageListSummary from '../packageList/PackageListSummary';
import { NewShipmentForm } from './newShipmentElements';

export default function NewShipmentPackageListForm({
  onEditedPackageChange,
  activeTemplateCounter,
  ...rest
}) {
  return (
    <NewShipmentForm
      {...rest}
      name="packages"
      className="NewShipmentPackageListForm margins-bottom-norm2"
    >
      <PackageListSummary />
      <PackageListPackages
        onEditedPackageChange={onEditedPackageChange}
        activeTemplateCounter={activeTemplateCounter}
      />
    </NewShipmentForm>
  );
}
