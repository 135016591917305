import { Col, Row, Typography } from 'antd';
import classNames from 'classnames';
import { noop } from 'lodash-es';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { FAIcon } from '../adapters/fontAwesomeAdapters';
import Collapsible from './Collapsible';
import { TwoLines } from './layoutElements';

export function ConfigurableCard({
  bodyComponent: BC = Row,
  bodyProps,
  className,
  headerClassName,
  bodyClassName,
  footerClassName,
  title,
  titleId,
  headerExtra,
  children,
  footer,
  ...rest
}) {
  const hasHeader = title || titleId || headerExtra;

  return (
    <div
      className={classNames(
        'ConfigurableCard',
        { 'no-header': !hasHeader },
        className
      )}
      {...rest}
    >
      {hasHeader && (
        <Row
          className={classNames('ConfigurableCard-Header', headerClassName)}
          align="middle"
          justify="space-between"
        >
          <Col>
            <Typography.Title level={4}>
              {titleId ? <FormattedMessage id={titleId} /> : title}
            </Typography.Title>
          </Col>
          <Col>{headerExtra}</Col>
        </Row>
      )}
      <BC
        className={classNames(
          'ConfigurableCard-Body',
          { 'no-footer': !footer },
          bodyClassName
        )}
        {...bodyProps}
      >
        {children}
      </BC>
      {footer && (
        <div className={classNames('ConfigurableCard-Footer', footerClassName)}>
          {footer}
        </div>
      )}
    </div>
  );
}

export const SimpleCardTheme = {
  ROUND_BORDERED: 'ROUND_BORDERED',
  FLAT: 'FLAT',
};

export function SimpleCard({
  className,
  titleId,
  title,
  children,
  theme = SimpleCardTheme.FLAT,
  bodyProps = {},
  collapsible,
  headerExtraContent,
}) {
  const [expanded, setExpanded] = useState(true);

  return (
    <div
      className={classNames(
        'SimpleCard',
        `SimpleCardTheme-${theme}`,
        { collapsible },
        className
      )}
    >
      <div
        className="SimpleCard-Header"
        onClick={collapsible ? () => setExpanded(!expanded) : noop}
      >
        <Typography.Title level={4}>
          {titleId ? <FormattedMessage id={titleId} /> : title}
        </Typography.Title>
        {headerExtraContent}
        {collapsible && (
          <FAIcon
            icon={expanded ? 'caret-up' : 'caret-down'}
            className="icon-20"
          />
        )}
      </div>
      {theme === SimpleCardTheme.ROUND_BORDERED && (
        <TwoLines className="size-10" />
      )}
      <div
        {...bodyProps}
        className={classNames('SimpleCard-Body', bodyProps.className)}
      >
        {collapsible ? (
          <Collapsible expanded={expanded} dynamicContent>
            {children}
          </Collapsible>
        ) : (
          children
        )}
      </div>
    </div>
  );
}

export function SimpleCardSubtitle({ className, titleId, title, ...rest }) {
  return (
    <div className={classNames('SimpleCard-Subtitle', className)} {...rest}>
      <span>{titleId ? <FormattedMessage id={titleId} /> : title}</span>
    </div>
  );
}
