import { useRef } from 'react';

import { FormItemSearch } from '../components/forms/searchFormElements';
import { externalLinks } from '../config/externalLinks';

export default function TrackSearchForm() {
  const form = useRef();
  return (
    <form
      ref={form}
      target="_blank"
      method="POST"
      action={externalLinks.trackSearch}
    >
      <FormItemSearch
        labelId="form.track.label"
        placeholderId="form.track.placeholder"
        formItemComponentProps={{ className: 'TrackNumberInput', name: 'bol' }}
        onSearch={() => form.current.submit()}
      />
    </form>
  );
}
