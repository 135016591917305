import { useQuery } from '@apollo/client';

import { PACKAGE_TEMPERATURES_QUERY } from '../../../../app/graphql/adminQueries';
import AdminAccountTransferPage from './AdminAccountTransferPage';

const AdminAccountPackTemperature = ({ account, viewMode, visible }) => {
  const { data, loading } = useQuery(PACKAGE_TEMPERATURES_QUERY, {
    skip: !visible,
  });

  return (
    <AdminAccountTransferPage
      account={account}
      viewMode={viewMode}
      visible={visible}
      titleIcon="location-arrow"
      titleId="admin.accountManagement.accountDetail.packageTemps"
      itemName="packageTempSettings"
      titlesPrefix="admin.accountManagement.accountDetail.packTemp"
      data={data?.packageTemperatures}
      loading={loading}
    />
  );
};

export default AdminAccountPackTemperature;
