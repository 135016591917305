import { useMutation } from '@apollo/client';
import { Typography } from 'antd';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { PASSWORD_RESET_INIT_MUTATION } from '../app/graphql/authQueries';
import { HeaderLogo } from '../components/Logo';
import Whitespace from '../components/Whitespace';
import { FormItemInputText } from '../components/forms/basicFormElements';
import { NoLabelForm, SubmitButton } from '../components/forms/forms';
import { useResponsiveQueries } from '../components/responsive/responsiveQueries';
import {
  AuthEnvelope,
  AuthEnvelopeTitle,
  useAuthErrorMessage,
} from '../widgets/AuthEnvelope';

function EnterEmailFormContent({ onSubmit: onSubmitOuter }) {
  const media = useResponsiveQueries();
  const isSm = media.xs || media.sm;

  const errorMessage = useAuthErrorMessage();
  const [initPasswordReset] = useMutation(PASSWORD_RESET_INIT_MUTATION);

  const onSubmit = useCallback(
    async ({ email }) => {
      try {
        await initPasswordReset({ variables: { email } });
        onSubmitOuter && onSubmitOuter(email);
      } catch (e) {
        errorMessage(e);
      }
      return null;
    },
    [errorMessage, initPasswordReset, onSubmitOuter]
  );

  return (
    <NoLabelForm onSubmit={onSubmit} hideRequiredMark>
      <Typography.Title level={4}>
        <FormattedMessage id="passwordReset.init.envelope.subtitle.fragment1" />
        {isSm ? <Whitespace /> : <br />}
        <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>
          <FormattedMessage id="passwordReset.init.envelope.subtitle.fragment2" />
        </span>
        <Whitespace />
        <FormattedMessage id="passwordReset.init.envelope.subtitle.fragment3" />
        {isSm ? <Whitespace /> : <br />}
        <a href="https://quickonline.com">quickonline.com</a>
        <Whitespace />
        <FormattedMessage id="passwordReset.init.envelope.subtitle.fragment4" />
      </Typography.Title>
      <FormItemInputText
        name="email"
        labelId="form.email.label"
        rules={[{ required: true }]}
      />
      <SubmitButton type="primary" textId="passwordReset.init.action" />
    </NoLabelForm>
  );
}

function EnterEmailForm({ onSubmit }) {
  return (
    <AuthEnvelope className="PasswordResetInitForm">
      <HeaderLogo />
      <AuthEnvelopeTitle textId="passwordReset.init.envelope.title" />
      <EnterEmailFormContent onSubmit={onSubmit} />
    </AuthEnvelope>
  );
}

function SuccessInfoMessage({ email }) {
  const media = useResponsiveQueries();
  const isSm = media.xs || media.sm;

  return (
    <AuthEnvelope className="PasswordResetEmailSentMessage">
      <HeaderLogo />
      <AuthEnvelopeTitle textId="passwordReset.emailSent.envelope.title" />
      <Typography.Title
        level={4}
        className={classNames({ 'nl-as-newline': !isSm })}
      >
        <FormattedMessage
          id="passwordReset.emailSent.envelope.subtitle"
          values={{ email: <strong>{email}</strong> }}
        />
      </Typography.Title>
    </AuthEnvelope>
  );
}

export default function PasswordResetInitForm() {
  const [email, setEmail] = useState();

  if (!email) {
    return (
      <EnterEmailForm
        onSubmit={val => {
          // These will cause unmounting, so we want to wait until all operations are completed
          window.requestAnimationFrame(() => setEmail(val));
        }}
      />
    );
  }

  return <SuccessInfoMessage email={email} />;
}
