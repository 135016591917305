import { useApolloClient } from '@apollo/client';
import { Col, Row } from 'antd';
import classNames from 'classnames';
import { every, isNumber } from 'lodash-es';
import { useCallback, useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { DIMENSIONAL_WEIGHT_QUERY } from '../../../app/graphql/jobQueries';
import { extractGraphqlEntity } from '../../../common/utils/graphqlUtils';
import { SettingsContext } from '../../../components/domainSpecific/settingsElements';
import { useDynamicOriginalValueDependency } from '../../../components/forms/dynamic/dynamicFormDependencies';
import { ConfigurableCard } from '../../../components/layout/cardElements';
import {
  TwoLines,
  TwoLinesSeparator,
} from '../../../components/layout/layoutElements';
import { useResponsiveQueries } from '../../../components/responsive/responsiveQueries';
import { cssVariables } from '../../../styles/cssVariables';
import { pxToNumber } from '../../../utils/cssUtils';
import { withMergedClass } from '../../../utils/reactUtils';

export function usePackageListResponsiveQueries() {
  const media = useResponsiveQueries();
  const isSm = media.xs || media.sm || media.md;
  const isLg = media.lg || media.xl || media.xxl;

  return { isSm, isLg, media };
}

export function useServiceInformationDeps() {
  const commodity = useDynamicOriginalValueDependency(
    'serviceInformation.commodity'
  );

  return { commodity };
}

export const DividerCol = ({ className, smallVerticalSpace }) => (
  <Col
    style={{
      alignSelf: 'stretch',
      margin: `${smallVerticalSpace ? '5px' : '0'} -0.5px`,
      paddingLeft: 0,
      paddingRight: 0,
    }}
  >
    <div className={classNames('ColVerticalDivider full', className)} />
  </Col>
);

export const SymbolCol = withMergedClass('SymbolCol')(Col);

export function PackageCard({ index, children, headerExtra, className }) {
  return (
    <ConfigurableCard
      title={
        isNumber(index) ? (
          <FormattedMessage
            id="book.newShipment.packageItemHeaderWithNumber"
            values={{ index: index + 1 }}
          />
        ) : (
          <FormattedMessage id="book.newShipment.packageItemHeader" />
        )
      }
      headerExtra={headerExtra}
      bodyComponent="div"
      bodyClassName={classNames('NewShipmentPackage', className)}
    >
      {children}
    </ConfigurableCard>
  );
}

export function PackageListCardHeader({ index, left, right }) {
  return (
    <Row
      align="middle"
      justify="space-between"
      className="NewShipmentPackage-Header no-margin-form-items"
    >
      <Col>
        <Row align="middle" gutter={pxToNumber(cssVariables.spaceNorm2)}>
          <Col>
            <div className="number-in-circle">{index + 1}</div>
          </Col>
          {left && <Col>{left}</Col>}
        </Row>
      </Col>
      <Col>{right}</Col>
    </Row>
  );
}

export const PackageListCardTitle = ({ className, text, textId, ...rest }) => (
  <div className={classNames('NewShipmentPackage-Title', className)} {...rest}>
    {textId ? <FormattedMessage id={textId} /> : text}
  </div>
);
export const PackageListCardSubtitle = ({
  className,
  text,
  textId,
  requiredIndicator,
  ...rest
}) => (
  <div
    className={classNames('NewShipmentPackage-Subtitle', className)}
    {...rest}
  >
    {textId ? <FormattedMessage id={textId} /> : text}
    {requiredIndicator && ' *'}
  </div>
);

export function PackageListCardSection({
  className,
  title,
  titleId,
  children,
  noTopLines,
  ...rest
}) {
  return (
    <div
      className={classNames(
        'NewShipmentPackage-Section',
        { 'has-two-lines': !noTopLines },
        className
      )}
      {...rest}
    >
      {!noTopLines && <TwoLines className="size-10 hide-lg-and-bigger" />}
      {(title || titleId) && (
        <PackageListCardTitle text={title} textId={titleId} />
      )}
      <div className="NewShipmentPackage-SectionBody no-margin-form-items">
        {children}
      </div>
    </div>
  );
}

export function PackageListCardSubsection({
  className,
  title,
  titleId,
  headerExtra,
  children,
}) {
  return (
    <div className="NewShipmentPackage-SubsectionParent">
      <TwoLinesSeparator className="hide-lg-and-bigger" />
      <div className={classNames('NewShipmentPackage-Subsection', className)}>
        <Row align="top" justify="space-between">
          <Col className="NewShipmentPackage-SubsectionTitle">
            {titleId ? <FormattedMessage id={titleId} /> : title}
          </Col>
          <Col>{headerExtra}</Col>
        </Row>
        {children}
      </div>
    </div>
  );
}

export function canComputeDimensionalWeight(pkg) {
  return every([pkg.actualWeight, pkg.height, pkg.length, pkg.width], isNumber);
}

export function useComputeDimensionalWeight() {
  const client = useApolloClient();
  const { unitSystem } = useContext(SettingsContext);

  return useCallback(
    async input => {
      const { data } = await client.query({
        query: DIMENSIONAL_WEIGHT_QUERY,
        variables: { input: { ...input, unitSystem } },
      });
      return extractGraphqlEntity(data);
    },
    [client, unitSystem]
  );
}

export function useIsFirstPackageDimensional() {
  const firstPackage = useDynamicOriginalValueDependency(
    'packageList.packages[0]'
  );
  return every(
    [
      firstPackage?.actualWeight,
      firstPackage?.height,
      firstPackage?.length,
      firstPackage?.width,
    ],
    isNumber
  );
}

export function isPackoutDateTimeEnabled(packageSource) {
  return packageSource?.code === 'C'; // Customer / Shipper
}
