/* eslint-disable react/jsx-filename-extension */

import ReactDOM from 'react-dom';

import App from './App';
import { loadPolyfills } from './polyfills';
import * as serviceWorker from './serviceWorker';

import './styles/main.less';
import '@fortawesome/fontawesome-free/css/all.min.css';

// render app after polyfills
loadPolyfills()
  .then(() => {
    ReactDOM.render(<App />, document.getElementById('root'));
  })
  .catch(console.error);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
