import { forwardRef, useImperativeHandle, useRef, useState } from 'react';

import { useStateIfMounted } from '../../utils/reactUtils';
import { FormMode, NoLabelForm } from './forms';

const SilentFormValidation = forwardRef(function SilentFormValidation(
  { children },
  ref
) {
  const [currentlyValidating, setCurrentlyValidating] = useStateIfMounted();
  // This is so that React Node was not reused (which leads to sharing old form state)
  const [key, setKey] = useState(0);

  const formRef = useRef();
  useImperativeHandle(ref, () => ({
    validate: async (values, context) => {
      setCurrentlyValidating({ values, ...context });
      setKey(k => k + 1);
      // The above triggers the first render and Ant's form is unresponsive
      // to API calls until mounted, so we have to wait a moment
      await new Promise(resolve => requestAnimationFrame(resolve));
      await formRef.current.customSubmit();
      setCurrentlyValidating(undefined);
    },
  }));

  return (
    <div style={{ position: 'fixed', top: '200vh', left: '200vw', opacity: 0 }}>
      {currentlyValidating && (
        <NoLabelForm
          key={key}
          component={false}
          formRef={formRef}
          name="silentValidation"
          mode={FormMode.EDIT}
          initialValues={currentlyValidating.values}
        >
          {children(currentlyValidating)}
        </NoLabelForm>
      )}
    </div>
  );
});

export default SilentFormValidation;
