import {
  compact,
  get,
  isArray,
  isNil,
  isObject,
  values as objValues,
  property,
  random,
  set,
  some,
} from 'lodash-es';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { useCachedProp } from '../../common/utils/hookUtils';
import { log } from '../../utils/logger';
import { useParentFormListName } from './FormList';
import { useFormContext } from './forms';

export function extractValueFromEvent(eventOrValue) {
  if (eventOrValue?.target) {
    return eventOrValue.target.value;
  }
  return eventOrValue;
}

export const isFormValuePresent = value => !isNil(value) && value !== '';

export function isFormSectionNonEmpty(value) {
  if (isObject(value)) {
    return some(objValues(value), isFormSectionNonEmpty);
  }
  if (isArray(value)) {
    return some(value, isFormSectionNonEmpty);
  }
  return isFormValuePresent(value);
}

export function getIsRequired(rules) {
  return rules && some(rules, property('required'));
}

export function resetFormExcept(form, fieldsToKeep) {
  const keep = {};
  const values = form.getFieldsValue();
  fieldsToKeep.forEach(fieldName =>
    set(keep, fieldName, get(values, fieldName))
  );
  form.resetFields();
  form.setFieldsValue(keep);
}

export function EnsureInitialFormListItem({
  fields,
  add,
  value,
  initialCount = 1,
}) {
  const numLines = fields.length;
  const cachedValue = useCachedProp(value);
  useEffect(() => {
    if (numLines < initialCount) {
      add(cachedValue);
    }
  }, [add, cachedValue, initialCount, numLines]);
  return null;
}

/**
 * Runs validation of dependent fields (`dependentNames`) after dependency has changed (`dependencyName` or `dependenciesNames`)
 *
 * Ant's `dependencies` is insufficient because:
 *  - it doesn't work at all if field is not touched (so after blur of an empty field validation error will stay there)
 *  - it runs immediately, sooner than rules could be updated in the next render cycle
 *
 * NOTE: if rules are optional, use a placeholder like `[ { required: false } ]`, because Ant's validation is buggy
 * if rules are empty - it doesn't remove previous validation messages
 */
export function useValidationAfterChange({
  dependencyName,
  dependenciesNames,
  dependentNames,
  onlyRunWhenHasError,
}) {
  if (dependenciesNames && dependencyName) {
    log.warn(
      'Only one of `dependencyName` and `dependenciesNames` should be used'
    );
  }

  const finalDependenciesNames =
    (dependencyName ? [dependencyName] : dependenciesNames) || [];

  const { values, formInstance: form } = useFormContext();
  const dependencies = useCachedProp(
    finalDependenciesNames.map(name => get(values, name))
  );
  const [initialDependencies] = useState(dependencies);
  const cachedDependentNames = useCachedProp(dependentNames);
  // Layout effect, because we want to wait until the change was reflected in rules and rendered
  useLayoutEffect(() => {
    // Only on change - this prevents initial call
    if (dependencies !== initialDependencies) {
      const namesToValidate = onlyRunWhenHasError
        ? cachedDependentNames.filter(
            name => form.getFieldError(name).length > 0
          )
        : cachedDependentNames;

      form && form.validateFields(namesToValidate);
    }
  }, [
    dependencies,
    cachedDependentNames,
    form,
    initialDependencies,
    onlyRunWhenHasError,
  ]);
}

export function useFormItemId({ name }) {
  const parentName = useParentFormListName();
  const computeId = useCallback(
    () => compact([parentName, name, random(10000000, 99999999)]).join('-'),
    [name, parentName]
  );
  const uniqueId = useRef(computeId());
  useEffect(() => computeId(), [computeId]);

  return uniqueId.current;
}

export function mergeFormNames(name1, name2) {
  const name1Array = isArray(name1) ? name1 : [name1];
  const name2Array = isArray(name2) ? name2 : [name2];
  return [...name1Array, ...name2Array].filter(val => !!val || val === 0);
}
