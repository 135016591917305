import { Form } from 'antd';
import { noop } from 'lodash-es';
import { createContext, useContext, useState } from 'react';

import IconButton from '../components/buttons/IconButton';
import { extractValueFromEvent } from '../components/forms/formHelpers';
import { FormItemSearchFlat } from '../components/forms/searchFormElements';

export const FreeTextJobSearchContext = createContext({
  value: '',
  setValue: noop,
  searchActive: false,
  setSearchActive: noop,
});

export function useFreeTextJobSearch() {
  const [value, setValue] = useState();
  const [searchActive, setSearchActive] = useState(false);

  return { value, setValue, searchActive, setSearchActive };
}

// Supports search by job number or HAWB/BOL
export function FreeTextJobSearch() {
  const { value, setValue, setSearchActive } = useContext(
    FreeTextJobSearchContext
  );
  const [tmpValue, setTmpValue] = useState(value);
  return (
    <Form component={false}>
      <FormItemSearchFlat
        onSearch={val => setValue(val?.trim())}
        onCancel={() => {
          setValue('');
          setSearchActive(false);
        }}
        placeholderId="monitor.search.placeholder"
        formItemComponentProps={{
          value: tmpValue,
          onChange: e => setTmpValue(extractValueFromEvent(e)),
          autoFocus: true,
        }}
      />
    </Form>
  );
}

export function FreeTextJobSearchIcon() {
  const { setSearchActive } = useContext(FreeTextJobSearchContext);
  return (
    <IconButton
      icon="search"
      className="FreeTextJobSearchIcon"
      onClick={() => setSearchActive(true)}
    />
  );
}
