import { noop } from 'lodash-es';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  closeDialog,
  getDialogData,
  getIsDialogDontShowAgain,
  getIsDialogOpen,
  openDialog,
  setDialogData,
  setDialogDontShowAgain,
} from '../../app/redux/dialogs';
import { LOCAL_STORAGE_DIALOG_DONT_SHOW_AGAIN_KEY } from '../../config/constants';
import { useLocalStorageSynchronizer } from '../../utils/localStorage';
import { useStateIfMounted } from '../../utils/reactUtils';
import { useArrayState, useCachedProp } from './hookUtils';

export function useDialogControls({ onOpenChange = noop } = {}) {
  const [isOpen, setOpen] = useStateIfMounted(false);
  const [dontShowAgain, setDontShowAgain] = useStateIfMounted(false);

  const open = useCallback(() => {
    if (!dontShowAgain) {
      setOpen(true);
      onOpenChange(true);
    }
  }, [dontShowAgain, onOpenChange, setOpen]);
  const close = useCallback(
    _dontShowAgain => {
      setOpen(false);
      onOpenChange(false);
      if (_dontShowAgain === true) {
        setDontShowAgain(true);
      }
    },
    [onOpenChange, setDontShowAgain, setOpen]
  );

  return { isOpen, open, close };
}

export function useArrayDialogControls() {
  const {
    values: openStates,
    setValue: setOpenState,
    removeValue: removeOpenState,
    setAllValues: setOpenStates,
  } = useArrayState();

  return {
    getDialogControls: index => ({
      isOpen: openStates[index],
      open: () => setOpenState(index, true),
      close: () => setOpenState(index, false),
    }),
    removeDialogControls: removeOpenState,
    setOpenStates,
  };
}

export function useReduxDialogControls({ id, spec, onOpenChange = noop }) {
  const dispatch = useDispatch();

  const cachedSpec = useCachedProp(spec);

  const isOpen = useSelector(getIsDialogOpen(id, cachedSpec));
  const open = useCallback(
    data => {
      dispatch(openDialog({ open: true, id, spec: cachedSpec, data }));
      onOpenChange(true);
    },
    [cachedSpec, dispatch, id, onOpenChange]
  );
  const close = useCallback(
    dontShowAgain => {
      dispatch(
        closeDialog({ open: false, id, spec: cachedSpec, dontShowAgain })
      );
      onOpenChange(false);
    },
    [cachedSpec, dispatch, id, onOpenChange]
  );

  const data = useSelector(getDialogData(id, cachedSpec));
  const setData = useCallback(
    newData => dispatch(setDialogData({ id, spec: cachedSpec, data: newData })),
    [dispatch, id, cachedSpec]
  );

  const dontShowAgain = useSelector(getIsDialogDontShowAgain(id, cachedSpec));
  const setDontShowAgain = useCallback(
    value => dispatch(setDialogDontShowAgain({ id, spec: cachedSpec, value })),
    [cachedSpec, dispatch, id]
  );

  useLocalStorageSynchronizer({
    value: dontShowAgain,
    setValue: setDontShowAgain,
    key: LOCAL_STORAGE_DIALOG_DONT_SHOW_AGAIN_KEY(id),
  });

  return { isOpen, open, close, data, setData };
}
