import {
  get,
  isArray,
  isFunction,
  isNil,
  isObject,
  isPlainObject,
  mapValues,
  omitBy,
} from 'lodash-es';

export function getOrCall(funcOrValue, ...args) {
  return isFunction(funcOrValue) ? funcOrValue(...args) : funcOrValue;
}

export function getByPathOrFunc(obj, pathOrFunc) {
  return isFunction(pathOrFunc) ? pathOrFunc(obj) : get(obj, pathOrFunc);
}

export function optionalDecorator(decorator) {
  return (val, ...rest) => (!isNil(val) ? decorator(val, ...rest) : val);
}

export function firstNonNull(...values) {
  return values.find(val => typeof val !== 'undefined' && val !== null);
}

export function omitByDeep(value, predicate) {
  if (isArray(value)) {
    return value.map(val => omitByDeep(val, predicate));
  }
  if (isObject(value)) {
    return mapValues(omitBy(value, predicate), val =>
      omitByDeep(val, predicate)
    );
  }
  return value;
}

export function isIdentical(value1, value2) {
  return value1 === value2;
}

export function mapValuesDeep(value, mapper, keys = []) {
  if (isArray(value)) {
    return value.map((val, key) => mapValuesDeep(val, mapper, [...keys, key]));
  }
  if (isPlainObject(value)) {
    return mapValues(value, (val, key) =>
      mapValuesDeep(val, mapper, [...keys, key])
    );
  }
  return mapper(value, keys);
}
