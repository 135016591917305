import { useMutation } from '@apollo/client';
import { isObject, mapValues } from 'lodash-es';

import { extractGraphqlEntity } from '../../common/utils/graphqlUtils';
import {
  ADDRESS_BOOK_ENTRIES_QUERY,
  CREATE_ADDRESS_BOOK_ENTRY_MUTATION,
  DELETE_ADDRESS_BOOK_ENTRY_MUTATION,
  EDIT_ADDRESS_BOOK_ENTRY_MUTATION,
} from './addressBookQueries';

function ensureNullValues(obj) {
  return mapValues(obj, val => {
    if (isObject(val)) {
      return ensureNullValues(val);
    }
    if (val === undefined) {
      return null;
    }
    return val;
  });
}

// Convert AddressBookEntryInput to AddressBookEntry
// - JobLocation uses code values unlike InputJobLocation
function inputToAddressBookEntry(id, { accountNumber, ...input }) {
  // ensure all fields are either resolved or null
  return ensureNullValues({
    id,
    ...input,
    address: input.address && {
      ...input.address,
      city: {
        text: input.address.city,
        code: null,
      },
      stateProvince: {
        text: input.address.stateProvince,
        code: null,
      },
      country: {
        text: input.address.country,
        code: null,
      },
    },
  });
}

export function useCreateAddressBookEntry({ accountNumber }) {
  const [mutate] = useMutation(CREATE_ADDRESS_BOOK_ENTRY_MUTATION);
  return async function create(input) {
    const { data } = await mutate({
      variables: { input },
      update(
        cache,
        {
          data: {
            createAddressBookEntry: { id },
          },
        }
      ) {
        const cacheResult = cache.readQuery({
          query: ADDRESS_BOOK_ENTRIES_QUERY,
          variables: { accountNumber },
        });
        if (!cacheResult) {
          return;
        }

        const { addressBookEntries } = cacheResult;
        cache.writeQuery({
          query: ADDRESS_BOOK_ENTRIES_QUERY,
          variables: { accountNumber },
          data: {
            addressBookEntries: {
              ...addressBookEntries,
              data: [
                ...addressBookEntries.data,
                inputToAddressBookEntry(id, input),
              ],
            },
          },
        });
      },
    });
    return extractGraphqlEntity(data);
  };
}

export function useEditAddressBookEntry({ accountNumber }) {
  const [mutate] = useMutation(EDIT_ADDRESS_BOOK_ENTRY_MUTATION);
  return async function edit(id, input) {
    const { data } = await mutate({
      variables: { id, input },
      update(cache) {
        const { addressBookEntries } = cache.readQuery({
          query: ADDRESS_BOOK_ENTRIES_QUERY,
          variables: { accountNumber },
        });
        cache.writeQuery({
          query: ADDRESS_BOOK_ENTRIES_QUERY,
          variables: { accountNumber },
          data: {
            addressBookEntries: {
              ...addressBookEntries,
              data: addressBookEntries.data.map(entry => {
                if (entry.id === id) {
                  return inputToAddressBookEntry(id, input);
                }
                return entry;
              }),
            },
          },
        });
      },
    });
    return extractGraphqlEntity(data);
  };
}

export function useRemoveAddressBookEntry({ accountNumber }) {
  const [mutate] = useMutation(DELETE_ADDRESS_BOOK_ENTRY_MUTATION);
  return async function remove(id) {
    await mutate({
      variables: { id },
      update(cache) {
        const { addressBookEntries } = cache.readQuery({
          query: ADDRESS_BOOK_ENTRIES_QUERY,
          variables: { accountNumber },
        });
        cache.writeQuery({
          query: ADDRESS_BOOK_ENTRIES_QUERY,
          variables: { accountNumber },
          data: {
            addressBookEntries: {
              ...addressBookEntries,
              data: addressBookEntries.data.filter(entry => entry.id !== id),
            },
          },
        });
      },
    });
  };
}
