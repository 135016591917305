import { Col, Row } from 'antd';
import { chunk } from 'lodash-es';

import DynamicFormField from './DynamicFormField';
import { useDynamicFormSchemaDynamicFieldsDefinition } from './dynamicFormSchema';

export default function DynamicFieldsForm({
  schemaName,
  namePrefix,
  rowComponent: R = Row,
  colComponent: C = Col,
}) {
  const fields = useDynamicFormSchemaDynamicFieldsDefinition({ schemaName });
  const rows = chunk(fields, 2);
  return (
    <R>
      <C key="col1">
        {rows.map(([{ name, ...info }]) => (
          <DynamicFormField key={name} name={[namePrefix, name]} {...info} />
        ))}
      </C>
      <C key="col2">
        {rows.map(([, el2]) => {
          if (!el2) {
            return null;
          }
          const { name, ...info } = el2;
          return (
            <DynamicFormField key={name} name={[namePrefix, name]} {...info} />
          );
        })}
      </C>
    </R>
  );
}
