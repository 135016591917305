/* eslint-disable react/no-array-index-key */
import { useQuery } from '@apollo/client';
import { Alert, Col, Row } from 'antd';
import classNames from 'classnames';
import { compact, get, isNil, negate, range, some, values } from 'lodash-es';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import URI from 'urijs';

import {
  DeviceAddonType,
  getDeviceAddonLabel,
} from '../../app/data/deviceAddonConversions';
import { JOB_SENSOR_DATA_QUERY } from '../../app/graphql/jobQueries';
import { fromServerDateTime } from '../../common/utils/dateUtils';
import { extractGraphqlEntity } from '../../common/utils/graphqlUtils';
import { forceIntoRange } from '../../common/utils/numberUtils';
import { FAIcon } from '../../components/adapters/fontAwesomeAdapters';
import { Spinner, ThreeDotsSpinner } from '../../components/data/Spinner';
import { useFlashMessageContext } from '../../components/dialogs/FlashMessageProvider';
import ShipmentDetailDrawer from '../../components/drawers/ShipmentDetailDrawer';
import Gauge, {
  GaugeAxisAngle,
  OptimalRangeGaugeOuterAxis,
} from '../../components/graphics/Gauge';
import {
  Scrollbars,
  VerticalTrackTypes,
} from '../../components/layout/Scrollbars';
import {
  SimpleCard,
  SimpleCardTheme,
} from '../../components/layout/cardElements';
import { FlexCol } from '../../components/layout/layoutElements';
import { cssVariables } from '../../styles/cssVariables';
import { pxToNumber } from '../../utils/cssUtils';
import { DateTimeFormatNumeric } from '../../utils/dateFormatting';
import { log } from '../../utils/logger';

const Mode = {
  CUSTOM: 'CUSTOM',
  EXTERNAL: 'EXTERNAL',
};

const DateTimeFormatOneLine = props => (
  <DateTimeFormatNumeric {...props} separator=" | " />
);
const DateTimeFormatTwoLines = props => (
  <DateTimeFormatNumeric {...props} separator={'\n'} />
);

export function SensorCard({ title, titleId, timestamp, children }) {
  return (
    <SimpleCard
      title={title}
      titleId={titleId}
      theme={SimpleCardTheme.ROUND_BORDERED}
      bodyProps={{ className: 'SensorDataCard' }}
    >
      <div className="SensorDataCard-LastReport text-uppercase">
        <FormattedMessage id="shipmentDetail.sensorDrawer.lastReport" />:{' '}
        <DateTimeFormatOneLine value={timestamp} />
      </div>
      {children}
    </SimpleCard>
  );
}

const BATTERY_NUM_PARTS = 6;
const BATTERY_PART_MAX_PERCENTAGE = 100 / BATTERY_NUM_PARTS;
export function BatterySensorContent({ percentage }) {
  return (
    <FlexCol
      align="center"
      justify="flex-start"
      className={classNames('BatterySensorContent-Container', {
        'BatterySensorContent-High': percentage > 70,
        'BatterySensorContent-Medium': percentage > 20 && percentage <= 70,
        'BatterySensorContent-Low': percentage <= 20,
      })}
    >
      <div className="BatterySensorContent-Value">{percentage}%</div>
      <div className="BatterySensorContent-BatteryContainer">
        <div className="BatterySensorContent-BatteryOutline">
          <div className="BatterySensorContent-BatteryState">
            {range(0, BATTERY_NUM_PARTS).map(i => {
              const percentageSkip = BATTERY_PART_MAX_PERCENTAGE * i;
              const totalPercentageInThisPart = forceIntoRange(
                percentage - percentageSkip,
                { min: 0, max: BATTERY_PART_MAX_PERCENTAGE }
              );
              const fillPercentage =
                (totalPercentageInThisPart * 100) / BATTERY_PART_MAX_PERCENTAGE;
              return (
                <div className="BatterySensorContent-BatteryPowerPart" key={i}>
                  <div
                    className="BatterySensorContent-BatteryPowerPartInner"
                    style={{ width: `${fillPercentage}%` }}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="BatterySensorContent-BatteryRightPart" />
      </div>
    </FlexCol>
  );
}
export function LightDetectionSensorContent({ value }) {
  return (
    <>
      <div
        className={classNames(
          'LightDetectionSensorContent-Title',
          value ? 'on' : 'off'
        )}
      >
        {value ? (
          <FormattedMessage id="shipmentDetail.sensorDrawer.eventDetected" />
        ) : (
          <FormattedMessage id="shipmentDetail.sensorDrawer.noEvents" />
        )}
      </div>
      <div
        className={classNames(
          'LightDetectionSensorContent-Img',
          value ? 'on' : 'off'
        )}
      />
    </>
  );
}
export function TemperatureSensorContent({ current, min, max, optimalRange }) {
  return (
    <>
      {min && (
        <div className="TemperatureSensorContent-MinValue">
          <div>
            <strong>{min.value} °C</strong>
            <FAIcon icon="caret-down" />
          </div>
          <div className="nl-as-newline">
            <DateTimeFormatTwoLines value={min.timestamp} />
          </div>
        </div>
      )}
      {max && (
        <div className="TemperatureSensorContent-MaxValue">
          <div>
            <FAIcon icon="caret-up" />
            <strong>{max.value} °C</strong>
          </div>
          <div className="nl-as-newline">
            <DateTimeFormatTwoLines value={max.timestamp} />
          </div>
        </div>
      )}
      <Gauge
        outerAxisComponent={OptimalRangeGaugeOuterAxis}
        outerAxisProps={{ range: optimalRange }}
        minValue={-200}
        maxValue={60}
        formatValue={val => `${val} °C`}
        value={current?.value}
      />
    </>
  );
}
export function HumiditySensorContent({ value }) {
  return <Gauge formatValue={val => `${val}%`} value={value} />;
}
export function PressureSensorContent({ value }) {
  return (
    <Gauge
      minValue={300}
      maxValue={1200}
      formatValue={val => (
        <>
          {val}
          <div className="help-text">mbar</div>
        </>
      )}
      value={value}
    />
  );
}
export function OrientationSensorContent({ value }) {
  return (
    <Gauge
      minValue={0}
      maxValue={180}
      formatValue={val => `${val}°`}
      value={value}
      angleType={GaugeAxisAngle.HALF_CIRCLE}
    />
  );
}

function ShipmentSensorWidgets({ data }) {
  const gauges = [
    data.battery && {
      titleId: 'shipmentDetail.sensorDrawer.type.battery',
      timestamp: data.battery.timestamp,
      props: data.battery,
      component: BatterySensorContent,
    },
    data.lightDetection && {
      titleId: 'shipmentDetail.sensorDrawer.type.lightDetection',
      timestamp: data.lightDetection.timestamp,
      props: data.lightDetection,
      component: LightDetectionSensorContent,
    },
    data.temperature && {
      titleId: 'shipmentDetail.sensorDrawer.type.temperature',
      timestamp: data.temperature.current.timestamp,
      props: data.temperature,
      component: TemperatureSensorContent,
    },
    data.temperatureProbe && {
      titleId: 'shipmentDetail.sensorDrawer.type.temperatureProbe',
      timestamp: data.temperatureProbe.current.timestamp,
      props: data.temperatureProbe,
      component: TemperatureSensorContent,
    },
    data.humidity && {
      titleId: 'shipmentDetail.sensorDrawer.type.humidity',
      timestamp: data.humidity.timestamp,
      props: data.humidity,
      component: HumiditySensorContent,
    },
    data.pressure && {
      titleId: 'shipmentDetail.sensorDrawer.type.pressure',
      timestamp: data.pressure.timestamp,
      props: data.pressure,
      component: PressureSensorContent,
    },
    data.orientation && {
      titleId: 'shipmentDetail.sensorDrawer.type.orientation',
      timestamp: data.orientation.timestamp,
      props: data.orientation,
      component: OrientationSensorContent,
    },
  ];

  return (
    <div className="ShipmentSensorWidgets">
      <Row gutter={pxToNumber(cssVariables.spaceNorm2)}>
        {compact(gauges).map(
          ({ titleId, timestamp, props, component: C }, i) => (
            <Col key={i} xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <SensorCard titleId={titleId} timestamp={timestamp}>
                <C {...props} />
              </SensorCard>
            </Col>
          )
        )}
      </Row>
    </div>
  );
}

function normalizeGauge(gauge) {
  if (!gauge) {
    return null;
  }
  const { timestamp, ...rest } = gauge;
  return {
    timestamp: fromServerDateTime(timestamp),
    ...rest,
  };
}
function normalizeTemperatureGauge(gauge) {
  if (!gauge) {
    return null;
  }
  const { current, min, max } = gauge;
  return {
    current: normalizeGauge(current),
    min: normalizeGauge(min),
    max: normalizeGauge(max),
  };
}
function normalizeSensorData(sensorData) {
  return {
    battery: normalizeGauge(sensorData?.battery),
    lightDetection: normalizeGauge(sensorData?.lightDetection),
    temperature: normalizeTemperatureGauge(sensorData?.temperature),
    temperatureProbe: normalizeTemperatureGauge(sensorData?.temperatureProbe),
    humidity: normalizeGauge(sensorData?.humidity),
    pressure: normalizeGauge(sensorData?.pressure),
    orientation: normalizeGauge(sensorData?.orientation),
  };
}
export function computeSensorMode({ sensorData, sensorUrl }) {
  if (some(values(normalizeSensorData(sensorData)), negate(isNil))) {
    return Mode.CUSTOM;
  }
  if (sensorUrl) {
    return Mode.EXTERNAL;
  }
  return null;
}
function normalizeAddonData({
  brandName,
  serialNumber,
  modelName,
  sensorData,
  sensorUrl,
} = {}) {
  return {
    brandName,
    serialNumber,
    modelName,
    sensorData: normalizeSensorData(sensorData),
    sensorUrl,
    mode: computeSensorMode({ sensorData, sensorUrl }),
  };
}

function useShipmentSensorsDataBase({ jobNumber, skip }) {
  const { errorMessage } = useFlashMessageContext();
  const { data } = useQuery(JOB_SENSOR_DATA_QUERY, {
    variables: { jobNumber },
    // Loading and refreshing will be taken care of in full shipment query to prevent multiple requests
    fetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,
    onError: errorMessage,
    skip: !jobNumber || skip,
  });

  return data && extractGraphqlEntity(data);
}

export function useShipmentPackageSensorData({
  jobNumber,
  packageIndex,
  skip,
}) {
  const data = useShipmentSensorsDataBase({ jobNumber, skip });
  return (
    data &&
    normalizeAddonData(
      get(data, `packagesInformation.packages.${packageIndex}`)
    )
  );
}

export function useShipmentDeviceSensorData({
  jobNumber,
  packageIndex,
  addonIndex,
  type,
}) {
  const data = useShipmentSensorsDataBase({ jobNumber });
  return (
    data &&
    normalizeAddonData(
      get(
        data,
        `packagesInformation.packages.${packageIndex}.${
          type === DeviceAddonType.GPS ? 'gpsDevices' : 'temperatureDevices'
        }.${addonIndex}`
      )
    )
  );
}

function ShipmentSensorWidgetsDrawerLeftHeader({ addonData }) {
  return (
    addonData?.mode === Mode.EXTERNAL && (
      <a href={addonData.sensorUrl} className="red-link" target="__blank">
        <FormattedMessage id="shipmentDetail.deviceAddon.openExternalLink" />
      </a>
    )
  );
}

function ShipmentSensorWidgetsDrawerRightHeader({ addonData }) {
  const label = addonData && getDeviceAddonLabel(addonData);

  if (addonData && !label) {
    return null;
  }

  return (
    <Row align="middle" gutter={pxToNumber(cssVariables.spaceXs)}>
      <Col>
        <div className="Typography-LabelCategory text-uppercase">
          <FormattedMessage id="shipmentDetail.sensorDrawer.system" />:
        </div>
      </Col>
      <Col>
        <div
          className="CommonLabelValue-Text"
          style={{ fontSize: cssVariables['font-size-base'] }}
        >
          {addonData ? label : <ThreeDotsSpinner />}
        </div>
      </Col>
    </Row>
  );
}

function validateUri(uri, { jobNumber }) {
  const thisPage = URI(window.location.href);
  if (!uri.is('absolute')) {
    log.error(
      `Invalid URL in sensorUrl: ${uri.toString()} (Job number: ${jobNumber})`
    );
    return 'error.invalidUrl';
  }
  if (uri.scheme() !== 'https' && thisPage.scheme() === 'https') {
    return 'error.insecureScheme';
  }
  return null;
}

function ShipmentSensorDataExternalUrl({ jobNumber, url }) {
  const uri = URI(url);
  const [error, setError] = useState();
  const errorId = error
    ? 'error.iframe.other'
    : validateUri(uri, { jobNumber });
  const onError = e => {
    setError(e);
    log.error(`Error during loading of iframe: ${uri.toString()}`, e);
  };

  if (errorId) {
    return (
      <Alert
        type="error"
        message={<FormattedMessage id={errorId} />}
        style={{ width: '100%', alignSelf: 'flex-start' }}
      />
    );
  }

  // http iframes are blocked from https websites, so we always set https
  // uri.scheme('https');
  // eslint-disable-next-line jsx-a11y/iframe-has-title
  return <iframe src={uri.toString()} onError={onError} />;
}

function ShipmentSensorWidgetsDrawerContent({ addonData, jobNumber }) {
  if (!addonData) {
    return <Spinner />;
  }

  if (addonData.mode === Mode.CUSTOM) {
    return (
      <Scrollbars vertical={VerticalTrackTypes.OUTSIDE} isInFlexContainer>
        <ShipmentSensorWidgets data={addonData.sensorData} />
      </Scrollbars>
    );
  }

  if (addonData.mode === Mode.EXTERNAL) {
    return (
      <ShipmentSensorDataExternalUrl
        url={addonData.sensorUrl}
        jobNumber={jobNumber}
      />
    );
  }

  return null;
}

export function ShipmentSensorWidgetsDrawer({
  jobNumber,
  addonData,
  isOpen,
  onClose,
}) {
  return (
    <ShipmentDetailDrawer
      className={classNames({ wide: addonData?.mode === Mode.EXTERNAL })}
      visible={isOpen}
      onClose={onClose}
      leftHeader={
        <ShipmentSensorWidgetsDrawerLeftHeader addonData={addonData} />
      }
      rightHeader={
        <ShipmentSensorWidgetsDrawerRightHeader addonData={addonData} />
      }
    >
      <ShipmentSensorWidgetsDrawerContent
        addonData={addonData}
        jobNumber={jobNumber}
      />
    </ShipmentDetailDrawer>
  );
}
