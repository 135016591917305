import { resetFormExcept } from '../../../components/forms/formHelpers';

export function copyShipmentTemplateIntoForm(forms, values) {
  forms[0].form.setFieldsValue(values.origin);
  forms[1].form.setFieldsValue(values.destination);
  forms[2].form.setFieldsValue(values.serviceInformation);
  forms[3].form.setFieldsValue(values.packageList);
}

export function cancelShipmentTemplate(forms) {
  resetFormExcept(forms[0].form, [
    'pickupDateTime',
    'pickupDateTime-date',
    'pickupDateTime-time',
    'pickupDateTime-specialValue',
    'pickupDateTime-specialValueData',
  ]);
  resetFormExcept(forms[1].form, [
    'deliveryDateTime',
    'deliveryDateTime-date',
    'deliveryDateTime-time',
    'deliveryDateTime-specialValue',
    'deliveryDateTime-specialValueData',
  ]);
  resetFormExcept(forms[2].form, ['bolNumber', 'shipperSpecificInfo']);
  forms[3].form.resetFields();
}
