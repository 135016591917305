import { generatePath } from 'react-router';

import routes from '../../../../app/routes';
import { FormItemsOverrideContextProvider } from '../../../../components/forms/FormItem';
import AdminUserList from '../../userManagement/AdminUserList';

export function AdminAccountUserList({ accountNumber }) {
  return (
    <FormItemsOverrideContextProvider force viewMode={false}>
      <AdminUserList
        filterBase={{ accountNumber }}
        detailBaseRoute={generatePath(routes.admin.accountDetail.userDetail, {
          accountNumber,
          userId: ':userId',
        })}
      />
    </FormItemsOverrideContextProvider>
  );
}
