import { useQuery } from '@apollo/client';
import { Button, Col, Row, Typography } from 'antd';
import { every, isEmpty, max, property, some } from 'lodash-es';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { USER_QUERY } from '../app/graphql/authQueries';
import { GENERATE_REPORT_MUTATION } from '../app/graphql/reportQueries';
import { toServerDateOptional } from '../common/utils/dateUtils';
import { extractGraphqlEntity } from '../common/utils/graphqlUtils';
import { HeaderLogo } from '../components/Logo';
import { FAIcon } from '../components/adapters/fontAwesomeAdapters';
import { GraphQLMutatingButton } from '../components/data/asyncActionElements';
import { useFlashMessageContext } from '../components/dialogs/FlashMessageProvider';
import SimpleDialog from '../components/dialogs/SimpleDialog';
import { FormItemFieldRegistration } from '../components/forms/basicFormElements';
import { NoLabelForm, useFormContext } from '../components/forms/forms';
import { SlidingContent } from '../components/nav/SlidingContent';
import logoImg from '../resources/img/quick-online-logo-q.svg';
import { cssVariables } from '../styles/cssVariables';
import { pxToNumber } from '../utils/cssUtils';
import { ReportsAccountSelection } from './reports/ReportsAccountSelection';
import { ReportsDateRange } from './reports/ReportsDateRange';
import { ReportsDownloadSummary } from './reports/ReportsDownloadSummary';
import { ReportsReportSelection } from './reports/ReportsReportSelection';

const STEPS = {
  ACCOUNT_SELECTION: 0,
  REPORT_TYPES: 1,
  DATES: 2,
  REVIEW: 3,
};

const STEPS_DATA = [
  {
    name: 'accountSelection',
    component: ReportsAccountSelection,
    canContinue: values => !isEmpty(values.selectedAccounts),
  },
  {
    name: 'reportSelection',
    component: ReportsReportSelection,
    canContinue: values => !isEmpty(values.selectedReports),
  },
  {
    name: 'dateRange',
    component: ReportsDateRange,
    canContinue: values => {
      if (some(values.selectedReports, property('requiresDateRange'))) {
        return values.dateStart && values.dateEnd;
      }
      return true;
    },
  },
  {
    name: 'review',
    component: ReportsDownloadSummary,
  },
];

export function ReportsDialog({ visible, close }) {
  const [doneDialogOpen, setDoneDialogOpen] = useState(false);
  return (
    <>
      <SimpleDialog
        className="ReportsDialog"
        icon={<img src={logoImg} alt="" />}
        visible={visible && !doneDialogOpen}
        centered
        mask
        destroyOnClose
      >
        <Typography.Title level={2}>
          <FormattedMessage id="reports.title" />
        </Typography.Title>
        <Typography.Title level={4}>
          <FormattedMessage id="reports.subtitle" />
        </Typography.Title>
        <NoLabelForm
          initialValues={{ selectedAccounts: [], selectedReports: [] }}
        >
          <FormItemFieldRegistration name="selectedAccounts" />
          <FormItemFieldRegistration name="selectedReports" />
          <FormItemFieldRegistration name="dateStart" />
          <FormItemFieldRegistration name="dateEnd" />
          <Steps close={close} onDone={() => setDoneDialogOpen(true)} />
        </NoLabelForm>
      </SimpleDialog>
      <DoneDialog
        visible={doneDialogOpen}
        onCancel={() => {
          setDoneDialogOpen(false);
          close();
        }}
      />
    </>
  );
}

function Steps({ close, onDone }) {
  const [step, setStep] = useState(STEPS.ACCOUNT_SELECTION);
  const stepData = STEPS_DATA[step];
  const { values, formInstance } = useFormContext();
  const { errorMessage } = useFlashMessageContext();

  const saveNextDisabled = !stepData?.canContinue?.(values) ?? false;

  return (
    <>
      <div className="spaces-vert-md">
        <div className="ReportsDialog__Toolbar">
          <div className="ReportsDialog__ToolbarTitle">
            <FormattedMessage id="Report Builder" />
          </div>
          <Row gutter={pxToNumber(cssVariables.spaceNorm)}>
            <Col>
              <Button size="large" onClick={close} className="width-100">
                <FormattedMessage id="buttons.cancel" />
              </Button>
            </Col>
            <Col>
              <GraphQLMutatingButton
                size="large"
                type="primary"
                className="width-190"
                disabled={step < STEPS.REVIEW}
                mutation={GENERATE_REPORT_MUTATION}
                mutationOptions={{
                  onCompleted: data => {
                    const { items } = extractGraphqlEntity(data);
                    if (!every(items, it => it.status === 'SUBMITTED')) {
                      errorMessage({ contentId: 'reports.generation.error' });
                    }
                    onDone();
                  },
                }}
                getMutationArgs={() => {
                  const vals = formInstance.getFieldsValue();
                  return {
                    variables: {
                      input: {
                        accountNumbers: vals.selectedAccounts.map(
                          property('number')
                        ),
                        reportNames: vals.selectedReports.map(property('code')),
                        startDate: toServerDateOptional(vals.dateStart),
                        endDate: toServerDateOptional(vals.dateEnd),
                      },
                    },
                  };
                }}
              >
                <span>
                  <FormattedMessage id="reports.download" />
                </span>
                <FAIcon icon="download" className="icon-20" />
              </GraphQLMutatingButton>
            </Col>
          </Row>
        </div>
        <Row gutter={pxToNumber(cssVariables.spaceNorm)} justify="end">
          <Col>
            <Button
              onClick={() => setStep(old => old - 1)}
              size="small"
              disabled={step === STEPS.ACCOUNT_SELECTION}
            >
              <FAIcon icon="chevron-left" />
              <span>
                <FormattedMessage id="buttons.back" />
              </span>
            </Button>
          </Col>
          {step < max(Object.values(STEPS)) && (
            <Col>
              <Button
                type="primary"
                size="small"
                onClick={() => setStep(old => old + 1)}
                disabled={saveNextDisabled}
              >
                <span>
                  <FormattedMessage id="buttons.saveNext" />
                </span>
                <FAIcon icon="chevron-right" />
              </Button>
            </Col>
          )}
        </Row>
      </div>
      <SlidingContent
        data={STEPS_DATA}
        selected={stepData?.name}
        listenToSelected
        autoHeight
      />
    </>
  );
}

function DoneDialog({ visible, onCancel }) {
  const { data } = useQuery(USER_QUERY);
  const { email } = extractGraphqlEntity(data);

  return (
    <SimpleDialog
      visible={visible}
      onCancel={onCancel}
      mask
      maskClosable
      className="ReportsDoneDialog"
    >
      <HeaderLogo size="sm" />
      <Typography.Title level={1}>
        <FormattedMessage id="reports.doneDialog.title" />
      </Typography.Title>
      <Typography.Title level={4} className="nl-as-newline">
        <FormattedMessage id="reports.doneDialog.paragraph1" />
        <br />
        <br />
        <FormattedMessage
          id="reports.doneDialog.paragraph2"
          values={{ email: <strong>{email}</strong> }}
        />
        <br />
        <br />
        <FormattedMessage id="reports.doneDialog.paragraph3" />
      </Typography.Title>
    </SimpleDialog>
  );
}
