import { useState } from 'react';

import { FlexCol } from '../../../components/layout/layoutElements';
import {
  CollapsibleMapCard,
  CollapsibleMapCardWithToggle,
} from '../../../components/maps/CollapsibleMapCard';
import { useResponsiveQueries } from '../../../components/responsive/responsiveQueries';
import ShipmentDetailMap from '../ShipmentDetailMap';
import { ShipmentSummaries, ShipmentSummariesCol } from '../shipmentSummaries';

function ContentLarge({
  data,
  shipment,
  summariesProps,
  largeViewComponent: C,
}) {
  return (
    <C data={data} shipment={shipment}>
      <ShipmentSummariesCol {...summariesProps} />
    </C>
  );
}

function ContentMedium({ shipment, summariesProps }) {
  return (
    <FlexCol className="Flex1 spaces-vert-norm">
      <ShipmentDetailMap
        shipment={shipment}
        renderMap={map => (
          <CollapsibleMapCard expanded>{map}</CollapsibleMapCard>
        )}
      />
      <ShipmentSummaries {...summariesProps} />
    </FlexCol>
  );
}

function ContentSmall({ shipment, summariesProps }) {
  const [expanded, setExpanded] = useState(true);

  return (
    <FlexCol className="full-height spaces-vert-norm2">
      <ShipmentDetailMap
        shipment={shipment}
        renderMap={map => (
          <CollapsibleMapCardWithToggle
            expanded={expanded}
            setExpanded={setExpanded}
          >
            {map}
          </CollapsibleMapCardWithToggle>
        )}
      />
      <ShipmentSummaries {...summariesProps} />
    </FlexCol>
  );
}

export function ShipmentDetailResponsiveContent({
  data,
  shipment,
  summariesSharedProps,
  summariesViews,
  largeViewComponent,
  ...rest
}) {
  const media = useResponsiveQueries();
  const isLg = media.xxl || media.xl || media.lg;
  const isMd = media.md;

  const summariesProps = {
    shipment,
    views: summariesViews,
    sharedProps: summariesSharedProps,
  };

  if (isLg) {
    return (
      <ContentLarge
        data={data}
        shipment={shipment}
        summariesProps={summariesProps}
        largeViewComponent={largeViewComponent}
        {...rest}
      />
    );
  }
  if (isMd) {
    return (
      <ContentMedium
        data={data}
        shipment={shipment}
        summariesProps={summariesProps}
        {...rest}
      />
    );
  }
  return (
    <ContentSmall
      data={data}
      shipment={shipment}
      summariesProps={summariesProps}
      {...rest}
    />
  );
}
