export function latLng(lat, lng) {
  return { lat, lng };
}

export function point(x, y) {
  return { x, y };
}

export function mapSize(width, height) {
  return window.google && new window.google.maps.Size(width, height);
}

export function createMapBounds(coordinates) {
  const bounds = new window.google.maps.LatLngBounds();
  coordinates.forEach(coords => {
    if (!bounds.contains(coords)) {
      bounds.extend(coords);
    }
  });
  return bounds;
}
