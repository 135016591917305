import { isBoolean, isNumber, isObject, isString } from 'lodash-es';
import moment from 'moment';

import { toServerDateTimeOptional } from '../../../common/utils/dateUtils';

export function extractStringValueFromRichFormValue(value) {
  if (isString(value)) {
    return value;
  }
  if (isNumber(value)) {
    return `${value}`;
  }
  if (isBoolean(value)) {
    return `${value}`;
  }
  if (isObject(value) && value.code) {
    return value.code;
  }
  if (moment.isMoment(value)) {
    return toServerDateTimeOptional(value);
  }
  return '';
}

export function extractLabelFromRichFormValue(value) {
  if (isString(value)) {
    return value;
  }
  if (isNumber(value)) {
    return `${value}`;
  }
  if (isBoolean(value)) {
    return `${value}`;
  }
  if (isObject(value) && value.text) {
    return value.text;
  }
  if (moment.isMoment(value)) {
    return toServerDateTimeOptional(value);
  }
  return '';
}
