import antEn from 'antd/lib/locale-provider/en_GB';

import appEn from './en.json';

export const Language = {
  EN: 'EN',
};

const LanguageData = {
  [Language.EN]: {
    locale: 'en-US',
    antLocale: antEn,
    messages: appEn,
  },
};

export function getLanguageData(lang) {
  return LanguageData[lang];
}
