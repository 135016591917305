import { createIntl } from 'react-intl';

import { getLanguage } from '../app/redux/settings';
import { getLanguageData } from '../config/intl/intl';

const intlCache = {};

export function getIntlFromStore(store) {
  const lang = getLanguage(store.getState());
  if (intlCache[lang]) {
    return intlCache[lang];
  }

  const langData = getLanguageData(lang);
  const intl = createIntl({
    locale: langData.locale,
    messages: langData.messages,
  });
  intlCache[lang] = intl;
  return intl;
}
