export const ShipmentMode = {
  AERO: 'AERO',
  GENERIC: 'GENERIC',
  // medical accounts
  DTP: 'DTP',
  BOT: 'BOT',
  CGT: 'CGT',
  CTS: 'CTS',
  DTL: 'DTL',
  // other modes are not supported
  UNKNOWN: 'UNKNOWN',
};
