import React from 'react';

import {
  SIGNUP_MUTATION,
  SIGNUP_TOKEN_VALIDATION_QUERY,
} from '../app/graphql/authQueries';
import NewPasswordForm from './auth/NewPasswordForm';
import { useChangePasswordFormProps } from './auth/newPasswordCommon';

export default function SignupForm({ token, email }) {
  const {
    error,
    loading,
    changePassword,
    onChangePasswordSuccess,
    validatePassword,
  } = useChangePasswordFormProps({
    token,
    email,
    verifyQuery: SIGNUP_TOKEN_VALIDATION_QUERY,
    changeMutation: SIGNUP_MUTATION,
    successMessageId: 'signup.newPassword.successMessage',
  });

  return (
    <NewPasswordForm
      className="SignupForm"
      tokenError={error}
      tokenValidLoading={loading}
      changePassword={changePassword}
      onChangePasswordSuccess={onChangePasswordSuccess}
      validatePassword={validatePassword}
      passwordButtonTextId="signup.newPassword.action"
      pinButtonTextId="signup.pin.action"
    />
  );
}
