import { InnerContentScrollbars } from '../../../components/layout/InnerContentScrollbars';
import { FlexCol } from '../../../components/layout/layoutElements';
import { AdminConsoleTitleContainer } from '../components/AdminConsoleTitle';
import AdminConsoleHeader from './AdminConsoleHeader';
import AdminConsoleMenu from './AdminConsoleMenu';

export default function AdminConsoleTemplate({ title, children }) {
  return (
    <div className="AdminConsoleTemplate">
      <div className="AdminConsoleTemplate-Content">
        <AdminConsoleHeader />
        <AdminConsoleMenu />
        <div className="AdminConsoleTemplate-InnerContent">
          <FlexCol className="Flex1 gap-norm">
            {title && (
              <AdminConsoleTitleContainer>{title}</AdminConsoleTitleContainer>
            )}
            <FlexCol className="Flex1 AdminConsoleTemplate--Scrollable">
              <InnerContentScrollbars>{children}</InnerContentScrollbars>
            </FlexCol>
          </FlexCol>
        </div>
      </div>
    </div>
  );
}
