import { values } from 'lodash-es';

const ExpandedTrackingOperation = {
  EQUALS: 'EQUALS',
  INCLUDES: 'INCLUDES',
  STARTS_WITH: 'STARTS_WITH',
  ENDS_WITH: 'ENDS_WITH',
};

export const ExpandedTrackingOperationValues = values(
  ExpandedTrackingOperation
);
