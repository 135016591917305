import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Whitespace from '../../components/Whitespace';
import { FormItemLetterInput } from '../../components/forms/LetterInput';
import { useFormContext } from '../../components/forms/forms';
import { cssVariables } from '../../styles/cssVariables';
import { pxToNumber } from '../../utils/cssUtils';

export default function PinField({ name = 'pin' }) {
  const isLetterAllowed = useCallback(letter => /[0-9]/.test(letter), []);
  const intl = useIntl();
  const { submittingRef } = useFormContext();
  const requiredRule = {
    validator: (_, val) => {
      // Only when submitting not to be annoying
      if (!submittingRef.current) {
        return Promise.resolve();
      }
      if (val?.length === 4 && val.join('').length === 4) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error(intl.formatMessage({ id: 'error.required' }))
      );
    },
  };
  const [showLetters, setShowLetters] = useState(false);

  return (
    <Row
      align="middle"
      justify="center"
      gutter={pxToNumber(cssVariables.spaceNorm)}
    >
      <Col>
        <FormItemLetterInput
          name={name}
          size={4}
          elementSize="lg"
          rules={[requiredRule]}
          formItemComponentProps={{
            autoFocus: true,
            isLetterAllowed,
            type: showLetters ? 'text' : 'password',
          }}
        />
      </Col>
      <Col>
        <div
          className="Clickable PinField__ShowToggle"
          onClick={() => setShowLetters(!showLetters)}
        >
          {showLetters ? <EyeInvisibleOutlined /> : <EyeOutlined />}
          <Whitespace />
          <FormattedMessage
            id={showLetters ? 'pinField.hide' : 'pinField.show'}
          />
        </div>
      </Col>
    </Row>
  );
}
