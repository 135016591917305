import { Col, Row } from 'antd';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import ShipmentDetailDrawer from '../../components/drawers/ShipmentDetailDrawer';
import { DRAWER_MEDIA_MAPPER } from '../../components/drawers/drawerCommon';
import { InnerContentScrollbars } from '../../components/layout/InnerContentScrollbars';
import { LabelWithValue } from '../../components/typography';
import { cssVariables } from '../../styles/cssVariables';
import { pxToNumber } from '../../utils/cssUtils';

const StyledLabelWithValue = props => (
  <LabelWithValue {...props} size="xs" labelSize="full" nowrap />
);

function AssetEntry({ data, index }) {
  const gutter = pxToNumber(cssVariables.spaceNorm);

  return (
    <div
      className={classNames('ShipmentAssetsInventory-Entry', {
        'odd-index': index % 2 === 0,
        'even-index': index % 2 === 1,
      })}
    >
      <Row align="stretch" gutter={gutter}>
        <Col className="col-1">
          <StyledLabelWithValue
            labelId="shipmentDetail.inventoryDrawer.description"
            text={data.description}
          />
        </Col>
        <Col className="cols-2-3">
          <Row align="top" gutter={gutter}>
            <Col className="col-2">
              <StyledLabelWithValue
                labelId="shipmentDetail.inventoryDrawer.partNumber"
                text={data.partNumber}
              />
            </Col>
            <Col className="col-3">
              <StyledLabelWithValue
                labelId="shipmentDetail.inventoryDrawer.assetTagNumber"
                text={data.assetTagNumber}
              />
            </Col>
          </Row>
          <Row align="top" gutter={gutter}>
            <Col className="col-2">
              <StyledLabelWithValue
                labelId="shipmentDetail.inventoryDrawer.serialNumber"
                text={data.serialNumber}
              />
            </Col>
            <Col className="col-3">
              <StyledLabelWithValue
                labelId="shipmentDetail.inventoryDrawer.poNumber"
                text={data.poNumber}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export function ShipmentAssetsInventoryDrawer({ isOpen, close, data }) {
  return (
    <ShipmentDetailDrawer
      visible={isOpen}
      onClose={close}
      header={<FormattedMessage id="shipmentDetail.inventoryDrawer.header" />}
      className="ShipmentAssetsInventoryDrawer"
    >
      <InnerContentScrollbars mediaMapper={DRAWER_MEDIA_MAPPER}>
        <div className="ShipmentAssetsInventory">
          {(data || []).map((asset, i) => (
            // Items are static, so there is no problem in using index as key
            // eslint-disable-next-line react/no-array-index-key
            <AssetEntry key={i} index={i} data={asset} />
          ))}
        </div>
      </InnerContentScrollbars>
    </ShipmentDetailDrawer>
  );
}
