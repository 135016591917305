import { gql } from '@apollo/client';

export const SHIPMENT_FILTER_TEMPLATES_QUERY = gql`
  query ShipmentFilterTemplatesQuery($sort: Sort) {
    shipmentFilters(sort: $sort) {
      data {
        id
        name
        accountNumber
        shipmentStatus
        date {
          dateFrom
          dateTo
          namedRange
        }
        destinationContactName
        destinationCity
        destinationCountry
      }
    }
  }
`;

export const SHIPMENT_STATUS_OPTIONS_QUERY = gql`
  query ShipmentStatusesQuery {
    shipmentStatuses {
      code
      text
    }
  }
`;

export const SAVE_SHIPMENT_FILTER_TEMPLATE_MUTATION = gql`
  mutation CreateShipmentFilterMutation($input: ShipmentFilterTemplateInput!) {
    createShipmentFilter(input: $input) {
      id
    }
  }
`;

export const DELETE_SHIPMENT_FILTER_TEMPLATE_MUTATION = gql`
  mutation DeleteShipmentFilterMutation($id: String!) {
    deleteShipmentFilter(id: $id)
  }
`;
