import classNames from 'classnames';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { FAIcon } from '../../components/adapters/fontAwesomeAdapters';
import { InlineLink } from '../../components/typography';
import iconBlue from '../../resources/img/icons/map-circle-blue.png';
import iconGrey from '../../resources/img/icons/map-circle-grey.png';
import iconYellow from '../../resources/img/icons/map-circle-yellow.png';
import groupingIcon from '../../resources/img/icons/map-grouping.png';

export default function MonitorJobMapLegend() {
  const [isOpen, setOpen] = useState();
  return (
    <div
      className={classNames('MonitorMapLegend', {
        open: isOpen,
        closed: !isOpen,
      })}
    >
      <InlineLink
        className="MonitorMapLegend-Title"
        iconAfter={<FAIcon icon={isOpen ? 'caret-down' : 'caret-right'} />}
        onClick={() => setOpen(val => !val)}
      >
        <FormattedMessage id="monitor.map.legend" />
      </InlineLink>
      <div className="MonitorMapLegend-Text">
        <div>
          <span className="label">
            <FormattedMessage id="monitor.map.legend.nonDelivered" />
          </span>
          <span className="icon">
            <img src={iconBlue} alt="" />
          </span>
        </div>
        <div>
          <span className="label">
            <FormattedMessage id="monitor.map.legend.delivered" />
          </span>
          <span className="icon">
            <img src={iconGrey} alt="" />
            <FAIcon icon="check" />
          </span>
        </div>
        <div>
          <span className="label">
            <FormattedMessage id="monitor.map.legend.revisedETA" />
          </span>
          <span className="icon">
            <img src={iconYellow} alt="" />
            <FAIcon icon="exclamation" />
          </span>
        </div>
        <div style={{ height: 40 }}>
          <div className="label">
            <FormattedMessage id="monitor.map.legend.group" />
          </div>
          <div className="icon">
            <img src={groupingIcon} alt="" />
            <div className="text-label">2</div>
          </div>
        </div>
      </div>
    </div>
  );
}
