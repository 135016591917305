import classNames from 'classnames';
import { capitalize, get, isEmpty } from 'lodash-es';
import { createContext, useContext } from 'react';

const AdminConsoleSectionContext = createContext({ level: -1 });

export default function AdminConsoleSection({ children }) {
  const { level: parentLevel } = useContext(AdminConsoleSectionContext);
  const level = parentLevel < 0 ? 0 : parentLevel + 1;

  return (
    <AdminConsoleSectionContext.Provider value={{ level }}>
      <div className={`AdminConsoleSection AdminConsoleSection--Level${level}`}>
        {children}
      </div>
    </AdminConsoleSectionContext.Provider>
  );
}

function AdminConsoleSectionHeader({ type, children }) {
  const { level } = useContext(AdminConsoleSectionContext);
  return (
    <div
      className={`AdminConsoleSection__Header AdminConsoleSection__Header--${type} AdminConsoleSection__Header--Level${level}`}
    >
      {children}
    </div>
  );
}

export function AdminConsoleSectionTableHeader(props) {
  return <AdminConsoleSectionHeader {...props} type="Table" />;
}
export function AdminConsoleSectionTitleHeader(props) {
  return <AdminConsoleSectionHeader {...props} type="Title" />;
}

export function AdminConsoleSectionHeaderControls({ children }) {
  return <div className="AdminConsoleSection__HeaderControls">{children}</div>;
}

export function AdminConsoleSectionContent({ children, className }) {
  const { level } = useContext(AdminConsoleSectionContext);
  return (
    <div
      className={classNames(
        `AdminConsoleSection__Content AdminConsoleSection__Content--Level${level}`,
        className
      )}
    >
      {children}
    </div>
  );
}

export function AdminConsoleSectionSubheader({ children, size = 'sm' }) {
  return (
    <div
      className={classNames(
        'AdminConsoleSection__Subheader',
        `AdminConsoleSection__Subheader--Size${capitalize(size)}`
      )}
    >
      {children}
    </div>
  );
}

export function AdminConsoleSectionLine() {
  return <div className="AdminConsoleSection__Line" />;
}

export function AdminConsoleSectionTable({
  header,
  columnsClassName,
  children,
  rows,
  rowKey = 'id',
  renderRow,
}) {
  const hasData = renderRow ? !isEmpty(rows) : !!children;
  const showHeader = header && hasData;

  return (
    <div className="AdminConsoleSection__Table">
      {showHeader && (
        <div
          className={classNames(
            'AdminConsoleSection__Table__Headers',
            columnsClassName
          )}
        >
          {header}
        </div>
      )}
      {hasData &&
        (renderRow ? (
          <div className="AdminConsoleSection__Table__Content AdminConsoleSection__Table__Content--Rows">
            {rows?.map((row, i) => (
              <div key={get(row, rowKey)} className={columnsClassName}>
                {renderRow(row, i)}
              </div>
            ))}
          </div>
        ) : (
          <div
            className={classNames(
              'AdminConsoleSection__Table__Content AdminConsoleSection__Table__Content--Single',
              columnsClassName
            )}
          >
            {children}
          </div>
        ))}
    </div>
  );
}
