import { List } from 'immutable';
import { debounce, isArray, isEqual, isFunction, noop } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';

import { isIdentical } from './funcUtils';

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useMountEffect = func => useEffect(func, []);

export function useMountAndUpdateEffect(
  { onMount = noop, onUpdate = noop } = {},
  deps
) {
  const wasFired = useRef(false);
  const prevDeps = useRef(deps);
  useEffect(() => {
    let destroy;
    if (wasFired.current) {
      destroy = onUpdate(prevDeps.current);
    } else {
      wasFired.current = true;
      destroy = onMount();
    }
    prevDeps.current = deps;
    return isFunction(destroy) ? destroy : noop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export function useCachedProp(propValue) {
  const [lastValue, setLastValue] = useState(propValue);
  useEffect(() => {
    if (!isEqual(propValue, lastValue)) {
      setLastValue(propValue);
    }
  }, [lastValue, propValue]);
  return lastValue;
}

export function useDebouncedVariable(
  value,
  { delay = 500, comparator = isIdentical } = {}
) {
  const [debouncedProp, setDebouncedProp] = useState(value);
  const prevValue = useRef(value);
  const timeout = useRef();
  useEffect(() => {
    if (!comparator(value, prevValue.current)) {
      prevValue.current = value;
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => setDebouncedProp(value), delay);
      return () => clearTimeout(timeout.current);
    }
    return noop;
  }, [comparator, delay, value]);

  return debouncedProp;
}

export function useArrayState(initValues) {
  const [values, setValues] = useState(
    isArray(initValues) ? List(initValues) : List()
  );

  const setValue = (index, value) => setValues(values.set(index, value));
  const removeValue = index => setValues(values.delete(index));
  const setAllValues = vals => setValues(List(vals));

  return {
    values: values.toArray(),
    setValue,
    removeValue,
    setAllValues,
  };
}

export function useEffectDebounced(fn, delay, deps) {
  const callThrottled = useRef(debounce(toThrottleFn => toThrottleFn(), delay));
  useEffect(() => {
    callThrottled.current = debounce(toThrottleFn => toThrottleFn(), delay);
  });
  useEffect(() => {
    callThrottled.current(fn);
  }, deps); // eslint-disable-line
}
