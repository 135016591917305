import { Col, Row, Typography } from 'antd';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import ErrorBoundary from '../components/ErrorBoundary';
import Whitespace from '../components/Whitespace';
import { FAIcon } from '../components/adapters/fontAwesomeAdapters';
import { InlineLink } from '../components/typography';
import { cssVariables } from '../styles/cssVariables';
import { pxToNumber } from '../utils/cssUtils';

function SimpleDetailPageHeader({ className, children }) {
  return (
    <ErrorBoundary>
      <div className={classNames('DetailPageHeader', className)}>
        {children}
      </div>
    </ErrorBoundary>
  );
}

export function BackLinkDetailPageHeader({
  backTargetId,
  backLink,
  titleId,
  buttons,
}) {
  return (
    <SimpleDetailPageHeader className="DetailPageHeader--size-40 DetailPageHeader--WithBackLink">
      <Row className="flex-nowrap" align="middle" justify="space-between">
        <Col>
          {backLink && backTargetId && (
            <InlineLink
              iconBefore={<FAIcon icon="arrow-circle-left" />}
              to={backLink}
            >
              <FormattedMessage id="buttons.prefix.backTo" />
              <Whitespace />
              <FormattedMessage id={backTargetId} />
            </InlineLink>
          )}
        </Col>
        <Col>
          <Row
            className="flex-nowrap"
            align="middle"
            gutter={pxToNumber(cssVariables.spaceNorm)}
          >
            <Col>
              <Typography.Title level={2}>
                <FormattedMessage id={titleId} />
              </Typography.Title>
            </Col>
            {buttons && <Col>{buttons}</Col>}
          </Row>
        </Col>
      </Row>
    </SimpleDetailPageHeader>
  );
}
