import { fromPairs, get, noop } from 'lodash-es';
import { createContext, useCallback, useContext, useState } from 'react';

import {
  useCachedProp,
  useMountAndUpdateEffect,
} from '../../../common/utils/hookUtils';
import { extractStringValueFromRichFormValue } from './dynamicFormHelpers';

export const DynamicFormDependenciesContext = createContext({
  values: {},
  blurCounts: {},
  onBlur: noop,
});

export function DynamicFormDependenciesProvider({ values, children }) {
  const [blurCounts, setBlurCounts] = useState({});
  const onBlur = useCallback(
    schemaName =>
      schemaName &&
      setBlurCounts(old =>
        old[schemaName]
          ? { ...old, [schemaName]: old[schemaName] + 1 }
          : { ...old, [schemaName]: 1 }
      ),
    []
  );

  return (
    <DynamicFormDependenciesContext.Provider
      value={{ values, blurCounts, onBlur }}
    >
      {children}
    </DynamicFormDependenciesContext.Provider>
  );
}

// Initial implementation - may change in the future
// Assumptions: dates are represented as String in the state
function getStringValue(values, name) {
  const value = get(values, name);
  return extractStringValueFromRichFormValue(value);
}

export function useDynamicFieldDependencies(dependenciesNames) {
  const { values } = useContext(DynamicFormDependenciesContext);

  const dependencies = useCachedProp(
    dependenciesNames.map(field => ({
      field,
      value: getStringValue(values, field),
    }))
  );

  return { dependencies };
}

export function useEffectOnDependenciesBlur(effect, dependenciesNames) {
  const { blurCounts } = useContext(DynamicFormDependenciesContext);
  const listenedBlurCounts = useCachedProp(
    fromPairs(dependenciesNames.map(name => [name, blurCounts[name] || 0]))
  );

  useMountAndUpdateEffect(
    {
      onUpdate: () => {
        effect();
      },
    },
    [listenedBlurCounts]
  );
}

export function useDynamicOriginalValueDependency(dependencySchemaName) {
  const { values } = useContext(DynamicFormDependenciesContext);
  return get(values, dependencySchemaName);
}
