import { Button, Col, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory } from 'react-router';

import { EDIT_SHIPMENT_MUTATION } from '../../app/graphql/jobFormQueries';
import { JOB_QUERY } from '../../app/graphql/jobQueries';
import routes from '../../app/routes';
import { CustomLoaderButton } from '../../components/buttons/buttons';
import { useAsynchronousAction } from '../../components/data/asyncActionElements';
import { useResponsiveQueries } from '../../components/responsive/responsiveQueries';
import {
  BaseShipmentSubmitStateDialog,
  useShipmentSubmitState,
} from './useShipmentSubmitState';

export function useReleaseSavedShipmentSubmit({ jobNumber }) {
  return useShipmentSubmitState({
    mutation: EDIT_SHIPMENT_MUTATION,
    mutationOptions: {
      refetchQueries: [JOB_QUERY],
      awaitRefetchQueries: true,
    },
    jobNumber,
  });
}

function ConfirmOrderHeaderNormal({
  action,
  shipmentForm,
  setEditShipmentInitialValues,
}) {
  const { push } = useHistory();
  const media = useResponsiveQueries();
  const textId =
    media.xs || media.sm
      ? 'book.confirmOrder.releaseShipment'
      : 'book.confirmOrder.releaseShipment.long';

  return (
    <Row className="ConfirmOrderPage-Buttons">
      <Col className="ant-col__small">
        <Button
          className="ant-btn--round-left"
          size="large"
          onClick={() => {
            setEditShipmentInitialValues(shipmentForm);
            push(
              generatePath(routes.editShipment, {
                jobNumber: shipmentForm.jobNumber,
              })
            );
          }}
        >
          <FormattedMessage id="buttons.edit" />
        </Button>
      </Col>
      <Col className="ant-col__big">
        <CustomLoaderButton
          className="ant-btn__font-plus-1"
          type="primary"
          size="large"
          onClick={action.execute}
        >
          <FormattedMessage id={textId} />
        </CustomLoaderButton>
      </Col>
      <Col className="ant-col__small">
        <Button
          className="ant-btn--round-right"
          size="large"
          onClick={() => push(routes.monitor)}
          disabled={action.loading}
        >
          <FormattedMessage id="buttons.cancel" />
        </Button>
      </Col>
    </Row>
  );
}

function ConfirmOrderHeaderLoading() {
  return (
    <Row className="ConfirmOrderPage-Buttons">
      <Col className="ant-col__big">
        <CustomLoaderButton
          className="three-dots-lg ant-btn__font-plus-1 ant-btn--round-left ant-btn--round-right"
          type="primary"
          size="large"
          loading
          disabled
        >
          <FormattedMessage id="book.confirmOrder.releasing" />
        </CustomLoaderButton>
      </Col>
    </Row>
  );
}

function ConfirmOrderHeader({
  action,
  onCancel,
  shipmentForm,
  setEditShipmentInitialValues,
}) {
  if (action.loading) {
    return (
      <ConfirmOrderHeaderLoading
        action={action}
        onCancel={onCancel}
        shipmentForm={shipmentForm}
        setEditShipmentInitialValues={setEditShipmentInitialValues}
      />
    );
  }

  return (
    <ConfirmOrderHeaderNormal
      action={action}
      onCancel={onCancel}
      shipmentForm={shipmentForm}
      setEditShipmentInitialValues={setEditShipmentInitialValues}
    />
  );
}

export function ReleaseSavedShipmentSubmitDialog({
  onConfirm,
  setEditShipmentInitialValues,
  ...rest
}) {
  const { push } = useHistory();

  const action = useAsynchronousAction(async ({ shipmentForm }) => {
    const result = await onConfirm({ shipmentForm });
    if (result) {
      const { jobNumber } = result;
      push(generatePath(routes.shipmentDetailSuccess, { jobNumber }));
    }
  });

  const confirmOrderPageProps = {
    headerComponent: ConfirmOrderHeader,
    headerProps: { setEditShipmentInitialValues },
    action,
  };

  return (
    <BaseShipmentSubmitStateDialog
      onConfirm={onConfirm}
      confirmOrderPageProps={confirmOrderPageProps}
      {...rest}
    />
  );
}
