import { pick } from 'lodash-es';

import { FAIcon } from '../../../../components/adapters/fontAwesomeAdapters';
import AdminUserExpandedTrackingFormSections from '../forms/AdminUserExpandedTrackingFormSections';
import AdminAddUserStepTemplate from './AdminAddUserStepTemplate';

export default function AdminAddUserExpandedTracking({
  onSave,
  onBack,
  onCancel,
  values,
}) {
  return (
    <AdminAddUserStepTemplate
      titleId="admin.userManagement.addUser.expandedTracking"
      titleIconNode={
        <div className="flex-center" style={{ alignItems: 'flex-start' }}>
          <FAIcon icon="search" className="icon-18" />
          <FAIcon icon="plus" className="icon-11" />
        </div>
      }
      onSave={onSave}
      onBack={onBack}
      onCancel={onCancel}
      values={values}
      formClassName=""
      okButtonLabelId="admin.userManagement.addUser.save"
      extractRelevantValues={vals => pick(vals, ['expandedTracking'])}
      allowSaveEmpty
      twoStepSkipProcess
      saveButtonInHeader
    >
      <AdminUserExpandedTrackingFormSections />
    </AdminAddUserStepTemplate>
  );
}
