import { useApolloClient } from '@apollo/client';
import { every, isEmpty, property } from 'lodash-es';
import { useContext, useState } from 'react';

import { extractGraphqlEntity } from '../../../common/utils/graphqlUtils';
import { useMountEffect } from '../../../common/utils/hookUtils';
import { useDebouncedAsyncCallback } from '../../../common/utils/promiseUtils';
import { useFlashMessageContext } from '../../dialogs/FlashMessageProvider';
import { FormMode, useFormContext } from '../forms';
import {
  useDynamicFieldDependencies,
  useEffectOnDependenciesBlur,
} from './dynamicFormDependencies';
import { extractStringValueFromRichFormValue } from './dynamicFormHelpers';
import {
  DynamicFormSchemaContext,
  useDynamicFormSchemaFieldDefinition,
} from './dynamicFormSchema';

const DEBOUNCE_DELAY = 500;

export function useDynamicAutofill({ name, schemaName, onChange, onSkip }) {
  const fieldDef = useDynamicFormSchemaFieldDefinition({ schemaName });
  const { formInstance } = useFormContext();

  const { mode } = useFormContext();

  const { schema, autofillQuery } = useContext(DynamicFormSchemaContext);
  const schemaLoaded = !!schema;
  const { errorMessage } = useFlashMessageContext();

  const client = useApolloClient();

  const [loading, setLoading] = useState(false);
  const listenTo = fieldDef?.autofill?.listenTo || [];
  const { dependencies: paramDependencies } = useDynamicFieldDependencies(
    fieldDef?.autofill?.dependsOn || []
  );
  const skip =
    !schemaLoaded ||
    isEmpty(listenTo) ||
    !every(paramDependencies, property('value'));

  const runAutofill = useDebouncedAsyncCallback(
    async () => {
      try {
        setLoading(true);
        const currentValue = extractStringValueFromRichFormValue(
          formInstance.getFieldValue(name)
        );

        const { data } = await client.query({
          query: autofillQuery,
          variables: {
            input: {
              fieldName: schemaName,
              value: currentValue,
              params: paramDependencies,
            },
          },
        });
        const value = extractGraphqlEntity(data)?.value;
        onChange(value || '');
        if (currentValue || value) {
          formInstance.validateFields([name]);
        }
      } catch (e) {
        errorMessage(e);
      } finally {
        setLoading(false);
      }
    },
    [
      autofillQuery,
      client,
      errorMessage,
      onChange,
      paramDependencies,
      schemaName,
    ],
    { delay: DEBOUNCE_DELAY }
  );

  // Apply autofill initially only in add mode
  useMountEffect(() => {
    if (!skip && mode === FormMode.ADD) {
      runAutofill();
    }
  });

  // Apply autofill on blur of a listened field
  useEffectOnDependenciesBlur(() => {
    if (!skip) {
      runAutofill();
    } else {
      onSkip && onSkip();
    }
  }, listenTo);

  return { loading };
}
