import { useMutation, useQuery } from '@apollo/client';
import { Col, Row, Tooltip } from 'antd';
import * as clipboard from 'clipboard-polyfill/text';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Route, generatePath, useHistory } from 'react-router';

import { shipmentConversions } from '../app/data/shipmentConversions';
import {
  JOB_QUERY,
  SHARED_SHIPMENT_QUERY,
  SHARE_SHIPMENT_MUTATION,
} from '../app/graphql/jobQueries';
import { setShipmentDetailSummaryView } from '../app/redux/shipmentDetail';
import routes from '../app/routes';
import { extractGraphqlEntity } from '../common/utils/graphqlUtils';
import IconButton from '../components/buttons/IconButton';
import { renderDataStateIndicator } from '../components/data/dataStateHandlers';
import { useFlashMessageContext } from '../components/dialogs/FlashMessageProvider';
import { OverrideSettingsContextProvider } from '../components/domainSpecific/settingsElements';
import { SimpleCard, SimpleCardTheme } from '../components/layout/cardElements';
import { Flex1 } from '../components/layout/layoutElements';
import { useResponsiveQueries } from '../components/responsive/responsiveQueries';
import { DEFAULT_POLL_REFRESH_INTERVAL } from '../config/constants';
import { OrderSuccessDialog } from '../dialogs/shipments/newShipmentDialogs';
import { useAccounts } from '../hooks/data/auth';
import { cssVariables } from '../styles/cssVariables';
import DetailPage from '../templates/DetailPage';
import { pxToNumber } from '../utils/cssUtils';
import { BackLinkDetailPageHeader } from '../widgets/detailPageHeaders';
import ShipmentDetailMap from './shipmentDetail/ShipmentDetailMap';
import ShipmentMilestonesSteps from './shipmentDetail/ShipmentMilestonesSteps';
import { SHIPMENT_SUMMARY_VIEWS } from './shipmentDetail/shipmentSummaries';
import { ShipmentDetailResponsiveContent } from './shipmentDetail/shipmentSummaries/ShipmentDetailResponsiveContent';

const Card = props => (
  <SimpleCard {...props} theme={SimpleCardTheme.ROUND_BORDERED} />
);

function LargeView({ shipment, children }) {
  return (
    <Row
      className="Flex1 flex-nowrap"
      gutter={pxToNumber(cssVariables.spaceNorm2)}
    >
      {children}
      <Col className="FlexCol Flex1 spaces-vert-norm2">
        <Card
          className="ShipmentMilestonesCard"
          titleId="shipmentDetail.shipmentMilestones"
        >
          <ShipmentMilestonesSteps shipment={shipment} />
        </Card>
        <Flex1>
          <ShipmentDetailMap shipment={shipment} />
        </Flex1>
      </Col>
    </Row>
  );
}

function ReverseOrderButton({ shipment }) {
  const { push } = useHistory();
  return (
    <Tooltip
      placement="bottom"
      overlayClassName="ant-tooltip--sm nl-as-newline text-center"
      title={<FormattedMessage id="shipmentDetail.reverseOrder.tooltip" />}
    >
      <IconButton
        className="ShipmentHeaderButton"
        labelId="shipmentDetail.reverseOrder"
        icon="exchange-alt"
        onClick={() => {
          push(
            generatePath(routes.reverseShipment, {
              jobNumber: shipment.jobNumber,
            })
          );
        }}
      />
    </Tooltip>
  );
}

function ShareButton({ shipment }) {
  const [share, { loading: sharing }] = useMutation(SHARE_SHIPMENT_MUTATION);
  const { errorMessage, successMessage } = useFlashMessageContext();

  return (
    <IconButton
      className="ShipmentHeaderButton"
      labelId="shipmentDetail.share"
      icon="link"
      disabled={sharing}
      onClick={async () => {
        const { data } = await share({
          variables: { jobNumber: shipment.jobNumber },
        });
        const { token, url } = extractGraphqlEntity(data);
        const finalUrl =
          url ||
          `${window.location.origin}${generatePath(
            routes.sharingShipmentDetail,
            { token }
          )}`;
        try {
          clipboard.writeText(finalUrl);
          successMessage({ contentId: 'copyToClipboard.success' });
        } catch (err) {
          errorMessage({ contentId: 'copyToClipboard.failure' });
        }
      }}
    />
  );
}

function PrintBolButton({ bolUrl }) {
  return bolUrl ? (
    <IconButton
      className="ShipmentHeaderButton"
      labelId="shipmentDetail.printBolLabel"
      icon="print"
      onClick={() => window.open(bolUrl, '__blank')}
    />
  ) : null;
}

function HeaderButtons({ shipment }) {
  const bolUrl = shipment?.documents?.bolUrl;
  const shareable = shipment?.metadata?.shareable;

  return (
    <Row align="middle" gutter={pxToNumber(cssVariables.spaceSm)}>
      <Col>
        <ReverseOrderButton shipment={shipment} />
      </Col>
      {shareable && (
        <Col>
          <ShareButton shipment={shipment} />
        </Col>
      )}
      {bolUrl && (
        <Col>
          <PrintBolButton bolUrl={bolUrl} />
        </Col>
      )}
    </Row>
  );
}

function ShipmentDetailPageInner({
  shipment,
  indicator,
  showBackButton,
  showHeaderButtons,
}) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setShipmentDetailSummaryView(SHIPMENT_SUMMARY_VIEWS[0].name));
  }, [dispatch]);

  const media = useResponsiveQueries();
  const isSm = media.sm || media.xs;

  return (
    <DetailPage
      id="ShipmentDetailPage"
      header={
        <BackLinkDetailPageHeader
          backTargetId={showBackButton && 'monitor.short'}
          backLink={showBackButton && routes.monitor}
          titleId="shipmentDetail.title"
          buttons={showHeaderButtons && <HeaderButtons shipment={shipment} />}
        />
      }
      wrapInScrollbar={isSm}
    >
      <OverrideSettingsContextProvider unitSystem={shipment.unitSystem}>
        {indicator || (
          <ShipmentDetailResponsiveContent
            shipment={shipment}
            summariesSharedProps={{ showAirlineFlightStatus: true }}
            largeViewComponent={LargeView}
          />
        )}
      </OverrideSettingsContextProvider>
    </DetailPage>
  );
}

export default function ShipmentDetailPage({ match: { url, params } }) {
  const { replace } = useHistory();
  const { data, loading, error } = useQuery(JOB_QUERY, {
    variables: { jobNumber: params.jobNumber },
    pollInterval: DEFAULT_POLL_REFRESH_INTERVAL,
    fetchPolicy: 'cache-and-network',
  });
  const silentReload = loading && data;
  const indicator =
    !silentReload && renderDataStateIndicator({ loading, error, data });

  const { customerAccounts } = useAccounts();
  const rawShipment = !indicator && extractGraphqlEntity(data);
  const shipment =
    rawShipment &&
    shipmentConversions.graphqlToDetail(rawShipment, { customerAccounts });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setShipmentDetailSummaryView(SHIPMENT_SUMMARY_VIEWS[0].name));
  }, [dispatch]);

  return (
    <>
      <ShipmentDetailPageInner
        shipment={shipment}
        indicator={indicator}
        showHeaderButtons
        showBackButton
      />
      <Route
        path={routes.shipmentDetailSuccess}
        render={routeProps => (
          <OrderSuccessDialog {...routeProps} onOk={() => replace(url)} />
        )}
      />
    </>
  );
}

export function SharedShipmentDetailPage({ match: { params } }) {
  const { data, loading, error } = useQuery(SHARED_SHIPMENT_QUERY, {
    variables: { token: params.token },
    pollInterval: DEFAULT_POLL_REFRESH_INTERVAL,
    fetchPolicy: 'cache-and-network',
  });
  const silentReload = loading && data;
  const indicator =
    !silentReload && renderDataStateIndicator({ loading, error, data });

  const rawShipment = !indicator && extractGraphqlEntity(data);
  const shipment =
    rawShipment && shipmentConversions.graphqlToDetail(rawShipment, {});

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setShipmentDetailSummaryView(SHIPMENT_SUMMARY_VIEWS[0].name));
  }, [dispatch]);

  return (
    <ShipmentDetailPageInner
      shipment={shipment}
      indicator={indicator}
      showHeaderButtons={false}
      showBackButton={false}
    />
  );
}
