import { range } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';

import { useInternalStepsState } from '../../../components/nav/StepsWithContent';

export default function useNewShipmentSteps({
  formNamePrefix,
  forms,
  refreshValues,
  steps,
  disableUnvisitedSteps,
  activeTemplateCounter,
}) {
  const [maxVisitedIndex, setMaxVisitedIndex] = useState(0);
  const additionalProps = {
    packageList: {
      onEditedPackageChange: (changed, allValues) =>
        // `requestAnimationFrame` is to avoid collisions with native form changes
        requestAnimationFrame(() => refreshValues({ packages: allValues })),
    },
  };
  const stepsWithFormProps = range(0, steps.length).map(i => {
    const step = steps[i];
    const { form, name, formRef } = forms[i];
    return {
      ...step,
      disabled: disableUnvisitedSteps ? i > maxVisitedIndex : false,
      props: {
        form,
        formRef,
        formName: `${formNamePrefix || ''}-${name}`,
        onValuesChange: () => refreshValues(),
        activeTemplateCounter,
        ...additionalProps[name],
      },
    };
  });

  const stepsProps = useInternalStepsState(stepsWithFormProps);
  const { currentIndex, goToNext, goToPrev, step, setStep } = stepsProps;
  const firstStep = steps[0].name;
  const resetStep = useCallback(() => {
    setStep(firstStep);
    setMaxVisitedIndex(0);
  }, [firstStep, setStep]);
  useEffect(() => {
    if (currentIndex > maxVisitedIndex) {
      setMaxVisitedIndex(currentIndex);
    }
  }, [currentIndex, maxVisitedIndex]);

  return { stepsProps, currentIndex, goToNext, goToPrev, step, resetStep };
}
