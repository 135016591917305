import { noop } from 'lodash-es';
import { createContext, useMemo, useState } from 'react';

export const FiltersAndSortContext = createContext({
  filters: {
    values: {},
    setValues: noop,
    initialValues: {},
  },
  sort: {
    values: undefined,
    setValues: noop,
    initialValues: undefined,
  },
});

export function useFiltersAndSort({ initialFilters = {}, initialSort } = {}) {
  const [filters, setFilters] = useState(initialFilters);
  const [sort, setSort] = useState(initialSort);

  return useMemo(
    () => ({
      filters: {
        values: filters,
        setValues: setFilters,
        initialValues: initialFilters,
      },
      sort: {
        values: sort,
        setValues: setSort,
      },
    }),
    [filters, initialFilters, sort]
  );
}
