import { Col, Form, Row, Typography } from 'antd';
import classNames from 'classnames';
import { isFunction } from 'lodash-es';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { extractGraphqlEntity } from '../../../common/utils/graphqlUtils';
import { cssVariables } from '../../../styles/cssVariables';
import { pxToNumber } from '../../../utils/cssUtils';
import { FAIcon } from '../../adapters/fontAwesomeAdapters';
import { useFlashMessageContext } from '../../dialogs/FlashMessageProvider';
import { FormItemInputText } from '../../forms/basicFormElements';
import { NoLabelForm } from '../../forms/forms';
import { Popup, PopupContext } from '../../nav/navElements';
import { InlineLink } from '../../typography';

function SavingForm({
  saveAction,
  getGraphQLData,
  successMessageId,
  afterSave,
  onError,
  children,
}) {
  const { execute: save, loading: saving } = saveAction;
  const [form] = Form.useForm();
  const { setOpen } = useContext(PopupContext);
  const { errorMessage, successMessage } = useFlashMessageContext();

  const saveAndClose = async () => {
    const input = getGraphQLData(form.getFieldsValue());
    try {
      try {
        await form.validateFields();
      } catch (e) {
        // Validation messages are shown automatically, so no need to do any more handling
        return;
      }
      const { data } = await save({ variables: { input } });
      setOpen(false);
      form.resetFields();
      successMessage({ contentId: successMessageId });
      afterSave && afterSave({ ...input, ...extractGraphqlEntity(data) });
    } catch (e) {
      if (isFunction(onError)) {
        onError(e);
      } else {
        errorMessage(e);
      }
    }
  };

  return (
    <NoLabelForm component={false} form={form}>
      {children({ saveAndClose, saving, closePopup: () => setOpen(false) })}
    </NoLabelForm>
  );
}

function SaveTemplatePopupContent({
  afterSave,
  saveAction,
  getGraphQLData,
  titleId,
  successMessageId,
  nameLabelId,
  onError,
}) {
  return (
    <SavingForm
      saveAction={saveAction}
      getGraphQLData={getGraphQLData}
      successMessageId={successMessageId}
      afterSave={afterSave}
      onError={onError}
    >
      {({ saveAndClose, saving, closePopup }) => (
        <div className="spaces-vert-xs">
          <Typography.Title level={4}>
            <FormattedMessage id={titleId} />
          </Typography.Title>
          <FormItemInputText
            name="name"
            labelId={nameLabelId}
            rules={[{ required: true }]}
            formItemComponentProps={{
              onKeyDown: e => {
                if (!saving && e.key === 'Enter') {
                  saveAndClose();
                }
              },
              autoFocus: true,
            }}
            disabled={saving}
          />
          <Row
            className="control-buttons"
            align="middle"
            justify="end"
            gutter={pxToNumber(cssVariables.spaceNorm2)}
          >
            <Col>
              <InlineLink
                className="cancel-button"
                textId="buttons.cancel"
                onClick={closePopup}
              />
            </Col>
            <Col>
              <InlineLink
                textId="buttons.save"
                iconBefore={<FAIcon icon="plus-square" />}
                onClick={saveAndClose}
                disabled={saving}
              />
            </Col>
          </Row>
        </div>
      )}
    </SavingForm>
  );
}

export function SaveTemplatePopup({
  className,
  overlayClassName,
  disabled,
  label,
  labelId,
  activeItem,
  afterSave,
  saveAction,
  getGraphQLData,
  titleId,
  successMessageId,
  nameLabelId,
  onError,
  ...rest
}) {
  return (
    <Popup
      className={classNames('SaveTemplatePopup-Label', className)}
      overlayClassName={classNames(
        'SaveTemplatePopup no-arrow',
        { TemplateModified: !!activeItem },
        overlayClassName
      )}
      label={label}
      labelId={labelId}
      placement="bottomRight"
      trigger="click"
      noIcon
      disabled={disabled}
      {...rest}
    >
      <SaveTemplatePopupContent
        activeItem={activeItem}
        afterSave={afterSave}
        saveAction={saveAction}
        getGraphQLData={getGraphQLData}
        titleId={titleId}
        successMessageId={successMessageId}
        onError={onError}
        nameLabelId={nameLabelId}
      />
    </Popup>
  );
}
