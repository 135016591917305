import { Upload } from 'antd';
import classNames from 'classnames';
import { findIndex, isFunction, stubObject, toPairs } from 'lodash-es';
import { useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { MAX_UPLOAD_FILE_SIZE } from '../config/constants';
import { useRichFetch } from '../config/http';
import { makeError } from '../utils/errorUtils';

export default function UploadDropArea({
  className,
  placeholderId = 'uploadDropArea.placeholder',
  ...rest
}) {
  return (
    <div className="FileUpload__Container">
      <Upload.Dragger
        className={classNames('FileUpload', className)}
        data-role="file-drop-area"
        {...rest}
      >
        <FormattedMessage id={placeholderId} />
      </Upload.Dragger>
    </div>
  );
}

export const UploadStatus = {
  ERROR: 'error',
  SUCCESS: 'success',
  DONE: 'done',
  UPLOADING: 'uploading',
  REMOVED: 'removed',
};

function validateFile(file) {
  if (file.size > MAX_UPLOAD_FILE_SIZE) {
    const error = makeError({ code: 'error.fileSize' });
    return { ...file, error, status: UploadStatus.ERROR, disabled: true };
  }
  return file;
}

export function useFileUpload({
  uploadUrl,
  getData = stubObject,
  afterUpload,
}) {
  const abortController = useRef(new AbortController());
  const cancelled = useRef(false);
  const cancel = useCallback(() => {
    cancelled.current = true;
    abortController.current.abort();
  }, []);

  const [fileList, setFileList] = useState([]);
  const setFile = useCallback(
    (file, info) =>
      setFileList(old => {
        const index = findIndex(old, ({ uid }) => uid === file.uid);
        if (index === -1) {
          return old;
        }
        const copy = [...old];
        copy[index] = { ...old[index], ...info };
        return copy;
      }),
    []
  );
  const onChange = useCallback(
    info => setFileList(info.fileList.map(validateFile)),
    []
  );

  const reset = useCallback(() => {
    abortController.current = new AbortController();
    cancelled.current = false;
    setFileList([]);
  }, []);

  const richFetch = useRichFetch();

  const upload = useCallback(
    async file => {
      if (cancelled.current || file.disabled) {
        return false;
      }

      setFile(file, { status: UploadStatus.UPLOADING, error: null });

      const body = new FormData();
      body.append('file', file.originFileObj);
      toPairs(getData(file)).forEach(([key, value]) => body.append(key, value));

      try {
        const res = await richFetch(uploadUrl, {
          method: 'POST',
          body,
          signal: abortController.current.signal,
        });
        const json = await res.json();
        if (json.error) {
          setFile(file, {
            status: UploadStatus.ERROR,
            error: new Error(json.error),
          });
          return false;
        }
        let customResponse = {};
        if (isFunction(afterUpload)) {
          try {
            if (cancelled.current) {
              return false;
            }
            customResponse = await afterUpload(json);
          } catch (e) {
            setFile(file, {
              status: UploadStatus.ERROR,
              error: e,
            });
            return false;
          }
        }

        setFile(file, {
          status: UploadStatus.SUCCESS,
          response: { ...json, ...customResponse },
        });
        return true;
      } catch (e) {
        setFile(file, {
          status: UploadStatus.ERROR,
          error: e,
        });
        return false;
      }
    },
    [afterUpload, getData, richFetch, setFile, uploadUrl]
  );

  return { fileList, onChange, upload, cancel, reset };
}
