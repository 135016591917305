import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Row, Space, Tag } from 'antd';
import classNames from 'classnames';
import { compact, isEmpty, last, set } from 'lodash-es';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  notificationSettingsFormToGraphql,
  notificationSettingsGraphqlToForm,
} from '../app/data/settingsConversions';
import { USER_QUERY } from '../app/graphql/authQueries';
import {
  UPDATE_USER_NOTIFICATION_SETTINGS_MUTATION,
  USER_NOTIFICATION_SETTINGS_QUERY,
} from '../app/graphql/settingsQueries';
import { extractGraphqlEntity } from '../common/utils/graphqlUtils';
import {
  useCachedProp,
  useMountAndUpdateEffect,
} from '../common/utils/hookUtils';
import { FAIcon } from '../components/adapters/fontAwesomeAdapters';
import { ThreeDotsSpinner } from '../components/data/Spinner';
import { renderDataStateIndicator } from '../components/data/dataStateHandlers';
import { SimpleConfirmDialog } from '../components/dialogs/SimpleConfirmDialog';
import FormList from '../components/forms/FormList';
import { FormItemInputText } from '../components/forms/basicFormElements';
import { FormItemInlineCheckbox } from '../components/forms/checkable';
import { useTranslatedOptions } from '../components/forms/formDecorators';
import {
  EnsureInitialFormListItem,
  mergeFormNames,
} from '../components/forms/formHelpers';
import {
  NoLabelForm,
  SubmitButton,
  useFormContext,
} from '../components/forms/forms';
import { FormItemSelect } from '../components/forms/selects';
import AutoHeightScrollbars from '../components/layout/AutoHeightScrollbars';
import { VerticalTrackTypes } from '../components/layout/Scrollbars';
import { TwoLines } from '../components/layout/layoutElements';
import { cssVariables } from '../styles/cssVariables';
import { pxToNumber } from '../utils/cssUtils';

const NOTIFY_WHEN_OPTIONS = [
  'orderPlaced',
  'csConfirm',
  'pickedUp',
  'flightChange',
  'flightTenderTime',
  'recovered',
  'clearedCustoms',
  'outForDelivery',
  'revisedEta',
  'proofOfDelivery',
  'jobCancelled',
].map(value => ({
  value,
  labelId: `settings.emailNotificationSettings.${value}`,
}));

function NotificationGroupItemCommon({
  name,
  index,
  renderBoolean,
  renderStringList,
  rowJustify = 'space-between',
}) {
  const { values } = useFormContext();
  const value = values.values[index];

  if (!value?.name) {
    return null;
  }

  const booleans = compact([
    renderBoolean({
      name: mergeFormNames(name, 'myself'),
      labelId: 'settings.emailNotificationSettings.myself',
      value: value.myself,
    }),
    ...(value.name !== 'csConfirm'
      ? [
          renderBoolean({
            name: mergeFormNames(name, 'shipper'),
            labelId: 'settings.emailNotificationSettings.shipper',
            value: value.shipper,
          }),
          renderBoolean({
            name: mergeFormNames(name, 'consignee'),
            labelId: 'settings.emailNotificationSettings.consignee',
            value: value.consignee,
          }),
          renderBoolean({
            name: mergeFormNames(name, 'other'),
            labelId: 'settings.emailNotificationSettings.other',
            value: value.other,
          }),
        ]
      : []),
  ]);

  return (
    <div className="spaces-vert-norm">
      <Row
        align="top"
        justify={rowJustify}
        gutter={[
          pxToNumber(cssVariables.spaceNorm),
          pxToNumber(cssVariables.spaceNorm),
        ]}
      >
        {booleans.map((el, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Col key={i}>{el}</Col>
        ))}
      </Row>
      {value.other &&
        renderStringList({ name: 'otherEmails', value: value.otherEmails })}
    </div>
  );
}

function NotificationGroupItem({ name, remove, index }) {
  const { values } = useFormContext();
  const value = values.values[index];

  return (
    <div className="EmailNotifications__Card">
      <div className="EmailNotifications__Card__Header EmailNotifications__Card__Header--Light">
        <FAIcon
          icon="times-circle"
          className="Clickable icon-18"
          onClick={remove}
        />
        <span>
          <FormattedMessage
            id={`settings.emailNotificationSettings.${value.name}`}
          />
        </span>
      </div>
      <div className="EmailNotifications__Card__Body spaces-vert-norm">
        <NotificationGroupItemCommon
          name={name}
          index={index}
          renderBoolean={({ value: val, labelId }) =>
            val && (
              <Tag className="EmailNotifications__BooleanPreview">
                <FAIcon icon="circle" className="icon-8" />
                <span>
                  <FormattedMessage id={labelId} />
                </span>
              </Tag>
            )
          }
          rowJustify="start"
          renderStringList={({ value: val }) => (
            <div>
              <div className="EmailNotifications__OtherEmailsLabel">
                <FormattedMessage id="settings.emailNotificationSettings.otherEmails" />
              </div>
              {val.map(email => (
                <div
                  className="EmailNotifications__OtherEmailsValue"
                  key={email}
                >
                  {email}
                </div>
              ))}
            </div>
          )}
        />
      </div>
    </div>
  );
}

function NotificationGroupItemAdding({ name, onDone, remove, index }) {
  const options = useTranslatedOptions(NOTIFY_WHEN_OPTIONS);
  const { values, formInstance, forceUpdate } = useFormContext();
  const [dialogOpen, setDialogOpen] = useState(false);

  const alreadyUsedValues = useCachedProp(
    compact(values.values.slice(0, -1).map(val => val?.name))
  );
  const selectedType = values.values[index]?.name;
  useMountAndUpdateEffect(
    {
      onUpdate: () => {
        if (alreadyUsedValues.indexOf(selectedType) > -1) {
          const vals = formInstance.getFieldsValue();
          set(vals, `values.${index}.name`, '');
          formInstance.setFieldsValue(vals);
          forceUpdate();
          setDialogOpen(true);
        }
      },
    },
    [selectedType]
  );

  return (
    <div className="EmailNotifications__Card">
      <div className="EmailNotifications__Card__Header EmailNotifications__Card__Header--Dark">
        <span>
          <FormattedMessage id="settings.emailNotificationSettings.add" />
        </span>
        <Space direction="horizontal" size={pxToNumber(cssVariables.spaceNorm)}>
          <FAIcon
            icon="times-circle"
            className="Clickable icon-18"
            onClick={() => {
              remove();
              onDone();
            }}
          />
        </Space>
      </div>
      <div className="EmailNotifications__Card__Body spaces-vert-norm2">
        <div className="spaces-vert-norm">
          <div>
            <FormItemSelect
              name={mergeFormNames(name, 'name')}
              options={options}
              labelId="settings.emailNotificationSettings.notifyWhen"
              rules={[{ required: true }]}
            />
          </div>
          <NotificationGroupItemCommon
            name={name}
            index={index}
            renderBoolean={({ name: fieldName, labelId }) => (
              <FormItemInlineCheckbox name={fieldName} labelId={labelId} />
            )}
            renderStringList={() => (
              <FormList name={mergeFormNames(name, 'otherEmails')}>
                {(fields, { add: addInner, remove: removeInner }) => (
                  <>
                    <EnsureInitialFormListItem
                      fields={fields}
                      add={addInner}
                      value=""
                      initialCount={1}
                    />
                    <div className="spaces-vert-norm">
                      {fields.map((field, i) => (
                        <Row
                          gutter={pxToNumber(cssVariables.spaceMd)}
                          align="top"
                          key={field.key}
                        >
                          <Col className="Flex1">
                            <FormItemInputText
                              name={field.name}
                              labelId="settings.emailNotificationSettings.otherEmails"
                              rules={[{ type: 'email' }]}
                            />
                          </Col>
                          <Col>
                            <div className="EmailNotifications__OtherEmailsControls">
                              <FAIcon
                                icon="plus-square"
                                className="Clickable icon-16"
                                onClick={() => addInner('')}
                              />
                              <FAIcon
                                icon="trash"
                                className="Clickable icon-16"
                                onClick={() => removeInner(i)}
                              />
                            </div>
                          </Col>
                        </Row>
                      ))}
                    </div>
                  </>
                )}
              </FormList>
            )}
          />
        </div>
        <Row align="top" gutter={pxToNumber(cssVariables.spaceNorm2)}>
          <Col>
            <Button
              size="large"
              onClick={() => {
                remove();
                onDone();
              }}
              className="width-60"
            >
              <FormattedMessage id="buttons.cancel" />
            </Button>
          </Col>
          <Col className="Flex1">
            <SubmitButton size="large" type="primary" className="width-100p">
              <span>
                <FormattedMessage id="settings.emailNotificationSettings.save" />
              </span>
              <FAIcon icon="save" className="icon-16" />
            </SubmitButton>
          </Col>
        </Row>
      </div>
      <SimpleConfirmDialog
        visible={dialogOpen}
        onClose={() => setDialogOpen(false)}
        textId="settings.emailNotificationSettings.duplicateWarning"
        cancelTextId="buttons.close"
      />
    </div>
  );
}

function splitFields({ fields, isAdding }) {
  const indexedFields = fields.map((f, i) => ({ ...f, index: i }));
  if (isEmpty(fields)) {
    return { normal: [], adding: null };
  }
  if (!isAdding) {
    return { normal: indexedFields, adding: null };
  }
  return {
    normal: indexedFields.slice(0, -1),
    adding: last(indexedFields),
  };
}

function NotificationGroupList({
  fields,
  add,
  remove,
  isAdding,
  setAdding,
  disabled,
}) {
  const { normal, adding } = splitFields({ fields, isAdding });
  const { triggerSubmit } = useFormContext();

  return (
    <div className="spaces-vert-norm">
      {!disabled && (
        <>
          {!adding ? (
            <div
              className="Clickable EmailNotifications__AddNotificationButton"
              onClick={() => {
                setAdding(true);
                add({});
              }}
            >
              <span>
                <FormattedMessage id="settings.emailNotificationSettings.add" />
              </span>
              <FAIcon icon="plus-square" className="icon-18" />
            </div>
          ) : (
            <NotificationGroupItemAdding
              key={adding.key}
              name={adding.name}
              remove={() => remove(adding.index)}
              onDone={() => setAdding(false)}
              index={adding.index}
            />
          )}
          <div>
            <TwoLines />
            <TwoLines />
          </div>
        </>
      )}
      <div className="EmailNotifications__ActiveNotifications__Title">
        <FormattedMessage id="settings.emailNotificationSettings.activeNotifications" />
      </div>
      {isEmpty(normal) ? (
        <div className="EmailNotifications__ActiveNotifications__None">
          <FormattedMessage id="settings.emailNotificationSettings.none" />
        </div>
      ) : (
        <div className="spaces-vert-norm2">
          {normal.map(({ name, key, index }) => (
            <NotificationGroupItem
              key={key}
              name={name}
              remove={() => {
                remove(index);
                triggerSubmit();
              }}
              index={index}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function renderNotificationGroupList(
  fields,
  { add, remove, move },
  { isAdding, setAdding, disabled }
) {
  return (
    <NotificationGroupList
      fields={fields}
      add={add}
      remove={remove}
      move={move}
      isAdding={isAdding}
      setAdding={setAdding}
      disabled={disabled}
    />
  );
}

const EmailNotificationsFormWrapper = ({
  useWrapper,
  className,
  scrollbarsClassName,
  children,
}) =>
  useWrapper ? (
    <div className={classNames('EmailNotifications', className)}>
      <div className="spaces-vert-norm">
        <TwoLines />
        <AutoHeightScrollbars
          spaceOutside
          className={scrollbarsClassName}
          vertical={VerticalTrackTypes.OUTSIDE}
        >
          {children}
        </AutoHeightScrollbars>
      </div>
    </div>
  ) : (
    children
  );

const EmailNotificationsForm = forwardRef(function EmailNotificationsForm(
  {
    update,
    initialValues,
    disabled,
    className,
    useWrapper,
    scrollbarsClassName,
  },
  ref
) {
  const [isAdding, setAdding] = useState(false);

  useImperativeHandle(ref, () => ({ isAdding }), [isAdding]);

  return (
    <NoLabelForm
      className="no-margin-form-items"
      onSubmit={async values => {
        await update(values);
        setAdding(false);
      }}
      initialValues={initialValues}
    >
      <EmailNotificationsFormWrapper
        useWrapper={useWrapper}
        className={className}
        scrollbarsClassName={scrollbarsClassName}
      >
        <div className="spaces-vert-norm padding-bottom-norm">
          <FormList name="values">
            {(fields, ctrl) =>
              renderNotificationGroupList(fields, ctrl, {
                isAdding,
                setAdding,
                disabled,
              })
            }
          </FormList>
        </div>
      </EmailNotificationsFormWrapper>
    </NoLabelForm>
  );
});

function UserEmailNotificationsForm({
  className,
  useWrapper = true,
  scrollbarsClassName,
}) {
  const { data, loading, error } = useQuery(USER_NOTIFICATION_SETTINGS_QUERY);
  const [key, setKey] = useState(0);
  useEffect(() => {
    setKey(old => old + 1);
  }, [data]);

  const [update, { loading: saving }] = useMutation(
    UPDATE_USER_NOTIFICATION_SETTINGS_MUTATION,
    {
      awaitRefetchQueries: true,
      refetchQueries: [USER_NOTIFICATION_SETTINGS_QUERY],
    }
  );

  const indicator = renderDataStateIndicator({ data, loading, error });
  if (indicator) {
    return indicator;
  }

  return (
    <>
      <EmailNotificationsForm
        key={key}
        initialValues={notificationSettingsGraphqlToForm(
          extractGraphqlEntity(data)
        )}
        update={input =>
          update({
            variables: { input: notificationSettingsFormToGraphql(input) },
          })
        }
        className={className}
        useWrapper={useWrapper}
        scrollbarsClassName={scrollbarsClassName}
      />
      {saving && (
        <div className="UserQuickSetup__SavingMask">
          <span>
            <FormattedMessage id="settings.emailNotificationSettings.saving" />
            <ThreeDotsSpinner />
          </span>
        </div>
      )}
    </>
  );
}

function AccountEmailNotificationsForm() {
  return <EmailNotificationsForm />;
}

export const JobEmailNotificationsForm = forwardRef(
  function JobEmailNotificationsForm(
    {
      override,
      values,
      onSave,
      className,
      useWrapper = true,
      scrollbarsClassName,
    },
    ref
  ) {
    const {
      data: userData,
      loading: userLoading,
      error: userError,
    } = useQuery(USER_QUERY);
    const {
      data: userNotifsData,
      loading,
      error,
    } = useQuery(USER_NOTIFICATION_SETTINGS_QUERY, {
      variables: userData && { id: extractGraphqlEntity(userData).id },
      skip: !userData,
    });
    const [key, setKey] = useState(0);
    useEffect(() => {
      setKey(old => old + 1);
    }, [override, userNotifsData]);

    const indicator = renderDataStateIndicator({
      data: userNotifsData,
      loading: loading || userLoading,
      error: error || userError,
    });
    if (indicator) {
      return indicator;
    }

    const initialUserValues = notificationSettingsGraphqlToForm(
      extractGraphqlEntity(userNotifsData)
    );

    return (
      <EmailNotificationsForm
        key={key}
        ref={ref}
        initialValues={
          override ? values || initialUserValues : initialUserValues
        }
        update={input => onSave(input)}
        disabled={!override}
        className={className}
        useWrapper={useWrapper}
        scrollbarsClassName={scrollbarsClassName}
      />
    );
  }
);

export const EMAIL_NOTIFICATION_MODES = [
  { labelId: 'settings.emailNotificationSettings.user', value: 'user' },
  {
    labelId: 'settings.emailNotificationSettings.account',
    value: 'account',
    disabled: true,
    tooltipId: 'labels.comingSoon',
    tooltipProps: { placement: 'top' },
  },
];

export const EMAIL_NOTIFICATION_TABS = [
  { name: 'user', component: UserEmailNotificationsForm },
  { name: 'account', component: AccountEmailNotificationsForm },
];
