import { get, merge } from 'lodash-es';

import { NewShipmentDateSpecialValue } from '../../../app/data/newShipmentConversions';

export const SAVED_SHIPMENT_SERVICE_TYPE = 'AB';

export function shouldForceTbdServiceType(values) {
  return (
    get(values, 'origin.pickupDateTime-specialValue') ===
      NewShipmentDateSpecialValue.TBD ||
    get(values, 'destination.deliveryDateTime-specialValue') ===
      NewShipmentDateSpecialValue.TBD ||
    values?.packageList?.tbd
  );
}

export function isSavedShipmentModeActive(values) {
  return (
    shouldForceTbdServiceType(values) ||
    values?.serviceInformation?.serviceType?.code ===
      SAVED_SHIPMENT_SERVICE_TYPE
  );
}

const FORCE_TBD_OVERRIDE = {
  pickupDateTime: null,
  deliveryDateTime: null,
  origin: {
    pickupDateTime: null,
    'pickupDateTime-specialValue': null,
  },
  destination: {
    deliveryDateTime: null,
    'deliveryDateTime-specialValue': null,
  },
  serviceInformation: {
    serviceType: { code: SAVED_SHIPMENT_SERVICE_TYPE },
  },
};
export function forceSavedShipmentForm(shipmentForm) {
  return merge({}, shipmentForm, FORCE_TBD_OVERRIDE);
}
