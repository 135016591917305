import { useMutation } from '@apollo/client';
import { Steps } from 'antd';
import classNames from 'classnames';
import { Set } from 'immutable';
import { isEmpty, isString } from 'lodash-es';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';
import {
  Route,
  Switch,
  generatePath,
  matchPath,
  useHistory,
  useLocation,
} from 'react-router';

import { userFormToGraphql } from '../../../app/data/userConversions';
import { CREATE_USER_MUTATION } from '../../../app/graphql/adminQueries';
import routes from '../../../app/routes';
import { extractGraphqlEntity } from '../../../common/utils/graphqlUtils';
import {
  FAIcon,
  FATypes,
} from '../../../components/adapters/fontAwesomeAdapters';
import { useFlashMessageContext } from '../../../components/dialogs/FlashMessageProvider';
import AdminConsoleTitle from '../components/AdminConsoleTitle';
import { formatUserLabel } from '../utils/adminFormatting';
import AdminAddUserAccounts from './add/AdminAddUserAccounts';
import AdminAddUserConfirmation from './add/AdminAddUserConfirmation';
import AdminAddUserDocumentGroup from './add/AdminAddUserDocumentGroup';
import AdminAddUserExpandedTracking from './add/AdminAddUserExpandedTracking';
import AdminAddUserProfile from './add/AdminAddUserProfile';

const STEPS = [
  { name: 'userProfile', routeKey: 'root' },
  'linkAccounts',
  'confirmProfile',
  'documentGroup',
  'expandedTracking',
];

const stepToRoute = (step, baseRoute) => {
  const routeKey = isString(step) ? step : step.routeKey;
  return `${baseRoute}${routes.admin.addUserSteps[routeKey]}`;
};

function useNavigation(baseRoute) {
  const { pathname } = useLocation();
  const { push } = useHistory();

  const currentIndex = STEPS.findIndex(
    step =>
      !!matchPath(pathname, { path: stepToRoute(step, baseRoute), exact: true })
  );
  const goToStep = index => {
    const step = STEPS[index];
    if (step) {
      push(stepToRoute(step, baseRoute));
    }
  };
  const goToDiff = diff => {
    goToStep(currentIndex + diff);
  };

  return {
    currentIndex,
    goToStep,
    goToNext: () => goToDiff(1),
    goToPrev: () => goToDiff(-1),
  };
}

export function AdminAddUserSteps({
  baseRoute = routes.admin.addUser,
  level = 1,
}) {
  const { currentIndex } = useNavigation(baseRoute);
  const { push } = useHistory();
  const [visitedIndices, setVisitedIndices] = useState(Set([currentIndex]));
  useEffect(() => {
    setVisitedIndices(old => old.add(currentIndex));
  }, [currentIndex]);

  return (
    <Steps
      direction="vertical"
      className={classNames(
        'AdminAddUser__Steps',
        `AdminAddUser__Steps--Level${level}`
      )}
      current={currentIndex}
      progressDot={(iconDot, { status }) => {
        if (status === 'wait') {
          return (
            <FAIcon
              icon="dot-circle"
              type={FATypes.REGULAR}
              className="icon-18"
            />
          );
        }
        if (status === 'finish') {
          return <FAIcon icon="check-circle" className="icon-18" />;
        }
        return (
          <FAIcon icon="dot-circle" type={FATypes.SOLID} className="icon-18" />
        );
      }}
    >
      {STEPS.map((info, i) => {
        const name = isString(info) ? info : info.name;
        const tba = !!info?.tba;
        const clickable = visitedIndices.has(i);
        return (
          <Steps.Step
            key={name}
            className={classNames(clickable && 'Clickable')}
            onClick={
              clickable ? () => push(stepToRoute(info, baseRoute)) : undefined
            }
            disabled={tba}
            title={
              <span
                className={
                  tba
                    ? 'AdminConsoleSection__Tba AdminConsoleSection__Tba--Right'
                    : ''
                }
              >
                <FormattedMessage id={`admin.userManagement.addUser.${name}`} />
              </span>
            }
          />
        );
      })}
    </Steps>
  );
}

const normalizeValues = values => ({
  ...values,
  name:
    !isEmpty(values.firstName) && !isEmpty(values.lastName)
      ? formatUserLabel(values)
      : undefined,
});

const DEFAULT_GET_REDIRECT_URL = userResp =>
  generatePath(routes.admin.userDetail, { userId: userResp.id });

export default function AdminAddUser({
  headerRightRef,
  baseRoute = routes.admin.addUser,
  initialValues = {},
  getRedirectUrl = DEFAULT_GET_REDIRECT_URL,
}) {
  const { goToNext, goToPrev } = useNavigation(baseRoute);
  const { push } = useHistory();
  const onCancel = () => push(routes.admin.userManagement);

  const [values, setValues] = useState(initialValues);
  const onStepSave = vals => {
    setValues(old => normalizeValues({ ...old, ...vals }));
    goToNext();
  };

  const { infoMessage } = useFlashMessageContext();

  const [save] = useMutation(CREATE_USER_MUTATION);

  const onSave = async vals => {
    setValues(old => normalizeValues({ ...old, ...vals }));
    const user = userFormToGraphql({ ...values, ...vals });
    const { data } = await save({ variables: { input: user } });
    const userResp = extractGraphqlEntity(data);
    infoMessage({ contentId: 'admin.userManagement.addUser.save.success' });
    push(getRedirectUrl(userResp));
  };

  return (
    <>
      {headerRightRef.current &&
        createPortal(
          <AdminConsoleTitle
            titleId="admin.userManagement.addUser.title"
            icon="user-plus"
            type="sub"
          />,
          headerRightRef.current
        )}
      <Switch>
        <Route path={`${baseRoute}${routes.admin.addUserSteps.root}`} exact>
          <AdminAddUserProfile
            onSave={onStepSave}
            onCancel={onCancel}
            values={values}
          />
        </Route>
        <Route path={`${baseRoute}${routes.admin.addUserSteps.linkAccounts}`}>
          <AdminAddUserAccounts
            onSave={onStepSave}
            onBack={goToPrev}
            onCancel={onCancel}
            values={values}
          />
        </Route>
        <Route path={`${baseRoute}${routes.admin.addUserSteps.confirmProfile}`}>
          <AdminAddUserConfirmation
            onSave={onStepSave}
            onBack={goToPrev}
            onCancel={onCancel}
            values={values}
          />
        </Route>
        <Route path={`${baseRoute}${routes.admin.addUserSteps.documentGroup}`}>
          <AdminAddUserDocumentGroup
            onSave={onStepSave}
            onBack={goToPrev}
            onCancel={onCancel}
            values={values}
          />
        </Route>
        <Route
          path={`${baseRoute}${routes.admin.addUserSteps.expandedTracking}`}
        >
          <AdminAddUserExpandedTracking
            onSave={onSave}
            onBack={goToPrev}
            onCancel={onCancel}
            values={values}
          />
        </Route>
      </Switch>
    </>
  );
}
