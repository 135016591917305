import classNames from 'classnames';

export const FATypes = {
  REGULAR: 'REGULAR',
  LIGHT: 'LIGHT',
  SOLID: 'SOLID',
};
const FA_MAPPINGS = {
  REGULAR: 'far',
  LIGHT: 'fal',
  SOLID: 'fas',
};

export function FAIcon({
  icon,
  type = FATypes.SOLID,
  className,
  disabled,
  ...rest
}) {
  return (
    <span
      className={classNames(
        `fa-${icon}`,
        FA_MAPPINGS[type],
        { disabled },
        className
      )}
      {...rest}
    />
  );
}

export const FAIconChars = {
  PLANE_DEPARTURE: '\uf5b0',
  PLANE_ARRIVAL: '\uf5af',
  WIFI: '\uf1eb',
  TRUCK: '\uf0d1',
  MAP_MARKER: '\uf3c5',
  FLAG_CHECKERED: '\uf11e',
  CHECK: '\uf00c',
  EXCLAMATION: '\uf12a',
};
