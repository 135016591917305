import { ApolloClient, ApolloLink, InMemoryCache, gql } from '@apollo/client';
import React, { useState } from 'react';

import { config } from '../../config/config';
import { authLink, httpLink } from '../../config/graphql';
import { log } from '../../utils/logger';
import { InlineLink } from '../typography';
import { debugEnvInfo, timestamp } from './debugReportsCommon';

const logs = [];

function interceptConsole() {
  ['log', 'error', 'warn'].forEach(method => {
    const origMethod = console[method];
    console[method] = (...args) => {
      logs.push([timestamp(), `[[${method}]]`, ...args]);
      origMethod(...args);
    };
  });
}

function stringifyLog(obj) {
  try {
    return JSON.stringify(obj, null, 2);
  } catch {
    return '[[Failed to stringify log entry]]';
  }
}

function makeConsoleOutput() {
  return logs
    .map(args => args.map(arg => stringifyLog(arg)).join(' :: '))
    .join('\n');
}

async function makeScreenshot() {
  const html2canvas = await require('html2canvas');
  const canvas = await html2canvas(document.body);
  const blob = await new Promise(resolve => canvas.toBlob(resolve));
  return blob;
}

const METADATA_DEBUG_QUERY = gql`
  query MetadataDebugQuery {
    metadata {
      isMock
      version
      api20Version
    }
  }
`;

async function getServerDebugMetadata() {
  try {
    const apolloClient = new ApolloClient({
      link: ApolloLink.from([authLink, httpLink]),
      cache: new InMemoryCache(),
    });

    const { data } = await apolloClient.query({
      query: METADATA_DEBUG_QUERY,
      fetchPolicy: 'network-only',
    });
    return data?.metadata;
  } catch (e) {
    console.warn(e);
    return {
      error: 'unable to get server metadata',
    };
  }
}

async function makeReportInfo() {
  const debugInfo = debugEnvInfo();
  const serverDebugMetadata = await getServerDebugMetadata();
  const serverDebugInfo = JSON.stringify(serverDebugMetadata);
  return `${debugInfo}
  ServerInfo: ${serverDebugInfo}
  `;
}

async function downloadReport() {
  log.info('Collecting debug report');
  try {
    const FileSaver = await require('file-saver');
    const JSZip = await require('jszip');
    const zip = new JSZip();
    zip.file('browser-console.log', makeConsoleOutput());
    zip.file('info.txt', makeReportInfo());
    const screenshot = await makeScreenshot();
    zip.file('screenshot.png', screenshot);
    const content = await zip.generateAsync({ type: 'blob' });
    FileSaver.saveAs(content, `client-report-${timestamp()}.zip`);
    log.info('Debug report collected successfully');
  } catch (e) {
    // eslint-disable-next-line
    window.alert('Error collecting report');
    log.error('Error collecting debug report', e);
  }
}

function setupReportShortcut() {
  document.addEventListener('keyup', e => {
    if (e.ctrlKey && e.altKey && e.key === 'e') {
      downloadReport();
    }
  });
}

if (config.showDebugInfo) {
  // enables collecting console logs
  interceptConsole();

  // enables shortcut to collect report
  setupReportShortcut();
}

export default function CollectDebugReportButton() {
  const [loading, setLoading] = useState(false);
  if (!config.showDebugInfo) {
    return null;
  }
  return (
    <InlineLink
      onClick={async () => {
        setLoading(true);
        await downloadReport();
        setLoading(false);
      }}
      title="ctrl + alt + e"
      loading={loading}
    >
      COLLECT REPORT
    </InlineLink>
  );
}
