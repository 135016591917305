import { Input } from 'antd';
import classNames from 'classnames';
import { useCallback } from 'react';

import { getOrCall } from '../../common/utils/funcUtils';
import { FAIcon } from '../adapters/fontAwesomeAdapters';
import IconButton from '../buttons/IconButton';
import { FormItem } from './FormItem';

function InputSearch({ onSearch, value, ...rest }) {
  const search = useCallback(() => {
    onSearch && onSearch(value);
  }, [onSearch, value]);
  return (
    <Input.Search
      enterButton={<FAIcon icon="chevron-right" onClick={search} />}
      onSearch={search}
      value={value}
      {...rest}
    />
  );
}
export function FormItemSearch({ onSearch, formItemComponentProps, ...rest }) {
  return (
    <FormItem
      {...rest}
      formItemComponentProps={{
        ...formItemComponentProps,
        onSearch,
      }}
      component={InputSearch}
      floatingLabel={false}
    />
  );
}

function InputSearchFlat({
  value,
  className,
  onSearch,
  onCancel,
  prefixIconSize = 'md',
  ...rest
}) {
  const search = useCallback(() => {
    onSearch && onSearch(value);
  }, [onSearch, value]);
  return (
    <Input
      value={value}
      className={classNames('InputSearchFlat', className)}
      prefix={
        <IconButton icon="search" size={prefixIconSize} onClick={search} />
      }
      suffix={<IconButton icon="times" onClick={() => getOrCall(onCancel)} />}
      onPressEnter={search}
      {...rest}
    />
  );
}
export function FormItemSearchFlat({
  onSearch,
  onCancel,
  className,
  formItemComponentProps,
  ...rest
}) {
  return (
    <FormItem
      {...rest}
      formItemComponentProps={{
        ...formItemComponentProps,
        onSearch,
        onCancel,
        className,
      }}
      component={InputSearchFlat}
      floatingLabel={false}
    />
  );
}
