export function cssTransitionClassNames(
  classNameBase,
  { appear = true, enter = true, exit = true } = {}
) {
  return {
    ...(appear
      ? {
          appear: `${classNameBase}-appear`,
          appearActive: `${classNameBase}-appear-active`,
          appearDone: `${classNameBase}-appear-done`,
        }
      : {}),
    ...(enter
      ? {
          enter: `${classNameBase}-enter`,
          enterActive: `${classNameBase}-enter-active`,
          enterDone: `${classNameBase}-enter-done`,
        }
      : {}),
    ...(exit
      ? {
          exit: `${classNameBase}-exit`,
          exitActive: `${classNameBase}-exit-active`,
          exitDone: `${classNameBase}-exit-done`,
        }
      : {}),
  };
}
