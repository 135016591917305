import { useMutation } from '@apollo/client';
import { identity, isArray, noop } from 'lodash-es';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { useStateIfMounted } from '../../utils/reactUtils';
import { CustomLoaderButton } from '../buttons/buttons';
import { InlineLink } from '../typography';

export function useAsynchronousAction(action, { onError } = {}) {
  const [loading, setLoading] = useStateIfMounted(false);
  const decoratedAction = useCallback(
    async (...args) => {
      setLoading(true);
      try {
        return await action(...args);
      } catch (e) {
        if (onError) {
          onError(e);
        }
        throw e;
      } finally {
        setLoading(false);
      }
    },
    [action, onError, setLoading]
  );

  return { execute: decoratedAction, loading };
}

/**
 * Element that executes an action and sets indications of the action's state
 * @prop {Component} component Component implementing { onClick, disabled, loading } props
 * @prop {{ execute: Function<() => Promise>, loading: boolean }} action Action definition
 */
function AsyncActionElement({
  component: C,
  action,
  onDone,
  textId,
  loadingText,
  loadingTextId,
  children,
  ...rest
}) {
  const { execute, loading } = action;
  const onClick = useCallback(async () => {
    try {
      const result = await execute();
      if (onDone) {
        onDone(result);
      }
    } catch (e) {
      // Error handling should be done inside execute, which should then rethrow to prevent `onDone` being called
    }
  }, [execute, onDone]);

  const nonLoadingChild = textId ? <FormattedMessage id={textId} /> : children;
  const loadingChild =
    (loadingTextId ? <FormattedMessage id={loadingTextId} /> : loadingText) ||
    nonLoadingChild;

  return (
    <C onClick={loading ? noop : onClick} loading={loading} {...rest}>
      {loading ? loadingChild : nonLoadingChild}
    </C>
  );
}

/**
 * @prop {Component} component Component implementing { onClick, disabled, loading } props
 * @prop {gql} mutation GraphQL mutation
 * @prop {MutationHookOptions} mutationOptions GraphQL options
 */
function GraphQLMutatingElement({
  mutation,
  mutationOptions,
  getMutationArgs = identity,
  onError,
  ...rest
}) {
  const [mutate, { loading }] = useMutation(mutation, mutationOptions);
  // We need failed call to throw error, so a custom error handling is used
  const execute = useCallback(
    async (...args) => {
      try {
        const margs = getMutationArgs(args);
        return await mutate(...(isArray(margs) ? margs : [margs]));
      } catch (e) {
        onError(e);
        throw e;
      }
    },
    [getMutationArgs, mutate, onError]
  );
  const action = { execute, loading };
  return <AsyncActionElement action={action} {...rest} />;
}

/**
 * @prop {Component} component Component implementing { onClick, disabled, loading } props
 * @prop {Function<() => Promise>} callback Simple asynchronous function, takes precedence to mutation
 */
function AsyncCallbackElement({ callback, onError, ...rest }) {
  const action = useAsynchronousAction(callback, { onError });
  return <AsyncActionElement action={action} {...rest} />;
}

// Create other variations as needed
export const GraphQLMutatingLink = props => (
  <GraphQLMutatingElement component={InlineLink} {...props} />
);
export const GraphQLMutatingButton = props => (
  <GraphQLMutatingElement component={CustomLoaderButton} {...props} />
);
export const AsyncCallbackButton = props => (
  <AsyncCallbackElement component={CustomLoaderButton} {...props} />
);
