import { Button } from 'antd';
import classNames from 'classnames';
import { get, keys, noop } from 'lodash-es';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useMountEffect } from '../../common/utils/hookUtils';
import { cssVariables } from '../../styles/cssVariables';
import { useMountedIndicator } from '../../utils/reactUtils';

const ANIMATION_DURATION = parseInt(cssVariables.animationDurationMd, 10);

export function DefaultButton({ labelId, label, active, onClick, ...props }) {
  return (
    <Button type={active ? 'primary' : 'default'} onClick={onClick}>
      {labelId ? <FormattedMessage id={labelId} /> : label}
    </Button>
  );
}

function getSurroundingNamesRaw(page, { data, activePage }, asKeys = true) {
  const index = getIndexRaw(page, { data, activePage });
  const ret = {};
  if (index !== undefined) {
    ret[data[Math.max(0, index - 1)].name] = true;
    ret[data[Math.min(index + 1, data.length - 1)].name] = true;
  }
  return asKeys ? ret : keys(ret);
}

function getIndexRaw(page, { data, activePage }) {
  const targetPage = page || activePage;
  const index = data.findIndex(info => info.name === targetPage);
  return index < 0 ? undefined : index;
}

function updateHeightRaw(
  page,
  { data, activePage, autoHeight, contentContainerRef, setActiveHeight }
) {
  const index = getIndexRaw(page, { data, activePage });

  if (
    autoHeight &&
    contentContainerRef &&
    contentContainerRef.current &&
    index !== undefined
  ) {
    const child = contentContainerRef.current.children[index];
    if (child) {
      setActiveHeight(child.scrollHeight);
    }
  }
}

function goToRaw(
  page,
  {
    data,
    activePage,
    setActivePage,
    setVisibleNames,
    autoHeight,
    contentContainerRef,
    setActiveHeight,
    replaceTimeout,
    onAnimationComplete,
    unmountInvisible,
  }
) {
  updateHeightRaw(page, {
    data,
    activePage,
    autoHeight,
    contentContainerRef,
    setActiveHeight,
  });

  setActivePage(page);
  setVisibleNames({
    // Newly displayed slide
    [page]: true,
    // Previously displayed slide
    [activePage]: true,
    ...(unmountInvisible === 1
      ? getSurroundingNamesRaw(page, { data, activePage })
      : null),
  });

  replaceTimeout(() => {
    onAnimationComplete && onAnimationComplete();
    setVisibleNames({
      [page]: true,
      ...(unmountInvisible === 1
        ? getSurroundingNamesRaw(page, { data, activePage })
        : null),
    });
  }, ANIMATION_DURATION);
}

function selectRaw(name, { stopAutoplay, onSelect, listenToSelected, goTo }) {
  // User select disabled autoplay
  stopAutoplay();
  if (onSelect && !listenToSelected) {
    onSelect(name);
  }
  goTo(name);
}

function useTimeout() {
  const mounted = useMountedIndicator();
  const timeout = useRef();
  const removeTimeout = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
      timeout.current = undefined;
    }
  }, [timeout]);
  const replaceTimeout = useCallback(
    (callback, time) => {
      removeTimeout();
      timeout.current = setTimeout(() => {
        if (mounted.current) {
          callback();
        }
      }, time);
      return removeTimeout;
    },
    [mounted, removeTimeout]
  );
  return { replaceTimeout, removeTimeout };
}

function useAutoplay({
  autoplayCount,
  autoplayTimeout,
  data,
  activePage,
  goTo,
}) {
  const [autoplayRoundsRemaining, setAutoplayRoundsRemaining] =
    useState(autoplayCount);

  const setNextAutoplay = useCallback(() => {
    const rem = autoplayRoundsRemaining;
    if (rem < 0) {
      return;
    }

    const index = getIndexRaw(undefined, { data, activePage });
    // The last page - decrease the remaining number
    if (index === data.length - 1) {
      let newRem = rem - 1;
      if (rem === 0) {
        newRem = 0;
      } else if (rem === 1) {
        newRem = -1;
      }
      setAutoplayRoundsRemaining(newRem);
      goTo(data[0].name);
    } else {
      goTo(data[index + 1].name);
    }
  }, [activePage, autoplayRoundsRemaining, data, goTo]);

  const { replaceTimeout, removeTimeout } = useTimeout();
  useEffect(() => {
    if (autoplayCount > -1) {
      replaceTimeout(setNextAutoplay, autoplayTimeout);
    }
    return removeTimeout;
  }, [
    autoplayCount,
    autoplayTimeout,
    removeTimeout,
    replaceTimeout,
    setNextAutoplay,
  ]);

  return {
    stopAutoplay: () => {
      removeTimeout();
      setAutoplayRoundsRemaining(-1);
    },
  };
}

function useControls({
  selected,
  listenToSelected,
  data,
  unmountInvisible,
  autoHeight,
  onAnimationComplete,
  autoplayCount,
  autoplayTimeout,
  onSelect,
}) {
  const [activePage, setActivePage] = useState(selected || get(data, '0.name'));
  const [activeHeight, setActiveHeight] = useState(undefined);

  const getSurroundingNames = useCallback(
    (page, asKeys) =>
      getSurroundingNamesRaw(page, { data, activePage }, asKeys),
    [activePage, data]
  );
  const [visibleNames, setVisibleNames] = useState(
    unmountInvisible ? getSurroundingNames(activePage) : null
  );

  const { replaceTimeout } = useTimeout();
  const contentContainerRef = useRef();

  const getIndex = useCallback(
    page => getIndexRaw(page, { data, activePage }),
    [activePage, data]
  );

  const goTo = useCallback(
    page =>
      goToRaw(page, {
        data,
        activePage,
        setActivePage,
        setVisibleNames,
        autoHeight,
        contentContainerRef,
        setActiveHeight,
        replaceTimeout,
        onAnimationComplete,
        unmountInvisible,
      }),
    [
      activePage,
      autoHeight,
      data,
      onAnimationComplete,
      replaceTimeout,
      unmountInvisible,
    ]
  );

  const { stopAutoplay } = useAutoplay({
    autoplayCount,
    autoplayTimeout,
    data,
    activePage,
    goTo,
  });

  const select = useCallback(
    name => selectRaw(name, { stopAutoplay, onSelect, listenToSelected, goTo }),
    [goTo, listenToSelected, onSelect, stopAutoplay]
  );

  const updateHeight = useCallback(
    page =>
      updateHeightRaw(page, {
        data,
        activePage,
        autoHeight,
        contentContainerRef,
        setActiveHeight,
      }),
    [activePage, autoHeight, data]
  );

  useMountEffect(() => updateHeight());
  useEffect(() => {
    if (!autoHeight) {
      return noop;
    }
    const interval = setInterval(() => updateHeight(), 100);
    return () => clearInterval(interval);
  }, [autoHeight, updateHeight]);
  useEffect(() => {
    if (listenToSelected && selected !== activePage) {
      goTo(selected);
    }
  }, [activePage, goTo, listenToSelected, selected]);

  return {
    activePage,
    activeHeight,
    visibleNames,
    contentContainerRef,
    select,
    getIndex,
  };
}

/**
 * Animates between multiple components
 * props
 *   * ButtonComponent - component to render button (undefined means no buttons)
 *   * StaticContentComponent - shared non-animated component rendered for all pages
 *   * ContentComponent - component to render page based on data
 *   * data - array of data in format: \
 *            { name, component, props: { ...props_passed_to_component }, buttonLabel(Id)? } \
 *            (component overrides ContentComponent)
 *   * sharedProps - props object shared between all instances
 *   * listenToSelected - if true, the component will listen to props changes and select new page if
 *                        selected changes
 *   * selected - name of the selected page
 *   * unmountInvisible - whether to unmount those pages that are not currently selected (if equal to 1
 *                        , those that are more than 1 slide away are unmounted)
 *   * vertical - whether to animate pages vertically instead of horizontally (height must be specified)
 *   * autoHeight - whether to dynamically set height based on currently selected page
 *   * className - class name
 *   * onSelect - listener for select event of type (name: string): void
 *   * onAnimationComplete - listener for animation being completed
 *   * autoplayTimeout - timeout after which next slide should be autoselected
 *   * autoplayCount - how many rounds should be executed
 */
export function SlidingContent(props) {
  const {
    ButtonComponent,
    StaticContentComponent,
    ContentComponent,
    data,
    unmountInvisible,
    vertical,
    autoHeight,
    className,
    listenToSelected,
    onAnimationComplete,
    onSelect,
    sharedProps,
    autoplayCount,
    autoplayTimeout,
    ...rest
  } = props;

  const {
    activePage,
    activeHeight,
    select,
    getIndex,
    contentContainerRef,
    visibleNames,
  } = useControls(props);

  const index = getIndex();
  const negIndex = index === undefined ? 0 : -index;
  const translate = `${negIndex * 100}%`;
  const translates = vertical ? [0, translate, 0] : [translate, 0, 0];

  return (
    <div
      className={classNames('SlidingContent', { vertical }, className)}
      {...rest}
    >
      {ButtonComponent && (
        <div className="SlidingContent-Buttons">
          {data.map(info => (
            <div className="SlidingContent-Button" key={info.name}>
              <ButtonComponent
                label={info.buttonLabel}
                labelId={info.buttonLabelId}
                active={info.name === activePage}
                onClick={() => select(info.name)}
              />
            </div>
          ))}
        </div>
      )}
      <div
        className={classNames('SlidingContent-Content', {
          'SlidingContent-Content-AutoHeight': autoHeight,
        })}
        {...(autoHeight && activeHeight && { style: { height: activeHeight } })}
      >
        {StaticContentComponent && (
          <div className="SlidingContent-ContentOverlay">
            <StaticContentComponent />
          </div>
        )}
        <div
          className={classNames('SlidingContent-ContentAnimationContainer', {
            vertical,
          })}
          style={{
            transform: `translate3d(${translates.join(',')})`,
          }}
          ref={contentContainerRef}
        >
          {data.map(({ name, component: C, props: stepProps }) => {
            const Component = C || ContentComponent;
            const active = name === activePage;
            return (
              <div
                className={classNames('SlidingContent-ContentTab', {
                  active,
                })}
                key={name}
              >
                {(!unmountInvisible || visibleNames[name]) && (
                  <Component
                    slideActive={active}
                    {...stepProps}
                    {...sharedProps}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
