import { useCallback } from 'react';

export default function useNewShipmentStateUpdaters({
  forms,
  refreshValues,
  setUnitSystem,
}) {
  const afterApplyTemplate = useCallback(
    tpl => {
      refreshValues();
      // Force update in the children forms
      forms.forEach(({ formRef }) => {
        if (formRef.current) {
          formRef.current.forceUpdate();
        }
      });
      setUnitSystem(tpl?.unitSystem);
    },
    [forms, refreshValues, setUnitSystem]
  );

  return { afterApplyTemplate };
}
