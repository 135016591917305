import { Button, Typography } from 'antd';
import { isEqualWith } from 'lodash-es';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { shipmentTemplateFormToInput } from '../../../app/data/shipmentTemplateConversions';
import { useDialogControls } from '../../../common/utils/dialogUtils';
import { FAIcon } from '../../../components/adapters/fontAwesomeAdapters';
import { DialogsParentContext } from '../../../components/dialogs/DialogsParentContext';
import { useFlashMessageContext } from '../../../components/dialogs/FlashMessageProvider';
import SimpleDialog from '../../../components/dialogs/SimpleDialog';
import { isFormValuePresent } from '../../../components/forms/formHelpers';
import { TwoLines } from '../../../components/layout/layoutElements';
import { getValidPackages } from '../shipmentCommon';
import { copyShipmentTemplateIntoForm } from './shipmentTemplateUtils';

export function useShipmentTemplateChangeDetectionInterceptors() {
  const [checkDiffCounter, setCheckDiffCounter] = useState(0);
  const onBlur = useCallback(
    () => setCheckDiffCounter(checkDiffCounter + 1),
    [checkDiffCounter]
  );
  const onKeyDown = useCallback(
    e => {
      if (e.key === 'Enter') {
        setCheckDiffCounter(checkDiffCounter + 1);
      }
    },
    [checkDiffCounter]
  );
  return { onBlur, onKeyDown, props: { checkDiffCounter } };
}

// Special logic is applied - entering an empty field is not considered as change
function isTemplateChanged(templateValues, currentValues) {
  return !isEqualWith(
    shipmentTemplateFormToInput(templateValues),
    shipmentTemplateFormToInput(currentValues),
    tplVal => {
      if (!isFormValuePresent(tplVal)) {
        return true;
      }
      // undefined signals that the default `isEqual` behaviour should be used
      return undefined;
    }
  );
}

export default function ShipmentTemplatesChangedDialog({
  activeItem,
  setActiveItem,
  values,
  forms,
  afterApply,
  checkDiffCounter,
}) {
  const { infoMessage } = useFlashMessageContext();

  const { setDialogOpen } = useContext(DialogsParentContext);
  const { isOpen, open, close } = useDialogControls({
    onOpenChange: setDialogOpen,
  });

  /**
   * THE PROCESS:
   * - If no template is active, any change is ignored
   * - If a template is active and any change is made (except entering field that was empty in the template),
   *   dialog is presented with options:
   *     - dismiss the template (set active item to null, continue with the same values)
   *     - cancel and revert the changes back
   */

  // Used for reverting
  const lastValues = useRef(values);
  // Used for comparision
  const templateValues = useRef(values);
  // Used to check if the change was caused by form change or template change
  const lastActiveItem = useRef(activeItem);
  // Used as a temporary variable to hold values before they are rewritten with the new version
  const revertValues = useRef(values);

  useEffect(() => {
    lastValues.current = values;
    templateValues.current = values;
    lastActiveItem.current = activeItem;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItem]);
  useEffect(() => {
    if (
      activeItem &&
      activeItem === lastActiveItem.current &&
      isTemplateChanged(lastValues.current, values)
    ) {
      revertValues.current = lastValues.current;
      open();
    }
    lastValues.current = values;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkDiffCounter]);

  const revert = () => {
    close();
    const { packages, ...packageListRest } = revertValues.current.packageList;
    const sanitizedRevertValues = {
      ...revertValues.current,
      packageList: {
        packages: getValidPackages(packages),
        ...packageListRest,
      },
    };
    copyShipmentTemplateIntoForm(forms, sanitizedRevertValues);
    lastValues.current = revertValues.current;
    afterApply();
    infoMessage({ contentId: 'shipmentTemplates.messages.reset' });
  };

  const dismiss = () => {
    close();
    setActiveItem(undefined);
    infoMessage({ contentId: 'shipmentTemplates.messages.dismiss' });
  };

  return (
    <SimpleDialog
      visible={!!activeItem && isOpen}
      className="ShipmentTemplateChangedDialog"
      icon={<FAIcon icon="exclamation-triangle" />}
      mask={false}
    >
      <div className="spaces-vert-norm">
        <Typography.Title level={2}>
          <FormattedMessage id="shipmentTemplates.modified.title" />
        </Typography.Title>
        <div className="TemplateOperationWarning-Name">{activeItem?.name}</div>
        <TwoLines className="size-6" />
        <Button size="large" onClick={dismiss}>
          <FormattedMessage id="shipmentTemplates.buttons.dismiss" />
        </Button>
        <Button size="large" type="primary" onClick={revert}>
          <FormattedMessage id="buttons.cancel" />
        </Button>
      </div>
    </SimpleDialog>
  );
}
