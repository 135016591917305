import { useApolloClient, useQuery } from '@apollo/client';
import {
  compact,
  find,
  isArray,
  isFunction,
  isNil,
  isObject,
  keys,
  pick,
  property,
  some,
  toPairs,
} from 'lodash-es';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import { graphQLToFormAddressBookEntry } from '../../app/data/addressBookConversions';
import { ADDRESS_BOOK_ENTRIES_QUERY } from '../../app/graphql/addressBookQueries';
import {
  ADDRESS_AUTOCOMPLETE_DETAIL_QUERY,
  ADDRESS_AUTOCOMPLETE_QUERY,
} from '../../app/graphql/geographicQueries';
import { formatLocationAutocompleteLabel } from '../../common/utils/formatUtils';
import { omitByDeep } from '../../common/utils/funcUtils';
import { extractGraphqlEntity } from '../../common/utils/graphqlUtils';
import { useDebouncedVariable } from '../../common/utils/hookUtils';
import { sanitizeFormValueToAscii } from '../../common/utils/stringUtils';
import { useFlashMessageContext } from '../../components/dialogs/FlashMessageProvider';
import { FormItemCustomGroupAutocomplete } from '../../components/forms/CustomGroupAutocomplete';
import { useDynamicOriginalValueDependency } from '../../components/forms/dynamic/dynamicFormDependencies';
import { DynamicFormSchemaContext } from '../../components/forms/dynamic/dynamicFormSchema';
import {
  isFormSectionNonEmpty,
  isFormValuePresent,
} from '../../components/forms/formHelpers';
import { makeStaticCustomRule } from '../../components/forms/formValidationRules';
import { useFormContext } from '../../components/forms/forms';
import { useLabelFilter } from '../../components/forms/selectDecorators';
import { useStateIfMounted } from '../../utils/reactUtils';

function formValueIsPresentAndDoesntMatch({ formValue, entryValue }) {
  return isFormValuePresent(formValue) && formValue !== entryValue;
}

function getAllAddressBookEntryTexts(entry) {
  return [
    entry.companyName,
    entry.contact?.name,
    entry.contact?.email,
    entry.contact?.phone,
    entry.contact?.phoneExtension,
    entry.address?.addressLine1,
    entry.address?.addressLine2,
    entry.address?.addressLine3,
    entry.address?.city?.text,
    entry.address?.stateProvince?.text,
    entry.address?.postalCode,
    entry.address?.country?.text,
    entry.specialInstructions,
  ];
}

function useCountryCode({ countrySchemaName }) {
  const countryText = useDynamicOriginalValueDependency(countrySchemaName);
  const { optionsQuery } = useContext(DynamicFormSchemaContext);
  const { data } = useQuery(optionsQuery, {
    skip: !countrySchemaName || !countryText,
    variables: { input: { fieldName: countrySchemaName, value: countryText } },
  });
  const countryOptions = data && extractGraphqlEntity(data)?.values;

  if (countryOptions?.length === 1) {
    return countryOptions[0].code;
  }
  if (countryOptions?.length > 1) {
    return find(countryOptions, opt => opt.text === countryText)?.code;
  }
  return null;
}

function useFilterAddressBookOption({ filterOption, searchText }) {
  // Address book entry should be included in the results if:
  // - all of country, state and city are either not filled or match the entry
  // - the focused field is a substring of entry's field
  return useCallback(
    (values, { entry }) => {
      const filteredFields = [
        {
          formValue: values?.address?.country,
          entryValue: entry?.address?.country?.text,
        },
        {
          formValue: values?.address?.stateProvince,
          entryValue: entry?.address?.stateProvince?.text,
        },
        {
          formValue: values?.address?.city,
          entryValue: entry?.address?.city?.text,
        },
      ];
      if (some(filteredFields, formValueIsPresentAndDoesntMatch)) {
        return false;
      }

      return some(getAllAddressBookEntryTexts(entry), sourceText =>
        filterOption(searchText, { label: sourceText })
      );
    },
    [filterOption, searchText]
  );
}

function useFilteredAddressBookItems({ searchText }) {
  const accountNumber = useDynamicOriginalValueDependency('accountNumber');
  const { values } = useFormContext();

  const { filterOption } = useLabelFilter();
  const filterAddressBookOption = useFilterAddressBookOption({
    filterOption,
    searchText,
  });

  const { data, loading, error } = useQuery(ADDRESS_BOOK_ENTRIES_QUERY, {
    skip: !accountNumber,
    variables: { accountNumber },
  });
  if (loading) {
    return { loading: true };
  }
  if (error) {
    return { error };
  }

  const entries = data ? extractGraphqlEntity(data).data : [];
  const allOptions = entries.map(entry => ({
    entry,
    value: entry.id,
    label: formatLocationAutocompleteLabel(entry),
  }));

  const options = allOptions.filter(opt =>
    filterAddressBookOption(values, opt)
  );

  return { options };
}

function useWebAddressItems({ searchText, countrySchemaName, skip }) {
  const [sessionToken, setSessionToken] = useStateIfMounted(() => uuidv4());
  const resetSessionToken = useCallback(() => {
    setSessionToken(uuidv4());
  }, [setSessionToken]);

  const countryCode = useCountryCode({ countrySchemaName });

  const { errorMessage } = useFlashMessageContext();
  const client = useApolloClient();
  const loadDetail = useCallback(
    async ({ id }) => {
      try {
        const { data } = await client.query({
          query: ADDRESS_AUTOCOMPLETE_DETAIL_QUERY,
          variables: { input: { id, sessionToken } },
        });
        return extractGraphqlEntity(data);
      } catch (e) {
        errorMessage(e);
        throw e;
      }
    },
    [client, errorMessage, sessionToken]
  );

  const [lastData, setLastData] = useState();
  const { data, loading, error } = useQuery(ADDRESS_AUTOCOMPLETE_QUERY, {
    skip: !searchText || skip,
    variables: { input: { searchText, sessionToken, countryCode } },
    onCompleted: dt => {
      setLastData(dt);
    },
  });
  if (loading && !lastData) {
    return { loading: true };
  }
  if (error) {
    return { error };
  }

  const usedData = data || lastData;
  const entries = usedData ? extractGraphqlEntity(usedData).data : [];
  const options = entries.map(entry => ({
    entry,
    value: entry.id,
    label: entry.text,
  }));

  return { options, resetSessionToken, loadDetail };
}

const CUSTOM_ADDRESS_ID = uuidv4();

function AddressAutoComplete({
  onSelect: onSelectOuter,
  countrySchemaName,
  enableWebResults = true,
  enableAddressBookResults = true,
}) {
  const intl = useIntl();
  const {
    formInstance,
    forceUpdate,
    submittingRef,
    values: { addressSearchText },
  } = useFormContext();

  const searchText = useDebouncedVariable(addressSearchText, {
    delay: 250,
  });
  const addressBookGroup = useFilteredAddressBookItems({
    searchText,
  });
  const {
    resetSessionToken,
    loadDetail: loadWebAddressDetail,
    ...webAddressGroup
  } = useWebAddressItems({
    searchText,
    countrySchemaName,
    skip: !enableWebResults,
  });

  const options = compact([
    {
      groupId: 'custom',
      options: [
        {
          value: CUSTOM_ADDRESS_ID,
          label: intl.formatMessage({
            id: 'address.autoComplete.customAddress',
          }),
        },
      ],
    },
    enableAddressBookResults && {
      groupId: 'addressBook',
      headerId: 'address.autoComplete.addressBook',
      ...addressBookGroup,
    },
    enableWebResults && {
      groupId: 'web',
      headerId: 'address.autoComplete.web',
      ...webAddressGroup,
    },
  ]);

  const onSelect = async opt => {
    try {
      onSelectOuter && onSelectOuter(opt);

      if (opt.value === CUSTOM_ADDRESS_ID) {
        return;
      }

      const values = graphQLToFormAddressBookEntry(
        opt.entry?.text ? await loadWebAddressDetail(opt.entry) : opt.entry
      );
      formInstance.setFieldsValue(sanitizeFormValueToAscii(values));
      forceUpdate();
    } finally {
      resetSessionToken();
    }
  };

  return (
    <FormItemCustomGroupAutocomplete
      name="addressSearchText"
      placeholder={
        // We want it to look like required but to have a custom validation only run at submit time
        `${intl.formatMessage({
          id: 'address.lookup.placeholder',
        })} *`
      }
      floatingLabel={false}
      options={options}
      formItemComponentProps={{
        onSelect,
        setLabelOnSelect: false,
      }}
      rules={[
        // Only show error when submitting
        makeStaticCustomRule(
          'error.addressRequired',
          () => !submittingRef.current
        ),
      ]}
    />
  );
}

function getAutocompleteableLocationPart(values) {
  return omitByDeep(
    pick(values, ['address', 'contact', 'companyName']),
    (val, key) => key === 'country'
  );
}

function getFieldNames(values, depth = 0, prefix = []) {
  if (depth === 0) {
    return keys(values).map(key => ({
      fieldName: [...prefix, key],
    }));
  }
  return toPairs(values)
    .map(([key, val]) => {
      if (isNil(val)) {
        return null;
      }
      if (isObject(val)) {
        return getFieldNames(val, depth - 1, [...prefix, key]);
      }
      return { fieldName: [...prefix, key] };
    })
    .reduce((prev, cur) => {
      if (isNil(cur)) {
        return prev;
      }
      if (isArray(cur)) {
        return [...prev, ...cur];
      }
      return [...prev, cur];
    }, []);
}

export default function AddressLookup({
  children,
  renderLookup,
  countrySchemaName,
  enableWebResults = true,
  enableAddressBookResults = true,
}) {
  const { values, formInstance, forceUpdate } = useFormContext();
  const [showLookup, setShowLookup] = useState(true);
  const onSelect = useCallback(
    opt => {
      setShowLookup(false);
      formInstance.setFieldsValue({ addressSearchText: '' });
    },
    [formInstance]
  );
  const onReset = useCallback(() => {
    setShowLookup(true);
    formInstance.setFieldsValue({ addressSearchText: '' });

    const resettable = getAutocompleteableLocationPart(
      formInstance.getFieldsValue()
    );
    const resettableFields = getFieldNames(resettable, 1);
    formInstance.resetFields(resettableFields.map(property('fieldName')));
    forceUpdate();
  }, [forceUpdate, formInstance]);

  const addressNonEmpty = isFormSectionNonEmpty(
    getAutocompleteableLocationPart(values)
  );
  // As soon as address is non empty, lookup will be disabled
  useEffect(() => {
    if (addressNonEmpty) {
      setShowLookup(false);
    }
  }, [addressNonEmpty]);

  const field = (
    <AddressAutoComplete
      onSelect={onSelect}
      countrySchemaName={countrySchemaName}
      enableAddressBookResults={enableAddressBookResults}
      enableWebResults={enableWebResults}
    />
  );

  if (showLookup) {
    return renderLookup(field);
  }

  return isFunction(children) ? children({ onReset }) : children;
}
