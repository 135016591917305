import { Col, Row } from 'antd';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { useReduxDialogControls } from '../../../common/utils/dialogUtils';
import { formatCodeValue } from '../../../common/utils/formatUtils';
import IconButton from '../../../components/buttons/IconButton';
import {
  SimpleCard,
  SimpleCardTheme,
} from '../../../components/layout/cardElements';
import { LabelWithValue } from '../../../components/typography';
import { cssVariables } from '../../../styles/cssVariables';
import { pxToNumber } from '../../../utils/cssUtils';
import {
  DateTimeFormatNumericNoYear,
  DateTimeFormatShortWithDashes,
} from '../../../utils/dateFormatting';
import {
  ShipmentSensorWidgetsDrawer,
  computeSensorMode,
  useShipmentDeviceSensorData,
} from '../ShipmentSensorWidgets';

export const ShipmentSummaryCard = props => (
  <SimpleCard {...props} theme={SimpleCardTheme.FLAT} />
);
export function ShipmentSummaryCardSection({
  className,
  header,
  headerId,
  children,
}) {
  return (
    <div className={classNames('ShipmentSummaryCard-Section', className)}>
      {(header || headerId) && (
        <div className="ShipmentSummaryCard-SectionHeader">
          {headerId ? <FormattedMessage id={headerId} /> : header}
        </div>
      )}
      {header || headerId ? (
        <div className="ShipmentSummaryCard-SectionBody">{children}</div>
      ) : (
        children
      )}
    </div>
  );
}

export const ShipmentSummaryLabelWithValue = props => (
  <LabelWithValue {...props} nowrap />
);

export const TwoLineLabelWithValue = props => (
  <ShipmentSummaryLabelWithValue {...props} labelSize="full" />
);

export const ShipmentSummaryDateTimeFormatFlight = props => (
  <DateTimeFormatNumericNoYear {...props} separator=" | " />
);
export const ShipmentSummaryDateTimeFormatWithPipe = props => (
  <DateTimeFormatShortWithDashes {...props} separator=" | " />
);

export function ShipmentDeviceAddon({ data, jobNumber, packageIndex, index }) {
  const { index: addonIndex = 0, type } = data || {};
  const { isOpen, open, close } = useReduxDialogControls({
    id: 'shipmentDetail.sensorWidgets',
    spec: { packageIndex, addonIndex, type },
  });
  const addonData = useShipmentDeviceSensorData({
    jobNumber,
    packageIndex,
    addonIndex,
    type,
  });

  if (!data) {
    return null;
  }
  const { brandName, modelName, source, sensorData, sensorUrl, serialNumber } =
    data;

  const sensorMode = computeSensorMode({ sensorData, sensorUrl });

  return (
    <div className="ShipmentDetail-DeviceAddonRow">
      <div
        className={classNames('ShipmentDetail-DeviceAddonRow-Header', {
          active: isOpen,
        })}
      >
        <div className="ShipmentDetail-DeviceAddonRow-Index">{index + 1}</div>
        <div className="ShipmentDetail-DeviceAddonRow-HeaderLabel">
          <FormattedMessage id="shipmentDetail.deviceAddon.serialNumber" />
        </div>
        <div className="ShipmentDetail-DeviceAddonRow-HeaderValue">
          {serialNumber || '-'}
        </div>
        <IconButton
          icon="external-link-square-alt icon-16"
          onClick={open}
          disabled={!sensorMode}
        />
      </div>
      <div className="ShipmentDetail-DeviceAddonRow-Body">
        <Row
          className="LabelRow"
          align="top"
          gutter={pxToNumber(cssVariables.spaceSm)}
        >
          <Col span={12}>
            <ShipmentSummaryLabelWithValue
              labelId="shipmentDetail.deviceAddon.brandName"
              text={brandName}
              labelSize="full"
            />
          </Col>
          <Col span={8}>
            <ShipmentSummaryLabelWithValue
              labelId="shipmentDetail.deviceAddon.modelName"
              text={modelName}
              labelSize="full"
            />
          </Col>
          <Col span={4}>
            <ShipmentSummaryLabelWithValue
              labelId="shipmentDetail.deviceAddon.source"
              text={formatCodeValue(source)}
              labelSize="full"
            />
          </Col>
        </Row>
      </div>
      <ShipmentSensorWidgetsDrawer
        jobNumber={jobNumber}
        addonData={addonData}
        isOpen={isOpen}
        onClose={close}
      />
    </div>
  );
}
