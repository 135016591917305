import { stubTrue, sumBy } from 'lodash-es';

import { isFormSectionNonEmpty } from '../../components/forms/formHelpers';
import { DateTimeFormatMedium } from '../../utils/dateFormatting';
import { canComputeDimensionalWeight } from './packageList/packageListCommon';

export const ShipmentDateTimeFormat = props => (
  <DateTimeFormatMedium {...props} separator=" | " />
);

export function getValidPackages(packages) {
  return (packages || []).filter(isFormSectionNonEmpty);
}

export function getPackageCount(packages, filter) {
  return getValidPackages(packages).filter(filter || stubTrue).length;
}

export function extractActualWeight(pkg) {
  return pkg.unknownWeight ? null : pkg.actualWeight;
}

export function extractBilledWeight(pkg) {
  if (canComputeDimensionalWeight(pkg)) {
    return pkg.billedWeight;
  }
  return pkg.unknownWeight ? null : pkg.actualWeight;
}

export function getPackageTotal(packages, getter) {
  return sumBy(getValidPackages(packages), getter);
}
