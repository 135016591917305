import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { useCachedProp } from '../../common/utils/hookUtils';
import { makeHookInjectingHOC } from '../../utils/reactUtils';

function useEnumOptions({ intlPrefix, intlValues }) {
  const intl = useIntl();
  return intlValues.map(value => ({
    label: intl.formatMessage({
      id: [intlPrefix, value].join('.').replace(/\.+/g, '.'),
    }),
    value,
  }));
}

const WithEnumOptions = ({ component: C, intlPrefix, intlValues, ...rest }) => {
  const options = useEnumOptions({ intlPrefix, intlValues });
  return <C {...rest} options={options} />;
};
export const withEnumOptions = component => props => (
  <WithEnumOptions component={component} {...props} />
);

export function useTranslatedOptions(options) {
  const intl = useIntl();
  return options.map(({ labelId, label, value, intlArgs, ...rest }) => ({
    ...rest,
    label: labelId ? intl.formatMessage({ id: labelId }, intlArgs) : label,
    value,
  }));
}
export const withTranslatedOptions = makeHookInjectingHOC({
  propName: 'options',
  hook: useTranslatedOptions,
  getArgs: props => [props.options],
});

function isEvent(arg) {
  return arg && arg.nativeEvent;
}

export function useFormListCallbacks({
  fields,
  index,
  add,
  remove,
  move,
  defaultValue,
}) {
  const cachedDefaultValue = useCachedProp(defaultValue);
  const addNew = useCallback(
    val => {
      // We want to ignore event argument so that it can be used as a callback directly
      const value = isEvent(val)
        ? cachedDefaultValue
        : val || cachedDefaultValue;
      add(value);
    },
    [add, cachedDefaultValue]
  );
  const addAfter = useCallback(
    val => {
      const newIndex = fields.length;
      addNew(val);
      if (newIndex !== index + 1) {
        move(newIndex, index + 1);
      }
    },
    [addNew, index, fields.length, move]
  );
  const removeThis = useCallback(() => remove(index), [index, remove]);

  return { addNew, addAfter, removeThis };
}
