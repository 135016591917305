import { gql } from '@apollo/client';

import { codeValueFragment, makeLocationFragment } from './commonFragments';

const gpsLocationFragment = `{
  lat
  lng
}`;

const indicatorsFragment = `{
  driver {
    active
  }
  device {
    active
  }
  flight {
    active
  }
  serviceUpdate {
    active
  }
}`;

const listLocationFragment = `{
  companyName
  address {
    city {
      text
    }
    stateProvince {
      code
    }
    country {
      code
    }
  }
  location ${gpsLocationFragment}
}`;
export const JOBS_QUERY = gql`
  query CustomerShipmentsQuery($pageIndex: Int, $pageSize: Int, $filter: ShipmentFilter, $sort: Sort) {
    customerShipments(pageIndex: $pageIndex, pageSize: $pageSize, filter: $filter, sort: $sort) {
      pageIndex
      pageSize
      total
      data {
        metadata {
          qolAccount {
            number
            name
          }
          blindingInformation {
            origin
            destination
            flights
            activity
            accountDetails
            location
          }
        }
        jobNumber
        origin ${listLocationFragment}
        destination ${listLocationFragment}
        shipmentStatus
        stage
        pickupDateTime {
          scheduled
        }
        deliveryDateTime {
          scheduled
          isAsap
          actual
        }
        shippersReferences
        productExpirationDateTime
        indicators ${indicatorsFragment}
        serviceUpdate {
          oldEstimatedArrival
        }
      }
    }
  }
`;

export const JOBS_COUNTS_QUERY = gql`
  query CustomerShipmentCountsQuery($filter: ShipmentFilter) {
    customerShipmentCounts(filter: $filter) {
      saved
      live
      delivered
    }
  }
`;

const locationFragment = makeLocationFragment({ fullState: true });

const batterySensorFragment = `{
  timestamp
  percentage
}`;
// For battery and numeric
const simpleValueSensorFragment = `{
  timestamp
  value
}`;
const temperatureSensorFragment = `{
  current ${simpleValueSensorFragment}
  min ${simpleValueSensorFragment}
  max ${simpleValueSensorFragment}
}`;
const deviceFragment = `{
  deviceCode
  brandName
  modelName
  serialNumber
  source ${codeValueFragment}
  sensorData {
    location ${gpsLocationFragment}
    battery ${batterySensorFragment}
    lightDetection ${simpleValueSensorFragment}
    temperature ${temperatureSensorFragment}
    temperatureProbe ${temperatureSensorFragment}
    humidity ${simpleValueSensorFragment}
    pressure ${simpleValueSensorFragment}
    orientation ${simpleValueSensorFragment}
  }
  sensorUrl
}`;

const shipmentAllFields = `
    metadata {
      qolAccount {
        number
        name
      }
      blindingInformation {
        origin
        destination
        flights
        activity
        accountDetails
        location
      }
      shareable
    }
    jobNumber
    authorizedBy {
      contact {
        name
        phone
      }
    }
    origin {
      ...JobLocationFragment
      location ${gpsLocationFragment}
    }
    destination {
      ...JobLocationFragment
      location ${gpsLocationFragment}
    }
    shipmentStatus
    stage
    shippersReferences
    pickupDateTime {
      scheduled
      estimated
      actual
    }
    deliveryDateTime {
      scheduled
      isAsap
      estimated
      actual
    }
    outForDeliveryDateTime
    signedBy
    productExpirationDateTime
    indicators ${indicatorsFragment}
    serviceInformation {
      transportType
      commodity
      serviceType ${codeValueFragment}
      bolNumber
      dangerousGoods
      unNumber ${codeValueFragment}
      exceptedQuantity
      additionalInsurance
      declaredValue
      currency ${codeValueFragment}
      costCenter
      shipperSpecificInfo {
        name
        label
        value
        type
      }
      eoriNumber
      hsInfo {
        code
        description
      }
    }
    flights {
      awb
      airline
      number
      originAirportCode
      originAirportLocation ${gpsLocationFragment}
      destinationAirportCode
      status
      location ${gpsLocationFragment}
      departure {
        scheduled
        estimated
        actual
      }
      arrival {
        scheduled
        estimated
        actual
      }
      tenderDateTime
      recoveryDateTime
    }
    packagesInformation {
      totalWeight
      count
      packages {
        packageSource ${codeValueFragment}
        packagingType ${codeValueFragment}
        sensorUrl
        commodityTemperature ${codeValueFragment}
        packageQualificationTime
        packoutDateTime
        billedWeight
        actualWeight
        length
        width
        height
        gpsDevices ${deviceFragment}
        temperatureDevices ${deviceFragment}
        pieces {
          description
          partNumber
          quantity
        }
        coolantAddon {
          type ${codeValueFragment}
          source ${codeValueFragment}
          amount
          replenishTime
        }
      }
    }
    activity {
      type
      dateTime
    }
    serviceUpdate {
      customerServicePhone
      oldEstimatedArrival
      newEstimatedArrival
      reason
    }
    driver {
      location ${gpsLocationFragment}
    }
    documents {
      bolUrl
      documentList {
        id
        description
        uploadedBy
        uploadedAt
      }
    }
    assets {
      description
      partNumber
      serialNumber
      assetTagNumber
      poNumber
    }
    unitSystem`;

export const JOB_QUERY = gql`
  fragment JobLocationFragment on JobLocation ${locationFragment}
  query CustomerShipmentQuery($jobNumber: String!) {
    customerShipment(jobNumber: $jobNumber) {
      ${shipmentAllFields}
    }
  }
`;

export const REVERSED_JOB_FORM_QUERY = gql`
  fragment JobLocationFragment on JobLocation ${locationFragment}
  query ReversedShipmentFormQuery($jobNumber: String!) {
    reversedShipmentForm(jobNumber: $jobNumber) {
      ${shipmentAllFields}
    }
  }
`;

// PERF NOTE: it should always be a subset of JOB_QUERY to prevent fetching twice
export const JOB_LOCATION_QUERY = gql`
  query CustomerShipmentLocationQuery($jobNumber: String!) {
    customerShipment(jobNumber: $jobNumber) {
      jobNumber
      driver {
        location ${gpsLocationFragment}
      }
      packagesInformation {
        packages {
          gpsDevices {
            sensorData {
              location ${gpsLocationFragment}
            }
            serialNumber
          }
          temperatureDevices {
            sensorData {
              location ${gpsLocationFragment}
            }
          }
        }
      }
      flights {
        location ${gpsLocationFragment}
        originAirportLocation ${gpsLocationFragment}
        departure {
          actual
        }
        arrival {
          actual
        }
        recoveryDateTime
      }
      origin {
        location ${gpsLocationFragment}
      }
      destination {
        location ${gpsLocationFragment}
      }
    }
  }
`;
// PERF NOTE: it should always be a subset of JOB_QUERY to prevent fetching twice
export const JOB_SENSOR_DATA_QUERY = gql`
  query CustomerShipmentSensorDataQuery($jobNumber: String!) {
    customerShipment(jobNumber: $jobNumber) {
      jobNumber
      packagesInformation {
        packages {
          sensorUrl
          gpsDevices ${deviceFragment}
          temperatureDevices ${deviceFragment}
        }
      }
    }
  }
`;

export const JOB_DOCUMENTS_QUERY = gql`
  query CustomerShipmentDocumentsQuery($jobNumber: String!) {
    customerShipment(jobNumber: $jobNumber) {
      jobNumber
      documents {
        bolUrl
        documentList {
          id
          description
        }
      }
    }
  }
`;

export const DIMENSIONAL_WEIGHT_QUERY = gql`
  query DimensionalWeightQuery($input: DimensionalWeightInput!) {
    computeDimensionalWeight(input: $input) {
      dimensionalWeight
      billedWeight
    }
  }
`;

export const SHARED_SHIPMENT_QUERY = gql`
  fragment JobLocationFragment on JobLocation ${locationFragment}
  query SharedShipmentQuery($token: String!) {
    sharedCustomerShipment(token: $token) {
      ${shipmentAllFields}
    }
  }
`;

export const SHARE_SHIPMENT_MUTATION = gql`
  mutation ShareShipmentMutation($jobNumber: String!) {
    shareShipment(jobNumber: $jobNumber) {
      token
      url
    }
  }
`;
