import { Col, Row } from 'antd';
import { useState } from 'react';

import { useMountEffect } from '../../common/utils/hookUtils';
import { FAIcon } from '../../components/adapters/fontAwesomeAdapters';
import { useFlashMessageContext } from '../../components/dialogs/FlashMessageProvider';
import { FormItemInputPassword } from '../../components/forms/basicFormElements';
import { useFormContext } from '../../components/forms/forms';

function PasswordValidationResultRow({ message, success }) {
  return (
    <Row className="ValidationSubruleResult" align="middle">
      <Col>
        <FAIcon
          icon={success ? 'check' : 'times'}
          className={success ? 'success' : 'error'}
        />
      </Col>
      <Col>{message}</Col>
    </Row>
  );
}

export default function NewPasswordField({
  name = 'password',
  validatePassword,
}) {
  const { errorMessage } = useFlashMessageContext();
  const [messages, setMessages] = useState([]);
  const { values } = useFormContext();

  const validate = async value => {
    try {
      const { success, messages: newMessages } = await validatePassword(value);
      setMessages(newMessages);
      return success;
    } catch (err) {
      errorMessage(err);
      throw err;
    }
  };
  useMountEffect(() => validate(values[name] || ''));

  const strongPasswordRule = {
    message: '',
    validator: async (_, value) => {
      if (await validate(value)) {
        return Promise.resolve();
      }
      return Promise.reject();
    },
  };

  return (
    <>
      <div className="ValidationSubruleResults">
        {messages.map(msg => (
          <PasswordValidationResultRow
            key={msg.code}
            success={msg.success}
            message={msg.message}
          />
        ))}
      </div>
      <FormItemInputPassword
        name={name}
        labelId="newPassword.password.field.placeholder"
        rules={[{ required: true }, strongPasswordRule]}
        className="hidden-error"
        validateTrigger="onChange"
      />
    </>
  );
}
