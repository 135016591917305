import { useQuery } from '@apollo/client';
import { Tag } from 'antd';
import classNames from 'classnames';
import {
  find,
  first,
  get,
  isEqual,
  values as objValues,
  toPairs,
} from 'lodash-es';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { SHIPMENT_STATUS_OPTIONS_QUERY } from '../../app/graphql/jobFilterQueries';
import { isDateRangeEqual } from '../../common/utils/dateRangeUtils';
import { formatAccount } from '../../common/utils/formatUtils';
import { extractGraphqlEntity } from '../../common/utils/graphqlUtils';
import { useSortToggle } from '../../common/utils/sortUtils';
import { useNodeInnerText } from '../../common/utils/stringUtils';
import Whitespace from '../../components/Whitespace';
import { FAIcon, FATypes } from '../../components/adapters/fontAwesomeAdapters';
import IconButton from '../../components/buttons/IconButton';
import { FiltersAndSortContext } from '../../components/data/FiltersAndSortProvider';
import { useAccountOptions } from '../../components/domainSpecific/accountElements';
import { FormItemFieldRegistration } from '../../components/forms/basicFormElements';
import { FormItemDateRangePopup } from '../../components/forms/dateElements';
import { isFormValuePresent } from '../../components/forms/formHelpers';
import { useFormContext } from '../../components/forms/forms';
import {
  findAndPrintSelectOption,
  findSelectOption,
  mapSelectOptions,
} from '../../components/forms/selectUtils';
import { Popup } from '../../components/nav/navElements';
import { useDateRangePresets } from '../../config/dateRangeConstants';
import { DateFormatNumeric } from '../../utils/dateFormatting';
import {
  FormItemSearchSingleSelect,
  FormItemSearchTextFilterSubmenu,
} from './monitorFiltersFormItems';

function FilterPopup({ className, overlayClassName, isPresent, ...rest }) {
  return (
    <div className="MonitorSearchFilters-PopupContainer">
      <Popup
        className={classNames('MonitorSearchFilters-PopupLabel', className)}
        overlayClassName={classNames(
          'MonitorSearchFilters-Popup no-arrow',
          overlayClassName
        )}
        align={{
          points: ['t', 'b'],
          offset: [0, 4],
          overflow: {
            adjustX: 1,
            adjustY: 1,
          },
        }}
        trigger="click"
        getPopupContainer={triggerNode => triggerNode.parentNode}
        customClosedIcon={
          isPresent ? (
            <FAIcon icon="pen-square" className="color-highlight icon-18" />
          ) : undefined
        }
        {...rest}
      />
    </div>
  );
}

function DateRangeFilter({ name, isPresent }) {
  return (
    <FormItemDateRangePopup
      name={name}
      popupComponent={FilterPopup}
      placeholderId="filters.labels.dateRange"
      popupProps={{ isPresent, overlayClassName: 'DateRangeFilter' }}
    />
  );
}
export function DateRangeFilterPreviewText({ DateFormat, value }) {
  const { labelIdsByKey } = useDateRangePresets();
  const labelId = labelIdsByKey[value?.namedRange];
  return labelId ? (
    <FormattedMessage id={labelId} />
  ) : (
    <>
      <DateFormat value={value?.from} />
      <span className="margin-hor-xs">
        <FormattedMessage id="filters.labels.rangeJoin" />
      </span>
      <DateFormat value={value?.to} />
    </>
  );
}
function DateRangeTagContent({ value }) {
  const { ref, text } = useNodeInnerText();
  return (
    <>
      <FAIcon
        icon="calendar"
        type={FATypes.REGULAR}
        className="DatePreviewIcon"
      />
      <span className="text" ref={ref} title={text}>
        <DateRangeFilterPreviewText
          DateFormat={DateFormatNumeric}
          value={value}
        />
      </span>
    </>
  );
}

function useSimpleAccountOptions() {
  const { options } = useAccountOptions();
  return mapSelectOptions(options, opt => ({
    ...opt,
    value: opt.value.number,
    account: opt.value,
  }));
}
function AccountFilter({ name, isPresent }) {
  const options = useSimpleAccountOptions();
  return (
    <FilterPopup labelId="filters.labels.account" isPresent={isPresent}>
      <FormItemSearchSingleSelect
        name={name}
        options={options}
        className="AccountSelect__Dropdown"
      />
    </FilterPopup>
  );
}
function AccountTagContent({ value }) {
  const options = useSimpleAccountOptions();
  const opt = findSelectOption(value, options);
  const text = opt && formatAccount(opt.account);
  return text ? (
    <span className="text" title={text}>
      {text}
    </span>
  ) : null;
}

function useStatusOptions() {
  const { data } = useQuery(SHIPMENT_STATUS_OPTIONS_QUERY);
  return (
    data &&
    extractGraphqlEntity(data).map(({ code, text }) => ({
      value: code,
      label: text,
      title: text,
    }))
  );
}
function StatusFilter({ name, isPresent }) {
  return (
    <FilterPopup labelId="filters.labels.status" isPresent={isPresent}>
      <FormItemSearchSingleSelect name={name} options={useStatusOptions()} />
    </FilterPopup>
  );
}
function StatusTagContent({ value }) {
  const options = useStatusOptions();
  const text = findAndPrintSelectOption(value, options);
  return text ? (
    <span className="text" title={text}>
      {text}
    </span>
  ) : null;
}

const SHIPMENT_DETAILS_OPTIONS = [
  {
    name: 'destinationContactName',
    labelId: 'filters.labels.shipmentDetails.destinationContactName',
  },
  {
    name: 'destinationCity',
    labelId: 'filters.labels.shipmentDetails.destinationCity',
  },
  {
    name: 'destinationCountry',
    labelId: 'filters.labels.shipmentDetails.destinationCountry',
  },
];
function ShipmentDetailsFilter({ name, isPresent }) {
  return (
    <FilterPopup labelId="filters.labels.shipmentDetails" isPresent={isPresent}>
      <FormItemSearchTextFilterSubmenu
        name={name}
        options={SHIPMENT_DETAILS_OPTIONS}
      />
    </FilterPopup>
  );
}
function ShipmentDetailsTagContent({ value }) {
  const { ref, text: title } = useNodeInnerText();

  const [name, text] = value ? first(toPairs(value)) : [];
  const labelId = find(
    SHIPMENT_DETAILS_OPTIONS,
    item => item.name === name
  )?.labelId;

  return text ? (
    <span className="text" ref={ref} title={title}>
      {labelId && (
        <>
          <FormattedMessage id={labelId} />
          <Whitespace />|<Whitespace />
          {text}
        </>
      )}
    </span>
  ) : null;
}

const MonitorFilterType = {
  DATE_RANGE: {
    name: 'dateRange',
    filterComponent: DateRangeFilter,
    isFilterEqual: isDateRangeEqual,
    tagContentComponent: DateRangeTagContent,
    isClearable: true,
    isSortable: true,
    isFilterPresent: val => val?.from || val?.to,
  },
  ACCOUNT: {
    name: 'accountNumber',
    filterComponent: AccountFilter,
    tagContentComponent: AccountTagContent,
    isClearable: true,
    isSortable: true,
  },
  STATUS: {
    name: 'shipmentStatus',
    filterComponent: StatusFilter,
    tagContentComponent: StatusTagContent,
    isClearable: true,
    isSortable: true,
  },
  SHIPMENT_DETAILS: {
    name: 'shipmentDetails',
    filterComponent: ShipmentDetailsFilter,
    tagContentComponent: ShipmentDetailsTagContent,
    isClearable: true,
    isSortable: false,
  },
};

export function MonitorFiltersRegistration() {
  return objValues(MonitorFilterType).map(({ name }) => (
    <FormItemFieldRegistration key={name} name={name} />
  ));
}

function SortIcon({ name }) {
  const {
    sort: { values: sort, setValues: setSort },
  } = useContext(FiltersAndSortContext);

  const { active, icon, onClick } = useSortToggle({ name, sort, setSort });

  return (
    <IconButton
      className={classNames('MonitorSearchFilters-SortIcon', { active })}
      icon={icon}
      onClick={onClick}
    />
  );
}

function MonitorFilter({ definition }) {
  const { values } = useFormContext();

  const {
    name,
    filterComponent: FC,
    isFilterPresent = isFormValuePresent,
    isSortable,
  } = definition;
  const isPresent = isFilterPresent(get(values, name));
  return (
    <>
      <FC name={name} isPresent={isPresent} />
      {isSortable && <SortIcon name={name} />}
    </>
  );
}

export const DateRangeMonitorFilter = props => (
  <MonitorFilter {...props} definition={MonitorFilterType.DATE_RANGE} />
);
export const AccountMonitorFilter = props => (
  <MonitorFilter {...props} definition={MonitorFilterType.ACCOUNT} />
);
export const StatusMonitorFilter = props => (
  <MonitorFilter {...props} definition={MonitorFilterType.STATUS} />
);
export const ShipmentDetailsMonitorFilter = props => (
  <MonitorFilter {...props} definition={MonitorFilterType.SHIPMENT_DETAILS} />
);

function MonitorFilterTag({ definition }) {
  const { values, formInstance, forceUpdate } = useFormContext();
  const {
    filters: { values: filters },
  } = useContext(FiltersAndSortContext);

  const {
    name,
    tagContentComponent: TCC,
    isClearable,
    isFilterEqual = isEqual,
    isFilterPresent = isFormValuePresent,
  } = definition;

  const tmpVal = get(values, name);
  const savedVal = get(filters, name);

  const isDeleted = isFilterPresent(savedVal) && !isFilterPresent(tmpVal);
  const isChanged = !isDeleted && !isFilterEqual(savedVal, tmpVal);
  const visible = isFilterPresent(savedVal) || isFilterPresent(tmpVal);
  const closable = isClearable && !isDeleted;

  return (
    <Tag
      key={name}
      className={classNames({
        changed: isChanged,
        deleted: isDeleted,
        closable,
      })}
      closable={closable}
      closeIcon={<FAIcon icon="times" />}
      onClose={() => {
        formInstance.resetFields([name]);
        forceUpdate();
      }}
      visible={visible}
    >
      {isDeleted ? (
        <FormattedMessage id="filters.labels.deleted" />
      ) : (
        <TCC value={tmpVal} />
      )}
    </Tag>
  );
}

export const DateRangeMonitorFilterTag = props => (
  <MonitorFilterTag {...props} definition={MonitorFilterType.DATE_RANGE} />
);
export const AccountMonitorFilterTag = props => (
  <MonitorFilterTag {...props} definition={MonitorFilterType.ACCOUNT} />
);
export const StatusMonitorFilterTag = props => (
  <MonitorFilterTag {...props} definition={MonitorFilterType.STATUS} />
);
export const ShipmentDetailsMonitorFilterTag = props => (
  <MonitorFilterTag
    {...props}
    definition={MonitorFilterType.SHIPMENT_DETAILS}
  />
);
