import { Col, Row } from 'antd';
import { once } from 'lodash-es';
import moment from 'moment';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { useDialogControls } from '../common/utils/dialogUtils';
import Whitespace from '../components/Whitespace';
import CollectDebugReportButton from '../components/debug/CollectDebugReportButton';
import { InlineLink } from '../components/typography';
import { config } from '../config/config';
import { UserControlledChangelogDialog } from '../dialogs/ChangelogDialog';
import { useMetadata } from '../hooks/data/misc';
import { cssVariables } from '../styles/cssVariables';
import { pxToNumber } from '../utils/cssUtils';
import { log } from '../utils/logger';

const VersionNumberLabel = () => (
  <span>
    <FormattedMessage id="labels.version" />
    <Whitespace />
    {config.version}
  </span>
);

function VersionNumberWithLink() {
  const { isOpen, open, close } = useDialogControls();

  return (
    <>
      <UserControlledChangelogDialog isOpen={isOpen} close={close} />
      <InlineLink onClick={open}>
        <VersionNumberLabel />
      </InlineLink>
    </>
  );
}

export function VersionNumber({ canOpenChangelog }) {
  return canOpenChangelog ? <VersionNumberWithLink /> : <VersionNumberLabel />;
}

function MockInfo({ metadata }) {
  return metadata?.isMock ? ' (MOCK)' : null;
}

const DividerCol = () => <Col>|</Col>;

function DebugInfo() {
  const date = moment
    .unix(config.commitTimestamp)
    .format('YYYY-MM-DD HH:mm UTC');
  return (
    <>
      <DividerCol />
      <Col>{date}</Col>
      {config.commitHash && (
        <>
          <DividerCol />
          <Col>
            <a
              href={`${config.repoUrl}/commit/${config.commitHash}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {config.commitHash.substring(0, 7)}
            </a>
          </Col>
        </>
      )}
      <DividerCol />
      <Col>
        <CollectDebugReportButton />
      </Col>
    </>
  );
}

const logAppInfoOnce = once(metadata =>
  log.info('App info', {
    version: config.version,
    mock: metadata.isMock,
    logLevel: config.logLevel,
    commitHash: config.commitHash,
    commitTimestamp: config.commitTimestamp,
  })
);

export function MetadataInfo({ canOpenChangelog }) {
  const metadata = useMetadata();
  useEffect(() => {
    if (!metadata) {
      return;
    }
    logAppInfoOnce(metadata);
  }, [metadata]);
  return (
    <Row className="MetadataInfo" gutter={pxToNumber(cssVariables.spaceSm)}>
      <Col>
        <VersionNumber canOpenChangelog={canOpenChangelog} />
        <MockInfo metadata={metadata} />
      </Col>
      {config.showDebugInfo && <DebugInfo />}
    </Row>
  );
}
