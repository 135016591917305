import { stubFalse } from 'lodash-es';

function animateInner({
  startTime,
  startValue,
  endValue,
  duration,
  easing,
  onStep,
  onEnd,
}) {
  requestAnimationFrame(() => {
    const currentTime = new Date().getTime();
    const time = Math.min(currentTime - startTime, duration);
    const value = easing(time, startValue, endValue - startValue, duration);
    const stepResult = onStep(value);
    if (time === duration || stepResult === false) {
      onEnd();
    } else {
      animateInner({
        startTime,
        startValue,
        endValue,
        duration,
        easing,
        onStep,
        onEnd,
      });
    }
  });
}

export function animate({ startValue, endValue, duration, easing, onStep }) {
  const startTime = new Date().getTime();
  const cancelled = { value: false };

  const promise = new Promise((resolve, reject) =>
    animateInner({
      startTime,
      startValue,
      endValue,
      duration,
      easing,
      onStep: cancelled.value ? stubFalse : onStep,
      onEnd: resolve,
    })
  );
  const cancel = () => {
    cancelled.value = true;
  };

  return { promise, cancel };
}
