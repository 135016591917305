import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';

import { LOG_MUTATION } from '../../app/graphql/logQueries';
import { formatError } from '../../utils/errorUtils';
import { log } from '../../utils/logger';
import { debugEnvInfo } from './debugReportsCommon';

/**
 * Client needs to be provided if used outside of ApolloProvider
 */
export function useRemoteLogger({ client } = {}) {
  const [gqlLog] = useMutation(LOG_MUTATION, client ? { client } : {});
  const intl = useIntl();

  return async (error, level = 'error') => {
    const errorMsg = formatError(error, { intl });
    const message = [errorMsg, debugEnvInfo(), error?.stack].join('\n');
    try {
      await gqlLog({ variables: { input: { message, level } } });
    } catch (e) {
      log.error(e);
    }
  };
}
