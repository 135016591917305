import { compact } from 'lodash-es';

import { EMPTY_VALUE } from '../../../common/utils/formatUtils';
import { capitalizeWords } from '../../../common/utils/stringUtils';

export function formatUserLabel(user) {
  return user
    ? capitalizeWords(`${user.firstName} ${user.lastName}`)
    : EMPTY_VALUE;
}

export function formatAccountLabel(account, { long } = {}) {
  return compact([account.number, long && account.name]).join(' | ');
}
