import classNames from 'classnames';
import { get, isFunction, noop } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { useMountAndUpdateEffect } from '../../common/utils/hookUtils';
import { cssVariables } from '../../styles/cssVariables';
import { msToNumber } from '../../utils/cssUtils';
import {
  getScrollYParent,
  isElementInScrollView,
  useScrollToThis,
} from '../../utils/scrollUtils';
import { Spinner, SpinnerType } from './Spinner';

function Loader({ scrollToView }) {
  const ref = useScrollToThis({ active: scrollToView });
  return <Spinner type={SpinnerType.ROW} ref={ref} />;
}

const DEFAULT_RENDER_EMPTY = () => null;

export function AnimatedList({
  className,
  dataSource,
  rowKey,
  renderItem,
  renderEmpty = DEFAULT_RENDER_EMPTY,
  transitionProps,
}) {
  return (
    <>
      <TransitionGroup component="div" className={classNames(className)}>
        {dataSource.map(row => (
          <CSSTransition
            key={get(row, rowKey)}
            timeout={msToNumber(cssVariables.animationDurationMd)}
            {...(isFunction(transitionProps)
              ? transitionProps(row)
              : transitionProps)}
          >
            {renderItem(row)}
          </CSSTransition>
        ))}
      </TransitionGroup>
      {dataSource.length === 0 && renderEmpty()}
    </>
  );
}

export function InfiniteScrollList({
  rows,
  hasNext,
  loadNext,
  error,
  loading,
  loadingPage1,
  disabled, // disable loading for example if hidden in a shared scroll area
  render,
}) {
  const prevRows = useRef(rows);
  useEffect(() => {
    if (!loading) {
      prevRows.current = rows;
    }
  }, [loading, rows]);
  const loaderRef = useRef();

  // "ref" is in state, because rendering depends on it
  const [container, setContainer] = useState();
  const containerRef = useRef(ref => setContainer(ref));

  const loader = (
    <div ref={loaderRef} key="spinner">
      <Loader scrollToView={loadingPage1} />
    </div>
  );

  // Infinite scroll doesn't initiate loading without scroll - we have to do it
  // manually in case the first page has less content than container's height
  const loadMoreIfNeeded = () => {
    if (
      loaderRef.current &&
      rows.length > 0 &&
      isElementInScrollView(loaderRef.current)
    ) {
      loadNext();
    }
  };
  useMountAndUpdateEffect(
    {
      onMount() {
        loadMoreIfNeeded();
      },
      onUpdate([prevLoading, prevDisabled, prevLength]) {
        if (prevLoading && !loading) {
          loadMoreIfNeeded();
        } else if (prevLength !== rows.length) {
          loadMoreIfNeeded();
        }
        if (prevDisabled && !disabled) {
          loadMoreIfNeeded();
        }
      },
    },
    [loading, disabled, rows.length]
  );

  if (loadingPage1) {
    // Loading page 1 and no stale data - showing only loader
    if (prevRows.current.length === 0) {
      return loader;
    }
    // Loading page 1 and stale data present - showing loader and a list with blurred overlay
    return (
      <div className="spaces-vert-norm">
        {loader}
        <div className="DataRenderer-StaleData">
          {render({ rows: prevRows.current })}
        </div>
      </div>
    );
  }

  const showList =
    rows.length > 0 || // Data present - showing the list
    !hasNext; // No more data to be loaded (even with no data) - showing list and letting it handle empty state
  // If there is no data but it's still expected to come, we don't want to show list with its empty message

  // Any other state - showing infinite scroll with its own handling of loader
  return (
    <div ref={containerRef.current}>
      {/*
        We will not render until we have container ref, because incorrect `getScrollParent` result
        then results in an inconsistent `InfiniteScroll` behaviour for a moment
      */}
      {container && (
        <InfiniteScroll
          initialLoad={false}
          hasMore={!error && hasNext}
          loader={loader}
          loadMore={disabled ? noop : loadNext}
          useWindow={false}
          getScrollParent={() => getScrollYParent(container)}
          threshold={32}
        >
          {showList && render({ rows })}
        </InfiniteScroll>
      )}
    </div>
  );
}
