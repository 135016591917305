import { Checkbox, Form, Radio, Tooltip } from 'antd';
import classNames from 'classnames';
import { useCallback, useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { EMPTY_VALUE } from '../../common/utils/formatUtils';
import { optionalDecorator } from '../../common/utils/funcUtils';
import { FormItemOverrideContext, useFormValueDecorator } from './FormItem';
import { useTranslatedOptions, withEnumOptions } from './formDecorators';
import { extractValueFromEvent } from './formHelpers';
import { FormInterceptorContext } from './forms';
import { FormItemSelect } from './selects';

function InlineCheckable({
  component: C,
  name,
  labelId,
  label,
  placeholderId,
  placeholder,
  disabled: disabledOuter,
  formItemComponentProps,
  ...rest
}) {
  const overrideContext = useContext(FormItemOverrideContext);
  const disabled =
    disabledOuter || overrideContext.disabled || overrideContext.viewMode;

  return (
    <Form.Item name={name} valuePropName="checked" {...rest}>
      <C disabled={disabled} name={name} {...formItemComponentProps}>
        {labelId ? <FormattedMessage id={labelId} /> : label}
      </C>
    </Form.Item>
  );
}

function FormItemInlineCheckable({
  className,
  size,
  formItemComponentProps,
  ...rest
}) {
  return (
    <InlineCheckable
      formItemComponentProps={{
        ...formItemComponentProps,
        className: classNames(className, size && `size-${size}`),
      }}
      {...rest}
    />
  );
}

function ExtendedCheckbox({ onChange: onChangeOuter, ...rest }) {
  const { onBlur: onBlurGlobal } = useContext(FormInterceptorContext);
  const onChange = useCallback(
    e => {
      onChangeOuter(e);
      // Simulate blur on change
      onBlurGlobal(e);
    },
    [onBlurGlobal, onChangeOuter]
  );

  return <Checkbox onChange={onChange} {...rest} />;
}

export const FormItemInlineCheckbox = props => (
  <FormItemInlineCheckable {...props} component={ExtendedCheckbox} />
);

function CheckableGroup({
  component: C,
  name,
  placeholderId,
  placeholder,
  formItemComponentProps,
  options,
  layout,
  disabled,
  ...rest
}) {
  const plainOptions = useTranslatedOptions(options);
  const className = classNames(formItemComponentProps.className, {
    'CheckableGroup--Vertical': layout === 'vertical',
  });
  return (
    <Form.Item name={name} {...rest}>
      <C
        {...formItemComponentProps}
        name={name}
        className={className}
        options={plainOptions}
        disabled={disabled}
      />
    </Form.Item>
  );
}

function FormItemCheckboxGroup({ className, formItemComponentProps, ...rest }) {
  return (
    <CheckableGroup
      component={Checkbox.Group}
      layout="vertical"
      formItemComponentProps={{ ...formItemComponentProps, className }}
      {...rest}
    />
  );
}
export const FormItemEnumCheckboxGroup = withEnumOptions(FormItemCheckboxGroup);

// Simple rewrite of ant's RadioGroup - that one is buggy (onChange is not called)
function RadioGroup({
  name,
  className,
  options,
  value: selectedValueOuter,
  onChange: onChangeOuter,
  formatValue,
  parseValue,
  disabledOuter,
}) {
  const { value: selectedValue, onChange } = useFormValueDecorator({
    value: selectedValueOuter,
    onChange: onChangeOuter,
    formatValue,
    parseValue,
  });

  const overrideContext = useContext(FormItemOverrideContext);
  const disabled =
    disabledOuter || overrideContext.disabled || overrideContext.viewMode;

  const onRadioChange = useCallback(
    e => {
      onChange(extractValueFromEvent(e));
    },
    [onChange]
  );

  return (
    <div className={classNames('ant-radio-group', className)}>
      {(options || []).map(
        ({ value, label, disabled: disabledOne, tooltip, tooltipId }) => (
          <Tooltip
            key={value}
            title={tooltipId ? <FormattedMessage id={tooltipId} /> : tooltip}
          >
            <Radio
              name={name}
              value={value}
              onChange={onRadioChange}
              checked={value === selectedValue}
              disabled={disabled || disabledOne}
            >
              {label}
            </Radio>
          </Tooltip>
        )
      )}
    </div>
  );
}

export function FormItemRadioGroup({
  className,
  formItemComponentProps,
  formatValue,
  parseValue,
  ...rest
}) {
  return (
    <CheckableGroup
      component={RadioGroup}
      formItemComponentProps={{
        ...formItemComponentProps,
        className,
        formatValue,
        parseValue,
      }}
      {...rest}
    />
  );
}
export const FormItemEnumRadioGroup = withEnumOptions(FormItemRadioGroup);

const YesNo = {
  YES: 'yes',
  NO: 'no',
};
const YES_NO_PROPS = {
  options: [
    { value: YesNo.YES, labelId: 'form.labels.yes' },
    { value: YesNo.NO, labelId: 'form.labels.no' },
  ],
  parseValue: optionalDecorator(val => val === YesNo.YES),
  formatValue: optionalDecorator(val => (val ? YesNo.YES : YesNo.NO)),
};
export const FormItemYesNo = props => (
  <FormItemRadioGroup {...props} {...YES_NO_PROPS} />
);
export const FormItemYesNoSelect = props => (
  <FormItemSelect {...props} {...YES_NO_PROPS} />
);

export const YesNoFormat = ({ value, ...rest }) =>
  typeof value === 'boolean' ? (
    <span {...rest}>
      <FormattedMessage id={value ? 'form.labels.yes' : 'form.labels.no'} />
    </span>
  ) : (
    EMPTY_VALUE
  );
