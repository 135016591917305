import { noop } from 'lodash-es';
import { createContext, useCallback, useContext, useState } from 'react';

export const FormSanitizationContext = createContext({
  asciiWarningShown: false,
  setAsciiWarningShown: noop,
});

export function FormSanitizationProvider({ children }) {
  const { setAsciiWarningShown: setParentAsciiWarningShown } = useContext(
    FormSanitizationContext
  );

  const [asciiWarningShown, setRawAsciiWarningShown] = useState(false);
  const setAsciiWarningShown = useCallback(() => {
    setRawAsciiWarningShown(true);
    setParentAsciiWarningShown();
  }, [setParentAsciiWarningShown]);

  return (
    <FormSanitizationContext.Provider
      value={{ asciiWarningShown, setAsciiWarningShown }}
    >
      {children}
    </FormSanitizationContext.Provider>
  );
}
