import classNames from 'classnames';

import { withMergedClass } from '../../utils/reactUtils';

const InlineDialogIcon = withMergedClass('InlineDialog-Icon')('div');

export default function InlineDialog({
  children,
  className,
  title,
  titleId,
  icon,
  visible = true,
  ...rest
}) {
  return (
    <div className={classNames('InlineDialog', className)} {...rest}>
      {icon && <InlineDialogIcon>{icon}</InlineDialogIcon>}
      {children}
    </div>
  );
}
