import { ApolloProvider } from '@apollo/client';
import { ConfigProvider as AntdProvider } from 'antd';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import AppRoutes from './app/AppRoutes';
import { getLanguage } from './app/redux/settings';
import { apolloClient, store } from './appSingletons';
import ErrorBoundary from './components/ErrorBoundary';
import FlashMessageProvider from './components/dialogs/FlashMessageProvider';
import { UserSettingsContextProvider } from './components/domainSpecific/settingsElements';
import { FormSanitizationProvider } from './components/forms/FormSanitizationContext';
import StatePreparedGuard from './components/guards/StatePreparedGuard';
import { FormConfigProvider } from './config/intl/FormConfigProvider';
import { getLanguageData } from './config/intl/intl';
import ServiceDeskWidget from './containers/ServiceDeskWidget';
import { LoginStateWatcher } from './containers/auth/LoginStateWatcher';
import PageExitDialog, {
  usePageExitDialogState,
} from './containers/nav/PageExitDialog';
import VersionIncompatibilityDialog from './dialogs/VersionIncompatibilityDialog';

function App() {
  const [pageExitDialogProps, getUserConfirmation] = usePageExitDialogState();

  const langData = getLanguageData(getLanguage(store.getState()));

  return (
    <IntlProvider locale={langData.locale} messages={langData.messages}>
      <ErrorBoundary apolloClient={apolloClient}>
        <AntdProvider locale={langData.antLocale}>
          <PageExitDialog {...pageExitDialogProps} />
          <FormConfigProvider>
            <ReduxProvider store={store}>
              <ApolloProvider client={apolloClient}>
                <FlashMessageProvider>
                  <FormSanitizationProvider>
                    <StatePreparedGuard
                      initializerComponents={[LoginStateWatcher]}
                    >
                      <UserSettingsContextProvider>
                        <ErrorBoundary>
                          <ServiceDeskWidget />
                          <VersionIncompatibilityDialog />
                        </ErrorBoundary>
                        <BrowserRouter
                          getUserConfirmation={getUserConfirmation}
                        >
                          <AppRoutes />
                        </BrowserRouter>
                      </UserSettingsContextProvider>
                    </StatePreparedGuard>
                  </FormSanitizationProvider>
                </FlashMessageProvider>
              </ApolloProvider>
            </ReduxProvider>
          </FormConfigProvider>
        </AntdProvider>
      </ErrorBoundary>
    </IntlProvider>
  );
}

export default App;
