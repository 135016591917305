import { get, isNil, pickBy } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import { DateFormatValues } from '../../../../app/enums/DateFormat';
import { UnitSystemValues } from '../../../../app/enums/measuringUnits';
import {
  FormItemEnumCheckboxGroup,
  FormItemEnumRadioGroup,
  FormItemRadioGroup,
} from '../../../../components/forms/checkable';
import { useFormContext } from '../../../../components/forms/forms';
import {
  FormItemEnumSelect,
  FormItemSelect,
} from '../../../../components/forms/selects';
import AdminConsoleSection, {
  AdminConsoleSectionTable,
  AdminConsoleSectionTitleHeader,
} from '../../components/AdminConsoleSection';

function FormItemSingleOption({
  name,
  options,
  intlPrefix,
  intlValues,
  viewMode,
  ...rest
}) {
  let Component;
  const props = pickBy({ options, intlPrefix, intlValues }, v => !isNil(v));
  if (viewMode) {
    Component = intlPrefix && intlValues ? FormItemEnumSelect : FormItemSelect;
    Object.assign(props, { floatingLabel: false });
  } else {
    Component =
      intlPrefix && intlValues ? FormItemEnumRadioGroup : FormItemRadioGroup;
    Object.assign(props, { layout: 'vertical' });
  }
  return <Component name={name} {...props} {...rest} />;
}

function FormItemMultipleOptions({
  name,
  viewMode,
  intlPrefix,
  intlValues,
  editModeProps,
}) {
  const { values } = useFormContext();
  const value = get(values, name);

  if (viewMode) {
    return (
      <div className="spaces-vert-norm">
        {(value || []).map(val => (
          <div key={val}>
            <FormItemEnumSelect
              floatingLabel={false}
              intlPrefix={intlPrefix}
              intlValues={intlValues}
              formItemComponentProps={{ value: val }}
            />
          </div>
        ))}
      </div>
    );
  }
  return (
    <FormItemEnumCheckboxGroup
      name={name}
      intlPrefix={intlPrefix}
      intlValues={intlValues}
      layout="vertical"
      {...editModeProps}
    />
  );
}

export default function AdminUserSpecificSettingsFormSection({ viewMode }) {
  return (
    <AdminConsoleSection>
      <AdminConsoleSectionTitleHeader>
        <strong>
          <FormattedMessage id="admin.userManagement.forms.userSpecificSettings" />
        </strong>
      </AdminConsoleSectionTitleHeader>
      <AdminConsoleSectionTable
        columnsClassName="AdminUserForms__UserSpecificSettingsColumns"
        header={
          <>
            <div>
              <FormattedMessage id="admin.userManagement.forms.dateFormat" />
            </div>
            <div>
              <FormattedMessage id="admin.userManagement.forms.unitSystem" />
            </div>
            <div>
              <FormattedMessage id="admin.userManagement.forms.reportsAccess" />
            </div>
            <div>
              <span className="AdminConsoleSection__Tba AdminConsoleSection__Tba--Right">
                <FormattedMessage id="admin.userManagement.forms.addressBookPermissions" />
              </span>
            </div>
          </>
        }
      >
        <div>
          <FormItemSingleOption
            name={['settings', 'dateFormat']}
            options={DateFormatValues}
            viewMode={viewMode}
            rules={[{ required: true }]}
          />
        </div>
        <div>
          <FormItemSingleOption
            name={['settings', 'unitSystem']}
            intlPrefix="settings.unitSystem"
            intlValues={UnitSystemValues}
            viewMode={viewMode}
            rules={[{ required: true }]}
          />
        </div>
        <div>
          <FormItemSingleOption
            name="reportsEnabled"
            intlPrefix="settings.reportsAccess"
            intlValues={[true, false]}
            viewMode={viewMode}
            rules={[{ required: true }]}
          />
        </div>
        <div>
          <FormItemMultipleOptions
            name={['settings', 'addressBookPermissions']}
            intlPrefix="admin.userManagement.forms.addressBookPermissions"
            intlValues={['SINGLE', 'MULTIPLE']}
            viewMode={viewMode}
            editModeProps={{ disabled: true }}
          />
        </div>
      </AdminConsoleSectionTable>
    </AdminConsoleSection>
  );
}
