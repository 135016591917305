import { useQuery } from '@apollo/client';
import { compact } from 'lodash-es';

import { USER_QUERY } from '../app/graphql/authQueries';
import { extractGraphqlEntity } from '../common/utils/graphqlUtils';
import { ThreeLines } from '../components/layout/layoutElements';
import { LinksRow } from '../components/nav/navElements';
import { accountMenuLinks } from './AccountTopBar';
import Footer, { FooterTheme } from './Footer';
import UserSettingsDialog from './UserSettingsDialog';

export default function LoggedFooter() {
  const { loading, data } = useQuery(USER_QUERY);
  const user = data && extractGraphqlEntity(data);
  return (
    <>
      <div className="LoggedFooter-LinksAbove">
        <ThreeLines />
        <div className="hide-md-and-bigger LoggedFooter-Links">
          <LinksRow
            links={compact([
              !loading && <UserSettingsDialog key="settings" user={user} />,
              ...accountMenuLinks,
            ])}
          />
        </div>
      </div>
      <Footer theme={FooterTheme.DARK} />
    </>
  );
}
