import { LogLevel, LogLevels } from '../app/enums/LogLevel';
import { config } from '../config/config';

const logPriority = LogLevels.findIndex(level => config.logLevel === level);

const shouldLog = level => LogLevels.findIndex(l => level === l) >= logPriority;

const logDebug = shouldLog(LogLevel.DEBUG);
const logInfo = shouldLog(LogLevel.INFO);
const logWarn = shouldLog(LogLevel.WARN);
const logError = shouldLog(LogLevel.ERROR);

export const log = {
  error(...args) {
    if (logError) {
      console.error('[ERROR]', ...args);
    }
  },
  warn(...args) {
    if (logWarn) {
      console.warn('[WARN]', ...args);
    }
  },
  info(...args) {
    if (logInfo) {
      console.log('[INFO]', ...args);
    }
  },
  debug(...args) {
    if (logDebug) {
      console.log('[DEBUG]', ...args);
    }
  },
};
