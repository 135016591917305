import { Button, Space } from 'antd';
import { isEqual } from 'lodash-es';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import {
  SubmitButton,
  useFormContext,
} from '../../../../components/forms/forms';
import { cssVariables } from '../../../../styles/cssVariables';
import { pxToNumber } from '../../../../utils/cssUtils';

export function AdminConsoleDetailHeaderButtons({
  viewMode,
  setViewMode,
  initialValues,
  headerRightRef,
  sectionHomeRoute,
  formToGraphql,
}) {
  const { values, formInstance, forceUpdate, triggerSubmit } = useFormContext();
  const { push } = useHistory();
  const isChanged = !isEqual(
    formToGraphql(values),
    formToGraphql(initialValues)
  );

  return (
    headerRightRef.current &&
    createPortal(
      <Space direction="horizontal" size={pxToNumber(cssVariables.spaceNorm)}>
        <Button
          ghost
          size="small"
          className="width-120"
          onClick={() => {
            if (viewMode) {
              push(sectionHomeRoute);
            } else {
              formInstance.setFieldsValue(initialValues);
              forceUpdate();
              setViewMode(true);
            }
          }}
        >
          <FormattedMessage id="buttons.cancel" />
        </Button>
        {viewMode ? (
          <Button
            type="primary"
            size="small"
            className="width-120"
            onClick={() => setViewMode(false)}
          >
            <FormattedMessage id="buttons.edit" />
          </Button>
        ) : (
          <SubmitButton
            type="primary"
            size="small"
            className="width-120"
            disabled={!isChanged}
            onClick={triggerSubmit}
          >
            <FormattedMessage id="buttons.submit" />
          </SubmitButton>
        )}
      </Space>,
      headerRightRef.current
    )
  );
}
