import { Col, Row } from 'antd';

import { cssVariables } from '../../styles/cssVariables';
import { pxToNumber } from '../../utils/cssUtils';
import AdminConsoleTitle from './components/AdminConsoleTitle';
import AdminConsoleTemplate from './layout/AdminConsoleTemplate';

export default function AdminBusinessTypePage() {
  return (
    <AdminConsoleTemplate
      title={
        <AdminConsoleTitle
          titleId="admin.businessType.title"
          icon="file-invoice"
        />
      }
    >
      <Row align="top" gutter={pxToNumber(cssVariables.spaceNorm2)}>
        <Col>{}</Col>
        <Col className="Flex1">TODO</Col>
      </Row>
    </AdminConsoleTemplate>
  );
}
