import { isNumber } from 'lodash-es';

export function pxToNumber(pxString) {
  if (isNumber(pxString)) {
    return pxString;
  }
  const chunks = (pxString || '').match(/^(-?[0-9\\.]+)px$/);
  return chunks && Number.parseInt(chunks[1], 10);
}

export function msToNumber(msString) {
  if (isNumber(msString)) {
    return msString;
  }
  const chunks = (msString || '').match(/^(-?[0-9\\.]+)ms$/);
  return chunks && Number.parseInt(chunks[1], 10);
}

export function pointsToCssPoints(points) {
  return points.map(({ x, y }) => `${x} ${y}`).join(',');
}
