import { ConfigProvider } from 'antd';
import { useIntl } from 'react-intl';

import { mapValuesDeep } from '../../common/utils/funcUtils';

// See src/app/node_modules/rc-field-form/lib/utils/messages.d.ts for defaults
const DEFAULT_VALIDATE_MESSAGE_IDS = {
  required: 'error.required',
  types: {
    email: 'error.invalidEmail',
  },
};

export function FormConfigProvider({ children }) {
  const intl = useIntl();
  const validateMessages = mapValuesDeep(DEFAULT_VALIDATE_MESSAGE_IDS, id =>
    intl.formatMessage({ id })
  );
  return (
    <ConfigProvider form={{ validateMessages }}>{children}</ConfigProvider>
  );
}
