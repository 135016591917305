import { isEmpty, last } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router';

import { USERS_LIST_QUERY } from '../../../app/graphql/adminQueries';
import { getUsersFilter, setUsersFilter } from '../../../app/redux/admin';
import routes from '../../../app/routes';
import { FAIcon } from '../../../components/adapters/fontAwesomeAdapters';
import AdminConsoleList, {
  AdminConsoleListDataSection,
  AdminConsoleListFiltersSection,
} from '../common/AdminConsoleList';

const FILTER_BY_OPTIONS = [
  { value: 'firstName', labelId: 'admin.userManagement.userList.firstName' },
  { value: 'lastName', labelId: 'admin.userManagement.userList.lastName' },
  { value: 'email', labelId: 'admin.userManagement.userList.email' },
];

const ACTIVE_OPTIONS = [
  { value: true, labelId: 'admin.userManagement.userList.active.true' },
  { value: false, labelId: 'admin.userManagement.userList.active.false' },
  { value: undefined, labelId: 'admin.userManagement.userList.active.all' },
];

function FiltersSection() {
  return (
    <AdminConsoleListFiltersSection
      activeOptions={ACTIVE_OPTIONS}
      defaultActiveOption={last(ACTIVE_OPTIONS)}
      filterByOptions={FILTER_BY_OPTIONS}
      renderTag={({ filterBy }) => (
        <FormattedMessage id={`admin.userManagement.userList.${filterBy}`} />
      )}
    />
  );
}

const HEADER_COLUMNS = [
  {
    key: 'firstName',
    labelId: 'admin.userManagement.userList.firstName',
    sortName: 'firstName',
  },
  {
    key: 'lastName',
    labelId: 'admin.userManagement.userList.lastName',
    sortName: 'lastName',
  },
  {
    key: 'email',
    labelId: 'admin.userManagement.userList.email',
    sortName: 'email',
  },
  { key: 'accounts', labelId: 'admin.userManagement.userList.accounts' },
  { key: 'active', labelId: 'admin.userManagement.userList.active' },
];

const GET_ROW_LINK = (user, detailBaseRoute) =>
  generatePath(detailBaseRoute, { userId: user.id });

const renderColumns = user => [
  { key: 'firstName', label: user.firstName },
  { key: 'lastName', label: user.lastName },
  { key: 'email', label: user.email },
  { key: 'accountsCount', label: user.accountsCount },
  {
    key: 'active',
    label: user.active && <FAIcon icon="check-circle" className="icon-16" />,
  },
];

function DataSection({ detailBaseRoute }) {
  return (
    <AdminConsoleListDataSection
      paginationSuffix="admin.userManagement.userList.paginationSuffix"
      headerColumns={HEADER_COLUMNS}
      getRowLink={user => GET_ROW_LINK(user, detailBaseRoute)}
      renderColumns={renderColumns}
      columnClassName="AdminUserList__Column"
    />
  );
}

const SHOULD_SKIP = filter =>
  ['search', ...FILTER_BY_OPTIONS.map(item => item.value)].every(key =>
    isEmpty(filter?.[key])
  );

export default function AdminUserList({
  detailBaseRoute = routes.admin.userDetail,
  filterBase,
}) {
  return (
    <AdminConsoleList
      titleId="admin.userManagement.userList"
      titleIcon="address-book"
      defaultSortColumn="firstName"
      shouldSkip={SHOULD_SKIP}
      query={USERS_LIST_QUERY}
      graphqlEntityName="users"
      graphqlResultTypename="UsersResult"
      getFilterSelector={getUsersFilter}
      setFilterActionCreator={setUsersFilter}
      decorateFilter={
        filterBase ? filter => ({ ...filterBase, ...filter }) : undefined
      }
    >
      <FiltersSection />
      <DataSection detailBaseRoute={detailBaseRoute} />
    </AdminConsoleList>
  );
}
