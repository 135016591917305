import { Steps } from 'antd';
import classNames from 'classnames';
import { fromPairs, get, noop } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { numberOr } from '../../common/utils/numberUtils';
import { FAIcon, FATypes } from '../adapters/fontAwesomeAdapters';
import { useResponsiveQueries } from '../responsive/responsiveQueries';
import SegmentedControl from './SegmentedControl';
import { SlidingContent } from './SlidingContent';

function renderStep({ name, titleId, active, finished, disabled }) {
  let icon;
  if (finished) {
    icon = <FAIcon icon="check" className="size-xs" />;
  } else if (active) {
    icon = <FAIcon icon="circle" type={FATypes.SOLID} className="size-xs" />;
  }
  return (
    <Steps.Step
      key={name}
      title={<FormattedMessage id={titleId} />}
      icon={icon}
      disabled={disabled}
    />
  );
}

const StepsCarousel = ({ step, steps, goToNext }) => (
  <SlidingContent
    listenToSelected
    selected={step}
    data={steps}
    sharedProps={{ goToNext }}
    autoHeight
  />
);
export function StepsWithContent({
  className,
  stepsClassName,
  segmentedControlClassName,
  step,
  setStep,
  steps,
  currentIndex,
  goToNext,
  clickable,
}) {
  const media = useResponsiveQueries();
  const isLg = media.lg || media.xl || media.xxl;

  return (
    <div className={classNames('StepsWithContent', className)}>
      {isLg ? (
        <Steps
          className={stepsClassName}
          current={currentIndex}
          onChange={clickable ? i => setStep(steps[i].name) : undefined}
          direction="horizontal"
          labelPlacement="vertical"
          size="default"
          status="process"
        >
          {steps.map((info, i) =>
            renderStep({
              ...info,
              active: i === currentIndex,
              finished: i < currentIndex,
            })
          )}
        </Steps>
      ) : (
        <SegmentedControl
          className={segmentedControlClassName}
          value={step}
          onChange={clickable ? setStep : noop}
          options={steps.map(({ shortTitleId, name, disabled }) => ({
            labelId: shortTitleId,
            value: name,
            disabled,
          }))}
        />
      )}
      <StepsCarousel
        steps={steps}
        step={step}
        currentIndex={currentIndex}
        goToNext={goToNext}
      />
    </div>
  );
}

export function isLastStep({ steps, currentIndex }) {
  return currentIndex === steps.length - 1;
}
export function isFirstStep({ currentIndex }) {
  return currentIndex === 0;
}

function useStepControls({ step, setStep, setInitStep, steps: rawSteps }) {
  const steps = rawSteps.map((info, i) => ({
    ...info,
    index: i,
  }));
  const stepsByName = fromPairs(steps.map(s => [s.name, s]));
  const currentIndex = numberOr(get(stepsByName, `${step}.index`), 0);
  const goToNext = useCallback(() => {
    if (!isLastStep({ steps, currentIndex })) {
      setStep(steps[currentIndex + 1].name);
    }
  }, [currentIndex, setStep, steps]);
  const goToPrev = useCallback(() => {
    if (!isFirstStep({ steps, currentIndex })) {
      setStep(steps[currentIndex - 1].name);
    }
  }, [currentIndex, setStep, steps]);

  // Set step if not defined
  useEffect(() => {
    if (!step || !stepsByName[step]) {
      const initSetter = setInitStep || setStep;
      initSetter(steps[0].name);
    }
  }, [setInitStep, setStep, step, steps, stepsByName]);

  return { steps, currentIndex, goToNext, goToPrev };
}

export function useInternalStepsState(steps) {
  const [step, setStep] = useState();
  return { ...useStepControls({ step, setStep, steps }), step, setStep };
}
