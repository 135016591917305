import { useQuery } from '@apollo/client';

import { GPS_DEVICES_QUERY } from '../../../../app/graphql/adminQueries';
import AdminAccountTransferPage from './AdminAccountTransferPage';

const AdminAccountGPSDevices = ({ account, viewMode, visible }) => {
  const { data, loading } = useQuery(GPS_DEVICES_QUERY, {
    skip: !visible,
  });

  return (
    <AdminAccountTransferPage
      account={account}
      viewMode={viewMode}
      visible={visible}
      titleIcon="location-arrow"
      titleId="admin.accountManagement.accountDetail.gpsDevices"
      itemName="gpsDevicesSettings"
      titlesPrefix="admin.accountManagement.accountDetail.gps"
      data={data?.gpsDevices}
      loading={loading}
    />
  );
};

export default AdminAccountGPSDevices;
