import { Checkbox, Tooltip, Transfer } from 'antd';
import {
  differenceWith,
  groupBy,
  includes,
  isEmpty,
  map,
  size,
  some,
} from 'lodash-es';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { normalizeString } from '../../common/utils/stringUtils';
import { FAIcon } from '../adapters/fontAwesomeAdapters';
import { Scrollbars } from '../layout/Scrollbars';
import SolidDivider from '../layout/SolidDivider';
import { FormItem } from './FormItem';

const TransferSectionHeader = ({
  children,
  onSelected,
  isEditing,
  checked,
}) => (
  <div className="transfer-header">
    {isEditing ? (
      <Checkbox checked={checked} onChange={onSelected}>
        {children}
      </Checkbox>
    ) : (
      children
    )}
  </div>
);

function SelectableRow({ item, selected, onSelected, isEditing }) {
  return (
    <div key={item.key} className="transfer-row ellipsis">
      {isEditing ? (
        <Checkbox
          checked={selected}
          onChange={() => {
            onSelected(item);
          }}
        >
          <div>
            <Tooltip title={item.title}>{item.title}</Tooltip>
          </div>
        </Checkbox>
      ) : (
        <Tooltip title={item.title}>{item.title}</Tooltip>
      )}
    </div>
  );
}

function TransferSection({
  header,
  items,
  listSelectedKeys,
  onSelected,
  onSelectAll,
  isEditing,
  scrollbarsProps,
  className,
}) {
  const unchecked = differenceWith(
    items,
    listSelectedKeys,
    (a, b) => a.key === b
  );
  const allSelected = some(items) && isEmpty(unchecked);

  return (
    <div className={className}>
      <TransferSectionHeader
        onSelected={onSelectAll}
        isEditing={isEditing}
        checked={allSelected}
      >
        {header} ({size(items)})
      </TransferSectionHeader>
      <div className="transfer-section-scrollbars">
        <Scrollbars {...scrollbarsProps}>
          {map(items, n => (
            <SelectableRow
              key={n.key}
              item={n}
              onSelected={onSelected}
              selected={some(listSelectedKeys, k => k === n.key)}
              isEditing={isEditing}
            />
          ))}
        </Scrollbars>
      </div>
    </div>
  );
}

const TransferInner = ({
  selectedKeys,
  disabled: viewMode,
  titles,
  value,
  onChange,
  getSection,
  transferSections,
  updateSelection,
  getTargetKeys = keys => keys,
  ...props
}) => {
  const intl = useIntl();

  const onItemsMove = (_values, direction, movedKeys) => {
    const newValues = updateSelection(value, direction, movedKeys);
    if (newValues) onChange(newValues);
  };

  const targetKeys = getTargetKeys(value);

  const isFiltered = (inputValue, option) =>
    includes(normalizeString(option.title), normalizeString(inputValue));

  return (
    <Transfer
      targetKeys={targetKeys}
      selectedKeys={selectedKeys}
      render={item => item.title}
      showSelectAll={false}
      showSearch
      operations={[
        <FAIcon icon="chevron-right" />,
        <FAIcon icon="chevron-left" />,
      ]}
      titles={map(titles, title => intl.formatMessage({ id: title }))}
      onChange={onItemsMove}
      filterOption={isFiltered}
      {...props}
    >
      {({
        direction,
        filteredItems,
        onItemSelectAll,
        onItemSelect,
        selectedKeys: listSelectedKeys,
      }) => {
        const isSelected = item => some(selectedKeys, key => item.key === key);

        const onItemSelectFunc = it => {
          onItemSelect(it.key, !isSelected(it));
        };

        const onAllItemSelectFunc = items => {
          const select = some(
            differenceWith(items, selectedKeys, (i, key) => i.key === key)
          );
          onItemSelectAll(
            map(items, s => s.key),
            select
          );
        };

        const groupedItems = groupBy(filteredItems, item => getSection(item));
        const sectionsCount = size(transferSections);
        return map(transferSections, (section, ix) => (
          <>
            <TransferSection
              header={
                direction === 'left' ? section.leftHeader : section.rightHeader
              }
              listSelectedKeys={listSelectedKeys}
              onSelected={onItemSelectFunc}
              items={groupedItems[section.key]}
              isEditing={!viewMode}
              onSelectAll={() => {
                onAllItemSelectFunc(groupedItems[section.key]);
              }}
              scrollbarsProps={section.scrollbarsConfig}
              className={section.className}
            />
            {sectionsCount > 1 && ix < sectionsCount - 1 && <SolidDivider />}
          </>
        ));
      }}
    </Transfer>
  );
};

const TransferFormItem = ({ name, ...rest }) => {
  const [selectedKeys, setSelectedKeys] = useState([]);

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  return (
    <FormItem
      name={name}
      component={TransferInner}
      formItemComponentProps={{
        selectedKeys,
        onSelectChange,
        ...rest,
      }}
    />
  );
};

export default TransferFormItem;
