import { InfoWindow } from '@react-google-maps/api';
import { noop } from 'lodash-es';
import { createContext, useContext, useState } from 'react';

import { isTouchDevice } from '../../../common/utils/deviceUtils';

const GoogleMapHoverContext = createContext({});
export function GoogleMapsTooltipProvider({ children }) {
  const [anchor, setAnchor] = useState();
  const [tooltipContent, setTooltipContent] = useState();

  const onMouseOver = (_anchor, content) => {
    setAnchor(_anchor);
    setTooltipContent(content);
  };
  const onMouseOut = () => {
    setAnchor(undefined);
    setTooltipContent(undefined);
  };

  return (
    <>
      {anchor && tooltipContent && (
        <InfoWindow anchor={anchor}>
          <div>{tooltipContent}</div>
        </InfoWindow>
      )}
      <GoogleMapHoverContext.Provider value={{ onMouseOver, onMouseOut }}>
        {children}
      </GoogleMapHoverContext.Provider>
    </>
  );
}

export function useGoogleMapTooltip({
  getContent,
  onMouseOver: onMouseOverOuter,
  onMouseOut: onMouseOutOuter,
  getElement,
}) {
  const { onMouseOver: tooltipOnMouseOver, onMouseOut: tooltipOnMouseOut } =
    useContext(GoogleMapHoverContext);

  const onMouseOver =
    getContent || onMouseOverOuter
      ? e => {
          const element = getElement(e);
          getContent && tooltipOnMouseOver(element, getContent(e));
          onMouseOverOuter && onMouseOverOuter(e);
        }
      : noop;
  const onMouseOut =
    getContent || onMouseOutOuter
      ? e => {
          getContent && tooltipOnMouseOut();
          onMouseOutOuter && onMouseOutOuter(e);
        }
      : noop;
  const onMouseDown = isTouchDevice() ? onMouseOver : noop;
  const onMouseUp = isTouchDevice() ? onMouseOut : noop;

  return { onMouseOver, onMouseOut, onMouseDown, onMouseUp };
}
