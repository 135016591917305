import { Form } from 'antd';
import { createContext, useContext } from 'react';

import { useFormContext } from './forms';

const FormListContext = createContext({ name: null, parentForm: null });

export function useParentFormListName() {
  const { formInstance } = useFormContext();
  const { name, parentForm } = useContext(FormListContext);

  if (parentForm !== formInstance) {
    // If there is a nested form inside a list, name prefix loses importance
    return null;
  }

  return name;
}

export default function FormList({ name, ...rest }) {
  const { formInstance } = useFormContext();
  return (
    <FormListContext.Provider value={{ name, parentForm: formInstance }}>
      <Form.List name={name} {...rest} />
    </FormListContext.Provider>
  );
}
