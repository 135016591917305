import {
  formToInputJobLocation,
  graphQLToFormJobLocation,
} from './locationConversions';

export function formToInputAddressBookEntry(form, accountNumber) {
  return {
    accountNumber,
    ...formToInputJobLocation(form),
    keyword: form.keyword,
  };
}

export function graphQLToFormAddressBookEntry(data) {
  return {
    ...graphQLToFormJobLocation(data),
    keyword: data.keyword,
  };
}
