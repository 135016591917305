import { find, flow, isArray } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import { optionalDecorator } from '../../common/utils/funcUtils';

export function flattenSelectOptions(options) {
  if (!isArray(options)) {
    return [];
  }
  return options.flatMap(opt =>
    opt.options ? flattenSelectOptions(opt.options) : opt
  );
}

export function mapSelectOptions(options, mapper) {
  return options.map(opt =>
    opt.options
      ? { ...opt, options: mapSelectOptions(opt.options, mapper) }
      : mapper(opt)
  );
}

export function findSelectOption(value, options) {
  return find(flattenSelectOptions(options), opt => opt.value === value);
}

function printSelectOption({ label, labelId }) {
  return labelId ? <FormattedMessage id={labelId} /> : label;
}

export const findAndPrintSelectOption = flow(
  findSelectOption,
  optionalDecorator(printSelectOption)
);

export const selectOptionToCodeValue = codeValue => ({
  code: codeValue.value,
  text: codeValue.label,
});
export const codeValueToRichSelectOption = codeValue => ({
  value: codeValue,
  label: codeValue.text,
});
export const codeValueToSelectOption = codeValue => ({
  value: codeValue.code,
  label: codeValue.text,
});
export const richSelectValueToCodeValueInput = value =>
  value?.code || value?.text // If free-text is allowed, it's represented as a code-less option
    ? {
        code: value.code,
        text: value.text, // Will be discarded most of the time but may be used sometimes
      }
    : null;
export const richSelectValueToCodeValue = value => ({
  code: value?.code,
  text: value?.text,
});
