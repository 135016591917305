import { Col, Row, Typography } from 'antd';

import {
  FormTheme,
  FormThemeOverrideContextProvider,
} from '../../../../components/forms/formTheme';
import { cssVariables } from '../../../../styles/cssVariables';
import { pxToNumber } from '../../../../utils/cssUtils';
import AccountsPicker from '../../../../widgets/AccountsPicker';
import AdminConsoleSection, {
  AdminConsoleSectionContent,
  AdminConsoleSectionTitleHeader,
} from '../../components/AdminConsoleSection';
import AdminConsoleContent from '../../layout/AdminConsoleContent';
import { formatAccountLabel } from '../../utils/adminFormatting';
import { AccountBusinessTypeField } from './AdminAccountDetailCommon';

const AdminAccountAddressBookSharing = ({ account, viewMode, visible }) => {
  const renderAccount = acc => (
    <div>
      {acc.number} | {acc.name}
    </div>
  );

  return (
    <AdminConsoleContent
      titleIcon="file-invoice"
      titleId="admin.accountManagement.accountDetail.addressBookSharing"
      entityLabel={formatAccountLabel(account)}
      isEditing={!viewMode}
      visible={visible}
      headerRight={<AccountBusinessTypeField />}
    >
      <FormThemeOverrideContextProvider themeClassName={FormTheme.DarkSimple}>
        <AdminConsoleSection>
          <AdminConsoleSectionTitleHeader>
            <Typography.Title level={3}>
              {formatAccountLabel(account, { long: true })}
            </Typography.Title>
          </AdminConsoleSectionTitleHeader>

          <AdminConsoleSectionContent>
            <AdminConsoleSection>
              <AdminConsoleSectionContent>
                <Row
                  align="middle"
                  justify="space-between"
                  gutter={[
                    pxToNumber(cssVariables.spaceXl),
                    pxToNumber(cssVariables.spaceXl),
                  ]}
                >
                  <Col style={{ alignSelf: 'flex-start' }} span={12}>
                    <AccountsPicker
                      account={account}
                      titleIcon="share"
                      name="addressBookSharedWith"
                      renderItem={renderAccount}
                      titleId="admin.accountManagement.accountDetail.addressBook.sharedWith"
                      subtitleId="admin.accountManagement.accountDetail.addressBook.sharing-header"
                    />
                  </Col>
                  <Col style={{ alignSelf: 'flex-start' }} span={12}>
                    <AccountsPicker
                      disabled
                      account={account}
                      titleIcon="share"
                      iconCss="fa-flip-horizontal"
                      name="addressBookSharedBy"
                      renderItem={renderAccount}
                      titleId="admin.accountManagement.accountDetail.addressBook.sharedBy"
                      subtitleId="admin.accountManagement.accountDetail.addressBook.sharing-header"
                    />
                  </Col>
                </Row>
              </AdminConsoleSectionContent>
            </AdminConsoleSection>
          </AdminConsoleSectionContent>
        </AdminConsoleSection>
      </FormThemeOverrideContextProvider>
    </AdminConsoleContent>
  );
};

export default AdminAccountAddressBookSharing;
