import { LogLevel, LogLevels } from '../app/enums/LogLevel';

// This factory function is needed in order to prevent webpack from evaluating process.env at build time. Env variables are injected in runtime using nginx/docker.
function makeConfig(env) {
  let logLevel = env.REACT_APP_CLIENT_LOG_LEVEL;
  if (!LogLevels.includes(logLevel)) {
    console.warn(`Invalid log level ${logLevel}, falling back to INFO`);
    logLevel = LogLevel.INFO;
  }

  return {
    repoUrl: 'https://github.com/Quickintl/quick-online-v2',
    // config from environment variables
    googleMapsApiKey: env.REACT_APP_GOOGLE_MAPS_API_KEY,
    version: env.REACT_APP_VERSION,
    commitHash: env.REACT_APP_COMMIT_HASH,
    commitTimestamp: env.REACT_APP_COMMIT_TIMESTAMP,
    showDebugInfo: env.REACT_APP_SHOW_DEBUG_INFO === 'true',
    logLevel,
  };
}

export const config = makeConfig(process.env);
