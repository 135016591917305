import { useMutation, useQuery } from '@apollo/client';
import { first, toPairs } from 'lodash-es';
import { useCallback, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ErrorCode } from '../../app/common/ErrorCode';
import {
  filterTemplateFormToGraphQL,
  filterTemplateGraphQLToForm,
} from '../../app/data/filterConversions';
import {
  DELETE_SHIPMENT_FILTER_TEMPLATE_MUTATION,
  SAVE_SHIPMENT_FILTER_TEMPLATE_MUTATION,
  SHIPMENT_FILTER_TEMPLATES_QUERY,
  SHIPMENT_STATUS_OPTIONS_QUERY,
} from '../../app/graphql/jobFilterQueries';
import { useDialogControls } from '../../common/utils/dialogUtils';
import {
  deleteQueryFromCache,
  extractGraphqlEntity,
} from '../../common/utils/graphqlUtils';
import { FAIcon, FATypes } from '../../components/adapters/fontAwesomeAdapters';
import { FiltersAndSortContext } from '../../components/data/FiltersAndSortProvider';
import { useAsynchronousAction } from '../../components/data/asyncActionElements';
import { useFlashMessageContext } from '../../components/dialogs/FlashMessageProvider';
import { SimpleConfirmDialog } from '../../components/dialogs/SimpleConfirmDialog';
import { useFormContext } from '../../components/forms/forms';
import { PopupContext } from '../../components/nav/navElements';
import { SaveTemplatePopup } from '../../components/popups/templates/SaveTemplatePopup';
import TemplatesListPopup from '../../components/popups/templates/TemplatesListPopup';
import { useTemplatesActiveItem } from '../../components/popups/templates/templatePopupUtils';
import { InlineLink, LabelWithValue } from '../../components/typography';
import { useDateRangePresets } from '../../config/dateRangeConstants';
import { useAccounts } from '../../hooks/data/auth';
import { DateFormatNumericNoYear } from '../../utils/dateFormatting';
import { extractExceptionErrorCode } from '../../utils/errorUtils';
import { DateRangeFilterPreviewText } from './MonitorFilter';
import { areMonitorFiltersEqual } from './monitorFilterUtils';

const updateQueries = cache => deleteQueryFromCache(cache, 'shipmentFilters');

const XsLabelWithValue = props => (
  <LabelWithValue {...props} size="xs" labelSize="full" nowrap />
);

function ItemPopupContent({ data }) {
  const { customerAccounts } = useAccounts();
  const accountName =
    data.accountNumber &&
    customerAccounts.find(acc => acc.number === data.accountNumber)?.name;
  const { data: statusesData } = useQuery(SHIPMENT_STATUS_OPTIONS_QUERY);
  const shipmentStatus =
    data.shipmentStatus &&
    statusesData &&
    extractGraphqlEntity(statusesData).find(
      st => st.code === data.shipmentStatus
    )?.text;
  const shipmentDetailsPair = first(toPairs(data.shipmentDetails));

  return (
    <>
      <div className="col-1">
        <XsLabelWithValue
          labelId="filters.labels.dateRange"
          text={
            data.dateRange && (
              <DateRangeFilterPreviewText
                DateFormat={DateFormatNumericNoYear}
                value={data.dateRange}
              />
            )
          }
        />
        <XsLabelWithValue
          labelId="filters.labels.status"
          text={shipmentStatus}
        />
      </div>
      <div className="col-2">
        <XsLabelWithValue labelId="filters.labels.account" text={accountName} />
        {shipmentDetailsPair && (
          <XsLabelWithValue
            labelId={`filters.labels.shipmentDetails.${shipmentDetailsPair[0]}`}
            text={shipmentDetailsPair[1]}
          />
        )}
      </div>
    </>
  );
}

function PopupLabel({ activeItem }) {
  return activeItem ? (
    <>
      <div className="flex-center spaces-hor-xs hide-sm-and-smaller">
        <span className="text-uppercase">
          <FormattedMessage id="filters.filterSets.activePrefix" />:
        </span>
        <div
          className="ellipsis text-no-transform"
          style={{ maxWidth: 150 }}
          title={activeItem.name}
        >
          {activeItem.name}
        </div>
      </div>
      <span className="hide-md-and-bigger">
        <FormattedMessage id="filters.filterSets.active" />
      </span>
    </>
  ) : (
    <FormattedMessage id="filters.filterSets" />
  );
}

export function FilterSetsListPopup({ activeItem, ...rest }) {
  const { presetsByKey } = useDateRangePresets();
  const mapItemToForm = useCallback(
    values => filterTemplateGraphQLToForm(values, presetsByKey),
    [presetsByKey]
  );

  const { formInstance, forceUpdate } = useFormContext();
  const {
    filters: { setValues },
  } = useContext(FiltersAndSortContext);
  const onApplyItem = useCallback(
    data => {
      setValues(data);
      formInstance.setFieldsValue(data);
      forceUpdate();
    },
    [forceUpdate, formInstance, setValues]
  );

  const [remove] = useMutation(DELETE_SHIPMENT_FILTER_TEMPLATE_MUTATION, {
    update: updateQueries,
  });
  const removeAction = useAsynchronousAction(remove);

  return (
    <TemplatesListPopup
      removeAction={removeAction}
      mapItemToForm={mapItemToForm}
      columns={[{ name: 'name', titleId: 'filters.filterSets.header.name' }]}
      label={<PopupLabel activeItem={activeItem} />}
      onApplyItem={onApplyItem}
      renderItemPopup={data => <ItemPopupContent {...data} />}
      noItemsMessageId="filters.filterSets.noItems"
      deleteSuccessMessageId="filters.messages.delete.success"
      removeTextId="filters.filterSets.delete.dialog.text"
      activeItem={activeItem}
      query={SHIPMENT_FILTER_TEMPLATES_QUERY}
      {...rest}
    />
  );
}

function SaveFilterSetButton({ disabled, disabledReasonId }) {
  const intl = useIntl();
  return (
    <InlineLink
      textId="filters.saveSet"
      iconBefore={<FAIcon icon="save" type={FATypes.SOLID} />}
      title={disabledReasonId && intl.formatMessage({ id: disabledReasonId })}
      disabled={disabled}
    />
  );
}

function useFilterSetGetGraphQLData() {
  const {
    filters: { values },
  } = useContext(FiltersAndSortContext);

  return vals => filterTemplateFormToGraphQL({ ...values, ...vals });
}

function useSaveFilterSetErrorHandler() {
  const { setOpen } = useContext(PopupContext);
  const { errorMessage } = useFlashMessageContext();
  const { open: openDuplicateNameDialog, ...duplicateNameDialog } =
    useDialogControls();

  return {
    onError: e => {
      if (extractExceptionErrorCode(e) === ErrorCode.DUPLICATE_NAME) {
        openDuplicateNameDialog();
        setOpen(false);
        return;
      }
      errorMessage(e);
    },
    duplicateNameDialog,
  };
}

export function SaveFilterSetPopup({
  className,
  overlayClassName,
  disabled,
  disabledReasonId,
  afterSave,
  ...rest
}) {
  const getGraphQLData = useFilterSetGetGraphQLData();
  const { onError: onCreateError, duplicateNameDialog } =
    useSaveFilterSetErrorHandler();

  const [save] = useMutation(SAVE_SHIPMENT_FILTER_TEMPLATE_MUTATION, {
    update: updateQueries,
  });
  const saveAction = useAsynchronousAction(save);

  return (
    <>
      <SaveTemplatePopup
        disabled={disabled}
        label={
          <SaveFilterSetButton
            disabled={disabled}
            disabledReasonId={disabledReasonId}
          />
        }
        afterSave={afterSave}
        saveAction={saveAction}
        getGraphQLData={getGraphQLData}
        titleId="filters.enterFilterName"
        successMessageId="filters.messages.save.success"
        nameLabelId="filters.labels.filterName"
        onError={onCreateError}
        {...rest}
      />
      <SimpleConfirmDialog
        className="MonitorFilterDuplicateNameDialog size-sm"
        okTextId="buttons.close"
        onOk={duplicateNameDialog.close}
        textId="filters.filterSets.duplicateName.dialog.text"
        open={duplicateNameDialog.isOpen}
      />
    </>
  );
}

export function useMonitorFilterTemplatesActiveItem() {
  const { presetsByKey } = useDateRangePresets();
  const mapItemToForm = useCallback(
    data => filterTemplateGraphQLToForm(data, presetsByKey),
    [presetsByKey]
  );

  const {
    filters: { values: filters },
  } = useContext(FiltersAndSortContext);

  return useTemplatesActiveItem({
    mapItemToForm,
    currentValues: filters,
    isEqual: areMonitorFiltersEqual,
  });
}
