import { Col, Row, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import routes from '../../../app/routes';
import { SmallLogo } from '../../../components/Logo';
import IconButton from '../../../components/buttons/IconButton';
import { cssVariables } from '../../../styles/cssVariables';
import { pxToNumber } from '../../../utils/cssUtils';
import { VersionNumber } from '../../../widgets/metadataWidgets';

export default function AdminConsoleHeader() {
  const { push } = useHistory();

  return (
    <div className="AdminConsoleHeader">
      <Row align="middle" justify="space-between">
        <Col className="AdminConsoleHeader__Left">
          <Space direction="horizontal" size={pxToNumber(cssVariables.spaceSm)}>
            <SmallLogo size="xs" />
            <FormattedMessage id="admin.header.title" />
          </Space>
        </Col>
        <Col className="AdminConsoleHeader__Right">
          <IconButton
            labelId="buttons.exit"
            icon="sign-out-alt"
            size="md"
            onClick={() => push(routes.root)}
          />
        </Col>
      </Row>
      <span className="AdminConsoleHeader__Version">
        <VersionNumber />
      </span>
    </div>
  );
}
