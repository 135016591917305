import { compact, isEmpty, last, property } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router';

import { ACCOUNTS_QUERY } from '../../../app/graphql/accountQueries';
import { getAccountsFilter, setAccountsFilter } from '../../../app/redux/admin';
import routes from '../../../app/routes';
import { FAIcon } from '../../../components/adapters/fontAwesomeAdapters';
import AdminConsoleList, {
  AdminConsoleListDataSection,
  AdminConsoleListFiltersSection,
} from '../common/AdminConsoleList';

const FILTER_BY_OPTIONS = [
  { value: 'city', labelId: 'admin.userManagement.accountList.city' },
  { value: 'state', labelId: 'admin.userManagement.accountList.state' },
  {
    value: 'profile',
    labelId: 'admin.userManagement.accountList.profile',
  },
];

const ACTIVE_OPTIONS = [
  { value: true, labelId: 'admin.userManagement.accountList.active.true' },
  { value: false, labelId: 'admin.userManagement.accountList.active.false' },
  { value: undefined, labelId: 'admin.userManagement.accountList.active.all' },
];

function FiltersSection() {
  return (
    <AdminConsoleListFiltersSection
      activeOptions={ACTIVE_OPTIONS}
      defaultActiveOption={last(ACTIVE_OPTIONS)}
      filterByOptions={FILTER_BY_OPTIONS}
      renderTag={({ filterBy }) => (
        <FormattedMessage id={`admin.userManagement.accountList.${filterBy}`} />
      )}
      placeholderId="admin.accountManagement.search.placeholder"
    />
  );
}

const HEADER_COLUMNS = [
  {
    key: 'accountNumber',
    labelId: 'admin.userManagement.accountList.accountNumber',
    sortName: 'accountNumber',
  },
  {
    key: 'accountName',
    labelId: 'admin.userManagement.accountList.accountName',
    sortName: 'accoutnName',
  },
  {
    key: 'location',
    labelId: 'admin.userManagement.accountList.location',
    sortName: 'location',
  },
  {
    key: 'profile',
    labelId: 'admin.userManagement.accountList.profile',
    sortName: 'profile',
  },
  { key: 'active', labelId: 'admin.userManagement.accountList.active' },
];

const GET_ROW_LINK = account =>
  generatePath(routes.admin.accountDetail.root, {
    accountNumber: account.number,
  });

const renderColumns = account => [
  { key: 'accountNumber', label: account.number },
  { key: 'accountName', label: account.name },
  {
    key: 'location',
    label: compact([
      account.address?.city?.text,
      account.address?.stateProvince?.code,
    ]).join(', '),
  },
  { key: 'profile', label: account.profile },
  {
    key: 'active',
    label: account.active && <FAIcon icon="check-circle" className="icon-16" />,
  },
];

function DataSection() {
  return (
    <AdminConsoleListDataSection
      paginationSuffix="admin.userManagement.accountList.paginationSuffix"
      headerColumns={HEADER_COLUMNS}
      getRowLink={GET_ROW_LINK}
      renderColumns={renderColumns}
      columnClassName="AdminAccountList__Column"
      getKey={property('accountNumber')}
    />
  );
}

const SHOULD_SKIP = filter =>
  ['search', ...FILTER_BY_OPTIONS.map(item => item.value)].every(key =>
    isEmpty(filter?.[key])
  );

export default function AdminAccountList() {
  return (
    <AdminConsoleList
      titleId="admin.accountManagement.accountList"
      titleIcon="address-book"
      defaultSortColumn="accountNumber"
      shouldSkip={SHOULD_SKIP}
      query={ACCOUNTS_QUERY}
      graphqlEntityName="accounts"
      graphqlResultTypename="AccountsResult"
      getFilterSelector={getAccountsFilter}
      setFilterActionCreator={setAccountsFilter}
    >
      <FiltersSection />
      <DataSection />
    </AdminConsoleList>
  );
}
