import { Record } from 'immutable';

import { simpleAction } from '../../common/utils/reduxUtils';
import { Language } from '../../config/intl/intl';

const InitialState = Record({
  language: Language.EN,
});
const INITIAL_STATE = InitialState();

const CHANGE_LANGUAGE = 'SETTINGS/CHANGE_LANGUAGE';

export function settingsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return state.set('language', action.language);
    default:
      return state;
  }
}

export const getLanguage = state => state.settings.language;

export const setLanguage = simpleAction(CHANGE_LANGUAGE, 'language');
