// static names of milestones in addition to flight numbers
import { get } from 'lodash-es';

export const Milestones = {
  PICKUP: 'PICKUP',
  ORIGIN: 'ORIGIN',
  ENROUTE: 'ENROUTE',
  DESTINATION: 'DESTINATION',
  DELIVERY: 'DELIVERY',
};

export function determineActiveFlightMilestone(shipment) {
  const {
    flights = [],
    pickupDateTime,
    deliveryDateTime,
    outForDeliveryDateTime,
  } = shipment;

  if (get(deliveryDateTime, 'actual')) {
    return { milestone: Milestones.DELIVERY, isApproaching: false };
  }

  if (outForDeliveryDateTime) {
    return { milestone: Milestones.DELIVERY, isApproaching: true };
  }

  for (let i = flights.length; i > -1; i--) {
    const flight = flights[i];
    const isFirst = i === 0;
    const isLast = i === flights.length - 1;
    if (get(flight, 'arrival.actual')) {
      if (isLast) {
        return { milestone: Milestones.DESTINATION, isApproaching: false };
      }
      return { milestone: flights[i + 1].number, isApproaching: true };
    }
    if (get(flight, 'departure.actual')) {
      if (isFirst) {
        return { milestone: Milestones.ORIGIN, isApproaching: false };
      }
      return { milestone: flights[i].number, isApproaching: false };
    }
  }

  if (get(flights, '0.tenderDateTime')) {
    return { milestone: Milestones.ORIGIN, isApproaching: true };
  }

  if (get(pickupDateTime, 'actual')) {
    return { milestone: Milestones.PICKUP, isApproaching: false };
  }

  return { milestone: null, isApproaching: false };
}

export function determineActiveTruckMilestone(shipment) {
  const { pickupDateTime, deliveryDateTime } = shipment;

  if (get(deliveryDateTime, 'actual')) {
    return Milestones.DELIVERY;
  }
  if (get(pickupDateTime, 'actual')) {
    return Milestones.ENROUTE;
  }
  return null;
}
