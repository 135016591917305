import classNames from 'classnames';

import { FAIcon } from '../adapters/fontAwesomeAdapters';
import { InlineLink } from '../typography';
import { MapCard } from './MapCard';

export function CollapsibleMapCard({
  className,
  beforeContent,
  expanded,
  children,
}) {
  return (
    <MapCard
      className={classNames('CollapsibleMapCard', { expanded }, className)}
    >
      {beforeContent}
      <div className={classNames('CollapseContent', { hidden: !expanded })}>
        {children}
      </div>
    </MapCard>
  );
}

export function CollapsibleMapCardWithToggle({
  expanded,
  setExpanded,
  className,
  children,
}) {
  return (
    <CollapsibleMapCard
      className={className}
      expanded={expanded}
      beforeContent={
        <InlineLink
          className="CollapseControl"
          textId="labels.map"
          iconBefore={
            <FAIcon
              icon={expanded ? 'angle-up' : 'angle-down'}
              className="icon-20"
            />
          }
          onClick={() => setExpanded(!expanded)}
        />
      }
    >
      {children}
    </CollapsibleMapCard>
  );
}
