import { useMutation, useQuery } from '@apollo/client';
import { Typography } from 'antd';
import { merge } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory } from 'react-router';

import {
  accountFormToGraphql,
  accountGraphqlToForm,
} from '../../../app/data/accountConversions';
import {
  ACCOUNT_QUERY,
  UPDATE_ACCOUNT_MUTATION,
} from '../../../app/graphql/accountQueries';
import { BUSSINESS_TYPES_QUERY } from '../../../app/graphql/bussinessTypeQueries';
import routes from '../../../app/routes';
import { SimpleConfirmDialog } from '../../../components/dialogs/SimpleConfirmDialog';
import { SimpleDialogMaskStyles } from '../../../components/dialogs/SimpleDialog';
import {
  FormTheme,
  FormThemeOverrideContextProvider,
} from '../../../components/forms/formTheme';
import { NoLabelForm, useFormContext } from '../../../components/forms/forms';
import { FormItemSelect } from '../../../components/forms/selects';
import AdminConsoleDetail from '../common/AdminConsoleDetail';
import AdminAccountAddressBookSharing from './detail/AdminAccountAddressBookSharing';
import AdminAccountCommodityTypes from './detail/AdminAccountCommodityTypes';
import { BUSINESS_TYPE_OPTIONS } from './detail/AdminAccountDetailCommon';
import AdminAccountDetailProfile from './detail/AdminAccountDetailProfile';
import AdminAccountDocuments from './detail/AdminAccountDocuments';
import AdminAccountGPSDevices from './detail/AdminAccountGPSDevices';
import AdminAccountPackTemperature from './detail/AdminAccountPackTemperature';
import AdminAccountPackageTypes from './detail/AdminAccountPackageTypes';
import AdminAccountServiceTypes from './detail/AdminAccountServiceTypes';
import AdminAccountTemperatureDevices from './detail/AdminAccountTemperatureDevices';

const STEPS = [
  {
    path: routes.admin.accountDetail.root,
    routeProps: { exact: true },
    component: AdminAccountDetailProfile,
  },
  {
    path: routes.admin.accountDetail.documents,
    component: AdminAccountDocuments,
  },
  {
    path: routes.admin.accountDetail.addressBookSharing,
    component: AdminAccountAddressBookSharing,
  },
  {
    path: routes.admin.accountDetail.serviceTypes,
    component: AdminAccountServiceTypes,
  },
  {
    path: routes.admin.accountDetail.commodityTypes,
    component: AdminAccountCommodityTypes,
  },
  {
    path: routes.admin.accountDetail.gpsDevices,
    component: AdminAccountGPSDevices,
  },
  {
    path: routes.admin.accountDetail.temperatureDevices,
    component: AdminAccountTemperatureDevices,
  },
  {
    path: routes.admin.accountDetail.packageTypes,
    component: AdminAccountPackageTypes,
  },
  {
    path: routes.admin.accountDetail.packageTemperatures,
    component: AdminAccountPackTemperature,
  },
];

const MOCK_VALUES = {
  keyContacts: { contactName: 'Shawn Richardson', phone: '(303) 555-1212' },
};

function BusinessTypeDialogInner() {
  const { push } = useHistory();
  const { triggerSubmit, values } = useFormContext();

  return (
    <SimpleConfirmDialog
      visible
      className="SimpleDialog--ThemeDark AdminAccountDetail__BusinessTypeDialog"
      titleId={null}
      okTextId="buttons.confirm"
      onOk={triggerSubmit}
      okButtonProps={{ size: 'large', disabled: !values.profile }}
      cancelTextId="buttons.cancel"
      onClose={() => push(routes.admin.accountManagement)}
      cancelButtonProps={{ size: 'large' }}
      mask
      maskStyle={SimpleDialogMaskStyles.dark}
      borderAboveButtons
    >
      <Typography.Title level={2}>
        <FormattedMessage id="admin.accountManagement.accountDetail.businessTypeDialog.title" />
      </Typography.Title>
      <div className="spaces-vert-norm2 no-margin-form-items">
        <div className="AdminAccountDetail__BusinessTypeDialog__Subtitle">
          <FormattedMessage id="admin.accountManagement.accountDetail.businessTypeDialog.subtitle" />
        </div>
        <FormThemeOverrideContextProvider themeClassName={FormTheme.DarkSimple}>
          <FormItemSelect
            name="profile"
            options={BUSINESS_TYPE_OPTIONS}
            labelId="admin.accountManagement.accountDetail.businessTypeDialog.selectPlaceholder"
            rules={[{ required: true }]}
          />
        </FormThemeOverrideContextProvider>
      </div>
    </SimpleConfirmDialog>
  );
}

function BusinessTypeDialog({ account, save }) {
  return (
    <NoLabelForm
      component={false}
      onSubmit={({ profile }) =>
        save(
          account,
          accountFormToGraphql({ ...accountGraphqlToForm(account), profile })
        )
      }
      initialValues={accountGraphqlToForm(account)}
    >
      <BusinessTypeDialogInner />
    </NoLabelForm>
  );
}

export default function AdminAccountDetail({ accountNumber, headerRightRef }) {
  const { data: bussinessTypesData } = useQuery(BUSSINESS_TYPES_QUERY);
  const [mutate] = useMutation(UPDATE_ACCOUNT_MUTATION);
  const save = async (_account, values) => {
    await mutate({
      variables: {
        accountNumber,
        input: values,
      },
    });
  };

  return (
    <AdminConsoleDetail
      query={ACCOUNT_QUERY}
      variables={{ accountNumber }}
      headerRightRef={headerRightRef}
      graphqlToForm={vals => merge(accountGraphqlToForm(vals), MOCK_VALUES)}
      formToGraphql={accountFormToGraphql}
      save={save}
      saveSuccessMessageId="admin.userManagement.accountDetail.save.success"
      getAfterSaveRedirectRoute={_account =>
        generatePath(routes.admin.accountDetail.root, {
          accountNumber,
        })
      }
      sectionHomeRoute={routes.admin.accountManagement}
      steps={STEPS}
      getStepsProps={account => ({
        account,
        headerRightRef,
        bussinessTypes: bussinessTypesData?.bussinessTypes,
      })}
    >
      {({ account }) =>
        !account.profile && <BusinessTypeDialog account={account} save={save} />
      }
    </AdminConsoleDetail>
  );
}
