import { Tooltip } from 'antd';
import classNames from 'classnames';
import * as clipboard from 'clipboard-polyfill/text';
import { stubString } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import { FATypes } from '../adapters/fontAwesomeAdapters';
import IconButton from '../buttons/IconButton';
import { useFlashMessageContext } from '../dialogs/FlashMessageProvider';

export function CopyToClipboardHoverArea({
  className,
  alwaysVisible,
  ...rest
}) {
  return (
    <div
      className={classNames(
        'CopyToClipboard-HoverArea',
        {
          'CopyToClipboard-HoverArea--AlwaysVisible': alwaysVisible,
          'CopyToClipboard-HoverArea--ShowOnHover': !alwaysVisible,
        },
        className
      )}
      {...rest}
    />
  );
}

export function CopyToClipboardButton({
  className,
  tooltip,
  tooltipId,
  getContent = stubString,
}) {
  const { errorMessage, successMessage } = useFlashMessageContext();

  const tooltipContent = tooltipId ? (
    <FormattedMessage id={tooltipId} />
  ) : (
    tooltip
  );

  return (
    <Tooltip
      title={tooltipContent || <FormattedMessage id="buttons.copy" />}
      placement="bottom"
      overlayClassName="ant-tooltip--no-arrow ant-tooltip--sm"
    >
      <span
        className="CopyToClipboard-Icon"
        /* Title is to block the parent title */ title=" "
      >
        <IconButton
          icon="copy"
          type={FATypes.REGULAR}
          className={className}
          onClick={async e => {
            e.stopPropagation();
            try {
              clipboard.writeText(getContent());
              successMessage({ contentId: 'copyToClipboard.success' });
            } catch (err) {
              errorMessage({ contentId: 'copyToClipboard.failure' });
            }
          }}
        />
      </span>
    </Tooltip>
  );
}
