import { get, head, last, upperCase } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import {
  Milestones,
  determineActiveFlightMilestone,
  determineActiveTruckMilestone,
} from '../../app/data/shipmentMilestone';
import { TransportType } from '../../app/enums/TransportType';
import { EMPTY_VALUE } from '../../common/utils/formatUtils';
import { capitalizeWords } from '../../common/utils/stringUtils';
import Whitespace from '../../components/Whitespace';
import { FAIcon } from '../../components/adapters/fontAwesomeAdapters';
import MilestoneStepper, {
  StepType,
} from '../../components/nav/MilestoneStepper';
import { DateTimeFormatMedium } from '../../utils/dateFormatting';

const DateTimeFormat = ({ timePrefix, ...rest }) => (
  <DateTimeFormatMedium
    {...rest}
    separator={
      <span className="text-uppercase">
        <br />
        <FormattedMessage id={timePrefix} />
        <Whitespace />
      </span>
    }
  />
);

const TBD = () => <FormattedMessage id="labels.tbd" />;

export function MilestoneDescription({ schedule }) {
  if (!schedule) {
    return <TBD />;
  }
  if (!schedule.actual && !schedule.estimated && !schedule.scheduled) {
    if (schedule.isAsap) {
      return <FormattedMessage id="date.asap" />;
    }
    return <TBD />;
  }
  if (schedule.actual) {
    return (
      <DateTimeFormat value={schedule.actual} timePrefix="time.prefixAt" />
    );
  }
  return (
    <DateTimeFormat
      value={schedule.estimated || schedule.scheduled}
      timePrefix="time.prefixBy"
    />
  );
}

const UNKNOWN_FLIGHT = {
  status: null,
  originAirportCode: null,
  destinationAirportCode: null,
  departure: {
    scheduled: null,
  },
  arrival: {
    scheduled: null,
  },
};

const BLINDED_VALUE = '- -';

function makePickupStep(shipment) {
  return {
    type: StepType.PRIMARY,
    icon: <FAIcon icon="circle" style={{ fontSize: 12 }} />,
    key: Milestones.PICKUP,
    title: <FormattedMessage id="shipmentDetail.pickup" />,
    subtitle: capitalizeWords(
      shipment.origin?.address?.city?.text || EMPTY_VALUE
    ),
    description: <MilestoneDescription schedule={shipment.pickupDateTime} />,
  };
}

function makeDestinationStep(shipment) {
  return {
    type: StepType.PRIMARY,
    icon: <FAIcon icon="circle" style={{ fontSize: 12 }} />,
    key: Milestones.DELIVERY,
    title: <FormattedMessage id="shipmentDetail.delivery" />,
    subtitle: capitalizeWords(
      shipment.destination?.address?.city?.text || EMPTY_VALUE
    ),
    description: <MilestoneDescription schedule={shipment.deliveryDateTime} />,
  };
}

function FlightShipmentMilestonesSteps({ shipment }) {
  const blinded = shipment.metadata?.blindingInformation?.flights;

  const flights = get(shipment, 'flights') || [];
  const flight1 = head(flights) || UNKNOWN_FLIGHT;
  const flightN = last(flights) || UNKNOWN_FLIGHT;

  const rawDataSource = [
    makePickupStep(shipment),
    {
      type: StepType.PRIMARY,
      icon: <FAIcon icon="plane-departure" />,
      key: Milestones.ORIGIN,
      title: get(flight1, 'originAirportCode') || <TBD />,
      subtitle: upperCase(get(flight1, 'status') || EMPTY_VALUE),
      description: <MilestoneDescription schedule={flight1.departure} />,
    },
    ...flights.slice(1).map(flight => ({
      type: StepType.SECONDARY,
      key: flight.number,
    })),
    {
      type: StepType.PRIMARY,
      icon: <FAIcon icon="plane-arrival" />,
      key: Milestones.DESTINATION,
      title: get(flightN, 'destinationAirportCode') || <TBD />,
      subtitle: upperCase(get(flightN, 'status') || EMPTY_VALUE),
      description: <MilestoneDescription schedule={flightN.arrival} />,
    },
    makeDestinationStep(shipment),
  ];

  const dataSource = blinded
    ? rawDataSource.map(({ key, title, subtitle, description, ...rest }) => {
        const hasLabel =
          key === Milestones.PICKUP || key === Milestones.DELIVERY;
        return {
          key,
          title: hasLabel ? title : BLINDED_VALUE,
          subtitle: hasLabel ? BLINDED_VALUE : '',
          description: hasLabel ? description : BLINDED_VALUE,
          ...rest,
        };
      })
    : rawDataSource;

  const { isApproaching, milestone } = determineActiveFlightMilestone(shipment);

  return (
    <MilestoneStepper
      className="ShipmentMilestonesSteps"
      dataSource={dataSource}
      activeStep={milestone}
      isLeadingStepActivated={!isApproaching}
    />
  );
}

function TruckShipmentMilestonesSteps({ shipment }) {
  const dataSource = [
    makePickupStep(shipment),
    {
      type: StepType.PRIMARY,
      icon: <FAIcon icon="truck" />,
      key: Milestones.ENROUTE,
      title: null,
      subtitle: (
        <FormattedMessage id="shipmentDetail.shipmentMilestones.enroute" />
      ),
      description: null,
    },
    makeDestinationStep(shipment),
  ];

  const milestone = determineActiveTruckMilestone(shipment);

  return (
    <MilestoneStepper
      className="ShipmentMilestonesSteps"
      dataSource={dataSource}
      activeStep={milestone}
      isLeadingStepActivated
    />
  );
}

export default function ShipmentMilestonesSteps({ shipment }) {
  const transportType = shipment?.serviceInformation?.transportType;

  if (transportType === TransportType.FLIGHT) {
    return <FlightShipmentMilestonesSteps shipment={shipment} />;
  }

  return <TruckShipmentMilestonesSteps shipment={shipment} />;
}
