import { useIntl } from 'react-intl';

import { DynamicFormFieldType } from '../../../app/enums/dynamicFormEnums';
import {
  fromServerDateOptional,
  fromServerTimeOptional,
  toServerDateOptional,
  toServerDateTimeOptional,
  toServerTimeOptional,
} from '../../../common/utils/dateUtils';
import { log } from '../../../utils/logger';
import { FormItemInputNumber, FormItemInputText } from '../basicFormElements';
import { FormItemYesNoSelect } from '../checkable';
import {
  FormItemDatePicker,
  FormItemDateTimePicker,
  FormItemTimePicker,
} from '../dateElements';
import { FormItemPolymorphicSelect } from '../selects';
import { useDynamicFormValidationRules } from './dynamicFormValidation';

function DynamicTextField({ name, label, rules }) {
  return <FormItemInputText name={name} label={label} rules={rules} />;
}

function DynamicSelectField({ name, label, rules, field }) {
  if (!field?.option?.staticOptions) {
    console.error(
      "Autocomplete is not yet supported for dynamic fields - it's not clear what `fieldName` should be (some combination of `schemaName` and `name`?)"
    );
  }
  return (
    <FormItemPolymorphicSelect
      name={name}
      label={label}
      rules={rules}
      fieldDef={field}
    />
  );
}

function DynamicDateField({ name, label, rules }) {
  return (
    <FormItemDatePicker
      name={name}
      label={label}
      rules={rules}
      formItemComponentProps={{
        formatValue: fromServerDateOptional,
        parseValue: toServerDateOptional,
      }}
    />
  );
}

const parseTime = val => toServerTimeOptional(val.seconds(0));
function DynamicTimeField({ name, label, rules }) {
  return (
    <FormItemTimePicker
      name={name}
      label={label}
      rules={rules}
      formItemComponentProps={{
        formatValue: fromServerTimeOptional,
        parseValue: parseTime,
      }}
    />
  );
}

function DynamicDateTimeField({ name, label, rules }) {
  const intl = useIntl();

  const dateProps = {
    formItemComponentProps: {
      label: `${label} (${intl.formatMessage({ id: 'labels.date' })})`,
    },
  };
  const timeProps = {
    formItemComponentProps: {
      label: `${label} (${intl.formatMessage({ id: 'labels.time' })})`,
    },
  };

  return (
    <div className="no-margin-form-items">
      <FormItemDateTimePicker
        name={name}
        label={label}
        rules={rules}
        normalize={toServerDateTimeOptional}
        dateProps={dateProps}
        timeProps={timeProps}
        floatingLabel
        expandSize
      />
    </div>
  );
}

function DynamicBooleanField({ name, label, rules }) {
  return <FormItemYesNoSelect name={name} label={label} rules={rules} />;
}

function DynamicNumericField({ name, label, rules }) {
  return <FormItemInputNumber name={name} label={label} rules={rules} />;
}

const TYPE_MAPPING = {
  [DynamicFormFieldType.TEXT]: DynamicTextField,
  [DynamicFormFieldType.OPTION]: DynamicSelectField,
  [DynamicFormFieldType.DATE]: DynamicDateField,
  [DynamicFormFieldType.TIME]: DynamicTimeField,
  [DynamicFormFieldType.DATETIME]: DynamicDateTimeField,
  [DynamicFormFieldType.BOOLEAN]: DynamicBooleanField,
  [DynamicFormFieldType.NUMERIC]: DynamicNumericField,
};

export default function DynamicFormField({ type, name, label, field }) {
  if (field?.validation?.async) {
    log.error(
      "Async validation is not yet supported for dynamic fields - it's not clear what `fieldName` should be (some combination of `schemaName` and `name`?)"
    );
  }
  const rules = useDynamicFormValidationRules({
    validation: field.validation,
  });

  const Component = TYPE_MAPPING[type];
  return <Component name={name} label={label} rules={rules} field={field} />;
}
