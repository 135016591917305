import { AutoComplete, Menu, Select } from 'antd';
import { find, identity, noop, property } from 'lodash-es';
import { useCallback, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { FAIcon } from '../adapters/fontAwesomeAdapters';
import { DropdownMenu } from '../nav/navElements';
import { InlineLink } from '../typography';
import { FormItem } from './FormItem';
import { useDynamicFormSchemaFieldDefinition } from './dynamic/dynamicFormSchema';
import { useDynamicSelectOptionsLoader } from './dynamic/dynamicSelects';
import { useTranslatedOptions, withEnumOptions } from './formDecorators';
import { extractValueFromEvent } from './formHelpers';
import { FormThemeOverrideContext } from './formTheme';
import { validOptionSelectedRule } from './formValidationRules';
import { useLabelFilter, useObjectValues } from './selectDecorators';
import {
  codeValueToRichSelectOption,
  selectOptionToCodeValue,
} from './selectUtils';

function SelectAdapter({ options, ...rest }) {
  const { filterOption } = useLabelFilter();
  const { themeClassName } = useContext(FormThemeOverrideContext);
  return (
    <Select
      allowClear
      showSearch
      showArrow
      suffixIcon={<FAIcon icon="caret-down" />}
      options={options}
      filterOption={filterOption}
      onClear={() => requestAnimationFrame(rest.onBlur)}
      dropdownClassName={themeClassName}
      {...rest}
    />
  );
}
export function FormItemSelect({
  options,
  mapOptions = identity,
  withOptions = noop,
  optionKey,
  ...rest
}) {
  const translatedOptions = useTranslatedOptions(mapOptions(options));
  const {
    options: objOptions,
    formatValue,
    parseValue,
  } = useObjectValues({
    options: translatedOptions,
    optionKey,
  });

  useEffect(() => {
    withOptions(options);
  });

  return (
    <FormItem
      {...rest}
      component={SelectAdapter}
      options={translatedOptions}
      {...(optionKey ? { formatValue, parseValue, options: objOptions } : {})}
    />
  );
}

export const FormItemEnumSelect = withEnumOptions(FormItemSelect);

function RichValueAutocomplete({
  options,
  richOptions,
  value,
  formatLabel,
  onChange,
  onSearch: onSearchOuter,
  onSelect: onSelectOuter,
  ...rest
}) {
  const strValue = formatLabel(value);

  const onSearch = useCallback(
    searchText => {
      onSearchOuter && onSearchOuter(searchText);

      const matchingOption = richOptions.find(opt => opt.label === searchText);
      if (matchingOption) {
        onChange(matchingOption.value);
      } else {
        onChange(searchText ? { text: searchText } : undefined);
      }
    },
    [onChange, onSearchOuter, richOptions]
  );

  const onSelect = useCallback(
    (val, option) => {
      onSelectOuter && onSelectOuter(val, option);
      const richOption = richOptions.find(opt => opt.value.code === val);
      if (richOption) {
        onChange(richOption.value);
      }
    },
    [onChange, onSelectOuter, richOptions]
  );

  return (
    <AutoComplete
      options={options}
      value={strValue}
      onSearch={onSearch}
      onSelect={onSelect}
      /** `onChange` shouldn't be passed because Ant AutoComplete calls it in various
       *  stages with a value format we don't want */
      {...rest}
    />
  );
}

function FreeTextAutocomplete({
  options,
  textAsValue,
  value,
  onChange,
  onSearch: onSearchOuter,
  onSelect: onSelectOuter,
  ...rest
}) {
  const strValue = textAsValue ? value : value?.text;

  const onSelect = useCallback(
    (val, option) => {
      onSelectOuter && onSelectOuter(val, option);
      onChange(textAsValue ? option.label : selectOptionToCodeValue(option));
    },
    [onChange, onSelectOuter, textAsValue]
  );

  const onSearch = useCallback(
    val => {
      onSearchOuter && onSearchOuter(val);
      if (textAsValue) {
        onChange(val);
      } else {
        const opt = find(options, o => o.label === val);
        onChange(opt ? selectOptionToCodeValue(opt) : { text: val });
      }
    },
    [onChange, onSearchOuter, options, textAsValue]
  );

  return (
    <AutoComplete
      options={options}
      value={strValue}
      onSelect={onSelect}
      onSearch={onSearch}
      /** `onChange` shouldn't be passed because Ant AutoComplete calls it in various
       *  stages with a value format we don't want */
      {...rest}
    />
  );
}

const DEFAULT_FORMAT_LABEL = property('text');
function AutocompleteAdapter({
  options: richOptions,
  value,
  onChange,
  onSearch,
  onFocus,
  onBlur,
  onClear: onClearOuter,
  allowFreeText,
  textAsValue,
  formatLabel = DEFAULT_FORMAT_LABEL,
  useClientFilter = true,
  ...rest
}) {
  const { filterOption } = useLabelFilter();

  const options = richOptions.map(opt => ({
    value: opt.value.code,
    label: formatLabel(opt.value),
  }));

  const onClear = useCallback(() => {
    onClearOuter && onClearOuter();
    onChange('');
    requestAnimationFrame(onBlur);
  }, [onBlur, onChange, onClearOuter]);

  return allowFreeText ? (
    <FreeTextAutocomplete
      allowClear
      defaultActiveFirstOption
      textAsValue={textAsValue}
      options={options}
      value={value}
      onChange={onChange}
      onSearch={onSearch}
      onFocus={onFocus}
      onBlur={onBlur}
      onClear={onClear}
      filterOption={useClientFilter ? filterOption : undefined}
      {...rest}
    />
  ) : (
    <RichValueAutocomplete
      allowClear
      defaultActiveFirstOption
      options={options}
      richOptions={richOptions}
      value={value}
      formatLabel={formatLabel}
      onChange={onChange}
      onSearch={onSearch}
      onFocus={onFocus}
      onBlur={onBlur}
      onClear={onClear}
      filterOption={filterOption}
      {...rest}
    />
  );
}

function FormItemAutocomplete({
  name,
  schemaName,
  allowFreeText,
  textAsValue,
  formItemComponentProps,
  allowServerSideSearch = false,
  options: staticOptions,
  mapOptions = identity,
  withOptions = noop,
  preloadOptions,
  ...rest
}) {
  const { options, loading, loadOptions } = useDynamicSelectOptionsLoader({
    schemaName,
    preloadOptions: preloadOptions && !staticOptions,
  });

  const optionsLoading = staticOptions ? false : loading;
  const schemaOptions =
    staticOptions || options.map(codeValueToRichSelectOption);

  const finalOptions = optionsLoading
    ? schemaOptions
    : mapOptions(schemaOptions);

  useEffect(() => {
    if (!optionsLoading) {
      withOptions(schemaOptions);
    }
  });

  return (
    <FormItem
      name={name}
      schemaName={schemaName}
      options={finalOptions}
      loading={loading}
      formItemComponentProps={{
        ...formItemComponentProps,
        onFocus: e => {
          formItemComponentProps?.onFocus && formItemComponentProps.onFocus(e);
          if (!staticOptions) {
            loadOptions(
              allowServerSideSearch ? extractValueFromEvent(e) || '' : ''
            );
          }
        },
        onSearch: text => {
          formItemComponentProps?.onSearch &&
            formItemComponentProps.onSearch(text);
          if (allowServerSideSearch) {
            loadOptions(text);
          }
        },
        isValuePresent: textAsValue ? undefined : property('text'),
        allowFreeText,
        textAsValue,
        // If server side search is allowed, there may be a logic to also consider
        // code and other parameters, so in that case client filter is disabled
        useClientFilter: !allowServerSideSearch,
      }}
      rules={
        allowFreeText
          ? undefined
          : schemaRules => [...schemaRules, validOptionSelectedRule]
      }
      {...rest}
      component={AutocompleteAdapter}
    />
  );
}

export function FormItemPolymorphicSelect({
  name,
  fieldDef,
  allowFreeText,
  textAsValue,
  ...rest
}) {
  const staticOptions = fieldDef?.option?.staticOptions;

  // Options loaded on init
  if (staticOptions) {
    const options = staticOptions.values.map(codeValueToRichSelectOption);

    // Only options allowed
    if (!allowFreeText) {
      return (
        <FormItemSelect
          name={name}
          optionKey="code"
          options={options}
          {...rest}
        />
      );
    }
    // Options or free text allowed
    return (
      <FormItemAutocomplete
        name={name}
        options={options}
        allowFreeText={allowFreeText}
        textAsValue={textAsValue}
        {...rest}
      />
    );
  }

  // Options loaded on demand
  return (
    <FormItemAutocomplete
      name={name}
      allowFreeText={allowFreeText}
      textAsValue={textAsValue}
      {...rest}
    />
  );
}

export function FormItemSchemaDefinedSelect({ schemaName, ...rest }) {
  const fieldDef = useDynamicFormSchemaFieldDefinition({ schemaName });

  return (
    <FormItemPolymorphicSelect
      fieldDef={fieldDef}
      schemaName={schemaName}
      {...rest}
    />
  );
}

function DropdownSelect({
  renderLabel,
  options,
  value,
  onChange,
  className,
  overlayClassName,
  disabled,
}) {
  const selectedItem = options.find(opt => opt.value === value);
  const triggerLabel = renderLabel(selectedItem || {}, { disabled });

  if (disabled) {
    return triggerLabel;
  }

  return (
    <div>
      <DropdownMenu
        label={triggerLabel}
        noArrow
        className={className}
        overlayClassName={overlayClassName}
        align={{
          points: ['tr', 'br'],
          offset: [13, 4],
          overflow: {
            adjustX: 0,
            adjustY: 0,
          },
        }}
        overlayStyle={{ minWidth: 0 }}
      >
        {options.map(({ value: val, label, labelId }) => (
          <Menu.Item key={`${val}`}>
            <InlineLink onClick={() => onChange(val)}>
              {labelId ? <FormattedMessage id={labelId} /> : label}
            </InlineLink>
          </Menu.Item>
        ))}
      </DropdownMenu>
    </div>
  );
}

export function FormItemDropdownSelect({
  renderLabel,
  options,
  formItemComponentProps,
  ...rest
}) {
  return (
    <FormItem
      component={DropdownSelect}
      {...rest}
      formItemComponentProps={{
        ...formItemComponentProps,
        options,
        renderLabel,
      }}
    />
  );
}
