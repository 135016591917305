import { Col, Row, Tag, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import { useFormContext } from '../../components/forms/forms';
import { cssVariables } from '../../styles/cssVariables';
import { pxToNumber } from '../../utils/cssUtils';
import { DateFormatLongNoWeekday } from '../../utils/dateFormatting';

export function ReportsDownloadSummary() {
  const { values } = useFormContext();

  return (
    <div className="ReportsDialog__Section ReportsDialog__Content">
      <div className="ReportsDialog__Section__Title no-margin-form-items">
        <Typography.Title level={3}>
          4. <FormattedMessage id="reports.downloadSummary.title" />
        </Typography.Title>
      </div>
      <div className="ReportsDialog__SelectionSection ReportsDialog__SelectionSection--Summary spaces-vert-norm3">
        <div>
          <div className="ReportsDialog__SelectionSection__Title">
            <FormattedMessage id="reports.accountSelection" />
          </div>
          <div className="ReportsDialog__SelectionSection__Footer">
            {values.selectedAccounts.map(acc => (
              <Tag className="ReportsDialog__SelectionTag" key={acc}>
                {acc.number}
              </Tag>
            ))}
          </div>
        </div>
        <div>
          <div className="ReportsDialog__SelectionSection__Title">
            <FormattedMessage id="reports.reportSelection" />
          </div>
          <div className="ReportsDialog__SelectionSection__Footer">
            {values.selectedReports.map(report => (
              <Tag className="ReportsDialog__SelectionTag" key={report}>
                {report.text}
              </Tag>
            ))}
          </div>
        </div>
        <div>
          <Row gutter={pxToNumber(cssVariables.spaceNorm3)}>
            <Col span={12}>
              <div className="ReportsDialog__SelectionSection">
                <div className="ReportsDialog__SelectionSection__Title">
                  <FormattedMessage id="reports.downloadSummary.startDate" />
                </div>
                <div className="ReportsDialog__SelectionSection__Body ReportsDialog__Summary__DateBody">
                  <DateFormatLongNoWeekday value={values.dateStart} />
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="ReportsDialog__SelectionSection">
                <div className="ReportsDialog__SelectionSection__Title">
                  <FormattedMessage id="reports.downloadSummary.endDate" />
                </div>
                <div className="ReportsDialog__SelectionSection__Body ReportsDialog__Summary__DateBody">
                  <DateFormatLongNoWeekday value={values.dateEnd} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
