import { useQuery } from '@apollo/client';

import { COMMODITIES_QUERY } from '../../../../app/graphql/adminQueries';
import AdminAccountTransferPage from './AdminAccountTransferPage';

const AdminAccountCommodityTypes = ({ account, viewMode, visible }) => {
  const { data, loading } = useQuery(COMMODITIES_QUERY, {
    skip: !visible,
  });

  return (
    <AdminAccountTransferPage
      account={account}
      viewMode={viewMode}
      visible={visible}
      titleIcon="boxes"
      titleId="admin.accountManagement.accountDetail.commodityTypes"
      itemName="commodityTypesSettings"
      titlesPrefix="admin.accountManagement.accountDetail.commodity"
      data={data?.commodities}
      loading={loading}
    />
  );
};

export default AdminAccountCommodityTypes;
