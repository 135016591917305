import { Button, Space, Typography } from 'antd';
import { identity, isEmpty } from 'lodash-es';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormItemsOverrideContextProvider } from '../../../../components/forms/FormItem';
import { isFormSectionNonEmpty } from '../../../../components/forms/formHelpers';
import {
  FormTheme,
  FormThemeOverrideContextProvider,
} from '../../../../components/forms/formTheme';
import {
  NoLabelForm,
  SubmitButton,
  useFormContext,
} from '../../../../components/forms/forms';
import { cssVariables } from '../../../../styles/cssVariables';
import { pxToNumber } from '../../../../utils/cssUtils';
import AdminConsoleSection, {
  AdminConsoleSectionContent,
  AdminConsoleSectionTitleHeader,
} from '../../components/AdminConsoleSection';
import AdminConsoleContent from '../../layout/AdminConsoleContent';
import { formatUserLabel } from '../../utils/adminFormatting';

function SaveButton({
  labelId = 'buttons.saveNext',
  extractRelevantValues,
  allowSaveEmpty,
}) {
  const { values } = useFormContext();
  const isNonEmpty = isFormSectionNonEmpty(extractRelevantValues(values));

  return (
    <SubmitButton
      type="primary"
      size="small"
      disabled={!isNonEmpty && !allowSaveEmpty}
    >
      <FormattedMessage id={labelId} />
    </SubmitButton>
  );
}

const MODES = {
  EDIT: 'EDIT',
  SKIP: 'SKIP',
  VIEW: 'VIEW',
};

function Buttons({
  onBack,
  okLabelId = 'buttons.saveNext',
  extractRelevantValues,
  allowSaveEmpty,
  showSaveButton = true,
  mode,
  setMode,
  twoStepSkipProcess,
}) {
  return (
    <Space direction="horizontal" size={pxToNumber(cssVariables.spaceNorm)}>
      <Button
        ghost
        size="small"
        className="width-70"
        disabled={!onBack}
        onClick={onBack}
      >
        <FormattedMessage id="buttons.back" />
      </Button>
      {twoStepSkipProcess &&
        (mode === MODES.VIEW ? (
          <>
            <Button
              type="primary"
              size="small"
              onClick={() => setMode(MODES.SKIP)}
              className="width-110"
            >
              <FormattedMessage id="buttons.skip" />
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={() => setMode(MODES.EDIT)}
              className="width-110 text-uppercase"
            >
              <FormattedMessage id="buttons.enter" />
            </Button>
          </>
        ) : (
          <Button
            ghost
            size="small"
            className="width-70"
            onClick={() => setMode(MODES.VIEW)}
          >
            <FormattedMessage id="buttons.cancel" />
          </Button>
        ))}
      {showSaveButton && mode !== MODES.VIEW && (
        <SaveButton
          labelId={okLabelId}
          extractRelevantValues={extractRelevantValues}
          allowSaveEmpty={allowSaveEmpty}
        />
      )}
    </Space>
  );
}

function HeaderRight({
  okLabelId = 'buttons.saveNext',
  extractRelevantValues,
  showSaveButton,
  allowSaveEmpty,
  onCancel,
  mode,
}) {
  return (
    <Space direction="horizontal" size={pxToNumber(cssVariables.spaceNorm)}>
      <Button ghost size="small" className="width-70" onClick={onCancel}>
        <FormattedMessage id="buttons.cancel" />
      </Button>
      {showSaveButton && mode !== MODES.VIEW && (
        <SaveButton
          labelId={okLabelId}
          extractRelevantValues={extractRelevantValues}
          allowSaveEmpty={allowSaveEmpty}
        />
      )}
    </Space>
  );
}

export default function AdminAddUserStepTemplate({
  titleId,
  titleIcon,
  titleIconNode,
  onSave,
  onBack,
  onCancel,
  values,
  children,
  okButtonLabelId,
  formClassName = FormTheme.DarkOrange,
  extractRelevantValues = identity,
  allowSaveEmpty,
  twoStepSkipProcess,
  saveButtonInHeader,
}) {
  const [mode, setMode] = useState(
    allowSaveEmpty && twoStepSkipProcess ? MODES.VIEW : MODES.EDIT
  );

  return (
    <NoLabelForm
      initialValues={values}
      onSubmit={onSave}
      className={formClassName}
    >
      <FormItemsOverrideContextProvider viewMode={mode !== MODES.EDIT}>
        <FormThemeOverrideContextProvider themeClassName={formClassName}>
          <AdminConsoleContent
            titleId={titleId}
            titleIcon={titleIcon}
            titleIconNode={titleIconNode}
            headerRight={
              <HeaderRight
                okLabelId={okButtonLabelId}
                allowSaveEmpty={allowSaveEmpty}
                onCancel={onCancel}
                showSaveButton={saveButtonInHeader}
                extractRelevantValues={extractRelevantValues}
                mode={mode}
                setMode={setMode}
              />
            }
          >
            <AdminConsoleSection>
              <AdminConsoleSectionTitleHeader>
                <Typography.Title level={3}>
                  {!isEmpty(values.name) ? (
                    formatUserLabel(values)
                  ) : (
                    <FormattedMessage id="admin.userManagement.addUser.emptySectionHeader" />
                  )}
                </Typography.Title>
                <Buttons
                  onBack={onBack}
                  okLabelId={okButtonLabelId}
                  extractRelevantValues={extractRelevantValues}
                  allowSaveEmpty={allowSaveEmpty}
                  showSaveButton={!saveButtonInHeader}
                  mode={mode}
                  setMode={setMode}
                  twoStepSkipProcess={twoStepSkipProcess}
                />
              </AdminConsoleSectionTitleHeader>
              <AdminConsoleSectionContent>
                <div className="spaces-vert-norm2 no-margin-form-items">
                  {children}
                </div>
              </AdminConsoleSectionContent>
            </AdminConsoleSection>
          </AdminConsoleContent>
        </FormThemeOverrideContextProvider>
      </FormItemsOverrideContextProvider>
    </NoLabelForm>
  );
}
