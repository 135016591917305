import { useMutation } from '@apollo/client';
import { Col, Form, Row, Typography } from 'antd';
import { find, negate, property } from 'lodash-es';
import { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';

import { DateFormatValues } from '../app/enums/DateFormat';
import { UnitSystem, UnitSystemValues } from '../app/enums/measuringUnits';
import {
  CHANGE_USER_SETTINGS_MUTATION,
  UPDATE_CURRENT_USER_SETTINGS,
} from '../app/graphql/authQueries';
import { updateQuery } from '../common/utils/graphqlUtils';
import { FAIcon, FATypes } from '../components/adapters/fontAwesomeAdapters';
import { ThreeDotsSpinner } from '../components/data/Spinner';
import { useFlashMessageContext } from '../components/dialogs/FlashMessageProvider';
import SimpleDialog from '../components/dialogs/SimpleDialog';
import {
  FormItemEnumRadioGroup,
  FormItemRadioGroup,
} from '../components/forms/checkable';
import { useTranslatedOptions } from '../components/forms/formDecorators';
import { NoLabelForm } from '../components/forms/forms';
import { TwoLines } from '../components/layout/layoutElements';
import SegmentedControl from '../components/nav/SegmentedControl';
import { SlidingContent } from '../components/nav/SlidingContent';
import { FEATURE_FLAGS } from '../config/featureFlags';
import {
  EMAIL_NOTIFICATION_MODES,
  EMAIL_NOTIFICATION_TABS,
} from './emailNotificationsForms';

function UserSettingsLabel({ user, open }) {
  return user ? (
    <div className="UserQuickSetup__Label Clickable" onClick={open}>
      <FAIcon icon="sliders-h" className="icon-13" />
      <span className="text-uppercase">
        <FormattedMessage id="settings.label" />
      </span>
    </div>
  ) : null;
}

function MetricsBlock({ user }) {
  const [form] = Form.useForm();
  const prevValues = useRef();
  const { errorMessage } = useFlashMessageContext();
  const [mutate, { loading: saving }] = useMutation(
    CHANGE_USER_SETTINGS_MUTATION,
    {
      update: (cache, _res, { variables: { input } }) => {
        updateQuery(cache, UPDATE_CURRENT_USER_SETTINGS, {
          ...user,
          settings: { __typename: 'UserSettings', ...input },
        });
      },
      onError: e => {
        errorMessage(e);
        // Revert form
        form.setFieldsValue(prevValues.current);
      },
    }
  );

  const onValuesChange = async (ch, all) => {
    await mutate({ variables: { input: all } });
    prevValues.current = form.getFieldsValue();
  };

  return (
    <div className="no-margin-form-items">
      {saving && (
        <div className="UserQuickSetup__SavingMask">
          <span>
            <FormattedMessage id="settings.userPreferences.saving" />
            <ThreeDotsSpinner />
          </span>
        </div>
      )}
      <NoLabelForm
        form={form}
        onValuesChange={onValuesChange}
        initialValues={user?.settings}
      >
        <Typography.Title level={4}>
          <FormattedMessage id="settings.userPreferences" />
        </Typography.Title>
        <Row
          className="UserQuickSetup__Row UserQuickSetup__Headers"
          align="top"
        >
          <Col>
            <FormattedMessage id="settings.header.unitSystem" />
          </Col>
          <Col>
            <FormattedMessage id="settings.header.dateFormat" />
          </Col>
        </Row>
        <TwoLines className="size-6" />
        <Row className="UserQuickSetup__Row UserQuickSetup__Radios" align="top">
          <Col>
            {FEATURE_FLAGS.allowMetric ? (
              <FormItemEnumRadioGroup
                name="unitSystem"
                intlPrefix="settings.unitSystem"
                intlValues={UnitSystemValues}
                layout="vertical"
              />
            ) : (
              <FormItemRadioGroup
                name="unitSystem"
                options={[
                  {
                    labelId: `settings.unitSystem.${UnitSystem.IMPERIAL}`,
                    value: UnitSystem.IMPERIAL,
                  },
                  {
                    labelId: `settings.unitSystem.${UnitSystem.METRIC}`,
                    value: UnitSystem.METRIC,
                    disabled: true,
                    tooltipId: 'labels.comingSoon',
                  },
                ]}
                intlPrefix="settings.unitSystem"
                intlValues={UnitSystemValues}
                layout="vertical"
              />
            )}
          </Col>
          <Col>
            <FormItemRadioGroup
              name="dateFormat"
              options={DateFormatValues}
              layout="vertical"
            />
          </Col>
        </Row>
      </NoLabelForm>
    </div>
  );
}

function NotificationsBlock() {
  const [tab, setTab] = useState(
    find(EMAIL_NOTIFICATION_MODES, negate(property('disabled'))).value
  );
  const options = useTranslatedOptions(EMAIL_NOTIFICATION_MODES);

  return (
    <>
      <Typography.Title level={4}>
        <FormattedMessage id="settings.emailNotificationSettings" />
      </Typography.Title>
      <TwoLines />
      <SegmentedControl
        options={options}
        value={tab}
        onChange={setTab}
        className="UserQuickSetup__SegmentedControl"
      />
      <SlidingContent
        listenToSelected
        selected={tab}
        autoHeight
        unmountInvisible
        data={EMAIL_NOTIFICATION_TABS}
        sharedProps={{
          className: 'UserQuickSetup__EmailNotifications',
          scrollbarsClassName: 'UserQuickSetup__NotificationsScrollbar',
        }}
      />
    </>
  );
}

function Title({ close }) {
  return (
    <div className="UserQuickSetup__Title">
      <Typography.Title level={1}>
        <span>
          <FormattedMessage id="settings.label" />
        </span>
      </Typography.Title>
      <FAIcon
        icon="times-circle"
        type={FATypes.REGULAR}
        className="Clickable icon-24"
        onClick={close}
      />
    </div>
  );
}

export default function UserSettingsDialog({ user }) {
  const [isOpen, setOpen] = useState(false);
  const [innerElement, setInnerElement] = useState();
  const container =
    innerElement && innerElement.parentElement.parentElement.parentElement;

  return (
    <>
      <UserSettingsLabel user={user} open={() => setOpen(true)} />
      <SimpleDialog
        visible={isOpen}
        onClose={() => setOpen(false)}
        footer={null}
        className="UserQuickSetup__Dialog"
        wrapClassName="UserQuickSetup__DialogWrap"
        mask
        centered
        destroyOnClose
      >
        <div ref={setInnerElement} />
        {container && (
          <>
            {/** Portal appends before, so we use reverse order and reverse flex direction */}
            {user.emailNotificationsEnabled ? (
              <>
                <NotificationsBlock />
                {createPortal(
                  <>
                    <div className="ant-modal-content">
                      <div className="ant-modal-body">
                        <MetricsBlock user={user} />
                      </div>
                    </div>
                    <Title close={() => setOpen(false)} />
                  </>,
                  container
                )}
              </>
            ) : (
              <>
                <MetricsBlock user={user} />
                {createPortal(
                  <Title close={() => setOpen(false)} />,
                  container
                )}
              </>
            )}
          </>
        )}
      </SimpleDialog>
    </>
  );
}
