import { Col, Row } from 'antd';

import routes from '../app/routes';
import { HeaderLogo } from '../components/Logo';
import { LinksRow } from '../components/nav/navElements';
import { BlockLink } from '../components/typography';
import { externalLinks } from '../config/externalLinks';
import TrackSearchForm from '../forms/TrackSearchForm';

export function NonLoggedMenu() {
  return (
    <LinksRow
      className="NonLoggedMenu"
      links={[
        { href: externalLinks.contact, textId: 'header.contactUs' },
        { href: externalLinks.about, textId: 'header.about' },
      ]}
    />
  );
}

export default function NonLoggedHeader() {
  return (
    <div className="NonLoggedHeader">
      <Row align="middle" justify="space-between">
        <Col>
          <BlockLink to={routes.root}>
            <HeaderLogo size="md" />
          </BlockLink>
        </Col>
        <Col className="hide-lg-and-smaller">
          <NonLoggedMenu />
        </Col>
        <Col className="no-margin-form-items">
          <TrackSearchForm />
        </Col>
      </Row>
    </div>
  );
}
