import { gql } from '@apollo/client';

export const BUSSINESS_TYPES_QUERY = gql`
  query AcountBussinessType {
    bussinessTypes {
      id
      shareableUrl
    }
  }
`;
