import { isApolloError } from '@apollo/client';
import { get, merge } from 'lodash-es';

const API_ERROR_CODE = 'API_ERROR';
const OTHER_ERROR_CODE = 'OTHER_ERROR';
const APP_ERROR_CODE = 'APP_ERROR';

const getFirstExt = e => get(e, 'graphQLErrors.0.extensions', {});

export class RestError extends Error {
  static type = 'RestError';

  constructor({ message, statusCode, detail } = {}) {
    super(message);
    this.errorMessage = message;
    this.statusCode = statusCode;
    this.detail = detail;
    this.type = 'RestError';
  }
}

function isApiError(e) {
  const { code } = getFirstExt(e);
  return code === API_ERROR_CODE;
}

function isOtherError(e) {
  const { code } = getFirstExt(e);
  return code === OTHER_ERROR_CODE;
}

function isAppError(e) {
  const { code } = getFirstExt(e);
  return code === APP_ERROR_CODE;
}

function isRestError(e) {
  return e.type === RestError.type;
}

export function extractExceptionErrorCode(e) {
  return getFirstExt(e)?.exception?.code;
}

function extractExceptionErrorMessage(e) {
  return getFirstExt(e)?.exception?.message;
}

function messageOrDefault(message, { intl, defaultId }) {
  if ((message || '').trim() !== '') {
    return message;
  }
  return intl.formatMessage({ id: defaultId });
}

function formatApiError(e, { intl }) {
  const { apiErrors = [] } = getFirstExt(e);
  const message = apiErrors.map(err => err.message).join('; ');
  return messageOrDefault(message, { intl, defaultId: 'error.apiError' });
}

function formatOtherError(e, { intl }) {
  const { message, error } = getFirstExt(e);
  const detailMessage = error
    ? `[${error.errorCode}] ${error.errorMessage}: ${error.errorDetailMessage}`
    : message;
  return messageOrDefault(detailMessage, {
    intl,
    defaultId: 'error.otherError',
  });
}

function formatAppError(e, { intl }) {
  const message = extractExceptionErrorMessage(e);
  const code = extractExceptionErrorCode(e);
  return silentFormatMessage(intl, {
    id: code,
    message: messageOrDefault(message, {
      intl,
      defaultId: 'error.appError',
    }),
  });
}

function formatRestError(e, { intl }) {
  const { message } = e;
  return messageOrDefault(message, { intl, defaultId: 'error.otherError' });
}

function silentFormatMessage(intl, { id, message, verbose, values }) {
  if (!intl.messages[id]) {
    return message || id;
  }
  const intlText = intl.formatMessage({ id, defaultMessage: message }, values);

  return verbose ? `${intlText}: ${message}` : intlText;
}

export function formatError(e, { intl }) {
  if (isRestError(e)) {
    return formatRestError(e, { intl });
  }
  if (isApolloError(e)) {
    if (e.networkError) {
      return silentFormatMessage(intl, { id: 'error.network' });
    }
    if (isOtherError(e)) {
      return formatOtherError(e, { intl });
    }
    if (isApiError(e)) {
      return formatApiError(e, { intl });
    }
    if (isAppError(e)) {
      return formatAppError(e, { intl });
    }
    const { code: firstCode, message: firstMessage } = getFirstExt(e);
    if (firstCode) {
      return silentFormatMessage(intl, {
        id: firstCode,
        message: firstMessage || e.message,
        verbose: true,
      });
    }
  }
  if (e.code) {
    return silentFormatMessage(intl, {
      id: e.code,
      message: e.message,
      values: e.values,
    });
  }
  if (e.message) {
    return silentFormatMessage(intl, {
      id: e.message,
      message: e.message,
    });
  }
  return e.message;
}

export function formatErrorWithMessage({ error, intl, messageId, message }) {
  const errorText = formatError(error, { intl });
  const messageText = intl.formatMessage(message || { id: messageId });
  return `${messageText} (${errorText})`;
}

export function makeError({ message, ...rest } = {}) {
  const err = new Error(message);
  merge(err, rest);
  return err;
}
