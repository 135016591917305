// `apollo-client` is imported from its own package - see UPGRADE_ISSUES.md for reasoning
import { v4 as uuidv4 } from 'uuid';

import { safeLocalStorage } from '../utils/localStorage';
import { config } from './config';
import { LOCAL_STORAGE_AUTH_KEY } from './constants';

export function makeBaseHttpHeaders() {
  const txref = uuidv4();
  const auth = safeLocalStorage.getItem(LOCAL_STORAGE_AUTH_KEY);
  return {
    // unique reference identifying request
    txref,
    'auth-token': auth && auth.token,
    'channel-origin': 'web',
    'channel-os': navigator.platform,
    'channel-clientversion': config.version,
  };
}
