import { Col, Row } from 'antd';
import classNames from 'classnames';
import { upperCase } from 'lodash-es';
import { useIntl } from 'react-intl';

import AdminConsoleTitle from '../components/AdminConsoleTitle';

export default function AdminConsoleContent({
  entityLabel,
  isEditing,
  titleText,
  titleId,
  titleIcon,
  titleIconProps,
  titleIconNode,
  headerRight,
  visible = true,
  children,
}) {
  const intl = useIntl();
  let title = titleText;
  if (titleId) {
    title = isEditing
      ? `${upperCase(
          intl.formatMessage({
            id: 'admin.commonDetail.editPrefix',
          })
        )} | ${upperCase(
          intl.formatMessage({
            id: titleId,
          })
        )}${entityLabel ? ` (${entityLabel})` : ''}`
      : intl.formatMessage({ id: titleId });
  }

  return (
    <div
      className="spaces-vert-norm2"
      style={visible ? {} : { display: 'none' }}
    >
      <div>
        <div
          className={classNames('AdminConsoleContent__Header', {
            'AdminConsoleContent__Header--Editing': isEditing,
          })}
        >
          <Row align="middle" justify="space-between">
            <Col>
              <AdminConsoleTitle
                title={title}
                icon={titleIcon}
                type="sub"
                iconProps={titleIconProps}
                iconNode={titleIconNode}
              />
            </Col>
            {headerRight && <Col>{headerRight}</Col>}
          </Row>
        </div>
      </div>
      <div className="spaces-vert-norm3_5">{children}</div>
      <div className="padding-vert-norm" />
    </div>
  );
}
