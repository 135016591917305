import { useQuery } from '@apollo/client';
import { isFunction, noop } from 'lodash-es';
import { useState } from 'react';
import { Route, useHistory } from 'react-router';

import { extractGraphqlEntity } from '../../../common/utils/graphqlUtils';
import { useMountAndUpdateEffect } from '../../../common/utils/hookUtils';
import { Spinner } from '../../../components/data/Spinner';
import { DataError } from '../../../components/data/dataStateHandlers';
import { useFlashMessageContext } from '../../../components/dialogs/FlashMessageProvider';
import { FormItemsOverrideContextProvider } from '../../../components/forms/FormItem';
import { NoLabelForm } from '../../../components/forms/forms';
import { AdminConsoleDetailHeaderButtons } from './detail/AdminConsoleDetailHeaderButtons';

function AdminConsoleDetailFormStateManager({
  value,
  graphqlToForm,
  formToGraphql,
  headerRightRef,
  save,
  saveSuccessMessageId,
  getAfterSaveRedirectRoute,
  sectionHomeRoute,
  steps,
  getStepsProps,
}) {
  const [viewMode, setViewMode] = useState(true);
  const initialValues = graphqlToForm(value);
  const { push } = useHistory();

  const { infoMessage } = useFlashMessageContext();
  const onSubmit = async values => {
    await save(value, formToGraphql(values, value));
    infoMessage({ contentId: saveSuccessMessageId });
    setViewMode(true);
    const newUrl = getAfterSaveRedirectRoute(value);
    if (newUrl) {
      push(newUrl);
    }
  };

  const stepsProps = getStepsProps(value);

  return (
    <NoLabelForm initialValues={initialValues} onSubmit={onSubmit}>
      <FormItemsOverrideContextProvider viewMode={viewMode}>
        <AdminConsoleDetailHeaderButtons
          viewMode={viewMode}
          setViewMode={setViewMode}
          initialValues={initialValues}
          headerRightRef={headerRightRef}
          sectionHomeRoute={sectionHomeRoute}
          formToGraphql={formToGraphql}
        />
        {steps.map(({ path, routeProps, component: C, props }) => (
          <Route key={path} path={path} {...routeProps}>
            {({ match }) => (
              <C
                {...stepsProps}
                viewMode={viewMode}
                visible={!!match}
                {...props}
              />
            )}
          </Route>
        ))}
      </FormItemsOverrideContextProvider>
    </NoLabelForm>
  );
}

export default function AdminConsoleDetail({
  query,
  variables,
  headerRightRef,
  graphqlToForm,
  formToGraphql,
  save,
  saveSuccessMessageId,
  getAfterSaveRedirectRoute = noop,
  sectionHomeRoute,
  steps = [],
  getStepsProps = noop,
  children,
}) {
  const { data, loading, error } = useQuery(query, { variables });
  const value = data && extractGraphqlEntity(data);

  const [key, setKey] = useState(0);
  useMountAndUpdateEffect({ onUpdate: () => setKey(old => old + 1) }, [value]);

  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <DataError error={error} />;
  }

  return (
    <>
      <AdminConsoleDetailFormStateManager
        key={key}
        value={value}
        headerRightRef={headerRightRef}
        graphqlToForm={graphqlToForm}
        formToGraphql={formToGraphql}
        save={save}
        saveSuccessMessageId={saveSuccessMessageId}
        getAfterSaveRedirectRoute={getAfterSaveRedirectRoute}
        sectionHomeRoute={sectionHomeRoute}
        steps={steps}
        getStepsProps={getStepsProps}
      />
      {isFunction(children) ? children({ account: value }) : children}
    </>
  );
}
