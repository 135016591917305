import {
  compact,
  isEmpty,
  isObject,
  merge,
  negate,
  some,
  toPairs,
} from 'lodash-es';

export function notificationSettingsGraphqlToForm({
  csConfirmMyself,
  ...rest
}) {
  return {
    values: compact([
      csConfirmMyself && { name: 'csConfirm', myself: true },
      ...toPairs(rest).map(([key, val]) => {
        if (
          isObject(val) &&
          (!isEmpty(val.other) ||
            some([val.myself, val.shipper, val.consignee], v => !!v))
        ) {
          return {
            name: key,
            ...val,
            other: !isEmpty(val.other),
            otherEmails: val.other.split(/,\s*/g),
          };
        }
        return null;
      }),
    ]),
  };
}

const DEFAULT_VALUE = {
  orderPlaced: { myself: false, shipper: false, consignee: false, other: '' },
  csConfirmMyself: false,
  pickedUp: { myself: false, shipper: false, consignee: false, other: '' },
  flightChange: { myself: false, shipper: false, consignee: false, other: '' },
  flightTenderTime: {
    myself: false,
    shipper: false,
    consignee: false,
    other: '',
  },
  recovered: { myself: false, shipper: false, consignee: false, other: '' },
  clearedCustoms: {
    myself: false,
    shipper: false,
    consignee: false,
    other: '',
  },
  outForDelivery: {
    myself: false,
    shipper: false,
    consignee: false,
    other: '',
  },
  revisedEta: { myself: false, shipper: false, consignee: false, other: '' },
  proofOfDelivery: {
    myself: false,
    shipper: false,
    consignee: false,
    other: '',
  },
  jobCancelled: { myself: false, shipper: false, consignee: false, other: '' },
};

export function notificationSettingsFormToGraphql({ values = [] } = {}) {
  const ret = merge({}, DEFAULT_VALUE);
  for (const {
    name,
    myself,
    shipper,
    consignee,
    other,
    otherEmails,
  } of values) {
    if (name === 'csConfirm') {
      ret.csConfirmMyself = myself;
    } else if (ret[name]) {
      ret[name] = {
        myself: !!myself,
        shipper: !!shipper,
        consignee: !!consignee,
        other:
          other && !isEmpty(otherEmails)
            ? otherEmails.filter(negate(isEmpty)).join(',')
            : '',
      };
    }
  }
  return ret;
}
