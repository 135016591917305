import { latLng } from '../../../utils/mapUtils';

export const DEFAULT_PROPS = {
  mapTypeId: 'ROADMAP',
  zoom: 4,
  center: latLng(39.8, -98.6),
  streetViewControl: false,
  // https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
  options: {
    maxZoom: 17,
    gestureHandling: 'greedy',
    clickableIcons: false,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
    ],
  },
};
