export const SortOrder = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export function makeSort(name, order) {
  return { name, order };
}

export function isSortedBy(sort, name) {
  return sort?.name === name;
}

export function getSortOrder(sort) {
  return sort?.order;
}
