import { Button, Col, Row, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory } from 'react-router';

import { EDIT_SHIPMENT_MUTATION } from '../../app/graphql/jobFormQueries';
import { JOB_QUERY } from '../../app/graphql/jobQueries';
import routes from '../../app/routes';
import { CustomLoaderButton } from '../../components/buttons/buttons';
import { useAsynchronousAction } from '../../components/data/asyncActionElements';
import { useResponsiveQueries } from '../../components/responsive/responsiveQueries';
import { forceSavedShipmentForm } from '../../forms/shipments/newShipment/savedShipmentsUtils';
import {
  BaseShipmentSubmitStateDialog,
  useShipmentSubmitState,
} from './useShipmentSubmitState';

export function useEditShipmentSubmit({ jobNumber }) {
  return useShipmentSubmitState({
    mutation: EDIT_SHIPMENT_MUTATION,
    mutationOptions: {
      refetchQueries: [JOB_QUERY],
      awaitRefetchQueries: true,
    },
    jobNumber,
  });
}

function ButtonWrapper({ tooltipId, children }) {
  return tooltipId ? (
    <Tooltip
      title={<FormattedMessage id={tooltipId} />}
      overlayClassName="ant-tooltip-custom-lines"
    >
      <div>{children}</div>
    </Tooltip>
  ) : (
    children
  );
}

function ConfirmOrderHeaderTBD({ action, onCancel }) {
  const media = useResponsiveQueries();
  const textId =
    media.xs || media.sm
      ? 'book.confirmOrder.saveShipment'
      : 'book.confirmOrder.saveShipment.long';

  return (
    <Row className="ConfirmOrderPage-Buttons">
      <Col className="ant-col__big">
        <ButtonWrapper tooltipId="book.confirmOrder.tbdWarning">
          <CustomLoaderButton
            className="ant-btn--round-left ant-btn__font-plus-2"
            type="primary"
            size="large"
            onClick={action.execute}
            data-subject="saved-shipment"
            data-action="resave"
          >
            <FormattedMessage id={textId} />
          </CustomLoaderButton>
        </ButtonWrapper>
      </Col>
      <Col className="ant-col__small">
        <Button
          className="ant-btn--round-right"
          size="large"
          onClick={onCancel}
          disabled={action.loading}
          data-subject="saved-shipment"
          data-action="cancel"
        >
          <FormattedMessage id="buttons.cancel" />
        </Button>
      </Col>
    </Row>
  );
}

function ConfirmOrderHeaderNormal({ action, onCancel }) {
  const media = useResponsiveQueries();
  const saveTextId = media.xs || media.sm ? 'buttons.save' : 'buttons.saveTbd';

  return (
    <Row className="ConfirmOrderPage-Buttons">
      <Col className="ant-col__small">
        <CustomLoaderButton
          className="ant-btn--round-left"
          size="large"
          onClick={() => action.execute({ forceTbd: true })}
          data-subject="saved-shipment"
          data-action="resave"
        >
          <FormattedMessage id={saveTextId} />
        </CustomLoaderButton>
      </Col>
      <Col className="ant-col__big">
        <CustomLoaderButton
          className="ant-btn__font-plus-2"
          type="primary"
          size="large"
          data-subject="saved-shipment"
          data-action="release"
          onClick={action.execute}
        >
          <FormattedMessage id="book.confirmOrder.confirmShipment" />
        </CustomLoaderButton>
      </Col>
      <Col className="ant-col__small">
        <Button
          className="ant-btn--round-right"
          size="large"
          data-subject="saved-shipment"
          data-action="cancel"
          onClick={onCancel}
          disabled={action.loading}
        >
          <FormattedMessage id="buttons.cancel" />
        </Button>
      </Col>
    </Row>
  );
}

function ConfirmOrderHeaderLoading() {
  return (
    <Row className="ConfirmOrderPage-Buttons">
      <Col className="ant-col__big">
        <CustomLoaderButton
          className="three-dots-lg ant-btn--round-left ant-btn--round-right ant-btn__font-plus-2"
          type="primary"
          size="large"
          loading
          disabled
        >
          <FormattedMessage id="book.confirmOrder.processing" />
        </CustomLoaderButton>
      </Col>
    </Row>
  );
}

function ConfirmOrderHeader({ shipment, action, onCancel }) {
  if (action.loading) {
    return <ConfirmOrderHeaderLoading action={action} onCancel={onCancel} />;
  }
  if (shipment.containsTbd) {
    return <ConfirmOrderHeaderTBD action={action} onCancel={onCancel} />;
  }
  return <ConfirmOrderHeaderNormal action={action} onCancel={onCancel} />;
}

export function EditShipmentSubmitDialog({ onConfirm, ...rest }) {
  const { push } = useHistory();

  const action = useAsynchronousAction(
    async ({ shipmentForm }, { forceTbd = false } = {}) => {
      const result = await onConfirm({
        shipmentForm: forceTbd
          ? forceSavedShipmentForm(shipmentForm)
          : shipmentForm,
      });
      if (result) {
        const { jobNumber } = result;
        push(generatePath(routes.shipmentDetail, { jobNumber }));
      }
    }
  );

  const confirmOrderPageProps = {
    headerComponent: ConfirmOrderHeader,
    action,
  };

  return (
    <BaseShipmentSubmitStateDialog
      onConfirm={onConfirm}
      confirmOrderPageProps={confirmOrderPageProps}
      {...rest}
    />
  );
}
