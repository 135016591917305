import { gql } from '@apollo/client';

export const GENERATE_REPORT_MUTATION = gql`
  mutation GenerateReportMutation($input: ReportGenerationInput!) {
    generateReport(input: $input) {
      items {
        requestNumber
        status
      }
    }
  }
`;
