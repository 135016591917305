import classNames from 'classnames';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { getLoggedIn } from '../app/redux/auth';
import ErrorBoundary from '../components/ErrorBoundary';
import { InnerContentScrollbars } from '../components/layout/InnerContentScrollbars';
import { Scrollbars } from '../components/layout/Scrollbars';
import Footer, { FooterTheme } from '../widgets/Footer';
import LoggedFooter from '../widgets/LoggedFooter';

export default function DetailPage({
  id,
  className,
  header,
  children,
  wrapInScrollbar,
}) {
  const Wrapper = wrapInScrollbar ? InnerContentScrollbars : Fragment;
  const loggedIn = useSelector(getLoggedIn);

  return (
    <div className="fixed-full-screen">
      <Scrollbars id={id} className={classNames('DetailPage', className)}>
        <div className="DetailPage-Content">
          {header}
          <div className="DetailPage-InnerContent">
            <Wrapper>
              <ErrorBoundary messageId="error.render.page">
                {children}
              </ErrorBoundary>
            </Wrapper>
          </div>
          {loggedIn ? <LoggedFooter /> : <Footer theme={FooterTheme.DARK} />}
        </div>
      </Scrollbars>
    </div>
  );
}
