import useLocalStorage from '@rehooks/local-storage';

import { useMountAndUpdateEffect } from '../common/utils/hookUtils';

// utility to safely access local storage
export const safeLocalStorage = {
  getItem(key) {
    try {
      return JSON.parse(global.localStorage.getItem(key));
    } catch (err) {
      return undefined;
    }
  },
};

export function useLocalStorageSynchronizer({ value, setValue, key }) {
  const [lcValue, setLCValue, deleteLCValue] = useLocalStorage(key);
  useMountAndUpdateEffect(
    {
      onMount: () => {
        if (lcValue) {
          setValue(lcValue);
        }
      },
      onUpdate: () => {
        if (value) {
          setLCValue(value);
        } else {
          deleteLCValue();
        }
      },
    },
    [value]
  );
}
