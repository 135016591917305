import { makeReference } from '@apollo/client';
import { every, isNull, merge, range, stubTrue, values } from 'lodash-es';

import { PAGE_ONE } from '../../config/constants';
import { log } from '../../utils/logger';

export function extractGraphqlEntity(data) {
  const vals = values(data);
  if (vals.length !== 1) {
    throw new Error(
      'Invalid usage - this function can be used only for queries with exactly one entity'
    );
  }
  return vals[0];
}

export function isGraphqlEntityEmpty(data) {
  return every(values(data), isNull);
}

// Apollo's `mergeObjects` is shallow, so if we want to merge deeply, we need a custom function
const mergeObjectsDeep = (...objs) => merge({}, ...objs);

export function mergeArraysByIndices(
  existing = [],
  incoming = [],
  { mergeObjects = mergeObjectsDeep } = {}
) {
  const length = Math.max(existing.length, incoming.length);
  return range(0, length).map(i => mergeObjects(existing[i], incoming[i]));
}

export function parseStoreFieldName(storeFieldName) {
  const chunks = storeFieldName.match(/^([a-zA-Z]+):?(.*)?/);
  if (chunks && chunks[2]) {
    const json = chunks[2].replace(/^\(/, '').replace(/\)$/, '');
    try {
      return JSON.parse(json);
    } catch (e) {
      log.warn('Unexpected Apollo cache storeFieldName: ', json, e);
      return json;
    }
  }
  return null;
}

/**
 * Function to delete all instances of the query ${queryName} from the cache (in case some mutation invalidates them)
 * `shouldInvalidate` can limit which instances are actually deleted based on args (for example only for one account)
 */
export function deleteQueryFromCache(
  cache,
  queryName,
  shouldInvalidate = stubTrue
) {
  const id = cache.identify(makeReference('ROOT_QUERY'));
  cache.modify({
    id,
    fields: {
      [queryName]: (fieldValue, { fieldName, storeFieldName, DELETE }) => {
        const args = parseStoreFieldName(storeFieldName);
        if (shouldInvalidate({ args })) {
          log.debug(`Deleting ${fieldName}\n`, args);
          return DELETE;
        }
        return fieldValue;
      },
    },
  });
}

/**
 * Function to update all instances of the query ${queryName}
 * `getNewValue` should provide new value based on the old value and query arguments
 */
export function updateQuery(cache, fragment, newObject) {
  const id = cache.identify(newObject);
  cache.writeFragment({ id, fragment, data: newObject });
}

export function mergePaginatedGraphqlResult(
  existing,
  incoming,
  { variables: { pageIndex, pageSize } }
) {
  // Throw away the existing data if the incoming data is page 1 and smaller than page size
  if (pageIndex === PAGE_ONE && incoming.length < pageSize) {
    return incoming;
  }

  const combinedData = [...(existing || [])];
  const start = (pageIndex - PAGE_ONE) * pageSize;
  const end = start + incoming.length;
  for (let i = start; i < end; i++) {
    combinedData[i] = incoming[i - start];
  }
  return combinedData;
}
