import { fromPairs, get, isString } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getLanguage } from '../../app/redux/settings';
import { optionalDecorator } from '../../common/utils/funcUtils';
import { normalizeString } from '../../common/utils/stringUtils';
import { flattenSelectOptions, mapSelectOptions } from './selectUtils';

export function useLabelFilter() {
  const lang = useSelector(getLanguage);
  const filterOption = useCallback(
    (inputValue, option) => {
      const searched = normalizeString(inputValue, lang);
      // eslint-disable-next-line no-nested-ternary
      const label = isString(option.label)
        ? option.label
        : isString(option.title)
          ? option.title
          : '';
      const text = normalizeString(label, lang);
      return text.indexOf(searched) > -1;
    },
    [lang]
  );

  return { filterOption };
}

export function useObjectValues({ options, optionKey }) {
  const simpleOptions = useMemo(
    () =>
      mapSelectOptions(options, opt => ({
        ...opt,
        value: get(opt.value, optionKey),
      })),
    [optionKey, options]
  );
  const codeToValueMap = useMemo(
    () =>
      fromPairs(
        flattenSelectOptions(options).map(opt => [
          get(opt.value, optionKey),
          opt.value,
        ])
      ),
    [optionKey, options]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const parseValue = useCallback(
    optionalDecorator(val => codeToValueMap[val]),
    [codeToValueMap]
  );
  const formatValue = useCallback(val => get(val, optionKey), [optionKey]);

  return { options: simpleOptions, formatValue, parseValue };
}
