import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { useCallback, useState } from 'react';
import URI from 'urijs';

import { PASSWORD_CHANGE_VALIDATION_QUERY } from '../../app/graphql/authQueries';
import { extractGraphqlEntity } from '../../common/utils/graphqlUtils';
import { useFlashMessageContext } from '../../components/dialogs/FlashMessageProvider';

function mapInputValues({ password, pin }) {
  return { password, pin: pin.join('') };
}

async function validateUserPassword(client, authToken, value) {
  const { data: dt } = await client.query({
    query: PASSWORD_CHANGE_VALIDATION_QUERY,
    variables: { password: value, authToken },
  });
  return extractGraphqlEntity(dt);
}

export function useChangePasswordFormProps({
  token,
  email,
  verifyQuery,
  changeMutation,
  successMessageId,
}) {
  const client = useApolloClient();
  const [preparing, setPreparing] = useState(true);
  const { data, error } = useQuery(verifyQuery, {
    variables: { token, email },
    onCompleted: async d => {
      try {
        const authToken = d && extractGraphqlEntity(d);
        const emptyPassword = '';
        // prefetching initial validation messages for an empty password
        await validateUserPassword(client, authToken, emptyPassword);
      } finally {
        setPreparing(false);
      }
    },
    onError: () => {
      setPreparing(false);
    },
  });
  const authToken = data && extractGraphqlEntity(data);

  const [changePasswordMutation] = useMutation(changeMutation);
  const changePassword = useCallback(
    values =>
      changePasswordMutation({
        variables: { input: mapInputValues(values), authToken },
      }),
    [changePasswordMutation, authToken]
  );

  const { successMessage } = useFlashMessageContext();
  const onChangePasswordSuccess = useCallback(
    () =>
      successMessage({
        contentId: successMessageId,
      }),
    [successMessage, successMessageId]
  );

  const validatePassword = useCallback(
    value => validateUserPassword(client, authToken, value),
    [client, authToken]
  );

  return {
    error,
    loading: preparing,
    changePassword,
    onChangePasswordSuccess,
    validatePassword,
  };
}

export function useChangePasswordURLParams() {
  const { token, ...uri } = URI().query(true);
  const email = uri['user-email'];
  return { token, email };
}
