import { useApolloClient, useQuery } from '@apollo/client';
import { every, get, identity, isArray, isObject, mapValues } from 'lodash-es';
import React, { createContext, useContext } from 'react';

import {
  deleteQueryFromCache,
  extractGraphqlEntity,
} from '../../../common/utils/graphqlUtils';
import { useMountEffect } from '../../../common/utils/hookUtils';
import { renderDataStateIndicator } from '../../data/dataStateHandlers';

export const DynamicFormSchemaContext = createContext({
  schema: null,
  optionsQuery: null,
  fieldValidationQuery: null,
  autofillQuery: null,
});

export function DynamicFormSchemaProvider({
  query,
  optionsQuery,
  fieldValidationQuery,
  queryName,
  autofillQuery,
  variables,
  children,
  skip,
  clearCache,
  modifySchema = identity,
}) {
  const { data, loading, error } = useQuery(query, {
    variables,
    skip,
  });

  const client = useApolloClient();

  useMountEffect(() => {
    if (clearCache) {
      deleteQueryFromCache(client.cache, queryName);
    }
  });

  const indicator = renderDataStateIndicator({ data, loading, error });
  if (indicator) {
    return indicator;
  }

  const schema = data && modifySchema(extractGraphqlEntity(data));

  const value = { schema, optionsQuery, fieldValidationQuery, autofillQuery };

  return (
    <DynamicFormSchemaContext.Provider value={value}>
      {children}
    </DynamicFormSchemaContext.Provider>
  );
}

function isFormField(val) {
  // eslint-disable-next-line no-underscore-dangle
  return val && val.__typename === 'FormField';
}

const DEFAULT_FORM_FIELD = {
  validation: {
    rules: {
      required: false,
    },
  },
};

/**
 * Gets schema for a given field
 */
export function useDynamicFormSchemaFieldDefinition({ schemaName }) {
  const { schema } = useContext(DynamicFormSchemaContext);
  // No `schemaName` is an equivalent to Apollo's `skip`
  if (!schemaName) {
    return DEFAULT_FORM_FIELD;
  }
  if (!schema) {
    // While schema is loading, return default
    return DEFAULT_FORM_FIELD;
  }

  const fieldDef = get(schema, schemaName);
  if (!isFormField(fieldDef)) {
    throw new Error(`Field ${schemaName} isn't a correct static field name`);
  }

  return fieldDef;
}

function isDynamicFormField(val) {
  // eslint-disable-next-line no-underscore-dangle
  return val && val.__typename === 'DynamicFormField';
}

export function useDynamicFormSchemaDynamicFieldsDefinition({ schemaName }) {
  const { schema } = useContext(DynamicFormSchemaContext);
  if (!schema) {
    // While schema is loading, return default
    return [];
  }

  const fieldDefs = get(schema, schemaName);
  if (!isArray(fieldDefs) || !every(fieldDefs, isDynamicFormField)) {
    throw new Error(
      `Field ${schemaName} isn't a correct dynamic field group name`
    );
  }

  return fieldDefs;
}

export function mapFormSchema(val, mapper) {
  if (isFormField(val)) {
    return mapper(val);
  }
  if (isObject(val)) {
    return mapValues(val, subval => mapFormSchema(subval, mapper));
  }
  return val;
}
