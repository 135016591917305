import { Tooltip } from 'antd';
import classNames from 'classnames';
import { noop } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import { withTranslatedOptions } from '../forms/formDecorators';

function SegmentedControlInner({
  options,
  value,
  onChange,
  className,
  ...rest
}) {
  if (options.length < 2 || options.length > 5) {
    throw new Error('Unsupported number of options, must be 2-5');
  }

  return (
    <div
      className={classNames(
        'SegmentedControl',
        `num-items-${options.length}`,
        className
      )}
      {...rest}
    >
      <div className="SegmentedControl-Inner">
        {options.map(opt => (
          <Tooltip
            key={opt.value}
            title={
              opt.tooltipId ? <FormattedMessage id={opt.tooltipId} /> : null
            }
            {...opt.tooltipProps}
          >
            <div
              className={classNames(
                'SegmentedControl-Segment',
                {
                  active: opt.value === value,
                  disabled: opt.disabled,
                },
                opt.className
              )}
              onClick={opt.disabled ? noop : () => onChange(opt.value)}
              {...opt.props}
            >
              {opt.label}
            </div>
          </Tooltip>
        ))}
        <div className="SegmentedControl-ActiveIndicator" />
      </div>
    </div>
  );
}

const SegmentedControl = withTranslatedOptions(SegmentedControlInner);
export default SegmentedControl;
