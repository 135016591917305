import { Alert, Result } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import { ErrorCode } from '../../app/common/ErrorCode';
import { isGraphqlEntityEmpty } from '../../common/utils/graphqlUtils';
import {
  extractExceptionErrorCode,
  formatErrorWithMessage,
} from '../../utils/errorUtils';
import { Spinner, SpinnerType } from './Spinner';

export function DataError({ error }) {
  const intl = useIntl();
  const errorMsg = formatErrorWithMessage({
    error,
    intl,
    messageId: 'error.loadingData',
  });
  return <Alert type="error" message={errorMsg} />;
}

/**
 * Returns a special element to render if a state of data is not ready to be rendered
 */
export function renderDataStateIndicator({ loading, error, data }) {
  if (loading) {
    return <Spinner type={SpinnerType.ROW} />;
  }

  const isNotFoundError =
    error && extractExceptionErrorCode(error) === ErrorCode.NO_DATA_FOUND;

  if (error && !isNotFoundError) {
    return <DataError error={error} />;
  }

  if (isNotFoundError || (data && isGraphqlEntityEmpty(data))) {
    return (
      <Result
        status="404"
        title="404"
        subTitle={<FormattedMessage id="error.notFound" />}
      />
    );
  }

  return null;
}

export function DataStateIndicatorGuard({ queryResult, children }) {
  const indicator = renderDataStateIndicator(queryResult);
  if (indicator) {
    return indicator;
  }
  return children(queryResult.data);
}
