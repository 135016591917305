import { Button, Col, Row, Tooltip, Typography } from 'antd';
import { head, values } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { generatePath, useHistory } from 'react-router';

import { CREATE_SHIPMENT_MUTATION } from '../../app/graphql/jobFormQueries';
import routes from '../../app/routes';
import { useMountEffect } from '../../common/utils/hookUtils';
import { FAIcon } from '../../components/adapters/fontAwesomeAdapters';
import { CustomLoaderButton } from '../../components/buttons/buttons';
import { useAsynchronousAction } from '../../components/data/asyncActionElements';
import SimpleDialog, {
  SimpleDialogLabel,
} from '../../components/dialogs/SimpleDialog';
import { useAccountOptions } from '../../components/domainSpecific/accountElements';
import { NoLabelForm } from '../../components/forms/forms';
import { FormItemSelect } from '../../components/forms/selects';
import { useResponsiveQueries } from '../../components/responsive/responsiveQueries';
import { InlineLink } from '../../components/typography';
import { forceSavedShipmentForm } from '../../forms/shipments/newShipment/savedShipmentsUtils';
import logoImg from '../../resources/img/quick-online-logo-q.svg';
import {
  BaseShipmentSubmitStateDialog,
  useShipmentSubmitState,
} from './useShipmentSubmitState';

export function AccountSelectionDialog({ value, onChange, customerAccounts }) {
  useMountEffect(() => {
    if (customerAccounts.length === 1) {
      onChange(customerAccounts[0]);
    }
  }, [onChange, customerAccounts]);

  const { options: accountOptions } = useAccountOptions({
    filter: account => account.newOrder.enabled,
  });

  return (
    <SimpleDialog
      className="NewShipment-SelectAccountDialog"
      icon={<img src={logoImg} alt="" />}
      visible={!value}
      centered
      mask
    >
      <Typography.Title level={1} className="sm margin-md">
        <FormattedMessage id="book.newShipment.accountDialog.title" />
      </Typography.Title>
      <SimpleDialogLabel>
        <FormattedMessage id="book.newShipment.accountDialog.subtitle" />
      </SimpleDialogLabel>
      <NoLabelForm
        onValuesChange={ch => onChange(head(values(ch)))}
        className="no-margin-form-items"
      >
        <FormItemSelect
          name="account"
          labelId="book.newShipment.accountDialog.account"
          rules={[{ required: true }]}
          options={accountOptions}
          optionKey="number"
          formItemComponentProps={{
            dropdownClassName: 'AccountSelect__Dropdown',
          }}
        />
      </NoLabelForm>
      <InlineLink
        className="cancel-button"
        textId="buttons.cancel"
        to={routes.monitor}
      />
    </SimpleDialog>
  );
}

export function useNewShipmentSubmit() {
  return useShipmentSubmitState({
    mutation: CREATE_SHIPMENT_MUTATION,
  });
}

function ButtonWrapper({ tooltipId, children }) {
  return tooltipId ? (
    <Tooltip
      title={<FormattedMessage id={tooltipId} />}
      overlayClassName="ant-tooltip-custom-lines"
    >
      <div>{children}</div>
    </Tooltip>
  ) : (
    children
  );
}

function ConfirmOrderHeaderTBD({ action, onCancel }) {
  const media = useResponsiveQueries();
  const textId =
    media.xs || media.sm
      ? 'book.confirmOrder.saveShipment'
      : 'book.confirmOrder.saveShipment.long';

  return (
    <Row className="ConfirmOrderPage-Buttons">
      <Col className="ant-col__big">
        <ButtonWrapper tooltipId="book.confirmOrder.tbdWarning">
          <CustomLoaderButton
            className="ant-btn--round-left ant-btn__font-plus-2"
            type="primary"
            size="large"
            onClick={action.execute}
            data-subject="new-shipment"
            data-action="createSaved"
          >
            <FormattedMessage id={textId} />
          </CustomLoaderButton>
        </ButtonWrapper>
      </Col>
      <Col className="ant-col__small">
        <Button
          className="ant-btn--round-right"
          size="large"
          onClick={onCancel}
          disabled={action.loading}
          data-subject="new-shipment"
          data-action="cancel"
        >
          <FormattedMessage id="buttons.cancel" />
        </Button>
      </Col>
    </Row>
  );
}

function ConfirmOrderHeaderNormal({ action, onCancel }) {
  const media = useResponsiveQueries();
  const saveTextId = media.xs || media.sm ? 'buttons.save' : 'buttons.saveTbd';

  return (
    <Row className="ConfirmOrderPage-Buttons">
      <Col className="ant-col__small">
        <CustomLoaderButton
          className="ant-btn--round-left"
          size="large"
          onClick={() => action.execute({ forceTbd: true })}
          data-subject="new-shipment"
          data-action="createSaved"
        >
          <FormattedMessage id={saveTextId} />
        </CustomLoaderButton>
      </Col>
      <Col className="ant-col__big">
        <CustomLoaderButton
          className="ant-btn__font-plus-2"
          type="primary"
          size="large"
          onClick={() => action.execute()}
          data-subject="new-shipment"
          data-action="save"
        >
          <FormattedMessage id="book.confirmOrder.confirmShipment" />
        </CustomLoaderButton>
      </Col>
      <Col className="ant-col__small">
        <Button
          className="ant-btn--round-right"
          size="large"
          onClick={onCancel}
          disabled={action.loading}
          data-subject="new-shipment"
          data-action="cancel"
        >
          <FormattedMessage id="buttons.cancel" />
        </Button>
      </Col>
    </Row>
  );
}

function ConfirmOrderHeaderLoading() {
  return (
    <Row className="ConfirmOrderPage-Buttons">
      <Col className="ant-col__big">
        <CustomLoaderButton
          className="three-dots-lg ant-btn--round-left ant-btn--round-right ant-btn__font-plus-2"
          type="primary"
          size="large"
          loading
          disabled
        >
          <FormattedMessage id="book.confirmOrder.processing" />
        </CustomLoaderButton>
      </Col>
    </Row>
  );
}

function ConfirmOrderHeader({ shipment, action, onCancel }) {
  if (action.loading) {
    return <ConfirmOrderHeaderLoading action={action} onCancel={onCancel} />;
  }
  if (shipment.containsTbd) {
    return <ConfirmOrderHeaderTBD action={action} onCancel={onCancel} />;
  }
  return <ConfirmOrderHeaderNormal action={action} onCancel={onCancel} />;
}

export function NewShipmentSubmitDialog({
  onConfirm,
  savedShipmentModeActive,
  ...rest
}) {
  const { push } = useHistory();

  const action = useAsynchronousAction(
    async ({ shipmentForm }, { forceTbd = false } = {}) => {
      const result = await onConfirm({
        shipmentForm: forceTbd
          ? forceSavedShipmentForm(shipmentForm)
          : shipmentForm,
      });
      if (result) {
        const { jobNumber } = result;
        push(generatePath(routes.shipmentDetailSuccess, { jobNumber }));
      }
    }
  );

  const confirmOrderPageProps = {
    headerComponent: ConfirmOrderHeader,
    action,
  };

  return (
    <BaseShipmentSubmitStateDialog
      onConfirm={onConfirm}
      confirmOrderPageProps={confirmOrderPageProps}
      optimizingRouteDialogProps={
        savedShipmentModeActive
          ? {
              preparingLabelId:
                'book.newShipment.submit.preparingSavedShipment',
            }
          : {}
      }
      {...rest}
    />
  );
}

export function OrderSuccessDialog({ onOk }) {
  return (
    <SimpleDialog
      icon={<FAIcon icon="check-circle" />}
      className="OrderSuccessDialog"
      centered
      mask
    >
      <Typography.Title level={1}>
        <FormattedMessage id="book.newShipment.successDialog.title" />
      </Typography.Title>
      <Typography.Title level={4} className="margin-lg">
        <FormattedMessage id="book.newShipment.successDialog.subtitle" />
      </Typography.Title>
      <div className="margin-hor-norm3" style={{ alignSelf: 'stretch' }}>
        <Button
          type="primary"
          size="large"
          onClick={onOk}
          data-subject="order-success"
          data-action="ok"
        >
          <FormattedMessage id="buttons.ok" />
        </Button>
      </div>
    </SimpleDialog>
  );
}
