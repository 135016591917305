function makeKey(coords) {
  return `${coords.lat}-${coords.lng}`;
}

export function makeMarker(coords, otherProps) {
  return {
    key: makeKey(coords),
    coords,
    ...otherProps,
  };
}

export function makePolyline(path, otherProps) {
  return { key: path.map(makeKey).join(','), path, ...otherProps };
}
