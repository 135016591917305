import classNames from 'classnames';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { getLoggedIn } from '../app/redux/auth';
import routes from '../app/routes';
import ErrorBoundary from '../components/ErrorBoundary';
import { InnerContentScrollbars } from '../components/layout/InnerContentScrollbars';
import {
  HorizontalTrackTypes,
  Scrollbars,
} from '../components/layout/Scrollbars';
import { useSilentLogout } from '../hooks/data/auth';
import NonLoggedFooter from '../widgets/NonLoggedFooter';
import NonLoggedHeader from '../widgets/NonLoggedHeader';
import NonLoggedTopBar from '../widgets/NonLoggedTopBar';

export default function NonLoggedPage({
  id,
  className,
  children,
  logoutUser = false,
}) {
  const loggedIn = useSelector(getLoggedIn);
  const logout = useSilentLogout();
  useEffect(() => {
    if (logoutUser && loggedIn) {
      logout();
    }
  }, [loggedIn, logoutUser, logout]);
  if (!logoutUser && loggedIn) {
    return <Redirect to={routes.root} />;
  }

  return (
    <Scrollbars
      id={id}
      className={classNames('NonLoggedPage', className)}
      horizontal={HorizontalTrackTypes.BOTTOM}
    >
      <div className="NonLoggedPage-Content">
        <NonLoggedTopBar />
        <NonLoggedHeader />
        <div className="NonLoggedPage-InnerContent">
          <InnerContentScrollbars>
            <ErrorBoundary messageId="error.render.page">
              <div className="NonLoggedPage-ScrollContent">{children}</div>
            </ErrorBoundary>
          </InnerContentScrollbars>
        </div>
        <NonLoggedFooter />
      </div>
    </Scrollbars>
  );
}
