import { useGoogleMap } from '@react-google-maps/api';
import { noop } from 'lodash-es';
import { useContext } from 'react';

import { useMountEffect } from '../../../common/utils/hookUtils';
import { createMapBounds } from '../../../utils/mapUtils';
import { GoogleMapClustererContext } from './googleMapClustering';

export function GoogleMapOptimalBoundsSetter({ coordinates }) {
  const map = useGoogleMap();
  const clusterer = useContext(GoogleMapClustererContext);

  // Bounds will only be set on mount
  // If it's desired to be done again later, caller of this component is responsible to remount the component
  // (for example during changing filters)
  useMountEffect(() => {
    if (map) {
      const bounds = createMapBounds(coordinates);
      const previousZoom = map.getZoom();
      map.fitBounds(bounds);
      const currentZoom = map.getZoom();
      if (clusterer && previousZoom !== currentZoom) {
        const repaint = () => clusterer.repaint();
        const listener = window.google.maps.event.addListenerOnce(
          map,
          'idle',
          repaint
        );
        return () => window.google.maps.event.removeListener(listener);
      }
    }
    return noop;
  });

  return null;
}
