import { merge } from 'lodash-es';

import {
  SHIPMENT_FORM_AUTOFILL_QUERY,
  SHIPMENT_FORM_GET_OPTIONS_QUERY,
  SHIPMENT_FORM_SCHEMA_QUERY,
  VALIDATE_SHIPMENT_FORM_FIELD_QUERY,
} from '../../../app/graphql/jobFormQueries';
import { DynamicFormSchemaProvider } from '../../../components/forms/dynamic/dynamicFormSchema';

const REQUIRED = { validation: { rules: { required: true } } };
function makeDGFieldsRequired(schema) {
  return merge({}, schema, {
    serviceInformation: {
      dangerousGoods: REQUIRED,
      exceptedQuantity: REQUIRED,
      unNumber: REQUIRED,
    },
  });
}

export default function NewShipmentFormSchemaProvider({ account, children }) {
  return (
    <DynamicFormSchemaProvider
      query={SHIPMENT_FORM_SCHEMA_QUERY}
      optionsQuery={SHIPMENT_FORM_GET_OPTIONS_QUERY}
      fieldValidationQuery={VALIDATE_SHIPMENT_FORM_FIELD_QUERY}
      autofillQuery={SHIPMENT_FORM_AUTOFILL_QUERY}
      variables={account && { accountNumber: account.number }}
      skip={!account}
      modifySchema={makeDGFieldsRequired}
      queryName="newShipmentAsyncFieldValidation"
      clearCache
    >
      {children}
    </DynamicFormSchemaProvider>
  );
}
