import { FAIcon } from '../../../../components/adapters/fontAwesomeAdapters';
import { FormItemsOverrideContextProvider } from '../../../../components/forms/FormItem';
import AdminUserContactInformationFormSection from '../forms/AdminUserContactInformationFormSection';
import { AdminUserLinkedAccountsFormSections } from '../forms/AdminUserLinkedAccountsFormSections';
import AdminUserSpecificSettingsFormSection from '../forms/AdminUserSpecificSettingsFormSection';
import AdminAddUserStepTemplate from './AdminAddUserStepTemplate';

export default function AdminAddUserConfirmation({
  onSave,
  onBack,
  onCancel,
  values,
}) {
  return (
    <AdminAddUserStepTemplate
      titleId="admin.userManagement.addUser.confirmProfile"
      titleIconNode={
        <div className="flex-center" style={{ gap: 2 }}>
          <FAIcon icon="address-card" className="icon-18" />
          <FAIcon icon="check" className="icon-12" />
        </div>
      }
      onSave={onSave}
      onBack={onBack}
      onCancel={onCancel}
      values={values}
      okButtonLabelId="buttons.confirmNext"
      allowSaveEmpty
    >
      <FormItemsOverrideContextProvider viewMode>
        <AdminUserContactInformationFormSection viewMode />
        <AdminUserSpecificSettingsFormSection viewMode />
        <AdminUserLinkedAccountsFormSections viewMode user={values} />
      </FormItemsOverrideContextProvider>
    </AdminAddUserStepTemplate>
  );
}
