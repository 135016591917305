import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { logoutUser } from '../app/redux/auth';
import { useFlashMessageContext } from '../components/dialogs/FlashMessageProvider';
import { RestError } from '../utils/errorUtils';
import { log } from '../utils/logger';
import { HttpStatusCodes } from './constants';
import { makeBaseHttpHeaders } from './httpHeaders';

async function parseHttpError(res) {
  if (res.ok) {
    return null;
  }
  const defaultError = new Error(`${res.status}: ${res.statusText}`);
  try {
    const errorData = await res.json();
    if (errorData.type === RestError.type) {
      return new RestError(errorData);
    }
    return defaultError;
  } catch {
    return defaultError;
  }
}

export function useRichFetch() {
  const { errorMessage } = useFlashMessageContext();
  const dispatch = useDispatch();

  return useCallback(
    async (url, options) => {
      const { headers, ...optionsRest } = options;
      const finalOptions = {
        ...optionsRest,
        headers: { ...headers, ...makeBaseHttpHeaders() },
      };

      const { txref } = finalOptions.headers;

      log.debug(`txref: ${txref}, url: ${url}`, options);

      const res = await fetch(url, finalOptions);

      const err = await parseHttpError(res);
      if (!res.ok) {
        log.error(`txref: ${txref}, url: ${url}`, err);
        if (err.statusCode === HttpStatusCodes.UNAUTHENTICATED) {
          dispatch(logoutUser());
          errorMessage({ contentId: 'error.tokenExpired' });
          err.handled = true;
        }
        throw err;
      }

      return res;
    },
    [dispatch, errorMessage]
  );
}
