import { useQuery } from '@apollo/client';
import { Col, Row } from 'antd';
import classNames from 'classnames';
import { startsWith } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Prompt,
  generatePath,
  matchPath,
  useHistory,
  useRouteMatch,
} from 'react-router';

import { shipmentConversions } from '../app/data/shipmentConversions';
import { REVERSED_JOB_FORM_QUERY } from '../app/graphql/jobQueries';
import routes from '../app/routes';
import { extractGraphqlEntity } from '../common/utils/graphqlUtils';
import { useMountEffect } from '../common/utils/hookUtils';
import Whitespace from '../components/Whitespace';
import { DataStateIndicatorGuard } from '../components/data/dataStateHandlers';
import { DialogsParentContext } from '../components/dialogs/DialogsParentContext';
import { SimpleConfirmDialog } from '../components/dialogs/SimpleConfirmDialog';
import { OverrideSettingsContextProvider } from '../components/domainSpecific/settingsElements';
import { FormSanitizationProvider } from '../components/forms/FormSanitizationContext';
import { DynamicFormDependenciesProvider } from '../components/forms/dynamic/dynamicFormDependencies';
import { FormInterceptorContext } from '../components/forms/forms';
import { InnerContentScrollbars } from '../components/layout/InnerContentScrollbars';
import { FlexCol, TitleWithExtra } from '../components/layout/layoutElements';
import {
  StepsWithContent,
  isFirstStep,
  isLastStep,
} from '../components/nav/StepsWithContent';
import { InlineLink } from '../components/typography';
import {
  NewShipmentSubmitDialog,
  useNewShipmentSubmit,
} from '../dialogs/shipments/newShipmentDialogs';
import NewShipmentFormSchemaProvider from '../forms/shipments/newShipment/NewShipmentFormSchemaProvider';
import { SavedShipmentProvider } from '../forms/shipments/newShipment/SavedShipmentProvider';
import { NEW_SHIPMENT_STEPS } from '../forms/shipments/newShipment/newShipmentConstants';
import {
  CancelShipmentLink,
  NewShipmentBottomControls,
  NewShipmentFormButtons,
} from '../forms/shipments/newShipment/newShipmentElements';
import {
  isSavedShipmentModeActive,
  shouldForceTbdServiceType,
} from '../forms/shipments/newShipment/savedShipmentsUtils';
import useNewShipmentAsyncConfig from '../forms/shipments/newShipment/useNewShipmentAsyncConfig';
import useNewShipmentFormState from '../forms/shipments/newShipment/useNewShipmentFormState';
import useNewShipmentStateUpdaters from '../forms/shipments/newShipment/useNewShipmentStateUpdaters';
import useNewShipmentSteps from '../forms/shipments/newShipment/useNewShipmentSteps';
import useSubmitNewShipmentStep from '../forms/shipments/newShipment/useSubmitNewShipmentStep';
import { useShipmentTemplateChangeDetectionInterceptors } from '../forms/shipments/shipmentTemplates/ShipmentTemplateChangedDialog';
import ShipmentTemplatesCard from '../forms/shipments/shipmentTemplates/ShipmentTemplatesCard';
import { useAccount } from '../hooks/data/auth';
import { cssVariables } from '../styles/cssVariables';
import BaseLoggedPage from '../templates/BaseLoggedPage';
import { pxToNumber } from '../utils/cssUtils';
import ShipmentOrderPreviewCard from '../widgets/shipments/ShipmentOrderPreviewCard';
import { PageExitDialogType } from './nav/PageExitDialog';

function ReverseShipmentPageWrapper({ account, title, children }) {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const computedIsDialogOpen = isDialogOpen || !account;
  const isPartnerAccess = account?.partner;

  return (
    <BaseLoggedPage
      id="NewShipmentPage"
      className={classNames('height-extending-error', {
        'has-dialog': computedIsDialogOpen,
      })}
    >
      <DialogsParentContext.Provider
        value={{ isDialogOpen: computedIsDialogOpen, setDialogOpen }}
      >
        <FlexCol className="Flex1">
          {isPartnerAccess && (
            <div className="NewShipmentPage__PartnerAccessTopBar">
              <FormattedMessage id="book.newShipment.partnerAccess" />
              <Whitespace />|<Whitespace />
              {account.name}
              <Whitespace />
              {account.number}
            </div>
          )}
          {title}
          <InnerContentScrollbars>{children}</InnerContentScrollbars>
        </FlexCol>
      </DialogsParentContext.Provider>
    </BaseLoggedPage>
  );
}

function ReverseShipmentExitPrompt({ match, dialogOpen }) {
  const backUrl = generatePath(routes.shipmentDetail, {
    jobNumber: match.params.jobNumber,
  });

  return (
    <Prompt
      message={({ pathname }) =>
        startsWith(pathname, match.url) || // Reverse shipment subpages are allowed
        (dialogOpen && pathname === backUrl) || // Going back from the initial dialog is allowed
        (pathname !== backUrl &&
          matchPath(pathname, { path: routes.shipmentDetail })) // Redirect to detail in the end is allowed
          ? true
          : PageExitDialogType.CANCEL_SHIPMENT
      }
    />
  );
}

function ReverseShipmentProviders({
  values,
  account,
  unitSystem,
  shipmentTemplateChangeDetection,
  shipmentResult,
  children,
}) {
  const savedShipmentModeActive = isSavedShipmentModeActive(values);
  const forceTbdServiceType = shouldForceTbdServiceType(values);

  return (
    <FormInterceptorContext.Provider
      value={{
        onBlur: shipmentTemplateChangeDetection.onBlur,
        onKeyDown: shipmentTemplateChangeDetection.onKeyDown,
      }}
    >
      <NewShipmentFormSchemaProvider account={account}>
        <DataStateIndicatorGuard queryResult={shipmentResult}>
          {() => (
            <DynamicFormDependenciesProvider values={values}>
              <SavedShipmentProvider
                active={savedShipmentModeActive}
                forceTbdServiceType={forceTbdServiceType}
              >
                <OverrideSettingsContextProvider unitSystem={unitSystem}>
                  <FormSanitizationProvider>
                    {children}
                  </FormSanitizationProvider>
                </OverrideSettingsContextProvider>
              </SavedShipmentProvider>
            </DynamicFormDependenciesProvider>
          )}
        </DataStateIndicatorGuard>
      </NewShipmentFormSchemaProvider>
    </FormInterceptorContext.Provider>
  );
}

function ReverseShipmentInitialFormValuesSetter({
  forms,
  setAccount,
  setUnitSystem,
  refreshValues,
  shipmentResult,
}) {
  const shipmentForm =
    shipmentResult.data &&
    shipmentConversions.graphQLToForm(
      extractGraphqlEntity(shipmentResult.data)
    );

  useMountEffect(() => {
    setAccount(shipmentForm.account);
    forms[0].form.setFieldsValue(shipmentForm.origin);
    forms[1].form.setFieldsValue(shipmentForm.destination);
    forms[2].form.setFieldsValue(shipmentForm.serviceInformation);
    forms[3].form.setFieldsValue(shipmentForm.packageList);
    forms.forEach(({ formRef }) => {
      if (formRef.current) {
        formRef.current.forceUpdate();
      }
    });
    setUnitSystem(shipmentForm.unitSystem);
    refreshValues();
  });

  // Account in the detail doesn't contain all information, so we will enrich it
  const account = useAccount(shipmentForm.account.number);
  useEffect(() => {
    setAccount(account);
    refreshValues();
    // Only listen to account change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return null;
}

function ReverseShipmentAttentionDialog({ jobNumber, open, onClose }) {
  const { push } = useHistory();
  const backLink = generatePath(routes.shipmentDetail, { jobNumber });

  return (
    <SimpleConfirmDialog
      open={open}
      onClose={() => {
        push(backLink);
      }}
      onOk={onClose}
      text={
        <FormattedMessage
          id="shipmentDetail.reverseOrder.dialog.text"
          values={{
            jobNumber: <InlineLink to={backLink}>{jobNumber}</InlineLink>,
          }}
        />
      }
      cancelTextId="buttons.cancel"
      cancelColProps={{ flex: 13 }}
      okTextId="buttons.start"
      okColProps={{ flex: 21 }}
      mask
    />
  );
}

export default function ReverseShipmentPage() {
  const match = useRouteMatch();
  const { jobNumber } = match.params;

  const shipmentResult = useQuery(REVERSED_JOB_FORM_QUERY, {
    variables: { jobNumber },
  });

  const [dialogOpen, setDialogOpen] = useState(true);

  const {
    forms,
    refreshValues,
    account,
    setAccount,
    unitSystem,
    setUnitSystem,
    values,
    extractValues,
  } = useNewShipmentFormState();

  const { submit, dialogProps: submitDialogProps } = useNewShipmentSubmit();

  const [activeTemplateCounter, setActiveTemplateCounter] = useState(0);
  const { stepsProps, currentIndex, goToNext, goToPrev, resetStep } =
    useNewShipmentSteps({
      forms,
      refreshValues,
      activeTemplateCounter,
      steps: NEW_SHIPMENT_STEPS,
      disableUnvisitedSteps: true,
    });

  const { afterApplyTemplate } = useNewShipmentStateUpdaters({
    forms,
    refreshValues,
    setUnitSystem,
  });
  const afterCancelTemplate = useCallback(() => {
    afterApplyTemplate();
    resetStep();
  }, [afterApplyTemplate, resetStep]);
  const onActiveTemplateChange = useCallback(({ fromForm }) => {
    // If form is saved as a template, it's not considered a change of active template
    if (!fromForm) {
      setActiveTemplateCounter(old => old + 1);
    }
  }, []);

  const savedShipmentModeActive = isSavedShipmentModeActive(values);
  const { accountDetailResult, isQuoteEnabled: isQuoteEnabledConfig } =
    useNewShipmentAsyncConfig({
      account,
    });
  const isQuoteEnabled = isQuoteEnabledConfig && !savedShipmentModeActive;

  const submitStep = useSubmitNewShipmentStep({
    forms,
    stepsProps,
    currentIndex,
    submit,
    extractValues,
    isQuoteEnabled,
    goToNext,
    steps: NEW_SHIPMENT_STEPS,
  });

  const {
    props: shipmentTemplateChangeDetectionProps,
    ...shipmentTemplateChangeDetection
  } = useShipmentTemplateChangeDetectionInterceptors();

  const backLink = generatePath(routes.shipmentDetail, { jobNumber });

  return (
    <>
      <ReverseShipmentProviders
        account={account}
        unitSystem={unitSystem}
        values={values}
        shipmentTemplateChangeDetection={shipmentTemplateChangeDetection}
        shipmentResult={shipmentResult}
      >
        <ReverseShipmentExitPrompt match={match} dialogOpen={dialogOpen} />
        <ReverseShipmentInitialFormValuesSetter
          forms={forms}
          setAccount={setAccount}
          setUnitSystem={setUnitSystem}
          refreshValues={refreshValues}
          shipmentResult={shipmentResult}
        />
        <ReverseShipmentPageWrapper
          account={account}
          title={
            <TitleWithExtra
              title={
                <>
                  <FormattedMessage id="book.newShipment.title" />
                  <CancelShipmentLink
                    className="hide-md-and-smaller"
                    to={backLink}
                  />
                </>
              }
              rightColClassName="hide-md-and-smaller"
            >
              <DataStateIndicatorGuard queryResult={accountDetailResult}>
                {() => (
                  <div className="NewShipmentTopControls">
                    <NewShipmentFormButtons
                      onSubmit={submitStep}
                      onBack={goToPrev}
                      isFirstStep={isFirstStep(stepsProps)}
                      isLastStep={isLastStep(stepsProps)}
                      isQuoteEnabled={isQuoteEnabled}
                    />
                  </div>
                )}
              </DataStateIndicatorGuard>
            </TitleWithExtra>
          }
        >
          <Row
            className="flex-nowrap"
            gutter={pxToNumber(cssVariables.spaceNorm2)}
          >
            <Col className="Flex1 spaces-vert-norm1_5">
              <ShipmentTemplatesCard
                forms={forms}
                values={values}
                afterApply={afterApplyTemplate}
                afterCancel={afterCancelTemplate}
                changeDetectionProps={shipmentTemplateChangeDetectionProps}
                onActiveItemChange={onActiveTemplateChange}
              />
              <StepsWithContent
                {...stepsProps}
                className="spaces-vert-norm1_5"
                stepsClassName="labels-in-middle"
                segmentedControlClassName="margin-hor-norm"
                clickable
              />
            </Col>
            <Col className="hide-md-and-smaller">
              <ShipmentOrderPreviewCard
                key={activeTemplateCounter}
                account={account}
                values={values}
                stepIndex={currentIndex}
              />
            </Col>
          </Row>
          <DataStateIndicatorGuard queryResult={accountDetailResult}>
            {() => (
              <>
                <NewShipmentBottomControls
                  submitStep={submitStep}
                  stepsProps={stepsProps}
                  isQuoteEnabled={isQuoteEnabled}
                />
                <NewShipmentSubmitDialog
                  {...submitDialogProps}
                  isQuoteEnabled={isQuoteEnabled}
                  savedShipmentModeActive={savedShipmentModeActive}
                />
              </>
            )}
          </DataStateIndicatorGuard>
        </ReverseShipmentPageWrapper>
      </ReverseShipmentProviders>
      <ReverseShipmentAttentionDialog
        jobNumber={jobNumber}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
}
