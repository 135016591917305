import { useLocalStorage } from '@rehooks/local-storage';
import { useDispatch, useSelector } from 'react-redux';

import {
  getAuthEmail,
  getAuthToken,
  getLoggedIn,
  loginUser,
} from '../../app/redux/auth';
import { useMountAndUpdateEffect } from '../../common/utils/hookUtils';
import { LOCAL_STORAGE_AUTH_KEY } from '../../config/constants';

/**
 * Keeps redux auth state in sync with local storage
 */
export function LoginStateWatcher({ onInit }) {
  const [loginState, setLoginState, deleteLoginState] = useLocalStorage(
    LOCAL_STORAGE_AUTH_KEY
  );
  const dispatch = useDispatch();
  const loggedIn = useSelector(getLoggedIn);
  const email = useSelector(getAuthEmail);
  const token = useSelector(getAuthToken);
  useMountAndUpdateEffect(
    {
      onMount: () => {
        if (loginState) {
          dispatch(loginUser(loginState.email, loginState.token));
        }
        onInit && onInit();
      },
      onUpdate: () => {
        if (loggedIn) {
          setLoginState({ email, token });
        } else {
          deleteLoginState();
        }
      },
    },
    [loggedIn, email, token]
  );

  return null;
}
