import { isFunction } from 'lodash-es';
import { useHistory } from 'react-router';

import routes from '../../app/routes';
import { useMountEffect } from '../../common/utils/hookUtils';
import { useAccounts } from '../../hooks/data/auth';
import { useFlashMessageContext } from '../dialogs/FlashMessageProvider';

function PageNotAllowed() {
  const { errorMessage } = useFlashMessageContext();
  const { push } = useHistory();

  useMountEffect(() => {
    errorMessage({ contentId: 'error.pageNotAllowed' });
    push(routes.root);
  });

  return null;
}

/**
 * @param {Function<(object) => boolean>} guard Predicate whether the page is allowed
 * @return {boolean} Whether the page is enabled
 */
export function usePageGuard(guard) {
  const { customerAccounts, loading } = useAccounts();
  return !loading && isFunction(guard) ? guard({ customerAccounts }) : true;
}

function PageGuard({ component: C, guard, ...rest }) {
  const enabled = usePageGuard(guard);
  return enabled ? <C {...rest} /> : <PageNotAllowed />;
}

export function withPageGuard(guard) {
  return component => props => (
    <PageGuard component={component} guard={guard} {...props} />
  );
}
