import moment from 'moment';

import { optionalDecorator } from './funcUtils';

export const DATE_TIME_FORMAT = 'YYYY-MM-DDTkk:mm:ss';
const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'kk:mm:ss';

export function fromServerDate(date) {
  if (!date) {
    return moment.invalid();
  }
  return moment(date, DATE_FORMAT).startOf('day');
}
export const fromServerDateOptional = optionalDecorator(fromServerDate);

function toServerDate(momentDate) {
  return momentDate.format(DATE_FORMAT);
}
export const toServerDateOptional = optionalDecorator(toServerDate);

export function fromServerTime(time) {
  if (!time) {
    return moment.invalid();
  }
  return joinDateAndTime(moment(), moment(time, TIME_FORMAT));
}
export const fromServerTimeOptional = optionalDecorator(fromServerTime);

function toServerTime(momentTime) {
  return momentTime.format(TIME_FORMAT);
}
export const toServerTimeOptional = optionalDecorator(toServerTime);

export function fromServerDateTime(datetime) {
  if (!datetime) {
    return moment.invalid();
  }
  return moment(datetime, DATE_TIME_FORMAT);
}
export const fromServerDateTimeOptional = optionalDecorator(fromServerDateTime);

function toServerDateTime(momentDate) {
  return momentDate.format(DATE_TIME_FORMAT);
}
export const toServerDateTimeOptional = optionalDecorator(toServerDateTime);

export function now() {
  return moment();
}

/**
 * Strips time form date.
 */
export function toDate(datetime) {
  if (!datetime || !datetime.isValid()) {
    return moment.invalid();
  }
  return datetime.clone().startOf('day');
}

export function joinDateAndTime(date, time) {
  return time.clone().year(date.year()).month(date.month()).date(date.date());
}

export function forceDateIntoRange(value, { min, max }) {
  return moment.max(min, moment.min(value, max));
}
