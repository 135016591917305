import { useQuery } from '@apollo/client';
import { pick } from 'lodash-es';
import { generatePath } from 'react-router';

import { ACCOUNT_QUERY } from '../../../../app/graphql/accountQueries';
import routes from '../../../../app/routes';
import { extractGraphqlEntity } from '../../../../common/utils/graphqlUtils';
import { Spinner } from '../../../../components/data/Spinner';
import { DataError } from '../../../../components/data/dataStateHandlers';
import { FormItemsOverrideContextProvider } from '../../../../components/forms/FormItem';
import AdminAddUser from '../../userManagement/AdminAddUser';

export function AdminAccountAddUser({ accountNumber, headerRightRef }) {
  const { data, loading, error } = useQuery(ACCOUNT_QUERY, {
    variables: { accountNumber },
  });
  const account = data && extractGraphqlEntity(data);

  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <DataError error={error} />;
  }

  return (
    <FormItemsOverrideContextProvider force viewMode={false}>
      <AdminAddUser
        headerRightRef={headerRightRef}
        initialValues={{ accounts: [pick(account, ['number', 'name'])] }}
        baseRoute={generatePath(routes.admin.accountDetail.addUser, {
          accountNumber,
        })}
        getRedirectUrl={userResp =>
          generatePath(routes.admin.accountDetail.userDetail, {
            accountNumber,
            userId: userResp.id,
          })
        }
      />
    </FormItemsOverrideContextProvider>
  );
}
