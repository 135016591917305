import { Button, Checkbox } from 'antd';
import classNames from 'classnames';
import { differenceBy, filter, isEmpty, map, some, unionWith } from 'lodash-es';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ACCOUNTS_QUERY } from '../app/graphql/accountQueries';
import { FAIcon } from '../components/adapters/fontAwesomeAdapters';
import { usePaginatedQuery } from '../components/data/dataLoaders';
import { InfiniteScrollList } from '../components/data/dataRenderers';
import { FormItem } from '../components/forms/FormItem';
import { FormItemInputText } from '../components/forms/basicFormElements';
import { Scrollbars } from '../components/layout/Scrollbars';
import SolidDivider from '../components/layout/SolidDivider';

const AccountsPickerEditing = ({ value, onChange, renderItem, account }) => {
  const [textSearch, setTextSearch] = useState('');
  const [selected, setSelected] = useState(value);

  const skip = isEmpty(textSearch);
  const { rows, hasNext, loadNext, loading, error } = usePaginatedQuery({
    query: ACCOUNTS_QUERY,
    variables: {
      filter: { search: textSearch, except: account?.number },
    },
    options: { skip },
  });

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const onUnchecked = item => {
    setSelected(current => filter(current, i => i.number !== item.number));
  };

  const onChecked = item => {
    setSelected(current =>
      unionWith(current, [item], (a, b) => a.number === b.number)
    );
  };

  const onSearchBlur = e => {
    setTextSearch(e.target.value);
  };

  return (
    <>
      <div className="flex-center AccountsPicker-edit-header">
        <div className="no-margin-form-items search-input">
          <FormItemInputText
            floatingLabel={false}
            onBlur={onSearchBlur}
            placeholderId="admin.accountManagement.search.placeholder-short"
            formItemComponentProps={{
              prefix: <FAIcon icon="search" className="icon-18" />,
              allowClear: true,
            }}
          />
        </div>

        <Button
          type="primary"
          size="small"
          className="width-80"
          onClick={() => onChange(selected)}
        >
          Save
        </Button>
      </div>

      {some(value) && (
        <div className="AccountsPicker-edit-body">
          {map(value, item => (
            <Checkbox
              checked={some(selected, s => s.number === item.number)}
              onChange={() => {
                onUnchecked(item);
              }}
            >
              {renderItem(item)}
            </Checkbox>
          ))}
        </div>
      )}

      {some(value) && <SolidDivider />}

      {skip ? (
        <div className="text-center AccountsPicker-no-items">
          <FormattedMessage id="admin.commonList.noFilter" />
        </div>
      ) : (
        <Scrollbars
          autoHeight
          autoHeightMin={30}
          autoHeightMax={400}
          className={classNames({ 'AccountsPicker-edit-body': isEmpty(value) })}
        >
          <InfiniteScrollList
            rows={rows}
            hasNext={hasNext}
            loadNext={loadNext}
            error={error}
            loading={loading}
            render={({ rows: innerRows }) =>
              map(differenceBy(innerRows, value, 'number'), row => (
                <Checkbox
                  checked={some(selected, s => s.number === row.number)}
                  onChange={() => {
                    onChecked(row);
                  }}
                >
                  {renderItem(row)}
                </Checkbox>
              ))
            }
          />
        </Scrollbars>
      )}
    </>
  );
};

const AccountsPickerImpl = ({
  value,
  renderItem,
  titleId,
  subtitleId,
  titleIcon,
  iconCss,
  disabled,
  onChange,
  account,
}) => (
  <div className="AccountsPicker-widget">
    <div className="AccountsPicker-title">
      <FAIcon
        icon={titleIcon}
        className={classNames('AccountsPicker-icon', iconCss)}
      />
      <FormattedMessage id={titleId} />
    </div>

    {disabled ? (
      <>
        <div className="AccountsPicker-subtitle">
          <FormattedMessage id={subtitleId} />
        </div>
        <div className="AccountsPicker-body">
          {map(value, item => (
            <div className="AccountsPicker-item" key={item.number}>
              {renderItem && renderItem(item)}
            </div>
          ))}
        </div>
      </>
    ) : (
      <AccountsPickerEditing
        value={value}
        onChange={onChange}
        renderItem={renderItem}
        account={account}
        disabled
      />
    )}
  </div>
);

const AccountsPicker = ({
  dataSource,
  name,
  disabled: disabledOuter,
  ...rest
}) => {
  const disabled = rest.disabled || disabledOuter;
  return (
    <FormItem
      floatingLabel={false}
      name={name}
      component={AccountsPickerImpl}
      disabled={disabled}
      formItemComponentProps={rest}
    />
  );
};

export default AccountsPicker;
