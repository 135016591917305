import { flatMap, isArray, property, some, sortBy } from 'lodash-es';
import { cloneElement } from 'react';
import { matchPath, useLocation } from 'react-router';

/**
 * Sorts routes in such a way that the more specific route will not be shadowed
 * by the less specific one
 */
export function sortRoutes(routeMappings, getPath = property('path')) {
  return sortBy(routeMappings, info => -getPath(info).length);
}

export function flattenRoutes(def) {
  if (isArray(def)) {
    return flatMap(def, flattenRoutes).filter(property('to'));
  }
  if (def.children) {
    return flattenRoutes(def.children);
  }
  return def;
}

export function useActiveRoute(routeList, routeKey = 'to') {
  const { pathname } = useLocation();
  const getPath = property(routeKey);
  const sortedRoutes = sortRoutes(flattenRoutes(routeList), getPath);
  return sortedRoutes.find(r => matchPath(pathname, { path: getPath(r) }));
}

export function isMenuItemActive(activeRoute, definition) {
  return (
    !!matchPath(definition.to, { path: activeRoute, exact: true }) ||
    some(definition.children || [], def => isMenuItemActive(activeRoute, def))
  );
}

export function renderMenuDefinition(
  def,
  { renderMenuItem, renderSubmenu, data = { key: 0, level: 0 }, props }
) {
  const makeRecurse = newLevel => subdef =>
    renderMenuDefinition(subdef, {
      renderMenuItem,
      renderSubmenu,
      data: newLevel ? { key: 0, level: data.level + 1 } : data,
      props,
    });

  if (isArray(def)) {
    return def.map(makeRecurse(false));
  }
  if (def.element) {
    // eslint-disable-next-line no-param-reassign
    return cloneElement(def.element, { key: `gen-${data.key++}` });
  }
  if (def.children) {
    return renderSubmenu(def, makeRecurse(true), { level: data.level }, props);
  }
  return renderMenuItem(def, props, { level: data.level });
}
