import { useQuery } from '@apollo/client';
import { Route, Switch, generatePath } from 'react-router';

import { USER_DETAIL_QUERY } from '../../../app/graphql/adminQueries';
import routes from '../../../app/routes';
import { extractGraphqlEntity } from '../../../common/utils/graphqlUtils';
import { formatUserLabel } from '../utils/adminFormatting';
import AdminConsoleSideMenu from './AdminConsoleSideMenu';

function MenuBase({ additionalItems = [] }) {
  return (
    <AdminConsoleSideMenu
      items={[
        {
          labelId: 'admin.userManagement.userList',
          to: routes.admin.userManagement,
        },
        ...additionalItems,
      ]}
    />
  );
}

function DefaultMenu() {
  return (
    <MenuBase
      additionalItems={[
        {
          labelId: 'admin.userManagement.addUser',
          to: routes.admin.addUser,
        },
      ]}
    />
  );
}

export function AdminUserDetailMenu({
  baseRoute = routes.admin.userDetail,
  userId,
  children,
}) {
  const { data } = useQuery(USER_DETAIL_QUERY, {
    variables: { id: userId },
  });
  const user = data && extractGraphqlEntity(data);

  const baseRouteExp = generatePath(baseRoute, { userId });

  return children({
    items: [
      {
        label: formatUserLabel(user),
        to: `${baseRouteExp}${routes.admin.userDetailSteps.root}`,
        children: [
          {
            labelId: 'admin.userManagement.userDetail.profile',
            to: `${baseRouteExp}${routes.admin.userDetailSteps.root}`,
          },
          {
            labelId: 'admin.userManagement.userDetail.documentGroup',
            to: `${baseRouteExp}${routes.admin.userDetailSteps.documentGroup}`,
          },
          {
            labelId: 'admin.userManagement.userDetail.expandedTracking',
            to: `${baseRouteExp}${routes.admin.userDetailSteps.expandedTracking}`,
          },
        ],
      },
    ],
  });
}

export default function AdminUserManagementMenu() {
  return (
    <Switch>
      <Route path={routes.admin.userDetail}>
        {({ match }) => (
          <AdminUserDetailMenu userId={match.params?.userId}>
            {({ items }) => <MenuBase additionalItems={items} />}
          </AdminUserDetailMenu>
        )}
      </Route>
      <Route path="*">
        <DefaultMenu />
      </Route>
    </Switch>
  );
}
