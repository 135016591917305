import {
  ReferenceInformationCard,
  ServiceInformationCard,
} from '../newShipment/NewShipmentServiceInformationForm';
import {
  JobLocationCards,
  NewShipmentForm,
} from '../newShipment/newShipmentElements';
import PackageListPackages from '../packageList/PackageListPackages';
import { PackageListFieldVisibilityProvider } from '../packageList/packageListFieldVisibility';

export function ShipmentTemplateFromAddressForm(props) {
  return (
    <NewShipmentForm {...props} name="tpl-origin">
      <JobLocationCards
        firstTitleId="shipmentTemplates.fromAddress.dateSection.title"
        secondTitleId="book.newShipment.fromAddress"
        schemaNamePrefix="origin"
        enableAddressLookup
      />
    </NewShipmentForm>
  );
}

export function ShipmentTemplateToAddressForm(props) {
  return (
    <NewShipmentForm
      {...props}
      name="tpl-destination"
      className="margins-bottom-norm2"
    >
      <JobLocationCards
        firstTitleId="shipmentTemplates.toAddress.dateSection.title"
        secondTitleId="book.newShipment.toAddress"
        schemaNamePrefix="destination"
        enableAddressLookup
      />
    </NewShipmentForm>
  );
}

export function ShipmentTemplateServiceInformationForm({ form, ...rest }) {
  return (
    <NewShipmentForm
      {...rest}
      form={form}
      name="tpl-serviceInformation"
      className="margins-bottom-norm2"
    >
      <ServiceInformationCard />
      <ReferenceInformationCard showJobSpecificFields={false} />
    </NewShipmentForm>
  );
}

export function ShipmentTemplatePackageListForm({
  onEditedPackageChange,
  ...rest
}) {
  return (
    <PackageListFieldVisibilityProvider packoutDateTime={false}>
      <NewShipmentForm
        {...rest}
        name="tpl-packages"
        className="NewShipmentPackageListForm margins-bottom-norm2"
      >
        <PackageListPackages onEditedPackageChange={onEditedPackageChange} />
      </NewShipmentForm>
    </PackageListFieldVisibilityProvider>
  );
}
