import { FormattedMessage } from 'react-intl';

import { SimpleConfirmDialog } from '../../../components/dialogs/SimpleConfirmDialog';

export function PackageConsistentWeightModeDialog({ isOpen, close }) {
  return (
    <SimpleConfirmDialog
      visible={isOpen}
      cancelTextId="buttons.close"
      onClose={close}
      okTextId="buttons.dontShowAgain"
      onOk={() => close(true)}
      text={
        <FormattedMessage
          id="book.newShipment.packageDialogs.weightModeConsistencySpecific.text"
          values={{
            infix: (
              <FormattedMessage id="book.newShipment.package.dims.dimensionalWeight" />
            ),
          }}
        />
      }
    />
  );
}
