import { FAIcon } from '../adapters/fontAwesomeAdapters';

const SIZE_RATIO = 13 / 23;

export default function CustomGPSIcon({ size = 23 }) {
  return (
    <div
      className="CustomGPSIcon"
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <FAIcon icon="wifi" style={{ fontSize: size * SIZE_RATIO }} />
      <span
        style={{
          fontSize: size * (1 - SIZE_RATIO),
          fontWeight: 900,
          marginTop: -2,
        }}
      >
        GPS
      </span>
    </div>
  );
}
