import { Form } from 'antd';
import { isFunction, noop } from 'lodash-es';
import {
  createContext,
  useCallback,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';

import { log } from '../../utils/logger';
import { useMountedIndicator } from '../../utils/reactUtils';
import { CustomLoaderButton } from '../buttons/buttons';
import { useFlashMessageContext } from '../dialogs/FlashMessageProvider';

export const FormMode = {
  ADD: 'ADD',
  EDIT: 'EDIT',
};

const FormContext = createContext({
  values: {},
  // Flag for rendering (for example button loading state)
  submitting: false,
  // Flag for asynchronous functions (for example validators)
  submittingRef: { current: false },
  forceUpdate: noop,
  formInstance: null,
  parentContext: null,
  mode: FormMode.ADD,
  customSubmit: noop,
  triggerSubmit: noop,
});
export function useFormContext() {
  return useContext(FormContext);
}

export const FormInterceptorContext = createContext({
  onBlur: noop,
  onKeyDown: noop,
});

function useFormState({
  form,
  onSubmit,
  onValuesChange: onValuesChangeOuter,
  initialValues,
  mode,
}) {
  const parentContext = useFormContext();
  const [submitting, setSubmittingState] = useState(false);
  const submittingRef = useRef(false);
  const setSubmitting = useCallback(val => {
    setSubmittingState(val);
    submittingRef.current = val;
  }, []);
  const [formValues, setValues] = useState(initialValues || {});
  useMountedIndicator({ logPrefix: 'useFormState' });
  const { errorMessage } = useFlashMessageContext();

  const onFinish = useCallback(
    async values => {
      if (!onSubmit) {
        return;
      }
      setSubmitting(true);
      try {
        await form.validateFields();
        try {
          await onSubmit(values);
        } catch (e) {
          errorMessage(e);
        }
      } catch (e) {
        log.debug('Validation error', e);
      } finally {
        setSubmitting(false);
      }
    },
    [errorMessage, form, onSubmit, setSubmitting]
  );

  const triggerSubmit = useCallback(async () => {
    await onFinish(form.getFieldsValue());
  }, [form, onFinish]);

  const customSubmit = useCallback(
    async executeSubmit => {
      setSubmitting(true);
      try {
        await form.validateFields();
        try {
          if (isFunction(executeSubmit)) {
            await executeSubmit();
          }
        } catch (e) {
          errorMessage(e);
        }
      } catch (e) {
        log.debug('Validation error', e);
        throw e;
      } finally {
        setSubmitting(false);
      }
    },
    [errorMessage, form, setSubmitting]
  );

  const onValuesChange = useCallback(
    (changed, all) => {
      setValues(all);
      return onValuesChangeOuter && onValuesChangeOuter(changed, all);
    },
    [onValuesChangeOuter]
  );

  const forceUpdate = useCallback(() => {
    const values = form.getFieldsValue();
    setValues(values);
    onValuesChangeOuter && onValuesChangeOuter({}, values);
  }, [form, onValuesChangeOuter]);

  return {
    form,
    formState: {
      values: formValues,
      submitting,
      submittingRef,
      forceUpdate,
      formInstance: form,
      parentContext: parentContext.forceUpdate === noop ? null : parentContext,
      mode,
      customSubmit,
      triggerSubmit,
    },
    onFinish,
    onValuesChange,
    initialValues,
  };
}

function BaseFormInner({
  form,
  name,
  onSubmit,
  onValuesChange,
  initialValues,
  mode = FormMode.ADD,
  formRef,
  ...rest
}) {
  const { formState, ...formProps } = useFormState({
    form,
    initialValues,
    onSubmit,
    onValuesChange,
    mode,
  });

  useImperativeHandle(formRef, () => formState);

  return (
    <FormContext.Provider value={formState}>
      <Form name={name} {...rest} {...formProps} />
    </FormContext.Provider>
  );
}

function BaseFormAddFormInstance(props) {
  const [form] = Form.useForm();
  return <BaseFormInner form={form} {...props} />;
}

function BaseForm({ form, ...rest }) {
  return form ? (
    <BaseFormInner form={form} {...rest} />
  ) : (
    <BaseFormAddFormInstance {...rest} />
  );
}

export const InlineForm = props => <BaseForm {...props} layout="inline" />;
export const VerticalForm = props => <BaseForm {...props} layout="vertical" />;
export const HorizontalForm = props => (
  <BaseForm
    {...props}
    layout="horizontal"
    labelAlign="left"
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
  />
);
export const NoLabelForm = props => (
  <BaseForm
    {...props}
    layout="horizontal"
    colon={false}
    labelAlign="left"
    labelCol={{ span: 0 }}
    wrapperCol={{ span: 24 }}
  />
);

const DEFAULT_SUBMITTING_PROPS = { disabled: true, loading: true };
export function SubmitButton({
  component: C = CustomLoaderButton,
  textId,
  children,
  submittingProps = DEFAULT_SUBMITTING_PROPS,
  ...rest
}) {
  const formState = useFormContext();

  return (
    <C
      htmlType="submit"
      {...rest}
      {...(formState.submitting ? submittingProps : {})}
    >
      {textId ? <FormattedMessage id={textId} /> : children}
    </C>
  );
}
