import { Drawer } from 'antd';

import IconButton from '../buttons/IconButton';
import { DialogsParentProvider } from '../dialogs/DialogsParentContext';

export default function NewShipmentDrawer({
  visible,
  children,
  onClose,
  header,
  headerRightContent,
  destroyOnClose = true,
  ...rest
}) {
  return (
    <DialogsParentProvider>
      <Drawer
        className="NewShipmentDrawer"
        closable
        title={
          <>
            {header}
            {headerRightContent && (
              <div className="NewShipmentDrawer-HeaderRightContent">
                {headerRightContent}
              </div>
            )}
          </>
        }
        closeIcon={
          <IconButton
            icon="times"
            className="CloseButton"
            data-subject="drawer"
            data-action="close"
          />
        }
        destroyOnClose={destroyOnClose}
        mask
        maskClosable
        open={visible}
        onClose={onClose}
        {...rest}
      >
        {children}
      </Drawer>
    </DialogsParentProvider>
  );
}
