import { merge } from 'lodash-es';

import {
  SHIPMENT_FORM_AUTOFILL_QUERY,
  SHIPMENT_FORM_GET_OPTIONS_QUERY,
  SHIPMENT_FORM_SCHEMA_QUERY,
  VALIDATE_SHIPMENT_FORM_FIELD_QUERY,
} from '../../../app/graphql/jobFormQueries';
import { useMountEffect } from '../../../common/utils/hookUtils';
import { OverrideSettingsContextProvider } from '../../../components/domainSpecific/settingsElements';
import { DRAWER_MEDIA_MAPPER } from '../../../components/drawers/drawerCommon';
import { FormItemInputText } from '../../../components/forms/basicFormElements';
import { DynamicFormDependenciesProvider } from '../../../components/forms/dynamic/dynamicFormDependencies';
import {
  DynamicFormSchemaProvider,
  mapFormSchema,
} from '../../../components/forms/dynamic/dynamicFormSchema';
import { StepsWithContent } from '../../../components/nav/StepsWithContent';
import { ResponsiveOverrideSubtree } from '../../../components/responsive/ResponsiveOverrideSubtree';
import useNewShipmentSteps from '../newShipment/useNewShipmentSteps';
import { SHIPMENT_TEMPLATE_STEPS } from './shipmentTemplateConstants';
import { copyShipmentTemplateIntoForm } from './shipmentTemplateUtils';

function removeStaticValidationRulesFromSchema(schema) {
  return mapFormSchema(schema, field =>
    merge({}, field, { validation: { rules: { required: false } } })
  );
}

function normalizeInitialValues(values) {
  const { packageList, ...rest } = values;
  return {
    ...rest,
    packageList: packageList.tbd ? { packages: [] } : packageList,
  };
}

export default function ShipmentTemplateFormContent({
  initialValues,
  data,
  forms,
  refreshValues,
  account,
  setAccount,
  setUnitSystem,
  values,
}) {
  const { stepsProps } = useNewShipmentSteps({
    formNamePrefix: 'shipmentTemplate',
    forms,
    refreshValues,
    steps: SHIPMENT_TEMPLATE_STEPS,
  });

  const adding = data?.adding;
  const editingOrViewing = data?.editing || data?.viewing;

  // Initialization
  useMountEffect(() => {
    // "Save Template" - adding from form
    if (adding === true) {
      copyShipmentTemplateIntoForm(
        forms,
        normalizeInitialValues(initialValues)
      );
    }

    // "Save As" - adding from existing template
    if (adding?.values) {
      copyShipmentTemplateIntoForm(forms, adding.values);
    }

    if (editingOrViewing) {
      // editing an existing template from popup
      if (editingOrViewing.values) {
        copyShipmentTemplateIntoForm(forms, editingOrViewing.values);
      }
      // editing an existing template from form after change
      else {
        copyShipmentTemplateIntoForm(
          forms,
          normalizeInitialValues(initialValues)
        );
      }
      setUnitSystem(editingOrViewing.values.unitSystem);
    }

    // Force update in the children forms
    forms.forEach(({ formRef }) => {
      if (formRef.current) {
        formRef.current.forceUpdate();
      }
    });

    // This also calls `refreshValues`
    setAccount(initialValues.account);
  });

  return (
    <ResponsiveOverrideSubtree mapper={DRAWER_MEDIA_MAPPER}>
      <OverrideSettingsContextProvider unitSystem={values.unitSystem}>
        <DynamicFormSchemaProvider
          query={SHIPMENT_FORM_SCHEMA_QUERY}
          optionsQuery={SHIPMENT_FORM_GET_OPTIONS_QUERY}
          fieldValidationQuery={VALIDATE_SHIPMENT_FORM_FIELD_QUERY}
          autofillQuery={SHIPMENT_FORM_AUTOFILL_QUERY}
          variables={account && { accountNumber: account.number }}
          skip={!account}
          modifySchema={removeStaticValidationRulesFromSchema}
          queryName="newShipmentAsyncFieldValidation"
        >
          <DynamicFormDependenciesProvider values={values}>
            {editingOrViewing && (
              <div className="ShipmentTemplateForm-Name">
                {editingOrViewing.name}
              </div>
            )}
            {adding && (
              <FormItemInputText
                name="name"
                labelId="shipmentTemplates.labels.name.new"
                rules={[{ required: true }]}
              />
            )}
            <StepsWithContent
              {...stepsProps}
              className="spaces-vert-norm1_5"
              stepsClassName="labels-in-middle"
              segmentedControlClassName="margin-hor-norm"
              clickable
            />
          </DynamicFormDependenciesProvider>
        </DynamicFormSchemaProvider>
      </OverrideSettingsContextProvider>
    </ResponsiveOverrideSubtree>
  );
}
