import { Row } from 'antd';
import classNames from 'classnames';

import ErrorBoundary from '../components/ErrorBoundary';
import { LinksRow } from '../components/nav/navElements';
import { externalLinks } from '../config/externalLinks';

export const FooterTheme = {
  DARK: 'DARK',
  LIGHT: 'LIGHT',
};

export default function Footer({ theme }) {
  return (
    <ErrorBoundary>
      <Row
        className={classNames('Footer', {
          'Footer-Dark': theme === FooterTheme.DARK,
          'Footer-Light': theme === FooterTheme.LIGHT,
        })}
        type="flex"
        align="middle"
        justify="center"
      >
        <LinksRow
          links={[
            { href: externalLinks.privacyPolicy, textId: 'footer.privacy' },
            {
              href: externalLinks.conditionsOfCarriage,
              textId: 'footer.conditions',
            },
          ]}
        />
      </Row>
    </ErrorBoundary>
  );
}
