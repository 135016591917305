import { useApolloClient, useQuery } from '@apollo/client';
import { Button, Checkbox, Form, Tooltip } from 'antd';
import classNames from 'classnames';
import { Set } from 'immutable';
import {
  differenceBy,
  get,
  identity,
  isEmpty,
  noop,
  property,
  reverse,
  sortBy,
} from 'lodash-es';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { SortOrder } from '../../../../app/common/sort';
import {
  ACCOUNTS_SIMPLE_QUERY,
  ACCOUNT_GROUPS_QUERY,
  PARTNER_GROUPS_QUERY,
} from '../../../../app/graphql/adminQueries';
import {
  deleteQueryFromCache,
  extractGraphqlEntity,
} from '../../../../common/utils/graphqlUtils';
import { useMountEffect } from '../../../../common/utils/hookUtils';
import Whitespace from '../../../../components/Whitespace';
import {
  FAIcon,
  FATypes,
} from '../../../../components/adapters/fontAwesomeAdapters';
import { Spinner } from '../../../../components/data/Spinner';
import { DataError } from '../../../../components/data/dataStateHandlers';
import FormList from '../../../../components/forms/FormList';
import {
  FormItemFieldRegistration,
  FormItemInputText,
} from '../../../../components/forms/basicFormElements';
import {
  NoLabelForm,
  useFormContext,
} from '../../../../components/forms/forms';
import { useLabelFilter } from '../../../../components/forms/selectDecorators';
import Collapsible from '../../../../components/layout/Collapsible';
import AdminConsoleSection, {
  AdminConsoleSectionHeaderControls,
  AdminConsoleSectionSubheader,
  AdminConsoleSectionTable,
  AdminConsoleSectionTitleHeader,
} from '../../components/AdminConsoleSection';
import AdminConsoleSorter from '../../components/AdminConsoleSorter';

export function AdminUserLinkedAccountsFormSections({ user, viewMode }) {
  const { values } = useFormContext();

  if (viewMode) {
    return (
      <AdminConsoleSection>
        <FormItemFieldRegistration name="accounts" />
        <FormItemFieldRegistration name="accountGroups" />
        <FormItemFieldRegistration name="partnerGroups" />
        <AdminConsoleSectionTitleHeader>
          <strong>
            <FAIcon icon="link" className="icon-16" />
            <FormattedMessage id="admin.userManagement.forms.linkedAccounts" />
            <Whitespace />(
            {(values?.accounts?.length || 0) +
              (values?.accountGroups?.length || 0)}
            )
          </strong>
        </AdminConsoleSectionTitleHeader>
        <AdminConsoleSectionSubheader>
          <FormattedMessage id="admin.userManagement.forms.soloAccounts" />
          <Whitespace />({values?.accounts?.length || 0})
        </AdminConsoleSectionSubheader>
        <AccountsTableView accounts={values?.accounts} />
        <AdminConsoleSectionSubheader>
          <FormattedMessage id="admin.userManagement.forms.accountGroups" />
          <Whitespace />({values?.accountGroups?.length || 0})
        </AdminConsoleSectionSubheader>
        <div>
          {user.accountGroups?.map(accountGroup => (
            <GroupView
              key={accountGroup.code}
              code={accountGroup.code}
              name={accountGroup.name}
              accounts={accountGroup.accounts}
            />
          ))}
        </div>
        <AdminConsoleSectionSubheader>
          <FormattedMessage id="admin.userManagement.forms.partnerGroups" />
          <Whitespace />({values?.partnerGroups?.length || 0})
        </AdminConsoleSectionSubheader>
        <div>
          {user.partnerGroups?.map(partnerGroup => (
            <GroupView
              key={partnerGroup.code}
              code={partnerGroup.code}
              name={partnerGroup.name}
              accounts={partnerGroup.accounts}
            />
          ))}
        </div>
      </AdminConsoleSection>
    );
  }

  return (
    <>
      <LinksEditing
        name="accounts"
        titleId="admin.userManagement.forms.linkAccounts"
        addButtonId="admin.userManagement.forms.addAccount"
        optionsQuery={ACCOUNTS_SIMPLE_QUERY}
        deleteOptionsCacheQueryName="accountsSimple"
        keyId="admin.userManagement.forms.accountNumber"
        labelId="admin.userManagement.forms.accountName"
        rowKey="number"
        rowLabel="name"
        renderHeader={({ sort, setSort }) => (
          <AccountsHeader sort={sort} setSort={setSort} />
        )}
        headerClassName="AdminUserForms__LinkedAccountsColumns"
        itemClassName="AdminUserForms__LinkedAccountsColumns"
        renderAdditionalBodyColumns={() => (
          <>
            <div />
            <div />
          </>
        )}
        minLength={1}
        minLengthMessageId="admin.userManagement.forms.linkAccounts.min"
      >
        {({ value, additionalColumns }) => (
          <AccountsTableView
            accounts={value}
            additionalColumns={additionalColumns}
          />
        )}
      </LinksEditing>
      <LinksEditing
        name="accountGroups"
        titleId="admin.userManagement.forms.linkAccountGroups"
        addButtonId="admin.userManagement.forms.addAccountGroup"
        optionsQuery={ACCOUNT_GROUPS_QUERY}
        keyId="admin.userManagement.forms.groupName"
        labelId="admin.userManagement.forms.groupDescription"
      >
        {({ value, additionalColumns }) => (
          <AdminConsoleSectionTable
            columnsClassName="AdminUserForms__LinkedGroupsColumns"
            header={
              <>
                <div>
                  <FormattedMessage id="admin.userManagement.forms.groupName" />
                </div>
                <div>
                  <FormattedMessage id="admin.userManagement.forms.groupDescription" />
                </div>
                {additionalColumns.map(col => (
                  <div key={col.key}>
                    {col.headerId && <FormattedMessage id={col.headerId} />}
                  </div>
                ))}
              </>
            }
            rows={value}
            rowKey="code"
            renderRow={(accountGroup, i) => (
              <>
                <div className="AdminUserForms__LinkedAccountsLabel">
                  {accountGroup.code}
                </div>
                <div className="AdminUserForms__LinkedAccountsLabel">
                  {accountGroup.text}
                </div>
                {additionalColumns.map(col => (
                  <div key={col.key}>
                    {col.render({ accountGroup, index: i })}
                  </div>
                ))}
              </>
            )}
          />
        )}
      </LinksEditing>
      <LinksEditing
        name="partnerGroups"
        titleId="admin.userManagement.forms.linkPartnerAccessGroups"
        addButtonId="admin.userManagement.forms.addPartnerAccessGroup"
        optionsQuery={PARTNER_GROUPS_QUERY}
        keyId="admin.userManagement.forms.groupName"
        labelId="admin.userManagement.forms.groupDescription"
      >
        {({ value, additionalColumns }) => (
          <AdminConsoleSectionTable
            columnsClassName="AdminUserForms__LinkedGroupsColumns"
            header={
              <>
                <div>
                  <FormattedMessage id="admin.userManagement.forms.groupName" />
                </div>
                <div>
                  <FormattedMessage id="admin.userManagement.forms.groupDescription" />
                </div>
                {additionalColumns.map(col => (
                  <div key={col.key}>
                    {col.headerId && <FormattedMessage id={col.headerId} />}
                  </div>
                ))}
              </>
            }
            rows={value}
            rowKey="code"
            renderRow={(accountGroup, i) => (
              <>
                <div className="AdminUserForms__LinkedAccountsLabel">
                  {accountGroup.code}
                </div>
                <div className="AdminUserForms__LinkedAccountsLabel">
                  {accountGroup.text}
                </div>
                {additionalColumns.map(col => (
                  <div key={col.key}>
                    {col.render({ accountGroup, index: i })}
                  </div>
                ))}
              </>
            )}
          />
        )}
      </LinksEditing>
    </>
  );
}

function GroupView({ code, name, accounts }) {
  const [expanded, setExpanded] = useState(true);

  return (
    <>
      <div
        className={classNames(
          'AdminUserForms__CollapsibleHeader',
          accounts && 'Clickable'
        )}
        onClick={() => setExpanded(old => !old)}
      >
        {accounts ? (
          <>
            <FAIcon
              icon={expanded ? 'caret-down' : 'caret-right'}
              className="icon-14"
            />
            <span>
              {code}
              <Whitespace />({accounts?.length || 0})
            </span>
          </>
        ) : (
          code
        )}
        <span className="AdminUserForms__CollapsibleHeader__Description">
          [ {name} ]
        </span>
      </div>
      {!isEmpty(accounts) && (
        <Collapsible expanded={expanded} dynamicContent>
          <AccountsTableView accounts={accounts} showHeader={false} />
        </Collapsible>
      )}
    </>
  );
}

function AccountsHeader({ additionalColumns, sort, setSort }) {
  return (
    <>
      <div>
        <FormattedMessage id="admin.userManagement.forms.accountNumber" />
        {setSort && (
          <AdminConsoleSorter sortName="number" sort={sort} setSort={setSort} />
        )}
      </div>
      <div>
        <FormattedMessage id="admin.userManagement.forms.accountName" />
        {setSort && (
          <AdminConsoleSorter sortName="text" sort={sort} setSort={setSort} />
        )}
      </div>
      <div>
        <span className="AdminConsoleSection__Tba AdminConsoleSection__Tba--Right">
          <FormattedMessage id="admin.userManagement.forms.location" />
        </span>
      </div>
      <div>
        <span className="AdminConsoleSection__Tba AdminConsoleSection__Tba--Right">
          <FormattedMessage id="admin.userManagement.forms.businessType" />
        </span>
      </div>
      {additionalColumns?.map(col => (
        <div key={col.key}>
          {col.headerId && <FormattedMessage id={col.headerId} />}
        </div>
      ))}
    </>
  );
}

function AccountsTableView({
  accounts,
  showHeader = true,
  additionalColumns = [],
}) {
  return (
    <AdminConsoleSectionTable
      columnsClassName="AdminUserForms__LinkedAccountsColumns"
      header={
        showHeader && <AccountsHeader additionalColumns={additionalColumns} />
      }
      rows={accounts}
      rowKey="number"
      renderRow={(acc, i) => (
        <>
          <div className="AdminUserForms__LinkedAccountsLabel AdminUserForms__LinkedAccountsLabel--Emph">
            {acc.number}
          </div>
          <div className="AdminUserForms__LinkedAccountsLabel">{acc.name}</div>
          <div className="AdminUserForms__LinkedAccountsLabel AdminUserForms__LinkedAccountsLabel--Tba">
            {}
          </div>
          <div className="AdminUserForms__LinkedAccountsLabel AdminUserForms__LinkedAccountsLabel--Tba">
            {}
          </div>
          {additionalColumns.map(col => (
            <div key={acc.number}>{col.render({ account: acc, index: i })}</div>
          ))}
        </>
      )}
    />
  );
}

function LinksEditing({
  name,
  titleId,
  addButtonId,
  children,
  optionsQuery,
  deleteOptionsCacheQueryName,
  keyId,
  labelId,
  rowKey = 'code',
  rowLabel = 'text',
  renderHeader,
  headerClassName,
  itemClassName,
  renderAdditionalBodyColumns,
  minLength = 0,
  minLengthMessageId,
}) {
  const [isAdding, setAdding] = useState(false);

  return (
    <div className="AdminUserForms__LinksEditing">
      <AdminConsoleSection>
        <AdminConsoleSectionTitleHeader>
          <strong>
            <FAIcon icon="link" className="icon-16" />
            <FormattedMessage id={titleId} />
          </strong>
          {isAdding && (
            <AdminConsoleSectionHeaderControls>
              <FAIcon
                icon="times-circle"
                className="icon-18 Clickable"
                onClick={() => setAdding(false)}
              />
            </AdminConsoleSectionHeaderControls>
          )}
        </AdminConsoleSectionTitleHeader>
        <FormList name={name}>
          {(fields, { add, remove }) =>
            isAdding ? (
              <LinksEditingAddingMode
                name={name}
                query={optionsQuery}
                deleteCacheQueryName={deleteOptionsCacheQueryName}
                keyId={keyId}
                labelId={labelId}
                add={add}
                remove={remove}
                rowKey={rowKey}
                rowLabel={rowLabel}
                close={() => setAdding(false)}
                renderHeader={renderHeader}
                headerClassName={headerClassName}
                itemClassName={itemClassName}
                renderAdditionalBodyColumns={renderAdditionalBodyColumns}
              />
            ) : (
              <LinksEditingPreview
                render={children}
                name={name}
                remove={remove}
                minLength={minLength}
                minLengthMessageId={minLengthMessageId}
              />
            )
          }
        </FormList>
      </AdminConsoleSection>
      {!isAdding && (
        <div
          className="AdminUserForms__LinksEditing__AddButton Clickable"
          onClick={() => setAdding(true)}
        >
          <FormattedMessage id={addButtonId} />
          <FAIcon icon="plus-square" className="icon-16" />
        </div>
      )}
    </div>
  );
}

function LinksEditingPreview({
  render,
  name,
  remove,
  minLength = 0,
  minLengthMessageId,
}) {
  const { values } = useFormContext();
  const value = get(values, name);
  const disabled = minLengthMessageId && value?.length - 1 < minLength;

  return render({
    value,
    remove,
    additionalColumns: [
      {
        key: 'archive',
        render: ({ index }) => (
          <Tooltip
            title={disabled && <FormattedMessage id={minLengthMessageId} />}
          >
            <FAIcon
              icon="trash-alt"
              type={FATypes.REGULAR}
              className="icon-20 AdminUserForms__LinksEditing__DeleteButton Clickable"
              onClick={disabled ? noop : () => remove(index)}
              disabled={disabled}
            />
          </Tooltip>
        ),
      },
    ],
  });
}

function LinksEditingAddingMode({
  name,
  query,
  deleteCacheQueryName,
  keyId,
  labelId,
  add,
  remove,
  rowKey,
  rowLabel,
  headerClassName,
  itemClassName,
  renderHeader,
  renderAdditionalBodyColumns,
  close,
}) {
  const client = useApolloClient();
  useMountEffect(() => {
    if (deleteCacheQueryName) {
      return () => deleteQueryFromCache(client.cache, deleteCacheQueryName);
    }
    return noop;
  });

  const { values } = useFormContext();
  const value = get(values, name);
  const { filterOption } = useLabelFilter();

  const [sort, setSort] = useState();

  const { data, error, loading } = useQuery(query);
  const options = data && extractGraphqlEntity(data);

  const [selectedKeys, setSelectedKeys] = useState(
    Set((value || []).map(property(rowKey)))
  );

  if (error) {
    return <DataError error={error} />;
  }

  return (
    <NoLabelForm component={false}>
      <div className="AdminUserForms__LinksEditing__SearchContainer">
        <FormItemInputText
          name="searchTerm"
          formItemComponentProps={{
            prefix: <FAIcon icon="search" className="icon-18" />,
            allowClear: true,
          }}
          placeholderId="admin.userManagement.userDetail.search.placeholder"
          floatingLabel={false}
        />
      </div>
      <Form.Item noStyle shouldUpdate>
        {fm => {
          const searchTerm = fm.getFieldValue('searchTerm');
          const filteredOptions = options?.filter(opt =>
            filterOption(searchTerm, {
              label: `${get(opt, rowKey)} ${get(opt, rowLabel)}`,
            })
          );
          const sortedOptions = sort
            ? (sort.order === SortOrder.DESC ? reverse : identity)(
                sortBy(filteredOptions, sort.name)
              )
            : filteredOptions;

          return (
            <>
              <div className="padding-hor-norm2 padding-vert-norm">
                <Button
                  type="primary"
                  size="small"
                  className="width-70"
                  onClick={() => {
                    const unassignedOptions = differenceBy(
                      options,
                      value,
                      rowKey
                    );
                    const addedOptions = unassignedOptions.filter(opt =>
                      selectedKeys.has(get(opt, rowKey))
                    );
                    addedOptions.forEach(opt => add(opt));

                    const removedIndices = (value || [])
                      .map((opt, i) => [opt, i])
                      .filter(
                        ([opt, i]) => !selectedKeys.has(get(opt, rowKey))
                      );
                    removedIndices.forEach(([, i]) => remove(i));
                    close();
                  }}
                  disabled={isEmpty(filteredOptions) || selectedKeys.isEmpty()}
                >
                  <FormattedMessage id="admin.userManagement.forms.link" />
                </Button>
              </div>
              <div
                className={classNames(
                  'AdminUserForms__LinksEditing__DataHeader',
                  headerClassName
                )}
              >
                {renderHeader ? (
                  renderHeader({ sort, setSort })
                ) : (
                  <>
                    <div>
                      <FormattedMessage id={keyId} />
                      <AdminConsoleSorter
                        sortName={rowKey}
                        sort={sort}
                        setSort={setSort}
                      />
                    </div>
                    <div>
                      <FormattedMessage id={labelId} />
                      <AdminConsoleSorter
                        sortName={rowLabel}
                        sort={sort}
                        setSort={setSort}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="AdminUserForms__LinksEditing__DataBody">
                {
                  // eslint-disable-next-line no-nested-ternary
                  loading ? (
                    <Spinner />
                  ) : isEmpty(filteredOptions) ? (
                    <div className="AdminUserForms__LinksEditing__DataBody--Empty">
                      <FormattedMessage id="admin.userManagement.forms.noResults" />
                    </div>
                  ) : (
                    sortedOptions?.map(opt => {
                      const key = get(opt, rowKey);
                      const isActive = selectedKeys.has(key);
                      return (
                        <div
                          key={key}
                          className={classNames(
                            'AdminUserForms__Checkboxes__Item',
                            isActive &&
                              'AdminUserForms__Checkboxes__Item--Active',
                            itemClassName
                          )}
                        >
                          <div>
                            <Checkbox
                              checked={isActive}
                              onChange={e => {
                                setSelectedKeys(old =>
                                  e.target.checked
                                    ? old.add(key)
                                    : old.remove(key)
                                );
                              }}
                            >
                              {key}
                            </Checkbox>
                          </div>
                          <div>{get(opt, rowLabel)}</div>
                          {renderAdditionalBodyColumns?.(opt)}
                        </div>
                      );
                    })
                  )
                }
              </div>
            </>
          );
        }}
      </Form.Item>
    </NoLabelForm>
  );
}
