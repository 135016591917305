import { useQuery } from '@apollo/client';
import { property } from 'lodash-es';

import { AVAILABLE_REPORTS_QUERY } from '../../app/graphql/accountQueries';
import { extractGraphqlEntity } from '../../common/utils/graphqlUtils';
import { FormItemInlineCheckbox } from '../../components/forms/checkable';
import { useFormContext } from '../../components/forms/forms';
import { ReportsSelectionSection } from './ReportsSelectionSection';

const COLUMNS = [
  {
    key: 'name',
    labelId: 'reports.reportSelection.description',
    span: 24,
    render: r => r.text,
  },
];

export function ReportsReportSelection() {
  const { values } = useFormContext();
  const { data, error, loading } = useQuery(AVAILABLE_REPORTS_QUERY, {
    variables: {
      accountNumbers: values.selectedAccounts.map(acc => acc.number),
    },
  });
  const items = data && extractGraphqlEntity(data);

  return (
    <ReportsSelectionSection
      index={2}
      titleId="reports.chooseReport"
      headerId="reports.reportSelection"
      subtitleId="reports.reportSelection.subtitle"
      subtitleRightContent={
        <FormItemInlineCheckbox
          name="excludeDeliveredJobs"
          labelId="reports.reportSelection.excludeDeliveredJobs"
          disabled
        />
      }
      searchPlaceholderId="reports.reportSearch.placeholder"
      items={items}
      loading={loading}
      error={error}
      getSearchSourcesFromItem={r => [r.code, r.text]}
      fieldName="selectedReports"
      getKey={property('code')}
      getLabel={property('text')}
      columns={COLUMNS}
    />
  );
}
