import { GoogleMap, LoadScriptNext } from '@react-google-maps/api';
import { property } from 'lodash-es';
import { useRef } from 'react';

import { firstNonNull } from '../../common/utils/funcUtils';
import { config } from '../../config/config';
import { GoogleMapOptimalBoundsSetter } from './googleMap/GoogleMapOptimalBoundsSetter';
import { GoogleMapClustererProvider } from './googleMap/googleMapClustering';
import { DEFAULT_PROPS } from './googleMap/googleMapDefaults';
import { renderMarker, renderPolyline } from './googleMap/googleMapElements';
import { GoogleMapsTooltipProvider } from './googleMap/googleMapTooltips';

// https://github.com/JustFly1984/react-google-maps-api/tree/master/packages/react-google-maps-api
export default function GoogleMapAdapter({
  center,
  zoom,
  markers,
  markersReady = true,
  polylines,
  clustererProps,
  children,
}) {
  const tooltipsRef = useRef();

  return (
    <LoadScriptNext googleMapsApiKey={config.googleMapsApiKey}>
      <GoogleMap
        mapContainerClassName="MapContainer"
        {...DEFAULT_PROPS}
        zoom={firstNonNull(zoom, DEFAULT_PROPS.zoom)}
        center={firstNonNull(center, DEFAULT_PROPS.center)}
      >
        {markers?.length && (
          <GoogleMapsTooltipProvider>
            <GoogleMapClustererProvider
              tooltipParent={tooltipsRef.current}
              {...clustererProps}
            >
              {markersReady && (
                <GoogleMapOptimalBoundsSetter
                  coordinates={markers.map(property('coords'))}
                />
              )}
              {(polylines || []).map(renderPolyline)}
              {markers.map(renderMarker)}
            </GoogleMapClustererProvider>
          </GoogleMapsTooltipProvider>
        )}
        {children}
        <div ref={tooltipsRef} />
      </GoogleMap>
    </LoadScriptNext>
  );
}
