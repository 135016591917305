import { Spin } from 'antd';
import classNames from 'classnames';
import { forwardRef } from 'react';

export const SpinnerType = {
  SQUARE: 'SQUARE',
  ROW: 'ROW',
};

/**
 * @prop {boolean} overlay If true, the container will fill the parent and position
 *                         the spinner according to type
 * @prop {SpinnerType} type * SQUARE - container will expand to square shape and put spinner into middle
 *                          * ROW - container will have small height and spinner will be on top
 */
export const Spinner = forwardRef(
  ({ overlay, type = SpinnerType.ROW, ...rest }, ref) => (
    <div
      ref={ref}
      className={classNames({
        SpinnerContainer: true,
        'SpinnerContainer-overlay': overlay,
        'SpinnerContainer-square': type === SpinnerType.SQUARE,
        'SpinnerContainer-row': type === SpinnerType.ROW,
      })}
      data-role="loader"
    >
      <Spin {...rest} />
    </div>
  )
);

export function ThreeDotsSpinner({ className }) {
  return (
    <div
      className={classNames('ThreeDotsSpinner', className)}
      data-role="loader"
    >
      <span />
    </div>
  );
}
