import { SortOrder, makeSort } from '../../../app/common/sort';
import { FAIcon } from '../../../components/adapters/fontAwesomeAdapters';

export default function AdminConsoleSorter({ sortName, sort, setSort }) {
  if (sort?.name !== sortName) {
    return (
      <FAIcon
        icon="sort"
        className="Clickable icon-16"
        onClick={() => setSort(makeSort(sortName, SortOrder.ASC))}
      />
    );
  }
  if (sort?.order === SortOrder.ASC) {
    return (
      <FAIcon
        icon="caret-up"
        className="Clickable icon-16"
        onClick={() => setSort(makeSort(sortName, SortOrder.DESC))}
      />
    );
  }
  return (
    <FAIcon
      icon="caret-down"
      className="Clickable icon-16"
      onClick={() => setSort(undefined)}
    />
  );
}
