import { Menu } from 'antd';
import classNames from 'classnames';
import { get, stubFalse } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import routes from '../../../app/routes';
import { usePageGuard } from '../../../components/guards/PageGuard';
import {
  isMenuItemActive,
  renderMenuDefinition,
  useActiveRoute,
} from '../../../utils/routeUtils';

function MenuItem({
  component: C,
  className,
  labelId,
  to,
  children,
  disabled: disabledOuter,
  active: activeOuter,
  activeRoute,
  guard,
  ...rest
}) {
  const allowed = usePageGuard(guard);
  const disabled = disabledOuter || !allowed;
  const active = activeOuter || (to && isMenuItemActive(activeRoute, { to }));

  return (
    <div
      key={labelId}
      className={classNames(
        'AdminConsoleMenu__Item',
        { active, disabled },
        className
      )}
      {...rest}
    >
      <Link className="AdminConsoleMenu__ItemInner" to={to}>
        <FormattedMessage id={labelId} />
      </Link>
    </div>
  );
}

function renderMenuItem(
  { labelId, to, guard },
  { activeRoute } = {},
  { level } = {}
) {
  if (level > 0) {
    return null;
  }

  return (
    <MenuItem
      component={Menu.Item}
      key={labelId}
      to={to}
      labelId={labelId}
      disabled={!to}
      activeRoute={activeRoute}
      guard={guard}
    />
  );
}

const ADMIN_MENU_ITEMS = [
  { to: routes.admin.userManagement, labelId: 'admin.menu.userManagement' },
  {
    to: routes.admin.accountManagement,
    labelId: 'admin.menu.accountManagement',
  },
  {
    to: routes.admin.businessType,
    labelId: 'admin.menu.businessType',
  },
  { to: routes.admin.systemSettings, labelId: 'admin.menu.systemSettings' },
];

export default function AdminConsoleMenu() {
  const activeRoute = get(useActiveRoute(ADMIN_MENU_ITEMS), 'to');
  const props = { activeRoute };

  return (
    <div className="AdminConsoleMenu">
      <div className="AdminConsoleMenu__Content">
        {renderMenuDefinition(ADMIN_MENU_ITEMS, {
          renderMenuItem,
          renderSubmenu: stubFalse,
          props,
        })}
      </div>
    </div>
  );
}
