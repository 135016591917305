/* eslint-disable react/style-prop-object */
import { isNumber } from 'lodash-es';
import { FormattedNumber } from 'react-intl';

import { FormItemInputText } from '../forms/basicFormElements';
import { useFormContext } from '../forms/forms';

// TODO - bind to config

export function FormItemInputCurrency({ formItemComponentProps, ...rest }) {
  const { values } = useFormContext();
  return (
    <FormItemInputText
      formItemComponentProps={{
        ...formItemComponentProps,
        prefix: values?.currency?.code ? (
          <div>{values?.currency?.code}</div>
        ) : null,
      }}
      {...rest}
    />
  );
}

export function CurrencyFormat({ value, ...rest }) {
  if (!isNumber(value)) {
    return '-';
  }
  return (
    <FormattedNumber
      style="currency"
      currency="USD"
      maximumFractionDigits={2}
      minimumFractionDigits={0}
      value={value}
      {...rest}
    />
  );
}
