import { createContext } from 'react';

export const SavedShipmentContext = createContext({
  enabled: false,
  active: false,
  forceTbdServiceType: false,
});

export function SavedShipmentProvider({
  enabled = true,
  active,
  forceTbdServiceType,
  children,
}) {
  return (
    <SavedShipmentContext.Provider
      value={{ enabled, active, forceTbdServiceType }}
    >
      {children}
    </SavedShipmentContext.Provider>
  );
}
