import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { Route, Switch, generatePath } from 'react-router';

import { ACCOUNT_QUERY } from '../../../app/graphql/accountQueries';
import routes from '../../../app/routes';
import { extractGraphqlEntity } from '../../../common/utils/graphqlUtils';
import AdminConsoleSideMenu from './AdminConsoleSideMenu';
import { AdminUserDetailMenu } from './AdminUserManagementMenu';

function MenuBase({ additionalItems = [] }) {
  return (
    <AdminConsoleSideMenu
      items={[
        {
          labelId: 'admin.accountManagement.accountList',
          to: routes.admin.accountManagement,
        },
        ...additionalItems,
      ]}
    />
  );
}

function DefaultMenu() {
  return <MenuBase />;
}

function AccountLabel({ account }) {
  const intl = useIntl();
  return `${intl.formatMessage({
    id: 'admin.accountManagement.accountDetailPrefix',
  })}: ${account.number}`;
}

function DetailMenu({ accountNumber, additionalUserItems }) {
  const { data } = useQuery(ACCOUNT_QUERY, { variables: { accountNumber } });
  const account = (data && extractGraphqlEntity(data)) || { number: '-' };

  return (
    <MenuBase
      additionalItems={[
        {
          label: <AccountLabel account={account} />,
          to: generatePath(routes.admin.accountDetail.root, { accountNumber }),
          children: [
            {
              to: generatePath(routes.admin.accountDetail.root, {
                accountNumber,
              }),
              labelId: 'admin.accountManagement.accountDetail.accountProfile',
            },
            {
              labelId: 'admin.accountManagement.accountDetail.monitorSettings',
              children: [
                {
                  to: generatePath(routes.admin.accountDetail.documents, {
                    accountNumber,
                  }),
                  labelId: 'admin.accountManagement.accountDetail.documents',
                },
              ],
            },
            {
              labelId: 'admin.accountManagement.accountDetail.shipmentSettings',
              children: [
                {
                  to: generatePath(
                    routes.admin.accountDetail.addressBookSharing,
                    {
                      accountNumber,
                    }
                  ),
                  labelId:
                    'admin.accountManagement.accountDetail.addressBookSharing',
                },
                {
                  to: generatePath(routes.admin.accountDetail.serviceTypes, {
                    accountNumber,
                  }),
                  labelId: 'admin.accountManagement.accountDetail.serviceTypes',
                },
                {
                  to: generatePath(routes.admin.accountDetail.commodityTypes, {
                    accountNumber,
                  }),
                  labelId:
                    'admin.accountManagement.accountDetail.commodityTypes',
                },
                {
                  to: generatePath(routes.admin.accountDetail.gpsDevices, {
                    accountNumber,
                  }),
                  labelId: 'admin.accountManagement.accountDetail.gpsDevices',
                },
                {
                  to: generatePath(
                    routes.admin.accountDetail.temperatureDevices,
                    {
                      accountNumber,
                    }
                  ),
                  labelId:
                    'admin.accountManagement.accountDetail.temperatureDevices',
                },
                {
                  to: generatePath(routes.admin.accountDetail.packageTypes, {
                    accountNumber,
                  }),
                  labelId: 'admin.accountManagement.accountDetail.packageTypes',
                },
                {
                  to: generatePath(
                    routes.admin.accountDetail.packageTemperatures,
                    {
                      accountNumber,
                    }
                  ),
                  labelId: 'admin.accountManagement.accountDetail.packageTemps',
                },
              ],
            },
            {
              labelId: 'admin.accountManagement.accountDetail.userManagement',
              children: [
                {
                  to: generatePath(routes.admin.accountDetail.userList, {
                    accountNumber,
                  }),
                  labelId: 'admin.accountManagement.accountDetail.userList',
                },
                ...additionalUserItems,
              ],
            },
          ],
        },
      ]}
    />
  );
}

export default function AdminAccountManagementMenu() {
  return (
    <Switch>
      <Route path={routes.admin.accountDetail.userDetail}>
        {({ match }) => (
          <AdminUserDetailMenu
            userId={match.params?.userId}
            baseRoute={generatePath(routes.admin.accountDetail.userDetail, {
              accountNumber: match.params?.accountNumber,
              userId: ':userId',
            })}
          >
            {({ items }) => (
              <DetailMenu
                accountNumber={match.params?.accountNumber}
                additionalUserItems={items}
              />
            )}
          </AdminUserDetailMenu>
        )}
      </Route>
      <Route path={routes.admin.accountDetail.root}>
        {({ match }) => (
          <DetailMenu
            accountNumber={match.params?.accountNumber}
            additionalUserItems={[
              {
                to: generatePath(routes.admin.accountDetail.addUser, {
                  accountNumber: match.params?.accountNumber,
                }),
                labelId: 'admin.accountManagement.accountDetail.addUser',
              },
            ]}
          />
        )}
      </Route>
      <Route path="*">
        <DefaultMenu />
      </Route>
    </Switch>
  );
}
