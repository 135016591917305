import { Typography } from 'antd';

import { FATypes } from '../../../../components/adapters/fontAwesomeAdapters';
import {
  FormTheme,
  FormThemeOverrideContextProvider,
} from '../../../../components/forms/formTheme';
import AdminConsoleSection, {
  AdminConsoleSectionContent,
  AdminConsoleSectionTitleHeader,
} from '../../components/AdminConsoleSection';
import AdminConsoleContent from '../../layout/AdminConsoleContent';
import { formatUserLabel } from '../../utils/adminFormatting';
import AdminUserDocumentGroupFormSection from '../forms/AdminUserDocumentGroupFormSection';

export default function AdminUserDetailDocumentGroup({
  user,
  viewMode,
  visible,
}) {
  return (
    <AdminConsoleContent
      titleId="admin.userManagement.userDetail.documentGroup"
      titleIcon="file-alt"
      titleIconProps={{ type: FATypes.REGULAR }}
      entityLabel={formatUserLabel(user)}
      isEditing={!viewMode}
      visible={visible}
    >
      <FormThemeOverrideContextProvider themeClassName={FormTheme.DarkSimple}>
        <AdminConsoleSection>
          <AdminConsoleSectionTitleHeader>
            <Typography.Title level={3}>
              {formatUserLabel(user)}
            </Typography.Title>
          </AdminConsoleSectionTitleHeader>
          <AdminConsoleSectionContent>
            <AdminUserDocumentGroupFormSection />
          </AdminConsoleSectionContent>
        </AdminConsoleSection>
      </FormThemeOverrideContextProvider>
    </AdminConsoleContent>
  );
}
