import { Col, Row } from 'antd';
import { get } from 'immutable';
import { FormattedMessage } from 'react-intl';

import { FAIcon } from '../../../components/adapters/fontAwesomeAdapters';
import ShipmentDetailDrawer from '../../../components/drawers/ShipmentDetailDrawer';
import { DRAWER_MEDIA_MAPPER } from '../../../components/drawers/drawerCommon';
import { InnerContentScrollbars } from '../../../components/layout/InnerContentScrollbars';
import { TwoLinesSeparator } from '../../../components/layout/layoutElements';
import { InlineLink, LabelWithValue } from '../../../components/typography';

function SmOrLgCol({ sm, lg, ...rest }) {
  return <Col xl={lg} lg={lg} md={sm} sm={sm} xs={sm} {...rest} />;
}

function PieceRow({ index, children, ...rest }) {
  return (
    <>
      {index === 0 && <TwoLinesSeparator />}
      <div className="ShipmentPackagePieceList-Row">
        <div className="number-in-circle">{index + 1}</div>
        <Row className="Flex1" align="top" {...rest}>
          {children}
        </Row>
      </div>
      <TwoLinesSeparator />
    </>
  );
}

function AviationRow({ piece, index, ...rest }) {
  return (
    <PieceRow index={index} {...rest}>
      <SmOrLgCol lg={8} sm={14}>
        <LabelWithValue
          labelSize="full"
          labelId="book.newShipment.label.partNumber"
          text={get(piece, 'partNumber')}
        />
      </SmOrLgCol>
      <SmOrLgCol lg={4} sm={10}>
        <LabelWithValue
          labelSize="full"
          labelId="book.newShipment.label.quantity"
          text={get(piece, 'quantity')}
        />
      </SmOrLgCol>
      <SmOrLgCol lg={12} sm={24}>
        <LabelWithValue
          labelSize="full"
          labelId="book.newShipment.label.description"
          text={get(piece, 'description')}
        />
      </SmOrLgCol>
    </PieceRow>
  );
}

function ShipmentPackagePieceListDrawer({ isOpen, close, data, isAviation }) {
  const PieceComponent = isAviation ? AviationRow : null;

  return (
    <ShipmentDetailDrawer
      visible={isOpen}
      onClose={close}
      header={
        <FormattedMessage id="book.newShipment.packages.pieceList.aviation.dialog.title" />
      }
      className="ShipmentPackagePieceListDrawer"
    >
      <InnerContentScrollbars mediaMapper={DRAWER_MEDIA_MAPPER}>
        <div className="ShipmentPackagePieceList">
          {PieceComponent &&
            data.map((piece, i) => (
              <PieceComponent
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                index={i}
                piece={piece}
              />
            ))}
        </div>
      </InnerContentScrollbars>
    </ShipmentDetailDrawer>
  );
}

export function ShipmentPackagePieceListButton({
  open,
  isOpen,
  close,
  data,
  isAviation,
  isBiomedLike,
}) {
  return (
    <div onClick={e => e.stopPropagation()}>
      {data.length > 0 && isAviation && (
        <InlineLink
          className="ShipmentPackagePieceList-Button"
          textId="shipmentDetail.pieceList.button.label"
          iconAfter={<FAIcon icon="external-link-square-alt" />}
          onClick={e => open()}
        />
      )}
      <ShipmentPackagePieceListDrawer
        isOpen={isOpen}
        close={close}
        data={data}
        isAviation={isAviation}
        isBiomedLike={isBiomedLike}
      />
    </div>
  );
}
