import classNames from 'classnames';
import { createContext } from 'react';

export const FormTheme = {
  Default: 'FormTheme--Default',
  DarkSimple: 'FormTheme--DarkSimple',
  DarkOrange: 'FormTheme--DarkOrange',
  DarkOrangeSimple: 'FormTheme--DarkOrangeSimple',
};

export const FormThemeOverrideContext = createContext({
  themeClassName: FormTheme.Default,
});
export function FormThemeOverrideContextProvider({
  component,
  themeClassName,
  className,
  children,
  ...rest
}) {
  const C = component || 'div';

  return (
    <FormThemeOverrideContext.Provider value={{ themeClassName }}>
      <C {...rest} className={classNames(themeClassName, className)}>
        {children}
      </C>
    </FormThemeOverrideContext.Provider>
  );
}
