import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getLoggedIn } from '../app/redux/auth';

/** Show/hide service desk widget iframe for un/authorized users */
export default function ServiceDeskWidget() {
  const loggedIn = useSelector(getLoggedIn);
  const [widgetElem, setWidgetElem] = useState();

  // sets the widget element when it becomes available in page
  useEffect(() => {
    if (widgetElem) {
      return () => undefined;
    }
    const interval = setInterval(() => {
      setWidgetElem(document.getElementById('jsd-widget'));
    }, 500);
    return () => clearInterval(interval);
  });

  // sets the visibility of widget based on login state
  useEffect(() => {
    if (widgetElem) {
      widgetElem.style.visibility = loggedIn ? 'visible' : 'hidden';
    }
  });

  return null;
}
