import { useQuery } from '@apollo/client';

import { DOCUMENT_TYPES_QUERY } from '../../../../app/graphql/adminQueries';
import AdminAccountTransferPage from './AdminAccountTransferPage';

const AdminAccountDocuments = ({ account, viewMode, visible }) => {
  const { data, loading } = useQuery(DOCUMENT_TYPES_QUERY, {
    skip: !visible,
  });

  return (
    <AdminAccountTransferPage
      account={account}
      viewMode={viewMode}
      visible={visible}
      itemName="documentsSettings"
      titleIcon="file-alt"
      titleId="admin.accountManagement.accountDetail.documents"
      titlesPrefix="admin.accountManagement.accountDetail.documents"
      data={data?.documentTypes}
      loading={loading}
    />
  );
};

export default AdminAccountDocuments;
