import { Col, Row } from 'antd';
import classNames from 'classnames';

import { graphQLToFormAddressBookEntry } from '../../app/data/addressBookConversions';
import { formatLocationMedium } from '../../common/utils/formatUtils';
import { FAIcon } from '../../components/adapters/fontAwesomeAdapters';
import { useFormContext } from '../../components/forms/forms';
import { TemplateListDeleteButton } from '../../components/popups/templates/TemplatesFormDrawer';
import { BlockLink, LabelWithValue } from '../../components/typography';
import { cssVariables } from '../../styles/cssVariables';
import { pxToNumber } from '../../utils/cssUtils';

export function AddressBookListSection({ header }) {
  return (
    <div className="AddressBookList-Section" data-letter={header}>
      <div className="AddressBookList-SectionHeader">{header}</div>
    </div>
  );
}

const StyledLabelWithValue = props => (
  <LabelWithValue {...props} size="xs" labelSize="full" nowrap />
);

export function AddressBookListEntry({
  data,
  onEdit,
  onApply,
  active,
  index,
  onDelete: onDeleteOuter,
  removeDialogClassName,
  removeText,
  removeTextId,
  removeSuccessMessageId,
  readOnly,
}) {
  const { formInstance, forceUpdate } = useFormContext();
  const setAndClose = () => {
    formInstance.setFieldsValue(graphQLToFormAddressBookEntry(data));
    forceUpdate();
    onApply(data);
  };

  const onDelete = () => onDeleteOuter(data.id);

  const gutter = pxToNumber(cssVariables.spaceNorm);

  return (
    <BlockLink
      className={classNames('AddressBookList-Entry', {
        active,
        'odd-index': index % 2 === 0,
        'even-index': index % 2 === 1,
      })}
      onClick={setAndClose}
      data-subject="address-book"
      data-role="item"
    >
      <Row align="stretch" gutter={gutter}>
        <Col className="Flex1">
          <Row align="top" gutter={gutter}>
            <Col className="cols-1-2">
              <Row align="top" gutter={gutter}>
                <Col className="col-1">
                  <StyledLabelWithValue
                    labelId="addressBook.companyName"
                    text={data.companyName}
                  />
                  <StyledLabelWithValue
                    labelId="addressBook.keyword"
                    text={data.keyword}
                  />
                </Col>
                <Col className="col-2">
                  <StyledLabelWithValue
                    labelId="addressBook.address"
                    text={formatLocationMedium(data)}
                    showNewlines
                  />
                </Col>
              </Row>
            </Col>
            <Col className="col-3">
              <StyledLabelWithValue
                labelId="addressBook.phone"
                text={data.contact?.phone}
              />
              <StyledLabelWithValue
                labelId="addressBook.email"
                text={data.contact?.email}
              />
            </Col>
          </Row>
        </Col>
        {!readOnly && (
          <Col className="Clickable control-buttons">
            <FAIcon
              icon="edit"
              className="icon-14"
              onClick={e => {
                onEdit();
                e.stopPropagation();
              }}
            />
            <TemplateListDeleteButton
              onDelete={onDelete}
              removeDialogClassName={removeDialogClassName}
              removeText={removeText}
              removeTextId={removeTextId}
              removeSuccessMessageId={removeSuccessMessageId}
            >
              {({ open }) => (
                <FAIcon
                  icon="trash-alt"
                  className="icon-14"
                  onClick={e => {
                    open();
                    e.stopPropagation();
                  }}
                />
              )}
            </TemplateListDeleteButton>
          </Col>
        )}
      </Row>
    </BlockLink>
  );
}
