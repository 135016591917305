import { Typography } from 'antd';

import { FAIcon } from '../../../../components/adapters/fontAwesomeAdapters';
import AdminConsoleSection, {
  AdminConsoleSectionContent,
  AdminConsoleSectionTitleHeader,
} from '../../components/AdminConsoleSection';
import AdminConsoleContent from '../../layout/AdminConsoleContent';
import { formatUserLabel } from '../../utils/adminFormatting';
import AdminUserExpandedTrackingFormSections from '../forms/AdminUserExpandedTrackingFormSections';

export default function AdminUserDetailExpandedTracking({
  user,
  viewMode,
  visible,
}) {
  return (
    <AdminConsoleContent
      titleId="admin.userManagement.userDetail.expandedTracking"
      titleIconNode={
        <div className="flex-center" style={{ alignItems: 'flex-start' }}>
          <FAIcon icon="search" className="icon-18" />
          <FAIcon icon="plus" className="icon-11" />
        </div>
      }
      entityLabel={formatUserLabel(user)}
      isEditing={!viewMode}
      visible={visible}
    >
      <AdminConsoleSection>
        <AdminConsoleSectionTitleHeader>
          <Typography.Title level={3}>{formatUserLabel(user)}</Typography.Title>
        </AdminConsoleSectionTitleHeader>
        <AdminConsoleSectionContent>
          <AdminUserExpandedTrackingFormSections />
        </AdminConsoleSectionContent>
      </AdminConsoleSection>
    </AdminConsoleContent>
  );
}
