import ErrorBoundary from '../components/ErrorBoundary';
import { MetadataInfo } from './metadataWidgets';

export default function NonLoggedTopBar() {
  return (
    <ErrorBoundary>
      <div className="NonLoggedTopBar">
        <MetadataInfo canOpenChangelog={false} />
      </div>
    </ErrorBoundary>
  );
}
