import { Col, Row } from 'antd';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router';

import { setAccountsFilter } from '../../app/redux/admin';
import routes from '../../app/routes';
import { useMountEffect } from '../../common/utils/hookUtils';
import { cssVariables } from '../../styles/cssVariables';
import { pxToNumber } from '../../utils/cssUtils';
import AdminAccountDetail from './accountManagement/AdminAccountDetail';
import AdminAccountList from './accountManagement/AdminAccountList';
import { AdminAccountAddUser } from './accountManagement/detail/AdminAccountAddUser';
import { AdminAccountUserDetail } from './accountManagement/detail/AdminAccountUserDetail';
import { AdminAccountUserList } from './accountManagement/detail/AdminAccountUserList';
import AdminAccountManagementMenu from './components/AdminAccountManagementMenu';
import AdminConsoleTitle from './components/AdminConsoleTitle';
import AdminConsoleTemplate from './layout/AdminConsoleTemplate';
import { AdminAddUserSteps } from './userManagement/AdminAddUser';

export default function AdminAccountManagementPage() {
  const headerRightRef = useRef();
  const dispatch = useDispatch();
  useMountEffect(() => () => dispatch(setAccountsFilter({})));

  return (
    <AdminConsoleTemplate
      title={
        <>
          <AdminConsoleTitle
            titleId="admin.accountManagement.title"
            icon="building"
          />
          <div ref={headerRightRef} />
        </>
      }
    >
      <Row align="top" gutter={pxToNumber(cssVariables.spaceNorm2)}>
        <Col>
          <AdminAccountManagementMenu />
          <Switch>
            <Route path={routes.admin.accountDetail.addUser}>
              {({ match }) =>
                match && <AdminAddUserSteps baseRoute={match.url} level={3} />
              }
            </Route>
          </Switch>
        </Col>
        <Col className="Flex1">
          <Switch>
            <Route path={routes.admin.accountManagement} exact>
              <AdminAccountList headerRightRef={headerRightRef} />
            </Route>
            <Route path={routes.admin.accountDetail.userList}>
              {({ match }) =>
                match.params?.accountNumber && (
                  <AdminAccountUserList
                    accountNumber={match.params.accountNumber}
                    headerRightRef={headerRightRef}
                  />
                )
              }
            </Route>
            <Route path={routes.admin.accountDetail.addUser}>
              {({ match }) =>
                match.params?.accountNumber && (
                  <AdminAccountAddUser
                    accountNumber={match.params.accountNumber}
                    headerRightRef={headerRightRef}
                  />
                )
              }
            </Route>
            <Route path={routes.admin.accountDetail.userDetail}>
              {({ match }) =>
                match.params?.userId && (
                  <AdminAccountUserDetail
                    accountNumber={match.params.accountNumber}
                    userId={match.params.userId}
                    headerRightRef={headerRightRef}
                  />
                )
              }
            </Route>
            <Route path={routes.admin.accountDetail.root}>
              {({ match }) =>
                match.params?.accountNumber && (
                  <AdminAccountDetail
                    accountNumber={match.params.accountNumber}
                    headerRightRef={headerRightRef}
                  />
                )
              }
            </Route>
          </Switch>
        </Col>
      </Row>
    </AdminConsoleTemplate>
  );
}
