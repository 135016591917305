export function findAirplaneInChangeFlight(flights = []) {
  // Flight that hasn't departed yet and which is after a flight that has already arrived
  for (let i = 1; i < flights.length; i++) {
    const prevArrived = flights[i - 1]?.arrival?.actual;
    const thisDeparted = flights[i]?.departure?.actual;
    if (prevArrived && !thisDeparted) {
      return flights[i];
    }
  }
  return null;
}

export function findAirplaneInRouteFlight(flights = []) {
  /* The first flight that has a location and is "in flight"
   * "in flight" means:
   *  - for 0 <= i <= n-1 -> flight that hasn't arrived
   *  - for i = n         -> flight that hasn't been recovered (but may have arrived)
   */
  const n = flights.length - 1;
  for (let i = 0; i < flights.length; i++) {
    const flight = flights[i];
    if (flight?.location) {
      if (i < n && !flight?.arrival?.actual) {
        return flight;
      }
      if (i === n && !flight?.recoveryDateTime) {
        return flight;
      }
    }
  }
  return null;
}
