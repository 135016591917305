import { useQuery } from '@apollo/client';
import { Tooltip } from 'antd';
import { compact, flatten, isEmpty, merge, property, some } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import { ShipmentStage } from '../../app/enums/ShipmentStage';
import { JOB_LOCATION_QUERY } from '../../app/graphql/jobQueries';
import { EMPTY_VALUE } from '../../common/utils/formatUtils';
import { extractGraphqlEntity } from '../../common/utils/graphqlUtils';
import GoogleMapAdapter from '../../components/adapters/GoogleMapAdapter';
import { FAIconChars } from '../../components/adapters/fontAwesomeAdapters';
import {
  iconOnlyLabel,
  imgOnlyLabel,
} from '../../components/adapters/googleMap/googleMapElements';
import { makeMarker } from '../../components/adapters/googleMap/googleMapHelpers';
import { useFlashMessageContext } from '../../components/dialogs/FlashMessageProvider';
import { MapCard } from '../../components/maps/MapCard';
import airplaneChangeImg from '../../resources/img/icons/airplane-change.png';
import airplaneInRouteImgEast from '../../resources/img/icons/airplane-in-route-east.png';
import airplaneInRouteImgWest from '../../resources/img/icons/airplane-in-route-west.png';
import gpsImg from '../../resources/img/icons/gps.png';
import emptyImg from '../../resources/img/icons/transparent-pixel.png';
import truckAndGpsImg from '../../resources/img/icons/truck-and-gps.png';
import { cssVariables } from '../../styles/cssVariables';
import { latLng, mapSize } from '../../utils/mapUtils';
import {
  findAirplaneInChangeFlight,
  findAirplaneInRouteFlight,
} from './flightUtils';

const ClusterableMarkerType = {
  DEVICE: 'device',
  DRIVER: 'driver',
};

function driverMarker(coords) {
  return makeMarker(
    coords,
    iconOnlyLabel(FAIconChars.TRUCK, undefined, cssVariables['heading-3-size'])
  );
}
function airplaneChangeMarker(coords) {
  return makeMarker(coords, imgOnlyLabel(airplaneChangeImg, mapSize(52, 43)));
}
function airplaneInRouteMarker(coords, { origCoords, destCoords }) {
  const isEast =
    origCoords?.lng && destCoords?.lng
      ? destCoords.lng >= origCoords.lng
      : // Default to east if no information about direction is available
        true;
  const img = isEast ? airplaneInRouteImgEast : airplaneInRouteImgWest;
  return makeMarker(coords, imgOnlyLabel(img, mapSize(50, 39)));
}
function gpsMarker(coords) {
  return makeMarker(coords, imgOnlyLabel(gpsImg, mapSize(26, 30)));
}
function originMarker(coords) {
  return makeMarker(
    coords,
    iconOnlyLabel(
      FAIconChars.MAP_MARKER,
      undefined,
      cssVariables['heading-2-size']
    )
  );
}
function destinationMarker(coords) {
  return makeMarker(
    coords,
    iconOnlyLabel(
      FAIconChars.FLAG_CHECKERED,
      undefined,
      cssVariables['heading-3-size']
    )
  );
}

function extractMarkersAndPolylines(locationData) {
  const driverCoords = locationData?.driver?.location;

  const packages = locationData?.packagesInformation?.packages || [];
  const gpsDevices = flatten(packages.map(pkg => pkg?.gpsDevices || []));
  const deviceCoords = gpsDevices
    .map(device => ({
      coords: device?.sensorData?.location,
      serialNumber: device?.serialNumber,
    }))
    .filter(property('coords'));
  const airplaneInChangeFlight = findAirplaneInChangeFlight(
    locationData?.flights
  );
  const airplaneInRouteFlight =
    !airplaneInChangeFlight && findAirplaneInRouteFlight(locationData?.flights);

  const origCoords = locationData?.origin?.location;
  const destCoords = locationData?.destination?.location;

  const markers = compact([
    driverCoords && {
      ...driverMarker(driverCoords),
      key: 'driver',
      data: { type: ClusterableMarkerType.DRIVER },
    },
    ...deviceCoords.map(({ coords, serialNumber }, i) => ({
      ...gpsMarker(coords),
      key: `device${i}`,
      data: { type: ClusterableMarkerType.DEVICE, serialNumber },
      tooltipContent: serialNumber,
    })),
    airplaneInChangeFlight && {
      ...airplaneChangeMarker(airplaneInChangeFlight.originAirportLocation),
      key: 'planeChange',
    },
    airplaneInRouteFlight && {
      ...airplaneInRouteMarker(airplaneInRouteFlight.location, {
        origCoords,
        destCoords,
      }),
      key: 'plane',
    },
    origCoords && { ...originMarker(origCoords), key: 'orig' },
    destCoords && { ...destinationMarker(destCoords), key: 'dest' },
  ]);
  return { markers };
}

function includeMarkerInClustering(marker) {
  return marker?.data?.type;
}

function calculateCluster(items) {
  const driverPresent = some(
    items,
    m => m?.data?.type === ClusterableMarkerType.DRIVER
  );
  const devicePresent = some(
    items,
    m => m?.data?.type === ClusterableMarkerType.DEVICE
  );

  // Index is 1-based and references index in `CLUSTERER_STYLES` array

  // Both driver and device markers present - show combined icon
  if (driverPresent && devicePresent) {
    return { index: 3, text: '' };
  }

  // Only device marker present - show standard icon
  if (devicePresent) {
    return { index: 2, text: '' };
  }

  // Only driver marker present - show standard icon
  return { index: 1, text: FAIconChars.TRUCK };
}

const CLUSTERER_STYLES = [
  {
    url: emptyImg,
    width: 30,
    height: 24,
    textColor: cssVariables.colorRed,
    fontFamily: "'Font Awesome 5 Free'",
    fontWeight: '700',
    textSize: cssVariables['heading-3-size'],
  },
  {
    url: gpsImg,
    width: 26,
    height: 30,
  },
  {
    url: truckAndGpsImg,
    width: 30,
    height: 39,
  },
];

function ClusterTooltip({ serialNumbers, rect, rectElement }) {
  const topSpace = rect.top;
  const bottomSpace = document.body.clientHeight - rect.bottom;

  return (
    <Tooltip
      placement={topSpace >= bottomSpace ? 'top' : 'bottom'}
      trigger="hover"
      title={<div className="nl-as-newline">{serialNumbers.join('\n')}</div>}
    >
      {rectElement}
    </Tooltip>
  );
}

function getClusterTooltip({ markers: ms, rect, rectElement }) {
  const serialNumbers = ms
    .filter(({ data }) => data?.type === ClusterableMarkerType.DEVICE)
    .map(({ data }) => data?.serialNumber || EMPTY_VALUE);
  return (
    !isEmpty(serialNumbers) && (
      <ClusterTooltip
        key={serialNumbers.join(',')}
        serialNumbers={serialNumbers}
        rect={rect}
        rectElement={rectElement}
      />
    )
  );
}
const CLUSTERER_PROPS = {
  enable: true,
  includeMarkerInClustering,
  calculator: calculateCluster,
  styles: CLUSTERER_STYLES,
  getTooltip: getClusterTooltip,
};

export function ShipmentDetailMapWithData({ locationData }) {
  const markerProps = extractMarkersAndPolylines(locationData);

  return <GoogleMapAdapter {...markerProps} clustererProps={CLUSTERER_PROPS} />;
}

function PollingShipmentDetailMap({ shipment }) {
  const { errorMessage } = useFlashMessageContext();
  const { data } = useQuery(JOB_LOCATION_QUERY, {
    variables: { jobNumber: shipment.jobNumber },
    // Loading and refreshing will be taken care of in full shipment query to prevent multiple requests
    fetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,
    onError: errorMessage,
  });

  const locationData = merge({}, shipment, data && extractGraphqlEntity(data));
  const markerProps = extractMarkersAndPolylines(locationData);

  return <GoogleMapAdapter {...markerProps} clustererProps={CLUSTERER_PROPS} />;
}

const defaultRenderMap = map => <MapCard>{map}</MapCard>;
export default function ShipmentDetailMap({
  shipment,
  renderMap = defaultRenderMap,
  disablePolling,
}) {
  if (shipment?.metadata?.blindingInformation?.location) {
    return renderMap(
      <GoogleMapAdapter center={latLng(39.8, -84.6)}>
        <div className="ShipmentDetail__BlindedMap">
          <span>
            <FormattedMessage id="gdpr.blinded" />
          </span>
        </div>
      </GoogleMapAdapter>
    );
  }
  if (shipment.jobNumber && shipment.stage !== ShipmentStage.SAVED) {
    return renderMap(<PollingShipmentDetailMap shipment={shipment} />);
  }
  return renderMap(<ShipmentDetailMapWithData locationData={shipment} />);
}
