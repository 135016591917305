export function svgCirclePath(r, { cx, cy } = {}) {
  const cX = cx || r;
  const cY = cy || r;
  return `
  M ${cX - r}, ${cY}
  a ${r},${r} 0 1,0 ${r * 2},0
  a ${r},${r} 0 1,0 ${-r * 2},0
  `;
}

export const degToRad = deg => (deg * Math.PI) / 180;
export const radToDeg = rad => (rad * 180) / Math.PI;
