import SignupForm from '../forms/SignupForm';
import { useChangePasswordURLParams } from '../forms/auth/newPasswordCommon';
import NonLoggedPage from '../templates/NonLoggedPage';

export default function SignupPage() {
  const { token, email } = useChangePasswordURLParams();

  return (
    <NonLoggedPage className="SignupPage" logoutUser>
      <SignupForm token={token} email={email} />
    </NonLoggedPage>
  );
}
