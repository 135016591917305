import { pick } from 'lodash-es';
import { useCallback, useState } from 'react';

import { shipmentTemplateGraphQLToForm } from '../../../app/data/shipmentTemplateConversions';
import {
  canComputeDimensionalWeight,
  useComputeDimensionalWeight,
} from '../packageList/packageListCommon';
import { copyShipmentTemplateIntoForm } from './shipmentTemplateUtils';

export function useShipmentTemplatesActiveItem({
  forms,
  afterApply,
  drawerData,
}) {
  const [activeItem, setActiveItem] = useState();
  const activeId = activeItem?.id;

  const isAdding = !!drawerData?.adding;
  const afterSave = ({ id, name, ...rest }) => {
    const data = rest;
    // If new template is created or active template edited, it should be activated
    // and form should be updated with new values
    if (isAdding || activeId === id) {
      setActiveItem({ id, name, data });
      copyShipmentTemplateIntoForm(forms, data);
      afterApply();
    }
  };

  const afterDelete = ({ id }) => {
    // If active template is deleted, form should be cleared and template inactivated
    if (activeId === id) {
      forms.forEach(({ form }) => form.resetFields());
      afterApply();
      setActiveItem(undefined);
    }
  };

  return { activeItem, setActiveItem, afterSave, afterDelete };
}

function useLoadMissingDimensionalWeights() {
  const computeDimensionalWeight = useComputeDimensionalWeight();
  return useCallback(
    async values => {
      const packagePromises = values.packageList.packages.map(async pkg => {
        const shouldCompute =
          canComputeDimensionalWeight(pkg) &&
          !pkg.billedWeight &&
          !pkg.dimensionalWeight;
        const computed = shouldCompute
          ? await computeDimensionalWeight(
              pick(pkg, ['actualWeight', 'height', 'length', 'width'])
            )
          : {};
        return {
          ...pkg,
          ...computed,
        };
      });
      const packages = await Promise.all(packagePromises);

      return {
        ...values,
        packageList: { packages },
      };
    },
    [computeDimensionalWeight]
  );
}

export function useShipmentTemplatesControls({
  forms,
  afterApply,
  openDrawer,
}) {
  const loadMissingDimensionalWeights = useLoadMissingDimensionalWeights();

  const enrichTemplate = async data => {
    const values = shipmentTemplateGraphQLToForm(data);
    // Dimensional weights are needed in form preview right away, so we load them
    // at the template conversion time
    const withDimensionalWeights = await loadMissingDimensionalWeights(values);
    const withTemplateFlag = {
      ...withDimensionalWeights,
      packageList: {
        packages: withDimensionalWeights.packageList.packages.map(pkg => ({
          ...pkg,
          fromTemplate: true,
        })),
      },
    };

    return withTemplateFlag;
  };

  const applyItem = async data => {
    const enrichedData = await enrichTemplate(data);
    copyShipmentTemplateIntoForm(forms, enrichedData);
    afterApply(enrichedData);
    const validatePromises = forms.map(({ form }) => form.validateFields());
    try {
      await Promise.all(validatePromises);
    } catch (e) {
      // No action, validation is there just to show the information to the user
    }
  };

  const edit = async ({ id, name, data }) =>
    openDrawer({
      editing: { id, name, values: data && (await enrichTemplate(data)) },
    });

  const view = async ({ id, name, data }) =>
    openDrawer({
      viewing: { id, name, values: data && (await enrichTemplate(data)) },
    });

  const add = () => openDrawer({ adding: true });

  return { applyItem, edit, view, add };
}
