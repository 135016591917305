import classNames from 'classnames';
import { get } from 'lodash-es';
import { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { useNodeInnerText } from '../../common/utils/stringUtils';
import { FAIcon, FATypes } from '../../components/adapters/fontAwesomeAdapters';
import { FormItem } from '../../components/forms/FormItem';
import { FormItemInputText } from '../../components/forms/basicFormElements';
import {
  extractValueFromEvent,
  isFormValuePresent,
} from '../../components/forms/formHelpers';
import { PopupContext } from '../../components/nav/navElements';
import { InlineLink } from '../../components/typography';
import { cssVariables } from '../../styles/cssVariables';
import { msToNumber } from '../../utils/cssUtils';

function SearchSingleSelectOption({
  value,
  label,
  active,
  onChange,
  setPopupOpen,
  allowClear,
}) {
  const { ref, text } = useNodeInnerText();
  return (
    <div
      ref={ref}
      className={classNames('SearchSingleSelect--Item', {
        active,
      })}
      title={text}
      onClick={() => {
        if (active && allowClear) {
          onChange(undefined);
        } else {
          onChange(value);
        }
        setPopupOpen(false);
      }}
    >
      {label}
    </div>
  );
}

function SearchSingleSelectOptionGroupHeader({ label }) {
  const { ref, text } = useNodeInnerText();
  return (
    <div ref={ref} title={text} className="SearchSingleSelect--Group">
      {label}
    </div>
  );
}

function SearchSingleSelectOptionGroup({
  label: groupLabel,
  activeValue,
  onChange,
  setPopupOpen,
  allowClear,
  options,
}) {
  return (
    <>
      {groupLabel && <SearchSingleSelectOptionGroupHeader label={groupLabel} />}
      {options.map(({ label, value: val, options: childOptions, key }) =>
        childOptions ? (
          <SearchSingleSelectOptionGroup
            key={key}
            label={label}
            activeValue={activeValue}
            onChange={onChange}
            setPopupOpen={setPopupOpen}
            allowClear={allowClear}
            options={childOptions}
          />
        ) : (
          <SearchSingleSelectOption
            key={val}
            value={val}
            label={label}
            active={activeValue === val}
            onChange={onChange}
            setPopupOpen={setPopupOpen}
            allowClear={allowClear}
          />
        )
      )}
    </>
  );
}

function SearchSingleSelect({
  value,
  onChange,
  options,
  allowClear = true,
  className,
}) {
  const { setOpen: setPopupOpen } = useContext(PopupContext);
  return (
    <div className={classNames('SearchSingleSelect', className)}>
      {allowClear && value && (
        <div
          className="SearchSingleSelect--Item"
          onClick={() => {
            onChange(undefined);
            setPopupOpen(false);
          }}
        >
          <FormattedMessage id="buttons.reset" />
        </div>
      )}
      <SearchSingleSelectOptionGroup
        activeValue={value}
        onChange={onChange}
        setPopupOpen={setPopupOpen}
        allowClear={allowClear}
        options={options}
      />
    </div>
  );
}

export function FormItemSearchSingleSelect(props) {
  return <FormItem component={SearchSingleSelect} noStyle {...props} />;
}

function SearchTextFilterSubmenu({ value, onChange, options }) {
  const intl = useIntl();
  const [entering, setEntering] = useState();
  const { setOpen } = useContext(PopupContext);

  const close = () => {
    setOpen(false);
    setEntering();
  };

  return (
    <TransitionGroup className="SearchTextFilterSubmenu">
      {entering && (
        <CSSTransition
          key="entering"
          timeout={msToNumber(cssVariables.animationDurationMd)}
          classNames="from-left"
        >
          <div className="SearchTextFilterSubmenu-Entering">
            <div>
              <InlineLink
                textId="filters.labels.shipmentDetails.enter"
                iconBefore={
                  <FAIcon
                    icon="angle-left"
                    type={FATypes.SOLID}
                    className="icon-18"
                  />
                }
                onClick={close}
              />
            </div>
            <div>
              <FormItemInputText
                labelId={entering.labelId}
                formItemComponentProps={{
                  value: get(value, entering.name),
                  onChange: e => {
                    const val = extractValueFromEvent(e);
                    onChange(val ? { [entering.name]: val } : null);
                  },
                  onKeyDown: e => {
                    if (e.key === 'Enter') {
                      close();
                    }
                  },
                }}
              />
            </div>
          </div>
        </CSSTransition>
      )}
      <CSSTransition
        key="submenu"
        timeout={msToNumber(cssVariables.animationDurationMd)}
      >
        <div className="SearchSingleSelect">
          {options.map(item => {
            const { name, labelId } = item;
            const active = isFormValuePresent(get(value, name));
            const label = intl.formatMessage({ id: labelId });
            return (
              <div
                key={name}
                className={classNames('SearchSingleSelect--Item', {
                  active,
                })}
                title={label}
                onClick={() => setEntering(item)}
              >
                {label}
              </div>
            );
          })}
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
}

export function FormItemSearchTextFilterSubmenu(props) {
  return (
    <FormItem
      component={SearchTextFilterSubmenu}
      noStyle
      floatingLabel={false}
      {...props}
    />
  );
}
