import { useQuery } from '@apollo/client';

import { PACKAGE_TYPES_QUERY } from '../../../../app/graphql/adminQueries';
import AdminAccountTransferPage from './AdminAccountTransferPage';

const AdminAccountPackageTypes = ({ account, viewMode, visible }) => {
  const { data, loading } = useQuery(PACKAGE_TYPES_QUERY, {
    skip: !visible,
  });

  return (
    <AdminAccountTransferPage
      account={account}
      viewMode={viewMode}
      visible={visible}
      titleIcon="boxes"
      titleId="admin.accountManagement.accountDetail.packageTypes"
      itemName="packageTypesSettings"
      titlesPrefix="admin.accountManagement.accountDetail.packageTypes"
      data={data?.packageTypes}
      loading={loading}
    />
  );
};

export default AdminAccountPackageTypes;
