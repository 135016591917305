import { every, zip } from 'lodash-es';
import { useMemo, useRef, useState } from 'react';

import {
  useCachedProp,
  useMountAndUpdateEffect,
} from '../../common/utils/hookUtils';

/**
 * Ensures that all initializations have been done before rendering the content
 * @prop {Array<React.Component<extends { onInit: () => void }>>} initializerComponents
 * @prop {ReactNode} children Guarded content
 */
export default function StatePreparedGuard({
  initializerComponents,
  children,
}) {
  const [allInitialized, setAllInitialized] = useState(false);
  const initialized = useRef([]);
  const comps = useCachedProp(initializerComponents);
  useMountAndUpdateEffect(
    {
      onUpdate() {
        initialized.current = [];
        setAllInitialized(false);
      },
    },
    [comps]
  );

  const onInits = useMemo(
    () =>
      comps.map((Comp, i) => () => {
        const flags = initialized.current;
        flags[i] = true;
        if (flags.length === comps.length && every(flags, v => !!v)) {
          setAllInitialized(true);
        }
      }),
    [comps]
  );

  return (
    <>
      {zip(comps, onInits).map(([Comp, onInit]) => (
        <Comp key={Comp.displayName || Comp.name} onInit={onInit} />
      ))}
      {allInitialized && children}
    </>
  );
}
