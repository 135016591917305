import { identity } from 'lodash-es';

import { useResponsiveQueries } from '../responsive/responsiveQueries';
import { Scrollbars, VerticalTrackTypes } from './Scrollbars';

export function InnerContentScrollbars({
  mediaMapper = identity,
  children,
  onScroll,
  style,
  scrollbarsRef,
  ...rest
}) {
  const media = mediaMapper(useResponsiveQueries());
  const isSm = media.xs || media.sm;
  return (
    <Scrollbars
      spaceOutside
      isInFlexContainer
      onScroll={onScroll}
      style={style}
      vertical={
        isSm ? VerticalTrackTypes.ABOVE_CONTENT : VerticalTrackTypes.OUTSIDE
      }
      scrollbarsRef={scrollbarsRef}
      autoHide={isSm}
      {...rest}
    >
      {children}
    </Scrollbars>
  );
}
