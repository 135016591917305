import { Form, Input, InputNumber } from 'antd';
import classNames from 'classnames';
import { isNumber, isString } from 'lodash-es';
import { useCallback, useRef } from 'react';

import { numberOr } from '../../common/utils/numberUtils';
import { FormItem } from './FormItem';
import { isFormValuePresent } from './formHelpers';

function InputText({ className, ...rest }) {
  return (
    <Input
      className={classNames(
        'InputText',
        { 'has-prefix': !!rest.prefix },
        className
      )}
      {...rest}
    />
  );
}
export function FormItemInputText({
  onFocus,
  onBlur,
  onKeyDown,
  formItemComponentProps,
  ...rest
}) {
  return (
    <FormItem
      {...rest}
      formItemComponentProps={{
        onFocus,
        onBlur,
        onKeyDown,
        ...formItemComponentProps,
      }}
      component={InputText}
    />
  );
}

export function FormItemInputPassword(props) {
  return <FormItem {...props} component={Input.Password} />;
}

const InputHidden = props => <Input {...props} style={{ display: 'none' }} />;
export function FormItemInputHidden(props) {
  return (
    <Form.Item {...props}>
      <InputHidden />
    </Form.Item>
  );
}
/**
 * This serves as a registration for ant form. Since the real form item is in dialog and thus
 * not rendered at the start, it would cause form to ignore the value when setting it programatically
 */
export const FormItemFieldRegistration = props => (
  <FormItemInputHidden {...props} noStyle />
);

function InputNumberAdapter({ suffixIcon, ...rest }) {
  // InputNumber doesn't support `suffix` prop, so we have to apply this hack
  return (
    <>
      <InputNumber {...rest} />
      <div className="form-item-suffix">{suffixIcon}</div>
    </>
  );
}

function parseNumber(val) {
  if (isNumber(val)) {
    return val;
  }
  if (!val) {
    return undefined;
  }
  if (isString(val)) {
    return numberOr(parseFloat(val), undefined);
  }
  return null;
}

export function FormItemInputNumber({
  suffix,
  min,
  max,
  step,
  formItemComponentProps,
  ...rest
}) {
  // Numeric part of the text
  const lastText = useRef('');

  // value -> text
  const suffixFormatter = useCallback(
    val => {
      const formattedValue = isFormValuePresent(val)
        ? `${numberOr(parseFloat(val), '')}`
        : val;

      const needsInitialization = !isFormValuePresent(lastText.current);
      const wasUpdated =
        isFormValuePresent(lastText.current) &&
        parseFloat(lastText.current) !== val;

      if (needsInitialization || wasUpdated) {
        lastText.current = formattedValue;
      }

      // We are returning the text as it is to prevent losing '.' during typing
      return isFormValuePresent(lastText.current)
        ? `${lastText.current}${suffix}`
        : '';
    },
    [suffix]
  );
  // text -> value
  const suffixParser = useCallback(
    val => {
      lastText.current = val.replace(suffix, '').replace(/[^0-9.+-]/g, '');
      return isFormValuePresent(val)
        ? numberOr(parseFloat(lastText.current))
        : undefined;
    },
    [suffix]
  );

  return (
    <FormItem
      {...rest}
      component={InputNumberAdapter}
      parseValue={parseNumber}
      formItemComponentProps={{
        parser: suffix ? suffixParser : undefined,
        formatter: suffix ? suffixFormatter : undefined,
        min,
        max,
        step,
        ...formItemComponentProps,
      }}
    />
  );
}
