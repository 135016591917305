import sanitize from 'sanitize-html';

export function sanitizeHtml(rawHtml) {
  return sanitize(rawHtml, {
    allowedTags: [
      'p',
      'img',
      'a',
      'h1',
      'h2',
      'h3',
      'ol',
      'ul',
      'li',
      'strong',
      'b',
      'i',
      'u',
    ],
    transformTags: {
      a: sanitize.simpleTransform('a', { target: '__blank' }),
    },
    nonTextTags: ['style', 'script', 'textarea', 'option', 'noscript', 'time'],
  });
}
