import { get } from 'lodash-es';

import { ShipmentMode } from '../../app/enums/ShipmentMode';
import { useDynamicOriginalValueDependency } from '../../components/forms/dynamic/dynamicFormDependencies';

function extractShipmentMode(account) {
  return get(account, 'newOrder.mode');
}

export function isAviationMode(account) {
  return extractShipmentMode(account) === ShipmentMode.AERO;
}

export function isBiomedLikeMode(account) {
  const mode = extractShipmentMode(account);
  return [
    ShipmentMode.CGT,
    ShipmentMode.DTP,
    ShipmentMode.BOT,
    ShipmentMode.CTS,
    ShipmentMode.DTL,
  ].includes(mode);
}

export function isGenericMode(account) {
  return extractShipmentMode(account) === ShipmentMode.GENERIC;
}

function extractNewOrderFeatures(account) {
  return get(account, 'newOrder.features') || {};
}

export function useNewOrderFeatures() {
  const account = useDynamicOriginalValueDependency('account');
  return extractNewOrderFeatures(account);
}

export function useShipmentMode() {
  const account = useDynamicOriginalValueDependency('account');

  const isBiomedLike = isBiomedLikeMode(account);
  const isAviation = isAviationMode(account);
  const piecesType = isAviation && 'aviation';

  return {
    hasTemperature: isBiomedLike,
    hasPieces: isAviation,
    piecesType,
  };
}
