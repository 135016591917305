import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import pkgImg from '../../resources/img/package.gif';
import { HeaderLogo } from '../Logo';
import { ThreeDotsSpinner } from '../data/Spinner';
import { FlexCol } from '../layout/layoutElements';
import ModalOverlay from './ModalOverlay';

export default function ProcessingModal({ textId }) {
  return (
    <ModalOverlay
      component={FlexCol}
      className="ProcessingModal"
      align="center"
      justify="center"
    >
      <HeaderLogo className="always-full-image" />
      <img src={pkgImg} alt="" />
      <Typography.Title level={1}>
        <FormattedMessage id={textId} />
        <ThreeDotsSpinner />
      </Typography.Title>
    </ModalOverlay>
  );
}
