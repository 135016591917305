import classNames from 'classnames';

export function HeaderLogo({ size = 'md', className, children, ...rest }) {
  return (
    <div
      className={classNames('QuickOnlineLogo', `size-${size}`, className)}
      {...rest}
    >
      {children}
    </div>
  );
}

export function SmallLogo({ size = 'md', className, children, ...rest }) {
  return (
    <div
      className={classNames('QuickOnlineLogoQ', `size-${size}`, className)}
      {...rest}
    />
  );
}
