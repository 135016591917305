export function takeOneFromObservable(observable) {
  return new Promise((resolve, reject) => {
    const subscription = observable.subscribe({
      next: ({ data }) => {
        resolve({ data });
        subscription.unsubscribe();
      },
      error: err => {
        reject(err);
        subscription.unsubscribe();
      },
    });
  });
}
