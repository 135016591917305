import { Button } from 'antd';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { EMPTY_VALUE } from '../../../../common/utils/formatUtils';
import Whitespace from '../../../../components/Whitespace';
import { FormItemDropdownSelect } from '../../../../components/forms/selects';

const BusinessTypes = [
  { value: 'AERO' },
  { value: 'BOT' },
  { value: 'CGT' },
  { value: 'DTP' },
  { value: 'CTS' },
  { value: 'DTL' },
  { value: 'GENERIC', short: 'GEN' },
];

export const BUSINESS_TYPE_OPTIONS = BusinessTypes.map(({ value, short }) => ({
  value,
  label: short || value,
  short,
}));

export function AccountBusinessTypeField() {
  return (
    <div className="no-margin-form-items AdminAccountDetail__HeaderRight">
      <span className="text-uppercase">
        <FormattedMessage id="admin.accountManagement.accountDetail.businessType" />
        :<Whitespace />
      </span>
      <FormItemDropdownSelect
        name="profile"
        options={BUSINESS_TYPE_OPTIONS}
        renderLabel={(it, { disabled }) => (
          <Button
            className={classNames(
              'AdminConsole__ButtonDropdownTrigger',
              'AdminConsole__ButtonDropdownTrigger--Borderless',
              'AdminConsole__ButtonDropdownTrigger--Inline',
              { disabled }
            )}
          >
            <span>
              {it?.value ? (
                <>
                  {it.short || it.label}
                  <Whitespace />(
                  <FormattedMessage
                    id={`admin.accountManagement.accountDetail.businessType.${it.value}`}
                  />
                  )
                </>
              ) : (
                EMPTY_VALUE
              )}
            </span>
          </Button>
        )}
        formItemComponentProps={{
          overlayClassName: 'DropdownTheme--Dark DropdownSize--Large',
        }}
        floatingLabel={false}
      />
    </div>
  );
}
