import { gql } from '@apollo/client';

import { makeLocationFragment } from './commonFragments';

export const CURRENT_TIME_QUERY = gql`
  query CurrentTimeQuery($address: AddressInput!) {
    currentTime(address: $address)
  }
`;

export const ADDRESS_AUTOCOMPLETE_QUERY = gql`
  query AddressLookupQuery($input: AddressAutocompleteInput!) {
    addressAutocomplete(input: $input) {
      data {
        id
        text
      }
    }
  }
`;

export const ADDRESS_AUTOCOMPLETE_DETAIL_QUERY = gql`
  query AddressLookupDetailQuery($input: AddressAutocompleteDetailInput!) {
    addressAutocompleteDetail(input: $input) ${makeLocationFragment({
      fullState: true,
      hasSpecialInstructions: false,
    })}
  }
`;
