import classNames from 'classnames';
import {
  identity,
  isNumber,
  mapValues,
  range,
  reverse,
  round,
  values,
} from 'lodash-es';
import propTypes from 'prop-types';

import { cssVariables } from '../../styles/cssVariables';
import { pointsToCssPoints } from '../../utils/cssUtils';
import { getLineEnd } from '../../utils/geometryUtils';
import { degToRad, radToDeg } from '../../utils/svgUtils';
import { Svg } from './Svg';

function withAngleSteps({ minAngle, maxAngle, count }) {
  const step = (maxAngle - minAngle) / (count - 1);
  return range(minAngle, maxAngle + step / 2, step);
}

function computeAngles(angle) {
  // Degrees go counterclockwise, whereas the gauge goes clockwise
  const minAngle = (Math.PI + angle) / 2;
  const maxAngle = (Math.PI - angle) / 2;
  return { minAngle, maxAngle };
}

function getPoint(angle, { cx, cy, radius }) {
  return getLineEnd({ x: cx, y: cy }, { angle, length: radius });
}

/**
 * @returns {Array<{x, y}>} Array containing inner point and outer point
 */
function getLine(angle, { cx, cy, outerRadius, innerRadius }) {
  return [
    getLineEnd({ x: cx, y: cy }, { angle, length: innerRadius }),
    getLineEnd({ x: cx, y: cy }, { angle, length: outerRadius }),
  ];
}

function valueToAngle(value, { minValue, maxValue, angle }) {
  const { minAngle } = computeAngles(angle);
  return minAngle - (angle * (value - minValue)) / (maxValue - minValue);
}

function angleToValue(angle, { minValue, maxValue, angle: totalAngle }) {
  const { minAngle } = computeAngles(totalAngle);
  return minValue + (maxValue - minValue) * ((minAngle - angle) / totalAngle);
}

const DEFAULT_SIZE = 194;
const elementSizes = {
  innerAxisOuterRadius: 60,
  innerAxisTickLength: 5,
  outerAxisOuterRadius: 85,
  outerAxisTickLength: 18,
  outerAxisTickWidth: 2,
  valueTickLength: 30,
  valueTickWidth: 8.5,
  axisLabelsRadius: 52,
};
function getScaledElementSize(baseElementSize, size) {
  return round((baseElementSize / DEFAULT_SIZE) * size, 1);
}

function Tick({ angle, outerRadius, length, width, color, size, ...rest }) {
  const [{ x: x1, y: y1 }, { x: x2, y: y2 }] = getLine(angle, {
    cx: size / 2,
    cy: size / 2,
    outerRadius,
    innerRadius: outerRadius - length,
  });

  return (
    <line
      x1={x1}
      x2={x2}
      y1={y1}
      y2={y2}
      style={{ stroke: color, strokeWidth: width }}
      {...rest}
    />
  );
}

function AxisLabel({ angle, value, size, radius, transform }) {
  const { x, y } = getPoint(angle, { cx: size / 2, cy: size / 2, radius });
  return (
    <div className="Gauge-AxisLabel" style={{ top: y, left: x, transform }}>
      {value}
    </div>
  );
}

export const GaugeAxisAngle = {
  HALF_CIRCLE: 'HALF_CIRCLE',
  ALMOST_FULL_CIRCLE: 'ALMOST_FULL_CIRCLE',
};
const GAUGE_AXIS_ANGLE_MAPPING = {
  [GaugeAxisAngle.HALF_CIRCLE]: {
    angle: degToRad(180),
    innerTicksNumber: 39,
    outerTicksNumber: 39,
  },
  [GaugeAxisAngle.ALMOST_FULL_CIRCLE]: {
    angle: degToRad((180 * 27) / 19),
    innerTicksNumber: 55,
    outerTicksNumber: 50,
  },
};

export function StandardGaugeInnerAxis({ size, count, minAngle, maxAngle }) {
  return withAngleSteps({ minAngle, maxAngle, count }).map((angle, index) => {
    const isFirstOrLast = index === 0 || index === count - 1;
    const is45degMultiple = round((angle / (Math.PI / 4)) % 1, 2) === 0;
    const outerRadius = getScaledElementSize(
      elementSizes.innerAxisOuterRadius,
      size
    );
    const baseTickLength = getScaledElementSize(
      elementSizes.innerAxisTickLength,
      size
    );
    const tickLength =
      isFirstOrLast || is45degMultiple ? baseTickLength : baseTickLength / 2;
    return (
      <Tick
        key={angle}
        angle={angle}
        outerRadius={outerRadius}
        length={tickLength}
        width={1}
        color={cssVariables.colorGrey1_5}
        size={size}
      />
    );
  });
}

function getOuterAxisBaseOptions(size) {
  return {
    outerRadius: getScaledElementSize(elementSizes.outerAxisOuterRadius, size),
    tickLength: getScaledElementSize(elementSizes.outerAxisTickLength, size),
    baseTickWidth: getScaledElementSize(elementSizes.outerAxisTickWidth, size),
  };
}

function StandardGaugeOuterAxis({
  value,
  size,
  count,
  minAngle,
  maxAngle,
  minValue,
  maxValue,
  angle: totalAngle,
}) {
  return withAngleSteps({ minAngle, maxAngle, count }).map(angle => {
    const { outerRadius, tickLength, baseTickWidth } =
      getOuterAxisBaseOptions(size);
    const tickValue = angleToValue(angle, {
      minValue,
      maxValue,
      angle: totalAngle,
    });
    const isHighlighted = tickValue <= value;
    const tickWidth = isHighlighted ? baseTickWidth : baseTickWidth / 2;
    const tickColor = isHighlighted
      ? cssVariables.colorBlue
      : cssVariables.colorGrey8_1;
    return (
      <Tick
        key={angle}
        angle={angle}
        outerRadius={outerRadius}
        length={tickLength}
        width={tickWidth}
        color={tickColor}
        size={size}
      />
    );
  });
}

export function OptimalRangeGaugeOuterAxis({
  size,
  count,
  minAngle,
  maxAngle,
  minValue,
  maxValue,
  angle: totalAngle,
  range: rng,
}) {
  return withAngleSteps({ minAngle, maxAngle, count }).map(angle => {
    const { outerRadius, tickLength, baseTickWidth } =
      getOuterAxisBaseOptions(size);
    const tickWidth = baseTickWidth;
    const tickValue = angleToValue(angle, {
      minValue,
      maxValue,
      angle: totalAngle,
    });

    const isInRange = rng && tickValue >= rng[0] && tickValue <= rng[1];
    const tickColor =
      !rng || isInRange ? cssVariables.colorBlue : cssVariables.colorRed2;
    return (
      <Tick
        key={angle}
        angle={angle}
        outerRadius={outerRadius}
        length={tickLength}
        width={tickWidth}
        color={tickColor}
        size={size}
      />
    );
  });
}

const ROUNDED_TRAPEZOID_SVG = {
  // Generated via Inkscape's "Trace Bitmap" function
  path: 'M 2.6452827,81.543521 C 1.036773,80.072992 -0.02479005,77.964126 4.401422e-4,76.289379 0.07565905,71.297441 10.487363,0.98284899 11.255254,0.28085182 c 0.409608,-0.37446909 1.07988,-0.37446909 1.489492,0 C 13.512636,0.98284899 23.92432,71.297441 23.99956,76.289379 24.0248,77.964126 22.963227,80.072992 21.354717,81.543521 19.090065,83.613919 17.619713,84 12,84 6.3802866,84 4.9099348,83.613919 2.6452827,81.543521 Z',
  width: 24,
  height: 84,
};
export function RoundedTrapezoidGaugeValueTick({
  value,
  size,
  angle: totalAngle,
  minValue,
  maxValue,
  possiblePositionsNumber,
}) {
  if (!isNumber(value)) {
    return null;
  }

  let roundedValue = value;
  if (isNumber(possiblePositionsNumber)) {
    const stepSize = (maxValue - minValue) / (possiblePositionsNumber - 1);
    roundedValue = round((value - minValue) / stepSize) * stepSize + minValue;
  }

  const angle = valueToAngle(roundedValue, {
    minValue,
    maxValue,
    angle: totalAngle,
  });
  const length = getScaledElementSize(elementSizes.valueTickLength, size);
  const width = getScaledElementSize(elementSizes.valueTickWidth, size);

  const tip = getPoint(angle, {
    cx: size / 2,
    cy: size / 2,
    radius: size / 2,
  });

  const transformCommands = [
    // Move by half to the left so that tip was at [0, 0] coordinate
    ['translate', -ROUNDED_TRAPEZOID_SVG.width / 2],
    [
      'scale',
      width / ROUNDED_TRAPEZOID_SVG.width,
      length / ROUNDED_TRAPEZOID_SVG.height,
    ],
    ['rotate', 90 - radToDeg(angle)],
    ['translate', tip.x, tip.y],
  ];

  // svg applies transformations in reverse order
  const transform = reverse(transformCommands)
    .map(([cmd, ...args]) => `${cmd}(${args.join(',')})`)
    .join(' ');

  return (
    <path
      d={ROUNDED_TRAPEZOID_SVG.path}
      transform={transform}
      style={{ fill: cssVariables.colorRed6, strokeWidth: 0 }}
    />
  );
}

export function StandardGaugeLabels({
  value,
  size,
  formatValue,
  minAngle,
  maxAngle,
  minValue,
  maxValue,
}) {
  const midValue = round((minValue + maxValue) / 2);
  const axisLabelsRadius = getScaledElementSize(
    elementSizes.axisLabelsRadius,
    size
  );

  return (
    <>
      {isNumber(value) && (
        <div className="Gauge-ValueText" style={{ top: size / 2 }}>
          {formatValue(value)}
        </div>
      )}
      <AxisLabel
        value={minValue}
        angle={minAngle}
        size={size}
        radius={axisLabelsRadius}
        transform="translate(0, -50%)"
      />
      <AxisLabel
        value={midValue}
        angle={Math.PI / 2}
        size={size}
        radius={axisLabelsRadius}
        transform="translate(-50%, 0)"
      />
      <AxisLabel
        value={maxValue}
        angle={maxAngle}
        size={size}
        radius={axisLabelsRadius}
        transform="translate(-100%, -50%)"
      />
    </>
  );
}

function ShadowContainer({ size, children, minAngle, maxAngle }) {
  const clipInnerRadius = getScaledElementSize(
    elementSizes.outerAxisOuterRadius,
    size
  );
  // size is used just as a reserve
  const clipOuterRadius = clipInnerRadius + size * 2;

  const shadowSize = clipInnerRadius * 2;
  const shadowPadding = (size - shadowSize) / 2;

  const [minPointInner, minPointOuter] = getLine(minAngle, {
    cx: shadowSize / 2,
    cy: shadowSize / 2,
    outerRadius: clipOuterRadius,
    innerRadius: clipInnerRadius,
  });
  const [maxPointInner, maxPointOuter] = getLine(maxAngle, {
    cx: shadowSize / 2,
    cy: shadowSize / 2,
    outerRadius: clipOuterRadius,
    innerRadius: clipInnerRadius,
  });
  const midPointInner = getPoint((minAngle + maxAngle) / 2 + Math.PI, {
    cx: shadowSize / 2,
    cy: shadowSize / 2,
    radius: clipInnerRadius,
  });

  // Path didn't work in Chrome (not even example from
  // https://developer.mozilla.org/en-US/docs/Web/CSS/clip-path#Examples),
  // therefore an arc-like polygon is used
  const clipPolygon = pointsToCssPoints(
    [
      maxPointInner,
      maxPointOuter,
      { x: '150%', y: '-50%' },
      { x: '-50%', y: '-50%' },
      minPointOuter,
      minPointInner,
      midPointInner,
    ].map(point =>
      mapValues(point, val => (isNumber(val) ? `${round(val)}px` : val))
    )
  );

  return (
    <div
      className="Gauge-Shadow"
      style={{
        width: shadowSize,
        height: shadowSize,
        left: shadowPadding * 2,
        top: shadowPadding * 2,
        // These are mainly for the child svg to inherit - negative padding doesn't work
        marginLeft: -shadowPadding,
        marginTop: -shadowPadding,
        clipPath: `polygon(${clipPolygon})`,
      }}
    >
      {children}
    </div>
  );
}

function Gauge({
  value,
  size,
  angleType,
  innerAxisComponent: InnerAxis,
  innerAxisProps,
  outerAxisComponent: OuterAxis,
  outerAxisProps,
  valueTickComponent: ValueTick,
  labelsComponent: Labels,
  minValue,
  maxValue,
  formatValue,
  className,
  style,
}) {
  const { angle, innerTicksNumber, outerTicksNumber } =
    GAUGE_AXIS_ANGLE_MAPPING[angleType];

  const { minAngle, maxAngle } = computeAngles(angle);
  const yReserve = getScaledElementSize(elementSizes.valueTickWidth, size) / 2;
  const { y: bottommostY } = getPoint(minAngle, {
    cx: size / 2,
    cy: size / 2,
    radius: size / 2,
  });
  const width = size;
  const height = round(bottommostY + yReserve, 2);

  return (
    <div
      className={classNames('Gauge-Container', className)}
      style={{
        ...style,
        width,
        height,
        fontSize: size,
      }}
    >
      <ShadowContainer size={size} minAngle={minAngle} maxAngle={maxAngle}>
        <Svg
          viewBox={`0 0 ${width} ${height}`}
          style={{
            width,
            height,
            marginLeft: 'inherit',
            marginTop: 'inherit',
          }}
          className="Gauge-Svg"
        >
          <InnerAxis
            size={size}
            count={innerTicksNumber}
            minAngle={minAngle}
            maxAngle={maxAngle}
            {...innerAxisProps}
          />
          <OuterAxis
            value={value}
            size={size}
            count={outerTicksNumber}
            angle={angle}
            minAngle={minAngle}
            maxAngle={maxAngle}
            minValue={minValue}
            maxValue={maxValue}
            {...outerAxisProps}
          />
          <ValueTick
            value={value}
            size={size}
            angle={angle}
            minValue={minValue}
            maxValue={maxValue}
            possiblePositionsNumber={outerTicksNumber}
          />
        </Svg>
      </ShadowContainer>
      <Labels
        value={value}
        size={size}
        formatValue={formatValue}
        minAngle={minAngle}
        maxAngle={maxAngle}
        minValue={minValue}
        maxValue={maxValue}
      />
    </div>
  );
}

Gauge.defaultProps = {
  size: DEFAULT_SIZE,
  angleType: GaugeAxisAngle.ALMOST_FULL_CIRCLE,
  innerAxisComponent: StandardGaugeInnerAxis,
  outerAxisComponent: StandardGaugeOuterAxis,
  valueTickComponent: RoundedTrapezoidGaugeValueTick,
  labelsComponent: StandardGaugeLabels,
  minValue: 0,
  maxValue: 100,
  formatValue: identity,
};

Gauge.propTypes = {
  value: propTypes.number.isRequired,
  size: propTypes.number,
  angleType: propTypes.oneOf(values(GaugeAxisAngle)),
  innerAxisComponent: propTypes.elementType,
  outerAxisComponent: propTypes.elementType,
  valueTickComponent: propTypes.elementType,
  labelsComponent: propTypes.elementType,
  minValue: propTypes.number,
  maxValue: propTypes.number,
  formatValue: propTypes.func,
};

export default Gauge;
