import { useQuery } from '@apollo/client';
import { Col, Row } from 'antd';
import { useContext } from 'react';

import { USER_QUERY } from '../app/graphql/authQueries';
import routes from '../app/routes';
import { extractGraphqlEntity } from '../common/utils/graphqlUtils';
import ErrorBoundary from '../components/ErrorBoundary';
import { HeaderLogo } from '../components/Logo';
import { ThreeDotsSpinner } from '../components/data/Spinner';
import { BlockLink } from '../components/typography';
import { AccountDropdown } from './AccountDropdown';
import {
  FreeTextJobSearch,
  FreeTextJobSearchContext,
  FreeTextJobSearchIcon,
} from './FreeTextJobSearch';
import { MainTopMenu } from './menus';

const LogoCol = () => (
  <Col>
    <BlockLink to={routes.root}>
      <HeaderLogo size="lg" />
    </BlockLink>
  </Col>
);

export default function MainMenuHeader() {
  const { loading, data } = useQuery(USER_QUERY);
  const user = data && extractGraphqlEntity(data);

  const { searchActive } = useContext(FreeTextJobSearchContext);
  return (
    <ErrorBoundary>
      <div className="MainMenuHeader">
        <Row
          align="middle"
          justify="space-between"
          className="hide-md-and-bigger"
        >
          <LogoCol />
          <Col>
            <Row align="middle">
              <Col>
                <MainTopMenu />
              </Col>
              <Col>
                {loading ? (
                  <ThreeDotsSpinner />
                ) : (
                  <AccountDropdown user={user} />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          align="middle"
          justify="space-between"
          className="hide-sm-and-smaller"
        >
          <LogoCol />
          {searchActive ? (
            <Col className="InputSearchFlatCol">
              <FreeTextJobSearch />
            </Col>
          ) : (
            <Col>
              <Row align="middle">
                <Col>
                  <MainTopMenu />
                </Col>
                <Col>
                  <FreeTextJobSearchIcon />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </div>
    </ErrorBoundary>
  );
}
