import { Col, Row } from 'antd';
import { FormattedMessage } from 'react-intl';

import {
  FormItemFieldRegistration,
  FormItemInputText,
} from '../../../../components/forms/basicFormElements';
import { cssVariables } from '../../../../styles/cssVariables';
import { pxToNumber } from '../../../../utils/cssUtils';
import AdminConsoleSection, {
  AdminConsoleSectionContent,
  AdminConsoleSectionTitleHeader,
} from '../../components/AdminConsoleSection';

export default function AdminUserContactInformationFormSection({ viewMode }) {
  return (
    <AdminConsoleSection>
      <AdminConsoleSectionTitleHeader>
        <strong>
          <FormattedMessage id="admin.userManagement.forms.contactInformation" />
        </strong>
      </AdminConsoleSectionTitleHeader>
      <AdminConsoleSectionContent>
        <Row
          gutter={[
            pxToNumber(cssVariables.spaceNorm2),
            pxToNumber(cssVariables.spaceNorm1_5),
          ]}
        >
          <FormItemFieldRegistration name="name" />
          <FormItemFieldRegistration name="firstName" />
          <FormItemFieldRegistration name="lastName" />
          {viewMode ? (
            <Col span={12}>
              <FormItemInputText
                name="name"
                labelId="admin.userManagement.forms.name"
              />
            </Col>
          ) : (
            <>
              <Col span={12}>
                <FormItemInputText
                  name="firstName"
                  labelId="admin.userManagement.forms.firstName"
                  rules={[{ required: true }]}
                />
              </Col>
              <Col span={12}>
                <FormItemInputText
                  name="lastName"
                  labelId="admin.userManagement.forms.lastName"
                  rules={[{ required: true }]}
                />
              </Col>
            </>
          )}
          {viewMode && (
            <Col span={12}>
              <FormItemFieldRegistration name="title" />
              <div className="AdminConsoleSection__FieldPlaceholder" />
            </Col>
          )}
          <Col span={12}>
            <FormItemInputText
              name="email"
              labelId="admin.userManagement.forms.email"
              rules={[{ required: true, type: 'email' }]}
            />
          </Col>
          <Col span={12}>
            <FormItemInputText
              name="phone"
              labelId="admin.userManagement.forms.phone"
              rules={[{ required: true }]}
            />
          </Col>
          {!viewMode && (
            <>
              <Col span={12}>
                <FormItemInputText
                  name="role"
                  label={
                    <span className="AdminConsoleSection__Tba AdminConsoleSection__Tba--Right">
                      <FormattedMessage id="admin.userManagement.forms.role" />
                    </span>
                  }
                  disabled
                />
              </Col>
              <Col span={12}>
                <FormItemFieldRegistration name="title" />
                <div className="AdminConsoleSection__FieldPlaceholder" />
              </Col>
            </>
          )}
        </Row>
      </AdminConsoleSectionContent>
    </AdminConsoleSection>
  );
}
