import { groupBy, isEmpty, stubTrue } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import { formatAccount } from '../../common/utils/formatUtils';
import { useAccounts } from '../../hooks/data/auth';

export function useAccountOptions({ filter = stubTrue } = {}) {
  const { customerAccounts, error, loading } = useAccounts();
  const accountOptions = (customerAccounts || [])
    .filter(filter)
    .map(account => ({
      label: formatAccount(account),
      title: formatAccount(account),
      value: account,
    }));

  const { true: partnerOptions = [], false: nonPartnerOptions = [] } = groupBy(
    accountOptions,
    acc => acc.value.partner
  );

  const options = isEmpty(partnerOptions)
    ? nonPartnerOptions
    : [
        {
          key: 'partner',
          label: (
            <span className="AccountSelect__Group--Partner">
              <FormattedMessage id="accountSelect.options.partner" />
            </span>
          ),
          options: partnerOptions,
        },
        {
          key: 'non-partner',
          label: (
            <span className="AccountSelect__Group--NonPartner">
              <FormattedMessage id="accountSelect.options.nonPartner" />
            </span>
          ),
          options: nonPartnerOptions,
        },
      ];

  return { options, error, loading };
}
