import { Col, Row } from 'antd';
import classNames from 'classnames';

import { useDynamicFormSchemaFieldDefinition } from '../../../components/forms/dynamic/dynamicFormSchema';
import { NoLabelForm } from '../../../components/forms/forms';
import { useResponsiveQueries } from '../../../components/responsive/responsiveQueries';
import { cssVariables } from '../../../styles/cssVariables';
import { pxToNumber } from '../../../utils/cssUtils';
import { JobLocationSection } from '../newShipment/newShipmentElements';
import PackageListPackages from '../packageList/PackageListPackages';
import {
  DeliveryDateTimeField,
  PickupDateTimeField,
} from '../shipmentFormsCommon/shipmentDateFields';
import { ServiceTypeField } from '../shipmentFormsCommon/shipmentServiceInformationFields';

const DividerCol = ({ className }) => (
  <Col
    style={{
      alignSelf: 'stretch',
      margin: '0px -0.5px',
      paddingLeft: 0,
      paddingRight: 0,
    }}
    className="DividerCol"
  >
    <div className={classNames('ColVerticalDivider full', className)} />
  </Col>
);

export function ReleaseShipmentSimpleFieldsForm({
  shipmentForm,
  ...formProps
}) {
  const serviceTypeFieldDef = useDynamicFormSchemaFieldDefinition({
    schemaName: 'serviceInformation.serviceType',
  });

  const media = useResponsiveQueries();
  const isLg = media.lg || media.xl || media.xxl;
  const isSm = media.xs || media.sm;

  return (
    <NoLabelForm {...formProps}>
      <div className="spaces-vert-norm2 no-margin-form-items">
        <div className="ReleaseShipmentPage__Card">
          <Row
            align="top"
            gutter={pxToNumber(
              isLg ? cssVariables.spaceXl : cssVariables.spaceNorm2
            )}
          >
            <Col span={isSm ? 24 : 12}>
              <PickupDateTimeField
                labelId="book.newShipment.label.pickupDateTime.short"
                showRequiredInLabel
                getAddress={() => shipmentForm.origin.address}
              />
            </Col>
            {!isSm && <DividerCol />}
            <Col span={isSm ? 24 : 12}>
              <DeliveryDateTimeField
                labelId="book.newShipment.label.deliveryDateTime.short"
                showRequiredInLabel
              />
            </Col>
          </Row>
        </div>
        <div className="ReleaseShipmentPage__Card">
          <JobLocationSection
            titleId="book.newShipment.label.serviceType"
            requiredInTitle={serviceTypeFieldDef.validation.rules.required}
          >
            <ServiceTypeField />
          </JobLocationSection>
        </div>
      </div>
    </NoLabelForm>
  );
}

export function ReleaseShipmentPackageListForm({
  onEditedPackageChange,
  pkgControlsRef,
  ...rest
}) {
  return (
    <NoLabelForm
      {...rest}
      name="release-shipment-packages"
      className="NewShipmentPackageListForm margins-bottom-norm2"
    >
      <PackageListPackages
        onEditedPackageChange={onEditedPackageChange}
        pkgControlsRef={pkgControlsRef}
      />
    </NoLabelForm>
  );
}
