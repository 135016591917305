import { compact } from 'lodash-es';

import { capitalizeWords, uppercaseWords } from './stringUtils';

export const EMPTY_VALUE = '-';

export function formatString(value) {
  return value || EMPTY_VALUE;
}

function preformatLocation(location) {
  return {
    original: location,
    capitalized: capitalizeWords(location),
    uppercase: uppercaseWords(location),
  };
}

const formatPrefixedString = (value, prefix = '') =>
  value ? `${prefix}${value}` : '';

// Due to country and state being represented by free text it's possible we won't have codes in the middle of filling form
const formatCodeTextShort = (val, prefix = '') => {
  const {
    capitalized: { text },
    uppercase: { code },
  } = preformatLocation(val || {});

  return formatPrefixedString(code || text, prefix);
};

export function formatLocationShort(location) {
  if (!location) {
    return EMPTY_VALUE;
  }

  const {
    capitalized: {
      companyName,
      address: { city },
    },
    original: {
      address: { stateProvince, country },
    },
  } = preformatLocation(location);

  return formatString(
    compact([
      companyName,
      [
        city?.text || '-',
        formatCodeTextShort(stateProvince, ', '),
        formatCodeTextShort(country, ' | '),
      ].join(''),
    ]).join('\n')
  );
}

export function formatLocationMedium(location) {
  if (!location) {
    return EMPTY_VALUE;
  }

  const {
    capitalized: {
      companyName,
      address: { addressLine1, city },
    },
    original: {
      address: { stateProvince, country },
    },
  } = preformatLocation(location);

  return formatString(
    compact([
      companyName,
      addressLine1,
      [
        city?.text || '-',
        formatCodeTextShort(stateProvince, ', '),
        formatCodeTextShort(country, ' | '),
      ].join(''),
    ]).join('\n')
  );
}

export function formatLocationLong(location) {
  if (!location) {
    return EMPTY_VALUE;
  }

  const {
    original: {
      contact: { email, phone, phoneExtension },
      address: { postalCode, stateProvince, country },
    },
    capitalized: {
      companyName,
      contact: { name },
      address: { addressLine1, addressLine2, addressLine3, city },
    },
  } = preformatLocation(location);

  const showCityLine = city?.text || postalCode;

  return compact([
    name,
    [phone, formatPrefixedString(phoneExtension, ' x')].join(''),
    email,
    companyName,
    addressLine1,
    addressLine2,
    addressLine3,
    showCityLine &&
      [
        city?.text,
        formatCodeTextShort(stateProvince, ', '),
        formatPrefixedString(postalCode, ', '),
      ].join(''),
    formatCodeTextShort(country),
  ]).join('\n');
}

export function formatLocationAutocompleteLabel(location) {
  if (!location) {
    return EMPTY_VALUE;
  }

  const {
    capitalized: {
      companyName,
      address: { addressLine1, city },
    },
    original: {
      address: { stateProvince, postalCode, country },
    },
  } = preformatLocation(location);

  return formatString(
    compact([
      formatString(companyName),
      formatPrefixedString(
        compact([addressLine1, city?.text]).join(' '),
        ' | '
      ),
      formatPrefixedString(
        compact([
          formatCodeTextShort(stateProvince),
          formatCodeTextShort(country),
          postalCode,
        ]).join(' '),
        ', '
      ),
    ]).join('')
  );
}

export function formatUser(user) {
  return formatString(user && `${user.firstName} ${user.lastName}`);
}

export function formatAccount(account) {
  return formatString(account && `${account.number} | ${account.name}`);
}

export function formatCodeValueShort(codeValue) {
  return codeValue ? codeValue.code : '-';
}
export function formatCodeValue(codeValue) {
  return codeValue ? codeValue.text : '-';
}
