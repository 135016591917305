import { Button, Calendar, Col, Row, Typography } from 'antd';
import classNames from 'classnames';
import { find, toPairs } from 'lodash-es';
import { FormattedDateParts, FormattedMessage } from 'react-intl';

import { isDateRangeEqual } from '../../common/utils/dateRangeUtils';
import { forceDateIntoRange, now } from '../../common/utils/dateUtils';
import { FAIcon, FATypes } from '../../components/adapters/fontAwesomeAdapters';
import IconButton from '../../components/buttons/IconButton';
import { useFormContext } from '../../components/forms/forms';
import SegmentedControl from '../../components/nav/SegmentedControl';
import { Popup } from '../../components/nav/navElements';
import { InlineLink } from '../../components/typography';
import { useDateRangePresets } from '../../config/dateRangeConstants';
import { cssVariables } from '../../styles/cssVariables';
import { pxToNumber } from '../../utils/cssUtils';
import { DateFormatMedium, useDateFormats } from '../../utils/dateFormatting';

function DateRangePresetButton({ name, selected, value, labelId, setValue }) {
  return (
    <Button
      className="width-100p"
      type={selected ? 'primary' : 'default'}
      onClick={() => setValue(name, value)}
    >
      <FormattedMessage id={labelId} />
    </Button>
  );
}

function Presets() {
  const { values, formInstance, forceUpdate } = useFormContext();
  const { dateStart, dateEnd } = values;
  const { presetsOrdered, presetsByKey } = useDateRangePresets({
    extended: true,
  });
  const namedRange = find(toPairs(presetsByKey), ([key, val]) =>
    isDateRangeEqual(val, { from: dateStart, to: dateEnd })
  )?.[0];

  const setPresetValue = (name, val) => {
    formInstance.setFieldsValue({
      dateStart: val.from,
      dateEnd: val.to,
    });
    forceUpdate();
  };

  return (
    <div
      className="ReportsDialog__DateRange__Envelope spaces-vert-sm"
      onFocus={e => e.stopPropagation()}
    >
      {presetsOrdered.map(({ name, labelId, value: val }) => (
        <DateRangePresetButton
          key={name}
          name={name}
          labelId={labelId}
          value={val}
          setValue={setPresetValue}
          selected={namedRange === name}
        />
      ))}
    </div>
  );
}

function CustomCalendar({ name }) {
  const { values, formInstance, forceUpdate } = useFormContext();
  const value = values[name];
  const validRange = [
    now().startOf('year').subtract(2, 'years'),
    now().endOf('day'),
  ];

  return (
    <Calendar
      className="ReportsDialog__DateRange__Calendar"
      fullscreen={false}
      value={value}
      onChange={val => {
        formInstance.setFieldsValue({
          [name]: val,
        });
        forceUpdate();
      }}
      validRange={validRange}
      headerRender={({ value: val, onChange }) => (
        <div>
          <SegmentedControl
            value={val?.year()}
            onChange={year =>
              onChange(
                forceDateIntoRange((val?.clone() ?? now()).year(year), {
                  min: validRange[0],
                  max: validRange[1],
                })
              )
            }
            options={[-2, -1, 0]
              .map(i => now().add(i, 'years').year())
              .map(year => ({ label: year, value: year }))}
          />
          <div className="ReportsDialog__DateRange__Calendar__MonthSelector">
            <IconButton
              icon="angle-left"
              className="icon-18"
              onClick={() =>
                onChange(
                  forceDateIntoRange(
                    (val?.clone() ?? now()).subtract(1, 'month'),
                    { min: validRange[0], max: validRange[1] }
                  )
                )
              }
              disabled={val && !validRange[0].isBefore(val, 'month')}
            />
            <span>
              <FormattedDateParts month="long" value={val}>
                {parts => parts[0].value}
              </FormattedDateParts>
            </span>
            <IconButton
              icon="angle-right"
              className="icon-18"
              onClick={() =>
                onChange(
                  forceDateIntoRange((val?.clone() ?? now()).add(1, 'month'), {
                    min: validRange[0],
                    max: validRange[1],
                  })
                )
              }
              disabled={!val || !validRange[1].isAfter(val, 'month')}
            />
          </div>
        </div>
      )}
      dateCellRender={date => date.date()}
    />
  );
}

function DatePicker({ fieldName }) {
  const { values } = useFormContext();
  const { numeric } = useDateFormats();
  const value = values[fieldName];

  return (
    <div className="ReportsDialog__DateRange__CalendarPopup">
      <Popup
        label={
          <div
            className={classNames(
              'InputLike ReportsDialog__DateRange__InputLike width-100p',
              { 'has-value': value }
            )}
          >
            <FAIcon
              icon="calendar"
              className="icon-24"
              type={FATypes.REGULAR}
            />
            <span>{value ? <DateFormatMedium value={value} /> : numeric}</span>
          </div>
        }
        noIcon
        getPopupContainer={cnt => cnt.nextSibling}
        trigger="click"
      >
        <CustomCalendar name={fieldName} />
      </Popup>
      <div className="ReportsDialog__DateRange__CalendarContainer" />
    </div>
  );
}

export function ReportsDateRange() {
  const { formInstance, forceUpdate } = useFormContext();

  return (
    <div className="ReportsDialog__Section ReportsDialog__Content">
      <div className="ReportsDialog__Section__Title no-margin-form-items">
        <Typography.Title level={3}>
          3. <FormattedMessage id="reports.dateRange.title" />
        </Typography.Title>
        <InlineLink
          textId="buttons.clear"
          onClick={() => {
            formInstance.setFieldsValue({ dateStart: null, dateEnd: null });
            forceUpdate();
          }}
        />
      </div>
      <div className="ReportsDialog__SelectionSection__Title">
        <Row className="Flex1" gutter={pxToNumber(cssVariables.spaceNorm2)}>
          <Col span={12} className="FlexCol">
            <FormattedMessage id="reports.dateRange.start" />
          </Col>
          <Col span={12} className="FlexCol">
            <FormattedMessage id="reports.dateRange.end" />
          </Col>
        </Row>
      </div>
      <div className="ReportsDialog__SelectionSection__Body">
        <Row gutter={pxToNumber(cssVariables.spaceNorm2)}>
          <Col span={12} className="FlexCol">
            <DatePicker fieldName="dateStart" />
          </Col>
          <Col span={12} className="FlexCol">
            <DatePicker fieldName="dateEnd" />
          </Col>
        </Row>
        <Presets />
      </div>
    </div>
  );
}
