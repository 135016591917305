export const formFieldFragment = `{
  validation {
    rules {
      required
      email
    }
    async {
      dependsOn
    }
  }
  option {
    staticOptions {
      values {
        code
        text
      }
    }
    dependsOn
  }
  autofill {
    listenTo
    dependsOn
  }
}`;

export const codeValueFragment = `{
  code
  text
}`;

export const makeLocationFragment = ({
  fullState,
  hasSpecialInstructions = true,
  otherFields = '',
} = {}) => `{
  companyName
  contact {
    name
    email
    phone
    phoneExtension
  }
  address {
    addressLine1
    addressLine2
    addressLine3
    city ${codeValueFragment}
    stateProvince ${fullState ? codeValueFragment : '{ code }'}
    postalCode
    country ${codeValueFragment}
  }
  ${hasSpecialInstructions ? 'specialInstructions' : ''}
  ${otherFields}
}`;

export const makeLocationFieldsFragment = ({ otherFields = '' } = {}) => `{
  companyName ${formFieldFragment}
  contact {
    email ${formFieldFragment}
    name ${formFieldFragment}
    phone ${formFieldFragment}
    phoneExtension ${formFieldFragment}
  }
  address {
    addressLine1 ${formFieldFragment}
    addressLine2 ${formFieldFragment}
    addressLine3 ${formFieldFragment}
    city ${formFieldFragment}
    stateProvince ${formFieldFragment}
    postalCode ${formFieldFragment}
    country ${formFieldFragment}
  }
  specialInstructions ${formFieldFragment}
  ${otherFields}
}`;
