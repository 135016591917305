import { LoginControlledChangelogDialog } from '../../dialogs/ChangelogDialog';
import {
  useAutoLogoutWhenLoggedOutRemotely,
  useRedirectWhenLoggedOutLocally,
} from '../../hooks/data/auth';

function LoggedInRemotelyGuard({ children }) {
  const loggedIn = useAutoLogoutWhenLoggedOutRemotely();

  if (!loggedIn) {
    // Hook should handle any actions
    return null;
  }

  return children;
}

export default function LoggedInGuard({ children }) {
  const loggedIn = useRedirectWhenLoggedOutLocally();

  if (!loggedIn) {
    // Hook should handle any actions
    return null;
  }

  // It's important this guard is only mounted when logged in so that no pointless
  // GraphQL queries with stale auth state were made
  return (
    <LoggedInRemotelyGuard>
      <LoginControlledChangelogDialog />
      {children}
    </LoggedInRemotelyGuard>
  );
}
