import { Button } from 'antd';
import classNames from 'classnames';

import { ThreeDotsSpinner } from '../data/Spinner';

export function DotButton({ active, className, ...rest }) {
  return (
    <div {...rest} className={classNames('DotButton', { active }, className)} />
  );
}

export function CustomLoaderButton({ loading, children, ...rest }) {
  return (
    <Button loading={loading} {...rest}>
      {children}
      {loading && <ThreeDotsSpinner />}
    </Button>
  );
}
