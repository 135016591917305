import { Col, Form, Row, Tag, Typography } from 'antd';
import classNames from 'classnames';
import { isEmpty, some } from 'lodash-es';
import { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { normalizeString } from '../../common/utils/stringUtils';
import IconButton from '../../components/buttons/IconButton';
import { renderDataStateIndicator } from '../../components/data/dataStateHandlers';
import { extractValueFromEvent } from '../../components/forms/formHelpers';
import { useFormContext } from '../../components/forms/forms';
import { FormItemSearchFlat } from '../../components/forms/searchFormElements';
import { InnerContentScrollbars } from '../../components/layout/InnerContentScrollbars';
import { InlineLink } from '../../components/typography';
import { cssVariables } from '../../styles/cssVariables';
import { pxToNumber } from '../../utils/cssUtils';

function Search({ value, onChange, setClosed, placeholderId }) {
  return (
    <Form component={false}>
      <FormItemSearchFlat
        name="accountSearch"
        onCancel={() => {
          onChange('');
          setClosed();
        }}
        formItemComponentProps={{
          autoFocus: true,
          value,
          onChange: e => onChange(extractValueFromEvent(e)),
          prefixIconSize: null,
        }}
        placeholderId={placeholderId}
      />
    </Form>
  );
}

function Item({ columns, item, index, getKey, fieldName }) {
  const { values, formInstance, forceUpdate } = useFormContext();
  const active = some(values[fieldName], val => getKey(val) === getKey(item));

  return (
    <div
      className={classNames(
        'ReportsDialog__SelectionSection__Row',
        'Clickable',
        `ReportsDialog__SelectionSection__Row--${
          index % 2 === 0 ? 'Even' : 'Odd'
        }`
      )}
      onClick={() => {
        const key = getKey(item);
        const oldVal = formInstance.getFieldValue(fieldName);
        formInstance.setFieldsValue({
          [fieldName]: active
            ? oldVal.filter(val => getKey(val) !== key)
            : [...oldVal, item],
        });
        forceUpdate();
      }}
    >
      {active && (
        <div className="ReportsDialog__SelectionSection__Row--Active" />
      )}
      <Row gutter={pxToNumber(cssVariables.spaceNorm)}>
        {columns.map(col => (
          <Col key={col.key} span={col.span}>
            {col.render(item)}
          </Col>
        ))}
      </Row>
    </div>
  );
}

const DEFAULT_SPLIT_INTO_GROUPS = items => [{ key: 'default', items }];

export function ReportsSelectionSection({
  index: sectionIndex,
  titleId,
  headerId,
  subtitleId,
  subtitleRightContent,
  searchPlaceholderId,
  items,
  loading,
  error,
  getSearchSourcesFromItem,
  splitIntoGroups = DEFAULT_SPLIT_INTO_GROUPS,
  fieldName,
  getKey,
  getLabel,
  columns,
}) {
  const [searchIsOpen, setSearchOpen] = useState();
  const [searchText, setSearchText] = useState('');
  const { values, formInstance, forceUpdate } = useFormContext();
  const selected = values[fieldName];

  const indicator = renderDataStateIndicator({ error, loading });
  const filteredItems = items?.filter(item =>
    some(
      getSearchSourcesFromItem(item),
      fieldValue =>
        normalizeString(fieldValue).indexOf(normalizeString(searchText)) > -1
    )
  );

  const groups = splitIntoGroups(filteredItems);

  return (
    <div className="ReportsDialog__Section ReportsDialog__Content">
      <div className="ReportsDialog__Section__Title no-margin-form-items">
        <Typography.Title level={3}>
          {sectionIndex}. <FormattedMessage id={titleId} />
        </Typography.Title>
        <Row align="middle" gutter={pxToNumber(cssVariables.spaceSm)}>
          <Col>
            {searchIsOpen ? (
              <Search
                value={searchText}
                onChange={setSearchText}
                setClosed={() => setSearchOpen(false)}
                placeholderId={searchPlaceholderId}
              />
            ) : (
              <IconButton
                icon="search"
                className="icon-18"
                onClick={() => setSearchOpen(true)}
              />
            )}
          </Col>
          <Col>
            <InlineLink
              textId="buttons.clear"
              onClick={() => {
                formInstance.setFieldsValue({ [fieldName]: [] });
                forceUpdate();
              }}
            />
          </Col>
        </Row>
      </div>
      <div className="ReportsDialog__SelectionSection">
        <div className="ReportsDialog__SelectionSection__Title">
          <FormattedMessage id={headerId} />
        </div>
        <div className="ReportsDialog__SelectionSection__Subtitle">
          <div className="ReportsDialog__SelectionSection__Subtitle__Left">
            {isEmpty(selected) ? (
              <FormattedMessage id={subtitleId} />
            ) : (
              <>
                <span className="label">
                  <FormattedMessage id="reports.selection.selected" />
                </span>
                {selected.map(val => (
                  <Tag
                    className="ReportsDialog__SelectionTag"
                    key={getKey(val)}
                  >
                    {getLabel(val)}
                  </Tag>
                ))}
              </>
            )}
          </div>
          <div className="no-margin-form-items">{subtitleRightContent}</div>
        </div>
        <div className="ReportsDialog__SelectionSection__Header">
          <Row gutter={pxToNumber(cssVariables.spaceNorm)}>
            {columns.map(col => (
              <Col key={col.key} span={col.span}>
                <FormattedMessage id={col.labelId} />
              </Col>
            ))}
          </Row>
        </div>
        <InnerContentScrollbars>
          <div className="ReportsDialog__SelectionSection__Body">
            {indicator ||
              groups?.map(group => (
                <Fragment key={group.key}>
                  {group.labelId ? (
                    <div
                      className={classNames(
                        'ReportsDialog__SelectionSection__GroupLabel',
                        group.classNames
                      )}
                    >
                      <FormattedMessage id={group.labelId} />
                    </div>
                  ) : (
                    <div />
                  )}
                  {group.items.map((item, index) => (
                    <Item
                      columns={columns}
                      item={item}
                      index={index}
                      getKey={getKey}
                      fieldName={fieldName}
                    />
                  ))}
                </Fragment>
              ))}
          </div>
        </InnerContentScrollbars>
      </div>
    </div>
  );
}
