import { useQuery } from '@apollo/client';
import { Alert, Button, Col, Form, Row, Typography } from 'antd';
import { isEmpty, merge, omit, pick } from 'lodash-es';
import { useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect, Route, generatePath, useHistory } from 'react-router';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { shipmentConversions } from '../app/data/shipmentConversions';
import { ShipmentStage } from '../app/enums/ShipmentStage';
import { JOB_QUERY } from '../app/graphql/jobQueries';
import routes from '../app/routes';
import { extractGraphqlEntity } from '../common/utils/graphqlUtils';
import { useMountEffect } from '../common/utils/hookUtils';
import { FAIcon, FATypes } from '../components/adapters/fontAwesomeAdapters';
import { AsyncCallbackButton } from '../components/data/asyncActionElements';
import { renderDataStateIndicator } from '../components/data/dataStateHandlers';
import ModalOverlay from '../components/dialogs/ModalOverlay';
import { OverrideSettingsContextProvider } from '../components/domainSpecific/settingsElements';
import { DynamicFormDependenciesProvider } from '../components/forms/dynamic/dynamicFormDependencies';
import { ResponsiveOverrideSubtree } from '../components/responsive/ResponsiveOverrideSubtree';
import {
  ReleaseSavedShipmentSubmitDialog,
  useReleaseSavedShipmentSubmit,
} from '../dialogs/shipments/savedShipmentDialogs';
import NewShipmentFormSchemaProvider from '../forms/shipments/newShipment/NewShipmentFormSchemaProvider';
import { SavedShipmentProvider } from '../forms/shipments/newShipment/SavedShipmentProvider';
import useNewShipmentAsyncConfig from '../forms/shipments/newShipment/useNewShipmentAsyncConfig';
import { mapFormValuesToDependencies } from '../forms/shipments/newShipment/useNewShipmentFormState';
import {
  ReleaseShipmentPackageListForm,
  ReleaseShipmentSimpleFieldsForm,
} from '../forms/shipments/savedShipment/releaseShipmentForms';
import { getValidPackages } from '../forms/shipments/shipmentCommon';
import { useAccount } from '../hooks/data/auth';
import { cssVariables } from '../styles/cssVariables';
import ModalPage from '../templates/ModalPage';
import { msToNumber, pxToNumber } from '../utils/cssUtils';
import ShipmentOrderPreviewCard from '../widgets/shipments/ShipmentOrderPreviewCard';
import EditShipmentPage from './EditShipmentPage';
import CopiableJobNumber from './shipmentDetail/CopiableJobNumber';

const MEDIA_MAPPER = media => ({
  xs: media.xs,
  sm: media.sm,
  md: media.md || media.lg,
  lg: media.xl,
  xl: media.xxl,
  xxl: false,
});

const ORIGIN_ENTERED_FIELDS = [
  'pickupDateTime',
  'pickupDateTime-specialValue',
  'pickupDateTime-specialValueData',
];
const DESTINATION_ENTERED_FIELDS = [
  'deliveryDateTime',
  'deliveryDateTime-specialValue',
  'deliveryDateTime-specialValueData',
];
const SERVICE_INFORMATION_ENTERED_FIELDS = ['serviceType'];

function ReleaseShipmentContent({ shipmentForm, values, setValues }) {
  const { push } = useHistory();
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const form1Ref = useRef();
  const form2Ref = useRef();
  const { account: detailAccount } = shipmentForm;
  // Account in the detial doesn't contain all information, so we will enrich it
  const account = useAccount(detailAccount.number) || detailAccount;

  const refresh = useCallback(() => {
    const f1 = form1.getFieldsValue();
    const result = mapFormValuesToDependencies({
      account,
      origin: {
        ...omit(shipmentForm.origin, ORIGIN_ENTERED_FIELDS),
        ...pick(f1, ORIGIN_ENTERED_FIELDS),
      },
      destination: {
        ...omit(shipmentForm.destination, DESTINATION_ENTERED_FIELDS),
        ...pick(f1, DESTINATION_ENTERED_FIELDS),
      },
      serviceInformation: {
        ...pick(f1, SERVICE_INFORMATION_ENTERED_FIELDS),
        ...omit(
          shipmentForm.serviceInformation,
          SERVICE_INFORMATION_ENTERED_FIELDS
        ),
      },
      packageList: form2.getFieldsValue(),
      unitSystem: shipmentForm.unitSystem,
    });
    setValues(result);
    return result;
  }, [account, form1, form2, setValues, shipmentForm]);
  const onEditedPackageChange = useCallback(
    (changed, all) => {
      setValues(old => ({ ...old, packages: all }));
    },
    [setValues]
  );
  const pkgControlsRef = useRef();
  useMountEffect(() => {
    form1.setFieldsValue({
      pickupDateTime: shipmentForm.origin.pickupDateTime,
      deliveryDateTime: shipmentForm.destination.deliveryDateTime,
      'deliveryDateTime-specialValue':
        shipmentForm.destination['deliveryDateTime-specialValue'],
      serviceType: shipmentForm.serviceInformation.serviceType,
    });
    form2.setFieldsValue(shipmentForm.packagesInformation);
    refresh();
    form1Ref.current.forceUpdate();
    form2Ref.current.forceUpdate();

    // Auto-create an empty package when there isn't any
    if (isEmpty(shipmentForm.packagesInformation.packages)) {
      requestAnimationFrame(() => pkgControlsRef.current.addAndStartEdit({}));
    }
  });

  const { submit, dialogProps } = useReleaseSavedShipmentSubmit({
    jobNumber: shipmentForm.jobNumber,
  });

  const { isQuoteEnabled } = useNewShipmentAsyncConfig({
    account,
  });

  const validateAndSubmit = async () => {
    await submit(() => merge({}, shipmentForm, refresh()), {
      isQuoteEnabled,
      validateFields: async () => {
        try {
          await Promise.all([
            form1Ref.current.customSubmit(),
            form2Ref.current.customSubmit(),
          ]);
          refresh();
        } catch (e) {
          return false;
        }
        return true;
      },
    });
  };

  const isPackageEdited = !!values.packages;
  const isPackageListEmpty = isEmpty(
    getValidPackages(values.packageList?.packages)
  );
  const submitDisabled = isPackageEdited || isPackageListEmpty;

  const [editShipmentInitialValues, setEditShipmentInitialValues] = useState();

  return (
    <ResponsiveOverrideSubtree mapper={MEDIA_MAPPER}>
      <OverrideSettingsContextProvider unitSystem={shipmentForm.unitSystem}>
        <Row
          align="top"
          justify="space-between"
          className="ReleaseShipmentPage__TopRow margin-bottom-norm1_5"
        >
          <Col>
            <Typography.Title level={2}>
              <FormattedMessage id="releaseShipment.title" />
            </Typography.Title>
            <CopiableJobNumber jobNumber={shipmentForm.jobNumber} />
          </Col>
          <Col className="ReleaseShipmentPage__TopButtons">
            <Button
              size="large"
              onClick={() => {
                setEditShipmentInitialValues(refresh());
                push(
                  generatePath(routes.editShipment, {
                    jobNumber: shipmentForm.jobNumber,
                  })
                );
              }}
            >
              <FormattedMessage id="buttons.edit" />
            </Button>
            <AsyncCallbackButton
              type="primary"
              size="large"
              disabled={submitDisabled}
              callback={validateAndSubmit}
            >
              <span>
                <FormattedMessage id="buttons.continue" />
              </span>
              <FAIcon
                icon="arrow-alt-circle-right"
                className="icon-22"
                type={FATypes.REGULAR}
              />
            </AsyncCallbackButton>
            <Button size="large" onClick={() => push(routes.monitor)}>
              <FormattedMessage id="buttons.cancel" />
            </Button>
          </Col>
        </Row>
        <Row
          className="flex-nowrap"
          gutter={pxToNumber(cssVariables.spaceNorm2)}
        >
          <Col className="Flex1 spaces-vert-norm2">
            <ReleaseShipmentSimpleFieldsForm
              form={form1}
              formRef={form1Ref}
              onValuesChange={refresh}
              shipmentForm={shipmentForm}
            />
            <ReleaseShipmentPackageListForm
              form={form2}
              formRef={form2Ref}
              onValuesChange={refresh}
              onEditedPackageChange={onEditedPackageChange}
              pkgControlsRef={pkgControlsRef}
            />
            <ReleaseSavedShipmentSubmitDialog
              setEditShipmentInitialValues={setEditShipmentInitialValues}
              {...dialogProps}
            />
          </Col>
          <Col className="hide-md-and-smaller">
            <ShipmentOrderPreviewCard
              key={0}
              account={account}
              values={values}
            />
          </Col>
        </Row>
      </OverrideSettingsContextProvider>
      <Route path={routes.editShipment}>
        {routeProps => (
          <TransitionGroup>
            {routeProps.match && (
              <CSSTransition
                key="edit"
                timeout={msToNumber(cssVariables.animationDurationMd)}
                classNames="from-left"
              >
                {editShipmentInitialValues ? (
                  <EditShipmentPage
                    {...routeProps}
                    shipmentForm={editShipmentInitialValues}
                    levelBoost={1}
                  />
                ) : (
                  /* Direct URL access to EditShipmentPage is not allowed, we will redirect to ReleaseShipmentPage */
                  <Redirect
                    to={generatePath(routes.releaseShipment, {
                      jobNumber: routeProps.match.params.jobNumber,
                    })}
                  />
                )}
              </CSSTransition>
            )}
          </TransitionGroup>
        )}
      </Route>
    </ResponsiveOverrideSubtree>
  );
}

function ReleaseShipmentContentSwitch({ shipment }) {
  const [values, setValues] = useState({});
  const { shipmentForm, shipmentDetail } = useMemo(
    () => ({
      shipmentForm: shipmentConversions.graphQLToForm(shipment),
      shipmentDetail: shipmentConversions.graphqlToDetail(shipment),
    }),
    [shipment]
  );
  const { account } = shipmentDetail.metadata;

  if (shipmentDetail.stage !== ShipmentStage.SAVED) {
    return (
      <Alert type="danger">
        <FormattedMessage id="releaseShipment.error.notSaved" />
      </Alert>
    );
  }

  return (
    <NewShipmentFormSchemaProvider account={account}>
      <DynamicFormDependenciesProvider values={values}>
        <SavedShipmentProvider enabled={false}>
          <ReleaseShipmentContent
            shipmentForm={shipmentForm}
            values={values}
            setValues={setValues}
          />
        </SavedShipmentProvider>
      </DynamicFormDependenciesProvider>
    </NewShipmentFormSchemaProvider>
  );
}

export default function ReleaseShipmentPage({ match: { params } }) {
  const { jobNumber } = params;
  const { data, loading, error } = useQuery(JOB_QUERY, {
    variables: { jobNumber },
  });
  const indicator = renderDataStateIndicator({ data, loading, error });
  const shipment = data && extractGraphqlEntity(data);

  return (
    <ModalOverlay>
      <ModalPage
        className="ReleaseShipmentPage height-extending-error"
        wrapInScrollbar
      >
        {indicator || <ReleaseShipmentContentSwitch shipment={shipment} />}
      </ModalPage>
    </ModalOverlay>
  );
}
