import { Dropdown, Menu } from 'antd';
import classNames from 'classnames';
import { get } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  isMenuItemActive,
  renderMenuDefinition,
  useActiveRoute,
} from '../../utils/routeUtils';
import { usePageGuard } from '../guards/PageGuard';

function TopMenuItem({ active, disabled, className, children, ...rest }) {
  return (
    <div className={classNames('TopMenu-MenuItem', className)} {...rest}>
      {children}
    </div>
  );
}
TopMenuItem.innerClassName = 'TopMenu-MenuItemInner';

function MenuItem({
  component: C,
  className,
  labelId,
  to,
  children,
  disabled: disabledOuter,
  active: activeOuter,
  activeRoute,
  guard,
  ...rest
}) {
  const allowed = usePageGuard(guard);
  const disabled = disabledOuter || !allowed;
  const effectiveTo = disabled ? undefined : to;
  const Wrapper = effectiveTo ? Link : 'div';
  const active = activeOuter || (to && isMenuItemActive(activeRoute, { to }));

  return (
    <C
      key={labelId}
      className={classNames({ active, disabled }, className)}
      active={active}
      disabled={disabled}
      {...rest}
    >
      <Wrapper className={C.innerClassName} to={effectiveTo}>
        <FormattedMessage id={labelId} />
      </Wrapper>
    </C>
  );
}

function Submenu({ labelId, active, children }) {
  return (
    <Dropdown
      key={labelId}
      overlayClassName="TopMenu-Submenu"
      overlay={<Menu>{children}</Menu>}
      placement="bottomRight"
    >
      <MenuItem
        component={TopMenuItem}
        labelId={labelId}
        active={active}
        className="has-arrow"
      />
    </Dropdown>
  );
}

function renderSubmenu({ labelId, children }, recurse, _data, { activeRoute }) {
  return (
    <Submenu
      key={labelId}
      labelId={labelId}
      active={isMenuItemActive(activeRoute, { children })}
    >
      {recurse(children)}
    </Submenu>
  );
}

function renderTopMenuItem({ labelId, to, guard }, { activeRoute }) {
  return (
    <MenuItem
      component={TopMenuItem}
      key={labelId}
      to={to}
      labelId={labelId}
      disabled={!to}
      activeRoute={activeRoute}
      guard={guard}
    />
  );
}

function renderSubMenuItem({ labelId, to, guard }, { activeRoute }) {
  return (
    <MenuItem
      component={Menu.Item}
      key={labelId}
      to={to}
      labelId={labelId}
      disabled={!to}
      activeRoute={activeRoute}
      guard={guard}
    />
  );
}

function renderMenuItem(def, props, { level }) {
  const renderer = level === 0 ? renderTopMenuItem : renderSubMenuItem;
  return renderer(def, props);
}

export default function TopMenu({ definitions }) {
  const activeRoute = get(useActiveRoute(definitions), 'to');
  const props = { activeRoute };

  return (
    <div className="TopMenu">
      {renderMenuDefinition(definitions, {
        renderMenuItem,
        renderSubmenu,
        props,
      })}
    </div>
  );
}
