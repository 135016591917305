import { Col, Row, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import { FormItemsOverrideContextProvider } from '../../../../components/forms/FormItem';
import { FormItemInputText } from '../../../../components/forms/basicFormElements';
import { FormItemInlineCheckbox } from '../../../../components/forms/checkable';
import {
  FormTheme,
  FormThemeOverrideContextProvider,
} from '../../../../components/forms/formTheme';
import { FormItemSelect } from '../../../../components/forms/selects';
import { cssVariables } from '../../../../styles/cssVariables';
import { pxToNumber } from '../../../../utils/cssUtils';
import AdminConsoleSection, {
  AdminConsoleSectionContent,
  AdminConsoleSectionTitleHeader,
} from '../../components/AdminConsoleSection';
import AdminConsoleContent from '../../layout/AdminConsoleContent';
import { formatAccountLabel } from '../../utils/adminFormatting';
import { AccountBusinessTypeField } from './AdminAccountDetailCommon';

function sharebleUrlOptions(account, types = []) {
  const accountType = types.find(x => x.id === account.profile);

  const intlArgs = {
    bussinessType: accountType?.id,
  };
  if (accountType?.shareableUrl) {
    return [
      {
        labelId:
          'admin.accountManagement.accountDetail.accountSetup.shareableUrl.acceptShown',
        value: true,
        intlArgs,
      },
      {
        labelId:
          'admin.accountManagement.accountDetail.accountSetup.shareableUrl.rejectShown',
        value: false,
        intlArgs,
      },
    ];
  }

  return [
    {
      labelId:
        'admin.accountManagement.accountDetail.accountSetup.shareableUrl.acceptHidden',
      value: false,
      intlArgs,
    },
    {
      labelId:
        'admin.accountManagement.accountDetail.accountSetup.shareableUrl.rejectHidden',
      value: true,
      intlArgs,
    },
  ];
}

function AccountSetupSection({ account, bussinessTypes }) {
  return (
    <AdminConsoleSection>
      <AdminConsoleSectionTitleHeader>
        <strong>
          <FormattedMessage id="admin.accountManagement.accountDetail.accountSetup" />
        </strong>
      </AdminConsoleSectionTitleHeader>
      <AdminConsoleSectionContent className="AdminAccountDetail__ThinCheckboxes">
        <Row align="middle" justify="space-between">
          <Col>
            <FormItemInlineCheckbox
              name="active"
              labelId="admin.accountManagement.accountDetail.accountSetup.active"
              className="vertical-reverse"
            />
          </Col>
          <Col>
            <FormItemInlineCheckbox
              name="isOrderEnabled"
              labelId="admin.accountManagement.accountDetail.accountSetup.orderEnabled"
              className="vertical-reverse"
            />
          </Col>
          <Col>
            <FormItemInlineCheckbox
              name="isQuoteEnabled"
              label={
                <span className="AdminConsoleSection__Tba AdminConsoleSection__Tba--Right">
                  <FormattedMessage id="admin.accountManagement.accountDetail.accountSetup.quoteEnabled" />
                </span>
              }
              className="vertical-reverse"
              disabled
            />
          </Col>
          <Col>
            <FormItemInlineCheckbox
              name="forceDeadline"
              label={
                <span className="AdminConsoleSection__Tba AdminConsoleSection__Tba--Right">
                  <FormattedMessage id="admin.accountManagement.accountDetail.accountSetup.forceDeadline" />
                </span>
              }
              className="vertical-reverse"
              disabled
            />
          </Col>
          <Col span={5}>
            <FormItemSelect
              labelCol={24}
              className="size-sm no-background no-padding"
              floatingLabel={false}
              name="shareableUrl"
              labelId="admin.accountManagement.accountDetail.accountSetup.shareableUrl"
              options={sharebleUrlOptions(account, bussinessTypes)}
            />
          </Col>
        </Row>
      </AdminConsoleSectionContent>
    </AdminConsoleSection>
  );
}

function AccountCompanyInformationSection() {
  return (
    <FormItemsOverrideContextProvider viewMode>
      <AdminConsoleSection>
        <AdminConsoleSectionTitleHeader>
          <strong>
            <FormattedMessage id="admin.accountManagement.accountDetail.accountSetup" />
          </strong>
        </AdminConsoleSectionTitleHeader>
        <AdminConsoleSectionContent className="AdminAccountDetail__ThinCheckboxes">
          <Row
            gutter={[
              pxToNumber(cssVariables.spaceNorm2),
              pxToNumber(cssVariables.spaceNorm1_5),
            ]}
          >
            <Col span={12}>
              <FormItemInputText
                name="name"
                labelId="admin.accountManagement.accountDetail.companyInformation.name"
              />
            </Col>
            <Col span={12}>
              <FormItemInputText
                name={['address', 'country']}
                labelId="admin.accountManagement.accountDetail.companyInformation.country"
              />
            </Col>
            <Col span={24}>
              <FormItemInputText
                name={['address', 'street']}
                labelId="admin.accountManagement.accountDetail.companyInformation.street"
              />
            </Col>
            <Col span={12}>
              <FormItemInputText
                name={['address', 'city']}
                labelId="admin.accountManagement.accountDetail.companyInformation.city"
              />
            </Col>
            <Col span={6}>
              <FormItemInputText
                name={['address', 'stateProvince']}
                labelId="admin.accountManagement.accountDetail.companyInformation.stateProvince"
              />
            </Col>
            <Col span={6}>
              <FormItemInputText
                name={['address', 'postalCode']}
                labelId="admin.accountManagement.accountDetail.companyInformation.postalCode"
              />
            </Col>
          </Row>
        </AdminConsoleSectionContent>
      </AdminConsoleSection>
    </FormItemsOverrideContextProvider>
  );
}

function AccountAddressBookSettingsSection() {
  return (
    <AdminConsoleSection>
      <AdminConsoleSectionTitleHeader>
        <strong>
          <FormattedMessage id="admin.accountManagement.accountDetail.addressBookSettings" />
        </strong>
      </AdminConsoleSectionTitleHeader>
      <AdminConsoleSectionContent className="AdminAccountDetail__ThinCheckboxes">
        <Row align="middle" justify="space-between">
          <Col>
            <FormItemInlineCheckbox
              name="addressBookFullAccess"
              labelId="admin.accountManagement.accountDetail.addressBookSettings.fullAccess"
              formItemComponentProps={{ disabled: true }}
            />
          </Col>
        </Row>
      </AdminConsoleSectionContent>
    </AdminConsoleSection>
  );
}

function AccountKeyContactsSection() {
  return (
    <FormItemsOverrideContextProvider viewMode>
      <AdminConsoleSection>
        <AdminConsoleSectionTitleHeader>
          <strong className="AdminConsoleSection__Tba AdminConsoleSection__Tba--Right">
            <FormattedMessage id="admin.accountManagement.accountDetail.keyContacts" />
          </strong>
        </AdminConsoleSectionTitleHeader>
        <AdminConsoleSectionContent>
          <Row gutter={pxToNumber(cssVariables.spaceNorm2)}>
            <Col span={12}>
              <FormItemInputText
                name={['keyContacts', 'contactName']}
                labelId="admin.accountManagement.accountDetail.keyContacts.contactName"
              />
            </Col>
            <Col span={6}>
              <FormItemInputText
                name={['keyContacts', 'phone']}
                labelId="admin.accountManagement.accountDetail.keyContacts.phone"
              />
            </Col>
            <Col span={6}>
              <div className="AdminConsoleSection__FieldPlaceholder" />
            </Col>
          </Row>
        </AdminConsoleSectionContent>
      </AdminConsoleSection>
    </FormItemsOverrideContextProvider>
  );
}

export default function AdminAccountDetailProfile({
  account,
  viewMode,
  visible,
  bussinessTypes,
}) {
  return (
    <AdminConsoleContent
      titleIcon="file-invoice"
      titleId="admin.accountManagement.accountDetail.accountProfile"
      entityLabel={formatAccountLabel(account)}
      isEditing={!viewMode}
      visible={visible}
      headerRight={<AccountBusinessTypeField />}
    >
      <FormThemeOverrideContextProvider themeClassName={FormTheme.DarkOrange}>
        <AdminConsoleSection>
          <AdminConsoleSectionTitleHeader>
            <Typography.Title level={3}>
              {formatAccountLabel(account, { long: true })}
            </Typography.Title>
          </AdminConsoleSectionTitleHeader>
          <AdminConsoleSectionContent>
            <div className="spaces-vert-norm2 no-margin-form-items">
              <AccountSetupSection
                viewMode={viewMode}
                account={account}
                bussinessTypes={bussinessTypes}
              />
              <AccountCompanyInformationSection
                viewMode={viewMode}
                account={account}
              />
              <AccountAddressBookSettingsSection
                viewMode={viewMode}
                account={account}
              />
              <AccountKeyContactsSection
                viewMode={viewMode}
                account={account}
              />
            </div>
          </AdminConsoleSectionContent>
        </AdminConsoleSection>
      </FormThemeOverrideContextProvider>
    </AdminConsoleContent>
  );
}
