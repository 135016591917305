import { every, get } from 'lodash-es';
import moment from 'moment';

export function isDateRangeEqual(range1, range2) {
  return every(['from', 'to'], key => {
    const m1 = get(range1, key);
    const m2 = get(range2, key);
    if (!moment.isMoment(m1) && !moment.isMoment(m2)) {
      return true;
    }
    if (moment.isMoment(m1) && moment.isMoment(m2)) {
      return m1.isSame(m2, 'day');
    }
    return false;
  });
}
