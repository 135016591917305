import { Form } from 'antd';
import classNames from 'classnames';
import { identity, isEqual, isFunction, pick } from 'lodash-es';
import { createContext, useCallback, useContext, useState } from 'react';
import { useIntl } from 'react-intl';

import { sanitizeFormValueToAscii } from '../../common/utils/stringUtils';
import { log } from '../../utils/logger';
import ErrorBoundary from '../ErrorBoundary';
import { useFlashMessageContext } from '../dialogs/FlashMessageProvider';
import { useParentFormListName } from './FormList';
import { FormSanitizationContext } from './FormSanitizationContext';
import { extractAntdFormItemProps } from './antdFormHelpers';
import { useDynamicAutofill } from './dynamic/dynamicAutofill';
import { DynamicFormDependenciesContext } from './dynamic/dynamicFormDependencies';
import { useDynamicFormSchemaFieldDefinition } from './dynamic/dynamicFormSchema';
import { useDynamicFormValidationRules } from './dynamic/dynamicFormValidation';
import {
  extractValueFromEvent,
  getIsRequired,
  isFormValuePresent,
  mergeFormNames,
  useFormItemId,
} from './formHelpers';
import { FormInterceptorContext } from './forms';

function makeRulesDecorator({ outerRules, mapper = identity }) {
  return rules => {
    const mappedSchemaRules = mapper(rules);
    let effectiveRules = outerRules;
    if (isFunction(outerRules)) {
      effectiveRules = outerRules(mappedSchemaRules);
    }
    if (!effectiveRules) {
      effectiveRules = mappedSchemaRules;
    }
    return effectiveRules;
  };
}

export function makeRulesAddingDecorator({ outerRules, newRules }) {
  return makeRulesDecorator({
    outerRules,
    mapper: rules => (rules ? [...rules, ...newRules] : newRules),
  });
}

export function makeRulesReplacingDecorator({
  outerRules,
  predicate,
  replacement,
}) {
  return makeRulesDecorator({
    outerRules,
    mapper: rules =>
      rules ? rules.map(rule => (predicate(rule) ? replacement : rule)) : rules,
  });
}

function useRulesFromSchema({ schemaName, rules }) {
  const fieldDef = useDynamicFormSchemaFieldDefinition({ schemaName });
  const schemaRules = useDynamicFormValidationRules({
    schemaName,
    validation: fieldDef.validation,
  });

  let effectiveRules = rules;
  if (isFunction(rules)) {
    effectiveRules = rules(schemaRules);
  }
  if (!effectiveRules) {
    effectiveRules = schemaRules;
  }

  // If rules are provided, they take precedence over dynamic definition,
  // so this has to be extracted from them
  const isRequired = getIsRequired(effectiveRules);

  return { rules: effectiveRules, isRequired };
}

function FormItemInner(props) {
  // Translations
  const intl = useIntl();
  const { label, labelId, placeholder, placeholderId } = props;
  const computedLabel = labelId ? intl.formatMessage({ id: labelId }) : label;
  const computedPlaceholder = placeholderId
    ? intl.formatMessage({ id: placeholderId })
    : placeholder;

  // Rules
  const { rules, isRequired } = props;

  // Form Item props
  const formItemProps = extractAntdFormItemProps(props);

  // Inner props
  const {
    fullName,
    component,
    floatingLabel = true,
    formatValue,
    parseValue,
    formItemComponentProps,
  } = props;
  const innerProps = {
    component,
    floatingLabel,
    label: computedLabel,
    placeholder: computedPlaceholder,
    required: isRequired,
    formatValue,
    parseValue,
    fullName,
    ...pick(props, [
      'name',
      'disabled',
      'loading',
      'options',
      'className',
      'style',
      'viewMode',
      'schemaName',
    ]),
    ...formItemComponentProps,
  };

  return (
    <Form.Item
      validateTrigger="onBlur"
      {...formItemProps}
      label={computedLabel}
      rules={rules}
    >
      <FormItemComponent {...innerProps} />
    </Form.Item>
  );
}

function FormItemSchemaDefinedRules({
  fullName,
  schemaName,
  rules: rulesOuter,
  ...rest
}) {
  const { rules, isRequired } = useRulesFromSchema({
    schemaName,
    rules: rulesOuter,
  });

  return (
    <FormItemInner
      fullName={fullName}
      rules={rules}
      isRequired={isRequired}
      schemaName={schemaName}
      {...rest}
    />
  );
}

/**
 * Like ant's Form Item, but with some more capabilities
 *  - label is translated (if `labelId` prop is provided)
 *  - rules are loaded from form schema (defined by `schemaName`)
 *    - if `rules` are passed, they take precedence
 *    - `rules` can also be a function, in which case it's called with schema rules
 */
export function FormItem(props) {
  const { name, schemaName, rules } = props;

  // ant's Form.List items require name to be just an index, but for validation a full name is needed
  const parentFormListName = useParentFormListName();
  const fullName = mergeFormNames(parentFormListName, name);

  if (!schemaName) {
    return (
      <ErrorBoundary messageId="error.render.formItem">
        <FormItemInner
          {...props}
          fullName={fullName}
          isRequired={getIsRequired(rules)}
        />
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary messageId="error.render.formItem">
      <FormItemSchemaDefinedRules {...props} fullName={fullName} />
    </ErrorBoundary>
  );
}

export function useFormValueDecorator({
  value,
  onChange,
  formatValue,
  parseValue,
}) {
  const { infoMessage } = useFlashMessageContext();
  const { asciiWarningShown, setAsciiWarningShown } = useContext(
    FormSanitizationContext
  );

  const formattedValue = formatValue ? formatValue(value) : value;
  const decoratedOnChange = useCallback(
    eventOrValue => {
      if (!onChange) {
        return;
      }

      const val = extractValueFromEvent(eventOrValue);
      const parsedValue = parseValue ? parseValue(val) : val;
      const sanitizedValue = sanitizeFormValueToAscii(parsedValue);
      onChange(sanitizedValue);

      if (!asciiWarningShown) {
        if (!isEqual(parsedValue, sanitizedValue)) {
          log.debug('Sanitized to ASCII (first occurence)', {
            from: parsedValue,
            to: sanitizedValue,
          });
          infoMessage({ contentId: 'form.warning.asciiSanitizationApplied' });
          setAsciiWarningShown();
        }
      }
    },
    [asciiWarningShown, infoMessage, onChange, parseValue, setAsciiWarningShown]
  );

  return { value: formattedValue, onChange: decoratedOnChange };
}

function FormItemComponentWithFloatingLabel({
  component: C,
  label,
  required,
  value,
  name,
  fullName,
  onFocus,
  onBlur,
  onKeyDown,
  focused,
  isValuePresent,
  ...rest
}) {
  return (
    <div
      className={classNames('FloatingLabelWrapper', {
        'has-value': isFunction(isValuePresent)
          ? isValuePresent(value)
          : isFormValuePresent(value),
        'is-required': required,
        'is-focused': focused,
        'is-disabled': rest.disabled,
      })}
    >
      <C
        {...rest}
        id={useFormItemId({ name })}
        name={fullName.join('.')}
        data-name={name}
        label={undefined}
        placeholder=" "
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
      />
      <label htmlFor={name}>{label}</label>
    </div>
  );
}

function FormItemComponentWithoutFloatingLabel({
  component: C,
  fullName,
  name,
  label,
  required,
  onFocus,
  onBlur,
  onKeyDown,
  focused,
  ...rest
}) {
  return (
    <C
      {...rest}
      id={useFormItemId({ name })}
      name={fullName.join('.')}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
    />
  );
}

export const FormItemOverrideContext = createContext({
  disabled: false,
  viewMode: false,
});
export function FormItemsOverrideContextProvider({
  force,
  disabled,
  viewMode,
  children,
}) {
  const { disabled: disabledFromParent, viewMode: viewModeFromParent } =
    useContext(FormItemOverrideContext);
  return (
    <FormItemOverrideContext.Provider
      value={{
        disabled: force ? !!disabled : !!disabled || disabledFromParent,
        viewMode: force ? !!viewMode : !!viewMode || viewModeFromParent,
      }}
    >
      {children}
    </FormItemOverrideContext.Provider>
  );
}

function FormItemComponent({
  floatingLabel,
  value: valueOuter,
  onChange: onChangeOuter,
  formatValue,
  parseValue,
  disabled,
  viewMode,
  className,
  name,
  schemaName,
  onFocus: onFocusOuter,
  onBlur: onBlurOuter,
  onKeyDown: onKeyDownOuter,
  onAutofill,
  onAutofillSkip,
  ...rest
}) {
  const { value, onChange } = useFormValueDecorator({
    value: valueOuter,
    onChange: onChangeOuter,
    formatValue,
    parseValue,
  });

  const onAutofillChange = useCallback(
    val => {
      onChange(val);
      onAutofill && onAutofill(val);
    },
    [onAutofill, onChange]
  );
  const { loading: loadingNewValue } = useDynamicAutofill({
    name,
    schemaName,
    onChange: onAutofillChange,
    onSkip: onAutofillSkip,
  });

  const overrideContext = useContext(FormItemOverrideContext);
  const viewModeActive = viewMode || overrideContext.viewMode;
  const overrides = {
    disabled:
      disabled || overrideContext.disabled || viewModeActive || loadingNewValue,
    className: viewModeActive
      ? classNames('ant-input-view-mode', className)
      : className,
  };
  const { onBlur: onBlurGlobal, onKeyDown: onKeyDownGlobal } = useContext(
    FormInterceptorContext
  );
  const { onBlur: onBlurCounting } = useContext(DynamicFormDependenciesContext);

  const [focused, setFocused] = useState();
  const onFocus = useCallback(
    (...args) => {
      setFocused(true);
      onFocusOuter && onFocusOuter(...args);
    },
    [onFocusOuter]
  );
  const onBlur = useCallback(
    (...args) => {
      setFocused(false);
      onBlurOuter && onBlurOuter(...args);
      onBlurGlobal(...args);
      onBlurCounting(schemaName);
    },
    [onBlurCounting, onBlurGlobal, onBlurOuter, schemaName]
  );
  const onKeyDown = useCallback(
    (...args) => {
      onKeyDownOuter && onKeyDownOuter(...args);
      onKeyDownGlobal(...args);
    },
    [onKeyDownGlobal, onKeyDownOuter]
  );

  return floatingLabel ? (
    <FormItemComponentWithFloatingLabel
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      focused={focused}
      {...rest}
      {...overrides}
    />
  ) : (
    <FormItemComponentWithoutFloatingLabel
      name={name}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      focused={focused}
      {...rest}
      {...overrides}
    />
  );
}
