import { gql } from '@apollo/client';

export const DOCUMENT_TYPE_OPTIONS_QUERY = gql`
  query ShipmentDocumentTypesQuery($accountNumber: String!) {
    shipmentDocumentTypes(accountNumber: $accountNumber) {
      code
      text
    }
  }
`;

export const DOCUMENT_UPLOAD_SUBSCRIPTION = gql`
  subscription ShipmentDocumentUploadSubscription($actionId: String!) {
    onDocumentUploadChange(actionId: $actionId) {
      actionId
      status
      error {
        code
        message
      }
      id
    }
  }
`;
