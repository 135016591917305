import { Button, Col, Form, Row } from 'antd';
import { useState } from 'react';

import { FAIcon, FATypes } from '../../components/adapters/fontAwesomeAdapters';
import IconButton from '../../components/buttons/IconButton';
import { extractValueFromEvent } from '../../components/forms/formHelpers';
import { FormItemSearchFlat } from '../../components/forms/searchFormElements';
import { cssVariables } from '../../styles/cssVariables';
import { pxToNumber } from '../../utils/cssUtils';

function AddressBookFreeTextSearch({ value, onChange, setClosed }) {
  return (
    <Form component={false}>
      <FormItemSearchFlat
        name="jobSearchText"
        onCancel={() => {
          onChange('');
          setClosed();
        }}
        formItemComponentProps={{
          autoFocus: true,
          value,
          onChange: e => onChange(extractValueFromEvent(e)),
        }}
      />
    </Form>
  );
}

function AddressBookFreeTextSearchIcon({ setOpen }) {
  return <IconButton icon="search" className="icon-18" onClick={setOpen} />;
}

export function AddressBookHeaderRightContent({
  searchValue,
  searchOnChange,
  setData,
  readOnly,
}) {
  const [searchIsOpen, setSearchOpen] = useState();

  if (searchIsOpen) {
    return (
      <div className="no-margin-form-items">
        <AddressBookFreeTextSearch
          value={searchValue}
          onChange={searchOnChange}
          setClosed={() => setSearchOpen(false)}
        />
      </div>
    );
  }

  return (
    <Row align="middle" gutter={pxToNumber(cssVariables.spaceNorm1_5)}>
      {!readOnly && (
        <Col>
          <Button
            type="primary"
            size="small"
            ghost
            className="width-60"
            onClick={() => setData({ adding: true })}
          >
            <FAIcon
              icon="address-card"
              className="icon-19"
              type={FATypes.REGULAR}
            />
            <FAIcon icon="plus" className="icon-15" />
          </Button>
        </Col>
      )}
      <Col>
        <AddressBookFreeTextSearchIcon setOpen={() => setSearchOpen(true)} />
      </Col>
    </Row>
  );
}
