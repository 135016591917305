import { camelCase } from 'lodash-es';

import {
  ShipmentTemplateFromAddressForm,
  ShipmentTemplatePackageListForm,
  ShipmentTemplateServiceInformationForm,
  ShipmentTemplateToAddressForm,
} from './shipmentTemplatesForms';

export const SHIPMENT_TEMPLATE_STEPS = [
  { name: 'from-address', component: ShipmentTemplateFromAddressForm },
  { name: 'to-address', component: ShipmentTemplateToAddressForm },
  {
    name: 'shipment-information',
    component: ShipmentTemplateServiceInformationForm,
  },
  { name: 'package-list', component: ShipmentTemplatePackageListForm },
].map(({ name, ...rest }) => ({
  ...rest,
  titleId: `book.newShipment.step.${camelCase(name)}`,
  shortTitleId: `book.newShipment.step.${camelCase(name)}.short`,
  name,
}));
