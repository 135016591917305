import { Col, Row, Typography } from 'antd';
import { get, isNumber } from 'lodash-es';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { NewShipmentDateSpecialValue } from '../../app/data/newShipmentConversions';
import { formToDetailLocation } from '../../app/data/shipmentConversions';
import {
  formatCodeValue,
  formatCodeValueShort,
  formatLocationLong,
} from '../../common/utils/formatUtils';
import { FormSanitizationContext } from '../../components/forms/FormSanitizationContext';
import { YesNoFormat } from '../../components/forms/checkable';
import { extractLabelFromRichFormValue } from '../../components/forms/dynamic/dynamicFormHelpers';
import { useDynamicFormSchemaDynamicFieldsDefinition } from '../../components/forms/dynamic/dynamicFormSchema';
import { isFormSectionNonEmpty } from '../../components/forms/formHelpers';
import { ConfigurableCard } from '../../components/layout/cardElements';
import { TwoLinesSeparator } from '../../components/layout/layoutElements';
import { LabelWithValue } from '../../components/typography';
import {
  formatDateTimeSchedule,
  formatShipperSpecificInfoField,
} from '../../containers/shipmentDetail/shipmentFormats';
import { useNewOrderFeatures } from '../../forms/shipments/shipmentModes';
import { DateTimeFormatMedium } from '../../utils/dateFormatting';

function Section({ titleId, children }) {
  return (
    <>
      <div className="ShipmentOrderPreviewCard-Section">
        {titleId && (
          <Typography.Title level={4}>
            <FormattedMessage id={titleId} />
          </Typography.Title>
        )}
        {children}
      </div>
      <TwoLinesSeparator className="hide-last" />
    </>
  );
}

function AddressInformation({ formValues, dateLabelId, dateName }) {
  const { companyName, [dateName]: date } = formValues;
  const dateSpecialValue = formValues[`${dateName}-specialValue`];
  const { address, contact } = formValues;
  const location = formToDetailLocation({ address, contact });

  if (
    !isFormSectionNonEmpty(address) &&
    !isFormSectionNonEmpty(contact) &&
    !isFormSectionNonEmpty(companyName) &&
    !date &&
    !dateSpecialValue
  ) {
    return null;
  }

  return (
    <>
      <Row>
        <Col>
          <LabelWithValue
            labelSize="full"
            labelId="shipmentDetail.company"
            text={companyName}
          />
        </Col>
        <Col className="Flex1">
          <LabelWithValue
            className="ShipmentOrderPreview__DateLabelWithValue"
            labelSize="full"
            labelId={dateLabelId}
            text={formatDateTimeSchedule(
              {
                scheduled: date,
                isAsap: dateSpecialValue === NewShipmentDateSpecialValue.ASAP,
                isNow: dateSpecialValue === NewShipmentDateSpecialValue.NOW,
              },
              DateTimeFormatMedium
            )}
          />
        </Col>
      </Row>
      <div className="nl-as-newline">{formatLocationLong(location)}</div>
    </>
  );
}

function ServiceInformation({ formValues }) {
  const shipperSpecificFields = useDynamicFormSchemaDynamicFieldsDefinition({
    schemaName: 'serviceInformation.shipperSpecificInfo',
  });
  const { dangerousGoods } = useNewOrderFeatures();

  if (!isFormSectionNonEmpty(formValues)) {
    return null;
  }

  return (
    <>
      <LabelWithValue
        labelSize="lg"
        nowrap
        labelId="book.newShipment.label.serviceType"
        text={formatCodeValue(formValues.serviceType)}
      />
      <LabelWithValue
        labelSize="lg"
        nowrap
        labelId="book.newShipment.label.commodity"
        text={formValues.commodity}
      />
      {dangerousGoods && (
        <>
          <LabelWithValue
            labelSize="lg"
            nowrap
            labelId="book.newShipment.label.dangerousGoods"
            text={
              <YesNoFormat
                className="text-uppercase"
                value={get(formValues, 'dangerousGoods')}
              />
            }
          />
          <LabelWithValue
            labelSize="lg"
            nowrap
            labelId="book.newShipment.label.exceptedQuantity"
            text={
              <YesNoFormat
                className="text-uppercase"
                value={get(formValues, 'exceptedQuantity')}
              />
            }
          />
          <LabelWithValue
            labelSize="lg"
            nowrap
            labelId="book.newShipment.label.unNumber"
            text={formatCodeValueShort(get(formValues, 'unNumber'))}
          />
          <LabelWithValue
            labelSize="lg"
            nowrap
            labelId="book.newShipment.label.unDescription"
            text={formatCodeValue(get(formValues, 'unNumber'))}
          />
        </>
      )}
      <LabelWithValue
        labelSize="lg"
        nowrap
        labelId="book.newShipment.label.hawbBol"
        text={formValues.bolNumber}
      />
      <LabelWithValue
        labelSize="lg"
        nowrap
        labelId="book.newShipment.label.costCenter"
        text={formValues.costCenter}
      />
      <LabelWithValue
        labelSize="lg"
        nowrap
        labelId="book.newShipment.label.currency"
        text={formatCodeValue(formValues.currency)}
      />
      <LabelWithValue
        labelSize="lg"
        nowrap
        labelId="book.newShipment.label.declaredValue"
        text={formValues.declaredValue}
      />
      <LabelWithValue
        labelSize="lg"
        nowrap
        labelId="book.newShipment.label.additionalInsurance"
        text={formValues.additionalInsurance}
      />
      <LabelWithValue
        labelSize="lg"
        nowrap
        labelId="book.newShipment.label.eoriNumber"
        text={formValues.eoriNumber}
      />
      {formValues.hsInfo
        ?.filter(item => isFormSectionNonEmpty(item))
        ?.map((item, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={i}>
            <LabelWithValue
              labelSize="lg"
              nowrap
              labelId="book.newShipment.label.hsCode"
              text={item.code}
            />
            <LabelWithValue
              labelSize="lg"
              nowrap
              labelId="book.newShipment.label.hsDescription"
              text={item.description}
            />
          </Fragment>
        ))}
      {shipperSpecificFields.map(({ name, label, ...info }) => {
        const text = formatShipperSpecificInfoField({
          ...info,
          value: extractLabelFromRichFormValue(
            formValues.shipperSpecificInfo?.[name]
          ),
        });
        return (
          <LabelWithValue
            key={name}
            labelSize="lg"
            nowrap
            label={label}
            text={text}
          />
        );
      })}
    </>
  );
}

export default function ShipmentOrderPreviewCard({
  account,
  values,
  stepIndex,
}) {
  // It would be annoying to see every change here immediately,
  // therefore we use cached values from the last step change
  const prevIndex = useRef(stepIndex);
  const [cachedValues, setCachedValues] = useState(values);
  const { asciiWarningShown } = useContext(FormSanitizationContext);
  useEffect(() => {
    if (stepIndex !== prevIndex.current) {
      setCachedValues({ ...values, asciiWarningShown });
      prevIndex.current = stepIndex;
    }
  }, [asciiWarningShown, stepIndex, values]);

  const usedValues = isNumber(stepIndex) ? cachedValues : values;

  return (
    <ConfigurableCard
      titleId="book.newShipment.shipmentPreview"
      className="ShipmentOrderPreviewCard"
      bodyComponent="div"
    >
      <Section>
        <LabelWithValue
          labelSize="lg"
          nowrap
          labelId="shipmentDetail.accountNumber"
          text={get(account, 'number')}
        />
      </Section>
      {cachedValues.asciiWarningShown && (
        <div className="ShipmentOrderPreview__Warning">
          <FormattedMessage id="book.newShipment.shipmentPreview.diacriticsWarning" />
        </div>
      )}
      <Section titleId="book.newShipment.step.fromAddress">
        <AddressInformation
          formValues={usedValues?.origin || {}}
          dateLabelId="job.readyDate.short"
          dateName="pickupDateTime"
        />
      </Section>
      <Section titleId="book.newShipment.step.toAddress">
        <AddressInformation
          formValues={usedValues?.destination || {}}
          dateLabelId="job.deadlineDate.short"
          dateName="deliveryDateTime"
        />
      </Section>
      <Section titleId="book.newShipment.step.shipmentInformation">
        <ServiceInformation formValues={usedValues?.serviceInformation || {}} />
      </Section>
    </ConfigurableCard>
  );
}
