import classNames from 'classnames';
import { noop } from 'lodash-es';
import { createContext, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { SimpleConfirmDialog } from '../components/dialogs/SimpleConfirmDialog';
import { externalLinks } from '../config/externalLinks';
import { formatError } from '../utils/errorUtils';

export function AuthEnvelopeTitle({ text, textId }) {
  return (
    <h3 className="AuthEnvelope-Title">
      {textId ? <FormattedMessage id={textId} /> : text}
    </h3>
  );
}

export function AuthEnvelopeSubtitle({ text, textId }) {
  return (
    <h3 className="AuthEnvelope-Subtitle">
      {textId ? <FormattedMessage id={textId} /> : text}
    </h3>
  );
}

export function AuthEnvelopeInfoText({ text, textId }) {
  return (
    <h4 className="AuthEnvelope-InfoText">
      {textId ? <FormattedMessage id={textId} /> : text}
    </h4>
  );
}

export function AuthEnvelopeLinks({ children }) {
  return <div className="AuthEnvelope-Links">{children}</div>;
}

const AuthErrorContext = createContext({ errorMessage: noop });

export function useAuthErrorMessage() {
  return useContext(AuthErrorContext).errorMessage;
}

function AuthErrorDialog({ error, close }) {
  const intl = useIntl();

  if (!error) {
    return null;
  }
  return (
    <SimpleConfirmDialog
      className="AuthErrorDialog"
      text={formatError(error, { intl })}
      cancelTextId="buttons.dismiss"
      onClose={close}
      {...error.dialogProps}
    >
      <div className="AuthErrorDialog__Links">
        <a href={externalLinks.helpDesk} target="__blank">
          <FormattedMessage id="auth.errorDialog.needHelp" />
        </a>
        <br />
        <a href={externalLinks.contact} target="__blank">
          <FormattedMessage id="auth.errorDialog.contact" />
        </a>
      </div>
    </SimpleConfirmDialog>
  );
}

export function AuthEnvelope({ className, ...rest }) {
  const [error, setError] = useState();

  return (
    <AuthErrorContext.Provider value={{ errorMessage: setError }}>
      <AuthErrorDialog error={error} close={() => setError(undefined)} />
      <div className={classNames('AuthEnvelope', className)} {...rest} />
    </AuthErrorContext.Provider>
  );
}
