import { generatePath } from 'react-router';

import routes from '../../../../app/routes';
import { FormItemsOverrideContextProvider } from '../../../../components/forms/FormItem';
import AdminUserDetail from '../../userManagement/AdminUserDetail';

export function AdminAccountUserDetail({
  headerRightRef,
  userId,
  accountNumber,
}) {
  return (
    <FormItemsOverrideContextProvider force viewMode={false}>
      <AdminUserDetail
        headerRightRef={headerRightRef}
        userId={userId}
        baseRoute={generatePath(routes.admin.accountDetail.userDetail, {
          accountNumber,
          userId: ':userId',
        })}
      />
    </FormItemsOverrideContextProvider>
  );
}
