import { Typography } from 'antd';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { FAIcon } from '../../../components/adapters/fontAwesomeAdapters';

const TYPE_TO_LEVEL = {
  main: 2,
  sub: 4,
};

const TYPE_TO_ICON_CLASS = {
  main: 'icon-22',
  sub: 'icon-19',
};

export default function AdminConsoleTitle({
  icon,
  title,
  titleId,
  type = 'main',
  iconProps,
  iconNode,
}) {
  return (
    <Typography.Title level={TYPE_TO_LEVEL[type]} className="AdminConsoleTitle">
      {iconNode || (
        <FAIcon
          {...iconProps}
          icon={icon}
          className={classNames(TYPE_TO_ICON_CLASS[type], iconProps?.className)}
        />
      )}
      <span>{titleId ? <FormattedMessage id={titleId} /> : title}</span>
    </Typography.Title>
  );
}

export function AdminConsoleTitleContainer({ children }) {
  return <div className="AdminConsoleTitle__Container">{children}</div>;
}
