import PasswordResetInitForm from '../forms/PasswordResetInitForm';
import PasswordResetNewPasswordForm from '../forms/PasswordResetNewPasswordForm';
import { useChangePasswordURLParams } from '../forms/auth/newPasswordCommon';
import NonLoggedPage from '../templates/NonLoggedPage';

export default function PasswordResetPage() {
  const { token, email } = useChangePasswordURLParams();

  return (
    <NonLoggedPage className="PasswordResetPage" logoutUser>
      {token && email ? (
        <PasswordResetNewPasswordForm token={token} email={email} />
      ) : (
        <PasswordResetInitForm />
      )}
    </NonLoggedPage>
  );
}
