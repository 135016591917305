import { find, toPairs } from 'lodash-es';

import ResponsiveOverrideContext from './ResponsiveOverrideContext';
import { useResponsiveQueries } from './responsiveQueries';

function ClassNameMarker({ component: C, children }) {
  const media = useResponsiveQueries();
  const activeMediaPair = find(toPairs(media), ([name, val]) => !!val);

  if (!activeMediaPair) {
    return children;
  }

  const activeMedia = activeMediaPair[0];
  return <C className={`responsive-force-${activeMedia}`}>{children}</C>;
}

export function ResponsiveOverrideSubtree({
  mapper,
  component = 'div',
  children,
}) {
  return (
    <ResponsiveOverrideContext.Provider value={{ mapper }}>
      <ClassNameMarker component={component}>{children}</ClassNameMarker>
    </ResponsiveOverrideContext.Provider>
  );
}
