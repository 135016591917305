import { gql } from '@apollo/client';

export const accountInfoFragment = `
  number
  name
  type
  profile
  isOrderEnabled
  shipmentMode
  isDangerousGoods
  isPartner
`;

const accountFullFragment = `
  number
  name
  type
  profile
  isOrderEnabled
  isQuoteEnabled
  shipmentMode
  isDangerousGoods
  isPartner
  active
  shareableUrl
  forceDeadline
  fullAddressBookAccess
  addressBookSharedBy {
    name
    number
  }
  addressBookSharedWith {
    name
    number
  }
  address {
    addressLine1
    addressLine2
    addressLine3
    city {
      text
    }
    stateProvince {
      code
    }
    postalCode
    country {
      code
    }
  }
  defaultOptions {
    commodities {
      code
      text
    }
    documentTypes {
      code
      text
    }
    gpsDevices {
      code
      text
    }
    temperatureDevices {
      code
      text
    }
    serviceTypes {
      code
      text
    }
    packageTypes {
      code
      text
    }
    valueAdded {
      code
      text
    }
    reports {
      code
      text
    }
  }
  serviceTypesSettings {
    default
    add
    remove
  }
  commodityTypesSettings {
    default
    add
    remove
  }
  gpsDevicesSettings {
    default
    add
    remove
  }
  tempDevicesSettings {
    default
    add
    remove
  }
  packageTempSettings {
    default
    add
    remove
  }
  packageTypesSettings {
    default
    add
    remove
  }
  documentsSettings {
    default
    add
    remove
  }
`;

export const ACCOUNT_SHIPMENT_INFO_QUERY = gql`
  query AccountShipmentInfoQuery($accountNumber: String!) {
    accountShipmentInfo(accountNumber: $accountNumber) {
      isQuoteEnabled
    }
  }
`;

export const ACCOUNTS_QUERY = gql`
  query AccountsQuery(
    $pageIndex: Int!
    $pageSize: Int!
    $filter: AccountFilter
    $sort: Sort
  ) {
    accounts(
      pageIndex: $pageIndex
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      pageIndex
      pageSize
      total
      data {
        number
        name
        profile
        active
        address {
          city {
            text
          }
          stateProvince {
            code
          }
        }
      }
    }
  }
`;

export const ACCOUNT_QUERY = gql`
  query AccountQuery($accountNumber: String!) {
    account(accountNumber: $accountNumber) {
      ${accountFullFragment}
    }
  }
`;

export const UPDATE_ACCOUNT_MUTATION = gql`
  mutation UpdateAccountMutation($accountNumber: String!, $input: AccountInput!) {
    updateAccount(accountNumber: $accountNumber, input: $input) {
      ${accountFullFragment}
    }
  }
`;

export const AVAILABLE_REPORTS_QUERY = gql`
  query AvailableReportsQuery($accountNumbers: [String!]!) {
    availableReports(accountNumbers: $accountNumbers) {
      code
      text
      requiresDateRange
    }
  }
`;
