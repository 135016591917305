import { pickBy } from 'lodash-es';
import { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';

import { UnitSystem } from '../../app/enums/measuringUnits';
import { getLoggedIn } from '../../app/redux/auth';
import { useSettings } from '../../hooks/data/auth';

export const SettingsContext = createContext({
  loading: true,
  unitSystem: null,
  dateFormat: null,
});

const DEFAULT_SETTINGS = {
  unitSystem: UnitSystem.IMPERIAL,
  dateFormat: 'MMDDYYYY',
};
function UserSettingsContextProviderInner({ children }) {
  const { settings, loading } = useSettings();
  return (
    <SettingsContext.Provider
      value={{
        ...DEFAULT_SETTINGS,
        ...settings,
        loading,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export function UserSettingsContextProvider({ children }) {
  const isLoggedIn = useSelector(getLoggedIn);

  if (!isLoggedIn) {
    return children;
  }

  return (
    <UserSettingsContextProviderInner>
      {children}
    </UserSettingsContextProviderInner>
  );
}

export function OverrideSettingsContextProvider({
  unitSystem,
  dateFormat,
  children,
}) {
  const parentSettings = useContext(SettingsContext);
  return (
    <SettingsContext.Provider
      value={{
        ...parentSettings,
        ...pickBy({ unitSystem, dateFormat }, v => !!v),
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}
