import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';

import { FAIcon } from '../../../components/adapters/fontAwesomeAdapters';
import { cancelShipmentTemplate } from './shipmentTemplateUtils';

export function SaveShipmentTemplateButton({ onClick, disabled }) {
  return (
    <Button
      size="small"
      onClick={onClick}
      disabled={disabled}
      data-subject="shipment-templates"
      data-action="open-drawer"
    >
      <FAIcon icon="save" />
      <span>
        <FormattedMessage id="book.newShipment.saveTemplate" />
      </span>
    </Button>
  );
}

export function CancelShipmentTemplateButton({
  setActiveItem,
  forms,
  afterApply,
}) {
  const onClick = () => {
    cancelShipmentTemplate(forms);

    afterApply();
    setActiveItem(undefined);
  };

  return (
    <Button size="small" onClick={onClick}>
      <FAIcon icon="ban" className="margin-sm" />
      <span>
        <FormattedMessage id="book.newShipment.cancelTemplate" />
      </span>
    </Button>
  );
}
