import React from 'react';

import {
  PASSWORD_CHANGE_MUTATION,
  PASSWORD_RESET_TOKEN_VALIDATION_QUERY,
} from '../app/graphql/authQueries';
import NewPasswordForm from './auth/NewPasswordForm';
import { useChangePasswordFormProps } from './auth/newPasswordCommon';

export default function PasswordResetNewPasswordForm({ token, email }) {
  const {
    error,
    loading,
    changePassword,
    onChangePasswordSuccess,
    validatePassword,
  } = useChangePasswordFormProps({
    token,
    email,
    verifyQuery: PASSWORD_RESET_TOKEN_VALIDATION_QUERY,
    changeMutation: PASSWORD_CHANGE_MUTATION,
    successMessageId: 'passwordReset.newPassword.successMessage',
  });
  return (
    <NewPasswordForm
      className="PasswordResetNewPasswordForm"
      tokenError={error}
      tokenValidLoading={loading}
      changePassword={changePassword}
      onChangePasswordSuccess={onChangePasswordSuccess}
      validatePassword={validatePassword}
      passwordButtonTextId="passwordReset.newPassword.action"
      pinButtonTextId="passwordReset.pin.action"
    />
  );
}
