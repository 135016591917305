import { ThreeLines } from '../components/layout/layoutElements';
import Footer, { FooterTheme } from './Footer';
import { NonLoggedMenu } from './NonLoggedHeader';

export default function NonLoggedFooter() {
  return (
    <>
      <div className="NonLoggedFooter-LinksAbove">
        <ThreeLines />
        <div className="LoggedFooter-Links hide-xl-and-bigger">
          <NonLoggedMenu />
        </div>
      </div>
      <Footer theme={FooterTheme.LIGHT} />
    </>
  );
}
