import { useReduxDialogControls } from '../common/utils/dialogUtils';
import { SimpleConfirmDialog } from '../components/dialogs/SimpleConfirmDialog';

export default function VersionIncompatibilityDialog() {
  const { isOpen } = useReduxDialogControls({
    id: 'app.incompatbileVersion',
  });

  return (
    <SimpleConfirmDialog
      className="VersionIncompatibilityDialog"
      visible={isOpen}
      onOk={() => window.location.reload()}
      textId="dialogs.versionIncompatibility.text"
      okTextId="dialogs.versionIncompatibility.refresh"
      mask
    />
  );
}
