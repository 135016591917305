import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { withMergedClass } from '../../utils/reactUtils';

export const SimpleTable = withMergedClass('SimpleTable')('div');
export const SimpleTableHead = withMergedClass('SimpleTable-Head')('div');
export const SimpleTableBody = withMergedClass('SimpleTable-Body')('div');
export const SimpleTableRow = withMergedClass('SimpleTable-Row')('div');

export function SimpleTableCell({ className, children, textId, ...rest }) {
  return (
    <div className={classNames('SimpleTable-Cell', className)} {...rest}>
      {textId ? <FormattedMessage id={textId} /> : children}
    </div>
  );
}
