import { useApolloClient, useQuery } from '@apollo/client';
import classNames from 'classnames';
import { identity, noop } from 'lodash-es';
import { createContext, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { extractGraphqlEntity } from '../../../common/utils/graphqlUtils';
import { useDebouncedVariable } from '../../../common/utils/hookUtils';
import { useSortToggle } from '../../../common/utils/sortUtils';
import { useStateIfMounted } from '../../../utils/reactUtils';
import { useScrollToThis } from '../../../utils/scrollUtils';
import { FAIcon } from '../../adapters/fontAwesomeAdapters';
import IconButton from '../../buttons/IconButton';
import { Spinner } from '../../data/Spinner';
import { renderDataStateIndicator } from '../../data/dataStateHandlers';
import { useFlashMessageContext } from '../../dialogs/FlashMessageProvider';
import { SimpleConfirmDialogButton } from '../../dialogs/SimpleConfirmDialog';
import { Scrollbars, VerticalTrackTypes } from '../../layout/Scrollbars';
import { Popup, PopupContext } from '../../nav/navElements';
import { InlineLink } from '../../typography';

const TemplatesListPopupContext = createContext({
  noItemsMessageId: '',
  deleteSuccessMessageId: '',
  mapItemToForm: identity,
  onApplyItem: noop,
  renderItemPopup: undefined,
  removeAction: undefined,
  onOpen: undefined,
  removeDialogClassName: '',
  removeTextId: '',
  getRemoveText: null,
  detailQuery: undefined,
});

function TemplatesListColumnHeader({ name, titleId, sort, setSort }) {
  const { active, icon, onClick } = useSortToggle({ name, sort, setSort });
  return (
    <InlineLink
      className="TemplatesList-HeaderItem"
      textId={titleId}
      iconAfter={
        icon && <FAIcon icon={icon} className={classNames({ active })} />
      }
      onClick={onClick}
    />
  );
}

function RemoveIcon({ id, name, active, setActive, closePopup }) {
  const {
    removeAction,
    deleteSuccessMessageId,
    removeDialogClassName,
    removeTextId,
    getRemoveText,
  } = useContext(TemplatesListPopupContext);

  const { errorMessage, successMessage } = useFlashMessageContext();
  const { execute: remove, loading: removing } = removeAction;

  return (
    <SimpleConfirmDialogButton
      className={removeDialogClassName}
      cancelTextId="buttons.cancel"
      okTextId="buttons.delete"
      onOk={async () => {
        try {
          await remove({ variables: { id } });
          if (active) {
            setActive(undefined);
          }
          successMessage({
            contentId: deleteSuccessMessageId,
          });
        } catch (err) {
          errorMessage(err);
        }
      }}
      text={getRemoveText && getRemoveText({ id, name })}
      textId={removeTextId}
    >
      {({ open }) => (
        <FAIcon
          icon="window-close"
          className={classNames('to-right icon-14 Clickable', {
            disabled: removing,
          })}
          onClick={
            removing
              ? noop
              : e => {
                  e.stopPropagation();
                  closePopup();
                  open();
                }
          }
        />
      )}
    </SimpleConfirmDialogButton>
  );
}

function EditIcon({ id, name, data, closePopup }) {
  const client = useApolloClient();
  const { onOpen, detailQuery } = useContext(TemplatesListPopupContext);
  const [loading, setLoading] = useStateIfMounted();

  const onClick = async () => {
    setLoading(true);
    try {
      if (detailQuery) {
        const { data: fullData } = await client.query({
          // This is to always receive the newest version in form
          fetchPolicy: 'network-only',
          query: detailQuery,
          variables: { id },
        });
        const { id: _1, name: _2, ...values } = extractGraphqlEntity(fullData);
        await onOpen({ id, name, data: values });
      } else {
        await onOpen({ id, name, data });
      }
    } finally {
      setLoading(false);
    }
    closePopup();
  };

  return loading ? (
    <Spinner />
  ) : (
    <FAIcon
      icon="external-link-square-alt"
      className={classNames('to-right icon-14 Clickable', {
        disabled: loading,
      })}
      onClick={e => {
        e.stopPropagation();
        onClick();
      }}
    />
  );
}

function TemplatesItemLabel({
  id,
  name,
  active,
  setActive,
  data,
  closePopup,
  loading,
}) {
  const { isOpen } = useContext(PopupContext);
  const { removeAction, onOpen, renderItemPopup } = useContext(
    TemplatesListPopupContext
  );

  return (
    <>
      {renderItemPopup && (
        <IconButton
          icon={isOpen ? 'caret-down' : 'caret-right'}
          className="to-left icon-18"
        />
      )}
      <div className="text">{name}</div>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {removeAction && (
            <RemoveIcon
              id={id}
              name={name}
              active={active}
              setActive={setActive}
              closePopup={closePopup}
            />
          )}
          {onOpen && (
            <EditIcon
              id={id}
              name={name}
              data={data}
              setActive={setActive}
              closePopup={closePopup}
            />
          )}
        </>
      )}
    </>
  );
}

function TemplateItem({ id, name, active, setActive, ...data }) {
  const { errorMessage } = useFlashMessageContext();
  const client = useApolloClient();
  const { detailQuery, onApplyItem, renderItemPopup } = useContext(
    TemplatesListPopupContext
  );
  const { isOpen, setOpen } = useContext(PopupContext);
  const [applying, setApplying] = useStateIfMounted();

  // If popup is closed, scrolling doesn't work, so we activate it only when open
  // We also want to wait until it's rendered, so debounce is used
  const scrollActive = useDebouncedVariable(active && isOpen, { delay: 25 });
  const ref = useScrollToThis({ active: scrollActive });

  const onClick = async () => {
    setApplying(true);
    try {
      if (detailQuery) {
        const { data: fullData } = await client.query({
          // This is to always receive the newest version on click
          fetchPolicy: 'network-only',
          query: detailQuery,
          variables: { id },
        });
        const { id: _1, name: _2, ...values } = extractGraphqlEntity(fullData);
        await onApplyItem(values);
        setActive({ id, name, data: values });
      } else {
        await onApplyItem(data);
        setActive({ id, name, data });
      }
    } catch (e) {
      errorMessage(e);
    } finally {
      setApplying(false);
    }
    setOpen(false);
  };

  return (
    <div
      ref={ref}
      key={id}
      className={classNames('TemplatesList-Item', {
        active,
        disabled: applying,
      })}
      onClick={applying ? noop : onClick}
      data-subject="template-list"
      data-role="item"
    >
      {renderItemPopup ? (
        <Popup
          className="TemplatesList-ItemLabel has-popup"
          overlayClassName="TemplatesList-ItemPreview"
          trigger="hover"
          placement="bottom"
          noIcon
          label={
            <TemplatesItemLabel
              id={id}
              name={name}
              active={active}
              setActive={setActive}
              data={data}
              closePopup={() => setOpen(false)}
              loading={applying}
            />
          }
          getPopupContainer={triggerNode => triggerNode.parentNode}
        >
          {renderItemPopup({ id, name, data })}
        </Popup>
      ) : (
        <div className="TemplatesList-ItemLabel">
          <TemplatesItemLabel
            id={id}
            name={name}
            active={active}
            setActive={setActive}
            closePopup={() => setOpen(false)}
            loading={applying}
          />
        </div>
      )}
    </div>
  );
}

function TemplateItems({ items, activeItem, setActiveItem }) {
  const { noItemsMessageId } = useContext(TemplatesListPopupContext);

  if (items.length === 0) {
    return (
      <div className="TemplatesList-NoItems">
        <FAIcon icon="exclamation-triangle ison-sm" />
        <div>
          <FormattedMessage id={noItemsMessageId} />
        </div>
      </div>
    );
  }

  const renderedItems = items.map(item => (
    <TemplateItem
      key={item.id}
      active={activeItem?.id === item.id}
      setActive={setActiveItem}
      {...item}
    />
  ));

  if (items.length <= 4) {
    return renderedItems;
  }
  return (
    <Scrollbars
      vertical={VerticalTrackTypes.ABOVE_CONTENT}
      className="Theme-Light"
    >
      {renderedItems}
    </Scrollbars>
  );
}

/**
 * USAGE: Must be in `TemplatesListQueryProvider`
 * @prop {gql} detailQuery Query to retrieve the detail. If provided, it will be called before applying
 *                         and then passed to `onApplyItem`
 * @prop {execute: Function, loading: Boolean} removeAction Remove action
 * @prop {Function<{id, name, data}>} onOpen Function to be called on clicking an open button
 * @prop {Function} mapItemToForm Mapping function to map GraphQL entity to form representation
 * @prop {Array<{name: string, titleId: string}>} Columns in popup
 * @prop {ReactNode} label Trigger label
 * @prop {String} labelId Trigger label id
 * @prop {Function} onApplyItem Callback after the item is applied (for setting values to form, ...)
 * @prop {Function<{id, name, data}>} renderItemPopup Function to render the popup of item
 * @prop {String} noItemsMessageId Message id to show when there are no items
 * @prop {String} removeDialogClassName Class name of remove dialog
 * @prop {String} removeTextId Text id of dialog to remove an item
 * @prop {Function<{id: string, name: string}: ReactNode>} getRemoveText Function returning content of dialog to remove an item
 * @prop {String} deleteSuccessMessageId Message id after delete success
 * @prop {Object} activeItem Active item
 * @prop {Function} setActiveItem Function to set active item
 */
export default function TemplatesListPopup({
  detailQuery,
  removeAction,
  onOpen,
  mapItemToForm = identity,
  columns,
  label,
  labelId,
  onApplyItem = noop,
  renderItemPopup,
  noItemsMessageId,
  removeDialogClassName,
  removeTextId,
  getRemoveText,
  deleteSuccessMessageId,
  activeItem,
  setActiveItem,
  className,
  overlayClassName,
  query,
  queryOptions,
  ...rest
}) {
  const [sort, setSort] = useState();

  const { variables: outerVariables, ...otherOptions } = queryOptions || {};
  const { data, loading, error } = useQuery(query, {
    variables: { ...outerVariables, sort },
    notifyOnNetworkStatusChange: true,
    ...otherOptions,
  });

  const indicator = renderDataStateIndicator({ data, loading, error });
  const items = data ? extractGraphqlEntity(data).data.map(mapItemToForm) : [];

  return (
    <TemplatesListPopupContext.Provider
      value={{
        detailQuery,
        removeAction,
        onOpen,
        onApplyItem,
        renderItemPopup,
        noItemsMessageId,
        deleteSuccessMessageId,
        mapItemToForm,
        removeDialogClassName,
        removeTextId,
        getRemoveText,
      }}
    >
      <Popup
        className={classNames(
          'TemplatesListPopup-Label',
          { 'text-uppercase': activeItem },
          className
        )}
        overlayClassName={classNames(
          'TemplatesListPopup no-arrow',
          overlayClassName
        )}
        label={label}
        labelId={labelId}
        placement="bottomRight"
        trigger="click"
        customOpenIcon={<FAIcon icon="caret-down" className="icon-18" />}
        customClosedIcon={<FAIcon icon="caret-down" className="icon-18" />}
        {...rest}
      >
        <div className="TemplatesList-Header">
          {columns.map(({ name, titleId }) => (
            <TemplatesListColumnHeader
              key={name}
              name={name}
              titleId={titleId}
              sort={sort}
              setSort={setSort}
            />
          ))}
        </div>
        {indicator || (
          <TemplateItems
            items={items}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
          />
        )}
      </Popup>
    </TemplatesListPopupContext.Provider>
  );
}
