import { identity } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';

export function useTemplatesActiveItem({
  mapItemToForm = identity,
  currentValues,
  isEqual,
}) {
  const [activeItem, setActiveItem] = useState();

  const afterSave = useCallback(
    ({ id, name, ...rest }) => {
      const data = mapItemToForm(rest);
      setActiveItem({ id, name, data });
    },
    [mapItemToForm]
  );

  useEffect(() => {
    if (activeItem && !isEqual(activeItem.data, currentValues)) {
      setActiveItem(undefined);
    }
  }, [activeItem, currentValues, isEqual, setActiveItem]);

  return { activeItem, setActiveItem, afterSave };
}
