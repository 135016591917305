import { Button, Col, Row, Space, Switch, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import { UNLOCK_USER_MUTATION } from '../../../../app/graphql/adminQueries';
import { USER_QUERY } from '../../../../app/graphql/authQueries';
import {
  FAIcon,
  FATypes,
} from '../../../../components/adapters/fontAwesomeAdapters';
import { GraphQLMutatingLink } from '../../../../components/data/asyncActionElements';
import {
  FormTheme,
  FormThemeOverrideContextProvider,
} from '../../../../components/forms/formTheme';
import { InlineLink } from '../../../../components/typography';
import { cssVariables } from '../../../../styles/cssVariables';
import { pxToNumber } from '../../../../utils/cssUtils';
import AdminConsoleSection, {
  AdminConsoleSectionContent,
  AdminConsoleSectionTitleHeader,
} from '../../components/AdminConsoleSection';
import AdminConsoleContent from '../../layout/AdminConsoleContent';
import { formatUserLabel } from '../../utils/adminFormatting';
import AdminUserContactInformationFormSection from '../forms/AdminUserContactInformationFormSection';
import { AdminUserLinkedAccountsFormSections } from '../forms/AdminUserLinkedAccountsFormSections';
import AdminUserSpecificSettingsFormSection from '../forms/AdminUserSpecificSettingsFormSection';

function UserActiveWidget({ user, viewMode }) {
  if (viewMode) {
    return (
      <Space
        direction="horizontal"
        size={pxToNumber(cssVariables.spaceNorm0_5)}
        className="AdminUserDetail__ActiveLabel"
      >
        {user.active ? (
          <>
            <FAIcon icon="check-circle" type={FATypes.SOLID} />
            <span>
              <FormattedMessage id="admin.userManagement.userDetail.profile.active" />
            </span>
          </>
        ) : (
          <>
            <FAIcon icon="times-circle" type={FATypes.SOLID} />
            <span>
              <FormattedMessage id="admin.userManagement.userDetail.profile.inactive" />
            </span>
          </>
        )}
      </Space>
    );
  }

  return (
    <Space direction="horizontal" size={pxToNumber(cssVariables.spaceSm)}>
      <div className="AdminConsole__InlineLabel">
        <FormattedMessage id="admin.userManagement.userDetail.profile.userActive" />
      </div>
      <Switch
        checked={user.active}
        disabled
        className="AdminConsoleSection__Tba AdminConsoleSection__Tba--Top"
      />
    </Space>
  );
}

function UserAccountAccessSection({ user, viewMode }) {
  return (
    viewMode && (
      <AdminConsoleSection>
        <AdminConsoleSectionTitleHeader>
          <strong>
            <FormattedMessage id="admin.userManagement.userDetail.profile.accountAccess" />
          </strong>
        </AdminConsoleSectionTitleHeader>
        <AdminConsoleSectionContent>
          <Row align="middle" justify="space-between">
            <Col>
              <Button
                ghost
                icon={<FAIcon icon="sign-in-alt" />}
                className="width-150 AdminConsoleSection__Tba AdminConsoleSection__Tba--Top"
                disabled
              >
                <span>
                  <FormattedMessage id="admin.userManagement.userDetail.profile.loginAsUser" />
                </span>
              </Button>
            </Col>
            <Col>
              <GraphQLMutatingLink
                mutation={UNLOCK_USER_MUTATION}
                mutationOptions={{
                  variables: { id: user.id },
                  refetchQueries: [
                    { query: USER_QUERY, variables: { id: user.id } },
                  ],
                  awaitRefetchQueries: true,
                }}
                textId="admin.userManagement.userDetail.profile.unlock"
                disabled={!user.isLocked}
              />
            </Col>
            <Col>
              <InlineLink
                textId="admin.userManagement.userDetail.profile.resetPassword"
                disabled
                className="AdminConsoleSection__Tba AdminConsoleSection__Tba--Top"
              />
            </Col>
            <Col>
              <InlineLink
                textId="admin.userManagement.userDetail.profile.sendRegistrationEmail"
                disabled
                className="AdminConsoleSection__Tba AdminConsoleSection__Tba--Top"
              />
            </Col>
          </Row>
        </AdminConsoleSectionContent>
      </AdminConsoleSection>
    )
  );
}

export default function AdminUserDetailProfile({ user, viewMode, visible }) {
  return (
    <AdminConsoleContent
      titleIcon={viewMode ? 'user' : 'user-edit'}
      titleId="admin.userManagement.userDetail.profile"
      entityLabel={formatUserLabel(user)}
      isEditing={!viewMode}
      visible={visible}
    >
      <FormThemeOverrideContextProvider themeClassName={FormTheme.DarkOrange}>
        <AdminConsoleSection>
          <AdminConsoleSectionTitleHeader>
            <Typography.Title level={3}>
              {formatUserLabel(user)}
            </Typography.Title>
            <UserActiveWidget user={user} viewMode={viewMode} />
          </AdminConsoleSectionTitleHeader>
          <AdminConsoleSectionContent>
            <div className="spaces-vert-norm2 no-margin-form-items">
              <UserAccountAccessSection user={user} viewMode={viewMode} />
              <AdminUserContactInformationFormSection viewMode={viewMode} />
              <AdminUserSpecificSettingsFormSection viewMode={viewMode} />
              <AdminUserLinkedAccountsFormSections
                user={user}
                viewMode={viewMode}
              />
            </div>
          </AdminConsoleSectionContent>
        </AdminConsoleSection>
      </FormThemeOverrideContextProvider>
    </AdminConsoleContent>
  );
}
