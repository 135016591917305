import { pick } from 'lodash-es';

import { FormTheme } from '../../../../components/forms/formTheme';
import AdminUserDocumentGroupFormSection from '../forms/AdminUserDocumentGroupFormSection';
import AdminAddUserStepTemplate from './AdminAddUserStepTemplate';

export default function AdminAddUserDocumentGroup({
  onSave,
  onBack,
  onCancel,
  values,
}) {
  return (
    <AdminAddUserStepTemplate
      titleId="admin.userManagement.addUser.documentGroup"
      titleIcon="file-alt"
      onSave={onSave}
      onBack={onBack}
      onCancel={onCancel}
      values={values}
      formClassName={FormTheme.DarkSimple}
      extractRelevantValues={vals => pick(vals, ['documentGroup'])}
    >
      <AdminUserDocumentGroupFormSection />
    </AdminAddUserStepTemplate>
  );
}
