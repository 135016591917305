import { useQuery } from '@apollo/client';
import { Redirect } from 'react-router';

import { USER_QUERY } from '../../app/graphql/authQueries';
import routes from '../../app/routes';
import { extractGraphqlEntity } from '../../common/utils/graphqlUtils';
import { Spinner } from '../data/Spinner';

export default function AdminGuard({ children }) {
  const { data, loading } = useQuery(USER_QUERY);
  const user = data && extractGraphqlEntity(data);

  if (loading) {
    return <Spinner />;
  }

  if (!user?.isAdmin) {
    return <Redirect to={routes.root} />;
  }

  return children;
}
