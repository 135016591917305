import { useCallback, useEffect, useRef } from 'react';

export function timeoutPromise(timeout) {
  return new Promise(resolve => setTimeout(resolve, timeout));
}

/**
 * Returns a callback, which will wait for completion if it already runs
 * If multiple calls are made during waiting, all calls but the last one are discarded
 * Similar to rxjs's switchMap
 */
function useSequentialAsyncCallback(callback, deps) {
  const runningPromise = useRef();
  // Contains marker of the last lock acquirer - those before last will be discarded
  const lock = useRef();

  return useCallback(async (...args) => {
    // Wait for the running promise to finish
    if (runningPromise.current) {
      // Any value with unique identity
      const thisLock = {};
      lock.current = thisLock;
      await runningPromise.current;
      // Discard if not last
      if (lock.current !== thisLock) {
        return undefined;
      }
    }

    runningPromise.current = callback(...args);
    return runningPromise.current;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

function useCallbackDebounced(callback, delay) {
  const timeout = useRef();
  const waitingCalls = useRef([]);

  // This ensures identity of the function and debounce effect
  const callerRef = useRef((...args) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    // This is to ensure that the version of callback from the call-time is used
    const callbackAtThisMoment = callbackRef.current;
    return new Promise((resolve, reject) => {
      waitingCalls.current.push({ resolve, reject });
      timeout.current = setTimeout(async () => {
        try {
          const res = await callbackAtThisMoment(...args);
          waitingCalls.current.forEach(call => call.resolve(res));
        } catch (e) {
          waitingCalls.current.forEach(call => call.reject(e));
        } finally {
          waitingCalls.current = [];
        }
      }, delay);
    });
  });
  const callbackRef = useRef(callback);

  useEffect(
    () =>
      // Cleanup - cancel the lastly invoked function
      () => {
        try {
          if (timeout.current) {
            clearTimeout(timeout.current);
          }
        } finally {
          timeout.current = null;
          waitingCalls.current = [];
        }
      },
    []
  );

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  return callerRef.current;
}

/**
 * Ensures that no new callback is started until the previous one has ended and that
 * callback is started at least ${delay}ms after the last action from the batch
 */
export function useDebouncedAsyncCallback(
  callback,
  deps,
  { delay = 500 } = {}
) {
  const sequentialCallback = useSequentialAsyncCallback(callback, deps);
  return useCallbackDebounced(sequentialCallback, delay);
}
