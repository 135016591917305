import { Col, Row } from 'antd';
import classNames from 'classnames';
import { ary, get, includes } from 'lodash-es';
import { Fragment, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  getShipmentDetailSummaryView,
  setShipmentDetailSummaryView,
} from '../../app/redux/shipmentDetail';
import { useReduxDialogControls } from '../../common/utils/dialogUtils';
import { useCachedProp, useMountEffect } from '../../common/utils/hookUtils';
import { FATypes } from '../../components/adapters/fontAwesomeAdapters';
import IconButton from '../../components/buttons/IconButton';
import {
  Scrollbars,
  VerticalTrackTypes,
} from '../../components/layout/Scrollbars';
import {
  Flex1,
  FlexCol,
  TwoLines,
  TwoLinesSeparator,
} from '../../components/layout/layoutElements';
import SegmentedControl from '../../components/nav/SegmentedControl';
import { SlidingContent } from '../../components/nav/SlidingContent';
import { useResponsiveQueries } from '../../components/responsive/responsiveQueries';
import StickyElement from '../../components/scroll/StickyElement';
import { LabelRow } from '../../components/typography';
import { cssVariables } from '../../styles/cssVariables';
import CopiableJobNumber from './CopiableJobNumber';
import { ShipmentAssetsInventoryDrawer } from './ShipmentAssetsInventory';
import ServiceUpdateCard from './shipmentSummaries/ServiceUpdateCard';
import { ShipmentHistoryAndDocs } from './shipmentSummaries/ShipmentHistoryAndDocs';
import { ShipmentOrderSummary } from './shipmentSummaries/ShipmentOrderSummary';
import { ShipmentPackageList } from './shipmentSummaries/ShipmentPackageList';
import {
  ShipmentSummaryDateTimeFormatFlight,
  TwoLineLabelWithValue,
} from './shipmentSummaries/shipmentSummariesCommon';

export const SHIPMENT_SUMMARY_VIEWS = [
  {
    name: 'order-summary',
    labelId: 'shipmentDetail.orderSummary',
    component: ShipmentOrderSummary,
    props: {
      'data-subject': 'shipment-detail',
      'data-role': 'tab',
      'data-id': 'order-summary',
    },
  },
  {
    name: 'package-list',
    labelId: 'shipmentDetail.packageList',
    component: ShipmentPackageList,
    props: {
      'data-subject': 'shipment-detail',
      'data-role': 'tab',
      'data-id': 'package-list',
    },
  },
  {
    name: 'history-docs',
    labelId: 'shipmentDetail.historyDocs',
    component: ShipmentHistoryAndDocs,
    props: {
      'data-subject': 'shipment-detail',
      'data-role': 'tab',
      'data-id': 'history-docs',
    },
  },
].map(v => ({ ...v, value: v.name }));

const InnerScrollbars = props => (
  <Scrollbars {...props} vertical={VerticalTrackTypes.OUTSIDE} />
);

export function ShipmentSummaries({ className, shipment, views, sharedProps }) {
  const media = useResponsiveQueries();
  const isSm = media.xs || media.sm;
  const cachedViews = useCachedProp(views);
  const viewsInfo = useMemo(
    () =>
      SHIPMENT_SUMMARY_VIEWS.filter(
        ({ name }) => !cachedViews || includes(cachedViews, name)
      ),
    [cachedViews]
  );

  const summaryView = useSelector(getShipmentDetailSummaryView);
  const dispatch = useDispatch();
  const setSummaryView = useCallback(
    value => dispatch(setShipmentDetailSummaryView(value)),
    [dispatch]
  );

  const Wrapper = isSm ? Fragment : InnerScrollbars;

  const isDelivered = shipment?.deliveryDateTime?.actual;
  const hasServiceUpdate = shipment?.serviceUpdate;
  const hasAssets = shipment?.assets?.length > 0;

  const assetsDrawer = useReduxDialogControls({
    id: 'shipmentDetail.assetsDrawer',
  });
  // Close drawer if detail is left
  useMountEffect(() => () => assetsDrawer.close());

  return (
    <FlexCol className={classNames('ShipmentSummaries Flex1', className)}>
      <StickyElement>
        <div className="SimpleCard SimpleCardTheme-ROUND_BORDERED">
          <div className="SimpleCard-Header">
            <SegmentedControl
              options={viewsInfo}
              value={summaryView}
              onChange={setSummaryView}
              style={{
                backgroundColor: 'transparent',
                padding: `${cssVariables.spaceXs} ${cssVariables.spaceNorm}`,
              }}
            />
          </div>
          <TwoLines className="size-10" />
        </div>
        <LabelRow className="ShipmentSummaries-JobNumberRow">
          <Col>
            <CopiableJobNumber jobNumber={shipment?.jobNumber} />
          </Col>
          <Col>
            <Row>
              <Col className="Flex1">
                <TwoLineLabelWithValue
                  labelId="shipmentDetail.status"
                  text={get(shipment, 'status')}
                  className={classNames({
                    'ShipmentSummary-DeliveredStatus': isDelivered,
                  })}
                />
              </Col>
              {hasAssets && (
                <Col className="ShipmentSummaries-AssetsCol">
                  <TwoLineLabelWithValue
                    labelId="shipmentDetail.inventory"
                    text={
                      <IconButton
                        icon="external-link-square-alt"
                        type={FATypes.SOLID}
                        className="icon-16 color-highlight"
                        onClick={ary(assetsDrawer.open, 0)}
                      />
                    }
                  />
                </Col>
              )}
            </Row>
          </Col>
        </LabelRow>
        {isDelivered && (
          <>
            <TwoLinesSeparator />
            <div className="padding-hor-norm">
              <div className="ShipmentSummary-DeliveryProof">
                <FormattedMessage id="shipmentDetail.deliveryProof" />
              </div>
              <LabelRow>
                <Col>
                  <TwoLineLabelWithValue
                    size="md"
                    labelId="shipmentDetail.deliveryTime"
                    text={
                      <ShipmentSummaryDateTimeFormatFlight
                        value={shipment.deliveryDateTime.actual}
                      />
                    }
                  />
                </Col>
                <Col>
                  <TwoLineLabelWithValue
                    labelId="shipmentDetail.signedBy"
                    text={get(shipment, 'signedBy')}
                  />
                </Col>
              </LabelRow>
            </div>
          </>
        )}
        {hasServiceUpdate && (
          <>
            <TwoLinesSeparator />
            <ServiceUpdateCard shipment={shipment} />
          </>
        )}
        <TwoLines className="size-10 margin-bottom-norm" />
      </StickyElement>
      <Flex1>
        <Wrapper>
          <SlidingContent
            autoHeight
            listenToSelected
            selected={summaryView}
            onSelect={setSummaryView}
            data={viewsInfo}
            sharedProps={{ ...sharedProps, shipment }}
          />
        </Wrapper>
      </Flex1>
      <ShipmentAssetsInventoryDrawer
        isOpen={assetsDrawer.isOpen}
        close={assetsDrawer.close}
        data={shipment?.assets}
      />
    </FlexCol>
  );
}

export function ShipmentSummariesCol({ colClassName, ...rest }) {
  return (
    <Col
      className={classNames('ShipmentDetail-LeftColumn FlexCol', colClassName)}
    >
      <ShipmentSummaries {...rest} />
    </Col>
  );
}
