import { Col, Row, Typography } from 'antd';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { withMergedClass } from '../../utils/reactUtils';

const { Title } = Typography;

export function VerticalDivider() {
  return <span className="VerticalDivider">|</span>;
}

export function HorizontalDividerWithCircle({ textId, children }) {
  return (
    <div className="HorizontalDividerWithCircle">
      <div className="HorizontalDividerWithCircle-Line" />
      <div className="HorizontalDividerWithCircle-Circle">
        {textId ? <FormattedMessage id={textId} /> : children}
      </div>
    </div>
  );
}

export const TwoLines = withMergedClass('TwoLines')('div');
export const ThreeLines = withMergedClass('ThreeLines')('div');

export function FlexCol({
  noOverflow,
  align = 'stretch',
  justify = 'flex-start',
  className,
  style,
  ...rest
}) {
  return (
    <div
      className={classNames(
        'FlexCol',
        {
          'FlexCol-NoOverflow': noOverflow,
        },
        className
      )}
      style={{
        ...style,
        alignItems: align,
        justifyContent: justify,
      }}
      {...rest}
    />
  );
}

export const Flex1 = withMergedClass('Flex1')('div');

export function TitleWithExtra({
  className,
  rightColClassName,
  title,
  titleId,
  children,
}) {
  return (
    <Row
      className={classNames('TitleWithExtra', className)}
      align="middle"
      justify="space-between"
    >
      <Col>
        <Title level={3}>
          {titleId ? <FormattedMessage id={titleId} /> : title}
        </Title>
      </Col>
      <Col className={rightColClassName}>{children}</Col>
    </Row>
  );
}

export const TwoLinesSeparator = withMergedClass('TwoLinesSeparator')('div');
export const DashedTwoLinesSeparator = withMergedClass(
  'DashedTwoLinesSeparator'
)('div');
