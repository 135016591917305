import { FormattedMessage } from 'react-intl';

import { isFormValuePresent } from './formHelpers';

function flagToPromise(flag) {
  return flag ? Promise.resolve() : Promise.reject(new Error());
}

function makeStaticRule(messageId, overrides, intlValues) {
  return {
    message: <FormattedMessage id={messageId} values={intlValues} />,
    ...overrides,
  };
}

export function makeStaticCustomRule(messageId, validator, intlValues) {
  return makeStaticRule(
    messageId,
    {
      validator: (_, value) => flagToPromise(validator(value)),
    },
    intlValues
  );
}

export const assertTrueRule = makeStaticCustomRule(
  'error.assertTrue',
  value => !!value
);

export const numericRule = makeStaticCustomRule(
  'error.number',
  value => !isFormValuePresent(value) || !Number.isNaN(Number(value))
);
export const greaterThanRule = min =>
  makeStaticCustomRule(
    'error.number.gt',
    value => !isFormValuePresent(value) || value > min,
    {
      min,
    }
  );

export const validOptionSelectedRule = makeStaticCustomRule(
  'error.validOption',
  value => {
    if (value?.code === '') return true;
    return !value || !!value.code;
  }
);
