import { pick } from 'lodash-es';

import AdminUserContactInformationFormSection from '../forms/AdminUserContactInformationFormSection';
import AdminUserSpecificSettingsFormSection from '../forms/AdminUserSpecificSettingsFormSection';
import AdminAddUserStepTemplate from './AdminAddUserStepTemplate';

export default function AdminAddUserProfile({
  onSave,
  onBack,
  onCancel,
  values,
}) {
  return (
    <AdminAddUserStepTemplate
      titleId="admin.userManagement.addUser.userProfile"
      titleIcon="address-card"
      onSave={onSave}
      onBack={onBack}
      onCancel={onCancel}
      values={values}
      extractRelevantValues={vals =>
        pick(vals, [
          'firstName',
          'lastName',
          'email',
          'phone',
          'title',
          'settings',
        ])
      }
    >
      <AdminUserContactInformationFormSection viewMode={false} />
      <AdminUserSpecificSettingsFormSection viewMode={false} />
    </AdminAddUserStepTemplate>
  );
}
