import classNames from 'classnames';
import { flatten, fromPairs, get, pickBy } from 'lodash-es';
import { useCallback, useEffect, useRef, useState } from 'react';

import { getOrCall } from '../common/utils/funcUtils';
import {
  useDebouncedVariable,
  useMountEffect,
} from '../common/utils/hookUtils';
import { log } from './logger';

const PERIODIC_CHECK_INTERVAL = 250;

export const usePeriodicCheck = performCheck =>
  useMountEffect(() => {
    const interval = window.setInterval(() => {
      performCheck();
    }, PERIODIC_CHECK_INTERVAL);
    return () => window.clearInterval(interval);
  });

export function joinReactElements(elements, joiner = '') {
  return flatten(elements.map((el, i) => [el, getOrCall(joiner, i)])).slice(
    0,
    -1
  );
}

const WithoutProps = ({ component: C, propNames, ...rest }) => {
  const keptProps = pickBy(rest, (val, key) => propNames.indexOf(key) < 0);
  return <C {...keptProps} />;
};
export function withoutProps(...propNames) {
  return component => props => (
    <WithoutProps {...props} component={component} propNames={propNames} />
  );
}

export function withMergedClass(...classDefs) {
  return Component => props => {
    const { className } = props;
    const addClassNames = classDefs.map(def => getOrCall(def, props));
    return (
      <Component {...props} className={classNames(addClassNames, className)} />
    );
  };
}

export function makeComposedFormAccessors({ value, onChange, fields }) {
  return fromPairs(
    fields.map(f => [
      f,
      {
        value: get(value, f),
        onChange: newVal => onChange({ ...value, [f]: newVal }),
      },
    ])
  );
}

export function makeHookInjectingHOC({ propName, hook, getArgs }) {
  const WithHook = ({ component: C, ...rest }) => {
    const hookArgs = getOrCall(getArgs, rest) || [];
    const hookResult = hook(...hookArgs);
    const newProps = { [propName]: hookResult };
    return <C {...rest} {...newProps} />;
  };
  return Component => props => <WithHook component={Component} {...props} />;
}

export function useMountedIndicator({ logPrefix } = {}) {
  const mounted = useRef(true);
  useMountEffect(() => {
    mounted.current = true;
    if (logPrefix) {
      log.debug(logPrefix, 'mount');
    }
    return () => {
      mounted.current = false;
      if (logPrefix) {
        log.debug(logPrefix, 'unmount');
      }
    };
  });
  return mounted;
}

export function useStateIfMounted(initialValue) {
  const mounted = useMountedIndicator();
  const [state, setState] = useState(initialValue);
  const safeSetState = useCallback(
    val => {
      if (mounted.current) {
        setState(val);
      }
    },
    [mounted]
  );

  return [state, safeSetState];
}

/**
 * Hook to help tracking focus in multiple related components
 * For example if a popover is triggered by input being focused,
 * the focus inside popover should still count as focus, because it's related
 */
export function useMultiElementFocus({
  onFocus: onFocusOuter,
  onBlur: onBlurOuter,
}) {
  const [focused, setFocused] = useStateIfMounted(false);
  const focusedElement = useRef();
  // To prevent fast toggle when focus is transferred between "allowed" elements
  const debFocused = useDebouncedVariable(focused, { delay: 250 });

  // Since we don't want to call these when fast toggle is happening, we will react to the debounced value
  useEffect(() => {
    if (debFocused) {
      onFocusOuter && onFocusOuter();
    } else {
      onBlurOuter && onBlurOuter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debFocused]);

  const onFocus = useCallback(
    e => {
      setFocused(true);
      focusedElement.current = e.target;
    },
    [setFocused]
  );

  const onBlur = useCallback(
    e => {
      setFocused(false);
      focusedElement.current = undefined;
    },
    [setFocused]
  );

  const forceBlur = useCallback(() => {
    if (focusedElement.current) {
      focusedElement.current.blur();
      focusedElement.current = undefined;
    }
    setFocused(false);
  }, [setFocused]);

  return { focused: debFocused, onFocus, onBlur, forceBlur };
}
