import classNames from 'classnames';
import { flatten } from 'lodash-es';

export const StepType = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
};

/** returns dataSource enriched by position (%) [{ item, position }] */
export function computePositions(dataSource) {
  const structured = [];
  let lastPrimaryStep;
  for (const item of dataSource) {
    if (item.type === StepType.SECONDARY) {
      lastPrimaryStep.subSteps.push(item);
    } else {
      lastPrimaryStep = { item, subSteps: [] };
      structured.push(lastPrimaryStep);
    }
  }

  const steps = structured.map(({ subSteps, item }, i) => {
    const parentStep = 100 / (structured.length - 1);
    const parentPosition = i * parentStep;
    return [
      {
        item,
        position: parentPosition,
      },
      ...subSteps.map((child, j) => {
        const childStep = parentStep / (subSteps.length + 1);
        const childPosition = (j + 1) * childStep;
        return {
          item: child,
          position: parentPosition + childPosition,
        };
      }),
    ];
  });
  return flatten(steps);
}

function CircleStep({ icon, active, isLeading, isLeadingStepActivated }) {
  const activated = !isLeading || isLeadingStepActivated;
  return (
    <div
      className={classNames('MilestoneStepper-CircleStep', {
        'MilestoneStepper-CircleStep-active': activated && active,
      })}
    >
      <div className="MilestoneStepper-CircleStep-outer" />
      <div className="MilestoneStepper-CircleStep-inner">{icon}</div>
    </div>
  );
}

function RectStep({ icon, active, isLeading, isLeadingStepActivated }) {
  const activated = !isLeading || isLeadingStepActivated;
  return (
    <div
      className={classNames('MilestoneStepper-RectStep', {
        'MilestoneStepper-RectStep-active': activated && active,
        'MilestoneStepper-RectStep-deactivated': !activated,
      })}
    >
      <div className="MilestoneStepper-RectStep-inner" />
    </div>
  );
}

function MilestoneTimeline({ steps, activeStep, isLeadingStepActivated }) {
  const activeIdx = steps.findIndex(({ item }) => item.key === activeStep);
  const activeItem = steps.find(({ item }) => item.key === activeStep);
  return (
    <div className="MilestoneStepper-Timeline">
      {activeItem && (
        <div
          className="MilestoneStepper-ProgressBar"
          style={{ right: `${100 - activeItem.position}%` }}
        />
      )}
      {steps.map(({ position, item }, idx) => {
        const Step = item.type === StepType.SECONDARY ? RectStep : CircleStep;
        return (
          <div
            className="MilestoneStepper-Step"
            style={{ left: `${position}%` }}
            key={item.key}
          >
            <Step
              icon={item.icon}
              active={activeIdx >= idx}
              isLeading={idx === activeIdx}
              isLeadingStepActivated={isLeadingStepActivated}
            />
          </div>
        );
      })}
    </div>
  );
}

function AlignedTexts({ steps, children, className }) {
  return (
    <div className={classNames('MilestoneStepper-AlignedTexts', className)}>
      {steps.map(step => (
        <div
          key={step.item.key}
          style={{ left: `${step.position}%` }}
          className="MilestoneStepper-Position"
        >
          <div className="MilestoneStepper-Alignment">{children(step)}</div>
        </div>
      ))}
    </div>
  );
}

export default function MilestoneStepper({
  style,
  dataSource,
  activeStep,
  isLeadingStepActivated = true,
  className,
}) {
  const steps = computePositions(dataSource);
  return (
    <div style={style} className={classNames('MilestoneStepper', className)}>
      <div className="MilestoneStepper-container">
        <AlignedTexts steps={steps} className="MilestoneStepper-Titles">
          {step => <span>{step.item.title}</span>}
        </AlignedTexts>
        <AlignedTexts steps={steps} className="MilestoneStepper-Subtitles">
          {step => <span>{step.item.subtitle}</span>}
        </AlignedTexts>
        <div className="MilestoneStepper-TimelineSection">
          <MilestoneTimeline
            steps={steps}
            activeStep={activeStep}
            isLeadingStepActivated={isLeadingStepActivated}
          />
        </div>
        <AlignedTexts steps={steps} className="MilestoneStepper-Descriptions">
          {step => <span>{step.item.description}</span>}
        </AlignedTexts>
      </div>
    </div>
  );
}
