import { gql } from '@apollo/client';

import { accountInfoFragment } from './accountQueries';

// Full query is always used so that cache capabilities of Apollo were used
export const USER_QUERY = gql`
  query User {
    currentUser {
      id
      email
      firstName
      lastName
      accounts {${accountInfoFragment}}
      partnerGroups {
        code
        name
        accounts {${accountInfoFragment}}
      }
      accountGroups {
        code
        name
        accounts {${accountInfoFragment}}
      }
      settings {
        unitSystem
        dateFormat
      }
      isAdmin
      emailNotificationsEnabled
      reportsEnabled
    }
  }
`;

export const RELEASE_NOTES_QUERY = gql`
  query ReleaseNotes {
    releaseNotes
  }
`;

export const ACCEPT_TERMS_OF_SERVICE_MUTATION = gql`
  mutation AcceptTermsOfService {
    acceptTermsOfService
  }
`;

export const CONFIRM_RELEASE_NOTES_MUTATION = gql`
  mutation ConfirmReleaseNotes {
    confirmReleaseNotes
  }
`;

export const LOGIN_MUTATION = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      success
      code
      errorText
      token
      termsOfService
      releaseNotes
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout {
      success
      code
    }
  }
`;

export const SIGNUP_TOKEN_VALIDATION_QUERY = gql`
  query ValidateSignupToken($token: String!, $email: String!) {
    validateSignupToken(token: $token, email: $email)
  }
`;

export const SIGNUP_MUTATION = gql`
  mutation Signup($input: NewPasswordInput!, $authToken: String!) {
    signup(input: $input, authToken: $authToken) {
      success
      messages {
        success
        code
        message
      }
    }
  }
`;

export const PASSWORD_CHANGE_VALIDATION_QUERY = gql`
  query ValidatePassword($password: String!, $authToken: String!) {
    validatePassword(password: $password, authToken: $authToken) {
      success
      messages {
        success
        code
        message
      }
    }
  }
`;

export const PASSWORD_RESET_TOKEN_VALIDATION_QUERY = gql`
  query ValidateResetPasswordToken($token: String!, $email: String!) {
    validateResetPasswordToken(token: $token, email: $email)
  }
`;

export const PASSWORD_RESET_INIT_MUTATION = gql`
  mutation InitPasswordReset($email: String!) {
    initPasswordReset(email: $email)
  }
`;

export const PASSWORD_CHANGE_MUTATION = gql`
  mutation ChangePassword($input: NewPasswordInput!, $authToken: String!) {
    changePassword(input: $input, authToken: $authToken) {
      success
      messages {
        success
        code
        message
      }
    }
  }
`;

export const CHANGE_USER_SETTINGS_MUTATION = gql`
  mutation ChangeUserSettings($input: UserSettingsInput!) {
    changeUserSettings(input: $input)
  }
`;

export const UPDATE_CURRENT_USER_SETTINGS = gql`
  fragment UpdateCurrentUserSettings on User {
    settings
  }
`;
