import { Col } from 'antd';
import { get } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import { EMPTY_VALUE, formatString } from '../../../common/utils/formatUtils';
import { LabelRow } from '../../../components/typography';
import {
  ShipmentSummaryCard,
  ShipmentSummaryCardSection,
  ShipmentSummaryDateTimeFormatWithPipe,
  TwoLineLabelWithValue,
} from './shipmentSummariesCommon';

export default function ServiceUpdateCard({ shipment }) {
  const phone = get(shipment, 'serviceUpdate.customerServicePhone');
  return (
    <ShipmentSummaryCard
      className="ServiceUpdateCard"
      title={
        <>
          <div>
            <FormattedMessage id="shipmentDetail.serviceUpdate" />
          </div>
          <div className="ServiceUpdateCard-Subtitle">
            <FormattedMessage id="shipmentDetail.quickCustomerService" />{' '}
            {phone ? <a href={`tel:${phone}`}>{phone}</a> : EMPTY_VALUE}
          </div>
        </>
      }
    >
      <ShipmentSummaryCardSection>
        <div className="ServiceUpdateCard-GroupTitle">
          <FormattedMessage id="shipmentDetail.serviceUpdate.estimatedArrival" />
        </div>
        <LabelRow>
          <Col>
            <TwoLineLabelWithValue
              labelId="shipmentDetail.serviceUpdate.new"
              text={
                <ShipmentSummaryDateTimeFormatWithPipe
                  value={get(shipment, 'serviceUpdate.newEstimatedArrival')}
                />
              }
            />
          </Col>
          <Col>
            <TwoLineLabelWithValue
              labelId="shipmentDetail.serviceUpdate.original"
              text={
                <ShipmentSummaryDateTimeFormatWithPipe
                  value={get(shipment, 'serviceUpdate.oldEstimatedArrival')}
                />
              }
            />
          </Col>
        </LabelRow>
        <LabelRow>
          <Col className="ServiceUpdateCard-Reason">
            <FormattedMessage id="shipmentDetail.serviceUpdate.reason" />:{' '}
            {formatString(get(shipment, 'serviceUpdate.reason'))}
          </Col>
        </LabelRow>
      </ShipmentSummaryCardSection>
    </ShipmentSummaryCard>
  );
}
