import { compact, isEqual, values as objValues, pickBy } from 'lodash-es';

import { isDateRangeEqual } from '../../common/utils/dateRangeUtils';
import { isFormValuePresent } from '../../components/forms/formHelpers';

function normalizeMonitorFilters(filters) {
  const dateRangeObj =
    filters?.dateRange?.from || filters?.dateRange?.to
      ? { dateRange: filters.dateRange }
      : {};
  return { ...pickBy(filters, isFormValuePresent), ...dateRangeObj };
}

export function areMonitorFiltersEqual(values1, values2) {
  const { dateRange: dateRange1, ...rest1 } = normalizeMonitorFilters(values1);
  const { dateRange: dateRange2, ...rest2 } = normalizeMonitorFilters(values2);

  const isRangeEqual = isDateRangeEqual(dateRange1, dateRange2);

  return isRangeEqual && isEqual(rest1, rest2);
}

export function getMonitorFiltersCount(values) {
  return objValues(normalizeMonitorFilters(values)).length || 0;
}

export function areMonitorFiltersEmpty(values) {
  return compact(objValues(normalizeMonitorFilters(values))).length === 0;
}
