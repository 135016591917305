import { Button, Col, Form, Row } from 'antd';
import classNames from 'classnames';
import { compact, keys, values as objValues, sumBy } from 'lodash-es';
import { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { useMountEffect } from '../../common/utils/hookUtils';
import { FiltersAndSortContext } from '../../components/data/FiltersAndSortProvider';
import {
  DialogsParentContext,
  DialogsParentProvider,
} from '../../components/dialogs/DialogsParentContext';
import { NoLabelForm, useFormContext } from '../../components/forms/forms';
import { ConfigurableCard } from '../../components/layout/cardElements';
import { LinksRow } from '../../components/nav/navElements';
import { useResponsiveQueries } from '../../components/responsive/responsiveQueries';
import { InlineLink } from '../../components/typography';
import { cssVariables } from '../../styles/cssVariables';
import { pxToNumber } from '../../utils/cssUtils';
import { withMergedClass } from '../../utils/reactUtils';
import {
  FreeTextJobSearch,
  FreeTextJobSearchContext,
  FreeTextJobSearchIcon,
} from '../../widgets/FreeTextJobSearch';
import {
  AccountMonitorFilter,
  AccountMonitorFilterTag,
  DateRangeMonitorFilter,
  DateRangeMonitorFilterTag,
  MonitorFiltersRegistration,
  ShipmentDetailsMonitorFilter,
  ShipmentDetailsMonitorFilterTag,
  StatusMonitorFilter,
  StatusMonitorFilterTag,
} from './MonitorFilter';
import { MonitorContext } from './monitorContexts';
import {
  FilterSetsListPopup,
  SaveFilterSetPopup,
  useMonitorFilterTemplatesActiveItem,
} from './monitorFilterTemplates';
import {
  areMonitorFiltersEmpty,
  areMonitorFiltersEqual,
  getMonitorFiltersCount,
} from './monitorFilterUtils';

const FilterCol = withMergedClass('MonitorSearchFilters-Column')(Col);

function FiltersHeaderExtra() {
  const {
    filters: { values: filters },
  } = useContext(FiltersAndSortContext);
  const { values } = useFormContext();

  const { activeItem, setActiveItem, afterSave } =
    useMonitorFilterTemplatesActiveItem();

  const isFilterEmpty = areMonitorFiltersEmpty(filters);
  const isFilterChanged = !areMonitorFiltersEqual(filters, values);
  const isFilterSetActive = !!activeItem;

  let disabledReasonId;
  if (isFilterEmpty) {
    disabledReasonId = 'filters.saveSetDisabledReason.empty';
  } else if (isFilterChanged) {
    disabledReasonId = 'filters.saveSetDisabledReason.changed';
  } else if (isFilterSetActive) {
    disabledReasonId = 'filters.saveSetDisabledReason.filterSetActive';
  }

  return (
    <LinksRow
      className="big-spaces"
      links={[
        <FilterSetsListPopup
          key="filterSets"
          activeItem={activeItem}
          setActiveItem={setActiveItem}
        />,
        <SaveFilterSetPopup
          key="saveSet"
          disabled={!!disabledReasonId}
          disabledReasonId={disabledReasonId}
          afterSave={afterSave}
        />,
      ]}
    />
  );
}

function FiltersFooter() {
  const { values, formInstance, forceUpdate } = useFormContext();
  const {
    filters: {
      values: filters,
      setValues: setFilters,
      initialValues: initialFilters,
    },
  } = useContext(FiltersAndSortContext);

  const numFilters = getMonitorFiltersCount(filters);
  const { data } = useContext(MonitorContext);
  const totalRows = sumBy(compact(objValues(data)), 'totalRows');

  const isClearAllowed =
    !areMonitorFiltersEqual(initialFilters, values) ||
    !areMonitorFiltersEqual(initialFilters, filters);
  const isFilterChanged = !areMonitorFiltersEqual(filters, values);

  return (
    <Row className="MonitorSearchFilters-Footer" align="middle">
      <Col className="MonitorSearchFilters-CurrentState">
        <span className="MonitorSearchFilters-CurrentStateBadge">
          {numFilters}
        </span>
        <span>
          <FormattedMessage
            id="filters.numberShown"
            values={{ numRows: totalRows }}
          />
        </span>
      </Col>
      <Col className="MonitorSearchFilters-Tags">
        <DateRangeMonitorFilterTag />
        <AccountMonitorFilterTag />
        <StatusMonitorFilterTag />
        <ShipmentDetailsMonitorFilterTag />
      </Col>
      <Col className="MonitorSearchFilters-Buttons">
        <InlineLink
          className={classNames({ disabled: !isClearAllowed })}
          textId="filters.buttons.clear"
          onClick={() => {
            formInstance.resetFields();
            forceUpdate();
            setFilters(initialFilters);
          }}
        />
        <Button
          type="primary"
          onClick={() => setFilters(values)}
          disabled={!isFilterChanged}
          className="width-110"
        >
          <FormattedMessage id="filters.buttons.update" />
        </Button>
      </Col>
    </Row>
  );
}

function ContentLarge() {
  const { isDialogOpen } = useContext(DialogsParentContext);
  return (
    <ConfigurableCard
      className={classNames('MonitorSearchFilters', {
        'has-dialog': isDialogOpen,
      })}
      titleId="labels.filters"
      headerExtra={<FiltersHeaderExtra />}
      footer={<FiltersFooter />}
    >
      <FilterCol>
        <DateRangeMonitorFilter />
      </FilterCol>
      <FilterCol>
        <AccountMonitorFilter />
      </FilterCol>
      <FilterCol>
        <StatusMonitorFilter />
      </FilterCol>
      <FilterCol className="hide-lg-and-smaller">
        <ShipmentDetailsMonitorFilter />
      </FilterCol>
    </ConfigurableCard>
  );
}

function ContentSmall() {
  const { values } = useFormContext();
  const {
    filters: { values: filters, setValues: setFilters },
  } = useContext(FiltersAndSortContext);
  const media = useResponsiveQueries();
  const isSm = media.xs || media.sm || media.md;

  const { searchActive } = useContext(FreeTextJobSearchContext);
  const colProps =
    searchActive && !media.md ? { style: { display: 'none' } } : {};
  const isFilterChanged = !areMonitorFiltersEqual(filters, values);
  // There seems to be one render tick where values are an empty object when screen is resized
  const isFormInitialized = keys(values).length > 0;

  const { activeItem, setActiveItem } = useMonitorFilterTemplatesActiveItem();

  // Filters will be automatically updated on every change
  useEffect(() => {
    if (isSm && isFilterChanged && isFormInitialized) {
      setFilters(values);
    }
  }, [isFilterChanged, isFormInitialized, isSm, setFilters, values]);

  return (
    <Row
      className="MonitorSearchFilters"
      align="middle"
      justify="space-between"
    >
      <FilterCol {...colProps}>
        <DateRangeMonitorFilter />
      </FilterCol>
      <Col {...colProps}>
        <Row align="middle" gutter={pxToNumber(cssVariables.spaceNorm)}>
          <Col className="hide-xs-and-smaller">
            <FilterSetsListPopup
              activeItem={activeItem}
              setActiveItem={setActiveItem}
            />
          </Col>
          <Col className="hide-md-and-bigger flex-center">
            <FreeTextJobSearchIcon />
          </Col>
        </Row>
      </Col>
      {searchActive && (
        <Col className="hide-md-and-bigger SearchCol">
          <FreeTextJobSearch />
        </Col>
      )}
    </Row>
  );
}

function RemountValuesPersister() {
  const {
    filters: { values },
  } = useContext(FiltersAndSortContext);
  const { formInstance, forceUpdate } = useFormContext();

  const isRemount = !areMonitorFiltersEmpty(values);
  useMountEffect(() => {
    if (isRemount) {
      formInstance.setFieldsValue(values);
      forceUpdate();
    }
  });

  return null;
}

export default function MonitorFilters() {
  const [form] = Form.useForm();
  const {
    filters: { initialValues },
  } = useContext(FiltersAndSortContext);

  return (
    <DialogsParentProvider>
      <NoLabelForm
        form={form}
        className="no-margin-form-items"
        initialValues={initialValues}
      >
        <MonitorFiltersRegistration />
        <RemountValuesPersister />
        <div className="hide-md-and-smaller">
          <ContentLarge />
        </div>
        <div className="hide-lg-and-bigger">
          <ContentSmall />
        </div>
      </NoLabelForm>
    </DialogsParentProvider>
  );
}
