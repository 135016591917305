import { flatMap, isNumber } from 'lodash-es';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';

import { DRAWER_MEDIA_MAPPER } from '../../components/drawers/drawerCommon';
import { InnerContentScrollbars } from '../../components/layout/InnerContentScrollbars';
import { VerticalTrackTypes } from '../../components/layout/Scrollbars';
import { useResponsiveQueries } from '../../components/responsive/responsiveQueries';
import {
  AddressBookListEntry,
  AddressBookListSection,
} from './AddressBookListItem';

const SECTION = 'SECTION';
const ITEM = 'ITEM';

const ITEM_SIZES = {
  [SECTION]: 45,
  [ITEM]: 100,
};

const SM_ITEM_SIZES = {
  [SECTION]: 45,
  [ITEM]: 140,
};

function AddressRow({ index, style, data }) {
  const listItem = data.items[index];
  if (listItem.type === SECTION) {
    return (
      <div style={style}>
        <AddressBookListSection header={listItem.title} />
      </div>
    );
  }
  return (
    <div style={style}>
      <AddressBookListEntry
        index={listItem.item.index}
        active={data.active?.id === listItem.item.data.id}
        data={listItem.item.data}
        onEdit={() => data.onEdit(listItem.item.data)}
        onApply={data.onApply}
        onDelete={data.onDelete}
        removeDialogClassName={data.removeDialogClassName}
        removeText={data.removeText}
        removeTextId={data.removeTextId}
        removeSuccessMessageId={data.removeSuccessMessageId}
        readOnly={data.readOnly}
      />
    </div>
  );
}

const CustomScrollbars = React.forwardRef(
  ({ onScroll, style, children }, ref) => (
    <InnerContentScrollbars
      mediaMapper={DRAWER_MEDIA_MAPPER}
      onScroll={onScroll}
      style={{ ...style, overflow: 'hidden' }}
      scrollbarsRef={ref}
      vertical={VerticalTrackTypes.ABOVE_CONTENT}
      spaceOutside={false}
    >
      {children}
    </InnerContentScrollbars>
  )
);

function findCurrentSection({ items, visibleStartIndex }) {
  if (!isNumber(visibleStartIndex)) {
    return null;
  }
  if (items[visibleStartIndex]?.type === SECTION) {
    return items[visibleStartIndex];
  }
  for (let i = visibleStartIndex; i >= 0; i--) {
    if (items[i]?.type === SECTION) {
      return items[i];
    }
  }
  return null;
}

function FixedSectionHeader({ items, visibleStartIndex }) {
  const section = findCurrentSection({ items, visibleStartIndex });
  if (!section) {
    return null;
  }

  return (
    <div className="AddressBookList-FixedSection">
      <AddressBookListSection header={section.title} />
    </div>
  );
}

export const AddressBookList = React.forwardRef(function AddressBookList(
  {
    sections,
    active,
    onEdit,
    onApply,
    onDelete,
    removeDialogClassName,
    removeText,
    removeTextId,
    removeSuccessMessageId,
    readOnly,
  },
  ref
) {
  const listRef = useRef();
  const media = DRAWER_MEDIA_MAPPER(useResponsiveQueries());
  const isSm = media.xs || media.sm;
  const itemSizes = isSm ? SM_ITEM_SIZES : ITEM_SIZES;
  const [visibleStartIndex, setVisibleStartIndex] = useState();
  const onItemsRendered = useCallback(
    e => setVisibleStartIndex(e.visibleStartIndex),
    []
  );

  const items = useMemo(
    () =>
      flatMap(sections, ([section, addresses]) => [
        { type: SECTION, title: section },
        ...addresses.map(item => ({ type: ITEM, item })),
      ]),
    [sections]
  );
  useImperativeHandle(ref, () => ({
    scrollToLetter: letter => {
      const idx = items.findIndex(
        item => item.type === SECTION && item.title.toLowerCase() === letter
      );
      listRef.current.scrollToItem(idx, 'start');
    },
  }));
  useEffect(() => {
    // scroll to active item
    const idx = items.findIndex(
      item => item.type === ITEM && item.item.data.id === active?.id
    );
    if (listRef.current && idx > -1) {
      listRef.current.scrollToItem(idx, 'auto');
    }
  }, [active?.id, items]);
  useEffect(() => {
    // recompute item size after adding/removing items
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, [items.length]);

  const data = {
    items,
    active,
    onEdit,
    onApply,
    onDelete,
    removeDialogClassName,
    removeText,
    removeTextId,
    removeSuccessMessageId,
    readOnly,
  };

  return (
    <>
      <AutoSizer
        className="AddressBookList-Container"
        style={{ width: '100%' }}
      >
        {({ width, height }) => (
          <VariableSizeList
            ref={listRef}
            width={width}
            height={height}
            itemData={data}
            itemCount={items.length}
            estimatedItemSize={itemSizes[ITEM]}
            itemSize={index => itemSizes[items[index].type]}
            outerElementType={CustomScrollbars}
            onItemsRendered={onItemsRendered}
          >
            {AddressRow}
          </VariableSizeList>
        )}
      </AutoSizer>
      <FixedSectionHeader visibleStartIndex={visibleStartIndex} items={items} />
    </>
  );
});
