import classNames from 'classnames';
import { useContext, useRef } from 'react';

import { useMountEffect } from '../../common/utils/hookUtils';
import { usePeriodicCheck } from '../../utils/reactUtils';
import { ScrollbarsContext } from '../layout/Scrollbars';

export default function StickyElement({
  minTop = 0,
  className,
  style: outerStyle,
  children,
  ...rest
}) {
  const { scrollTop } = useContext(ScrollbarsContext);
  const containerRef = useRef();
  const innerRef = useRef();
  const rect = useRef();

  const computeRect = () => {
    if (innerRef.current) {
      rect.current = innerRef.current.getBoundingClientRect();
    }
  };
  useMountEffect(computeRect);
  usePeriodicCheck(computeRect);

  const active =
    containerRef.current && scrollTop - containerRef.current.offsetTop > minTop;
  const innerStyle = active
    ? {
        top:
          containerRef.current.offsetParent.getBoundingClientRect().top +
          minTop,
        left: rect.current.left,
        width: rect.current.width,
        height: rect.current.height,
      }
    : {};
  const style = {
    ...outerStyle,
    ...(active ? { height: rect.current.height } : {}),
  };

  return (
    <div
      ref={containerRef}
      className={classNames('StickyElement', { active }, className)}
      style={style}
      {...rest}
    >
      <div ref={innerRef} className="StickyElement-Inner" style={innerStyle}>
        <div className="StickyElement-Content">{children}</div>
      </div>
    </div>
  );
}
