import { Button, Col, Form, Row } from 'antd';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import classNames from 'classnames';
import { isObject } from 'lodash-es';
import React, { useContext, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { cssVariables } from '../../../styles/cssVariables';
import { msToNumber } from '../../../utils/cssUtils';
import { FAIcon, FATypes } from '../../adapters/fontAwesomeAdapters';
import { AsyncCallbackButton } from '../../data/asyncActionElements';
import { DialogsParentContext } from '../../dialogs/DialogsParentContext';
import { useFlashMessageContext } from '../../dialogs/FlashMessageProvider';
import { SimpleConfirmDialogButton } from '../../dialogs/SimpleConfirmDialog';
import NewShipmentDrawer from '../../drawers/NewShipmentDrawer';
import { FormItemsOverrideContextProvider } from '../../forms/FormItem';
import { NoLabelForm } from '../../forms/forms';
import { InnerContentScrollbars } from '../../layout/InnerContentScrollbars';
import { FlexCol, TwoLines } from '../../layout/layoutElements';
import { InlineLink } from '../../typography';

export function TemplateListDeleteButton({
  onDelete,
  removeDialogClassName,
  removeText,
  removeTextId,
  removeSuccessMessageId,
  children,
}) {
  const { errorMessage, successMessage } = useFlashMessageContext();
  return (
    <SimpleConfirmDialogButton
      className={removeDialogClassName}
      cancelTextId="buttons.cancel"
      okTextId="buttons.delete"
      onOk={async () => {
        try {
          await onDelete();
          successMessage({
            contentId: removeSuccessMessageId,
          });
        } catch (e) {
          errorMessage(e);
        }
      }}
      text={removeText}
      textId={removeTextId}
    >
      {children}
    </SimpleConfirmDialogButton>
  );
}

function TemplateDrawerForm({
  className,
  titleId,
  initialValues,
  viewMode,
  onCancel,
  onSave,
  onEdit,
  onSaveAs,
  onDelete,
  successMessageId,
  form,
  formName,
  removeDialogClassName,
  removeText,
  removeTextId,
  removeSuccessMessageId,
  formComponent: C,
  formComponentProps,
  readOnly,
}) {
  const { errorMessage, successMessage } = useFlashMessageContext();
  const { isDialogOpen } = useContext(DialogsParentContext);
  const formRef = useRef();

  return (
    <div
      className={classNames(
        'TemplateDrawerForm',
        { 'has-dialog': isDialogOpen },
        className
      )}
    >
      <FlexCol className="TemplateDrawerForm-Content">
        {titleId && (
          <div className="TemplateDrawerForm-Title">
            <FormattedMessage id={titleId} />
          </div>
        )}
        <Row
          className="TemplateDrawerForm-Controls"
          align="middle"
          justify="space-between"
        >
          <Col>
            {onDelete && !readOnly && (
              <TemplateListDeleteButton
                onDelete={onDelete}
                removeDialogClassName={removeDialogClassName}
                removeText={removeText}
                removeTextId={removeTextId}
                removeSuccessMessageId={removeSuccessMessageId}
              >
                {({ open }) => (
                  <InlineLink
                    textId="buttons.delete"
                    iconBefore={<FAIcon icon="trash-alt" />}
                    onClick={open}
                  />
                )}
              </TemplateListDeleteButton>
            )}
          </Col>
          <Col>
            <InlineLink textId="buttons.cancel" onClick={onCancel} />
            {onSaveAs && !readOnly && (
              <Button className="width-90" ghost onClick={onSaveAs}>
                <span>
                  <FormattedMessage id="buttons.saveAs" />
                </span>
                <FAIcon icon="copy" type={FATypes.REGULAR} />
              </Button>
            )}
            {onEdit && !readOnly && (
              <Button
                className="width-70"
                type="primary"
                ghost
                onClick={onEdit}
              >
                <span>
                  <FormattedMessage id="buttons.edit" />
                </span>
                <FAIcon icon="edit" />
              </Button>
            )}
            {!viewMode && (
              <AsyncCallbackButton
                className="width-100"
                type="primary"
                callback={async () => {
                  await formRef.current.customSubmit();
                  try {
                    await onSave(form.getFieldsValue());
                    successMessage({ contentId: successMessageId });
                  } catch (e) {
                    errorMessage(e);
                  }
                }}
                loadingTextId="buttons.saving"
              >
                <FormattedMessage id="buttons.save" />
                <FAIcon icon="save" />
              </AsyncCallbackButton>
            )}
          </Col>
        </Row>
        <TwoLines className="size-6" />
        <FlexCol className="Flex1">
          <InnerContentScrollbars>
            <NoLabelForm
              component={false}
              form={form}
              formRef={formRef}
              initialValues={initialValues}
              name={formName}
            >
              <div className="margins-bottom-norm height-extending-error">
                <C {...formComponentProps} />
              </div>
            </NoLabelForm>
          </InnerContentScrollbars>
        </FlexCol>
      </FlexCol>
    </div>
  );
}

function TemplatesFormDrawerViewOrEditContent({
  entry,
  onEdit,
  onSaveAs,
  getInitialValues,
  onSave: onSaveOuter,
  onDelete: onDeleteOuter,
  formClassName,
  titleId,
  successMessageId,
  formName,
  removeDialogClassName,
  removeText,
  removeTextId,
  removeSuccessMessageId,
  formComponent,
  formComponentProps,
  viewMode,
  readOnly,
  ...rest
}) {
  const [form] = Form.useForm();
  const initialValues = getInitialValues && getInitialValues(entry);
  const onSave = vals => onSaveOuter(entry.id, vals);
  const onDelete = () => onDeleteOuter(entry.id);

  return (
    <FormItemsOverrideContextProvider viewMode={viewMode}>
      <TemplateDrawerForm
        className={formClassName}
        form={form}
        titleId={titleId}
        initialValues={initialValues}
        viewMode={viewMode}
        onSave={onSave}
        onEdit={onEdit}
        onSaveAs={onSaveAs}
        onDelete={onDelete}
        successMessageId={successMessageId}
        formName={formName}
        removeDialogClassName={removeDialogClassName}
        removeText={removeText}
        removeTextId={removeTextId}
        removeSuccessMessageId={removeSuccessMessageId}
        formComponent={formComponent}
        formComponentProps={formComponentProps}
        readOnly={readOnly}
        {...rest}
      />
    </FormItemsOverrideContextProvider>
  );
}

function TemplatesFormDrawerAddContent({
  onSave: onSaveOuter,
  getInitialValues,
  formClassName,
  titleId,
  successMessageId,
  formName,
  formComponent,
  formComponentProps,
  ...rest
}) {
  const [form] = Form.useForm();
  const initialValues = getInitialValues && getInitialValues();

  const onSave = vals => onSaveOuter(null, vals);

  return (
    <TemplateDrawerForm
      className={formClassName}
      form={form}
      titleId={titleId}
      initialValues={initialValues}
      onSave={onSave}
      successMessageId={successMessageId}
      formName={formName}
      formComponent={formComponent}
      formComponentProps={formComponentProps}
      {...rest}
    />
  );
}

export default function TemplatesFormDrawer({
  data,
  setData,
  onClose,
  titleId,
  headerRightContent,
  onCancel,
  getCreateInitialValues,
  onSave,
  onDelete,
  getEditInitialValues,
  formClassName,
  formName,
  formComponent,
  formComponentProps,
  createTitleId,
  createSuccessMessageId,
  editTitleId,
  editSuccessMessageId,
  removeDialogClassName,
  removeText,
  removeTextId,
  removeSuccessMessageId,
  allowSaveAs,
  children,
  readOnly,
  ...rest
}) {
  const editingOrViewing = data?.editing || data?.viewing;
  const viewMode = data?.viewing || readOnly;

  return (
    <NewShipmentDrawer
      header={<FormattedMessage id={titleId} />}
      headerRightContent={headerRightContent}
      onClose={onClose}
      {...rest}
    >
      <ErrorBoundary>
        {children}
        <TransitionGroup component={null}>
          {(editingOrViewing ||
            isObject(data?.adding)) /* This is here for animation */ && (
            <CSSTransition
              key="editing"
              timeout={msToNumber(cssVariables.animationDurationMd)}
              classNames="from-bottom"
            >
              <TemplatesFormDrawerViewOrEditContent
                entry={editingOrViewing || data?.adding}
                onCancel={onCancel || onClose}
                onEdit={viewMode && (() => setData({ editing: data?.viewing }))}
                onSaveAs={
                  allowSaveAs &&
                  data?.editing &&
                  (() => setData({ adding: data?.editing }))
                }
                getInitialValues={getEditInitialValues}
                onSave={onSave}
                onDelete={onDelete}
                formClassName={formClassName}
                titleId={editTitleId}
                successMessageId={editSuccessMessageId}
                formName={formName}
                removeDialogClassName={removeDialogClassName}
                removeText={removeText}
                removeTextId={removeTextId}
                removeSuccessMessageId={removeSuccessMessageId}
                formComponent={formComponent}
                formComponentProps={formComponentProps}
                viewMode={viewMode}
                readOnly={readOnly}
              />
            </CSSTransition>
          )}
          {data?.adding && (
            <CSSTransition
              key="adding"
              timeout={msToNumber(cssVariables.animationDurationMd)}
              classNames="from-bottom"
            >
              <TemplatesFormDrawerAddContent
                onCancel={onCancel || onClose}
                getInitialValues={getCreateInitialValues}
                onSave={onSave}
                formClassName={formClassName}
                titleId={createTitleId}
                successMessageId={createSuccessMessageId}
                formName={formName}
                formComponent={formComponent}
                formComponentProps={formComponentProps}
              />
            </CSSTransition>
          )}
        </TransitionGroup>
      </ErrorBoundary>
    </NewShipmentDrawer>
  );
}
