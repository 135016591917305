import { Record } from 'immutable';

import { simpleAction } from '../../common/utils/reduxUtils';

const InitialState = Record({
  usersFilter: {},
  accountsFilter: {},
});
const INITIAL_STATE = InitialState();

const SET_USERS_FILTER = 'ADMIN/SET_USERS_FILTER';
const SET_ACCOUNTS_FILTER = 'ADMIN/SET_ACCOUNTS_FILTER';

export function adminReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_USERS_FILTER:
      return state.set('usersFilter', action.filter);
    case SET_ACCOUNTS_FILTER:
      return state.set('accountsFilter', action.filter);
    default:
      return state;
  }
}

export const getUsersFilter = state => state.admin.usersFilter;
export const getAccountsFilter = state => state.admin.accountsFilter;

export const setUsersFilter = simpleAction(SET_USERS_FILTER, 'filter');
export const setAccountsFilter = simpleAction(SET_ACCOUNTS_FILTER, 'filter');
