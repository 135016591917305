import { useContext } from 'react';
import { useMedia } from 'react-media';

import { cssVariables } from '../../styles/cssVariables';
import { pxToNumber } from '../../utils/cssUtils';
import ResponsiveOverrideContext from './ResponsiveOverrideContext';

const MEDIA_QUERIES = {
  xs: `(max-width: ${pxToNumber(cssVariables['screen-sm']) - 1}px)`,
  sm: `(min-width: ${cssVariables['screen-sm']}) and (max-width: ${
    pxToNumber(cssVariables['screen-md']) - 1
  }px)`,
  md: `(min-width: ${cssVariables['screen-md']}) and (max-width: ${
    pxToNumber(cssVariables['screen-lg']) - 1
  }px)`,
  lg: `(min-width: ${cssVariables['screen-lg']}) and (max-width: ${
    pxToNumber(cssVariables['screen-xl']) - 1
  }px)`,
  xl: `(min-width: ${cssVariables['screen-xl']}) and (max-width: ${
    pxToNumber(cssVariables['screen-xxl']) - 1
  }px)`,
  xxl: `(min-width: ${cssVariables['screen-xxl']})`,
};

export function useResponsiveQueries() {
  const { mapper } = useContext(ResponsiveOverrideContext);

  const origMedia = useMedia({ queries: MEDIA_QUERIES });
  return mapper(origMedia);
}
