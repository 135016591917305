import { Col } from 'antd';
import { get, isEmpty } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import {
  DeviceAddonType,
  getDeviceAddonLabel,
} from '../../../app/data/deviceAddonConversions';
import { ShipmentStage } from '../../../app/enums/ShipmentStage';
import { WeightUnit } from '../../../app/enums/measuringUnits';
import { useReduxDialogControls } from '../../../common/utils/dialogUtils';
import { formatCodeValue } from '../../../common/utils/formatUtils';
import Whitespace from '../../../components/Whitespace';
import IconButton from '../../../components/buttons/IconButton';
import {
  LengthFormat,
  NumericDurationFormat,
  WeightFormat,
} from '../../../components/domainSpecific/measuringUnitsElements';
import { LabelRow } from '../../../components/typography';
import { ShipmentDateTimeFormat } from '../../../forms/shipments/shipmentCommon';
import {
  isAviationMode,
  isBiomedLikeMode,
  isGenericMode,
} from '../../../forms/shipments/shipmentModes';
import {
  ShipmentSensorWidgetsDrawer,
  useShipmentPackageSensorData,
} from '../ShipmentSensorWidgets';
import { formatShipmentActualWeight } from '../shipmentFormats';
import { ShipmentPackagePieceListButton } from './ShipmentPackagePieceList';
import {
  ShipmentDeviceAddon,
  ShipmentSummaryCard,
  ShipmentSummaryCardSection,
  ShipmentSummaryLabelWithValue,
} from './shipmentSummariesCommon';

/**
 * NOTE: Different fields are displayed for AVIATION and BIOMED-LIKE accounts
 *   - BIOMED-LIKE only fields: commodityTemperature, packageQualificationTime, packoutDateTime, coolantAddon
 *   - AVIATION only fields: pieces
 */
function PackageCard({
  package: pkg,
  packageIndex,
  isAviation,
  isBiomedLike,
  isGeneric,
  jobNumber,
  shipment,
}) {
  const pieceListDrawer = useReduxDialogControls({
    id: 'shipmentDetail.pieceList',
    spec: { packageIndex },
  });
  const packageSensorUrlDrawer = useReduxDialogControls({
    id: 'shipmentDetail.sensorUrl',
    spec: { packageIndex },
  });

  const deviceAddons = [
    ...(pkg?.gpsDevices || []).map((device, i) => ({
      type: DeviceAddonType.GPS,
      index: i,
      ...device,
    })),
    ...(pkg?.temperatureDevices || []).map((device, i) => ({
      type: DeviceAddonType.TEMPERATURE,
      index: i,
      ...device,
    })),
  ].filter(val => !!val);
  const pieces = pkg?.pieces || [];

  const addonData = useShipmentPackageSensorData({
    jobNumber,
    packageIndex,
    skip: shipment.stage === ShipmentStage.SAVED,
  });

  return (
    <ShipmentSummaryCard
      title={
        <FormattedMessage
          id="shipmentDetail.packageTitle"
          values={{ number: packageIndex + 1 }}
        />
      }
      collapsible
      headerExtraContent={
        <ShipmentPackagePieceListButton
          {...pieceListDrawer}
          data={pieces}
          isAviation={isAviation}
          isBiomedLike={isBiomedLike}
          isGeneric={isGeneric}
        />
      }
    >
      <ShipmentSummaryCardSection headerId="shipmentDetail.packageDescription">
        <LabelRow>
          <Col>
            <ShipmentSummaryLabelWithValue
              labelSize="full"
              labelId="book.newShipment.label.packageSource"
              text={formatCodeValue(pkg?.packageSource)}
            />
          </Col>
          <Col>
            <ShipmentSummaryLabelWithValue
              labelSize="full"
              labelId="book.newShipment.label.packagingType"
              text={formatCodeValue(pkg?.packagingType)}
            />
            {pkg.sensorUrl && (
              <IconButton
                icon="external-link-square-alt"
                className="ShipmentDetailExternalLink icon-16"
                onClick={() => window.open(pkg.sensorUrl)}
              />
            )}
          </Col>
        </LabelRow>
        {isBiomedLike && (
          <>
            <LabelRow noBorder>
              <Col>
                <ShipmentSummaryLabelWithValue
                  labelSize="full"
                  labelId="book.newShipment.label.commodityTemperature"
                  text={formatCodeValue(get(pkg, 'commodityTemperature'))}
                />
              </Col>
              <Col>
                <ShipmentSummaryLabelWithValue
                  labelSize="full"
                  labelId="book.newShipment.label.packageQualificationTime"
                  text={
                    <NumericDurationFormat
                      value={get(pkg, 'packageQualificationTime')}
                    />
                  }
                />
              </Col>
            </LabelRow>
            <LabelRow>
              <Col>
                <ShipmentSummaryLabelWithValue
                  labelSize="full"
                  labelId="book.newShipment.label.packoutDateTime"
                  text={
                    <ShipmentDateTimeFormat
                      value={get(pkg, 'packoutDateTime')}
                    />
                  }
                />
              </Col>
              <Col>
                <ShipmentSummaryLabelWithValue
                  labelSize="full"
                  labelId="book.newShipment.quantityWeightSummary.billedWeight"
                  text={<WeightFormat value={pkg.billedWeight} />}
                />
              </Col>
            </LabelRow>
            <LabelRow noBorder>
              <Col>
                <ShipmentSummaryLabelWithValue
                  labelSize="full"
                  labelId="book.newShipment.label.actualWeight"
                  text={formatShipmentActualWeight(pkg)}
                />
              </Col>
              <Col>
                <ShipmentSummaryLabelWithValue
                  labelSize="full"
                  labelId="book.newShipment.label.length"
                  text={<LengthFormat value={get(pkg, 'length')} />}
                />
              </Col>
            </LabelRow>
            <LabelRow>
              <Col>
                <ShipmentSummaryLabelWithValue
                  labelSize="full"
                  labelId="book.newShipment.label.width"
                  text={<LengthFormat value={get(pkg, 'width')} />}
                />
              </Col>
              <Col>
                <ShipmentSummaryLabelWithValue
                  labelSize="full"
                  labelId="book.newShipment.label.height"
                  text={<LengthFormat value={get(pkg, 'height')} />}
                />
              </Col>
            </LabelRow>
          </>
        )}
        {(isAviation || isGeneric) && (
          <>
            <LabelRow>
              <Col>
                <ShipmentSummaryLabelWithValue
                  labelSize="full"
                  labelId="book.newShipment.label.actualWeight"
                  text={formatShipmentActualWeight(pkg)}
                />
              </Col>
              <Col>
                <ShipmentSummaryLabelWithValue
                  labelSize="full"
                  labelId="book.newShipment.quantityWeightSummary.billedWeight"
                  text={<WeightFormat value={pkg.billedWeight} />}
                />
              </Col>
            </LabelRow>
            <LabelRow noBorder>
              <Col>
                <ShipmentSummaryLabelWithValue
                  labelSize="full"
                  labelId="book.newShipment.label.length"
                  text={<LengthFormat value={get(pkg, 'length')} />}
                />
              </Col>
              <Col>
                <ShipmentSummaryLabelWithValue
                  labelSize="full"
                  labelId="book.newShipment.label.width"
                  text={<LengthFormat value={get(pkg, 'width')} />}
                />
              </Col>
            </LabelRow>
            <LabelRow>
              <Col>
                <ShipmentSummaryLabelWithValue
                  labelSize="full"
                  labelId="book.newShipment.label.height"
                  text={<LengthFormat value={get(pkg, 'height')} />}
                />
              </Col>
            </LabelRow>
          </>
        )}
      </ShipmentSummaryCardSection>
      {deviceAddons.length > 0 && (
        <ShipmentSummaryCardSection headerId="shipmentDetail.deviceAddon">
          <LabelRow>
            <Col>
              {deviceAddons.map((data, i) => (
                <ShipmentDeviceAddon
                  key={data.serialNumber || getDeviceAddonLabel(data)}
                  data={data}
                  packageIndex={packageIndex}
                  index={i}
                  jobNumber={jobNumber}
                />
              ))}
            </Col>
          </LabelRow>
        </ShipmentSummaryCardSection>
      )}
      {isBiomedLike && (
        <ShipmentSummaryCardSection headerId="shipmentDetail.coolantAddon">
          <LabelRow>
            <Col>
              <ShipmentSummaryLabelWithValue
                labelSize="full"
                labelId="book.newShipment.packages.coolantAddons"
                text={
                  <>
                    {formatCodeValue(get(pkg, 'coolantAddon.type'))}
                    <Whitespace />-<Whitespace />
                    <WeightFormat
                      value={get(pkg, 'coolantAddon.amount')}
                      unit={WeightUnit.KILOGRAM}
                    />
                  </>
                }
              />
            </Col>
            <Col>
              <ShipmentSummaryLabelWithValue
                labelSize="full"
                text={
                  <>
                    <FormattedMessage id="book.newShipment.label.replenish" />
                    :<Whitespace />
                    <NumericDurationFormat
                      value={get(pkg, 'coolantAddon.replenishTime')}
                    />
                  </>
                }
              />
            </Col>
          </LabelRow>
        </ShipmentSummaryCardSection>
      )}
      <ShipmentSensorWidgetsDrawer
        jobNumber={jobNumber}
        addonData={addonData}
        isOpen={packageSensorUrlDrawer.isOpen}
        onClose={packageSensorUrlDrawer.close}
      />
    </ShipmentSummaryCard>
  );
}

export function ShipmentPackageList({ shipment }) {
  const account = get(shipment, 'metadata.account');
  const isAviation = isAviationMode(account);
  const isBiomedLike = isBiomedLikeMode(account);
  const isGeneric = isGenericMode(account);

  return (
    <div className="margins-bottom-norm2">
      {(shipment.packagesInformation?.packages || []).map(
        (pkg, packageIndex) => (
          <PackageCard
            // eslint-disable-next-line react/no-array-index-key
            key={packageIndex}
            package={pkg}
            packageIndex={packageIndex}
            isAviation={isAviation}
            isBiomedLike={isBiomedLike}
            isGeneric={isGeneric}
            jobNumber={shipment.jobNumber}
            shipment={shipment}
          />
        )
      )}
      {shipment.stage === ShipmentStage.SAVED &&
        isEmpty(shipment.packagesInformation?.packages) && (
          <div className="padding-hor-norm">
            <FormattedMessage id="labels.tbd" />
          </div>
        )}
    </div>
  );
}
