import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import {
  JOB_TEMPLATES_QUERY,
  JOB_TEMPLATE_QUERY,
} from '../../../app/graphql/jobTemplateQueries';
import { useDynamicOriginalValueDependency } from '../../../components/forms/dynamic/dynamicFormDependencies';
import TemplatesListPopup from '../../../components/popups/templates/TemplatesListPopup';

function PopupLabel({ activeItem }) {
  return activeItem ? (
    <div
      className="ShipmentTemplatesListPopup-Name ellipsis text-no-transform"
      title={activeItem.name}
    >
      {activeItem.name}
    </div>
  ) : (
    <span data-subject="shipment-templates" data-action="open">
      <FormattedMessage id="shipmentTemplates.label" />
    </span>
  );
}

export default function ShipmentTemplatesListPopup({
  applyItem,
  view,
  activeItem,
  ...rest
}) {
  const accountNumber = useDynamicOriginalValueDependency('accountNumber');
  return (
    <TemplatesListPopup
      className={classNames('ShipmentTemplatesListPopup', {
        active: !!activeItem,
      })}
      detailQuery={JOB_TEMPLATE_QUERY}
      onOpen={view}
      columns={[{ name: 'name', titleId: 'shipmentTemplates.header.name' }]}
      label={<PopupLabel activeItem={activeItem} />}
      onApplyItem={applyItem}
      noItemsMessageId="shipmentTemplates.noItems"
      activeItem={activeItem}
      query={JOB_TEMPLATES_QUERY}
      queryOptions={{ variables: { accountNumber }, skip: !accountNumber }}
      {...rest}
    />
  );
}
