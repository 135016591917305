import {
  SortOrder,
  getSortOrder,
  isSortedBy,
  makeSort,
} from '../../app/common/sort';

export function useSortToggle({
  name,
  sort,
  setSort,
  iconNonActive = 'signal',
  iconAsc = 'sort-amount-up-alt',
  iconDesc = 'sort-amount-down-alt',
}) {
  // Progression: no-sort, DESC, ASC
  const active = isSortedBy(sort, name);
  let icon = iconNonActive;
  let onClick = () => setSort(makeSort(name, SortOrder.DESC));
  if (active) {
    if (getSortOrder(sort) === SortOrder.ASC) {
      icon = iconAsc;
      onClick = () => setSort(undefined);
    } else {
      icon = iconDesc;
      onClick = () => setSort(makeSort(name, SortOrder.ASC));
    }
  }

  return { active, icon, onClick };
}
