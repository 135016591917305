import classNames from 'classnames';
import { Fragment } from 'react';

import ErrorBoundary from '../components/ErrorBoundary';
import { InnerContentScrollbars } from '../components/layout/InnerContentScrollbars';
import {
  HorizontalTrackTypes,
  Scrollbars,
} from '../components/layout/Scrollbars';
import LoggedFooter from '../widgets/LoggedFooter';

export default function ModalPage({
  id,
  className,
  header,
  children,
  wrapInScrollbar,
}) {
  const Wrapper = wrapInScrollbar ? InnerContentScrollbars : Fragment;
  return (
    <Scrollbars
      id={id}
      className={classNames('ModalPage', className)}
      horizontal={HorizontalTrackTypes.BOTTOM}
    >
      <div className="ModalPage-Content">
        {header}
        <div className="ModalPage-InnerContent">
          <Wrapper>
            <ErrorBoundary messageId="error.render.page">
              {children}
            </ErrorBoundary>
          </Wrapper>
        </div>
        <LoggedFooter />
      </div>
    </Scrollbars>
  );
}
