import { Record } from 'immutable';

import { simpleAction } from '../../common/utils/reduxUtils';

const InitialState = Record({
  loggedIn: false,
  email: undefined,
  token: undefined,
  releaseNotes: undefined,
});
const INITIAL_STATE = InitialState();

const LOGIN = 'AUTH/LOGIN';
const LOGOUT = 'AUTH/LOGOUT';
const PREPARE_RELEASE_NOTES = 'AUTH/PREPARE_RELEASE_NOTES';
const CLOSE_RELEASE_NOTES = 'AUTH/CLOSE_RELEASE_NOTES';

export function authReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN:
      return state
        .set('loggedIn', true)
        .set('email', action.email)
        .set('token', action.token);
    case LOGOUT:
      return state.clear();
    case PREPARE_RELEASE_NOTES:
      return state.set('releaseNotes', action.content);
    case CLOSE_RELEASE_NOTES:
      return state.delete('releaseNotes');
    default:
      return state;
  }
}

export const getLoggedIn = state => state.auth.loggedIn;
export const getAuthEmail = state => state.auth.email;
export const getAuthToken = state => state.auth.token;
export const getReleaseNotes = state => state.auth.releaseNotes;

export const loginUser = simpleAction(LOGIN, 'email', 'token');

export function logoutUser() {
  return async (dispatch, getState, context) => {
    // This needs to be done before dispatch - otherwise it would be cleared after
    // rerender and all running queries would be stuck
    // `clearStore` is preferred to `resetStore` for logout scenario
    await context.apolloClient.clearStore();
    dispatch({ type: LOGOUT });
  };
}

export const prepareReleaseNotes = simpleAction(
  PREPARE_RELEASE_NOTES,
  'content'
);
export const closeReleaseNotes = simpleAction(CLOSE_RELEASE_NOTES, 'content');
