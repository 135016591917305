import { useEffect, useRef, useState } from 'react';

import { Scrollbars, VerticalTrackTypes } from './Scrollbars';

export default function AutoHeightScrollbars({ children, ...rest }) {
  const ref = useRef();
  const [height, setHeight] = useState();
  // Max height for custom scrollbars is only achievable by measuring the content height manually
  // Since groups are collapsible and collapse effect is animated, there is no particular value
  // that could be used as a change trigger, therefore we measure it in interval as a workaround
  useEffect(() => {
    const interval = setInterval(
      () => setHeight(ref.current?.scrollHeight || 0),
      100
    );
    return () => clearInterval(interval);
  }, []);

  return (
    <Scrollbars
      vertical={VerticalTrackTypes.ABOVE_CONTENT}
      style={{ height }}
      {...rest}
    >
      <div ref={ref}>{children}</div>
    </Scrollbars>
  );
}
