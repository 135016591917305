import { pick } from 'lodash-es';

export function extractAntdFormItemProps(props) {
  return pick(props, [
    'className',
    'colon',
    'dependencies',
    'extra',
    'getValueFromEvent',
    'hasFeedback',
    'help',
    'htmlFor',
    'noStyle',
    'label',
    'labelAlign',
    'labelCol',
    'name',
    'normalize',
    'required',
    'shouldUpdate',
    'trigger',
    'validateFirst',
    'validateStatus',
    'validateTrigger',
    'valuePropName',
    'wrapperCol',
  ]);
}
