import { useMutation } from '@apollo/client';
import { pick } from 'lodash-es';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { shipmentTemplateFormToInput } from '../../../app/data/shipmentTemplateConversions';
import {
  CREATE_SHIPMENT_TEMPLATE_MUTATION,
  DELETE_SHIPMENT_TEMPLATE_MUTATION,
  EDIT_SHIPMENT_TEMPLATE_MUTATION,
} from '../../../app/graphql/jobTemplateQueries';
import {
  deleteQueryFromCache,
  extractGraphqlEntity,
} from '../../../common/utils/graphqlUtils';
import { useDynamicOriginalValueDependency } from '../../../components/forms/dynamic/dynamicFormDependencies';
import { TwoLines } from '../../../components/layout/layoutElements';
import TemplatesFormDrawer from '../../../components/popups/templates/TemplatesFormDrawer';
import { SavedShipmentProvider } from '../newShipment/SavedShipmentProvider';
import useNewShipmentFormState from '../newShipment/useNewShipmentFormState';
import ShipmentTemplateFormContent from './ShipmentTemplateFormContent';

function useUpdateQueries() {
  const accountNumber = useDynamicOriginalValueDependency('accountNumber');
  return cache =>
    deleteQueryFromCache(
      cache,
      'customerShipmentTemplates',
      ({ args }) => args.accountNumber === accountNumber
    );
}

function useCreateTemplate() {
  const updateQueries = useUpdateQueries();
  const [mutateSave] = useMutation(CREATE_SHIPMENT_TEMPLATE_MUTATION, {
    update: updateQueries,
  });

  return async function create(input) {
    const { data } = await mutateSave({ variables: { input } });
    return extractGraphqlEntity(data);
  };
}

function useEditTemplate() {
  const updateQueries = useUpdateQueries();
  const [mutateEdit] = useMutation(EDIT_SHIPMENT_TEMPLATE_MUTATION, {
    update: updateQueries,
  });
  return async function edit(id, input) {
    const { data } = await mutateEdit({ variables: { id, input } });
    return extractGraphqlEntity(data);
  };
}

function useRemoveTemplate() {
  const updateQueries = useUpdateQueries();
  const [mutateRemove] = useMutation(DELETE_SHIPMENT_TEMPLATE_MUTATION, {
    update: updateQueries,
  });
  return async function remove(id) {
    await mutateRemove({ variables: { id } });
  };
}

export function ShipmentTemplateDrawer({
  data,
  setData,
  onClose,
  afterSave,
  afterDelete,
  values: shipmentValues,
  visible,
  readOnly,
}) {
  const { forms, refreshValues, account, setAccount, setUnitSystem, values } =
    useNewShipmentFormState();

  const accountNumber = useDynamicOriginalValueDependency('accountNumber');
  const getInput = vals =>
    shipmentTemplateFormToInput({
      ...values,
      name: data?.editing?.name,
      accountNumber,
      ...vals,
    });

  const createTemplate = useCreateTemplate();
  const editTemplate = useEditTemplate();
  const removeTemplate = useRemoveTemplate();

  const getEditInitialValues = entry => pick(entry, ['name']);

  return (
    <SavedShipmentProvider enabled={false}>
      <TemplatesFormDrawer
        data={data}
        setData={setData}
        onClose={onClose}
        readOnly={readOnly}
        titleId="shipmentTemplates.drawerTitle"
        onSave={async (id, formValues) => {
          const input = getInput(formValues);
          const response = id
            ? await editTemplate(id, input)
            : await createTemplate(input);
          onClose();
          afterSave({ ...input, id: response.id });
        }}
        allowSaveAs
        onDelete={async id => {
          await removeTemplate(id);
          onClose();
          afterDelete({ id });
        }}
        getEditInitialValues={getEditInitialValues}
        formClassName="ShipmentTemplateForm"
        formName="shipmentTemplateEntry"
        formComponent={ShipmentTemplateFormContent}
        formComponentProps={{
          initialValues: shipmentValues,
          data,
          forms,
          refreshValues,
          account,
          setAccount,
          setUnitSystem,
          values,
        }}
        createSuccessMessageId="shipmentTemplates.messages.save.success"
        editSuccessMessageId="shipmentTemplates.edit.success"
        removeDialogClassName="DeleteShipmentTemplateDialog size-sm"
        removeText={
          <>
            <div className="TemplateOperationWarning-Name">
              {data?.editing?.name}
            </div>
            <TwoLines className="size-6" />
            <div className="TemplateOperationWarning-Text">
              <FormattedMessage id="shipmentTemplates.delete.dialog.text" />
            </div>
          </>
        }
        removeSuccessMessageId="shipmentTemplates.delete.success"
        visible={visible}
        data-subject="shipment-templates"
        data-role="drawer"
      />
    </SavedShipmentProvider>
  );
}
