import { useQuery } from '@apollo/client';
import { Col, Row } from 'antd';
import { isFunction } from 'lodash-es';
import { useEffect, useState } from 'react';

import { JOB_TEMPLATES_QUERY } from '../../../app/graphql/jobTemplateQueries';
import { useReduxDialogControls } from '../../../common/utils/dialogUtils';
import { useDynamicOriginalValueDependency } from '../../../components/forms/dynamic/dynamicFormDependencies';
import { ConfigurableCard } from '../../../components/layout/cardElements';
import { cssVariables } from '../../../styles/cssVariables';
import ShipmentTemplatesChangedDialog from './ShipmentTemplateChangedDialog';
import { ShipmentTemplateDrawer } from './ShipmentTemplateDrawer';
import ShipmentTemplatesListPopup from './ShipmentTemplatesListPopup';
import {
  CancelShipmentTemplateButton,
  SaveShipmentTemplateButton,
} from './shipmentTemplateButtons';
import {
  useShipmentTemplatesActiveItem,
  useShipmentTemplatesControls,
} from './shipmentTemplateHooks';

function useEditableButtonSetting(account) {
  const { data: templatesData } = useQuery(JOB_TEMPLATES_QUERY, {
    variables: { accountNumber: account?.number },
    skip: !account,
  });

  return templatesData?.customerShipmentTemplates?.enableEditButton;
}

function DisabledWrapper({ disabled, children }) {
  return disabled ? (
    <div className="DisabledBlock DisabledBlock--35">{children}</div>
  ) : (
    children
  );
}

export default function ShipmentTemplatesCard({
  forms,
  values,
  afterApply,
  afterCancel,
  changeDetectionProps,
  onActiveItemChange,
  disabled,
}) {
  const account = useDynamicOriginalValueDependency('account');
  const { isOpen, open, close, data, setData } = useReduxDialogControls({
    id: 'newShipment.shipmentTemplate',
  });

  const { activeItem, setActiveItem, afterSave, afterDelete } =
    useShipmentTemplatesActiveItem({ forms, afterApply, drawerData: data });

  const allowEdit = useEditableButtonSetting(account);

  const [fromForm, setFromForm] = useState();

  useEffect(() => {
    if (activeItem && isFunction(onActiveItemChange)) {
      onActiveItemChange({ activeItem, fromForm });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeItem]);

  const { applyItem, view, add } = useShipmentTemplatesControls({
    forms,
    afterApply,
    openDrawer: open,
  });

  const readOnly = !!account?.partner && !allowEdit;

  return (
    <>
      <ConfigurableCard
        titleId="book.newShipment.createShipment"
        bodyComponent="div"
        className="ShipmentTemplatesCard"
      >
        <Row
          className="flex-nowrap"
          align="middle"
          justify="space-between"
          gutter={cssVariables.spaceNorm}
        >
          <Col>
            <DisabledWrapper disabled={disabled}>
              <ShipmentTemplatesListPopup
                activeItem={activeItem}
                setActiveItem={setActiveItem}
                applyItem={applyItem}
                view={item => {
                  setFromForm(false);
                  view(item);
                }}
              />
            </DisabledWrapper>
          </Col>
          <Col>
            {activeItem && !disabled ? (
              <CancelShipmentTemplateButton
                forms={forms}
                afterApply={afterCancel}
                setActiveItem={setActiveItem}
              />
            ) : (
              <SaveShipmentTemplateButton
                onClick={() => {
                  setFromForm(true);
                  add();
                }}
                disabled={disabled || readOnly}
              />
            )}
          </Col>
        </Row>
      </ConfigurableCard>
      {!disabled && (
        <>
          <ShipmentTemplateDrawer
            visible={isOpen}
            onClose={close}
            data={data}
            setData={setData}
            afterSave={afterSave}
            afterDelete={afterDelete}
            values={values}
            activeItem={activeItem}
            readOnly={readOnly}
          />
          <ShipmentTemplatesChangedDialog
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            values={values}
            forms={forms}
            afterApply={afterApply}
            {...changeDetectionProps}
          />
        </>
      )}
    </>
  );
}
